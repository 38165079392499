import moment from 'moment';
import { expiresAtLSManager } from 'helper/localStorage';

export const createExpiresTime = (time) => moment().add(time, 'milliseconds');
export const isUserSessionExpired = () => moment(expiresAtLSManager.get()).isBefore();

export const dateFormatter = (date, fromFormat, toFormat) =>
  moment(date, fromFormat).format(toFormat);

export const monthsRange = (dateFrom, dateTo) => {
  const now = moment(dateFrom).startOf('month');
  const finish = moment(dateTo).endOf('month');

  const result = [];
  while (now.isSameOrBefore(finish)) {
    result.push(now.format('YYYY-MM'));
    now.add(1, 'months');
  }
  return result;
};
