import { useState, useCallback } from 'react';

import { REQUEST_STATUS } from 'constants/common';

export default (asyncFunction, throwError = false) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [status, setStatus] = useState(REQUEST_STATUS.idle);
  const [value, setValue] = useState(null);
  const [error, setError] = useState(null);

  const execute = useCallback(
    async (...args) => {
      try {
        setIsLoaded(false);
        setStatus(REQUEST_STATUS.loading);
        setError(null);
        setValue(await asyncFunction(...args));
        setStatus(REQUEST_STATUS.success);
      } catch (exception) {
        setStatus(REQUEST_STATUS.failure);
        setError(exception);

        if (throwError) {
          throw exception;
        }
      } finally {
        setIsLoaded(true);
      }
    },
    [asyncFunction],
  );

  return {
    execute,
    status,
    value,
    error,
    isLoaded,
    isLoading: status === REQUEST_STATUS.loading,
  };
};
