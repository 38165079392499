import React, { useState } from 'react';
import PropTypes from 'prop-types';

import 'components/common/Table/TableCells/styles.scss';
import { get, noop } from 'lodash';
import { NumberFormatted, NumberFormattedCurrency } from 'helper/NumberFormatted';
import { Popover, Stack } from '@mui/material';
import { ReactComponent as ArrowDownIcon } from 'assets/svg/chevron_down.svg';
import classNames from 'classnames';
import { ReactComponent as ActionsIcon } from 'assets/svg/more.svg';
import ContentLoader from 'components/common/ContentLoader';
import IconButton from 'components/common/IconButton';
import TooltipCopy from 'components/common/TooltipCopy';

export const DefaultCell = ({ column, row }) => (
  <span>{get(row, column.key)}</span>
);

DefaultCell.propTypes = {
  column: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  row: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

export const IdCell = ({ column, row }) => {
  const value = String(get(row, column.key, ''));
  const display = value.length > 7 ? `...${value.slice(-7)}` : value;

  return (
    <TooltipCopy value={value} info={value}>
      {display}
    </TooltipCopy>
  );
};

IdCell.propTypes = DefaultCell.propTypes;

export const FormattedNumberCell = ({
  column, row, prefix, perc,
}) => (
  <>
    {prefix ? (
      <span>
        {NumberFormattedCurrency(row[column.key])}
      </span>
    ) : perc ? (
      <>
        <span>
          {NumberFormatted(row[column.key])}
        </span>
        <span>
          {row[column.key] == null ? '' : '%'}
        </span>
      </>
    ) : (
      <span>
        {NumberFormatted(row[column.key])}
      </span>
    )}
  </>
);

FormattedNumberCell.propTypes = {
  ...IdCell.propTypes,
  prefix: PropTypes.bool,
  perc: PropTypes.bool,
};

FormattedNumberCell.defaultProps = {
  prefix: undefined,
  perc: undefined,
};

export const CollapsibleCell = ({ column, row, isRowExpanded }) => !!row[column.key] && (
  <Stack
    spacing={2}
    direction="row"
    alignItems="center"
    className={classNames('collapsible-cell', { expanded: isRowExpanded })}
  >
    <ArrowDownIcon className="collapsible-icon" />
    <span>
      {row[column.key]}
    </span>
  </Stack>
);

CollapsibleCell.propTypes = {
  column: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  row: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  isRowExpanded: PropTypes.bool.isRequired,
};

export const PopoverCell = ({
  items, onItemClick, children, onCellClick,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    onCellClick(event);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleItemClick = (item, event) => {
    onItemClick(item, event);
    handleClose();
  };

  const handleBackdropClick = (event) => {
    event.stopPropagation();
  };

  return (
    <div className="popover__cell" onClick={handleBackdropClick}>
      <div onClick={handleClick}>
        {children}
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className="popover-content">
          {items.map((item) => (
            <div
              key={item.name}
              className="popover-item text-ellipsis"
              onClick={(event) => handleItemClick(item, event)}
            >
              {item.name}
            </div>
          ))}
        </div>
      </Popover>
    </div>
  );
};

PopoverCell.propTypes = {
  items: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  onItemClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  onCellClick: PropTypes.func,
};

PopoverCell.defaultProps = {
  onCellClick: noop,
};

export const ActionsCell = ({
  column, row, actions,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleItemClick = async (item, event) => {
    if (item.hasLoader) setIsLoading(true);
    await item.action({ column, row });
    if (item.hasLoader) setIsLoading(false);
    event.stopPropagation();
  };

  const handleCellClick = (event) => {
    event.stopPropagation();
  };

  const filteredActions = actions.filter((action) => {
    const shouldShow = action.shouldShow || (() => true);
    return shouldShow({ column, row });
  });

  return (
    <div className="actions__cell">
      <ContentLoader isLoading={isLoading}>
        <PopoverCell
          items={filteredActions}
          onItemClick={handleItemClick}
          onCellClick={handleCellClick}
        >
          <IconButton className="icon-button__table" type="secondary" icon={ActionsIcon} />
        </PopoverCell>
      </ContentLoader>
    </div>
  );
};

ActionsCell.propTypes = {
  column: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  row: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  actions: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
};
