import React from 'react';
import { ReactComponent as LogoIcon } from 'assets/svg/custimyLogo.svg';
import 'components/views/Homepage/Banners/BannerTemplate/style.scss';
import classNames from 'classnames';

const BannerTemplate = (props) => (
  <div className={classNames('home-wrapper-top', props.className)}>
    <div className="logo-wrapper">
      {' '}
      <LogoIcon />
      {' '}
    </div>
    <div className="description-wrapper">
      {!!props.title && <span className="title">{props.title}</span>}
      {!!props.subtitle && <span className="subtitle">{props.subtitle}</span>}
    </div>
  </div>
);

export default BannerTemplate;
