import React from 'react';
import propTypes from 'prop-types';

import 'components/views/Integrations/IconBox/styles.scss';
import { ReactComponent as InfoIcon } from 'assets/svg/info-icon.svg';

const IconBoxAlert = ({ children }) => (
  <div className="alert alert__integration--coming-soon">
    <div className="alert__content alert__content-integration--coming-soon">{children}</div>
    <InfoIcon className="iconBox__infoIcon iconBox__infoIcon-alert" />
  </div>
);

IconBoxAlert.propTypes = {
  children: propTypes.string.isRequired,
};

export default IconBoxAlert;
