import React from 'react';

import Logo from 'components/common/Logo';
import 'containers/Authorization/Header/styles.scss';
import { EXTERNAL_LINK } from 'constants/routes';
import { MESSAGES } from 'constants/messages';

const Header = () => {
  const logoClickHandler = () => window.open(EXTERNAL_LINK.custimy, '_blank');

  return (
    <div className="header">
      <div className="header__nav">
        <div className="header__logo">
          <Logo onClick={logoClickHandler} />
        </div>
      </div>
      <div className="header__contacts header__item">
        {MESSAGES.do_you_need_help}
        {' '}
        <a href={EXTERNAL_LINK.custimyHelp} target="_blank" className="link header__link">{MESSAGES.contact_us}</a>
      </div>
    </div>
  );
};

export default Header;
