import PropTypes from 'prop-types';
import moment from 'moment';
import React from 'react';

import 'components/views/Admin/SuperUsers/ManageLicensesModal/SuperUserLicenseAccessCells/styles.scss';

export const NameCell = ({ column, row }) => (
  <span className="license__access__cell__name">
    {row[column.key]}
  </span>
);

NameCell.propTypes = {
  column: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  row: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

export const CreatedAtCell = ({ column, row }) => {
  const createdAtStr = row[column.key];
  const createdAt = createdAtStr && moment(createdAtStr).format('DD/MM/YY');
  return (
    <span className="user__cell__created_at">
      {createdAt}
    </span>
  );
};

CreatedAtCell.propTypes = NameCell.propTypes;

export const RemoveUserCell = ({ column, row, onClick }) => (
  <div className="super-user__cell__revoke" onClick={() => onClick({ column, row })}>
    <div is="webview">Revoke</div>
  </div>
);

RemoveUserCell.propTypes = {
  column: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  row: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  onClick: PropTypes.func.isRequired,
};
