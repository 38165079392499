import { http } from 'utils';
import { ENDPOINTS } from 'constants/routes';
import { HTTP_METHODS } from 'constants/http';
import { httpFile } from 'utils/file';

const paramsToString = (params) =>
  Object
    .keys(params)
    .filter((key) => Boolean(params[key]))
    .map((key) => `${key}=${params[key]}`)
    .join('&');

const prepareDashboardParams = (dateFrom, dateTo, fields) => {
  const params = {
    date__gte: dateFrom,
    date__lte: dateTo,
    fields: (fields || []).join(','),
  };

  return paramsToString(params);
};

export const getHomeData = ({ dateFrom, dateTo, fields }) => {
  const paramsString = prepareDashboardParams(dateFrom, dateTo, fields);

  return http({
    url: `${ENDPOINTS.home}?${paramsString}`,
    method: HTTP_METHODS.GET,
  });
};

const prepareMetricsParams = (dateFrom, dateTo, fields, platform) => {
  const params = {
    date__gte: dateFrom,
    date__lte: dateTo,
    platform,
    fields: (fields || []).join(','),
  };

  return paramsToString(params);
};

export const getMetrics = ({ dateFrom, dateTo, fields, platform }) => {
  const paramsString = prepareMetricsParams(dateFrom, dateTo, fields, platform);

  return http({
    url: `${ENDPOINTS.metrics}?${paramsString}`,
    method: HTTP_METHODS.GET,
  });
};

export const getHomeDateRange = () => http({
  url: ENDPOINTS.homeDateRange,
  method: HTTP_METHODS.GET,
});

export const exportHomeData = () => httpFile({
  url: ENDPOINTS.homeExport,
  method: HTTP_METHODS.GET,
});
