import React from 'react';
import Popup from 'reactjs-popup';
import propTypes from 'prop-types';

import './styles.scss';
import { TOOLTIP_POSITIONS } from 'constants/common';

const Tooltip = ({
  children,
  trigger,
  keepTooltipInside,
  position,
  ...rest
}) => (
  <Popup
    trigger={trigger}
    position={position}
    on={['hover']}
    keepTooltipInside={keepTooltipInside}
    {...rest}
  >
    {children}
  </Popup>
);

Tooltip.propTypes = {
  children: propTypes.node.isRequired,
  trigger: propTypes.func.isRequired,
  keepTooltipInside: propTypes.string,
  position: propTypes.string,
};

Tooltip.defaultProps = {
  keepTooltipInside: undefined,
  position: TOOLTIP_POSITIONS.leftCenter,
};

export default Tooltip;
