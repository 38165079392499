import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Grid } from '@mui/material';
import Chip from 'components/common/Chip';

const DateRange = ({
  activeItem, onClickHandler, dateRange, format,
}) => (
  <Grid container spacing={1}>
    {dateRange.map((item, idx) => (
      <Grid item>
        <Chip
          label={moment(item).format(format)}
          onClick={() => onClickHandler(item)}
          clickColor="#CE6230"
          selected={activeItem.includes(item)}
        />
      </Grid>
    ))}
  </Grid>
);

DateRange.propTypes = {
  dateRange: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.any,
    ]),
  ),
  onClickHandler: PropTypes.func,
  activeItem: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.any,
    ]),
  ),
  format: PropTypes.string,
};

DateRange.defaultProps = {
  dateRange: undefined,
  onClickHandler: null,
  activeItem: [],
  format: undefined,
};

export default DateRange;
