import React, { useState } from 'react';
import PropTypes from 'prop-types';
import 'components/views/Admin/SuperUsers/SuperUserCells/styles.scss';
import { ReactComponent as ArrowDownIcon } from 'assets/svg/chevron_down.svg';
import { SUPER_USER_PERMISSIONS_ITEMS } from 'constants/superUser';
import { changeSuperUserPermission } from 'api/superUserAPI';
import { useSelector } from 'react-redux';
import { getCurrentUser } from 'store/profile/getters';
import { PopoverCell } from 'components/common/Table/TableCells';

export const NameCell = ({ row }) => (
  <span className="super-user__cell__name">
    {row.user.first_name}
    {' '}
    {row.user.last_name}
  </span>
);

NameCell.propTypes = {
  row: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

export const PermissionCell = ({ column, row }) => {
  const currentUser = useSelector(getCurrentUser);
  const [value, setValue] = useState(row[column.key] && row[column.key][1]);

  const handleItemClick = async (item) => {
    await changeSuperUserPermission(row.uuid, item.key);
    setValue(item.name);
  };

  return (
    row.user.uuid === currentUser.uuid
      ? (
        <span className="super-user__cell__permission">
          {value}
        </span>
      )
      : (
        <PopoverCell
          items={SUPER_USER_PERMISSIONS_ITEMS}
          onItemClick={handleItemClick}
        >
          <div className="super-user__cell__permission">
            <span className="super-user__cell__permission__text">
              {value}
            </span>
            <ArrowDownIcon className="super-user__cell__permission__icon" />
          </div>
        </PopoverCell>
      )
  );
};

PermissionCell.propTypes = {
  column: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  row: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};
