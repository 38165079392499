export const updateChartAxisPrefix = (value, prefixConfig) => {
  const { prefix, perc } = prefixConfig;
  if (prefix) {
    return `${prefix} ${value}`;
  }
  if (perc) return `${value}%`;
  return value;
};

export const updateChartToolTipPrefix = (context, config) => {
  let label = context.dataset.label || '';
  const { prefix, perc } = config;

  if (label) {
    label += ': ';
  }

  if (context.parsed.y !== null) {
    if (prefix) {
      label += `${prefix} ${context.formattedValue}`;
    } else if (perc) {
      label += `${context.formattedValue}%`;
    } else label += `${context.formattedValue}`;
  }
  return label;
};

const roundThousands = (value, roundUp) => {
  let division = null;
  if (value / 1000 > 1) division = 100;
  if (value / 1000000 > 1) division = 100000;
  if (value / 1000000000 > 1) division = 100000000;

  if (!division) return value;

  value /= division;
  value = roundUp ? Math.ceil(value) : Math.floor(value);
  return value * division;
};

export const setAxisMinMaxValues = (data) => {
  const values = data.filter((value) => value || value === 0);
  let min = values.length ? Math.min(...values) : 0;
  let max = values.length ? Math.max(...values) : 0;

  min = roundThousands(min, false);
  max = roundThousands(max, true);

  return min === max
    ? {
      min: min - 1,
      max: max + 1,
    }
    : {
      min,
      max,
    };
};
