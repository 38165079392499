import { useEffect, useState } from 'react';
import { formatFilterLists } from 'helper/filterListFormatter';
import {
  cloneDeep, isEqual, keyBy, map,
} from 'lodash';
import { v1 as uuidv1 } from 'uuid';
import { BOOLEAN_OPTIONS } from 'constants/common';

export default (defaultTags, onTagsChanged, entityConfig, fetchFilters) => {
  const [tags, setTags] = useState([]);
  const [hasTagChanges, setHasTagChanges] = useState(false);
  const [fields, setFields] = useState({});
  const [operators, setOperators] = useState({});

  useEffect(() => {
    fetchFilters().then((resp) => {
      const formattedLists = formatFilterLists(resp);
      setFields(keyBy(formattedLists.fields, 'value'));
      setOperators(formattedLists.operators);
    });
  }, []);

  useEffect(() => {
    if (!hasTagChanges) return;
    onTagsChanged(tags);
    setHasTagChanges(false);
  }, [hasTagChanges]);

  const getTagTypeFromConfig = (tag) => {
    const entity = entityConfig.find((e) => e.key === tag.entityKey);
    return entity?.type;
  };

  const handleTypelessTag = (tag) => {
    if (tag.type) return tag;

    const fieldType = fields[tag.uuid]?.type || getTagTypeFromConfig(tag);
    if (!fieldType) return tag;

    let newValue = tag.value;
    if (tag.fromFilter) {
      if (fieldType === 'percent') {
        newValue *= 100;
      } else if (fieldType === 'boolean') {
        newValue = BOOLEAN_OPTIONS.find((option) => option.value === tag.value);
      }
    }

    return {
      ...tag,
      value: newValue,
      type: fieldType,
    };
  };

  const addTag = (tag) => {
    const newTag = handleTypelessTag(cloneDeep(tag));
    const entity = entityConfig.find((e) => e.key === newTag.entityKey);
    const isFinalized = tag.finalized || entity?.finalized;

    setTags([...tags, {
      key: uuidv1(),
      ...newTag,
      finalized: isFinalized,
    }]);
    if (isFinalized) setHasTagChanges(true);
  };

  const removeTag = (key) => {
    setTags(tags.filter((tag) => tag.key !== key));
    setHasTagChanges(true);
  };

  const updateTag = (key, newTag) => {
    handleTypelessTag(newTag);
    const tagIndex = tags.findIndex((tag) => tag.key === key);
    if (tagIndex === -1) return;

    const newTags = [...tags];
    newTags[tagIndex] = newTag;
    setTags(newTags);

    if (newTag.finalized) setHasTagChanges(true);
  };

  const clearTags = () => {
    setTags([]);
    setHasTagChanges(true);
  };

  useEffect(() => {
    const typeHandledTags = map(tags, handleTypelessTag);
    if (isEqual(tags, typeHandledTags)) return;

    setTags(typeHandledTags);
    setHasTagChanges(true);
  }, [fields, tags]);

  const setAllTags = (newTags) => {
    const typeHandledTags = map(newTags, handleTypelessTag);
    setTags(typeHandledTags);
    setHasTagChanges(true);
  };

  useEffect(() => {
    if (!defaultTags) return;
    setAllTags(defaultTags);
  }, [defaultTags]);

  return {
    addTag,
    removeTag,
    updateTag,
    setAllTags,
    clearTags,
    setTags,
    tags,
    fields,
    operators,
  };
};
