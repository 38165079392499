import React from 'react';
import propTypes from 'prop-types';

import './styles.scss';
import { ReactComponent as HelpIcon } from 'assets/svg/help.svg';

const CVCAlert = ({ children }) => (
  <div className="alert alert--cvc">
    <div className="alert__content">{children}</div>
    <HelpIcon className="alert__help-icon--cvc " />
  </div>
);

CVCAlert.propTypes = {
  children: propTypes.string.isRequired,
};

export default CVCAlert;
