import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import Popup from 'reactjs-popup';

import 'components/views/Admin/Users/DeleteUserModal/styles.scss';
import Button from 'components/common/Button';
import { REQUEST_STATUS, BUTTON_TYPE } from 'constants/common';
import useAsync from 'hooks/useAsync';
import { ReactComponent as WarningIcon } from 'assets/svg/warning.svg';
import { ReactComponent as OkIcon } from 'assets/svg/ok.svg';
import useForm from 'hooks/useForm';
import validation from 'components/views/Admin/Users/DeleteUserModal/deleteUserValidation';
import Input from 'components/common/Input';
import { deleteUser } from 'api/usersAPI';
import { MESSAGES } from 'constants/messages';

const DeleteUserModal = ({ user, onClose, onSubmit }) => {
  const [step, setStep] = useState(1);

  const deleteUserHandler = async () => {
    await deleteUser(user.uuid);
    onSubmit();
    setStep(2);
    setTimeout(handleClose, 3000);
  };

  const { execute, status } = useAsync(deleteUserHandler);

  const {
    handleSubmit, handleChange, handleBlur, handleFocus, values, errors, touched, cleanUpForm,
  } = useForm(
    execute,
    validation,
  );

  useEffect(() => {
    handleChange({ target: { name: 'user', value: user } });
  }, [user]);

  const handleClose = () => {
    onClose();
    setStep(1);
    cleanUpForm();
  };

  return (
    <Popup
      modal
      open={!!user.uuid}
      position="center center"
      onClose={handleClose}
    >
      {() => (
        step === 1
          ? (
            <div className="confirmation-window delete-user">
              <WarningIcon className="delete-user__icon" />
              <span className="delete-user__title">{MESSAGES.user_removal_warning}</span>
              <div className="delete-user__subtitle">
                {MESSAGES.user_deletion_is_permanent}
                <br />
                <br />
                <br />
                {MESSAGES.enter_full_name_of_user}
                <span className="delete-user__username-text">
                  {user.first_name}
                  {' '}
                  {user.last_name}
                </span>
                {MESSAGES.to_proceed}
              </div>

              <form className="delete-user__form main__form form" onSubmit={handleSubmit} noValidate>
                <div className="form__field">
                  <Input
                    id="userName"
                    name="userName"
                    label={MESSAGES.user_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    value={values.userName}
                    error={errors.userName}
                    touched={touched.userName}
                    isRequired
                    hasFeedback
                  />
                </div>
                <div className="delete-user__form__actions form__actions">
                  <div className="delete-user__form__actions__cancel form__cancel">
                    <Button
                      block
                      isPreventSubmit
                      onClick={onClose}
                      type={BUTTON_TYPE.secondary}
                    >
                      {MESSAGES.cancel}
                    </Button>
                  </div>
                  <div className="delete-user__form__actions__submit form__submit">
                    <Button
                      type={BUTTON_TYPE.primary}
                      danger
                      isLoading={REQUEST_STATUS.loading === status}
                      isDisabled={errors.userName}
                    >
                      {MESSAGES.delete_user}
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          )
          : (
            <div className="confirmation-window reset-password__success">
              <OkIcon />
              <span className="reset-password-success__text">
                {MESSAGES.the_user}
              </span>
              <span className="reset-password-success__username">
                {user.first_name}
                {' '}
                {user.last_name}
              </span>
              <span>
                {MESSAGES.has_been_deleted}
              </span>
            </div>
          )
      )}
    </Popup>
  );
};

export default DeleteUserModal;

DeleteUserModal.propTypes = {
  user: propTypes.objectOf(propTypes.oneOfType([
    propTypes.string,
    propTypes.array,
  ])).isRequired,
  onClose: propTypes.func.isRequired,
  onSubmit: propTypes.func.isRequired,
};
