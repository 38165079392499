import { createSlice } from '@reduxjs/toolkit';
import moduleName from './constants';

const initialState = {
  productsData: {
    'Least profitable product': [],
    'Most profitable product': [],
    'Most valuable product': [],
    'Quick repeaters': [],
    'Luring products': [],
    'Declining products': [],
    'Highest synergizing': [],
    'Trending products': [],
  },
  productsFilter: [],
};

const productSlice = createSlice({
  name: moduleName,
  initialState,
  reducers: {
    setProductsData(state, action) {
      state.productsData = action.payload;
    },
    setProductsFilter(state, action) {
      state.productsFilter = action.payload;
    },
  },
});

export const {
  setProductsData,
  setProductsFilter,
} = productSlice.actions;

export default productSlice.reducer;
