import { createSlice } from '@reduxjs/toolkit';

import moduleName from './constants';

const initialState = {
  uuid: '',
  email: '',
  first_name: '',
  last_name: '',
  package: null,
  role: [],
  avatar: '',
  super_user_permission: null,
  super_user_uuid: '',
};

const profileSlice = createSlice({
  name: moduleName,
  initialState,
  reducers: {
    setCurrentUser(state, action) {
      const {
        uuid,
        email,
        first_name,
        last_name,
        package: userPackage,
        role,
        avatar,
        is_first_license_exist,
        super_user_permission,
        super_user_uuid,
        uses_password,
      } = action.payload;
      state.uuid = uuid;
      state.email = email;
      state.first_name = first_name;
      state.last_name = last_name;
      state.package = userPackage;
      state.role = role;
      state.avatar = avatar;
      state.is_first_license_exist = is_first_license_exist;
      state.super_user_permission = super_user_permission;
      state.super_user_uuid = super_user_uuid;
      state.uses_password = uses_password;
    },
    setInvitedUser(state, action) {
      const { email } = action.payload;
      state.email = email;
    },
    updateCurrentUser(state, action) {
      const {
        uuid,
        email,
        first_name,
        last_name,
        package: userPackage,
        role,
        avatar,
        is_first_license_exist,
        super_user_permission,
        super_user_uuid,
      } = {
        ...state,
        ...action.payload,
      };
      state.uuid = uuid;
      state.email = email;
      state.first_name = first_name;
      state.last_name = last_name;
      state.package = userPackage;
      state.role = role;
      state.avatar = avatar;
      state.is_first_license_exist = is_first_license_exist;
      state.super_user_permission = super_user_permission;
      state.super_user_uuid = super_user_uuid;
    },
  },
});

export const {
  setCurrentUser,
  setInvitedUser,
  updateCurrentUser,
} = profileSlice.actions;

export default profileSlice.reducer;
