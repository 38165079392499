import React from 'react';
import propTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';

const ProtectedRoute = ({
  component: Component, hasPermission, redirectToFunc, ...rest
}) => (
  <Route
    render={(props) => (
      hasPermission ? <Component {...props} /> : <Redirect to={redirectToFunc(props)} />
    )}
    {...rest}
  />
);

ProtectedRoute.propTypes = {
  component: propTypes.func.isRequired,
  hasPermission: propTypes.bool.isRequired,
  redirectToFunc: propTypes.func.isRequired,
};

export default ProtectedRoute;
