import React, { useRef, useState } from 'react';
import classNames from 'classnames';

import './styles.scss';
import { ReactComponent as AttentionIcon } from 'assets/svg/attention.svg';
import { ReactComponent as PreviewIcon } from 'assets/svg/eye.svg';
import { ReactComponent as SuccessIcon } from 'assets/svg/checkMark.svg';
import { ReactComponent as CalendarIcon } from 'assets/svg/calendar.svg';
import { ReactComponent as SearchIcon } from 'assets/svg/search.svg';
import { INPUT_TYPE } from 'constants/common';
import Feedback from './Feedback';
import PasswordScale from './PasswordScale';
import { defaultInputProps, inputPropTypes } from 'components/common/Input/inputPropTypes';

const Input = ({
  id,
  containerRef,
  name,
  type,
  label,
  error,
  value,
  autoComplete,
  maxLength,
  touched,
  isRequired,
  isDisable,
  isReadonly,
  isBlurOnEnter,
  hasScale,
  hasPreview,
  hasFeedback,
  hasSuccess,
  hasFloatLabel,
  hasPercent,
  hasCalendar,
  hasSearch,
  onKeyPress,
  onChange,
  onBlur,
  onFocus,
  onClick,
  onSearchClick,
  inputType,
  autoFocus,
  isFixedLabel,
}) => {
  const [isPreview, setIsPreview] = useState(false);
  const element = useRef(null);

  const changePreview = (e) => {
    e.preventDefault();
    setIsPreview((prev) => !prev);
  };
  const handleTextChange = (e) => {
    onChange(e);
  };

  const handleKeyPress = (e) => {
    if (isBlurOnEnter && e.key === 'Enter') {
      element.current.blur();
    }
    onKeyPress(e);
  };

  return (
    <div
      ref={containerRef}
      className={classNames(
        'field', 'float-label', {
          'field--error': touched && error,
          'field--has-icon': hasFeedback || hasPreview || hasSuccess || hasCalendar || hasSearch,
          'field--has-sign': hasPercent,
          'field--v1': inputType === INPUT_TYPE.v1,
          'field--v2': inputType === INPUT_TYPE.v2,
          'field-disabled': isDisable,
        },
      )}
      onClick={onClick}
    >
      {hasFloatLabel && (
        <label
          htmlFor={id.type}
          className={classNames('field__floating-label',
            { activated: !!value || isFixedLabel })}
        >
          {`${label} ${isRequired ? '*' : ''}`}
        </label>
      )}
      <input
        ref={element}
        id={id}
        name={name}
        value={value}
        type={isPreview ? 'text' : type}
        required={isRequired}
        autoComplete={autoComplete}
        maxLength={maxLength}
        onChange={handleTextChange}
        onBlur={onBlur}
        onFocus={onFocus}
        className="field__input"
        placeholder={hasFloatLabel ? '' : `${label} ${isRequired ? '*' : ''}`}
        disabled={isDisable}
        readOnly={isReadonly}
        autoFocus={autoFocus}
        onKeyPress={handleKeyPress}
      />
      {hasScale && (
        <div className="field__scale">
          <PasswordScale password={value} />
        </div>
      )}
      {hasSuccess && touched && !error && <SuccessIcon className="field__success" />}
      {hasFeedback && touched && <AttentionIcon className="field__feedback" />}
      {hasCalendar && <CalendarIcon className="field__calendar" />}
      {hasSearch && (
        <div className="field__search" onClick={onSearchClick}>
          <SearchIcon className="field__search-icon" />
        </div>
      )}
      {hasPercent && <div className="field__percent">%</div> }
      {hasPreview && (
        <PreviewIcon
          onClick={changePreview}
          className={classNames('field__preview', {
            'field__preview--active': isPreview,
          })}
        />
      )}
      {hasFeedback && touched && error && (
        <div className="field__hint">
          <Feedback hint={error} />
        </div>
      )}
    </div>
  );
};

Input.propTypes = inputPropTypes;

Input.defaultProps = defaultInputProps;

export default Input;
