import { useEffect, useRef, useState } from 'react';
import { DOM_EVENTS } from 'constants/domEvents';

export default () => {
  const [isOpened, setIsOpened] = useState(false);
  const parentRef = useRef(null);

  const clickOutHandler = (e) => {
    if (!parentRef.current?.contains(e.target)) {
      setIsOpened(false);
    }
  };

  useEffect(() => {
    window.addEventListener(DOM_EVENTS.click, clickOutHandler);
    return () => window.removeEventListener(DOM_EVENTS.click, clickOutHandler);
  }, []);

  const togglePopover = () => setIsOpened(!isOpened);

  return {
    isOpened,
    setIsOpened,
    parentRef,
    togglePopover,
  };
};
