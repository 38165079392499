import Validator from 'utils/validator';
import {
  EMAIL_ERROR_PATTERN,
  EMAIL_ERROR_REQUIRED,
} from 'constants/errors';
import { EMAIL_PATTERN } from 'constants/validation';

export default ({ email }) => {
  const errors = {};

  const emailValidator = new Validator(email);

  try {
    emailValidator
      .required(EMAIL_ERROR_REQUIRED)
      .match(EMAIL_PATTERN, EMAIL_ERROR_PATTERN);
  } catch (error) {
    errors.email = error.message;
  }

  return errors;
};
