import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import Input from 'components/common/Input';
import { INPUT_VALUE_TYPE, BOOLEAN_OPTIONS } from 'constants/common';
import Select from 'components/common/Select';


const TypedInput = ({
  name, type, value, valueChanged, label, isFixedLabel, onKeyPress,
}) => {
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const handleValueChange = ({ target }) => {
    setLocalValue(target.value);
  };

  const handleValueBlur = ({ target }) => {
    if ([INPUT_VALUE_TYPE.number, INPUT_VALUE_TYPE.percent].includes(type)) {
      valueChanged(Number(target.value));
    } else {
      valueChanged(target.value);
    }
  };

  return (
    type === INPUT_VALUE_TYPE.number ? (
      <Input
        name={name}
        id="typed-input"
        type="number"
        label={label || '000'}
        hasFloatLabel={isFixedLabel}
        isFixedLabel={isFixedLabel}
        isBlurOnEnter
        value={localValue}
        onChange={handleValueChange}
        onBlur={handleValueBlur}
        onKeyPress={onKeyPress}
      />
    ) : type === INPUT_VALUE_TYPE.percent ? (
      <Input
        name={name}
        id="typed-input"
        type="number"
        label={label || '0'}
        hasFloatLabel={isFixedLabel}
        isFixedLabel={isFixedLabel}
        isBlurOnEnter
        value={localValue}
        onChange={handleValueChange}
        onBlur={handleValueBlur}
        onKeyPress={onKeyPress}
        hasPercent
      />
    ) : type === INPUT_VALUE_TYPE.text ? (
      <Input
        name={name}
        id="typed-input"
        type="text"
        label={label || '...'}
        hasFloatLabel={isFixedLabel}
        isFixedLabel={isFixedLabel}
        isBlurOnEnter
        value={localValue}
        onChange={handleValueChange}
        onBlur={handleValueBlur}
        onKeyPress={onKeyPress}
      />
    ) : type === INPUT_VALUE_TYPE.boolean ? (
      <Select
        label=""
        value={localValue}
        onChange={handleValueBlur}
        onBlur={() => undefined}
        onKeyPress={onKeyPress}
        options={BOOLEAN_OPTIONS}
      />
    ) : (
      <Input
        name={name}
        id="typed-input"
        isDisable
        label={label || '000'}
        hasFloatLabel={isFixedLabel}
        isFixedLabel={isFixedLabel}
        onKeyPress={onKeyPress}
      />
    )
  );
};

TypedInput.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  valueChanged: PropTypes.func,
  label: PropTypes.string,
  isFixedLabel: PropTypes.bool,
  onKeyPress: PropTypes.func,
};

TypedInput.defaultProps = {
  type: undefined,
  value: undefined,
  valueChanged: undefined,
  label: '',
  isFixedLabel: false,
  onKeyPress: undefined,
};

export default TypedInput;
