import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './styles.scss';
import { NumberFormattedCurrency } from 'helper/NumberFormatted';

const Percentage = ({
  children,
  inverted,
}) => {
  const positive = children >= 0;
  const negative = children < 0;

  return (
    <span className={classNames('percentage', {
      positive: (positive && !inverted) || (!positive && inverted),
      negative: (negative && !inverted) || (!negative && inverted),
    })}
    >
      {positive && '+'}
      {NumberFormattedCurrency(children)}
      %
    </span>
  );
};

Percentage.propTypes = {
  children: PropTypes.number,
  inverted: PropTypes.bool,
};

Percentage.defaultProps = {
  children: undefined,
  inverted: false,
};

export default Percentage;
