import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import propTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import './styles.scss';
import useRouter from 'hooks/useRouter';
import MinSideBarMenuItem from '../MinSideBarMenuItem';
import { Link } from 'react-router-dom';

const MinSideBarMenuList = ({
  config, hrefTo, children, title,
}) => {
  const { location } = useRouter();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const hasConfig = !!(config && config.length);
  const [toolTipTitle, setToolTipTitle] = useState(undefined);

  const openListHandler = () => {
    if (hasConfig) {
      setIsCollapsed((prevState) => !prevState);
    }
  };

  const handleSegmentsClick = () => {
    openListHandler();
  };

  useEffect(() => {
    if (location?.pathname.includes(hrefTo)) {
      setIsCollapsed(true);
    } else setIsCollapsed(false);
  }, [location?.pathname]);

  const getToolTipTitle = ({ currentTarget }) => (currentTarget.value);

  return (
    <li className={classNames('min-menu-list min-menu-list__list--selectable', {
      'min-menu-list__list--collapsed': !isCollapsed,
    })}
    >
      <div className="min-menu-list-header-wrapper">
        <Link to={hrefTo}>
          <button
            type="button"
            className={classNames('min-menu-list__header', {
              'min-menu-list__header--active': location.pathname.includes(hrefTo),
            })}
            onClick={handleSegmentsClick}
            value={title}
            onMouseEnter={(e) => setToolTipTitle(getToolTipTitle(e))}
            onMouseLeave={() => setToolTipTitle(undefined)}
          >
            <div
              className="min-menu-list__icon"
              data-tip
              data-for="tip"
            >
              {children}
            </div>
            {toolTipTitle && (
            <ReactTooltip className="tooltip-container" id="tip" place="right" overridePosition={null} type="light" effect="solid" globalEventOff="click" arrowColor="transparent">
              <div className="tooltip-text">{toolTipTitle}</div>
            </ReactTooltip>
            )}
          </button>
        </Link>
      </div>
      <ul className="min-menu-list__list">
        {config.map((item) => (
          <MinSideBarMenuItem
            key={item.title}
            item={item}
          />
        ))}
      </ul>
    </li>
  );
};

MinSideBarMenuList.propTypes = {
  children: propTypes.node.isRequired,
  config: propTypes.arrayOf(propTypes.oneOfType([propTypes.object])),
  hrefTo: propTypes.string,
  title: propTypes.string,
};

MinSideBarMenuList.defaultProps = {
  config: [],
  hrefTo: undefined,
  title: undefined,
};

export default MinSideBarMenuList;
