import React, { useState } from 'react';

import { ADD_LICENSE_STEPS } from 'constants/common';
import AddLicense from 'containers/PrivateApp/NewLicense/AddLicense';
import useRouter from 'hooks/useRouter';
import { ROUTES } from 'constants/routes';
import { MESSAGES } from 'constants/messages';

const NewLicense = () => {
  const { push } = useRouter();
  const [step, setStep] = useState(ADD_LICENSE_STEPS.license);

  const nextStep = () => {
    setStep((prevStep) => prevStep + 1);
    push(ROUTES.home);
  };

  return (
    <div className="authorization">
      <main className="details main">
        <div className="details__content">
          {
            step === ADD_LICENSE_STEPS.license && <AddLicense nextStep={nextStep} />
          }
          {
            step === ADD_LICENSE_STEPS.pay && <span>{MESSAGES.it_will_be_soon}</span>
          }
        </div>
      </main>
    </div>
  );
};

export default NewLicense;
