import React from 'react';
import propTypes from 'prop-types';

import './styles.scss';

const UserPanelItem = ({ children, onClickHandler }) => (
  <button type="button" onClick={onClickHandler} className="user-panel-item__item">
    {children}
  </button>
);

UserPanelItem.propTypes = {
  children: propTypes.node.isRequired,
  onClickHandler: propTypes.func,
};

UserPanelItem.defaultProps = {
  onClickHandler: undefined,
};

export default UserPanelItem;
