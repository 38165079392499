import React from 'react';
import { ReactComponent as NeedHelpIcon } from 'assets/svg/help_circle_outline.svg';
import propTypes from 'prop-types';
import { EXTERNAL_LINK } from 'constants/routes';
import { MESSAGES } from 'constants/messages';
import { LinkButton } from 'components/common/LinkButton';

const NeedHelpLink = ({ href }) => (
  <div className="info__help">
    <LinkButton
      size="large"
      type="secondary"
      icon={NeedHelpIcon}
      href={href}
      hrefNewTab
      underlined
    >
      {MESSAGES.help_guide}
    </LinkButton>
  </div>
);

NeedHelpLink.propTypes = {
  href: propTypes.string,
};
NeedHelpLink.defaultProps = {
  href: EXTERNAL_LINK.custimyHelp,
};

export default NeedHelpLink;
