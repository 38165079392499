import moduleName from 'store/profile/constants';
import { SUPER_USER_PERMISSIONS } from 'constants/superUser';

const getProfileState = (state) => state[moduleName];

export const getCurrentUser = (state) => getProfileState(state);
export const getIsSuperUser = (state) => !!(getProfileState(state).super_user_permission);

const _hasPermission = (state, permission) =>
  (getProfileState(state).super_user_permission || []).includes(permission);
export const getIsMaxAccessSuperUser = (state) => _hasPermission(state, SUPER_USER_PERMISSIONS.maxAccess);
export const getIsDataAccessSuperUser = (state) =>
  _hasPermission(state, SUPER_USER_PERMISSIONS.dataAccess) || getIsMaxAccessSuperUser(state);
