import {
  PASSWORD_PATTERN,
} from 'constants/validation';
import {
  ValidationError,
  PASSWORD_ERROR_PATTERN,
  CONFIRM_PASSWORD_ERROR_REQUIRED,
  CONFIRM_PASSWORD_ERROR_NOT_MATCH,
  OLD_PASSWORD_ERROR_REQUIRED,
  NEW_PASSWORD_ERROR_REQUIRED,
} from 'constants/errors';
import Validator from 'utils/validator';

export default ({
  old_password,
  new_password,
  confirm_password,
  uses_password,
}) => {
  const errors = {};
  const oldValidator = new Validator(old_password);
  const passwordValidator = new Validator(new_password);
  const confirmPasswordValidator = new Validator(confirm_password);

  if (uses_password) {
    try {
      oldValidator
        .required(OLD_PASSWORD_ERROR_REQUIRED)
        .match(PASSWORD_PATTERN, PASSWORD_ERROR_PATTERN);
    } catch (error) {
      errors.old_password = error.message;
    }
  }

  try {
    passwordValidator
      .required(NEW_PASSWORD_ERROR_REQUIRED)
      .match(PASSWORD_PATTERN, PASSWORD_ERROR_PATTERN);
  } catch (error) {
    errors.new_password = error.message;
  }

  try {
    confirmPasswordValidator
      .required(CONFIRM_PASSWORD_ERROR_REQUIRED)
      .additional((validator) => {
        if (validator.field !== new_password) {
          throw new ValidationError(CONFIRM_PASSWORD_ERROR_NOT_MATCH);
        }

        return validator;
      });
  } catch (error) {
    errors.confirm_password = error.message;
  }
  return errors;
};
