import React, { useState } from 'react';
import useListApi from 'hooks/useListApi';
import { getUsersList } from 'api/usersAPI';
import { USERS_PER_PAGE } from 'constants/common';
import { ActionsCell, DefaultCell, IdCell } from 'components/common/Table/TableCells';
import { LastLoginCell, NameCell, StatusCell } from 'components/views/Admin/Users/UsersCells';
import ResetPasswordModal from 'components/views/Admin/Users/ResetPasswordModal';
import DeleteUserModal from 'components/views/Admin/Users/DeleteUserModal';
import { AvatarCell } from 'components/views/Admin/AdminCells';
import ReactivateUserModal from 'components/views/Admin/Users/ReactivateUserModal';
import { ENTITY_LICENSE } from 'constants/entities';
import { MESSAGES } from 'constants/messages';
import Block from 'components/common/Block';
import PaginatedTable from 'components/common/Table/PaginatedTable';
import Search from 'components/common/Search';
import { Grid, Stack } from '@mui/material';
import TableSearchEntity from 'components/common/TableSearchEntity';
import Checkbox from 'components/common/Checkbox';
import FilterTags from 'components/common/FilterTags';
import { LIST_API_REDUX_KEYS } from 'constants/listApi';

const getTableConfig = (actions, showStatus) => [{
  title: null,
  key: 'avatar',
  ordering: null,
  CellComponent: AvatarCell,
  componentProps: {},
  width: '40px',
  hasOverflowEllipsis: false,
}, {
  title: 'Name',
  key: 'name',
  ordering: ['first_name', 'last_name'],
  CellComponent: NameCell,
  componentProps: {},
  width: '200px',
  hasOverflowEllipsis: true,
}, {
  title: 'ID',
  key: 'uuid',
  ordering: ['uuid'],
  CellComponent: IdCell,
  componentProps: {},
  width: '25%',
  hasOverflowEllipsis: true,
}, {
  title: 'E-mail',
  key: 'email',
  ordering: ['email'],
  CellComponent: DefaultCell,
  componentProps: {},
  width: '200px',
  hasOverflowEllipsis: true,
}, {
  title: 'Last login',
  key: 'last_login',
  ordering: ['last_login'],
  CellComponent: LastLoginCell,
  componentProps: {},
  width: '150px',
  hasOverflowEllipsis: true,
}, showStatus && {
  title: 'Status',
  key: 'is_active',
  ordering: ['is_active'],
  CellComponent: StatusCell,
  componentProps: {},
  width: '70px',
  hasOverflowEllipsis: true,
}, {
  title: null,
  key: null,
  ordering: null,
  CellComponent: ActionsCell,
  componentProps: { actions },
  width: '40px',
  hasOverflowEllipsis: false,
}].filter((item) => !!item);

const searchEntityConfig = [{
  key: ENTITY_LICENSE,
  title: 'Licenses',
  hasPages: true,
}];

const Users = () => {
  const [resetPasswordUser, setResetPasswordUser] = useState({});
  const [userForDeletion, setUserForDeletion] = useState({});
  const [userForReactivation, setUserForReactivation] = useState({});

  const defaultConfig = {
    ordering: ['last_login'],
    is_active: true,
  };
  const {
    data,
    totalCount,
    isDataLoading,
    config,
    changeConfig,
  } = useListApi(LIST_API_REDUX_KEYS.adminPanelUsers, defaultConfig, getUsersList);

  const handleTagsChange = (tags) => changeConfig({ tags });
  const handleSortChange = (ordering) => changeConfig({ ordering });
  const handlePageChange = (page) => changeConfig({ page });
  const handleSearchChange = (search) => changeConfig({ search });
  const handleShowDeletedChange = () => changeConfig({ is_active: config.is_active !== '' ? '' : true });
  const handleUserReactivateSubmit = () => {
    setUserForReactivation({});
    changeConfig({ page: config.page });
  };

  const actions = [{
    name: 'Reset Password',
    action: (x) => setResetPasswordUser(x.row),
    shouldShow: (x) => x.row.is_active,
  }, {
    name: 'Delete User',
    action: (x) => setUserForDeletion(x.row),
    shouldShow: (x) => x.row.is_active,
  }, {
    name: 'Reactivate User',
    action: (x) => setUserForReactivation(x.row),
    shouldShow: (x) => !x.row.is_active,
  }];
  const tableConfig = getTableConfig(actions, config.is_active === '');

  return (
    <>
      <Block header={MESSAGES.users}>
        <Grid container rowSpacing={2}>
          <Grid item container spacing={2} xs={12}>
            <Grid item md={4} xs={12}>
              <Search
                search={config.search}
                title={MESSAGES.search_by_name_email}
                searchChanged={handleSearchChange}
              />
            </Grid>
            <Grid item>
              <TableSearchEntity
                searchEntityConfig={searchEntityConfig}
                tags={config.tags}
                tagsChanged={handleTagsChange}
              />
            </Grid>
            <Grid item component={Stack} justifyContent="center">
              <Checkbox
                label={MESSAGES.show_deleted}
                value={config.is_active === ''}
                onChange={handleShowDeletedChange}
              />
            </Grid>
            {!!config.tags.length && (
              <Grid item xs={12}>
                <FilterTags
                  tags={config.tags}
                  tagsChanged={handleTagsChange}
                />
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <PaginatedTable
              sort={config.ordering}
              sortChanged={handleSortChange}
              totalCount={totalCount}
              tableConfig={tableConfig}
              data={data}
              highlightStartIdx={0}
              highlightEndIdx={5}
              countPerPage={USERS_PER_PAGE}
              page={config.page}
              pageChanged={handlePageChange}
              isDataLoading={isDataLoading}
            />
          </Grid>
        </Grid>
      </Block>
      <ResetPasswordModal
        user={resetPasswordUser}
        onClose={() => setResetPasswordUser({})}
      />
      <DeleteUserModal
        user={userForDeletion}
        onClose={() => setUserForDeletion({})}
        onSubmit={() => changeConfig({ page: 1 })}
      />
      <ReactivateUserModal
        user={userForReactivation}
        onClose={() => setUserForReactivation({})}
        onSubmit={handleUserReactivateSubmit}
      />
    </>
  );
};

Users.propTypes = {};

export default Users;
