import React, { useLayoutEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import Portal from 'containers/PrivateApp/Portal';
import SwitchLicense from 'containers/PrivateApp/SwitchLicense';
import NewLicense from 'containers/PrivateApp/NewLicense';
import { accessTokenLSManager, userUuidLSManager } from 'helper/localStorage';
import useRouter from 'hooks/useRouter';
import { useSelector } from 'react-redux';
import useLogout from 'hooks/useLogout';
import { getCurrentUser } from 'store/profile/getters';
import { getLicenseDetails } from 'store/license/getters';
import Payment from 'containers/PrivateApp/Payment';

const PrivateApp = () => {
  const [isChecked, setIsChecked] = useState(false);
  const { location, replace } = useRouter();
  const currentUser = useSelector(getCurrentUser);
  const {
    license,
    license_status: licenseStatus,
  } = useSelector(getLicenseDetails);
  const logoutHandler = useLogout();

  const checkUserDetails = () => {
    if (!userUuidLSManager.get() || !accessTokenLSManager.get()) {
      logoutHandler();
      return;
    }
    if (location.pathname === ROUTES.switchLicense) {
      return;
    }
    if (!currentUser?.first_name || !license?.company_name) {
      replace(ROUTES.details, location?.state);
      return;
    }
    if (!license.is_payment_valid) {
      replace(ROUTES.payment, location?.state);
      return;
    }
    if (licenseStatus?.length && licenseStatus[1] === 'Invited' && location.pathname !== ROUTES.newLicense) {
      replace(ROUTES.acceptInvitation);
    }
  };

  useLayoutEffect(() => {
    checkUserDetails();
    setIsChecked(true);
  }, [location?.pathname]);

  return isChecked && (
    <Switch>
      <Route path={ROUTES.payment} component={Payment} exact />
      <Route path={ROUTES.switchLicense} component={SwitchLicense} exact />
      <Route path={ROUTES.newLicense} component={NewLicense} exact />
      <Route path={ROUTES.portal} component={Portal} />
    </Switch>
  );
};

export default PrivateApp;
