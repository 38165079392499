import React, { useEffect, useState } from 'react';
import { Grid, Stack } from '@mui/material';
import SalesKpiItem from 'components/views/Homepage/KpiHistoryDataBlock/SalesKpiItem';
import 'components/views/Homepage/style.scss';
import ShowDetailedKpiChart from 'components/views/Homepage/KpiHistoryDataBlock/ShowDetailedKpiChart';
import useDashboard from 'components/views/Homepage/KpiHistoryDataBlock/useDashboard';
import useBarChart from 'components/views/Homepage/KpiHistoryDataBlock/useBarChart';
import UtilityKpiItem from 'components/views/Homepage/KpiHistoryDataBlock/UtilityKpiItem';
import { AVAILABLE_INTEGRATIONS } from 'components/views/Integrations/constants/Constants';
import { MESSAGES } from 'constants/messages';
import { GUIDES } from 'constants/guides';
import { exportHomeData, getHomeDateRange } from 'api/homeAPI';
import useMetrics from 'components/views/Homepage/KpiHistoryDataBlock/useMetrics';
import MetricsKpiItem from 'components/views/Homepage/KpiHistoryDataBlock/MetricsKpiItem';
import DataExport from 'components/common/DataExport';
import moment from 'moment';
import MonthPicker from 'components/common/MonthPicker';
import Block from 'components/common/Block';

const strToDate = (s) => {
  const [yearStr, monthStr] = s.split('-');
  return new Date(Number(yearStr), Number(monthStr) - 1);
};

const KpiHistoryDataBlock = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [dateBoundaries, setDateBoundaries] = useState({ date_from: null, date_to: null });
  const [selectedMonth, setSelectedMonth] = useState();
  const [selectedKpi, setSelectedKpi] = useState();

  useEffect(() => {
    const setDates = async () => {
      const result = await getHomeDateRange();
      setDateBoundaries(result);
      setMaxDate(strToDate(result.date_to));
      setMinDate(strToDate(result.date_from));
      setSelectedDate(strToDate(result.date_to));
    };

    setDates();
  }, []);

  const onDateChange = (date) => {
    setSelectedDate(date);
    setSelectedMonth(moment(date).format('YYYY-MM'));
  };

  const isMostRecent = (!dateBoundaries?.date_to || selectedMonth === undefined)
    || dateBoundaries.date_to.substring(0, 7) === selectedMonth;
  const { dashboardData, dashboardGraphData } = useDashboard(selectedMonth);
  const { metricsData, metricsGraphData } = useMetrics(selectedMonth);
  const { barChartData } = useBarChart(selectedKpi);

  const chartSelected = (key, title, prefixConfig, platform = null) => setSelectedKpi({
    key,
    title,
    prefixConfig,
    platform,
  });

  return (
    <Stack spacing={4}>
      <Grid container columnSpacing={2} rowSpacing={5} justifyContent="flex-end">
        <Grid item>
          <DataExport exportHandler={exportHomeData} />
        </Grid>
        <Grid item>
          <MonthPicker
            type="primary"
            value={selectedDate}
            onChange={onDateChange}
            minDate={minDate}
            maxDate={maxDate}
            withNavigation
          />
        </Grid>
      </Grid>
      <Stack spacing={2}>
        <Block>
          <SalesKpiItem
            data={dashboardData}
            graphData={dashboardGraphData}
            onClickChartHandler={chartSelected}
            isMostRecent={isMostRecent}
          />
        </Block>
        <Block>
          <MetricsKpiItem
            data={metricsData}
            graphData={metricsGraphData}
            onClickChartHandler={chartSelected}
            isMostRecent={isMostRecent}
          />
        </Block>
        <Block>
          <UtilityKpiItem
            categoryTitle={MESSAGES.order}
            graphData={dashboardGraphData}
            onClickChartHandler={chartSelected}
            isMostRecent={isMostRecent}
            options={[
              {
                key: 'avg_order_size',
                title: MESSAGES.order_value_avg,
                value: dashboardData?.avg_order_size,
                guide: GUIDES.H5,
                monthly: true,
                prefix: dashboardData?.currency,
              },
              {
                key: 'avg_order_items',
                title: MESSAGES.order_items_avg,
                value: dashboardData?.avg_order_items,
                guide: GUIDES.H18,
                monthly: true,
              },
              {
                key: 'return_rate',
                title: MESSAGES.order_return_rate,
                value: dashboardData?.return_rate,
                monthly: true,
                negative: true,
                guide: GUIDES.H10,
                perc: true,
              },
              {
                key: 'total_discounts',
                title: MESSAGES.applied_discounts,
                value: dashboardData?.total_discounts,
                guide: GUIDES.H17,
                monthly: true,
                prefix: dashboardData?.currency,
              },
              {
                key: 'applied_discount_rate',
                title: MESSAGES.applied_discount_rate,
                value: dashboardData?.applied_discount_rate,
                monthly: true,
                negative: true,
                guide: GUIDES.H19,
                perc: true,
              },
              //  {
              //   key: 'unique_visitors',
              //   title: MESSAGES.unique_sessions,
              //   value: dashboardData?.unique_visitors,
              //   requiredIntegration: AVAILABLE_INTEGRATIONS.GOOGLE_ANALYTICS,
              //   guide: GUIDES.H16,
              //   monthly: true,
              // },
              // {
              //   key: 'conversion_rate',
              //   title: MESSAGES.conversion_rate,
              //   value: dashboardData?.conversion_rate,
              //   requiredIntegration: AVAILABLE_INTEGRATIONS.GOOGLE_ANALYTICS,
              //   guide: GUIDES.H9,
              //   monthly: true,
              //   perc: true,
              // },
              {
                key: 'abandonment_rate',
                title: MESSAGES.cart_abandonment_rate,
                value: dashboardData?.abandonment_rate,
                monthly: true,
                negative: true,
                guide: GUIDES.H11,
                perc: true,
              },
            ]}
          />
        </Block>
        <Block>
          <UtilityKpiItem
            categoryTitle={MESSAGES.customer}
            graphData={dashboardGraphData}
            onClickChartHandler={chartSelected}
            isMostRecent={isMostRecent}
            options={[
              {
                key: 'avg_total_sales',
                title: MESSAGES.customer_revenue_avg,
                value: dashboardData?.avg_total_sales,
                guide: GUIDES.H6,
                monthly: true,
                prefix: dashboardData?.currency,
              },
              {
                key: 'clv',
                title: MESSAGES.customer_lifetime_value_avg,
                value: dashboardData?.clv,
                guide: GUIDES.H7,
                monthly: true,
                prefix: dashboardData?.currency,
              },
              {
                key: 'avg_count_orders',
                title: MESSAGES.customer_orders_avg,
                value: dashboardData?.avg_count_orders,
                guide: GUIDES.H8,
                monthly: true,
              },
              {
                key: 'new_customers',
                title: MESSAGES.new_customers,
                value: dashboardData?.new_customers,
                monthly: true,
                guide: GUIDES.H12,
              },
              {
                key: 'returned_customers',
                title: MESSAGES.returning_customers,
                value: dashboardData?.returned_customers,
                monthly: true,
                guide: GUIDES.H13,
              },
              {
                key: 'churn_customers',
                title: MESSAGES.churned_customers,
                value: dashboardData?.churn_customers,
                negative: true,
                monthly: true,
                guide: GUIDES.H14,
              },
              {
                key: 'churn_rate',
                title: MESSAGES.churn_rate,
                value: dashboardData?.churn_rate,
                negative: true,
                guide: GUIDES.H15,
                monthly: true,
                perc: true,
              },
            ]}
          />
        </Block>
        <ShowDetailedKpiChart
          open={Boolean(selectedKpi)}
          onClose={() => setSelectedKpi(null)}
          allYearsBarChartData={barChartData}
        />
      </Stack>
    </Stack>
  );
};

export default KpiHistoryDataBlock;
