import React, { useEffect, useState } from 'react';
import 'components/views/Segments/GraphicBlock/style.scss';
import {
  Bar, BarChart, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis,
} from 'recharts';
import PropTypes from 'prop-types';
import { NumberFormatted } from 'helper/NumberFormatted';
import { sum } from 'lodash';
import { SEGMENT_NAME } from 'constants/segmentNames';

const renderCustomAxisTick = ({ x, y, payload }) => (
  <g>
    <foreignObject x={x - 240} y={y - 14} width={200} height={200}>
      <div className="Label-block">
        {' '}
        {payload.value}
      </div>
    </foreignObject>
  </g>
);

const RenderCustomBarLabel = ({
  x, y, width, height, value, ...props
}) => {
  const xAxis = value > 0 ? (x + width + 20) : (x);
  const yAxis = y + 9;
  return (
    <div
      x={xAxis}
      y={yAxis}
      fill="#25253B"
      height={25}
    >
      {props.data.map((item) => (item.name === props.name ? item.um : null))}
    </div>
  );
};

RenderCustomBarLabel.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  value: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.object),
  name: PropTypes.string,
};

RenderCustomBarLabel.defaultProps = {
  x: undefined,
  y: undefined,
  width: undefined,
  height: undefined,
  value: undefined,
  data: undefined,
  name: undefined,
};

const CustomTooltip = ({ payload, active, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${label}: ${payload[0].value}%`}</p>
      </div>
    );
  }

  return null;
};

CustomTooltip.propTypes = {
  payload: PropTypes.arrayOf(PropTypes.any).isRequired,
  active: PropTypes.bool,
  label: PropTypes.string.isRequired,
};

CustomTooltip.defaultProps = {
  active: undefined,
};

export const GraphicItem = ({ data }) => {
  const [max, setMax] = useState(0);
  const [axis, setAxis] = useState([]);
  const [newData, setNewData] = useState([]);
  let i = -1;
  useEffect(() => {
    let t = max;
    newData.forEach((el) => {
      if (el.pv > t) t = el.pv;
    });
    setMax(t);
  }, [newData]);
  useEffect(() => {
    let arr = [0, 3, 8, 20, 40];
    let i = 4;
    const newArr = [];
    let a;
    if (arr.every((el) => el + 4 < max)) {
      while (Math.pow(Math.E, i) < max) {
        a = Math.round(Math.pow(Math.E, i));
        if ((a - max > 4) || (max - a > 4)) {
          newArr.push(a);
        }
        i++;
      }
    } else {
      arr.forEach((el) => {
        arr = [];
        if (max > el + 4) {
          newArr.push(el);
        }
        while (Math.pow(Math.E, i) < max) {
          a = Math.round(Math.pow(Math.E, i));
          if ((a - max > 4) || (max - a > 4)) {
            newArr.push(a);
          }
          i++;
        }
        arr.push(...newArr);
      });
    }
    arr.push(Math.round(max));
    setAxis(arr);
  }, [max]);
  useEffect(() => {
    if (data) {
      const totalCustomers = sum(data.map((item) => item.cus_num));
      const arr = [];
      let obj = {
        name: '',
        pv: '',
        um: '',
        color: '',
      };

      data.forEach((item) => {
        i = ++i;
        if (item.segment.segment_name !== SEGMENT_NAME.departed_customers) {
          obj = {
            name: item.segment.segment_name,
            pv: Math.ceil(item.cus_num * 100 / totalCustomers),
            um: typeof item.cus_num === 'number' ? NumberFormatted(item.cus_num) : 0,
            color: item.segment.color_1,
          };
          arr.push({ ...obj });
        }
      });
      setNewData(arr);
    }
  }, [data]);

  if (!newData || !newData.length) return null;

  return (
    <ResponsiveContainer
      width="100%"
      height={210}
    >
      <BarChart
        layout="vertical"
        data={newData}
        margin={{
          top: 5, right: 90, left: 10, bottom: 5,
        }}
      >
        <XAxis
          type="number"
          unit="%"
          ticks={axis}
          textAnchor="start"
          axisLine={false}
          interval={0}
        />
        <YAxis
          type="category"
          axisLine={false}
          tickLine={false}
          dataKey="name"
          width={240}
          height={40}
          tick={renderCustomAxisTick}
          interval={0}
        />
        <Tooltip
          cursor={{ fill: 'transparent' }}
          content={<CustomTooltip data={newData} />}
        />
        <Bar
          dataKey="pv"
          fill="red"
          barSize={10}
          radius={[50, 50, 50, 50]}
          label={<RenderCustomBarLabel data={newData} />}
        >
          {!!newData && !!newData.length && newData.map((i) => <Cell fill={i.color} />)}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

GraphicItem.propTypes = {
  data: PropTypes.arrayOf(PropTypes.objectOf([PropTypes.number, PropTypes.string, PropTypes.any])),
};
GraphicItem.defaultProps = {
  data: undefined,
};
