import { snakeCaseToTitle } from 'helper/textFormatters';
import { MESSAGES } from 'constants/messages';

const fieldsMapping = {
  clv: MESSAGES.predicted_yearly_value,
  avg_order_size: MESSAGES.order_value_avg,
  churn_prob: MESSAGES.probability_of_churn,
  days_since_last_order: MESSAGES.days_since_last_order,
  days_since_first_order: MESSAGES.days_since_first_order,
  total_products: MESSAGES.total_order_items,
  days_since_contacted_email: MESSAGES.days_since_last_contacted,
  propensity_to_buy: MESSAGES.propensity_to_buy,
  accepts_marketing: MESSAGES.customer_consent,
};

const operatorsMapping = {
  exact: '= Equals',
  lt: '< Under',
  gt: '> Over',
  icontains: '∋ Contains',
  noticontains: '∌ Not contains',
  top: '↑ Top',
  bottom: '↓ Bottom',
};

export const formatFilterLists = (lists) => {
  const fieldGroups = Object.keys(lists.fields).map((type) => {
    const values = lists.fields[type];
    return values.map((value) => ({
      value,
      type,
      label: snakeCaseToTitle(fieldsMapping[value] || value),
    }));
  });
  const fields = [].concat(...fieldGroups);

  const operators = Object.keys(lists.operators).reduce((result, type) => {
    const values = lists.operators[type];
    result[type] = values.map((value) => ({
      value,
      label: snakeCaseToTitle(operatorsMapping[value] || value),
    }));
    return result;
  }, {});

  return { fields, operators };
};
