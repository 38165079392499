import React from 'react';
import classNames from 'classnames';
import Loader from 'components/common/Loader';

import {
  BUTTON_TYPE,
} from 'constants/common';
import './styles.scss';
import { Stack } from '@mui/material';
import PropTypes from 'prop-types';

const Button = ({
  children,
  className,
  type,
  danger,
  isActive,
  isDisabled,
  isPreventSubmit,
  isRound,
  block,
  onClick,
  warning,
  ghost,
  isLoading,
  fillWidth,
  icon: Icon,
}, ref) => {
  const onClickHandler = (e) => {
    if (isPreventSubmit) {
      e.preventDefault();
    }

    if (typeof onClick === 'function') {
      onClick(e);
    }
  };

  if (!type) type = BUTTON_TYPE.primary;

  return (
    <button
      ref={ref}
      type={isPreventSubmit ? 'button' : 'submit'}
      className={classNames('button', className, {
        'button--primary': type === BUTTON_TYPE.primary,
        'button--secondary': type === BUTTON_TYPE.secondary,
        'button--link': type === BUTTON_TYPE.link,
        'button--danger': danger,
        'button--round': isRound,
        'button--block': block,
        'button--warning': warning,
        'button--ghost': ghost,
        'button--is-loading': isLoading,
        'button--is-active': isActive,
        'button--fill-width': fillWidth,
      })}
      onClick={onClickHandler}
      disabled={isDisabled}
    >
      <Stack
        spacing={1}
        direction="row"
        alignItems="center"
        className="button__content"
      >
        {!!Icon && (
          <Icon className="button__icon" />
        )}
        {!!children && (
          <div className="button__content__children">
            {children}
          </div>
        )}
      </Stack>
      {isLoading && <Loader color="white" />}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  type: PropTypes.string,
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isPreventSubmit: PropTypes.bool,
  isRound: PropTypes.bool,
  block: PropTypes.bool,
  onClick: PropTypes.func,
  danger: PropTypes.bool,
  warning: PropTypes.bool,
  ghost: PropTypes.bool,
  isLoading: PropTypes.bool,
  fillWidth: PropTypes.bool,
  icon: PropTypes.node,
};

Button.defaultProps = {
  className: null,
  type: null,
  isActive: false,
  isDisabled: false,
  danger: false,
  isRound: false,
  isPreventSubmit: false,
  block: false,
  onClick: undefined,
  warning: false,
  ghost: false,
  isLoading: false,
  fillWidth: false,
  icon: undefined,
};

export default React.forwardRef(Button);
