import { createSlice } from '@reduxjs/toolkit';

import moduleName from './constants';
import {
  getActiveIntegrationsSources as getActiveIntegrationsSourcesAPI,
  getAvailableIntegrationsSources,
} from '../../api/integrationsAPI';

const initialState = {
  user: '',
  uuid: '',
  license: {
    business_category: [],
    company_name: '',
    company_size: [],
    company_url: '',
    uuid: '',
    package: null,
    customer: null,
    avatar: '',
    bundle: null,
    integrations_count: 0,
    is_demo: false,
    is_payment_valid: false,
  },
  license_status: [],
  integrations: {
    activeIntegrationsList: [],
    availableIntegrationsList: [],
    isLoading: false,
    isLoaded: false,
    error: undefined,
  },
};

const licenseSlice = createSlice({
  name: moduleName,
  initialState,
  reducers: {
    setLicenseDetailsAction(state, action) {
      const {
        user,
        uuid,
        license,
        license_status,
        avatar,
      } = action.payload;

      state.user = user;
      state.uuid = uuid;
      state.license = license;
      state.license_status = license_status;
      state.avatar = avatar;
      state.integrations = initialState.integrations;
    },
    setLicenseAction(state, action) {
      state.license = action.payload;
    },
    setInvitedLicenseAction(state, action) {
      const {
        uuid, company_name, license_status, avatar,
      } = action.payload;
      state.uuid = uuid;
      state.license.company_name = company_name;
      state.license_status = license_status;
      state.license.avatar = avatar;
    },
    setCustomerAction(state, action) {
      state.license.customer = action.payload;
    },
    updateLicenseDetailsAction(state, action) {
      const {
        user,
        uuid,
        license,
        license_status,
        avatar,
      } = {
        ...state,
        ...action.payload,
      };

      state.user = user;
      state.uuid = uuid;
      state.license = license;
      state.license_status = license_status;
      state.avatar = avatar;
    },
    updateLicenseAction(state, action) {
      state.license = {
        ...state.license,
        ...action.payload,
      };
    },
    deleteLicenseAction: () => initialState,
    getIntegrationsListStart(state) {
      state.integrations.isLoading = true;
      state.integrations.isLoaded = false;
    },
    getIntegrationsListFinish(state) {
      state.integrations.isLoading = false;
      state.integrations.isLoaded = true;
    },
    getIntegrationsListError(state, action) {
      state.integrations.isLoading = false;
      state.integrations.isLoaded = false;
      state.integrations.error = action.payload.error;
    },
    getActiveIntegrationsListAction(state, action) {
      state.integrations.activeIntegrationsList = action.payload.map((item) => ({
        integration_status: item.integration_status,
        ...item.source,
      }));
    },
    getAvailableIntegrationsListAction(state, action) {
      state.integrations.availableIntegrationsList = action.payload;
    },
  },
});

export const {
  setLicenseDetailsAction,
  setLicenseAction,
  setInvitedLicenseAction,
  setCustomerAction,
  updateLicenseDetailsAction,
  updateLicenseAction,
  deleteLicenseAction,
  getIntegrationsListStart,
  getIntegrationsListFinish,
  getIntegrationsListError,
  getActiveIntegrationsListAction,
  getAvailableIntegrationsListAction,
} = licenseSlice.actions;

export const fetchActiveIntegrations = () => async (dispatch, getState) => {
  const { license_details } = getState();
  const { isLoading, isLoaded } = license_details.integrations;
  if (isLoading || isLoaded) return;
  try {
    dispatch(getIntegrationsListStart());
    const activeIntegrationsList = await getActiveIntegrationsSourcesAPI();
    dispatch(getActiveIntegrationsListAction(activeIntegrationsList));
    const availableIntegrationsList = await getAvailableIntegrationsSources();
    dispatch(getAvailableIntegrationsListAction(availableIntegrationsList));
  } catch (err) {
    dispatch(getIntegrationsListError({ error: err.message }));
  } finally {
    dispatch(getIntegrationsListFinish());
  }
};

export default licenseSlice.reducer;
