import React, { useEffect } from 'react';
import propTypes from 'prop-types';

import routesPropTypes from './types';
import { ROUTES } from 'constants/routes';
import useRouter from 'hooks/useRouter';
import { accessTokenLSManager } from 'helper/localStorage';
import useLogout from 'hooks/useLogout';
import ProtectedRoute from 'components/common/ProtectedRoutes';

const PrivateRoute = ({
  component: Component, redirectToFunc, hasPermission, ...rest
}) => {
  const { query } = useRouter();
  const accessToken = accessTokenLSManager.get();
  const logoutHandler = useLogout();

  useEffect(() => {
    if (!accessToken) {
      logoutHandler();
    }
  }, [accessToken]);

  const hasCustomPermissions = hasPermission === undefined ? true : hasPermission;

  return (
    <ProtectedRoute
      hasPermission={!!accessToken && hasCustomPermissions}
      component={Component}
      redirectToFunc={(props) => (!!redirectToFunc && redirectToFunc()) || ({
        pathname: query?.is_user_exist === 'true' ? ROUTES.signIn : ROUTES.signUp,
        search: props?.location?.search,
        state: { from: props.location },
      })}
      {...rest}
    />
  );
};

PrivateRoute.propTypes = {
  ...routesPropTypes,
  hasPermission: propTypes.bool,
  component: propTypes.func.isRequired,
  redirectToFunc: propTypes.func,
};

PrivateRoute.defaultProps = {
  hasPermission: undefined,
  redirectToFunc: undefined,
};

export default PrivateRoute;
