import React from 'react';

import './styles.scss';
import Block from 'components/common/Block';
import Billing from './Billing';
import { MESSAGES } from 'constants/messages';

const BillingDetails = () => (
  <div className="billing-details">

    <div className="billing-details__billing-wrapper">
      <Block
        header={MESSAGES.billing_details}
        postHeader={MESSAGES.update_billing_info}
      >
        <Billing />
      </Block>
    </div>
  </div>
);

export default BillingDetails;
