import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './styles.scss';

import { ReactComponent as ArrowIcon } from 'assets/svg/arrowUp.svg';

const NavigationButton = ({
  navigation, type, disabled, onClick,
}) => (
  <div
    className={classNames(
      'navigation-button',
      `navigation-button--${navigation}`,
      type,
      { 'navigation-button__disabled': disabled },
    )}
    onClick={onClick}
  >
    <ArrowIcon className="navigation-button__icon" />
  </div>
);

export default NavigationButton;

NavigationButton.propTypes = {
  type: PropTypes.oneOf(['primary', 'secondary']),
  navigation: PropTypes.oneOf(['prev', 'next']).isRequired,
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

NavigationButton.defaultProps = {
  type: 'secondary',
};
