import { useCallback, useEffect } from 'react';

import { accessTokenLSManager, userUuidLSManager } from 'helper/localStorage';
import { ROUTES } from 'constants/routes';
import useAsync from 'hooks/useAsync';
import { cleanUpPrivateStorage, verifyUserAuthorization } from 'utils/common';
import { useDispatch } from 'react-redux';
import useRouter from 'hooks/useRouter';
import { isUserSessionExpired } from 'helper/moment';
import { setCurrentUser } from 'store/profile/profileSlice';
import { setLicenseDetailsAction } from 'store/license/licenseSlice';
import { setPaymentMethodAction } from 'store/paymentMethod/paymentMethodSlice';

export default () => {
  const dispatch = useDispatch();
  const { replace, pathname, location } = useRouter();

  const setUser = useCallback(async () => {
    if (!userUuidLSManager.get()
        || !accessTokenLSManager.get()
        || isUserSessionExpired()
    ) {
      cleanUpPrivateStorage();
      return;
    }

    const {
      currentUser,
      currentUserLicense,
      currentPaymentMethod,
    } = await verifyUserAuthorization();

    if (!currentUser) {
      cleanUpPrivateStorage();
      return;
    }

    const isPrivateLocation = !pathname.includes(ROUTES.signIn)
      && !pathname.includes(ROUTES.signUp)
      && !pathname.includes(ROUTES.details)
      && !pathname.includes(ROUTES.payment)
      && !pathname.includes(ROUTES.restorePassword);

    dispatch(setCurrentUser(currentUser));
    if (currentUserLicense) {
      dispatch(setLicenseDetailsAction(currentUserLicense));
    }
    if (currentPaymentMethod) {
      dispatch(setPaymentMethodAction(currentPaymentMethod));
    }

    replace(isPrivateLocation
      ? location
      : ROUTES.home,
    location.state);
  }, []);

  const {
    execute: setCurrentUserAsync,
    status: currentUserStatus,
    error: currentUserErrors,
  } = useAsync(setUser);

  useEffect(() => {
    if (currentUserErrors) {
      cleanUpPrivateStorage();
    }
  }, [currentUserErrors]);

  return {
    setCurrentUserAsync,
    currentUserStatus,
    currentUserErrors,
  };
};
