import moduleName from 'store/crumbsIdToName/constants';

const getCrumbsIdToNameState = (state) => state[moduleName];

export const getAudienceNameFromId = (state) => {
  const { audiences_name } = getCrumbsIdToNameState(state);
  return audiences_name;
};

export const getCohortNameFromId = (state) => {
  const { cohorts_name } = getCrumbsIdToNameState(state);
  return cohorts_name;
};

export const getExperimentNameFromId = (state) => {
  const { experiments_name } = getCrumbsIdToNameState(state);
  return experiments_name;
};

export const getCustomerNameFromId = (state) => {
  const { customers_name } = getCrumbsIdToNameState(state);
  return customers_name;
};

export const getProductNameFromId = (state) => {
  const { products_name } = getCrumbsIdToNameState(state);
  return products_name;
};
