import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';

import 'components/views/Profile/PersonalBlock/styles.scss';
import { AVATAR_TYPE, BUTTON_TYPE } from 'constants/common';
import ViewMode from 'components/views/Profile/PersonalBlock/ViewMode';
import EditMode from 'components/views/Profile/PersonalBlock/EditMode';
import { useSelector } from 'react-redux';
import Button from 'components/common/Button';
import { getCurrentUser } from 'store/profile/getters';
import Avatar from 'components/common/Avatar';
import { ReactComponent as DownloadIcon } from 'assets/svg/dowmnoad.svg';
import CropperImage from 'components/views/Profile/CropperImage';
import classNames from 'classnames';
import { IMAGE_PATTERN } from 'constants/validation';
import { MESSAGES } from 'constants/messages';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/material';

const MAX_FILE_SIZE = 5242880;

const PersonalBlock = ({ editable }) => {
  const {
    first_name: firstName,
    last_name: lastName,
    role: workRole,
    email: workEmail,
    avatar: avatarImg,
  } = useSelector(getCurrentUser);

  const [isEdit, setIsEdit] = useState(false);
  const [avatar, setAvatar] = useState({
    avatar: {
      show: avatarImg,
    },
  });
  const [cropAvatar, setCropAvatar] = useState(false);
  const licenceProps = {
    workEmail,
    workRole,
    lastName,
    firstName,
  };

  const [img, setImg] = useState('');
  const [errorUpload, setErrorUpload] = useState('');
  const [isHint, setIsHint] = useState(false);
  const onEditModeSetHandler = () => setIsEdit(true);
  const onEditModeCancelHandler = () => {
    setIsEdit(false);
    setImg('');
    setErrorUpload('');
  };

  useEffect(() => {
    setAvatar({
      avatar: {
        show: avatarImg,
      },
    });
  }, [isEdit]);

  const uploadAvatar = (event) => {
    const { files, value } = event.target;

    if (!files || files.length < 1) return;
    if (files[0].size > MAX_FILE_SIZE) {
      setErrorUpload(MESSAGES.attachment_is_too_big);
      return;
    }
    if (!IMAGE_PATTERN.test(value)) {
      setErrorUpload(MESSAGES.attachment_has_wrong_format);
      return;
    }

    setErrorUpload('');
    const reader = new FileReader();
    reader.onload = (e) => {
      setAvatar({
        avatar: {
          avatarToCrop: e.target.result,
        },
        changed: true,
      });
      setImg(e.target.value);
      setCropAvatar(true);
    };
    reader.readAsDataURL(files[0]);
  };

  const deleteAvatar = () => {
    setAvatar({
      avatar: {
        show: '',
      },
    });
    setImg('');
  };

  const closeAvatarCropper = () => {
    setCropAvatar(false);
    setImg('');
  };

  const croppedAvatar = (show, file) => {
    setCropAvatar(false);
    setAvatar({
      avatar: {
        show,
        file,
      },
    });
  };

  return (
    <>
      <div className="personal-block">
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <div className="personal-block__avatar">
              {(!!avatar && !!avatar.avatar && !!avatar.avatar.show) ? (
                <div className="new-avatar">
                  {' '}
                  <img src={avatar.avatar.show} alt="avatar" />
                </div>
              ) : <Avatar type={AVATAR_TYPE.large} />}

              {isEdit && (
              <div className={classNames('upload-block', {
                error: !!errorUpload,
              })}
              >
                {' '}
                <DownloadIcon />
                {!!errorUpload && (
                <div className={classNames('hint', { errorHint: isHint })}>
                  <span>
                    {errorUpload}
                  </span>
                </div>
                )}
                <input
                  type="file"
                  title=""
                  className="file-input"
                  value={img}
                  accept="image/png, image/jpeg, image/jpg,"
                  onChange={uploadAvatar}
                  onMouseOver={() => setIsHint(true)}
                  onMouseOut={() => setIsHint(false)}
                />
              </div>
              )}
              <p className="profile__avatar-formats">
                {MESSAGES.image_formats}
              </p>
              <p className="profile__avatar-size">
                {MESSAGES.image_max_size}
              </p>
              {!!avatar && !!avatar.avatar && !!avatar.avatar.show && isEdit && (
                <div className="remove-avatar" onClick={deleteAvatar}>
                  {MESSAGES.remove_photo}
                </div>
              )}
            </div>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            display="flex"
            alignItems="center"
          >
            {isEdit ? (
              <EditMode
                cancelEditMode={onEditModeCancelHandler}
                {...licenceProps}
                avatar={avatar}
              />
            ) : (
              <ViewMode {...licenceProps} />
            )}
          </Grid>
          {editable && !isEdit && (
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  onClick={onEditModeSetHandler}
                  block
                  type={BUTTON_TYPE.primary}
                >
                  {MESSAGES.edit}
                </Button>
              </Box>
            </Grid>
          )}
        </Grid>
      </div>
      <CropperImage
        open={cropAvatar}
        avatar={(!!avatar && !!avatar.avatar) ? avatar.avatar.avatarToCrop : null}
        cropAvatar={croppedAvatar}
        closeAvatarCropper={closeAvatarCropper}
        uploadAvatar={uploadAvatar}
      />
    </>
  );
};

PersonalBlock.propTypes = {
  editable: propTypes.bool,
};

PersonalBlock.defaultProps = {
  editable: false,
};

export default PersonalBlock;
