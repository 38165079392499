import {
  PASSWORD_PATTERN,
} from 'constants/validation';
import {
  ValidationError,
  PASSWORD_ERROR_REQUIRED,
  PASSWORD_ERROR_PATTERN,
  CONFIRM_PASSWORD_ERROR_REQUIRED,
  CONFIRM_PASSWORD_ERROR_NOT_MATCH,
} from 'constants/errors';
import Validator from 'utils/validator';

export default ({ password, confirmPassword }) => {
  const errors = {};

  const passwordValidator = new Validator(password);
  const confirmPasswordValidator = new Validator(confirmPassword);

  try {
    passwordValidator
      .required(PASSWORD_ERROR_REQUIRED)
      .match(PASSWORD_PATTERN, PASSWORD_ERROR_PATTERN);
  } catch (error) {
    errors.password = error.message;
  }

  try {
    confirmPasswordValidator
      .required(CONFIRM_PASSWORD_ERROR_REQUIRED)
      .additional((validator) => {
        if (validator.field !== password) {
          throw new ValidationError(CONFIRM_PASSWORD_ERROR_NOT_MATCH);
        }

        return validator;
      });
  } catch (error) {
    errors.confirmPassword = error.message;
  }

  return errors;
};
