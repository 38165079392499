export const HTTP_STATUS = {
  unauthorized: 401,
  noContent: 204,
  forbidden: 403,
  internal: 500,
};

export const HTTP_METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
};
