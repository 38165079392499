import { createSlice } from '@reduxjs/toolkit';

import moduleName from './constants';
import { getCustomersList, getProductRecommendations } from 'api/customersAPI';

import { AUDIENCE_TYPES } from 'constants/customAudience';
import axios from 'axios';

const initialState = {
  customerListData: {
    count: 0,
    next: '',
    previous: '',
    customerList: [],
  },
  product_recommendations: [],
  isProductRecommendationsLoading: false,
  segments_count: [],
  isLoading: false,
  isLoaded: false,
  error: undefined,
};

const customerListSlice = createSlice({
  name: moduleName,
  initialState,
  reducers: {
    setCustomerListStart(state) {
      state.isLoading = true;
      state.isLoaded = false;
    },
    setCustomerListFinish(state) {
      state.isLoading = false;
      state.isLoaded = true;
    },
    setCustomerListError(state, action) {
      const { error } = action.payload;
      state.isLoading = false;
      state.isLoaded = false;
      state.error = error;
    },
    setCustomerListAction(state, action) {
      const {
        count, next, previous, results,
      } = action.payload;

      state.customerListData.count = count;
      state.customerListData.next = next;
      state.customerListData.previous = previous;
      state.customerListData.customerList = results;
    },
    setProductRecommendationsAction(state, action) {
      state.product_recommendations = action.payload;
    },
    setProductRecommendationsLoadingAction(state, action) {
      state.isProductRecommendationsLoading = action.payload;
    },
    setSegmentsCountAction(state, action) {
      const { segments_count } = action.payload;
      state.segments_count = segments_count;
    },
  },
});

export default customerListSlice.reducer;
export const {
  setCustomerListStart,
  setCustomerListFinish,
  setCustomerListError,
  setCustomerListAction,
  setProductRecommendationsAction,
  setProductRecommendationsLoadingAction,
  setSegmentsCountAction,
} = customerListSlice.actions;


let cancel;
const { CancelToken, isCancel } = axios;
export const fetchCustomerList = ({
  page, ordering, type = AUDIENCE_TYPES.segment, segment, tags = [], ...filters
}) => async (dispatch) => {
  try {
    if (cancel) cancel();
    const cancelTokenFunc = new CancelToken((c) => {
      cancel = c;
    });

    dispatch(setCustomerListStart());
    const result = await getCustomersList({
      page, ordering, type, segment, tags, filters, cancelToken: cancelTokenFunc,
    });

    cancel = undefined;
    if (result) {
      dispatch(setCustomerListAction(result));
      dispatch(setSegmentsCountAction(result));
    }
  } catch (err) {
    if (isCancel(err)) return;
    dispatch(setCustomerListError({ error: err.message }));
  } finally {
    if (!cancel) {
      dispatch(setCustomerListFinish());
    }
  }
};

export const fetchProductRecommendations = ({ segment = [], tags = [] }) => async (dispatch) => {
  dispatch(setProductRecommendationsLoadingAction(true));
  const result = await getProductRecommendations(segment, tags);
  dispatch(setProductRecommendationsAction(result));
  dispatch(setProductRecommendationsLoadingAction(false));
};
