import { useEffect, useState } from 'react';
import { isEqual, keys } from 'lodash';

export default (tableConfig, defaultColumnSelection, columnsManager) => {
  const getColumnsSelection = () => {
    const cached = columnsManager.get();
    if (cached && isEqual(keys(cached), keys(defaultColumnSelection))) return cached;
    return defaultColumnSelection;
  };

  const [isChecked, setIsChecked] = useState(getColumnsSelection);

  const filteredTableConfig = tableConfig.filter((item) => isChecked[item.key]);
  const resetColumns = () => setIsChecked(defaultColumnSelection);

  useEffect(() => {
    columnsManager.set(isChecked);
  }, [isChecked]);

  return {
    isChecked,
    setIsChecked,
    filteredTableConfig,
    resetColumns,
  };
};