import { configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import rootReducer from 'store/rootReducer';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const store = configureStore({
  reducer: rootReducer,
  enhancers: [sentryReduxEnhancer],
});

export default store;
