import React, { useEffect, useState } from 'react';
import { Bar, BarChart, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import 'components/views/SingleSegments/MostPopularCustomerSourceGraphic/style.scss'
import DataWithPercentage from "components/views/SingleSegments/DataWithPercentage";
import { max as calculateMax } from 'lodash';
import { getCustomerSourceImage } from 'utils/assets';


const RenderCustomAxisTick = ({ x, y, payload, data }) => {
  return (
    <g>
      <foreignObject x={x - 200} y={y - 13} width={190} height={200}>
        <div className="label-block">
          <div className="label-title"> {payload.value}</div>
          {data.map((item) => {
            if (payload.value === item.name) {
              return <img src={item.icon}/>
            }
          })}
        </div>
      </foreignObject>
    </g>
  );
};

const RenderCustomBarLabel = ({ payload, x, y, width, height, value, ...props }) => {
  return (
    <g>
      <foreignObject x={x + width + 20} y={y - 13} width={250} height={100}>
        <div className="bar-label">
          {props.data.map((item) => {
            if (item.name === props.name) {
              return <DataWithPercentage percentage={item.diff}>{value}</DataWithPercentage>
            }
          })}
        </div>
      </foreignObject>
    </g>
  )
};

const CustomTooltip = ({payload, active, ...props}) => {
  if (!!payload && !!payload.length) {
    props.setCoordinate(232 + payload[0].payload.pv * 410 / props.max);
  }
  if (active) {
    return (
      <div className="custom-tooltip">
        {!!payload && !!payload.length && <p className="label">{payload[0].payload.pv}%</p>}
      </div>
    );
  }

  return null;
}


export const MostPopularCustomerSourceGraphic = (props) => {
  const [max, setMax] = useState(0);
  const [axis, setAxis] = useState([]);
  const [newData, setNewData] = useState([]);
  const [coordinateX, setCoordinateX] = useState(0);
  useEffect(() => {
    const newMax = calculateMax(newData.map((el) => el.pv)) || 0;
    setMax(Math.round(newMax));
  }, [newData])
  useEffect(() => {
    let arr = [0, 3, 8, 20, 40];
    let i = 4;
    let newArr = []
    let a;
    if (arr.every(el => el + 4 < max)) {
      while (Math.pow(Math.E, i) < max) {
        a = Math.round(Math.pow(Math.E, i));
        if ((a - max > 4) || (max - a > 4)) {
          newArr.push(a)
        }
        i++
      }
    } else {
      arr.forEach((el) => {
        arr = []
        if (max > el + 4) {
          newArr.push(el)
        }
        while (Math.pow(Math.E, i) < max) {
          a = Math.round(Math.pow(Math.E, i));
          if ((a - max > 4) || (max - a > 4)) {
            newArr.push(a)
          }
          i++
        }
        arr.push(...newArr)
      })
    }
    arr.push(Math.round(max));
    setAxis(arr);
  }, [max])
  useEffect(() => {
    if (!!props.data) {
      let arr = [];
      let obj = {};
      let i = -1;
      props.data.forEach((item) => {
        i = ++i;
        obj = {
          "name": item.source_name,
          "pv": item.customer_source_percent < 1 ? Math.ceil(item.customer_source_percent) : item.customer_source_percent,
          "diff": item.customer_source_percent_last_month_percentage,
          "color": '#D7D7D7',
          "icon": getCustomerSourceImage(item.source_name),
        }

        arr.push({...obj})
      })
      setNewData(arr)
    }
  }, [props.data])
  return (
    <div className="mpcs-container">
      <ResponsiveContainer height={270} width="80%">
        <BarChart
          layout="vertical"
          data={newData}
          margin={{
            top: 33, right: 270, left: 40, bottom: 5,
          }}
        >
          <XAxis
            type="number"
            unit="%"
            ticks={axis}
            textAnchor="start"
            axisLine={false}
            interval={0}
          />} />
          <YAxis type="category" axisLine={false} tickLine={false} dataKey="name" width={210}
                 tick={<RenderCustomAxisTick data={newData}/>}/>
          <Bar dataKey="pv" fill={'black'} barSize={10} radius={[50, 50, 50, 50]}
               label={<RenderCustomBarLabel data={newData}/>}>
            {!!newData && !!newData.length && newData.map(i => <Cell fill={i.color}/>)}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
};
