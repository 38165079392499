import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { getLicenseDetails } from 'store/license/getters';
import Input from 'components/common/Input';
import Select from 'components/common/Select';
import Button from 'components/common/Button';
import validationPropTypes from '../types';
import { BUTTON_TYPE, REQUEST_STATUS } from 'constants/common';
import { ENDPOINTS } from 'constants/routes';
import useOptions from 'hooks/useOptions';
import { MESSAGES } from 'constants/messages';
import { size } from 'lodash';
import Grid from '@mui/material/Grid';
import useLogout from 'hooks/useLogout';
import { LinkButton } from 'components/common/LinkButton';
import { Box } from '@mui/material';
import Block from 'components/common/Block';

const PersonalDetails = ({
  handleSubmit,
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
  status,
}) => {
  document.title = `${MESSAGES.personal_information} - ${MESSAGES.custimy}`;
  const logoutHandler = useLogout();
  const { options, status: rolesStatus } = useOptions(ENDPOINTS.workRoles, 'roles');

  const {
    license: invitedLicense,
    license_status: licenseStatus,
  } = useSelector(getLicenseDetails);
  const isLicenseMember = licenseStatus?.length && licenseStatus[1] === 'Member';

  return (
    <div className="container">
      <Grid
        item
        xs={12}
        sx={{
          margin: { lg: 'auto 65px auto 225px', md: 'auto 65px auto 25px', xs: 'auto' },
          minWidth: { sm: '400px', xs: '100%' },
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div className="container__main">
          <Block>
            <main className="main">
              {invitedLicense && isLicenseMember ? (
                <>
                  <h1 className="main__title-other title-centered">
                    {MESSAGES.you_ve_been_invited_to_join_a_license}
                  </h1>
                  <p className="main__subtitle-other subtitle-centered">
                    {MESSAGES.please_create_a_password_to_accept_the_invitation}
                  </p>
                </>
              ) : (
                <h1 className="main__title-create-license">
                  {MESSAGES.enter_your_details}
                </h1>
              )}
              <form
                className={classNames('main__form', 'form', { 'form-top-margin': !isLicenseMember })}
                onSubmit={handleSubmit}
                noValidate
              >
                <div className="form__field">
                  <Input
                    id="firstName"
                    name="firstName"
                    label={MESSAGES.first_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="given-name"
                    value={values.firstName}
                    error={errors.firstName}
                    touched={touched.firstName}
                    isRequired
                    hasFeedback
                  />
                </div>
                <div className="form__field">
                  <Input
                    id="lastName"
                    name="lastName"
                    label={MESSAGES.last_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastName}
                    error={errors.lastName}
                    touched={touched.lastName}
                    autoComplete="family-name"
                    isRequired
                    hasFeedback
                  />
                </div>
                <div className="form__field form__field--last">
                  <Select
                    name="workRole"
                    label={MESSAGES.work_role}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={options}
                    error={errors.workRole}
                    touched={touched.workRole}
                    isLoading={rolesStatus === REQUEST_STATUS.loading}
                    isRequired
                    hasFeedback
                  />
                </div>
                <div className="form__actions">
                  <div className={classNames('form__actions__submit-sign-up', {
                    'submit-success': !size(errors) && values?.lastName?.length,
                  })}
                  >
                    <Button
                      type={BUTTON_TYPE.primary}
                      isDisabled={status === REQUEST_STATUS.loading}
                      fillWidth
                    >
                      {MESSAGES.continue}
                    </Button>
                  </div>
                </div>
                <div className="form__actions">
                  <Box display="flex" justifyContent="center">
                    <LinkButton
                      size="medium"
                      onClick={logoutHandler}
                    >
                      {MESSAGES.log_out}
                    </LinkButton>
                  </Box>
                </div>
              </form>
            </main>
          </Block>
        </div>
      </Grid>
    </div>
  );
};

PersonalDetails.propTypes = validationPropTypes;

export default PersonalDetails;
