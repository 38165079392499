import React, { useMemo, useState } from 'react';

import './styles.scss';
import classNames from 'classnames';
import { ReactComponent as LockIcon } from 'assets/svg/lock.svg';
import { ReactComponent as AttentionIcon } from 'assets/svg/attention.svg';
import Feedback from 'components/common/Input/Feedback';
import {
  defaultStripeElementProps,
  stripeElementPropTypes,
} from 'containers/PrivateApp/Payment/PaymentForm/StripeElementInput/stripeElementPropTypes';
import CVCFeedback from 'containers/PrivateApp/Payment/PaymentForm/StripeElementInput/CVCFeedback';

const StripeElementInput = ({
  id,
  name,
  label,
  placeholder,
  touched,
  error,
  isRequired,
  hasLock,
  hasHelp,
  hasFeedback,
  onChange,
  onBlur,
  children,
}) => {
  const [isActive, setIsActive] = useState(false);

  const options = useMemo(() => ({
    placeholder,
    style: {
      base: {
        fontSize: '16px',
        color: 'white',
        fontFamily: 'Work Sans Source Code Pro, monospace',
        lineHeight: '19px',
        '::placeholder': {
          color: 'white',
        },
      },
      invalid: {
        color: 'white',
      },
      empty: {
        color: 'white',
      },
      complete: {
        color: 'white',
      }
    },
  }), []);

  const onFocusHandler = () => setIsActive(true);
  const onBlurHandler = () => {
    const target = { name };
    setIsActive(false);
    onBlur({ target });
  };
  const onChangeHandler = (cardNumberState) => {
    const target = {
      name,
      value: cardNumberState,
    };

    onChange({ target });
  };

  return (
    <div className={classNames('card-number', {
      'card-number--focus': isActive,
      'payment-form__field--required': isRequired,
      'card-number--error field--error': touched && error,
      'field--has-icon': hasFeedback || hasLock || hasHelp,
    })}
    >
      <label className="card-number__label payment-form__label" htmlFor={id}>
        {label}
      </label>
      <div className="card-number__input">
        {
          React.cloneElement(children, {
            id,
            options,
            onFocus: onFocusHandler,
            onBlur: onBlurHandler,
            onChange: onChangeHandler,
          })
        }
        {hasFeedback && touched && <AttentionIcon className="card-number__feedback-icon field__feedback" />}
        {hasLock && <LockIcon className="card-number__lock-icon" />}
        {hasHelp && <CVCFeedback />}
        {hasFeedback && touched && error && (
          <div className="field__hint">
            <Feedback hint={error} />
          </div>
        )}
      </div>
    </div>
  );
};

StripeElementInput.propTypes = stripeElementPropTypes;

StripeElementInput.defaultProps = defaultStripeElementProps;

export default StripeElementInput;
