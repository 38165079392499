import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom';

import { ROUTES } from 'constants/routes';
import ProvideEmail from './ProvideEmail';
import ResetLink from './ResetLink';

const RestorePassword = () => (
  <main className="container">
    <Switch>
      <Route path={ROUTES.restorePassword} component={ProvideEmail} exact />
      <Route path={ROUTES.restorePasswordLink} component={ResetLink} />
    </Switch>
  </main>
);

export default RestorePassword;
