import React, { useEffect, useState } from 'react';
import { CardBlock } from 'components/views/Segments/CardBlock';
import 'components/views/Segments/style.scss';
import { GraphicItem } from 'components/views/Segments/GraphicBlock';
import { getSegmentByName, getSegmentsData } from 'store/segments/getters';
import { useSelector } from 'react-redux';
import {
  ALL_SOURCES_PULLING_ERR_OR_PULLING,
  FIRST_PULLING,
  NO_ACTIVE_INTEGRATION,
  OK_STATUS,
  OK_PARTIAL,
} from 'constants/status';
import NoIntegrationState from 'components/views/Homepage/EmptyStates/NoIntegrationState';
import NoDataState from 'components/views/Homepage/EmptyStates/NoDataState';
import MainPullingDataState from 'components/views/Homepage/EmptyStates/MainPullingDataState';
import ContentLoader from 'components/common/ContentLoader';
import { MESSAGES } from 'constants/messages';
import { SEGMENT_NAME } from 'constants/segmentNames';
import { Grid } from '@mui/material';
import { GUIDES, SEGMENT_GUIDE_LIST } from 'constants/guides';
import { ItemTemplate } from 'components/views/Segments/ItemTemplate';
import Block from 'components/common/Block';
import { getUserStatus } from 'api/usersAPI';
import Disclaimer from 'components/common/Disclaimer';

const initialState = {
  activeCustomer: false,
  activeOrderSize: false,
  activeAvgOrders: false,
  activeAvgProfit: false,
};

const Segments = () => {
  const [active, setActive] = useState({ ...initialState });
  const segmentsData = useSelector(getSegmentsData);
  const departedSegment = useSelector(getSegmentByName(SEGMENT_NAME.departed_customers));

  const updateState = (field, value) => {
    setActive({
      ...initialState,
      [field]: value,
    });
  };
  const [status, setStatus] = useState('');

  useEffect(() => {
    const populateUserStatus = async () => {
      const response = await getUserStatus();
      setStatus(response.status);
    };

    populateUserStatus();
  }, []);

  return (
    <div className="segments-main-container">
      <ContentLoader isLoading={!status}>
        {!!status && status === NO_ACTIVE_INTEGRATION && <NoIntegrationState />}
        {!!status && status === FIRST_PULLING && <MainPullingDataState /> }
        {!!status && status === ALL_SOURCES_PULLING_ERR_OR_PULLING && <NoDataState />}
        {!!status && (status === OK_STATUS || status === OK_PARTIAL) && (
          <div className="segments-container">
            {status === OK_PARTIAL && (
              <Disclaimer
                type="warning"
                text={MESSAGES.data_is_still_pulled}
              />
            )}
            <Grid container columnSpacing={1} rowSpacing={0}>
              <Grid item lg={4} xs={12}>
                <Block
                  fillHeight
                  className="info-container"
                  header={MESSAGES.segments}
                  postHeader={MESSAGES.shows_distribution_of_customers}
                  guide={GUIDES.SO1}
                  type="large-header"
                >
                  <div className="data-container">
                    <ItemTemplate
                      title={MESSAGES.total_customers}
                      percentage={segmentsData.total_customers_last_month_percentage}
                      data={segmentsData.total_customers}
                      className="total_customer"
                    />
                    <ItemTemplate
                      title={MESSAGES.total_departed}
                      percentage={departedSegment?.cus_num_last_month_percentage}
                      data={departedSegment?.cus_num}
                      className="total_departed"
                    />
                  </div>
                </Block>
              </Grid>
              <Grid item lg={8} xs={12}>
                <div className="graphic-wrapper">
                  <Block
                    header={MESSAGES.segment_customers}
                    className="graphic-container"
                    guide={GUIDES.SO2}
                  >
                    <GraphicItem data={segmentsData.licence_segments} />
                  </Block>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {!!segmentsData.licence_segments && segmentsData.licence_segments
                .filter((item) => item.segment.segment_name !== SEGMENT_NAME.departed_customers)
                .map((item, key) => (
                  <Grid item xl={3} lg={4} md={6} xs={12}>
                    <CardBlock
                      key={item.id}
                      data={item}
                      active={active}
                      updateState={updateState}
                      guide={GUIDES[SEGMENT_GUIDE_LIST[key]]}
                    />
                  </Grid>
                ))}
            </Grid>
          </div>
        )}
      </ContentLoader>
    </div>
  );
};

export default Segments;
