import {
  FIRST_NAME_MAX_LENGTH,
  FIRST_NAME_MIN_LENGTH,
  LAST_NAME_MIN_LENGTH,
  LAST_NAME_MAX_LENGTH,
  COMPANY_NAME_MIN_LENGTH,
  COMPANY_NAME_MAX_LENGTH,
  COMPANY_URL_MIN_LENGTH,
  COMPANY_URL_MAX_LENGTH, EMAIL_MIN_LENGTH, EMAIL_MAX_LENGTH,
} from 'constants/validation';

export class ValidationError {
  constructor(error) {
    this.name = 'ValidationError';
    this.message = {
      header: typeof (error) === 'string' ? error : error.header,
      body: error?.body,
    };
  }
}

const requiredErrorMessageTemplate = (filed) => `Please provide ${filed}`;
const invalidErrorMessageTemplate = (filed) => `Please provide a valid ${filed}`;

const requiredErrorCommonMessageTemplate = (filed) => `Please provide a valid ${filed}`;
const invalidErrorCommonMessageTemplate = (filed) => `Not a valid ${filed.toLowerCase()}`;

const invalidErrorCustomCompanyURLMessage = (filed) => `Not a valid ${filed}. Example: www.yourcompany.com`;

export const EMAIL_ERROR_REQUIRED = requiredErrorCommonMessageTemplate('Email');
export const EMAIL_ERROR_MIN_LENGTH = requiredErrorCommonMessageTemplate(`Email: min ${EMAIL_MIN_LENGTH} characters`);
export const EMAIL_ERROR_MAX_LENGTH = requiredErrorCommonMessageTemplate(`Email: maximum ${EMAIL_MAX_LENGTH} characters`);
export const EMAIL_ERROR_PATTERN = invalidErrorCommonMessageTemplate('Email Address');

export const PASSWORD_ERROR_REQUIRED = requiredErrorCommonMessageTemplate('Password');
export const OLD_PASSWORD_ERROR_REQUIRED = requiredErrorMessageTemplate('Old Password');
export const NEW_PASSWORD_ERROR_REQUIRED = requiredErrorMessageTemplate('New Password');
export const PASSWORD_ERROR_PATTERN = {
  header: 'Not a strong enough password',
};

export const CONFIRM_PASSWORD_ERROR_REQUIRED = requiredErrorMessageTemplate('Confirm Password');
export const CONFIRM_PASSWORD_ERROR_NOT_MATCH = 'Your passwords does not match';

export const USER_NAME_ERROR_REQUIRED = requiredErrorMessageTemplate('User Name');
export const USER_NAME_ERROR_NOT_MATCH = 'The User Name does not match the name of the User';

export const FIRST_NAME_ERROR_REQUIRED = requiredErrorMessageTemplate('First Name');
export const FIRST_NAME_ERROR_MIN_LENGTH = invalidErrorMessageTemplate(`First Name: min ${FIRST_NAME_MIN_LENGTH} characters`);
export const FIRST_NAME_ERROR_MAX_LENGTH = invalidErrorMessageTemplate(`First Name: maximum ${FIRST_NAME_MAX_LENGTH} characters`);

export const LAST_NAME_ERROR_REQUIRED = requiredErrorMessageTemplate('Last Name');
export const LAST_NAME_ERROR_MIN_LENGTH = invalidErrorMessageTemplate(`Last Name: min ${LAST_NAME_MIN_LENGTH} characters`);
export const LAST_NAME_ERROR_MAX_LENGTH = invalidErrorMessageTemplate(`Last Name: maximum ${LAST_NAME_MAX_LENGTH} characters`);

export const WORK_ROLE_ERROR_REQUIRED = requiredErrorMessageTemplate('Work Role');

export const COMPANY_NAME_ERROR_REQUIRED = requiredErrorMessageTemplate('Company Name');
export const COMPANY_URL_ERROR_REQUIRED = requiredErrorMessageTemplate('Company URL');
export const COMPANY_NAME_ERROR_NOT_MATCH = 'The Company Name does not match the company name of the License';
export const COMPANY_NAME_ERROR_MIN_LENGTH = invalidErrorMessageTemplate(`Company Name: min ${COMPANY_NAME_MIN_LENGTH} characters`);
export const COMPANY_NAME_ERROR_MAX_LENGTH = invalidErrorMessageTemplate(`Company Name: maximum ${COMPANY_NAME_MAX_LENGTH} characters`);

export const BUSINESS_CATEGORY_ERROR_REQUIRED = requiredErrorMessageTemplate('Business Category');

export const COMPANY_URL_ERROR_MIN_LENGTH = invalidErrorMessageTemplate(`Company URL: min ${COMPANY_URL_MIN_LENGTH} characters`);
export const COMPANY_URL_ERROR_MAX_LENGTH = invalidErrorMessageTemplate(`Company URL: maximum ${COMPANY_URL_MAX_LENGTH} characters`);
export const COMPANY_URL_ERROR_PATTERN = invalidErrorCustomCompanyURLMessage('URL');

export const COMPANY_SIZE_ERROR_REQUIRED = requiredErrorMessageTemplate('Company Size');

export const RESTORE_PASSWORD_LINK_EXPIRED = 'This link has expired. Make sure you reset your password within 24 hours.';
export const RESTORE_PASSWORD_RESTORED = 'Your password has been updated.';

export const CARD_NUMBER_ERROR_REQUIRED = requiredErrorMessageTemplate('Card Number');
export const CARD_NUMBER_ERROR_UNAVAILABLE = 'Please provide an available card brand';
export const CARD_EXPIRY_NUMBER_ERROR_REQUIRED = requiredErrorMessageTemplate('Expiration Date');
export const CARD_CVC_NUMBER_ERROR_REQUIRED = requiredErrorMessageTemplate('CVC');
export const PROMOTION_CODE_ERROR_REQUIRED = requiredErrorMessageTemplate('Coupon Code');

export const VALID_SHOP_URL_REQUIRED = 'The shop URL provided was invalid';

export const TITLE_ERROR_REQUIRED = requiredErrorMessageTemplate('Title');
export const NAME_ERROR_REQUIRED = requiredErrorMessageTemplate('Name');
export const DATE_FROM_ERROR_REQUIRED = requiredErrorMessageTemplate('Date from');
export const DATE_TO_ERROR_REQUIRED = requiredErrorMessageTemplate('Date to');
export const DISPLAY_METRIC_ERROR_REQUIRED = requiredErrorMessageTemplate('Display metric');
export const MAX_MONTHS_ERROR_REQUIRED = requiredErrorMessageTemplate('max months');

export const CODE_ERROR_REQUIRED = requiredErrorMessageTemplate('Code');
export const MAX_LICENSES_ERROR_REQUIRED = requiredErrorMessageTemplate('Max Licenses');

export const AUDIENCE_NAME_ERROR_REQUIRED = requiredErrorMessageTemplate('Custom Audience Name');
export const AUDIENCE_INTEGRATION_CHANNELS_ERROR_REQUIRED = requiredErrorMessageTemplate('integration channels');
export const AUDIENCE_PRODUCTS_ERROR_REQUIRED = requiredErrorMessageTemplate('products');
export const AUDIENCE_CATEGORY_ERROR_REQUIRED = requiredErrorMessageTemplate('category');

export const INTERNAL_SERVER_ERROR_TEXT = 'Something went wrong, please try again later.';

export const EXPERIMENT_GROUPS_ERROR_REQUIRED = requiredErrorMessageTemplate('experiment groups');
