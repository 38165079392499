import { WEB_STORAGE } from 'constants/common';

const silentJSONParse = (str) => {
  try {
    return JSON.parse(str);
  } catch(e) {
    return str;
  }
};

const getLSManager = (key) => ({
  get: () => silentJSONParse(localStorage.getItem(key)),
  set: (item) => localStorage.setItem(key, JSON.stringify(item)),
  remove: () => localStorage.removeItem(key),
});

// local storage managers
export const userUuidLSManager = getLSManager(WEB_STORAGE.uuid);
export const accessTokenLSManager = getLSManager(WEB_STORAGE.accessToken);
export const refreshTokenLSManager = getLSManager(WEB_STORAGE.refreshToken);
export const oauthStateLSManager = getLSManager(WEB_STORAGE.oauthState);
export const expiresAtLSManager = getLSManager(WEB_STORAGE.expiresAt);
export const sidebarExpandedLSManager = getLSManager(WEB_STORAGE.sidebarExpanded);
export const productColumnsLSManager = getLSManager(WEB_STORAGE.productColumns);
export const productCategoryColumnsLSManager = getLSManager(WEB_STORAGE.productCategoryColumns);
