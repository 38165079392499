import React from 'react';
import { useDispatch } from 'react-redux';
import propTypes from 'prop-types';

import 'components/views/Profile/PersonalBlock/styles.scss';
import editPropTypes from 'components/views/Profile/PersonalBlock/types';
import personalValidation from 'containers/Authorization/Details/PersonalDetails/personalValidation';
import useAsync from 'hooks/useAsync';
import useForm from 'hooks/useForm';
import Input from 'components/common/Input';
import Select from 'components/common/Select';
import Button from 'components/common/Button';
import { BUTTON_TYPE, INPUT_TYPE, REQUEST_STATUS } from 'constants/common';
import { submitPersonalDetails } from 'containers/Authorization/Details/utils';
import { ENDPOINTS } from 'constants/routes';
import useOptions from 'hooks/useOptions';
import ConfirmationCancelButton from 'components/views/Profile/PersonalBlock/ConfirmationCancelButton';
import usePrompt from 'hooks/usePrompt';
import ConfirmationWindow from 'components/common/ConfirmationWindow';
import { isObjectHasChanged, convertedRoleOptions } from 'utils/common';
import { updateCurrentUser } from 'store/profile/profileSlice';
import { MESSAGES } from 'constants/messages';
import Grid from '@mui/material/Grid';
import { Box, Stack } from '@mui/material';

const EditMode = ({
  firstName, lastName, workRole, cancelEditMode, avatar,
}) => {
  const dispatch = useDispatch();
  const { options, status: rolesStatus } = useOptions(ENDPOINTS.workRoles, 'roles');
  const submitProfileDetails = async (values, setErrors) => {
    const additionalUserData = await submitPersonalDetails(
      { ...values, avatar: avatar?.avatar?.file || avatar?.avatar?.show }, setErrors,
    );

    if (additionalUserData) {
      dispatch(updateCurrentUser(additionalUserData));
    }

    cancelEditMode();
  };

  const defaultWorkRole = workRole && convertedRoleOptions([workRole])[0];
  const initFormValues = {
    firstName,
    lastName,
    workRole: defaultWorkRole,
    avatar,
  };

  const { execute, status } = useAsync(submitProfileDetails);
  const {
    handleSubmit, handleChange, handleBlur, values, errors, touched,
  } = useForm(
    execute,
    personalValidation,
    initFormValues,
    true,
  );

  const hasFormChanged = isObjectHasChanged(initFormValues, values);

  const { isBlocking, onCancel, onConfirm } = usePrompt(hasFormChanged);
  const updatePersonalDetails = (e) => {
    if (!hasFormChanged) {
      cancelEditMode();
      return;
    }

    handleSubmit(e);
  };

  return (
    <>
      <ConfirmationWindow
        content={MESSAGES.leave_the_page}
        open={isBlocking}
        onCancel={onCancel}
        onConfirm={onConfirm}
        forceBlocking={false}
        inWorkArea
      />
      <form
        onSubmit={updatePersonalDetails}
        className="personal-block__edit-mode main__form form"
        noValidate
      >
        <Grid container spacing={6}>
          <Grid item xl={10} lg={7} xs={12}>
            <div className="form__field">
              <Input
                id="firstName"
                name="firstName"
                label={MESSAGES.first_name}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="given-name"
                value={values.firstName}
                error={errors.firstName}
                touched={touched.firstName}
                inputType={INPUT_TYPE.v2}
                isRequired
                hasFeedback
              />
            </div>
          </Grid>
          <Grid item xl={10} lg={7} xs={12}>
            <div className="form__field">
              <Input
                id="lastName"
                name="lastName"
                label={MESSAGES.last_name}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.lastName}
                error={errors.lastName}
                touched={touched.lastName}
                autoComplete="family-name"
                inputType={INPUT_TYPE.v2}
                isRequired
                hasFeedback
              />
            </div>
          </Grid>
          <Grid item xl={10} lg={7} xs={12}>
            <div className="personal-block__edit-mode-select form__field form__field--last">
              <Select
                name="workRole"
                label={MESSAGES.work_role}
                onChange={handleChange}
                onBlur={handleBlur}
                options={options}
                defaultValue={defaultWorkRole}
                error={errors.workRole}
                touched={touched.workRole}
                isLoading={rolesStatus === REQUEST_STATUS.loading}
                isRequired
                hasFeedback
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <Stack direction="row" spacing={1}>
                { hasFormChanged ? (
                  <ConfirmationCancelButton
                    onConfirm={cancelEditMode}
                    status={status}
                  />
                ) : (
                  <Button
                    onClick={cancelEditMode}
                    type={BUTTON_TYPE.secondary}
                    isPreventSubmit
                  >
                    {MESSAGES.cancel}
                  </Button>
                )}
                <Button
                  type={BUTTON_TYPE.primary}
                  isDisabled={REQUEST_STATUS.loading === status}
                >
                  {MESSAGES.save}
                </Button>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

EditMode.propTypes = {
  ...editPropTypes,
  cancelEditMode: propTypes.func.isRequired,
};

export default EditMode;
