import { MESSAGES } from 'constants/messages';
import React from 'react';
import BannerTemplate from 'components/views/Homepage/Banners/BannerTemplate';

const MainWelcomeBanner = () => (
  <BannerTemplate
    title={MESSAGES.welcome_to_custimy}
    subtitle={MESSAGES.welcome_to_custimy_subtitle}
  />
);

export default MainWelcomeBanner;