import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ItemBlock } from 'components/views/Segments/ItemBlock';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import useRouter from 'hooks/useRouter';
import { MESSAGES } from 'constants/messages';
import { SEGMENT_NAME } from 'constants/segmentNames';
import GuideInfo from 'components/common/GuideInfo';
import { Stack } from '@mui/material';
import Divider from 'components/common/Divider';

export const CardBlock = ({
  data, updateState, active, guide,
}) => {
  const { push } = useRouter();
  const [isSelected, setIsSelected] = useState(false);
  const clickHandler = () => {
    if (!data?.id) {
      return;
    }

    push(`/segments/${data?.id}`);
  };
  const colorHandler = (flag) => {
    setIsSelected(flag);
  };
  return (
    <div
      className={classNames('card-wrapper', {
        disabled: !data.id,
      })}
      style={isSelected ? { border: `1px solid ${data.segment.color_1}` } : { border: '1px solid rgba(255, 255, 255, 0.1)' }}
      onMouseOver={() => colorHandler(true)}
      onMouseOut={() => colorHandler(false)}
    >
      <Link to={`/segments/${data?.id}`} onClick={clickHandler}>
        {' '}
        <div className="card-block">
          <Stack direction="row" justifyContent="space-between">
            <div className="title-block">
              <div
                className="indicate-block"
                style={{ backgroundColor: `${data.segment.color_1}` }}
              />
              <div className="title">
                {data.segment.segment_name}
              </div>
            </div>
            {!!guide && (
              <GuideInfo info={guide} />
            )}
          </Stack>
          {data.segment.segment_name !== SEGMENT_NAME.departed_customers ? (!data.id
            ? (
              <div className="card-block__empty-state">
                {MESSAGES.no_customers_in_segment}
              </div>
            )
            : (
              <>
                <ItemBlock
                  title={MESSAGES.customers}
                  data={data.cus_num}
                  active={active}
                  percentage={data.cus_num_last_month_percentage}
                  updateState={(value) => updateState('activeCustomer', value)}
                  className="customers"
                />
                {(!!data.avg_order_size || !!data.avg_order_size_last_month_percentage) && (
                  <Divider />
                )}
                <ItemBlock
                  title={MESSAGES.order_value_avg}
                  data={data.avg_order_size}
                  percentage={data.avg_order_size_last_month_percentage}
                  prefix={data.currency}
                  active={active}
                  updateState={(val) => updateState('activeOrderSize', val)}
                  className="order_size"
                />
                {(!!data.avg_orders || !!data.avg_orders_last_month_percentage) && (
                  <Divider />
                )}
                <ItemBlock
                  title={MESSAGES.orders_avg}
                  data={data.avg_orders}
                  percentage={data.avg_orders_last_month_percentage}
                  active={active}
                  updateState={(val) => updateState('activeAvgOrders', val)}
                  className="avg_orders"
                />
                {(!!data.avg_profit || !!data.avg_profit_last_month_percentage) && (
                  <>
                    <Divider />
                    <ItemBlock
                      title={MESSAGES.profit_avg}
                      data={data.avg_profit}
                      percentage={data.avg_profit_last_month_percentage}
                      prefix={data.currency}
                      active={active}
                      updateState={(val) => updateState('activeAvgProfit', val)}
                      className="avg_profit"
                    />
                  </>
                )}
              </>
            )
          ) : (!!data.cus_num || !!data.cus_num_last_month_percentage ? (
            <>
              <ItemBlock
                title="Customers"
                data={data.cus_num}
                active={active}
                percentage={data.cus_num_last_month_percentage}
                updateState={(value) => updateState('activeCustomer', value)}
                className="customers"
              />
              {(!!data.cus_num || !!data.cus_num_last_month_percentage) && (
                <Divider />
              )}
            </>
          ) : (
            <div className="card-block__empty-state">
              {MESSAGES.no_customers_in_segment}
            </div>
          ))}
        </div>
      </Link>
    </div>
  );
};

CardBlock.propTypes = {
  updateState: PropTypes.func.isRequired,
  active: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  data: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
      PropTypes.string,
      PropTypes.object,
      PropTypes.array,
    ]),
  ).isRequired,
  guide: PropTypes.string,
};

CardBlock.defaultProps = {
  active: undefined,
  guide: undefined,
};
