import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export const ListSelect = ({ children }) => (
  <ul className="drop-list">
    {children}
  </ul>
);

ListSelect.propTypes = {
  children: PropTypes.node,
};

ListSelect.defaultProps = {
  children: undefined,
};
