import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { MESSAGES } from 'constants/messages';

import 'components/views/Admin/Users/UsersCells/styles.scss';

export const NameCell = ({ row }) => (
  <span className="user__cell__name">
    {row.first_name}
    {' '}
    {row.last_name}
  </span>
);

NameCell.propTypes = {
  row: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

export const LastLoginCell = ({ column, row }) => {
  const lastLoginStr = row[column.key];
  const lastLogin = lastLoginStr && moment(lastLoginStr).format('DD/MM/YY | HH:mm A');
  return (
    <span className="user__cell__last-login">
      {lastLogin}
    </span>
  );
};

LastLoginCell.propTypes = {
  column: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  row: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

export const StatusCell = ({ column, row }) => (
  <span className="user__cell__status">
    {row[column.key] ? MESSAGES.active : MESSAGES.deleted}
  </span>
);

StatusCell.propTypes = LastLoginCell.propTypes;
