import React from 'react';
import { useSelector } from 'react-redux';
import ProtectedRoute from 'components/common/ProtectedRoutes';
import { ROUTES } from 'constants/routes';
import propTypes from 'prop-types';
import { getIsMaxAccessSuperUser } from 'store/profile/getters';

const MaxAccessSuperUserRoute = ({ component: Component, ...rest }) => {
  const isMaxAccessSuperUser = useSelector(getIsMaxAccessSuperUser);

  return (
    <ProtectedRoute
      hasPermission={isMaxAccessSuperUser}
      component={Component}
      redirectToFunc={() => ({ pathname: ROUTES.adminPanel })}
      {...rest}
    />
  );
};

export default MaxAccessSuperUserRoute;

MaxAccessSuperUserRoute.propTypes = {
  component: propTypes.func.isRequired,
};
