import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import Popup from 'reactjs-popup';
import 'components/views/Homepage/KpiHistoryDataBlock/ShowDetailedKpiChart/styles.scss';
import BarChart from 'components/common/Charts/BarChart';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import { MESSAGES } from 'constants/messages';
import DateRange from 'components/views/Homepage/KpiHistoryDataBlock/DateRange';
import { MONTHS_LABELS } from 'components/views/Homepage/KpiHistoryDataBlock/const';
import { isEmpty, last, xor } from 'lodash';
import ContentLoader from 'components/common/ContentLoader';
import IconButton from 'components/common/IconButton';

const ShowDetailedKpiChart = ({
  open,
  onClose,
  allYearsBarChartData,
}) => {
  const [years, setYears] = useState([]);
  const [selectedYears, setSelectedYears] = useState([]);
  const [barChartData, setBarChartData] = useState({});

  useEffect(() => {
    if (allYearsBarChartData) {
      const years = allYearsBarChartData.datasets.map((item) => item.label);
      setYears(years);
      setSelectedYears([last(years)]);
    } else {
      setYears([]);
      setSelectedYears([]);
      setBarChartData({});
    }
  }, [allYearsBarChartData]);

  useEffect(() => {
    if (!isEmpty(selectedYears)) {
      setBarChartData({
        ...allYearsBarChartData,
        datasets: allYearsBarChartData.datasets.filter((dataset) => selectedYears.includes(dataset.label)),
      });
    }
  }, [selectedYears]);

  const toggleYear = (year) => {
    const newSelectedYears = xor(selectedYears, [year]);

    isEmpty(newSelectedYears) || setSelectedYears(newSelectedYears);
  };

  return (
    <>
      <Popup
        modal
        open={open}
        position="center center"
        closeOnDocumentClick={false}
        onClose={onClose}
      >
        {() => (
          <ContentLoader isLoading={!allYearsBarChartData}>
            <div className="show-detailed-kpi-chart">
              <div className="show-detailed-kpi-chart__actions">
                <div className="show-detailed-kpi-chart__actions__history">
                  <div className="show-detailed-kpi-history">
                    {MESSAGES.history}
                  </div>
                  <IconButton
                    onClick={() => onClose(false)}
                    icon={CloseIcon}
                    size="small"
                    type="secondary"
                    className="close-chart"
                  />
                </div>
                <div className="show-detailed-kpi-chart__actions__title">
                  <div className="show-detailed-kpi-title">
                    {barChartData?.title}
                  </div>
                  <div className="show-detailed-kpi-date-range">
                    <DateRange
                      format="YYYY"
                      activeItem={selectedYears}
                      dateRange={years}
                      onClickHandler={toggleYear}
                    />
                  </div>
                </div>
                <BarChart
                  datasets={barChartData?.datasets}
                  barChartLabels={MONTHS_LABELS}
                  prefixConfig={barChartData?.prefixConfig}
                />
              </div>
            </div>
          </ContentLoader>
        )}
      </Popup>
    </>
  );
};

ShowDetailedKpiChart.propTypes = {
  open: propTypes.bool,
  onClose: propTypes.func,
  allYearsBarChartData: propTypes.objectOf(
    propTypes.oneOfType([
      propTypes.string,
      propTypes.any,
    ]),
  ),
};

ShowDetailedKpiChart.defaultProps = {
  open: false,
  onClose: undefined,
  allYearsBarChartData: undefined,
};

export default ShowDetailedKpiChart;
