import React, { useEffect, useRef, useState } from 'react';
import MultiSelect from 'components/common/MultiSelect';
import { Box, Popper } from '@mui/material';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import Search from 'components/common/Search';
import './styles.scss';
import ContentLoader from 'components/common/ContentLoader';
import { MESSAGES } from 'constants/messages';

const SearchDropdown = ({
  placeholder, options, selectedKeys, onSelect,
  onDeselect, onSearchChanged, isLoading, isLoadingNext,
  maxSelection, hasMore, loadNext, onOpen,
}) => {
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [width, setWidth] = useState(300);
  const searchRef = useRef(null);

  const handleSearchChanged = (value) => setSearch(value);
  const openDropdown = () => setIsOpen(true);
  const closeDropdown = () => setIsOpen(false);
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      closeDropdown();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      onOpen();
    }
    if (!isOpen) document.removeEventListener('mousedown', handleClickOutside);
  }, [dropdownRef, isOpen]);

  useEffect(() => {
    onSearchChanged(search);
    if (!isOpen && search) setIsOpen(true);
  }, [search]);

  const handleResize = () => {
    if (!searchRef?.current) setWidth(300);
    setWidth(searchRef.current.clientWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    handleResize();
  }, [searchRef]);

  return (
    <div className="search-dropdown" ref={searchRef}>
      <Search
        useDebounce
        search={search}
        title={placeholder}
        searchChanged={handleSearchChanged}
        onFocus={openDropdown}
        onSearchClick={openDropdown}
      />
      <Popper
        className="search-dropdown-overlay"
        open={isOpen}
        anchorEl={searchRef?.current}
        placement="bottom"
      >
        <div
          className="search-dropdown-content"
          ref={dropdownRef}
          style={{ width: `${width}px` }}
        >
          {!options.length && !isLoading && (
            <Box display="flex" justifyContent="center">
              <span className="no-results-label">
                {MESSAGES.no_results}
              </span>
            </Box>
          )}
          <ContentLoader isLoading={isLoading && !isLoadingNext}>
            <MultiSelect
              options={options}
              selected={selectedKeys}
              onSelect={onSelect}
              onDeselect={onDeselect}
              maxSelection={maxSelection}
              hasMore={hasMore}
              loadNext={loadNext}
            />
          </ContentLoader>
        </div>
      </Popper>
    </div>
  );
};

SearchDropdown.propTypes = {
  selectedKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    content: PropTypes.node.isRequired,
    leftContent: PropTypes.node,
  })).isRequired,
  onSelect: PropTypes.func,
  onDeselect: PropTypes.func,
  onSearchChanged: PropTypes.func,
  isLoading: PropTypes.bool,
  isLoadingNext: PropTypes.bool,
  hasMore: PropTypes.bool,
  loadNext: PropTypes.func,
  onOpen: PropTypes.func,
  maxSelection: PropTypes.number,
};

SearchDropdown.defaultProps = {
  placeholder: '',
  onSelect: noop,
  onDeselect: noop,
  onSearchChanged: noop,
  isLoading: false,
  isLoadingNext: false,
  hasMore: false,
  loadNext: noop,
  onOpen: noop,
  maxSelection: null,
};

export default SearchDropdown;
