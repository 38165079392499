import React from 'react';
import propTypes from 'prop-types';
import 'containers/PrivateApp/SwitchLicense/LicenseTable/LicenseTableItem/styles.scss';
import CompanyName from 'components/common/CompanyName';
import { COMPANY_NAME_SIZE, VIEWTYPE } from 'constants/common';
import usePortal from 'hooks/usePortal';

const LicenseTableItem = ({
  license,
  company,
  status,
  onSelect,
}) => {
  const {
    company_name: companyName,
    avatar,
    is_payment_valid: isPaymentValid,
  } = company;

  const {
    viewType,
  } = usePortal();

  const onSelectItemHandler = () => {
    onSelect(license);
  };

  return (
    <tr
      className="license-table-row"
      tabIndex="0"
      onClick={onSelectItemHandler}
    >
      <td className="license-table__column license-table__column--license">
        <div className="license-table-row__logo">
          <CompanyName
            size={viewType === VIEWTYPE.MOBILE ? COMPANY_NAME_SIZE.small : COMPANY_NAME_SIZE.middle}
            src={avatar || null}
            onlyLogo
            isUnpaid={!isPaymentValid}
          />
        </div>
        <div
          className="license-table-row__name"
          style={viewType === VIEWTYPE.MOBILE ? { fontSize: '16px' } : { fontSize: '26px' }}
        >
          {companyName}
        </div>
      </td>
      <td className="license-table__column license-table__column--status">
        {status}
      </td>
    </tr>
  );
};

LicenseTableItem.propTypes = {
  license: propTypes.objectOf(propTypes.string).isRequired,
  company: propTypes.objectOf(propTypes.string).isRequired,
  status: propTypes.string.isRequired,
  onSelect: propTypes.func.isRequired,
};

export default LicenseTableItem;
