import React from 'react';
import propTypes from 'prop-types';
import Popup from 'reactjs-popup';

import 'components/views/Admin/SuperUsers/InviteSuperUserModal/styles.scss';
import Button from 'components/common/Button';
import { REQUEST_STATUS, BUTTON_TYPE } from 'constants/common';
import useAsync from 'hooks/useAsync';
import useForm from 'hooks/useForm';
import validation from 'components/views/Admin/SuperUsers/InviteSuperUserModal/inviteSuperUserValidation';
import Input from 'components/common/Input';
import { inviteSuperUser } from 'api/superUserAPI';
import { MESSAGES } from 'constants/messages';

const InviteSuperUserModal = ({ isOpened, onClose, onSubmit }) => {
  const inviteSuperUserHandler = async ({ email }, setErrors) => {
    try {
      await inviteSuperUser({ email });
      onSubmit();
      cleanUpForm();
    } catch (e) {
      setErrors({ email: { header: e.email[0] } });
    }
  };

  const { execute, status } = useAsync(inviteSuperUserHandler);

  const {
    handleSubmit, handleChange, handleBlur, handleFocus, values, errors, touched, cleanUpForm,
  } = useForm(
    execute,
    validation,
  );

  const handleClose = () => {
    onClose();
    cleanUpForm();
  };

  return (
    <Popup
      modal
      open={isOpened}
      position="center center"
      onClose={handleClose}
    >
      {() => (
        <div className="confirmation-window confirmation-window__content invite-super-user">
          <span className="invite-super-user__title">{MESSAGES.provide_email_of_new_super_user}</span>

          <form className="invite-super-user__form main__form form" onSubmit={handleSubmit} noValidate>
            <div className="form__field">
              <Input
                id="email"
                name="email"
                label={MESSAGES.email_example}
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                value={values.email}
                error={errors.email}
                touched={touched.email}
                isRequired
                hasFeedback
              />
            </div>
            <div className="invite-super-user__form__actions form__actions">
              <div className="invite-super-user__form__actions__cancel form__cancel">
                <Button
                  block
                  isPreventSubmit
                  onClick={onClose}
                  type={BUTTON_TYPE.secondary}
                >
                  {MESSAGES.cancel}
                </Button>
              </div>
              <div className="invite-super-user__form__actions__submit form__submit">
                <Button
                  type={BUTTON_TYPE.primary}
                  isLoading={REQUEST_STATUS.loading === status}
                  isDisabled={errors.email}
                >
                  {MESSAGES.send_invite}
                </Button>
              </div>
            </div>
          </form>
        </div>
      )}
    </Popup>
  );
};

export default InviteSuperUserModal;

InviteSuperUserModal.propTypes = {
  isOpened: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  onSubmit: propTypes.func.isRequired,
};
