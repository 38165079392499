import { createSlice } from '@reduxjs/toolkit';

import moduleName from './constants';

const initialState = {
  audiences_name: {},
  cohorts_name: {},
  customers_name: {},
  products_name: {},
  experiments_name: {},
};

const crumbsIdToNameSlice = createSlice({
  name: moduleName,
  initialState,
  reducers: {
    setAudienceName(state, action) {
      state.audiences_name = action.payload;
    },
    setCohortName(state, action) {
      state.cohorts_name = action.payload;
    },
    setCustomerName(state, action) {
      state.customers_name = action.payload;
    },
    setProductName(state, action) {
      state.products_name = action.payload;
    },
    setExperimentName(state, action) {
      state.experiments_name = action.payload;
    },
  },
});

export const {
  setAudienceName,
  setCohortName,
  setCustomerName,
  setProductName,
  setExperimentName,
} = crumbsIdToNameSlice.actions;

export default crumbsIdToNameSlice.reducer;
