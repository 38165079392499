import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Box, Stack } from '@mui/material';
import GuideInfo from 'components/common/GuideInfo';
import './styles.scss';

const Block = ({
  children,
  prefix,
  className,
  header,
  fillHeight,
  fillContentHeight,
  postHeader,
  guide,
  style,
  type,
  headerContent,
}) => (
  <Box
    className={classNames('block-wrapper', type, { 'fill-height': fillHeight })}
  >
    <Box
      style={style}
      className={classNames('block', className, {
        [`${prefix}-block`]: prefix,
        'fill-height': fillHeight,
      })}
    >
      {(!!header || !!postHeader || !!headerContent || !!guide) && (
        <div className="block__header-container">
          <Stack direction="row" justifyContent="space-between">
            <Stack>
              {!!header && (
                <span className={classNames('block__header', 'block__left-aligned')}>
                  {header}
                </span>
              )}
              {!!postHeader && (
                <span className={classNames('block__post-header')}>
                  {postHeader}
                </span>
              )}
            </Stack>
            {!!guide && (
              <Box alignItems="flex-end">
                <GuideInfo info={guide} type={type === 'large-header' ? 'white' : 'light-grey'} />
              </Box>
            )}
            {!!headerContent && (
              <Box alignItems="flex-end">
                {headerContent}
              </Box>
            )}
          </Stack>
        </div>
      )}
      <div className={classNames('block__content', { 'fill-height': fillContentHeight })}>
        {children}
      </div>
    </Box>
  </Box>
);

Block.propTypes = {
  children: PropTypes.node,
  prefix: PropTypes.string,
  className: PropTypes.string,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType, PropTypes.object]),
  fillHeight: PropTypes.bool,
  postHeader: PropTypes.string,
  fillContentHeight: PropTypes.bool,
  guide: PropTypes.string,
  style: PropTypes.object,
  type: PropTypes.oneOf(['primary', 'large-header']),
  headerContent: PropTypes.node,
};

Block.defaultProps = {
  children: undefined,
  className: undefined,
  prefix: undefined,
  header: undefined,
  fillHeight: false,
  postHeader: undefined,
  fillContentHeight: false,
  guide: undefined,
  style: undefined,
  type: 'primary',
  headerContent: undefined,
};

export default Block;
