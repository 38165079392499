import React from 'react';
import classNames from 'classnames';
import propTypes from 'prop-types';

import './styles.scss';

const setInnerHTMLBody = (body) => ({ __html: body });

const Feedback = ({ hint, isCenter }) => {
  const { header, body } = hint;

  return (
    <div className={classNames('feedback', {
      'feedback--center': isCenter,
    })}
    >
      <h1 className="feedback__header">{header}</h1>
      {!!body && <p className="feedback__body" dangerouslySetInnerHTML={setInnerHTMLBody(body)} />}
    </div>
  );
};

Feedback.propTypes = {
  hint: propTypes.objectOf(propTypes.string).isRequired,
  isCenter: propTypes.bool,
};

Feedback.defaultProps = {
  isCenter: false,
};

export default Feedback;
