import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import Input from 'components/common/Input';
import Button from 'components/common/Button';
import { BUTTON_TYPE, REQUEST_STATUS } from 'constants/common';
import { ROUTES } from 'constants/routes';
import useForm from 'hooks/useForm';
import useAsync from 'hooks/useAsync';
import validation from './signUpValidation';
import {
  accessTokenLSManager, expiresAtLSManager, refreshTokenLSManager, userUuidLSManager,
} from 'helper/localStorage';
import useCurrentUser from 'hooks/useCurrentUser';
import { createExpiresTime } from 'helper/moment';
import { SESSION_EXPIRES_AT } from 'constants/validation';
import { useSelector } from 'react-redux';
import { getPairOfToken } from 'api/authAPI';
import { createUser, sendEmailVerification, updateInvitedUser } from 'api/usersAPI';
import { getLicenseDetails } from 'store/license/getters';
import { getCurrentUser } from 'store/profile/getters';
import { MESSAGES } from 'constants/messages';
import Footer from 'containers/Authorization/Footer';
import ReactTooltip from 'react-tooltip';
import BaseFooter from 'containers/Authorization/BaseFooter';
import { size } from 'lodash';
import PasswordChecks from 'containers/Authorization/PasswordChecks';
import Grid from '@mui/material/Grid';
import useGoogleSignIn from 'hooks/useGoogleSignIn';
import { GoogleLogin } from '@react-oauth/google';
import Divider from 'components/common/Divider';
import { useToast } from 'components/common/Toast';
import { LinkButton } from 'components/common/LinkButton';
import Block from 'components/common/Block';

const GOOGLE_KEY = process.env.REACT_APP_GA_OAUTH_CLIENT_ID;

const SignUp = () => {
  document.title = `${MESSAGES.sign_up} - ${MESSAGES.custimy}`;
  const {
    license: invitedLicense,
    license_status: licenseStatus,
  } = useSelector(getLicenseDetails);
  const { email: invitedEmail } = useSelector(getCurrentUser);
  const isLicenseInvited = licenseStatus?.length && licenseStatus[1] === 'Invited';
  const [step, setStep] = useState(1);

  const { setCurrentUserAsync } = useCurrentUser();
  const { createToast } = useToast();

  const signUpHandler = async ({
    email,
    password,
    confirmPassword,
    verificationCode,
  }, setErrors) => {
    if (step === 1 && !isLicenseInvited) {
      sendEmailVerification(email).then(() => {
        setStep(2);
      }).catch((err) => {
        createToast(
          MESSAGES.failed_email_verification,
          err[0],
          'danger',
          'email-verification-send-failed',
        );
      });
      return;
    }

    const createUserRequestBody = {
      email,
      password,
      confirm_password: confirmPassword,
      verification_code: verificationCode,
    };

    try {
      const userCredentials = invitedEmail
        ? await updateInvitedUser({ invitedEmail, password, confirm_password: confirmPassword })
        : await createUser(createUserRequestBody);

      const pairOfTokens = await getPairOfToken(createUserRequestBody);

      if (userCredentials && pairOfTokens) {
        const expiresAtTime = createExpiresTime(SESSION_EXPIRES_AT);

        userUuidLSManager.set(userCredentials.uuid);
        accessTokenLSManager.set(pairOfTokens.access);
        refreshTokenLSManager.set(pairOfTokens.refresh);
        expiresAtLSManager.set(expiresAtTime);
        await setCurrentUserAsync();
      }
    } catch (error) {
      if (error.verification_code) {
        createToast(
          MESSAGES.failed_email_verification,
          error.verification_code[0],
          'danger',
          'email-verification-failed',
        );
      }
      if (error.email) {
        setErrors({
          email: {
            header: error.email[0],
          },
        });
      }
    }
  };

  const { execute, status } = useAsync(signUpHandler);
  const {
    handleSubmit, handleChange, handleBlur, handleFocus, values, errors, touched,
  } = useForm(
    execute,
    validation,
    isLicenseInvited && { email: invitedEmail },
  );

  const calculateToolTipPosition = (pos, e) => ({ left: (e.x - 45), top: e.y + 15 });

  const {
    onSuccess: onGoogleSuccess,
    onError: onGoogleError,
  } = useGoogleSignIn();

  const resendEmailVerification = () => {
    sendEmailVerification(values.email).then(() => {
      createToast(
        MESSAGES.email_resend,
        MESSAGES.email_verification_new_sent,
        'success',
        'email-verification-resend',
      );
    }).catch((err) => {
      createToast(
        MESSAGES.failed,
        err[0],
        'danger',
        'email-verification-resend-failed',
      );
    });
  };

  return (
    <div className="container">
      <Grid
        item
        xs={12}
        sx={{ margin: { lg: 'auto 65px auto 225px', md: 'auto 65px auto 25px', xs: 'auto' } }}
      >
        <div className="container__main">
          <Block>
            <main className="main">
              { !!isLicenseInvited && !!invitedLicense && (
                <>
                  <h1 className="main__title-other">
                    {MESSAGES.you_ve_been_invited_to_join_a_license}
                  </h1>
                  <p className="main__subtitle-other">
                    {MESSAGES.please_create_a_password_to_accept_the_invitation}
                  </p>
                </>
              )}
              { !isLicenseInvited && step === 1 && (
                <>
                  <h1 className="main__title">
                    {MESSAGES.sign_up}
                  </h1>
                  <div className="main__header">
                    <p className="main__header__hint">
                      {MESSAGES.already_have_an_account}
                      {' '}
                      <Link to={ROUTES.signIn} className="link authorization-title">
                        {MESSAGES.sign_in}
                      </Link>
                    </p>
                  </div>
                </>
              )}
              { step === 2 && (
                <>
                  <h1 className="main__title">
                    {MESSAGES.email_verification}
                  </h1>
                  <div className="main__header">
                    <p className="main__header__hint">
                      {MESSAGES.we_have_sent_an_email_to}
                      <strong>{values.email}</strong>
                      <br />
                      <br />
                      {MESSAGES.email_verification_sent_info}
                    </p>
                  </div>
                </>
              )}
              <form className="main__form form" onSubmit={handleSubmit} noValidate>
                {step === 1 && (
                  <>
                    <div className="google-login-wrapper">
                      <div className="google-button-wrapper">
                        <GoogleLogin
                          onSuccess={onGoogleSuccess}
                          onError={onGoogleError}
                          clientId={GOOGLE_KEY}
                          useOneTap
                          theme="filled_black"
                          text="signup_with"
                          context="signup"
                          width="300px"
                        />
                      </div>
                      <Divider />
                    </div>
                    {isLicenseInvited || (
                      <div className="form__field">
                        <Input
                          id="email"
                          name="email"
                          label={MESSAGES.work_email}
                          type="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          autoComplete="email"
                          value={values.email}
                          error={errors.email}
                          touched={touched.email}
                          isRequired
                          isDisable={isLicenseInvited}
                          hasFeedback={!!values?.email?.length}
                          hasSuccess
                        />
                      </div>
                    )}
                    <div className="form__field field-password">
                      <Input
                        id="password"
                        name="password"
                        label={MESSAGES.password}
                        type="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        value={values.password}
                        error={errors.password}
                        touched={touched.password}
                        autoComplete="new-password"
                        isRequired
                        hasPreview
                        hasFeedback={!!values?.password?.length}
                        hasSuccess
                      />
                    </div>
                    <PasswordChecks password={values?.password} />
                    <div className="form__field form__field--last form__field--confirm">
                      <Input
                        id="confirmPassword"
                        name="confirmPassword"
                        label={MESSAGES.confirm_password}
                        type="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete="new-password"
                        value={values.confirmPassword}
                        error={errors.confirmPassword}
                        touched={touched.confirmPassword}
                        isRequired
                        hasFeedback={!!values?.confirmPassword?.length}
                        hasPreview
                        hasSuccess
                      />
                    </div>
                    <div className="form__actions">
                      <div
                        className={classNames('form__actions__submit-sign-up', {
                          'submit-success': !size(errors) && values?.password?.length,
                        })}
                        data-tip
                        data-for="bottonTip"
                      >
                        <Button
                          type={BUTTON_TYPE.primary}
                          isDisabled={REQUEST_STATUS.loading === status}
                          fillWidth
                        >
                          {MESSAGES.sign_up}
                        </Button>
                      </div>
                      {(!!size(errors) || !size(values))
                        && (
                          <ReactTooltip
                            className="tooltip-container"
                            id="bottonTip"
                            place="right"
                            overridePosition={calculateToolTipPosition}
                            type="light"
                            effect="solid"
                            globalEventOff="click"
                            arrowColor="transparent"
                          >
                            <div className="tooltip-text">
                              {MESSAGES.please_fill_in_your_information}
                            </div>
                          </ReactTooltip>
                        )}
                    </div>
                  </>
                )}
                {step === 2 && (
                  <>
                    <div className="form__field">
                      <Input
                        id="verificationCode"
                        name="verificationCode"
                        label={MESSAGES.verification_code}
                        type="verificationCode"
                        onChange={handleChange}
                        autoComplete="verificationCode"
                        value={values.verificationCode}
                      />
                      <div className="email-resend-wrapper">
                        <LinkButton
                          size="medium"
                          onClick={resendEmailVerification}
                        >
                          {MESSAGES.resend_verification_email}
                        </LinkButton>
                      </div>
                    </div>
                    <Button
                      type={BUTTON_TYPE.primary}
                      isDisabled={REQUEST_STATUS.loading === status}
                      fillWidth
                    >
                      {MESSAGES.sign_up}
                    </Button>
                  </>
                )}
              </form>
              <Footer />
            </main>
          </Block>
          <BaseFooter />
        </div>
      </Grid>
    </div>
  );
};

export default SignUp;
