import * as React from 'react';
import 'components/views/Integrations/Intro/styles.scss';
import Block from 'components/common/Block';
import NeedHelpLink from 'components/views/Integrations/NeedHelpLink';
import { MESSAGES } from 'constants/messages';
import { Box } from '@mui/material';

const Intro = () => (
  <Block
    type="large-header"
    header={MESSAGES.start_integration_header}
    postHeader={MESSAGES.start_integration_subtext}
  >
    <Box display="flex" justifyContent="flex-end">
      <NeedHelpLink />
    </Box>
  </Block>
);
export default Intro;
