import React, { useCallback } from 'react';
import classNames from 'classnames';

import './styles.scss';
import { ReactComponent as ArrowIcon } from 'assets/svg/arrowUp.svg';
import { ReactComponent as UserIcon } from 'assets/svg/user.svg';
import { ReactComponent as ShieldIcon } from 'assets/svg/shield.svg';
import { ReactComponent as RepeatIcon } from 'assets/svg/repeat.svg';
import { ReactComponent as LogOutIcon } from 'assets/svg/exit.svg';
import Avatar from 'components/common/Avatar';
import { AVATAR_TYPE } from 'constants/common';
import { ROUTES } from 'constants/routes';
import UserPanelItem from 'containers/PrivateApp/Portal/CustomHeader/UserPanel/UserPanelItem';
import useRouter from 'hooks/useRouter';
import { useSelector } from 'react-redux';
import useLogout from 'hooks/useLogout';
import { getCurrentUser, getIsSuperUser } from 'store/profile/getters';
import { MESSAGES } from 'constants/messages';
import usePopover from 'hooks/usePopover';

const UserPanel = () => {
  const {
    first_name: firstName, last_name: lastName, avatar,
  } = useSelector(getCurrentUser);
  const isSuperUser = useSelector(getIsSuperUser);
  const { push } = useRouter();
  const logoutHandler = useLogout();

  const {
    isOpened,
    parentRef,
    togglePopover: headerClickHandler,
  } = usePopover();

  const goToProfile = () => push(ROUTES.profile);
  const goToSwitchLicense = () => push(ROUTES.switchLicense);
  const goToAdminPanel = () => push(ROUTES.adminPanelLicenses);

  const UserPanelList = useCallback(() => (
    <ul className="user-panel-list__list">
      <ul className="user-panel-list__list--top">
        <UserPanelItem onClickHandler={goToProfile}>
          <UserIcon className="user-panel-list__item-icon" />
          {MESSAGES.user_profile}
        </UserPanelItem>
        <UserPanelItem onClickHandler={goToSwitchLicense}>
          <RepeatIcon className="user-panel-list__item-icon" />
          {MESSAGES.switch_license}
        </UserPanelItem>
        {isSuperUser && (
          <UserPanelItem onClickHandler={goToAdminPanel}>
            <ShieldIcon className="user-panel-list__item-icon" />
            {MESSAGES.admin_panel}
          </UserPanelItem>
        )}
      </ul>
      <UserPanelItem onClickHandler={logoutHandler}>
        <LogOutIcon className="user-panel-list__item-icon" />
        {MESSAGES.log_out}
      </UserPanelItem>
    </ul>
  ), []);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      role="button"
      tabIndex="0"
      ref={parentRef}
      onClick={headerClickHandler}
      className={classNames('user-panel-list-container', {
        'user-panel-list-container__collapsed': !isOpened,
      })}
    >
      <div className="user-panel-header">
        <span title={`${firstName} ${lastName}`} className="user-panel-header__full-name">
          {firstName}
          {' '}
          {lastName}
        </span>
        <div className="user-panel-header__avatar">
          {(avatar ? <img src={avatar} alt="avatar" />
            : <Avatar type={AVATAR_TYPE.middle} hasBorder />) }
        </div>
        <div className="user-panel-header__icon-container">
          <ArrowIcon className="user-panel-header__arrow-icon" />
        </div>
      </div>
      <div className="user-panel-list__items-wrapper">
        <UserPanelList />
      </div>
    </div>
  );
};

export default UserPanel;
