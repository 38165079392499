import React, { useState } from 'react';
import classNames from 'classnames';
import Tooltip from 'components/common/Tooltip';
import { ReactComponent as InvitationIcon } from 'assets/svg/invitationMail.svg';
import ReInviteAlert from 'components/views/Settings/LicenseSettings/LicenseMembers/LicenseMembersTable/LicenseMembersTableItem/ReInviteAlert';
import propTypes from 'prop-types';
import { REQUEST_STATUS } from 'constants/common';
import useAsync from 'hooks/useAsync';
import { MESSAGES } from 'constants/messages';

const ReInvitationIcon = () => <InvitationIcon className="license-members-table-row-status-invitation-icon" />;

const ReInviteFeedback = ({
  reInviteClick,
}) => {
  const [isClosed, setIsClosed] = useState(false);
  const {
    execute: reInviteClickAsync,
    status: reInviteClickStatus,
  } = useAsync(reInviteClick);

  const isSuccess = reInviteClickStatus === REQUEST_STATUS.success && !isClosed;

  const reInviteClickHandler = async () => {
    await reInviteClickAsync();
    setIsClosed(false);
  };

  const onCloseHandler = () => setIsClosed(true);

  return (
    <Tooltip
      trigger={ReInvitationIcon}
      arrow={false}
      onClose={onCloseHandler}
    >
      <div
        className={classNames('tooltip-alert license-members-table-row-status-alert', {
          'license-members-table-row-status-alert--success': isSuccess,
        })}
        onClick={reInviteClickHandler}
      >
        <ReInviteAlert isSuccess={isSuccess}>
          {
            reInviteClickStatus === REQUEST_STATUS.loading
              ? MESSAGES.pending
              : MESSAGES.resend_invitation
          }
        </ReInviteAlert>
      </div>
    </Tooltip>
  );
};

ReInviteFeedback.propTypes = {
  reInviteClick: propTypes.func.isRequired,
};

export default ReInviteFeedback;
