import React from 'react';
import { Stack } from '@mui/material';
import Radio from 'components/common/Radio';
import PropTypes from 'prop-types';
import './styles.scss';
import classNames from 'classnames';
import { noop } from 'lodash';

const RadioCollapsible = ({
  header, leftContent, isExpanded, onClick, value, children,
}) => (
  <div
    data-value={value}
    className={classNames('radio-collapsible', { expanded: isExpanded })}
  >
    <Stack>
      <div className="radio-bar" onClick={onClick}>
        <Stack direction="row" spacing={2}>
          {leftContent}
          <div className="radio-header-wrapper">
            <span className="radio-header">
              {header}
            </span>
          </div>
        </Stack>
        <Radio isChecked={isExpanded} />
      </div>
      <div className="radio-collapsible-content">
        {isExpanded && children && (
          <div className="radio-collapsible-content-wrapper">
            {children}
          </div>
        )}
      </div>
    </Stack>
  </div>
);

RadioCollapsible.propTypes = {
  header: PropTypes.string.isRequired,
  leftContent: PropTypes.node,
  isExpanded: PropTypes.bool,
  onClick: PropTypes.func,
  value: PropTypes.string,
  children: PropTypes.node,
};

RadioCollapsible.defaultProps = {
  leftContent: undefined,
  isExpanded: false,
  onClick: noop,
  value: undefined,
  children: undefined,
};

export default RadioCollapsible;
