import { createSlice } from '@reduxjs/toolkit';

import moduleName from './constants';

const initialState = {
  toasts: [],
};

const toastsSlice = createSlice({
  name: moduleName,
  initialState,
  reducers: {
    addToastAction(state, action) {
      state.toasts = [...state.toasts, action.payload];
    },
    removeToastAction(state, action) {
      const { id } = action.payload;
      const index = state.toasts.findIndex((e) => e.id === id);
      state.toasts.splice(index, 1);
    },
    updateToastAction(state, action) {
      const { id } = action.payload;
      const index = state.toasts.findIndex((e) => e.id === id);
      state.toasts[index] = action.payload;
    },
  },
});

export const { addToastAction, removeToastAction, updateToastAction } = toastsSlice.actions;
export default toastsSlice.reducer;
