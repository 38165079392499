import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { ReactComponent as UsersIcon } from 'assets/svg/people.svg';
import { ReactComponent as LicensesIcon } from 'assets/svg/license.svg';
import { ReactComponent as SuperUsersIcon } from 'assets/svg/shield.svg';
import { ReactComponent as BundlesIcon } from 'assets/svg/segments.svg';
import { ROUTES } from 'constants/routes';
import CombinedSideBarMenu from 'containers/PrivateApp/Portal/CombinedSideBarMenu';
import { getIsMaxAccessSuperUser } from 'store/profile/getters';
import { MESSAGES } from 'constants/messages';

const AdminPanelMenu = ({
  defaultSideBar, viewType, toggleDrawer, toggleMenu, isMenuOpened, onCloseSlideMenu,
}) => {
  const isMaxAccessSuperUser = useSelector(getIsMaxAccessSuperUser);

  const mainItems = [
    {
      hrefTo: ROUTES.adminPanelLicenses,
      IconComponent: LicensesIcon,
      name: MESSAGES.licenses,
    }, {
      hrefTo: ROUTES.adminPanelUsers,
      IconComponent: UsersIcon,
      name: MESSAGES.users,
    }, {
      hrefTo: ROUTES.adminBundles,
      IconComponent: BundlesIcon,
      name: MESSAGES.bundles,
    },
  ];

  const additionalItems = (
    isMaxAccessSuperUser ? [{
      hrefTo: ROUTES.adminPanelSuperUsers,
      IconComponent: SuperUsersIcon,
      name: MESSAGES.super_users,
    }] : []
  );

  return (
    <CombinedSideBarMenu
      mainItems={mainItems}
      additionalItems={additionalItems}
      isMenuOpened={isMenuOpened}
      defaultSideBar={defaultSideBar}
      viewType={viewType}
      toggleDrawer={toggleDrawer}
      toggleMenu={toggleMenu}
      onCloseSlideMenu={onCloseSlideMenu}
    />
  );
};

export default AdminPanelMenu;

AdminPanelMenu.propTypes = {
  isMenuOpened: PropTypes.bool.isRequired,
  defaultSideBar: PropTypes.string,
  viewType: PropTypes.string.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  onCloseSlideMenu: PropTypes.func,
};

AdminPanelMenu.defaultProps = {
  defaultSideBar: undefined,
  onCloseSlideMenu: null,
};
