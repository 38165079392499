import React from 'react';
import './styles.scss';
import PropTypes from 'prop-types';
import ReactSlider from 'react-slider/dist/es/dev/components/ReactSlider/ReactSlider.mjs';
import { noop } from 'lodash';
import { getIntervals } from 'utils/common';
import InfoTooltip from 'components/common/InfoTooltip';

const Slider = ({
  min, max, value, percentage, marks, onChange, label,
}) => {
  const intervals = getIntervals(min, max, marks);
  const suffix = percentage ? '%' : null;

  const renderMark = ({ key, style }) => (
    <span className="mark" style={style}>
      {key}
      {suffix}
    </span>
  );

  const renderThumb = (props, state) => (
    <InfoTooltip text={`${state.value}${suffix}`} placement="top">
      <div {...props} />
    </InfoTooltip>
  );

  return (
    <div className="slider-wrapper">
      {!!label && (
        <span className="slider-label">
          {label}
        </span>
      )}
      <ReactSlider
        className="slider"
        thumbClassName="thumb"
        trackClassName="track"
        min={min}
        max={max}
        value={value}
        marks={intervals}
        renderMark={renderMark}
        onAfterChange={onChange}
        renderThumb={renderThumb}
      />
    </div>
  );
};

Slider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  percentage: PropTypes.bool,
  marks: PropTypes.number,
  onChange: PropTypes.func,
  label: PropTypes.string,
};

Slider.defaultProps = {
  percentage: false,
  marks: null,
  onChange: noop,
  label: null,
};

export default Slider;
