import React from 'react';
import './styles.scss';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import classNames from 'classnames';

const Radio = ({ isChecked, onClick }) => (
  <div
    className={classNames('radio', { checked: isChecked })}
    onClick={onClick}
  >
    <div className="inner-radio" />
  </div>
);

Radio.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
};

Radio.defaultProps = {
  onClick: noop,
};

export default Radio;
