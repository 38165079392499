import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import 'containers/PrivateApp/SwitchLicense/styles.scss';
import LogoSmall from 'components/common/LogoSmall';
import Button from 'components/common/Button';
import { BUTTON_TYPE } from 'constants/common';
import LicenseTable from 'containers/PrivateApp/SwitchLicense/LicenseTable';
import { ROUTES } from 'constants/routes';
import useAsync from 'hooks/useAsync';
import useRouter from 'hooks/useRouter';
import useLogout from 'hooks/useLogout';
import { getAllLicense } from 'api/licenseAPI';
import NoLicensesModal from 'containers/PrivateApp/SwitchLicense/NoLicensesModal';
import useSwitchLicense from 'hooks/useSwitchLicense';
import { getIsSuperUser } from 'store/profile/getters';
import { MESSAGES } from 'constants/messages';
import { Link } from 'react-router-dom';
import { LinkButton } from 'components/common/LinkButton';
import { Box, Stack } from '@mui/material';
import Block from 'components/common/Block';
import ContentLoader from 'components/common/ContentLoader';

const SwitchLicense = () => {
  const [licenses, setLicenses] = useState([]);
  const { push } = useRouter();
  const logoutHandler = useLogout();
  const switchLicense = useSwitchLicense();
  const isSuperUser = useSelector(getIsSuperUser);
  document.title = `${MESSAGES.switch_license} - ${MESSAGES.custimy}`;

  const loadOwnerLicenses = async () => {
    const { license_details: userLicenses } = await getAllLicense();

    if (userLicenses?.length) {
      setLicenses(userLicenses);
    }
  };

  const {
    execute: loadOwnerLicensesAsync,
    isLoaded: isLoadOwnerLicensesLoaded,
  } = useAsync(loadOwnerLicenses);

  useEffect(() => {
    loadOwnerLicensesAsync();
  }, []);

  const addNewLicense = () => push(ROUTES.newLicense);
  const goToAdminPanel = () => push(ROUTES.adminPanelLicenses);

  const {
    execute: onSelectItemHandlerAsync,
  } = useAsync((details) => switchLicense(details.license));

  return (
    <div className="switch-license">
      <Block fillHeight fillContentHeight>
        <div className="switch-license__content">
          <ContentLoader isLoading={!isLoadOwnerLicensesLoaded}>
            <Stack spacing={2}>
              <Stack
                justifyContent="space-between"
                direction="row"
                className="switch-license__header"
              >
                <Link to={ROUTES.newLicense}>
                  <Button
                    onClick={addNewLicense}
                    type={BUTTON_TYPE.primary}
                    block
                  >
                    {MESSAGES.add_license}
                  </Button>
                </Link>
                <Stack spacing={2}>
                  {isSuperUser && (
                    <LinkButton
                      onClick={goToAdminPanel}
                      size="large"
                      type="secondary"
                    >
                      {MESSAGES.admin_panel}
                    </LinkButton>
                  )}
                  <LinkButton
                    onClick={logoutHandler}
                    size="large"
                    type="danger"
                  >
                    {MESSAGES.log_out}
                  </LinkButton>
                </Stack>
              </Stack>
              { isLoadOwnerLicensesLoaded && licenses.length ? (
                <LicenseTable licenses={licenses} onSelect={onSelectItemHandlerAsync} />
              ) : (
                <NoLicensesModal />
              )}
              <div className="switch-license__logo">
                <LogoSmall />
              </div>
            </Stack>
          </ContentLoader>
        </div>
      </Block>
    </div>
  );
};

export default SwitchLicense;
