import React, { useEffect, useState, useMemo } from 'react';
import propTypes from 'prop-types';
import zxcvbn from 'zxcvbn';
import classNames from 'classnames';
import { MESSAGES } from 'constants/messages';

import './styles.scss';

const RANGE = 5;

const Round = ({ isActive }) => (
  <div className={classNames('round', {
    'round--active': isActive,
  })}
  />
);

Round.propTypes = {
  isActive: propTypes.bool.isRequired,
};

const renderStrongName = (score) => {
  if (score <= 2) return MESSAGES.weak;
  if (score <= 3) return MESSAGES.good;
  if (score <= 4) return MESSAGES.strong;
  return MESSAGES.good_choice;
};

const calculateScore = (password) => zxcvbn(password).score + 1;

const PasswordScale = ({ password }) => {
  const [score, setScore] = useState(0);

  useEffect(() => {
    if (password.length === 0) {
      setScore(0);
      return;
    }

    setScore(calculateScore(password));
  }, [password]);

  const rounds = useMemo(() => Array(RANGE).fill(false).fill(true, 0, score), [score]);

  return (
    <div className="scale">
      <ul className="scale__range">
        {rounds.map((round, index) => <Round key={index} isActive={round} />)}
      </ul>
      <span className="scale__name">{renderStrongName(score)}</span>
    </div>
  );
};

PasswordScale.propTypes = {
  password: propTypes.string.isRequired,
};

export default PasswordScale;
