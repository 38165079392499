import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useAsync from 'hooks/useAsync';
import {
  deleteExperiment,
  exportExperimentGroup,
  getExperiment,
  updateExperiment,
} from 'api/experimentsAPI';
import useRouter from 'hooks/useRouter';
import { ROUTES } from 'constants/routes';
import { Box, Grid, Stack } from '@mui/material';
import Block from 'components/common/Block';
import FieldLabel from 'components/common/FieldLabel';
import { MESSAGES } from 'constants/messages';
import StatusIndicator from 'components/common/StatusIndicator';
import { EXPERIMENT_STATUSES } from 'constants/experiments';
import { ReactComponent as DeleteIcon } from 'assets/svg/trash.svg';
import ConfirmationWindow from 'components/common/ConfirmationWindow';
import Divider from 'components/common/Divider';
import Button from 'components/common/Button';
import { BUTTON_TYPE } from 'constants/common';
import './styles.scss';
import Table from 'components/common/Table';
import { ContentCell, PercentageCell } from 'components/common/CommonTableCells';
import ContentLoader from 'components/common/ContentLoader';
import { getChannels } from 'api/integrationsAPI';
import { keyBy, noop, values } from 'lodash';
import {
  ExperimentChannelsCell,
  ExperimentCustomerSizeCell,
  ExperimentGroupNameCell,
} from 'components/views/Experiments/ExperimentCells';
import { ReactComponent as ExportIcon } from 'assets/svg/export.svg';
import PropTypes from 'prop-types';
import { ReactComponent as CalendarIcon } from 'assets/svg/calendar.svg';
import moment from 'moment';
import { useToast } from 'components/common/Toast';
import ExperimentResults from 'components/views/Experiments/ExperimentView/ExperimentResults';
import IconButton from 'components/common/IconButton';
import Input from 'components/common/Input';
import EditableField from 'components/common/EditableField';
import TextArea from 'components/common/TextArea';
import { useDispatch, useSelector } from 'react-redux';
import { getSegments } from 'store/segments/getters';
import { getTagsFromFilters } from 'utils/filters';
import {
  CUSTOMER_DETAILS,
  CUSTOMER_EVENTS,
  CUSTOMER_PERFORMANCE,
  ENTITY_SEGMENT,
} from 'constants/entities';
import {
  CUSTOMER_DETAILS_FIELDS,
  CUSTOMER_EVENT_FIELDS,
  CUSTOMER_PERFORMANCE_FIELDS,
} from 'components/common/SearchEntity/constants';
import AdvancedFilterTag from 'components/common/AdvancedFilterTag';
import tagHelper from 'components/common/AdvancedFilterTag/tagHelper';
import { getCustomerFilterList } from 'api/customersAPI';
import { setExperimentName } from 'store/crumbsIdToName/crumbsIdToNameSlice';

const ExportGroupButton = ({ row }) => {
  const { createToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const handleExportClick = () => {
    setIsLoading(true);
    exportExperimentGroup(row.uuid).then(() => {
      setIsLoading(false);
    }).catch(() => {
      setIsLoading(false);
      createToast(
        MESSAGES.failed,
        MESSAGES.failed_to_export_group,
        'danger',
        'experiment-group-export-error',
      );
    });
  };

  return (
    <div className="export-button-wrapper">
      <Button
        type={BUTTON_TYPE.secondary}
        onClick={handleExportClick}
        icon={ExportIcon}
        isLoading={isLoading}
      >
        {MESSAGES.export}
      </Button>
    </div>
  );
};

ExportGroupButton.propTypes = {
  row: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
  }).isRequired,
};

ExportGroupButton.propTypes = {
  row: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
  }).isRequired,
};

const getTableConfig = (channelsMap, experiment) => [{
  title: MESSAGES.group_name,
  key: 'name',
  ordering: null,
  CellComponent: ExperimentGroupNameCell,
  componentProps: {
    groupCount: experiment.group_count,
  },
  width: '200px',
  hasOverflowEllipsis: true,
}, {
  title: MESSAGES.split_size,
  key: 'split_size',
  ordering: null,
  CellComponent: PercentageCell,
  componentProps: {},
  width: '150px',
  hasOverflowEllipsis: true,
}, {
  title: MESSAGES.customers,
  key: 'size',
  ordering: null,
  CellComponent: ExperimentCustomerSizeCell,
  componentProps: {},
  width: '150px',
  hasOverflowEllipsis: true,
}, {
  title: MESSAGES.channels,
  key: 'channels',
  ordering: null,
  CellComponent: ExperimentChannelsCell,
  componentProps: { channelsMap },
  width: '200px',
  hasOverflowEllipsis: false,
}, {
  title: null,
  key: null,
  ordering: null,
  CellComponent: ContentCell,
  componentProps: {
    content: ExportGroupButton,
  },
  width: '100px',
  hasOverflowEllipsis: false,
}];

const statusesToShowResults = [
  EXPERIMENT_STATUSES.ACTIVE.value,
  EXPERIMENT_STATUSES.COMPLETED.value,
];

const getEntityKeysToFields = (segments) => {
  const segmentFields = segments.map(({ segment }) =>
    ({ key: segment.id, title: segment.segment_name }));
  return [
    {
      entityKey: CUSTOMER_PERFORMANCE,
      fields: CUSTOMER_PERFORMANCE_FIELDS,
    },
    {
      entityKey: CUSTOMER_DETAILS,
      fields: CUSTOMER_DETAILS_FIELDS,
    },
    {
      entityKey: CUSTOMER_EVENTS,
      fields: CUSTOMER_EVENT_FIELDS,
    },
    {
      entityKey: ENTITY_SEGMENT,
      fields: segmentFields,
      hasDynamicFields: true,
    },
  ];
};

const experimentDescriptions = {
  [EXPERIMENT_STATUSES.DRAFT.value]: {
    header: MESSAGES.prepare_experiment,
    description: MESSAGES.experiment_description_draft,
  },
  [EXPERIMENT_STATUSES.ACTIVE.value]: {
    header: MESSAGES.observe_experiment,
    description: MESSAGES.experiment_description_active,
  },
  [EXPERIMENT_STATUSES.COMPLETED.value]: {
    header: MESSAGES.evaluate_experiment,
    description: MESSAGES.experiment_description_complete,
  },
};

const toIntervalValues = (data, segments = []) => ({
  ...data,
  tags: getTagsFromFilters(data.filters, getEntityKeysToFields(segments)),
});

const ExperimentView = () => {
  const { uuid } = useParams();
  const { push } = useRouter();
  const { createToast } = useToast();
  const dispatch = useDispatch();
  const segmentStates = useSelector(getSegments);

  const [channelsMap, setChannelsMap] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isCompleting, setIsCompleting] = useState(false);
  const [updatedInfo, setUpdatedInfo] = useState({
    name: '',
    description: '',
  });

  const [experiment, setExperiment] = useState({
    uuid: null,
    name: '',
    description: '',
    tags: [],
    started_date: null,
    completed_date: null,
    status: EXPERIMENT_STATUSES.DRAFT.value,
    created_at: null,
    creator: null,
    groups: [
    ],
    group_count: 0,
    size: 0,
  });

  const resetUpdatedInfo = () => {
    setUpdatedInfo({
      name: experiment.name,
      description: experiment.description,
    });
  };

  useEffect(resetUpdatedInfo, [experiment]);

  useEffect(() => {
    const populateChannels = async () => {
      const channelsList = await getChannels();
      setChannelsMap(keyBy(channelsList, 'uuid'));
    };

    populateChannels();
  }, []);

  const tableConfig = getTableConfig(channelsMap, experiment);
  const goToList = () => push(ROUTES.experiments);

  const fetchExperiment = async () => {
    await getExperiment(uuid).then((fetchedExperiment) => {
      setExperiment(toIntervalValues(fetchedExperiment, segmentStates));
    }).catch(() => {
      goToList();
    });
  };

  const { execute: executeInitialFetch, isLoading } = useAsync(fetchExperiment);

  useEffect(() => {
    if (!uuid) return;
    executeInitialFetch();
  }, [uuid]);

  const handleExperimentDelete = () => deleteExperiment(experiment.uuid).then(() => {
    setIsDeleting(false);
    goToList();
  });

  const updateExperimentInfo = async (name, description) => {
    const body = {
      name,
      description,
    };

    setExperiment({ ...experiment, ...body });

    await updateExperiment(experiment.uuid, body).then((newExperiment) => {
      setExperiment(toIntervalValues(newExperiment, segmentStates));
    }).catch(() => {
      createToast(
        MESSAGES.failed,
        MESSAGES.failed_to_updated,
        'danger',
        'experiment-info-update-error',
      );
    });
  };

  useEffect(() => {
    setExperiment(toIntervalValues(experiment, segmentStates));
  }, [segmentStates]);

  const submitHandler = async (status) => {
    const body = {
      status,
    };

    await updateExperiment(experiment.uuid, body).then((newExperiment) => {
      setExperiment(toIntervalValues(newExperiment, segmentStates));
    }).catch(() => {
      createToast(
        MESSAGES.failed,
        MESSAGES.failed_to_start_or_stop_experiment,
        'danger',
        'experiment-status-update-error',
      );
    });
  };

  const {
    execute: executeUpdateStatus,
    isLoading: isLoadingStatusUpdate,
  } = useAsync(submitHandler);

  const handleStartExperiment = () =>
    executeUpdateStatus(EXPERIMENT_STATUSES.ACTIVE.value);

  const handleCompleteExperiment = () => {
    executeUpdateStatus(EXPERIMENT_STATUSES.COMPLETED.value);
    setIsCompleting(false);
  };

  const shouldShowResults = statusesToShowResults.includes(experiment.status);

  const handleNameChange = ({ target }) =>
    setUpdatedInfo({ ...updatedInfo, name: target.value });

  const handleDescriptionChange = ({ target }) =>
    setUpdatedInfo({ ...updatedInfo, description: target.value });

  const handleUpdateInfo = () =>
    updateExperimentInfo(updatedInfo.name, updatedInfo.description);

  const {
    tags,
    fields,
    operators,
    setTags,
  } = tagHelper(null, noop, [], getCustomerFilterList);

  useEffect(() => {
    setTags(experiment.tags);
  }, [experiment.tags]);

  useEffect(() => {
    dispatch(setExperimentName({ [uuid]: experiment.name }));
  }, [experiment.name]);

  return (
    <>
      <div className="experiment-view">
        <ContentLoader isLoading={isLoading}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Block
                header={experimentDescriptions[experiment.status]?.header}
                postHeader={experimentDescriptions[experiment.status]?.description}
                type="large-header"
              />
            </Grid>
            <Grid item xs={12}>
              <Block>
                <Grid container spacing={2}>
                  <Grid
                    container
                    item
                    xs={12}
                    direction="row-reverse"
                    spacing={2}
                  >
                    <Grid
                      item
                      xl={8}
                      lg={6}
                      md={4}
                      xs={12}
                      display="flex"
                      justifyContent={{ md: 'flex-end', xs: 'flex-start' }}
                    >
                      <Box>
                        <FieldLabel label={MESSAGES.status} inline>
                          <StatusIndicator
                            statuses={values(EXPERIMENT_STATUSES)}
                            value={experiment.status}
                          />
                        </FieldLabel>
                      </Box>
                    </Grid>
                    <Grid item xl={4} lg={6} md={8} xs={12}>
                      <FieldLabel label={MESSAGES.name}>
                        <EditableField
                          value={experiment.name}
                          hasDiscard
                          hasSave
                          onSave={handleUpdateInfo}
                          onDiscard={resetUpdatedInfo}
                        >
                          <Input
                            id="name"
                            name="name"
                            label={MESSAGES.experiment_name_placeholder}
                            value={updatedInfo.name}
                            hasFloatLabel={false}
                            maxLength={100}
                            onChange={handleNameChange}
                          />
                        </EditableField>
                      </FieldLabel>
                    </Grid>
                  </Grid>
                  <Grid item xl={4} lg={6} md={8} xs={12}>
                    <FieldLabel label={MESSAGES.description}>
                      <EditableField
                        value={experiment.description}
                        hasDiscard
                        hasSave
                        placeholder={MESSAGES.experiment_description_placeholder}
                        onSave={handleUpdateInfo}
                        onDiscard={resetUpdatedInfo}
                      >
                        <TextArea
                          name="description"
                          placeholder={MESSAGES.experiment_description_placeholder}
                          characterLimit={300}
                          onChange={handleDescriptionChange}
                          value={updatedInfo.description}
                          allowVerticalResize
                        />
                      </EditableField>
                    </FieldLabel>
                  </Grid>
                  <Grid item xs={12} container spacing={1}>
                    {tags && tags.map((tag) => (
                      <Grid key={tag.key} item>
                        <AdvancedFilterTag
                          tag={tag}
                          fields={fields}
                          operators={operators}
                          searchEntityConfig={[]}
                          disabled
                        />
                      </Grid>
                    ))}
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Stack spacing={4} direction="row">
                      <FieldLabel label={MESSAGES.started} sizeToContents>
                        <Stack spacing={1} direction="row">
                          <CalendarIcon className="experiment-view__date-icon" />
                          <span className="experiment-view__date">
                            {experiment.started_date
                              ? moment(experiment.started_date, 'YYYY-MM-DDTHH:mm:ssZ').format('MMMM Do, YYYY')
                              : MESSAGES.not_started }
                          </span>
                        </Stack>
                      </FieldLabel>
                      <FieldLabel label={MESSAGES.completed} sizeToContents>
                        <Stack spacing={1} direction="row">
                          <CalendarIcon className="experiment-view__date-icon" />
                          <span className="experiment-view__date">
                            {experiment.completed_date
                              ? moment(experiment.completed_date, 'YYYY-MM-DDTHH:mm:ssZ').format('MMMM Do, YYYY')
                              : MESSAGES.not_completed }
                          </span>
                        </Stack>
                      </FieldLabel>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Table
                      data={experiment.groups}
                      tableConfig={tableConfig}
                      initSort={['name']}
                      highlightStartIdx={0}
                      highlightEndIdx={4}
                      keyFunc={(group) => group.uuid}
                    />
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="flex-end"
                      >
                        <IconButton
                          icon={DeleteIcon}
                          type="danger"
                          size="medium"
                          tooltip={MESSAGES.delete}
                          tooltipPlacement="left"
                          uncolored
                          onClick={() => setIsDeleting(true)}
                        />
                        {experiment.status === EXPERIMENT_STATUSES.DRAFT.value && (
                          <Button
                            type={BUTTON_TYPE.primary}
                            onClick={handleStartExperiment}
                            isLoading={isLoadingStatusUpdate}
                          >
                            {MESSAGES.start_experiment}
                          </Button>
                        )}
                        {experiment.status === EXPERIMENT_STATUSES.ACTIVE.value && (
                          <Button
                            type={BUTTON_TYPE.primary}
                            onClick={() => setIsCompleting(true)}
                            isLoading={isLoadingStatusUpdate}
                          >
                            {MESSAGES.complete_experiment}
                          </Button>
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Block>
            </Grid>
            {shouldShowResults && (
              <Grid item xs={12}>
                <Block header={MESSAGES.performance}>
                  {!!experiment.uuid && (
                    <ExperimentResults experiment={experiment} />
                  )}
                </Block>
              </Grid>
            )}
          </Grid>
        </ContentLoader>
      </div>
      <ConfirmationWindow
        content={MESSAGES.experiment_delete_confirmation}
        open={!!isDeleting}
        onCancel={() => setIsDeleting(false)}
        onConfirm={handleExperimentDelete}
        inWorkArea
        forceBlocking={false}
        danger
      />
      <ConfirmationWindow
        content={MESSAGES.experiment_complete_confirmation}
        open={!!isCompleting}
        onCancel={() => setIsCompleting(false)}
        onConfirm={handleCompleteExperiment}
        inWorkArea
        forceBlocking={false}
        warning
      />
    </>
  );
};

export default ExperimentView;
