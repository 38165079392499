import {
  EMAIL_ERROR_REQUIRED,
  PASSWORD_ERROR_REQUIRED,
} from 'constants/errors';
import { Validator } from 'utils';

export default ({ email, password }) => {
  const errors = {};

  const emailValidator = new Validator(email);
  const passwordValidator = new Validator(password);

  try {
    emailValidator
      .required(EMAIL_ERROR_REQUIRED);
  } catch (error) {
    errors.email = error.message;
  }

  try {
    passwordValidator
      .required(PASSWORD_ERROR_REQUIRED);
  } catch (error) {
    errors.password = error.message;
  }

  return errors;
};
