export const EMAIL_MIN_LENGTH = 6;
export const EMAIL_MAX_LENGTH = 100;
export const EMAIL_PATTERN = /^.+[@][\w-]+([.]\w+)+$/;

export const PASSWORD_PATTERN_LETTER_NUMBER = /^(?=.*[0-9])(?=.*[A-Za-z])/;
export const PASSWORD_PATTERN_UPPERCASE_LOWERCASE = /^(?=.*[a-z])(?=.*[A-Z])/;
export const PASSWORD_PATTERN_SPECIAL_XTERS = /^(?=.*[,{}[\]:";'<>?/!$%^&*()_+|~\-=\\.#`])/;
export const PASSWORD_PATTERN = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[,{}[\]:";'<>?/!$%^&*()_+|~\-=\\.#`]).{8,}$/;
export const IMAGE_PATTERN = /\.(jpe?g|png)$/;
export const FIRST_NAME_MIN_LENGTH = 2;
export const FIRST_NAME_MAX_LENGTH = 50;

export const LAST_NAME_MIN_LENGTH = 2;
export const LAST_NAME_MAX_LENGTH = 50;

export const COMPANY_NAME_MIN_LENGTH = 3;
export const COMPANY_NAME_MAX_LENGTH = 20;

export const COMPANY_URL_MIN_LENGTH = 4;
export const COMPANY_URL_MAX_LENGTH = 100;
export const COMPANY_URL_PATTERN = /^(http:\/\/|https:\/\/|www\.|[a-zA-Z])[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:/~+#-]*[\w@?^=%&amp;/~+#-])$/;

export const SESSION_EXPIRES_AT = 1.8e+6;
export const PERMANENT_EXPIRES_AT = 8.64e+8;
