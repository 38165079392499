import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import 'components/common/AdvancedFilterTag/styles.scss';
import FilterTag from 'components/common/FilterTag';
import Popover from '@mui/material/Popover';
import { get, noop } from 'lodash';
import AdvancedFilterTagField
  from 'components/common/AdvancedFilterTag/AdvancedFilterTagField';
import Select from 'components/common/Select';
import TypedInput from 'components/common/TypedInput';
import { v1 as uuidv1 } from 'uuid';
import { INPUT_VALUE_TYPE } from 'constants/common';

const OPERATOR_SIGNS = {
  exact: '=',
  lt: '<',
  gt: '>',
  icontains: '∋',
  noticontains: '∌',
  top: '↑',
  bottom: '↓',
};

const isFilterValid = (isVariable, field, operator, value) => {
  if (!isVariable) {
    return !!field;
  }

  return !!(field && operator && (value || value === 0));
};

const AdvancedFilterTag = ({
  tag,
  fields,
  operators,
  searchEntityConfig,
  onTagChange,
  onRemove,
  disabled,
}) => {
  const anchorRef = useRef();
  const [isOpened, setIsOpened] = useState(false);
  const [field, setField] = useState(null);
  const [operator, setOperator] = useState(null);
  const [value, setValue] = useState('');
  const [fieldType, setFieldType] = useState(null);
  const [operatorsList, setOperatorsList] = useState([]);
  const isVariable = !!operatorsList && !!operatorsList.length;

  const fieldChanged = (newTag) => {
    const newFieldType = newTag.type || get(fields, [newTag.uuid, 'type']);

    if (newFieldType !== fieldType) {
      const newOperatorList = operators[newFieldType] || [];
      setOperatorsList(newOperatorList);

      const newOperator = newOperatorList.find((x) => x.value === tag.operator);
      setOperator(newOperator || null);
    }

    if (!newTag.finalized && !!newFieldType) {
      setIsOpened(true);
    }

    setValue(newTag.value || '');
    setFieldType(newFieldType);
    setField({
      value: newTag.uuid,
      label: newTag.name,
      entityKey: newTag.entityKey,
    });
  };

  useEffect(() => {
    fieldChanged(tag);
  }, [tag, operators]);

  const changeTag = (newValue) => {
    if (!isFilterValid(isVariable, field, operator, newValue)) {
      onRemove();
      return;
    }

    let newTag = {
      key: uuidv1(),
      entityKey: field.entityKey,
      uuid: field.value,
      name: field.label,
      finalized: true,
    };

    if (isVariable) {
      newTag = {
        ...newTag,
        operator: operator.value,
        value: newValue,
        type: fieldType,
      };
    }

    onTagChange(newTag);
  };

  const handlePopoverClose = () => {
    changeTag(value);
    setIsOpened(false);
  };

  const handleInputKeyPress = (e) => {
    if (e.key === 'Enter') {
      changeTag(e.target.value);
      setIsOpened(false);
    }
  };

  const handleFieldChange = (newField) => {
    setOperator(null);
    setValue('');
    onTagChange({
      key: tag.key,
      ...newField,
    });
  };

  const suffix = tag.type === 'percent' ? '%' : null;

  return (
    <>
      <div ref={anchorRef}>
        <FilterTag
          onRemoveClick={onRemove}
          onTagClick={() => setIsOpened(true)}
          isActive={isOpened}
          disabled={disabled}
        >
          <div className="products-filter-tag__content">
            <span className="products-filter-tag__content__name text-ellipsis">
              {tag.name}
            </span>
            {tag.operator && (
              <span className="products-filter-tag__content__operator">
                {OPERATOR_SIGNS[tag.operator]}
              </span>
            )}
            {(tag.value || tag.value === 0) && (
              <span className="products-filter-tag__content__value">
                {tag.type === INPUT_VALUE_TYPE.boolean ? tag.value.label : tag.value}
                {suffix}
              </span>
            )}
          </div>
        </FilterTag>
      </div>
      <Popover
        open={isOpened}
        anchorEl={anchorRef.current}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        classes={{ paper: 'product-filter-tag', root: 'product-filter-tag__root' }}
      >
        <div className="product-filter-tag__popover">
          <AdvancedFilterTagField
            field={field}
            fieldChanged={handleFieldChange}
            searchEntityConfig={searchEntityConfig}
          />
          {isVariable && (
            <>
              <div className="product-filter-tag__popover__operator">
                <Select
                  name="operator-select"
                  label="Operator"
                  value={operator}
                  onChange={(e) => setOperator(e.target.value)}
                  onBlur={() => undefined}
                  options={operatorsList}
                  isFixedLabel
                />
              </div>
              <div className="product-filter-tag__popover__value">
                <TypedInput
                  name="filter-tag-value-input"
                  type={fieldType}
                  value={value}
                  valueChanged={setValue}
                  label="Value"
                  isFixedLabel
                  onKeyPress={handleInputKeyPress}
                />
              </div>
            </>
          )}
        </div>
      </Popover>
    </>

  );
};

AdvancedFilterTag.propTypes = {
  tag: PropTypes.shape({
    key: PropTypes.string.isRequired,
    uuid: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    name: PropTypes.string.isRequired,
    entityKey: PropTypes.string.isRequired,
    operator: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.string,
    finalized: PropTypes.bool,
  }).isRequired,
  fields: PropTypes.objectOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  operators: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  searchEntityConfig: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  })).isRequired,
  onTagChange: PropTypes.func,
  onRemove: PropTypes.func,
  disabled: PropTypes.bool,
};

AdvancedFilterTag.defaultProps = {
  onTagChange: noop,
  onRemove: noop,
  disabled: false,
};

export default AdvancedFilterTag;
