import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';

import { ReactComponent as LogoIcon } from 'assets/svg/logo.svg';
import { ReactComponent as LogoSmallIcon } from 'assets/svg/logoMin.svg';
import { LOGO_TYPE } from 'constants/common';
import './styles.scss';

const renderLogo = (type) => {
  switch (type) {
    case LOGO_TYPE.primary:
      return <LogoIcon />;
    case LOGO_TYPE.small:
      return <LogoSmallIcon />;
    default:
      return null;
  }
};

const Logo = ({ onClick, type }) => (
  <button type="button" className={classNames('logo', { clickable: onClick })} onClick={onClick}>
    {renderLogo(type)}
  </button>
);

Logo.propTypes = {
  onClick: propTypes.func,
  type: propTypes.string,
};

Logo.defaultProps = {
  onClick: undefined,
  type: LOGO_TYPE.primary,
};

export default Logo;
