import { http } from 'utils';
import { ENDPOINTS } from 'constants/routes';
import { HTTP_METHODS } from 'constants/http';

export const getSegments = () => http({
  url: ENDPOINTS.segments,
  method: HTTP_METHODS.GET,
});

export const getSegmentsInfo = (search = '') => http({
  url: `${ENDPOINTS.segmentsInfo}?search=${search}`,
  method: HTTP_METHODS.GET,
});

export const getSegmentById = ({ id }) => http({
  url: `${ENDPOINTS.segments}${id}/`,
  method: HTTP_METHODS.GET,
});

export const getSegmentGraphicById = ({ id }) => http({
  url: `${ENDPOINTS.segmentsGraphic}?segment=${id}&page=1&page_size=5/`,
  method: HTTP_METHODS.GET,
});

export const exportSegmentById = ({ id, flag }) => http({
  url: `${ENDPOINTS.segmentsExport}${id}?accepts_marketing=${flag}`,
  method: HTTP_METHODS.GET,
});
