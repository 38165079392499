import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PaymentFormInput from 'containers/PrivateApp/Payment/PaymentForm/PaymentFormInput';
import { getPromotionCode } from 'api/paymentAPI';
import './styles.scss';
import { ReactComponent as RedeemIcon } from 'assets/svg/redeem.svg';
import { MESSAGES } from 'constants/messages';
import { LinkButton } from 'components/common/LinkButton';

const PROMOTION_CODE = {
  promotionCodeInfo: 'promotionCodeInfo',
  promotionCode: 'promotionCode',
}

const PromotionCode = ({
  onChange, onBlur, setSubmitDisabled, ...rest
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const [success, setSuccess] = useState(false);

  const changePromotionCodeInfo = (value) => onChange({
    target: {
      name: PROMOTION_CODE.promotionCodeInfo,
      value,
    },
  });

  const changeOpened = (shouldOpen) => {
    setIsOpened(shouldOpen);
    changePromotionCodeInfo({ shouldOpen });
  };

  const handleBlur = async (e) => {
    // handler is async so event object can be gone, that's why we create a copy
    const eventCopy = {
      target: {
        name: e.target.name,
        value: e.target.value,
      },
    };

    onBlur(eventCopy);

    if (eventCopy.target.value) {
      try {
        const promotionCode = await getPromotionCode(eventCopy.target.value);
        changePromotionCodeInfo({
          isOpened,
          isBundle: promotionCode.is_bundle,
          data: promotionCode.data,
          error: null,
        });
        setSuccess(true);
      } catch (error) {
        changePromotionCodeInfo({
          isOpened,
          isBundle: false,
          data: null,
          error: error[0],
        });
        setSuccess(false);
      }
    }

    setSubmitDisabled(false);
  };

  const clear = (e) => {
    changeOpened(false);
    onChange({ target: { name: PROMOTION_CODE.promotionCode, undefined } });
    setSuccess(false);
  };

  return (
    isOpened
      ? (
        <div className="promotion_code__input">
          <PaymentFormInput
            onChange={onChange}
            onBlur={handleBlur}
            onFocus={() => setSubmitDisabled(true)}
            hasSuccess={success}
            {...rest}
          />
          <div className="promotion_code__input-close" onClick={clear}>
            {MESSAGES.close}
          </div>
        </div>
      ) : (
        <div className="promotion_code__link">
          <LinkButton
            icon={RedeemIcon}
            onClick={() => changeOpened(true)}
            size="medium"
            type="secondary"
          >
            {MESSAGES.redeem_coupon_code}
          </LinkButton>
        </div>
      )
  );
};

PromotionCode.propTypes = {
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  setSubmitDisabled: PropTypes.func.isRequired,
};

export default PromotionCode;
