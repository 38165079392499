import React, { useState, useCallback } from 'react';
import './styles.scss';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

const CohortGraph = ({
  cohortRows, currency, maxMonthsSince, excludeFirst,
}) => {
  const [highlightedMonth, setHighlightedMonth] = useState(null);

  const handleMonthHighlight = (highlightedIndex) => {
    if (highlightedMonth === highlightedIndex) return;
    setHighlightedMonth(highlightedIndex);
  };

  const onMonthHover = useCallback(debounce(handleMonthHighlight, 100), [highlightedMonth]);

  const options = {
    onHover: (e, activeEls) => onMonthHover(activeEls.length ? activeEls[0].datasetIndex : null),
    responsive: true,
    tension: 0.3,
    maintainAspectRatio: false,
    interaction: {
      mode: 'point',
      intersect: false,
    },
    stacked: false,
    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        grid: {
          drawOnChartArea: false,
        },
      },
    },
    plugins: {
      legend: {
        position: 'bottom',
        onHover: (e, item) => onMonthHover(item.datasetIndex),
        onLeave: () => onMonthHover(null),
        labels: {
          color: '#F5F5F5',
        },
      },
      tooltip: {
        backgroundColor: '#fbfcfd',
        titleColor: '#869b9b',
        titleFont: { weight: 'normal' },
        displayColors: false,
        bodyColor: '#869b9b',
        bodyFont: { weight: 'bold' },
        cornerRadius: 5,
        callbacks: {
          label(context) {
            return `${context.dataset.label}: ${context.formattedValue} ${currency || ''}`;
          },
        },
      },
    },
  };

  const getMonthOpacity = (month) =>
    (highlightedMonth !== null && month !== highlightedMonth ? 0.1 : 1);

  const getColorForMonth = (month) =>
    `hsla(${(360 / (maxMonthsSince + 1)) * (month + 1)}, 70%, 45%, ${getMonthOpacity(month)})`;

  const getDataSets = () => cohortRows.filter((row) => !!row?.listed?.length).map((row, month) => ({
    label: moment(row.date, 'YYYY-MM-DDTHH:mm:ssZ').format('MMM YYYY'),
    data: row.listed,
    borderColor: getColorForMonth(month),
    backgroundColor: getColorForMonth(month),
  }));

  const getLabels = () => {
    const labels = [];
    for (let i = 0 + (excludeFirst ? 1 : 0); i <= maxMonthsSince; i++) {
      labels.push(`Month ${i}`);
    }
    return labels;
  };

  const data = {
    labels: getLabels(),
    datasets: getDataSets(),
  };

  return (
    <div className="cohort-graph-wrapper">
      <Line
        options={options}
        data={data}
      />
    </div>
  );
};

CohortGraph.propTypes = {
  cohortRows: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.string.isRequired,
    listed: PropTypes.arrayOf(PropTypes.number).isRequired,
  })).isRequired,
  currency: PropTypes.string.isRequired,
  maxMonthsSince: PropTypes.number.isRequired,
  excludeFirst: PropTypes.bool,
};

CohortGraph.defaultProps = {
  excludeFirst: false,
};

export default CohortGraph;
