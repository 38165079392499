import React, { useEffect, useMemo, useState } from 'react';

import 'components/views/Settings/LicenseSettings/LicenseMembers/styles.scss';
import LicenseMembersTable from 'components/views/Settings/LicenseSettings/LicenseMembers/LicenseMembersTable';
import ConfirmationInviteButton from 'components/views/Settings/LicenseSettings/LicenseMembers/InviteUser';
import { MAX_MEMBER_PER_LICENSE } from 'constants/license';
import { useSelector } from 'react-redux';
import useAsync from 'hooks/useAsync';
import { inviteUser } from 'api/usersAPI';
import { deleteLicenseActionMember, getLicenseMembers, updateLicenseMemberStatus } from 'api/licenseAPI';
import { getCurrentLicense, getIsLicenseAdmin, getLicenseDetails } from 'store/license/getters';
import DisabledInviteUserButton
  from 'components/views/Settings/LicenseSettings/LicenseMembers/InviteUser/DisabledInviteUserButton';
import { LICENSE_USERS_PER_PAGE } from 'constants/common';
import DataRange from 'components/common/PaginationTools/DataRange';
import Pagination from 'components/common/PaginationTools/Pagination';
import { getPaginationDetails } from 'components/common/PaginationTools/getPaginationDetails';
import classNames from 'classnames';
import { Box, Grid } from '@mui/material';

const LicenseMembers = () => {
  const {
    license: {
      uuid: licenseUUID,
    },
  } = useSelector(getLicenseDetails);
  const [fullLicenseMembers, setFullLicenseMembers] = useState([]);
  const isAdministrator = useSelector(getIsLicenseAdmin);
  const { is_demo } = useSelector(getCurrentLicense);
  const [page, setPage] = useState(1);
  const [licenseMembers, setLicenseMembers] = useState([]);

  const loadLicenseMembers = async () => {
    const userLicenseDetails = await getLicenseMembers({ licenseUUID });

    if (userLicenseDetails) {
      setFullLicenseMembers(userLicenseDetails);
      if (licenseMembers.length === 0) setLicenseMembers(userLicenseDetails.slice(0, LICENSE_USERS_PER_PAGE));
      else {
        const { from: fromValue, to: toValue } = getPaginationDetails(page, userLicenseDetails.length, LICENSE_USERS_PER_PAGE);
        setLicenseMembers(userLicenseDetails.slice(fromValue - 1, toValue));
      }
    }
  };

  const totalCount = fullLicenseMembers.length;
  const countPerPage = LICENSE_USERS_PER_PAGE;

  const { from, to, maxPage } = useMemo(
    () => getPaginationDetails(page, totalCount, countPerPage),
    [page, totalCount, countPerPage],
  );

  const handlePageChange = (pageNr) => {
    setPage(pageNr);
    pageNr === 1
      ? setLicenseMembers(fullLicenseMembers.slice(0, LICENSE_USERS_PER_PAGE))
      : setLicenseMembers(fullLicenseMembers.slice(
        (pageNr - 1) * LICENSE_USERS_PER_PAGE, (pageNr * LICENSE_USERS_PER_PAGE),
      ));
  };

  const {
    isLoaded,
    execute: loadLicenseMembersAsync,
  } = useAsync(loadLicenseMembers);

  useEffect(() => {
    loadLicenseMembersAsync();
  }, []);

  const deleteLicenseActionMemberHandler = async (member) => {
    try {
      await deleteLicenseActionMember({ licenseDetailsUUID: member.uuid });
    } finally {
      await loadLicenseMembersAsync();
    }
  };
  const selectLicenseStatusHandler = async (selectedStatus, member) => {
    try {
      await updateLicenseMemberStatus({
        licenseUUID: member.uuid,
        new_license_status: selectedStatus.value,
      });
    } finally {
      await loadLicenseMembersAsync();
    }
  };

  const confirmInviteUserHandler = async (email) => {
    const requestBody = {
      email,
      license: licenseUUID,
    };

    await inviteUser(requestBody);
    await loadLicenseMembersAsync();
  };

  const {
    execute: deleteLicenseActionMemberAsync,
  } = useAsync(deleteLicenseActionMemberHandler);
  const {
    execute: confirmInviteUserAsync,
    status: confirmInviteUserStatus,
  } = useAsync(confirmInviteUserHandler, true);

  return (
    <div className="license-members">
      <div className="license-members__table">
        <LicenseMembersTable
          licenseMembers={licenseMembers}
          onDelete={deleteLicenseActionMemberAsync}
          onSelect={selectLicenseStatusHandler}
          isAdministrator={isAdministrator}
          confirmInviteUserHandler={confirmInviteUserAsync}
          confirmInviteUserStatus={confirmInviteUserStatus}
          page={page}
          totalCount={totalCount}
          countPerPage={LICENSE_USERS_PER_PAGE}
          handlePageChange={handlePageChange}
          isDataLoading={!isLoaded}
        />
      </div>
      <div className={classNames('license-members__footer', {
        'no-users': fullLicenseMembers.length === 0,
      })}
      >
        <Grid container spacing={2}>
          <Grid
            item
            md={3}
            xs={12}
            component={Box}
            display="flex"
            justifyContent={{ md: 'flex-start', xs: 'center' }}
          >
            {fullLicenseMembers.length > 0 && (
              <DataRange
                totalCount={totalCount}
                from={from}
                to={to}
              />
            )}
          </Grid>
          <Grid item md={6} xs={12} className="pagination-wrapper">
            {fullLicenseMembers.length > 0 && (
              <Pagination
                page={page}
                maxPage={maxPage}
                pageChanged={handlePageChange}
              />
            )}
          </Grid>
          {isAdministrator && (
            <Grid item md={3} xs={12}>
              <div className="license-members__footer__buttons">
                {fullLicenseMembers.length >= MAX_MEMBER_PER_LICENSE && !is_demo ? (
                  <DisabledInviteUserButton />
                ) : (
                  <ConfirmationInviteButton onConfirm={confirmInviteUserAsync} />
                )}
              </div>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default LicenseMembers;
