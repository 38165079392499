import React from 'react';
import propTypes from 'prop-types';
import { ReactComponent as WarningIcon } from 'assets/svg/warning.svg';

import './styles.scss';

const CustomFeedback = ({ hint }) => {
  const { header, body } = hint;

  return (
    <div className="custom-feedback">
      <div className="custom-feedback__logo">
        <WarningIcon width="25px" height="20px" fill="#FED17B" />
      </div>
      <div feedback>
        {!!header && <h3 className="feedback__header">{header}</h3>}
        {!!body && <p className="feedback__body">{body}</p>}
      </div>
    </div>
  );
};

CustomFeedback.propTypes = {
  hint: propTypes.objectOf(propTypes.string).isRequired,
};

export default CustomFeedback;
