import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-switch';

const StatusSwitch = ({
  checked, onChange,
}) => (
  <div className="status-switch-container">
    <Switch
      className="status-switch"
      checked={checked}
      onChange={(x) => onChange(x)}
      offColor="#e4e4e4"
      onColor="#e4e4e4"
      offHandleColor="#FDC910"
      onHandleColor="#05C168"
      width={30}
      height={12}
      checkedIcon={false}
      uncheckedIcon={false}
      handleDiameter={16}
    />
  </div>
);

StatusSwitch.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default StatusSwitch;
