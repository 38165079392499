import {
  FIRST_NAME_ERROR_REQUIRED,
  FIRST_NAME_ERROR_MIN_LENGTH,
  FIRST_NAME_ERROR_MAX_LENGTH,
  LAST_NAME_ERROR_REQUIRED,
  LAST_NAME_ERROR_MIN_LENGTH,
  LAST_NAME_ERROR_MAX_LENGTH,
  WORK_ROLE_ERROR_REQUIRED,
} from 'constants/errors';
import {
  FIRST_NAME_MIN_LENGTH,
  FIRST_NAME_MAX_LENGTH,
  LAST_NAME_MIN_LENGTH,
  LAST_NAME_MAX_LENGTH,
} from 'constants/validation';

import { Validator } from 'utils';

export default ({ firstName, lastName, workRole }) => {
  const errors = {};

  const firstNameValidator = new Validator(firstName);
  const lastNameValidator = new Validator(lastName);
  const workRoleValidator = new Validator(workRole);

  try {
    firstNameValidator
      .required(FIRST_NAME_ERROR_REQUIRED)
      .minLength(FIRST_NAME_MIN_LENGTH, FIRST_NAME_ERROR_MIN_LENGTH)
      .maxLength(FIRST_NAME_MAX_LENGTH, FIRST_NAME_ERROR_MAX_LENGTH);
  } catch (error) {
    errors.firstName = error.message;
  }

  try {
    lastNameValidator
      .required(LAST_NAME_ERROR_REQUIRED)
      .minLength(LAST_NAME_MIN_LENGTH, LAST_NAME_ERROR_MIN_LENGTH)
      .maxLength(LAST_NAME_MAX_LENGTH, LAST_NAME_ERROR_MAX_LENGTH);
  } catch (error) {
    errors.lastName = error.message;
  }

  try {
    workRoleValidator
      .required(WORK_ROLE_ERROR_REQUIRED);
  } catch (error) {
    errors.workRole = error.message;
  }

  return errors;
};
