import React from 'react';
import { Grid } from '@mui/material';

import { MESSAGES } from 'constants/messages';
import Checkbox from 'components/common/Checkbox';
import { identity, noop, find } from 'lodash';
import Table from 'components/common/Table/index';
import PropTypes from 'prop-types';
import { LinkButton } from 'components/common/LinkButton';

const CheckBoxBlock = ({
  tableConfig, maxColumnsNum, isChecked, onCheckBoxChange, onReset,
}) => {
  const keys = Object.keys(isChecked);
  const checkedNum = Object.values(isChecked).filter(identity).length;
  const toggleValue = (key) => {
    const newValue = !isChecked[key];

    onCheckBoxChange({ ...isChecked, [key]: newValue });
  };

  const isDisabled = (key) => {
    const maxCondition = checkedNum >= maxColumnsNum && !isChecked[key];
    const lowCondition = checkedNum <= 1 && isChecked[key];

    return maxCondition || lowCondition;
  };

  const getColumnConfig = (key) => find(tableConfig, ['key', key]);

  return (
    <Grid container>
      <div className="check-box-block">
        <Grid item xs={12}>
          <div className="check-box-form">
            <div className="title">
              {MESSAGES.table_view}
            </div>
            <div className="subtitle">
              {MESSAGES.select_maximum_columns}
            </div>
            <div className="checkboxes">
              <div className="checkbox-items-wrapper">
                {keys.map((key) => (
                  <div key={key}>
                    <Checkbox
                      disabled={isDisabled(key)}
                      id={key}
                      value={isChecked[key]}
                      onChange={() => toggleValue(key)}
                      label={getColumnConfig(key).title}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="button-group">
            <LinkButton
              type="danger"
              onClick={onReset}
              size="large"
            >
              {MESSAGES.reset}
            </LinkButton>
          </div>
        </Grid>
      </div>
    </Grid>
  );
};

CheckBoxBlock.propTypes = {
  tableConfig: Table.propTypes.tableConfig,
  maxColumnsNum: PropTypes.number.isRequired,
  isChecked: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.bool])).isRequired,
  onCheckBoxChange: PropTypes.func,
  onReset: PropTypes.func,
};

CheckBoxBlock.defaultProps = {
  onCheckBoxChange: noop,
  onReset: noop,
};

export default CheckBoxBlock;
