import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import RadioCollapsibleGroup from 'components/common/RadioCollapsible/RadioCollapsibleGroup';
import RadioCollapsible from 'components/common/RadioCollapsible';
import { MESSAGES } from 'constants/messages';
import { ReactComponent as SegmentsIcon } from 'assets/svg/segments.svg';
import { ReactComponent as TagIcon } from 'assets/svg/tag.svg';
import { ReactComponent as ListIcon } from 'assets/svg/list.svg';
import { ReactComponent as ProductIcon } from 'assets/svg/product.svg';
import PropTypes from 'prop-types';
import { concat, find, noop } from 'lodash';
import useListApi from 'hooks/useListApi';
import { LIST_API_REDUX_KEYS } from 'constants/listApi';
import { getProduct, getProductCategories, getProducts } from 'api/productsAPI';
import SearchDropdown from 'components/common/SearchDropdown';
import './styles.scss';
import EntitySelectItem from 'components/common/EntitySelectItem';
import { AUDIENCE_TYPES } from 'constants/customAudience';

const ProductListContent = ({ product }) => (
  <Stack className="dropdown-product-content">
    <span className="dropdown-product-content__header">
      {product.name}
    </span>
    <span className="dropdown-product-content__subheader">
      {`${MESSAGES.sku}: ${product.sku}`}
    </span>
  </Stack>
);
ProductListContent.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
    sku: PropTypes.string.isRequired,
  }).isRequired,
};

const ProductListImage = ({ img }) => (
  <div className="dropdown-product-image">
    {!!img && (
      <img src={img} />
    )}
    {!img && (
      <ProductIcon />
    )}
  </div>
);
ProductListImage.propTypes = {
  img: PropTypes.string,
};
ProductListImage.defaultProps = {
  img: null,
};

const AudienceTypeSelector = ({
  onTypeChange, selectedKeys, onKeySelect, onKeyDeselect, value,
}) => {
  const defaultProductConfig = { ordering: ['id'], audienceSizeEdge: 0 };
  const {
    changeConfig: changeProductsConfig,
    data: productList,
    isDataLoading: isProductsLoading,
    fetchNext: fetchNextProducts,
    totalCount: productsTotalCount,
    isFetchingNext: isFetchingNextProducts,
  } = useListApi(
    LIST_API_REDUX_KEYS.productsRecommendations,
    defaultProductConfig,
    getProducts,
  );
  const handleProductSearchChange = (search) => changeProductsConfig({ search });
  const productOptions = productList?.map((product) => ({
    key: String(product.id),
    content: <ProductListContent product={product} />,
    leftContent: <ProductListImage img={product.img} />,
    entity: product,
  }));
  const [cachedProducts, setCachedProducts] = useState([]);

  const defaultCategoryConfig = { ordering: ['id'], audienceSizeEdge: 0 };
  const {
    changeConfig: changeCategoriesConfig,
    data: categoryList,
    isDataLoading: isCategoriesLoading,
    fetchNext: fetchNextCategories,
    totalCount: categoriesTotalCount,
    isFetchingNext: isFetchingNextCategories,
  } = useListApi(
    LIST_API_REDUX_KEYS.productsCategoriesRecommendations,
    defaultCategoryConfig,
    getProductCategories,
  );
  const handleCategorySearchChange = (search) => changeCategoriesConfig({ search });
  const categoryOptions = categoryList?.map((category) => ({
    key: category.name,
    content: category.name,
  }));

  useEffect(() => {
    setCachedProducts([]);
  }, [value]);

  useEffect(() => {
    if (value === AUDIENCE_TYPES.product) {
      selectedKeys.forEach((key) => {
        if (!find(cachedProducts, { key })) {
          getProduct(key).then((res) => {
            setCachedProducts((prevState) => concat(prevState, [{ key, ...res }]));
          });
        }
      });
    }
  }, [selectedKeys]);

  const onOptionSelect = (selectedKey) => {
    if (value === AUDIENCE_TYPES.product) {
      const option = find(productOptions, { key: selectedKey });
      setCachedProducts(concat(cachedProducts, [{ key: selectedKey, ...option.entity }]));
    }
    onKeySelect(selectedKey);
  };

  const onProductSelectOpen = () => changeProductsConfig({ page: 1 });
  const onCategoriesSelectOpen = () => changeCategoriesConfig({ page: 1 });

  return (
    <Stack
      spacing={2}
      className="audience-type-selector"
    >
      <RadioCollapsibleGroup
        onChange={onTypeChange}
        value={value}
      >
        <RadioCollapsible
          header={MESSAGES.segment}
          leftContent={<SegmentsIcon className="type-icon green" />}
          value={AUDIENCE_TYPES.segment}
        />
        <RadioCollapsible
          header={MESSAGES.product}
          leftContent={<TagIcon className="type-icon blue" />}
          value={AUDIENCE_TYPES.product}
        >
          <Stack spacing={2}>
            <SearchDropdown
              selectedKeys={selectedKeys}
              onSelect={onOptionSelect}
              onDeselect={onKeyDeselect}
              placeholder={MESSAGES.search_for_products}
              options={productOptions}
              onSearchChanged={handleProductSearchChange}
              isLoading={isProductsLoading}
              loadNext={fetchNextProducts}
              hasMore={productsTotalCount > productList.length}
              isLoadingNext={isFetchingNextProducts}
              onOpen={onProductSelectOpen}
            />
            <Stack
              spacing={2}
              className="selected-wrapper"
            >
              {selectedKeys.map((key) => {
                const option = find(cachedProducts, { key });
                if (!option) return null;
                return (
                  <div key={key}>
                    <EntitySelectItem
                      entityImage={option.img}
                      entityImagePlaceholder={<ProductIcon />}
                      onRemove={() => onKeyDeselect(key)}
                    >
                      {option.name}
                    </EntitySelectItem>
                  </div>
                );
              })}
            </Stack>
          </Stack>
        </RadioCollapsible>
        <RadioCollapsible
          header={MESSAGES.category}
          leftContent={<ListIcon className="type-icon yellow" />}
          value={AUDIENCE_TYPES.category}
        >
          <Stack spacing={2}>
            <SearchDropdown
              selectedKeys={selectedKeys}
              onSelect={onOptionSelect}
              onDeselect={onKeyDeselect}
              placeholder={MESSAGES.search_by_name}
              options={categoryOptions}
              onSearchChanged={handleCategorySearchChange}
              isLoading={isCategoriesLoading}
              maxSelection={1}
              loadNext={fetchNextCategories}
              hasMore={categoriesTotalCount > categoryList.length}
              isLoadingNext={isFetchingNextCategories}
              onOpen={onCategoriesSelectOpen}
            />
            {selectedKeys.map((key) => (
              <div key={key}>
                <EntitySelectItem
                  entityImagePlaceholder={<ListIcon />}
                  onRemove={() => onKeyDeselect(key)}
                >
                  {key}
                </EntitySelectItem>
              </div>
            ))}
          </Stack>
        </RadioCollapsible>
      </RadioCollapsibleGroup>
    </Stack>
  );
};

AudienceTypeSelector.propTypes = {
  value: PropTypes.string,
  onTypeChange: PropTypes.func,
  selectedKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  onKeySelect: PropTypes.func,
  onKeyDeselect: PropTypes.func,
};

AudienceTypeSelector.defaultProps = {
  value: null,
  onTypeChange: noop,
  onKeySelect: noop,
  onKeyDeselect: noop,
};

export default AudienceTypeSelector;
