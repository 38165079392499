import { http } from 'utils';
import { ENDPOINTS } from 'constants/routes';
import { HTTP_METHODS } from 'constants/http';

export const getBundlesList = ({
  page, ordering, search,
}) => http({
  url: `${ENDPOINTS.bundle}?page=${page}&ordering=${ordering}&search=${search}`,
  method: HTTP_METHODS.GET,
});

export const createBundle = (data) => http({
  url: ENDPOINTS.bundle,
  method: HTTP_METHODS.POST,
  data,
});

export const updateBundle = (uuid, data) => http({
  url: `${ENDPOINTS.bundle}${uuid}/`,
  method: HTTP_METHODS.PUT,
  data,
});
