import { VALID_SHOP_URL_REQUIRED } from 'constants/errors';
import { getRedirectUrl } from 'api/integrationsAPI';
import { flow, mapValues, toPairs } from 'lodash';


const removeLastSlash = (s) => s.replace(/\/*$/, '');
const hasScheme = (url) => ['http', 'https'].some((scheme) => url.startsWith(scheme + '://'));
const addHttps = (url) => (hasScheme(url) ? url : 'https://'+url);
const prepareUrl = flow(addHttps, removeLastSlash);
const dropDashes = (value) => value.replaceAll('-', '');


const formatShop = async (_, credentials) => {
  try {
    const response = await getRedirectUrl({ url: `${credentials.url}/admin` });

    const redirectedUrl = response.redirected_url;
    const regex = /\/\/(.*)\.myshopify/g;

    return regex.exec(redirectedUrl)[1];
  } catch (error) {
    throw VALID_SHOP_URL_REQUIRED;
  }
};


// Setup for formatting integration credentials
// Under each source key specify functions which will convert credential value into smth else.
// Formatting functions MUST be pure and can be asynchronous.
const formattersConfig = {
  shopify: {
    url: prepareUrl,
    shop: formatShop,
  },
  google_ads: {
    manager_customer_id: dropDashes,
    customer_id: dropDashes,
  },
  magento: {
    domain: prepareUrl,
  },
  woocommerce: {
    url: prepareUrl,
  },
  activecampaign: {
    domain: prepareUrl,
  },
  dandomain_classic: {
    domain: prepareUrl,
  },
};

const formatCredentials = async (sourceKey, credentials) => {
  const trimmedCredentials = mapValues(credentials, (val) => val && val.trim());
  const formatter = formattersConfig[sourceKey];

  if (!formatter) {
    return trimmedCredentials;
  }

  const paramFormatterIterator = async (credentials, [paramName, formatFn]) => {
    const resolvedCredentials = await credentials;

    const formattedResult = await formatFn(resolvedCredentials[paramName], resolvedCredentials);
    return Object.assign(resolvedCredentials, {[paramName]: formattedResult});
  };

  return toPairs(formatter).reduce(paramFormatterIterator, trimmedCredentials);
};

export default formatCredentials;
