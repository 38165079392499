import React from 'react';
import './styles.scss';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import GuideInfo from 'components/common/GuideInfo';

const FieldLabel = ({
  label, children, sizeToContents, className, inline, guide,
}) => (
  <div className={classNames('field-label', className, {
    'size-to-content': sizeToContents,
    inline,
  })}
  >
    <div className="field-label__label-wrapper">
      <span className="field-label__label">
        {label}
      </span>
      {!!guide && (
        <GuideInfo info={guide} tooltipPlacement="top" />
      )}
    </div>
    <div className="field-label__content">
      {children}
    </div>
  </div>
);

FieldLabel.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  sizeToContents: PropTypes.bool,
  className: PropTypes.string,
  inline: PropTypes.bool,
  guide: PropTypes.string,
};

FieldLabel.defaultProps = {
  children: null,
  sizeToContents: false,
  className: null,
  inline: false,
  guide: undefined,
};

export default FieldLabel;
