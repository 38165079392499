// eslint-disable-next-line import/prefer-default-export
export const SUPER_USER_PERMISSIONS = {
  supportAccess: 'S',
  dataAccess: 'D',
  maxAccess: 'M',
};

export const SUPER_USER_PERMISSIONS_ITEMS = [{
  key: SUPER_USER_PERMISSIONS.supportAccess,
  name: 'Support access',
}, {
  key: SUPER_USER_PERMISSIONS.dataAccess,
  name: 'Data access',
}, {
  key: SUPER_USER_PERMISSIONS.maxAccess,
  name: 'Max access',
}];
