import React from 'react';
import propTypes from 'prop-types';
import InviteUserConfirmationWindow
  from 'components/views/Settings/LicenseSettings/LicenseMembers/InviteUser/InviteUserConfirmationWindow';
import InviteUserButton from 'components/views/Settings/LicenseSettings/LicenseMembers/InviteUser/InviteUserButton';

const ConfirmationInviteButton = ({ onConfirm }) => {
  const onConfirmWithClose = (email) => onConfirm(email);

  const onCancelWithClose = (close) => {
    close();
  };

  return (
    <div className="license-members__add-button">
      <InviteUserConfirmationWindow
        onCancel={onCancelWithClose}
        onConfirm={onConfirmWithClose}
        customOpenButton={InviteUserButton}
      />
    </div>
  );
};

ConfirmationInviteButton.propTypes = {
  onConfirm: propTypes.func.isRequired,
};

export default ConfirmationInviteButton;
