import React from 'react';
import classNames from 'classnames';
import propTypes from 'prop-types';

import 'components/common/CompanyName/styles.scss';
import { COMPANY_NAME_SIZE } from 'constants/common';
import { ReactComponent as AddIcon } from 'assets/svg/plus.svg';
import { ReactComponent as WarnIcon } from 'assets/svg/warning.svg';
import { useSelector } from 'react-redux';
import { ROUTES } from 'constants/routes';
import useRouter from 'hooks/useRouter';
import { getCurrentLicense } from 'store/license/getters';

const CompanyName = ({
  size, hasAddButton, hasCategory, onlyLogo, ...props
}) => {
  const {
    company_name: companyName,
    business_category: businessCategory,
  } = useSelector(getCurrentLicense);
  const { push } = useRouter();

  const addNewLicenseHandler = () => {
    push(ROUTES.newLicense);
  };

  return (
    <div className={classNames('company-name', {
      'company-name--small': size === COMPANY_NAME_SIZE.small,
      'company-name--middle': size === COMPANY_NAME_SIZE.middle,
      'company-name--large': size === COMPANY_NAME_SIZE.large,
      'company-name--with-category': hasCategory,
      'company-name--only-logo': onlyLogo,
    })}
    >
      <div className="company-name__logo">
        <img src={props.src || '/static/img/companyDefaultLogo.svg'} className="company-name__img" alt="Company logo" />
        {props.isUnpaid && (
          <WarnIcon className="company-name__logo__warn" />
        )}
      </div>

      <div className="company-name__titles">
        {companyName}
        <p className="company-name__category">{businessCategory && businessCategory[1]}</p>
      </div>
      {hasAddButton && (
        <AddIcon onClick={addNewLicenseHandler} className="company-name__icon company-name__add-icon icon" />
      )}
    </div>
  );
};

CompanyName.propTypes = {
  size: propTypes.string,
  hasAddButton: propTypes.bool,
  hasCategory: propTypes.bool,
  onlyLogo: propTypes.bool,
};

CompanyName.defaultProps = {
  size: COMPANY_NAME_SIZE.small,
  hasAddButton: false,
  hasCategory: false,
  onlyLogo: false,
};

export default CompanyName;
