import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import propTypes from 'prop-types';

import './styles.scss';
import { ReactComponent as ArrowIcon } from 'assets/svg/arrowUp.svg';
import DropdownItem from 'components/common/Dropdown/DropdownItem';
import useAsync from 'hooks/useAsync';
import { REQUEST_STATUS } from 'constants/common';
import { Popover } from '@mui/material';

const Dropdown = ({
  options,
  className,
  value: selectedOption,
  onChange,
  isDisable,
}) => {
  const ref = useRef();
  const [isOpen, setIsOpen] = useState(false);

  const onChangeHandler = async (option) => {
    try {
      await onChange(option);
    } finally {
      setIsOpen(false);
    }
  };

  const {
    execute: onChangeAsync,
    status: onChangeStatus,
  } = useAsync(onChangeHandler);

  const onClickHandler = () => setIsOpen(!isDisable);

  const onClickItemHandler = (option) => {
    if (isDisable) {
      return;
    }

    if (option.value !== selectedOption.value) {
      onChangeAsync(option);
    }
    setIsOpen(false);
  };

  return (
    <>
      <button
        type="button"
        onClick={onClickHandler}
        ref={ref}
        className={classNames('dropdown', {
          'dropdown--collapsed': !isOpen,
          'dropdown--disabled': isDisable,
          'dropdown--loading': onChangeStatus === REQUEST_STATUS.loading,
          [`${className}--collapsed`]: !isOpen && className,
          [className]: className,
        })}
      >
        <div className="dropdown__header">
          <span className={classNames('dropdown__default', {
            [`${className}__default`]: className,
          })}
          >
            {selectedOption?.label}
          </span>
          {
            isDisable || (
              <ArrowIcon className={classNames('dropdown__arrow-icon', {
                [`${className}__arrow-icon`]: className,
              })}
              />
            )
          }
        </div>
      </button>
      <Popover
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <ul className="dropdown__options">
          <DropdownItem
            option={selectedOption}
            onClick={onClickItemHandler}
            className={className}
          />
          {
            options
              .filter((option) => option?.value !== selectedOption?.value)
              .map((option) => (
                <DropdownItem
                  option={option}
                  onClick={onClickItemHandler}
                  className={className}
                />
              ))
          }
        </ul>
      </Popover>
    </>
  );
};

Dropdown.propTypes = {
  options: propTypes.arrayOf(propTypes.string).isRequired,
  value: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
  className: propTypes.string,
  isDisable: propTypes.bool,
};

Dropdown.defaultProps = {
  className: '',
  isDisable: false,
};

export default Dropdown;
