import { useEffect } from 'react';
import posthog from 'posthog-js';
import { useSelector } from 'react-redux';
import { getCurrentLicense } from 'store/license/getters';
import { getCurrentUser } from 'store/profile/getters';
import { useDidMountEffect } from 'utils/common';
import useRouter from 'hooks/useRouter';

export default () => {
  const currentUser = useSelector(getCurrentUser);
  const currentLicense = useSelector(getCurrentLicense);
  const { location } = useRouter();

  useEffect(() => {
    if (!currentUser?.uuid) return;

    posthog.identify(
      currentUser.uuid,
      {
        first_name: currentUser.first_name,
        last_name: currentUser.last_name,
        email: currentUser.email,
        avatar: currentUser.avatar,
        role: currentUser.role?.length > 1 ? currentUser.role[1] : null,
        license_uuid: currentLicense?.uuid,
        license_name: currentLicense?.company_name,
      },
    );
  }, [currentUser, currentLicense]);

  useDidMountEffect(() => {
    if (!currentLicense?.uuid) return;

    posthog.capture('License Pageview', {
      license_uuid: currentLicense?.uuid,
      license_name: currentLicense?.company_name,
    });
  }, [location.pathname]);
};
