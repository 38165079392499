import moduleName from './constants';

const getCustomerListState = (state) => state[moduleName];

export const getCustomerListData = (state) => {
  const { customerListData } = getCustomerListState(state);
  return customerListData;
};

export const getCustomerList = (state) => {
  const { customerListData } = getCustomerListState(state);
  return customerListData?.customerList;
};

export const getCustomerListTotalCount = (state) => {
  const { customerListData } = getCustomerListState(state);
  return customerListData?.count;
};

export const getCustomerListLoaded = (state) => {
  const { isLoaded } = getCustomerListState(state);
  return isLoaded;
};

export const getCustomerListLoading = (state) => {
  const { isLoading } = getCustomerListState(state);
  return isLoading;
};

export const getProductRecommendations = (state) =>
  getCustomerListState(state).product_recommendations;

export const getProductRecommendationsIsLoading = (state) =>
  getCustomerListState(state).isProductRecommendationsLoading;

export const getSegmentsCount = (state) =>
  getCustomerListState(state).segments_count;
