import React from 'react';
import classNames from 'classnames';
import { useLocation, Link } from 'react-router-dom';
import { Breadcrumbs as MUIBreadcrumbs } from '@mui/material';
import { useSelector } from 'react-redux';
import { capitalize } from 'lodash';

import 'containers/PrivateApp/Portal/CustomHeader/Breadcrumbs/styles.scss';
import { ReactComponent as ArrowIcon } from 'assets/svg/arrowUp.svg';
import { getSegments } from 'store/segments/getters';
import { ROUTES } from 'constants/routes';
import { MESSAGES } from 'constants/messages';
import {
  getAudienceNameFromId,
  getCohortNameFromId,
  getCustomerNameFromId,
  getExperimentNameFromId,
  getProductNameFromId,
} from 'store/crumbsIdToName/getters';

const Breadcrumbs = () => {
  const { pathname } = useLocation();
  const pathNames = pathname.split('/').filter((x) => x);
  const param = pathNames[pathNames.length - 1];
  const isParamInt = Number.isInteger(Number(param));
  const isUUID = (/[a-z]/gi).test(param) && (/[0-9]/gi).test(param);

  const segments = useSelector(getSegments);
  const audiences_name = useSelector(getAudienceNameFromId);
  const cohorts_name = useSelector(getCohortNameFromId);
  const experiments_name = useSelector(getExperimentNameFromId);
  const customers_name = useSelector(getCustomerNameFromId);
  const products_name = useSelector(getProductNameFromId);

  const segmentsDetails = segments.map((segment) => ({
    id: segment.id,
    name: segment.segment.segment_name,
  }));
  const segmentsMap = segmentsDetails.reduce((config, segment) => {
    config = { ...config, [segment.id]: segment.name };
    return config;
  }, {});

  const hasProducts = pathname.startsWith(ROUTES.products) && pathNames.length > 1 && isParamInt;
  const hasCustomers = pathname.startsWith(ROUTES.customers) && pathNames.length > 1 && isParamInt;
  const hasCustomAudiences = pathname.startsWith(ROUTES.customAudiences)
        && pathNames.length > 1 && isUUID;
  const hasCohorts = pathname.startsWith(ROUTES.cohorts) && pathNames.length > 1 && isUUID;
  const hasExperiments = pathname.startsWith(ROUTES.experiments) && pathNames.length > 1 && isUUID;
  const hasSegments = pathname.startsWith(ROUTES.segments) && pathNames.length > 1 && isParamInt;

  const isCustom = hasCustomers || hasCustomAudiences
    || hasCohorts || hasProducts || hasSegments || hasExperiments;

  const convertIdToName = (value) => {
    switch (true) {
      case !!audiences_name[value]:
        return audiences_name[value];
      case !!cohorts_name[value]:
        return cohorts_name[value];
      case !!experiments_name[value]:
        return experiments_name[value];
      case !!customers_name[value]:
        return customers_name[value];
      case !!products_name[value]:
        return products_name[value];
      case !!segmentsMap[value]:
        return segmentsMap[value];
      default:
        return null;
    }
  };

  const convertRouteToName = (value) => capitalize(decodeURIComponent(value).replace('-', ' '));

  const formattedRoutes = pathNames.map((value, index) => {
    const isLast = index === pathNames.length - 1;
    const to = `/${(pathNames).slice(0, index + 1).join('/')}`;
    const formattedValue = isCustom && isLast ? convertIdToName(value) : convertRouteToName(value);
    return {
      isLast,
      to,
      formattedValue,
    };
  });

  let title = '';
  formattedRoutes.forEach(({ formattedValue, isLast }) => {
    title += formattedValue && isLast ? ` ${formattedValue} -` : '';
  });
  document.title = `${title} ${MESSAGES.custimy}`;

  const displayCrumbs = () => formattedRoutes.map(({ isLast, to, formattedValue }) => (
    isLast ? (
      <button
        type="button"
        key={to}
        disabled={isLast}
        className={classNames('links', { 'link-item': !isLast })}
      >
        {formattedValue}
      </button>
    ) : (
      <Link
        key={to}
        to={to}
        disabled={isLast}
        className={classNames('links', { 'link-item': !isLast })}
      >
        {formattedValue}
      </Link>
    )
  ));

  if (pathNames[0] === 'home') return null;

  return (
    <div className="breadcrumb-wrapper">
      <MUIBreadcrumbs
        separator={<ArrowIcon />}
        aria-label="breadcrumb"
        className="breadcrumbs"
      >
        <Link to={ROUTES.home} className="links link-item text-ellipsis">
          {MESSAGES.home}
        </Link>
        {displayCrumbs()}
      </MUIBreadcrumbs>
    </div>
  );
};

export default Breadcrumbs;
