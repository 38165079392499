import { http } from 'utils';
import { ENDPOINTS } from 'constants/routes';
import { HTTP_METHODS } from 'constants/http';

export const getPairOfToken = ({ email, password }) => http({
  url: ENDPOINTS.pairOfToken,
  method: HTTP_METHODS.POST,
  data: {
    email,
    password,
  },
  useToken: false,
});

export const getRefreshToken = ({ refresh }) => http({
  method: HTTP_METHODS.POST,
  url: ENDPOINTS.refreshToken,
  data: { refresh },
  throwError: true,
});

export const restorePassword = ({ email }) => http({
  url: ENDPOINTS.restorePassword,
  method: HTTP_METHODS.POST,
  data: { email },
  useToken: false,
});

export const restorePasswordTokenConfirm = ({ uid, token }) => http({
  url: `${ENDPOINTS.restoreLinkTokenConfirmation}${uid}/${token}/`,
  method: HTTP_METHODS.GET,
  useToken: false,
});

export const confirmToken = ({
  licenseUID,
  inviteToken,
  query,
  userEmailParam,
}) => http({
  url: `${ENDPOINTS.linkTokenConfirmation}${licenseUID}/${inviteToken}/${query}${userEmailParam}`,
  method: HTTP_METHODS.GET,
});
