import React from 'react';

import 'components/views/Settings/LicenseSettings/LicensePackage/styles.scss';
import useRouter from 'hooks/useRouter';
import { ROUTES } from 'constants/routes';
import { useSelector } from 'react-redux';
import { getPaymentMethodData } from 'store/paymentMethod/getters';
import { getCurrentLicense, getIsLicenseAdmin } from 'store/license/getters';
import { MESSAGES } from 'constants/messages';
import Button from 'components/common/Button';
import { BUTTON_TYPE } from 'constants/common';

const LicensePackage = () => {
  const { push } = useRouter();
  const isAdministrator = useSelector(getIsLicenseAdmin);

  const paymentMethodData = useSelector(getPaymentMethodData);
  const license = useSelector(getCurrentLicense);

  return (
    <div className="license-settings-package">
      <strong className="license-settings-package__title">
        Entry Plan
      </strong>
      <div className="license-settings-package__description">
        {license?.subscription && isAdministrator ? (
          <div>
            {MESSAGES.update_your_billing_company_info}
          </div>
        ) : license?.bundle?.title}
      </div>
      {paymentMethodData && isAdministrator && (
      <div className="license-settings-package__actions">
          <Button type={BUTTON_TYPE.secondary} onClick={() => push(ROUTES.billingDetails)}>
            {MESSAGES.billing_details}
          </Button>
        </div>
      )}
    </div>
  );
};

export default LicensePackage;
