import React from 'react';
import { CardExpiryElement } from '@stripe/react-stripe-js';

import {
  defaultStripeElementProps,
  stripeElementPropTypes,
} from 'containers/PrivateApp/Payment/PaymentForm/StripeElementInput/stripeElementPropTypes';
import StripeElementInput from 'containers/PrivateApp/Payment/PaymentForm/StripeElementInput';

const CardExpiry = (props) => (
  <StripeElementInput {...props}>
    <CardExpiryElement />
  </StripeElementInput>
);

CardExpiry.propTypes = stripeElementPropTypes;
CardExpiry.defaultProps = defaultStripeElementProps;

export default CardExpiry;
