import {
  TITLE_ERROR_REQUIRED,
  CODE_ERROR_REQUIRED,
  MAX_LICENSES_ERROR_REQUIRED,
} from 'constants/errors';
import Validator from 'utils/validator';

export default ({ title, code, max_licenses_num }) => {
  const errors = {};

  const titleValidator = new Validator(title);
  const codeValidator = new Validator(code);
  const maxLicensesValidator = new Validator(max_licenses_num);

  try {
    titleValidator.required(TITLE_ERROR_REQUIRED);
  } catch (error) {
    errors.title = error.message;
  }

  try {
    codeValidator.required(CODE_ERROR_REQUIRED);
  } catch (error) {
    errors.code = error.message;
  }

  try {
    maxLicensesValidator.required(MAX_LICENSES_ERROR_REQUIRED);
  } catch (error) {
    errors.max_licenses_num = error.message;
  }

  return errors;
};
