import React from 'react';
import propTypes from 'prop-types';
import Popup from 'reactjs-popup';

import 'components/views/Admin/Licenses/DeleteBundleModal/styles.scss';
import Button from 'components/common/Button';
import { REQUEST_STATUS, BUTTON_TYPE } from 'constants/common';
import useAsync from 'hooks/useAsync';
import { deleteLicenseBundle } from 'api/paymentAPI';
import { MESSAGES } from 'constants/messages';

const DeleteBundleModal = ({ license, onClose, onSubmit }) => {
  const deleteBundleHandler = async () => {
    await deleteLicenseBundle(license.uuid);
    onSubmit();
  };

  const { execute, status } = useAsync(deleteBundleHandler);

  return (
    <Popup
      modal
      open={!!license.uuid}
      position="center center"
      onClose={onClose}
    >
      {() => (
        <div className="confirmation-window delete-bundle">
          <span className="delete-bundle__title">{MESSAGES.remove_license_bundle_code}</span>
          <div className="delete-bundle__form__actions form__actions">
            <div className="delete-bundle__form__actions__cancel form__cancel">
              <Button
                block
                isPreventSubmit
                onClick={onClose}
                type={BUTTON_TYPE.secondary}
              >
                {MESSAGES.cancel}
              </Button>
            </div>
            <div className="delete-bundle__form__actions__submit form__submit">
              <Button
                type={BUTTON_TYPE.primary}
                danger
                isLoading={REQUEST_STATUS.loading === status}
                onClick={execute}
              >
                {MESSAGES.remove}
              </Button>
            </div>
          </div>
        </div>
      )}
    </Popup>
  );
};

export default DeleteBundleModal;

DeleteBundleModal.propTypes = {
  license: propTypes.objectOf(propTypes.oneOfType([
    propTypes.string,
    propTypes.array,
    propTypes.object,
  ])).isRequired,
  onClose: propTypes.func.isRequired,
  onSubmit: propTypes.func.isRequired,
};
