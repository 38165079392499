import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import 'components/common/Table/styles.scss';
import { SORT_DIRECTIONS } from 'constants/common';
import SortableColumn from 'components/common/Table/SortableColumn';
import { isArrayEq } from 'utils/common';
import { noop } from 'lodash';

const Table = ({
  tableConfig,
  data,
  keyFunc,
  initSort,
  sortChanged,
  highlightStartIdx,
  highlightEndIdx,
  handleRowClick,
  onHover,
  expandedRows,
  expandedContent,
  withoutHeader,
}) => {
  const sortToInnerView = (config, sort) => {
    const ordering = sort.map((s) => (s.startsWith('-') ? s.substring(1) : s));
    return ({
      direction: sort[0].startsWith('-') ? SORT_DIRECTIONS.desc : SORT_DIRECTIONS.asc,
      ordering,
      key: (config.find((x) => isArrayEq(x.ordering, ordering)) || {}).key,
    });
  };

  const [sort, setSort] = useState(sortToInnerView(tableConfig, initSort));

  const sortFromInnerView = (sort_val) => (
    sort_val.direction === SORT_DIRECTIONS.desc
      ? sort_val.ordering.map((s) => `-${s}`)
      : sort_val.ordering
  );

  const handleSort = (sort_val) => {
    setSort(sort_val);
    sortChanged(sortFromInnerView(sort_val));
  };

  const isItemRowExpanded = (item) => expandedRows.includes(keyFunc(item));

  return (
    <div className="table-wrapper">
      <table className="table">
        {!withoutHeader && (
          <thead className="table__header">
            <tr>
              {tableConfig.map((col, idx) => (
                <th
                  key={col.key}
                  className={classNames('table__header__cell text-ellipsis', {
                    'table__header__cell__first-highlighted': idx === highlightStartIdx,
                  })}
                  style={{ width: col.width }}
                >
                  {col.ordering ? (
                    <SortableColumn
                      column={col}
                      sort={sort}
                      setSort={handleSort}
                    >
                      {col.title}
                    </SortableColumn>
                  ) : (
                    <span>{col.title}</span>
                  )}
                </th>
              ))}
            </tr>
          </thead>
        )}
        <tbody className="table__body">
          {data.map((item) => (
            <>
              <tr
                key={keyFunc(item)}
                className={classNames('table__row', {
                  'table__row-clickable': !!handleRowClick,
                  'table__row-expanded': isItemRowExpanded(item),
                })}
                onClick={() => !!handleRowClick && handleRowClick(item)}
                onMouseEnter={(e) => onHover(e, item, true)}
                onMouseLeave={(e) => onHover(e, item, false)}
              >
                {tableConfig.map((col, idx) => (
                  <td
                    key={`${col?.key}${col?.title}${col?.width}`}
                    className={classNames('table__cell', {
                      'text-ellipsis': col.hasOverflowEllipsis,
                      'table__cell__highlight': idx >= highlightStartIdx && idx <= highlightEndIdx,
                      'table__cell__first-highlighted': idx === highlightStartIdx,
                      'table__cell__last-highlighted': idx === highlightEndIdx,
                    })}
                    style={{ width: col.width }}
                  >
                    <col.CellComponent
                      column={col}
                      row={item}
                      isRowExpanded={isItemRowExpanded(item)}
                      {...col.componentProps}
                    />
                  </td>
                ))}
              </tr>
              {isItemRowExpanded(item) && expandedContent && (
                <tr key={`${keyFunc(item)}-expanded`}>
                  <td colSpan={tableConfig.length}>
                    {expandedContent(item)}
                  </td>
                </tr>
              )}
            </>
          ))}
        </tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  tableConfig: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.any])).isRequired,
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.any])).isRequired,
  initSort: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.any])),
  sortChanged: PropTypes.func,
  highlightStartIdx: PropTypes.number.isRequired,
  highlightEndIdx: PropTypes.number.isRequired,
  handleRowClick: PropTypes.func,
  keyFunc: PropTypes.func,
  onHover: PropTypes.func,
  expandedRows: PropTypes.array,
  expandedContent: PropTypes.func,
  withoutHeader: PropTypes.bool,
};

Table.defaultProps = {
  keyFunc: (item) => item.uuid,
  handleRowClick: null,
  sortChanged: noop,
  onHover: noop,
  initSort: [],
  expandedRows: [],
  expandedContent: noop,
  withoutHeader: false,
};

export default Table;
