import React, { useState } from 'react';
import propTypes from 'prop-types';
import Popup from 'reactjs-popup';
import { useDispatch, useSelector } from 'react-redux';

import 'components/views/Settings/LicenseSettings/License/DeleteLicense/DeleteLicenseConfirmationWindow/styles.scss';
import useAsync from 'hooks/useAsync';
import useForm from 'hooks/useForm';
import { ReactComponent as WarningIcon } from 'assets/svg/warning.svg';
import Input from 'components/common/Input';
import Button from 'components/common/Button';
import { BUTTON_TYPE, REQUEST_STATUS } from 'constants/common';
import { getCurrentLicense } from 'store/license/getters';
import { deleteLicenseAction as deleteLicenseActionAction } from 'store/license/licenseSlice';
import { deleteLicenseAction as deleteLicenseActionAPI } from 'api/licenseAPI';
import useRouter from 'hooks/useRouter';
import { ROUTES } from 'constants/routes';
import Prompt from 'react-router-dom/Prompt';
import { MESSAGES } from 'constants/messages';
import { Stack } from '@mui/material';

const validation = () => ({});

const deleteList = [
  'All customer data',
  'All analytics',
  'All license settings and members',
  'All integrations',
  'All historical data',
];

const DeleteLicenseConfirmationWindow = ({
  customOpenButton,
  open,
  onCancel,
  preventNavigationBlocking,
  forceBlocking,
}) => {
  const {
    company_name: companyName,
    uuid: currentLicenseUUID,
  } = useSelector(getCurrentLicense);
  const dispatch = useDispatch();
  const { replace } = useRouter();
  const [isBlocking, setIsBlocking] = useState(false);

  const deleteLicenseAction = async () => {
    const block = preventNavigationBlocking();

    try {
      await deleteLicenseActionAPI({ licenseUUID: currentLicenseUUID });
      dispatch(deleteLicenseActionAction());
      setIsBlocking(false);
      replace(ROUTES.switchLicense);
    } finally {
      block();
    }
  };

  const {
    execute: deleteLicenseActionAsync,
    status: deleteLicenseActionStatus,
  } = useAsync(deleteLicenseAction);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    cleanUpForm,
    values,
    touched,
    errors,
  } = useForm(deleteLicenseActionAsync, validation);

  const cancelDeleteLicenseWindow = (close) => {
    setIsBlocking(false);
    cleanUpForm();
    onCancel(close);
  };

  return (
    <>
      <Prompt
        when={isBlocking}
        message={(location) => `Are you sure you want to go to ${location.pathname}`}
      />
      <Popup
        modal
        open={open}
        trigger={customOpenButton}
        position="center center"
        closeOnDocumentClick={false}
        onOpen={() => setIsBlocking(forceBlocking)}
      >
        {(close) => (
          <div className="confirmation-window delete-license-confirmation-window">
            <div className="delete-license-confirmation-window__warning">
              <WarningIcon className="delete-license-confirmation-window__warning-icon" />
            </div>
            <div className="delete-license-confirmation-window__content">
              <strong className="delete-license-confirmation-window__title">
                {MESSAGES.license_removal_warning}
              </strong>
              <p className="delete-license-confirmation-window__description">
                {MESSAGES.license_deletion_is_permanent_you_will_remove}
                <div className="delete-license-confirmation-window__block">
                  <ul className="delete-license-confirmation-window__delete-list">
                    {deleteList.map((item) => (
                      <li className="delete-license-confirmation-window__delete-list-item">
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
                {MESSAGES.please_enter_the_full_name_of_your_license}
                {' '}
                <span className="delete-license-confirmation-window__description--impotent">
                  {companyName}
                </span>
                {' '}
                {MESSAGES.to_proceed}
                .
              </p>
            </div>
            <div className="delete-license-confirmation-window__license-name">
              <Input
                id="delete-license-name"
                name="licenseName"
                label={MESSAGES.license_name}
                type="name"
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.licenseName}
                value={values.licenseName}
                touched={touched.licenseName}
              />
            </div>
            <Stack spacing={1} direction="row">
              <Button
                onClick={() => cancelDeleteLicenseWindow(close)}
                block
                type={BUTTON_TYPE.secondary}
                isDisabled={deleteLicenseActionStatus === REQUEST_STATUS.loading}
              >
                {MESSAGES.cancel}
              </Button>
              <Button
                onClick={handleSubmit}
                block
                type={BUTTON_TYPE.primary}
                isDisabled={
                  deleteLicenseActionStatus === REQUEST_STATUS.loading
                  || values.licenseName !== companyName
                }
              >
                {MESSAGES.delete_license}
              </Button>
            </Stack>
          </div>
        )}
      </Popup>
    </>
  );
};

DeleteLicenseConfirmationWindow.propTypes = {
  customOpenButton: propTypes.func.isRequired,
  onCancel: propTypes.func.isRequired,
  preventNavigationBlocking: propTypes.func.isRequired,
  forceBlocking: propTypes.bool,
  open: propTypes.bool,
};

DeleteLicenseConfirmationWindow.defaultProps = {
  open: false,
  forceBlocking: true,
};

export default DeleteLicenseConfirmationWindow;
