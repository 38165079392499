import { MESSAGES } from 'constants/messages';

export const EXPERIMENT_STATUSES = {
  DRAFT: {
    label: 'Inactive',
    value: 'DRAFT',
    type: 'warning',
  },
  ACTIVE: {
    label: 'Active',
    value: 'ACTIVE',
    type: 'success',
  },
  COMPLETED: {
    label: 'Completed',
    value: 'COMPLETED',
    type: 'neutral',
  },
};

export const EXPERIMENT_METRICS = {
  ORDERS: {
    value: 'num_orders',
    label: MESSAGES.orders,
    hasCurrency: false,
  },
  REVENUE: {
    value: 'revenue',
    label: MESSAGES.revenue,
    hasCurrency: true,
  },
  SALES: {
    value: 'sales',
    label: MESSAGES.sales,
    hasCurrency: true,
  },
  PROFIT: {
    value: 'profit',
    label: MESSAGES.profit,
    hasCurrency: true,
  },
};

export const EXPERIMENT_GROUP_BY = {
  DAY: {
    value: 'day',
    label: MESSAGES.day,
  },
  MONTH: {
    value: 'month',
    label: MESSAGES.month,
  },
};

export const EXPERIMENT_AVERAGED_BY = {
  NONE: {
    value: null,
    label: MESSAGES.none,
  },
  ORDERS: {
    value: 'orders',
    label: MESSAGES.order,
  },
  GROUP_SIZE: {
    value: 'group_size',
    label: MESSAGES.customer,
  },
};
