import React from 'react';
import propTypes from 'prop-types';
import 'components/views/Settings/LicenseSettings/License/styles.scss';
import licencePropTypes from 'components/views/Settings/LicenseSettings/License/types';
import { BUTTON_TYPE } from 'constants/common';
import Button from 'components/common/Button';
import useRouter from 'hooks/useRouter';
import { ROUTES } from 'constants/routes';
import { useSelector } from 'react-redux';
import { getCurrentLicense, getIsLicenseAdmin } from 'store/license/getters';
import { MESSAGES } from 'constants/messages';
import { Box, Grid, Stack } from '@mui/material';
import { LinkButton } from 'components/common/LinkButton';

const ViewMode = ({
  companyURL, companySize, onEditClick, avatarImg,
}) => {
  const { push } = useRouter();
  const isAdministrator = useSelector(getIsLicenseAdmin);
  const {
    company_name: companyName,
    business_category: businessCategory,
  } = useSelector(getCurrentLicense);

  const forwardLinkHandler = () => {
    const convertedURL = companyURL.includes('http')
      ? companyURL
      : `http://${companyURL}`;

    window.open(convertedURL, '_blank');
  };

  const addNewLicenseHandler = () => {
    push(ROUTES.newLicense);
  };

  return (
    <div className="license-settings__license">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xl={1} sm={2} xs={12} display="flex" justifyContent="center">
              <div className="company-name__logo">
                <img
                  src={avatarImg || '/static/img/companyDefaultLogo.svg'}
                  className="company-name__img"
                  alt="Company logo"
                />
              </div>
            </Grid>
            <Grid container item xl={11} sm={10} xs={12}>
              <Grid item xs={12}>
                <div className="company-name__titles company-name--large">
                  {companyName}
                </div>
              </Grid>
              <Grid item xs={12}>
                <p className="company-name__category">
                  {businessCategory && businessCategory[1]}
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} container spacing={2}>
          <Grid item xl={1} sm={2} xs={0} component={Box} display={{ sm: 'block', xs: 'none' }} />
          <Grid item xl={5} sm={4} xs={12}>
            <div className="license-content__details">
              <span className="license-content__size">
                {companySize && companySize[1]}
              </span>
              <LinkButton
                size="medium"
                onClick={forwardLinkHandler}
              >
                {companyURL}
              </LinkButton>
            </div>
          </Grid>
          <Grid item md={6} xs={12}>
            <Stack
              spacing={1}
              direction="row"
              display="flex"
              justifyContent="flex-end"
            >
              {isAdministrator && (
                <Button
                  block
                  onClick={onEditClick}
                  type={BUTTON_TYPE.secondary}
                >
                  {MESSAGES.edit}
                </Button>
              )}
              <Button
                block
                onClick={addNewLicenseHandler}
                type={BUTTON_TYPE.primary}
              >
                {MESSAGES.add_license}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

ViewMode.propTypes = {
  ...licencePropTypes,
  onEditClick: propTypes.func.isRequired,
};

export default ViewMode;
