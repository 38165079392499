import React, { useEffect, useState } from 'react';
import { Box, Grid, Stack } from '@mui/material';
import {
  ENTITY_PERFORMANCE, ENTITY_CATEGORY, ENTITY_DETAIL, ENTITY_SEGMENT, ENTITY_PRODUCT_SEGMENT,
} from 'constants/entities';
import { MESSAGES } from 'constants/messages';
import Block from 'components/common/Block';
import { keyBy } from 'lodash';
import useListApi from 'hooks/useListApi';
import {
  getProductFilters, getProducts, getProductsSegments,
} from 'api/productsAPI';
import { PRODUCT_SEGMENTS_COLORS } from 'constants/colors';
import ContentLoader from 'components/common/ContentLoader';
import { INPUT_VALUE_TYPE, REQUEST_STATUS } from 'constants/common';
import {
  ALL_SOURCES_PULLING_ERR_OR_PULLING, FIRST_PULLING, NO_ACTIVE_INTEGRATION, OK_STATUS, OK_PARTIAL,
} from 'constants/status';
import NoIntegrationState from 'components/views/Homepage/EmptyStates/NoIntegrationState';
import MainPullingDataState from 'components/views/Homepage/EmptyStates/MainPullingDataState';
import NoDataState from 'components/views/Homepage/EmptyStates/NoDataState';
import { ToggleSearchEntity } from 'components/common/TableSearchEntity';
import AdvancedFilterTag from 'components/common/AdvancedFilterTag';
import tagHelper from 'components/common/AdvancedFilterTag/tagHelper';
import SearchEntity from 'components/common/SearchEntity';
import { ProductSegmentsSelector } from 'components/views/Products/ProductList/ProductSegmentsSelector';
import { ReactComponent as ClearImage } from 'assets/svg/trash.svg';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import { LinkButton } from 'components/common/LinkButton';
import { LIST_API_REDUX_KEYS } from 'constants/listApi';
import { GUIDES } from 'constants/guides';
import SegmentPopperMetrics, { SegmentPopperMetricsStateHandler } from 'components/common/MetricList/PopperMetrics/SegmentPopperMetrics';
import { useSelector } from 'react-redux';
import { getSegmentByIdInState, getSegments } from 'store/segments/getters';
import CategoryPopperMetrics, { CategoryPopperMetricsStateHandler } from 'components/common/MetricList/PopperMetrics/CategoryPopperMetrics';
import ProductVariantsTable from 'components/views/Products/ProductVariantsTable';
import Popup from 'reactjs-popup';
import ProductTable from 'components/views/Products/ProductList/ProductTable';
import { getUserStatus } from 'api/usersAPI';
import Disclaimer from 'components/common/Disclaimer';
import IconButton from 'components/common/IconButton';

const searchEntityConfig = [{
  key: ENTITY_PERFORMANCE,
  title: MESSAGES.performance,
  hasPages: false,
  unique: false,
}, {
  key: ENTITY_CATEGORY,
  title: MESSAGES.categories,
  hasPages: true,
  finalized: true,
}, {
  key: ENTITY_SEGMENT,
  title: MESSAGES.segments_rate,
  hasPages: false,
  unique: false,
  type: INPUT_VALUE_TYPE.percent,
}, {
  key: ENTITY_DETAIL,
  title: MESSAGES.details,
  hasPages: false,
  unique: false,
}];

const defaultConfig = { ordering: ['id'] };

const ProductList = () => {
  const [status, setStatus] = useState('');
  const [segmentsMap, setSegmentsMap] = useState({});
  const [selectedParentId, setSelectedParentId] = useState(null);
  const segmentStates = useSelector(getSegments);

  const listApi = useListApi(LIST_API_REDUX_KEYS.products, defaultConfig, getProducts);
  const {
    config,
    changeConfig,
  } = listApi;
  const handleTagsChange = (tags) => changeConfig({ tags });

  const {
    tags,
    fields,
    operators,
    addTag,
    removeTag,
    updateTag,
    clearTags,
  } = tagHelper(config?.tags, handleTagsChange, searchEntityConfig, getProductFilters);

  const {
    handleOnHover: segmentHandleOnHover,
    anchorEl: segmentAnchorEl,
    item: segmentItem,
    itemData: segmentItemData,
    isLoading: isSegmentLoading,
    isOpen: isSegmentOpen,
  } = SegmentPopperMetricsStateHandler();

  const {
    handleOnHover: categoryHandleOnHover,
    anchorEl: categoryAnchorEl,
    item: categoryItem,
    itemData: categoryItemData,
    isLoading: isCategoryLoading,
    isOpen: isCategoryOpen,
  } = CategoryPopperMetricsStateHandler();

  const handleOptionHover = (e, isHovering, entity, option) => {
    if (entity.key === ENTITY_SEGMENT) {
      const { id } = getSegmentByIdInState(option.uuid, segmentStates);
      segmentHandleOnHover(e, { id, segment_name: option.name }, isHovering);
    }

    if (entity.key === ENTITY_CATEGORY) {
      categoryHandleOnHover(e, { id: option.uuid, title: option.uuid }, isHovering);
    }
  };

  useEffect(() => {
    const populateSegments = async () => {
      const segments = await getProductsSegments();
      const segmentsWithColor = segments.map((segment) => (
        { ...segment, color_1: PRODUCT_SEGMENTS_COLORS[segment?.id] }));
      setSegmentsMap(keyBy(segmentsWithColor, 'id'));
    };

    const populateUserStatus = async () => {
      const response = await getUserStatus();
      setStatus(response.status);
    };

    populateSegments();
    populateUserStatus();
  }, []);

  const shouldRenderTag = (tag) => tag.entityKey !== ENTITY_PRODUCT_SEGMENT;
  const displayedTags = tags.filter(shouldRenderTag);

  const openProductVariantsModal = (parentId) =>
    setSelectedParentId(parentId);

  const handleModalClose = () =>
    setSelectedParentId(null);

  return (
    <>
      <Box width="100%">
        <ContentLoader isLoading={status === REQUEST_STATUS.loading}>
          {!!status && status === NO_ACTIVE_INTEGRATION && <NoIntegrationState />}
          {!!status && status === FIRST_PULLING && <MainPullingDataState />}
          {!!status && status === ALL_SOURCES_PULLING_ERR_OR_PULLING && <NoDataState />}
          {!!status && (status === OK_STATUS || status === OK_PARTIAL) && (
          <Grid container spacing={2}>
            {status === OK_PARTIAL && (
              <Grid item>
                <Disclaimer
                  type="warning"
                  text={MESSAGES.data_is_still_pulled}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Block
                header={MESSAGES.product_insights}
                postHeader={MESSAGES.product_insights_subtext}
                type="large-header"
              />
            </Grid>
            <Grid item xs={12}>
              <Block header={MESSAGES.filters} guide={GUIDES.PI1}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <SearchEntity
                      config={searchEntityConfig}
                      ToggleComponent={ToggleSearchEntity}
                      selectedItems={tags}
                      onItemSelect={addTag}
                      onOptionHover={handleOptionHover}
                    />
                  </Grid>
                  <Grid item xs={6} component={Stack} justifyContent="flex-end">
                    {!!tags?.length && (
                      <LinkButton
                        type="danger"
                        onClick={clearTags}
                        icon={ClearImage}
                      >
                        {MESSAGES.clear_filter}
                      </LinkButton>
                    )}
                  </Grid>
                  <Grid item container spacing={1} xs={12}>
                    {displayedTags.map((tag) => (
                      <Grid key={tag.key} item>
                        <AdvancedFilterTag
                          tag={tag}
                          fields={fields}
                          operators={operators}
                          searchEntityConfig={searchEntityConfig}
                          onTagChange={(updatedTag) => updateTag(tag.key, updatedTag)}
                          onRemove={() => removeTag(tag.key)}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Block>
            </Grid>
            <Grid item xs={12}>
              <Block header={MESSAGES.product_segments} guide={GUIDES.PI2}>
                <ProductSegmentsSelector
                  segments={segmentsMap}
                  tags={tags}
                  onDeselect={removeTag}
                  onSelect={addTag}
                />
              </Block>
            </Grid>
            <Grid item xs={12}>
              <Block
                header={MESSAGES.product_list}
                postHeader={MESSAGES.yearly_performance}
                guide={GUIDES.PI3}
              >
                <ProductTable
                  listApi={listApi}
                  onVariantRowClick={openProductVariantsModal}
                />
              </Block>
            </Grid>
          </Grid>
          )}
        </ContentLoader>
      </Box>
      <SegmentPopperMetrics
        isLoading={isSegmentLoading}
        isOpen={isSegmentOpen}
        anchorEl={segmentAnchorEl}
        item={segmentItem}
        itemData={segmentItemData}
        placement="right"
      />
      <CategoryPopperMetrics
        isLoading={isCategoryLoading}
        isOpen={isCategoryOpen}
        anchorEl={categoryAnchorEl}
        item={categoryItem}
        itemData={categoryItemData}
        placement="right"
      />
      <Popup
        modal
        open={!!selectedParentId}
        position="center center"
        onClose={handleModalClose}
        closeOnDocumentClick={false}
      >
        {!!selectedParentId && (
          <div className="product-variants-table">
            <Block
              header={MESSAGES.variants}
              postHeader={MESSAGES.yearly_performance}
              headerContent={<IconButton icon={CloseIcon} size="small" type="secondary" onClick={handleModalClose} />}
            >
              <ProductVariantsTable
                parentId={selectedParentId}
              />
            </Block>
          </div>
        )}
      </Popup>
    </>
  );
};

export default ProductList;
