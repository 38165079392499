import React, { useEffect } from 'react';
import App from './App';
import { hotjar } from 'react-hotjar';
import chmln from '@chamaeleonidae/chmln';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { IntercomProvider } from 'react-use-intercom';

const HOTJAR_SITE_ID = Number(process.env.REACT_APP_HOTJAR_KEY);
const CHMLN_KEY = process.env.REACT_APP_CHMLN_KEY;
const HOTJAR_SNIPPET_VERSION = 6;
const GOOGLE_KEY = process.env.REACT_APP_GA_OAUTH_CLIENT_ID;
const INTERCOM_KEY = process.env.REACT_APP_INTERCOM_KEY;

const AppWrapper = () => {
  useEffect(() => {
    hotjar.initialize(HOTJAR_SITE_ID, HOTJAR_SNIPPET_VERSION);
    chmln.init(CHMLN_KEY, { fastUrl: 'https://fast.chameleon.io/' });
  }, []);

  return (
    <GoogleOAuthProvider clientId={GOOGLE_KEY}>
      <IntercomProvider appId={INTERCOM_KEY}>
        <App />
      </IntercomProvider>
    </GoogleOAuthProvider>
  );
};

export default AppWrapper;
