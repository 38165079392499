import React from 'react';
import { ENTITY_CHANNEL } from 'constants/entities';
import { MESSAGES } from 'constants/messages';
import Button from 'components/common/Button';
import { BUTTON_TYPE } from 'constants/common';
import PropTypes from 'prop-types';
import { noop, reject } from 'lodash';
import { Grid, Stack } from '@mui/material';
import Input from 'components/common/Input';
import Slider from 'components/common/Slider';
import SearchEntity from 'components/common/SearchEntity';
import { SEARCH_POPOVER_POSITION } from 'components/common/SearchEntity/constants';
import EntitySelectItem from 'components/common/EntitySelectItem';
import { getAssetSourceImage } from 'utils/assets';
import { ReactComponent as RemoveIcon } from 'assets/svg/remove.svg';
import 'components/views/Experiments/ExperimentCreateView/ExperimentGroupTable/ExperimentGroupRow/styles.scss';
import { ReactComponent as LockedIcon } from 'assets/svg/locked.svg';
import { ReactComponent as UnlockedIcon } from 'assets/svg/unlocked.svg';
import IconButton from 'components/common/IconButton';

const searchEntityConfig = [{
  key: ENTITY_CHANNEL,
  title: MESSAGES.channels,
  hasPages: false,
}];

const ToggleSearchEntity = ({ isOpened, onClick }) => (
  <div
    className="experiment-group-row__channels-add"
    onClick={onClick}
  >
    <Button
      fillWidth
      type={BUTTON_TYPE.secondary}
      isActive={isOpened}
    >
      {`+ ${MESSAGES.add_channels}`}
    </Button>
  </div>
);

ToggleSearchEntity.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
};

ToggleSearchEntity.defaultProps = {
  onClick: noop,
};

const ExperimentGroupRow = ({
  id,
  name,
  splitSize,
  channels,
  locked,
  channelsMap,
  onChange,
  onDelete,
}) => {
  const group = {
    id,
    name,
    split_size: splitSize,
    channels,
    locked,
  };

  const handleNameChange = (e) => onChange({
    ...group,
    name: e.target.value,
  });

  const handleSplitSizeChange = (size) => onChange({
    ...group,
    split_size: size,
  });

  const handleChannelSelect = ({ uuid }) => onChange({
    ...group,
    channels: [...channels, {
      integration: uuid,
    }],
  });

  const handleLockClick = () => onChange({
    ...group,
    locked: !locked,
  });

  const handleChannelRemove = (integration) => onChange({
    ...group,
    channels: reject(channels, { integration }),
  });

  const handleInputSplitSizeChange = ({ target }) => {
    let value = Number.isNaN(Number(target.value)) ? 0 : Number(target.value);
    value = Math.min(Math.max(value, 0), 100);
    handleSplitSizeChange(value);
  };

  const channelsToSelection = channels.map(({ integration }) => ({
    entityKey: ENTITY_CHANNEL,
    uuid: integration,
    name: integration,
  }));

  return (
    <div className="experiment-group-row">
      <Grid container spacing={4}>
        <Grid item lg={3} xs={12} alignItems="center" display="flex">
          <Stack
            direction="row"
            spacing={2}
            className="experiment-group-row__input-wrapper"
          >
            <IconButton
              icon={RemoveIcon}
              type="danger"
              onClick={onDelete}
              size="medium"
              uncolored
              tooltip={MESSAGES.remove}
            />
            <div className="experiment-group-row__input-wrapper">
              <Input
                id="name"
                name="name"
                label={MESSAGES.experiment_group_name_placeholder}
                hasFloatLabel={false}
                onChange={handleNameChange}
                onBlur={handleNameChange}
                value={name}
                isRequired
              />
            </div>
          </Stack>
        </Grid>
        <Grid
          item
          lg={4}
          xs={12}
          alignItems="center"
          display="flex"
        >
          <Stack
            direction="row"
            spacing={2}
            className="experiment-group-row__input-wrapper"
          >
            <Slider
              max={100}
              value={splitSize}
              min={0}
              percentage
              marks={4}
              onChange={handleSplitSizeChange}
            />
            <div className="experiment-group-row__size-wrapper">
              <div>
                <Input
                  id="split_size"
                  name="split_size"
                  type="percentage"
                  label={MESSAGES.size}
                  hasFloatLabel={false}
                  onChange={handleInputSplitSizeChange}
                  onBlur={handleInputSplitSizeChange}
                  value={splitSize}
                  hasPercent
                  isRequired
                />
              </div>
            </div>
            <div className="experiment-group-row__lock-wrapper">
              <IconButton
                icon={locked ? LockedIcon : UnlockedIcon}
                type="secondary"
                size="small"
                onClick={handleLockClick}
                tooltip={locked ? MESSAGES.unlock : MESSAGES.lock}
              />
            </div>
          </Stack>
        </Grid>
        <Grid
          item
          lg={5}
          xs={12}
          alignItems="center"
          display="flex"
        >
          <div className="experiment-group-row__channels_wrapper">
            <Stack direction="row" spacing={2}>
              <div className="experiment-group-row__channels_wrapper__add">
                <SearchEntity
                  selectedItems={channelsToSelection}
                  onItemSelect={handleChannelSelect}
                  config={searchEntityConfig}
                  ToggleComponent={ToggleSearchEntity}
                  position={SEARCH_POPOVER_POSITION.top}
                  isInContainer
                />
              </div>
              <div className="experiment-group-row__channels_wrapper__channels">
                <Stack direction="row">
                {channels.map(({ integration }) => (
                  <div key={integration}>
                    <EntitySelectItem
                      type="mini"
                      entityImage={getAssetSourceImage(channelsMap[integration].source.key, true)}
                      onRemove={() => handleChannelRemove(integration)}
                    >
                      {channelsMap[integration].source.name}
                    </EntitySelectItem>
                  </div>
                ))}
                </Stack>
              </div>
            </Stack>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

ExperimentGroupRow.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  splitSize: PropTypes.number.isRequired,
  channels: PropTypes.arrayOf(PropTypes.shape({
    integration: PropTypes.string.isRequired,
  })).isRequired,
  locked: PropTypes.bool.isRequired,
  channelsMap: PropTypes.objectOf(PropTypes.oneOfType(
    [PropTypes.object],
  )),
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
};

ExperimentGroupRow.defaultProps = {
  name: '',
  channelsMap: null,
  onChange: noop,
  onDelete: noop,
};

export default ExperimentGroupRow;
