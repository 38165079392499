import { MESSAGES } from 'constants/messages';
import React from 'react';
import EmptyTemplate from 'components/views/Homepage/EmptyStates/EmptyTemplate';

const NoIntegrationState = () => (
  <div className="empty-state-wrapper">
    <EmptyTemplate title="Let's start rocking your data">
      <span>
        {MESSAGES.to_start_using_custimy}
        <br />
        {MESSAGES.e_commerce_cms_platform}
      </span>
    </EmptyTemplate>
  </div>
);

export default NoIntegrationState;
