import React from 'react';
import 'components/views/Integrations/IconBox/styles.scss';
import classNames from 'classnames';
import propTypes from 'prop-types';
import IconBoxFeedback from 'components/views/Integrations/IconBox/IconBoxFeedback';
import { AVAILABLE_INTEGRATION_STATUSES } from 'components/views/Integrations/constants/Constants';
import { getAssetSourceImage } from 'utils/assets';

const SourceIconBox = ({
  source, justLogo, onClick, primaryNotAvailable, disabled,
}) => {
  const isComingSoon = source.status === AVAILABLE_INTEGRATION_STATUSES.coming_soon;
  const onClickHandler = isComingSoon ? undefined : onClick;

  return (
    <div
      onClick={onClickHandler}
      className={classNames(
        'iconBox',
        {
          'iconBox__comming-soon': isComingSoon,
          iconBox__disabled: disabled,
          'iconBox__just-logo': justLogo,
          'iconBox__primary-not-enabled': primaryNotAvailable,
        },
      )}
    >
      {isComingSoon && <IconBoxFeedback />}
      <img
        src={getAssetSourceImage(source.key)}
        alt={source.name}
        className="iconBox__img"
      />
    </div>
  );
};

SourceIconBox.propTypes = {
  source: propTypes.shape({
    status: propTypes.string.isRequired,
    name: propTypes.string.isRequired,
    key: propTypes.string.isRequired,
  }),
  disabled: propTypes.bool,
  justLogo: propTypes.bool,
  onClick: propTypes.func,
  primaryNotAvailable: propTypes.bool,
};

SourceIconBox.defaultProps = {
  source: undefined,
  disabled: false,
  justLogo: false,
  onClick: () => {},
  primaryNotAvailable: false,
};
export default SourceIconBox;
