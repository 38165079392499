import React from 'react';
import PropTypes from 'prop-types';

const Item = ({ item }) => (
  <div className="item-wrapper">
    {!!item.event_source && (
      <>
        <div className="data">
          {!!item.event_datetime && !!item.event_datetime.length && item.event_datetime}
        </div>
        <div
          className="app"
          style={{ backgroundColor: `${item.event_source.color}` }}
        >
          {item.event_source.source_name}
        </div>
        <div
          className="event"
          style={{
            border: `1px solid ${item.event_source.color}`,
            color: `${item.event_source.color}`,
          }}
        >
          {item.event_name}
        </div>
      </>
    )}
  </div>
);

Item.propTypes = {
  item: PropTypes.shape({
    event_datetime: PropTypes.string.isRequired,
    event_name: PropTypes.string.isRequired,
    event_source: PropTypes.shape({
      id: PropTypes.number.isRequired,
      source_name: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default Item;
