import React, { useState } from 'react';
import classNames from 'classnames';
import propTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import './styles.scss';
import useRouter from 'hooks/useRouter';
import { useSelector } from 'react-redux';
import { getSegments } from 'store/segments/getters';
import { getRouteKeyFromPath } from 'helper/routerHelper';

const MinSideBarMenuItem = ({ item }) => {
  const { location } = useRouter();
  const { title, hrefTo, disabled } = item;
  const [toolTipTitle, setToolTipTitle] = useState(undefined);

  const segmentIdx = hrefTo.split('/')[2];

  const segmentDetails = useSelector(getSegments);

  const getSegmentColor = (segmentIndex) => {
    const result = segmentDetails.find((segment) => `${segment.id}` === segmentIndex);
    return result ? result.segment.color_1 : '#CE6230';
  };

  const handleLinkClick = (e) => {
    if (disabled) {
      e.preventDefault();
    }
  };

  const isActive = hrefTo === location.pathname
    || item.id === getRouteKeyFromPath(location.pathname);

  const getToolTipTitle = ({ target }) => (target.value);

  if (item.id == null) return null;

  return (
    <li
      className={classNames('min-menu__item min-menu-item', {
        'min-menu__item--disabled': disabled,
      })}
    >
      <NavLink
        onClick={handleLinkClick}
        to={hrefTo}
        className="min-menu-item__link-wrapper"
        style={{
          border: isActive ? `1px solid ${getSegmentColor(segmentIdx)}` : null,
        }}
      >
        <button
          type="button"
          value={title}
          style={{ background: `${getSegmentColor(segmentIdx)}` }}
          className="min-menu-item__link"
          onMouseEnter={(e) => setToolTipTitle(getToolTipTitle(e))}
          onMouseLeave={() => setToolTipTitle(undefined)}
          data-tip
          data-for="tip"
        />
      </NavLink>
      {toolTipTitle && (
        <ReactTooltip
          className="tooltip-container"
          id="tip"
          place="right"
          overridePosition={null}
          type="light"
          effect="solid"
          globalEventOff="click"
          arrowColor="transparent"
        >
          <div className="tooltip-text">
            {toolTipTitle}
          </div>
        </ReactTooltip>
      )}
    </li>
  );
};

MinSideBarMenuItem.propTypes = {
  item: propTypes.objectOf(propTypes.oneOfType([
    propTypes.number, propTypes.string, propTypes.bool])).isRequired,
};

export default MinSideBarMenuItem;
