import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { MESSAGES } from 'constants/messages';

import 'components/views/Admin/Licenses/LicensesCells/styles.scss';

export const NameCell = ({ column, row }) => (
  <span className="license__cell__name">
    {row[column.key]}
  </span>
);

NameCell.propTypes = {
  column: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  row: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

export const CreatedAtCell = ({ column, row }) => {
  const createdAtStr = row[column.key];
  const createdAt = createdAtStr && moment(createdAtStr).format('DD/MM/YY');
  return (
    <span className="user__cell__created_at">
      {createdAt}
    </span>
  );
};

CreatedAtCell.propTypes = NameCell.propTypes;

export const StatusCell = ({ column, row }) => (
  <span className="license__cell__status">
    {row[column.key] ? MESSAGES.deleted : MESSAGES.active}
  </span>
);

StatusCell.propTypes = NameCell.propTypes;
