export const ROUTES = {
  portal: '/',
  signIn: '/sign-in',
  signUp: '/sign-up',
  details: '/details',
  profile: '/profile',
  profiles: '/profiles',
  licenseSettings: '/license-settings',
  customer: '/customers/:id',
  customers: '/customers',
  billingDetails: '/license-settings/billing-details',
  restorePassword: '/restore-password',
  restorePasswordLink: '/restore-password/link',
  switchLicense: '/switch-license',
  newLicense: '/new-license',
  acceptInvitation: '/accept-invitation/link',
  integrations: '/integrations',
  payment: '/payment',
  product: '/products/:id',
  products: '/products',
  productCategory: '/products/categories/:id',
  productCategories: '/products/categories',
  home: '/home',
  segments: '/segments',
  segments_id: '/segments/:id',
  newCustomAudience: '/custom-audiences/new',
  customAudience: '/custom-audiences/:uuid',
  customAudiences: '/custom-audiences',
  newCohort: '/cohorts/new',
  cohort: '/cohorts/:uuid',
  cohorts: '/cohorts',
  newExperiment: '/experiments/new',
  experiment: '/experiments/:uuid',
  experiments: '/experiments',
  adminPanel: '/admin',
  adminPanelUsers: '/admin/users',
  adminPanelLicenses: '/admin/licenses',
  adminPanelLogs: '/admin/logs',
  adminPanelSuperUsers: '/admin/super-users',
  adminBundles: '/admin/bundles',
};

export const EXTERNAL_LINK = {
  custimy: 'http://custimy.io/',
  custimyHelp: 'https://makeinfluence.notion.site/Custimy-integrations-2e8cbfca38b947bf97abce60a9fa194d?pvs=4',
  custimyPrivacyPolicy: 'https://custimy.io/privacy-policy/',
  custimyTermsOfBusiness: 'https://custimy.io/terms-of-business/',
  helpIntegrations: 'https://makeinfluence.notion.site/Custimy-integrations-2e8cbfca38b947bf97abce60a9fa194d?pvs=4',
  helpShopify: 'https://makeinfluence.notion.site/Shopify-Integration-6d749570748f4f778b3608f05d00eb77',
  helpWoocommerce: 'https://makeinfluence.notion.site/WooCommerce-Integration-f2da4d59bfad46f0a9a29581ea7cb301?pvs=4',
  helpDandomainClassic: 'https://makeinfluence.notion.site/DanDomain-Webshop-Integration-503723488ee046dfbb84918c4c172c08?pvs=4',
  helpDandomain: 'https://makeinfluence.notion.site/DanDomain-Integration-e1bc2bcc9288493ba98c56f6bd07a2ed?pvs=4',
  helpBusinessCentral: 'https://makeinfluence.notion.site/Microsoft-Dynamics-Business-Central-Integration-37265ff5cb404bfba059edecc24b2e38?pvs=4',
  helpMagento: 'https://makeinfluence.notion.site/Magento-2-x-Integration-0eb2868529224e33a5227845a20804cb?pvs=4',
  helpDatalakeAPI: 'https://makeinfluence.notion.site/Custimy-API-Integration-2ec5dfed07d14fb0ba59d1def32d6f4a?pvs=4',
  helpMailchimp: 'https://makeinfluence.notion.site/Mailchimp-Integration-a73a63affe1b4415a5eac72db528ded2?pvs=4',
  helpKlaviyo: 'https://makeinfluence.notion.site/Klaviyo-Integration-e89d8d46e4d74814b7726cb9ed901d0b?pvs=4',
  helpActiveCampaign: 'https://makeinfluence.notion.site/ActiveCampaign-Integration-6f1fbed21d1e4ca2acf36c3d2ccd22c2?pvs=4',
  helpGoogleAds: 'https://makeinfluence.notion.site/Google-Ads-Integration-51517bf69bd242408be6093ba22b85e8',
  helpFacebookAds: 'https://makeinfluence.notion.site/Facebook-Ads-Integration-d3c5c19a26944dc1bafe62472de3349e',
  helpGA: 'https://makeinfluence.notion.site/Google-Analytics-Integration-3bd6259d542143008d53b0525279d0dd?pvs=4',
  helpFacebookCAPI: 'https://makeinfluence.notion.site/Facebook-CAPI-Integration-20c1c64418ce49388ab03f77acfcfe9d',
  helpMagentoLegacy: 'https://makeinfluence.notion.site/Magento-1-x-Integration-fe04bd9d2fac427ea57da5c493d16cc1?pvs=4',
  helpSnapchat: 'https://makeinfluence.notion.site/Snapchat-Integration-4dc2413f0e754226a40384c53cabb45a?pvs=4',
  helpTikTok: 'https://makeinfluence.notion.site/TikTok-Integration-93ab5e5433e6461487b0adca73a5865d?pvs=4',
  helpDrip: 'https://makeinfluence.notion.site/Drip-Integration-9fd7de5428734b39b08dec9b7209b827?pvs=4',
};

export const ENDPOINTS = {
  baseUrl: '/api/',
  user: 'v1/user/',
  userStatus: 'v1/user/status/',
  userReactivate: 'v1/user/reactivate/',
  superUser: 'v1/super-user/',
  bundle: 'v1/bundle/',
  switchLicense: 'v1/user/switch-license/',
  pairOfToken: 'v1/auth/api/token/',
  refreshToken: 'v1/auth/api/token/refresh/',
  workRoles: 'v1/user/roles/',
  businessCategory: 'v1/license/business_categories/',
  companySize: 'v1/license/company_sizes/',
  personalDetails: 'v1/user/provide-details/',
  createLicense: 'v1/license/create/',
  getCurrentUser: 'v1/user/current/',
  currentLicense: 'v1/user/current/license/details/',
  restorePassword: 'v1/auth/password-restore/',
  ownerLicenses: 'v1/license/all/',
  license: 'v1/license/',
  licenseReactivate: 'v1/license/reactivate/',
  cohorts: 'v1/cohorts/',
  cohortsData: 'v1/cohorts/:uuid/data',
  cohortsDataExport: 'v1/cohorts/:uuid/data/export/',
  experiments: 'v1/experiments/',
  experimentData: 'v1/experiments/:uuid/data',
  experimentDataBoundaries: 'v1/experiments/:uuid/data/boundaries',
  exportExperimentGroup: 'v1/experiments/group/:uuid/export',
  customAudiences: 'v1/custom-audiences/',
  inviteUser: 'v1/user/invite/',
  linkTokenConfirmation: 'v1/auth/token-confirm/',
  restoreLinkTokenConfirmation: 'v1/auth/restore/token-confirm/',
  updateInvitedUser: 'v1/user/register/invite/',
  acceptLicenseInvitation: 'v1/license/accept/invite/',
  licenseMembers: 'v1/license/users/',
  licenseStatus: 'v1/license/license_update_statuses/',
  deleteLicense: 'v1/license/delete/',
  deleteLicenseMember: 'v1/license/details/delete/',
  updateLicenseMemberStatus: 'v1/license/details/update-status/',
  paymentPublicKey: 'v1/payment/public-key/',
  paymentCreateCard: 'v1/payment/customer/create/',
  paymentCreatePackage: 'v1/payment/customer/create/',
  paymentLicenseBundle: 'v1/payment/license-bundle/',
  paymentGetPaymentMethod: 'v1/payment/license/card/',
  paymentGetPromotionCode: 'v1/payment/promotion-code/',
  paymentStartTrial: 'v1/payment/webhook/start-trial/',
  getAvailableIntegrationsSources: 'v1/data-integrations/sources/',
  getActiveIntegrationsSources: 'v1/data-integrations/user-integrations-details/',
  getChannels: 'v1/data-integrations/channels/',
  updateIntegration: 'v1/data-integrations/',
  updateOAuthIntegration: 'v1/data-integrations/oauth/',
  deleteActiveRegistration: 'v1/data-integrations/user-integrations-delete/',
  getRedirectUrl: 'v1/data-integrations/util/get-redirect/',
  segments: 'v1/segments/',
  segmentsGraphic: 'v1/segments/customer-sources/',
  segmentsInfo: 'v1/segments/info/',
  segmentsExport: 'v1/segments/export/',
  customerList: 'v1/customers/',
  customersProductRecommendations: 'v1/customers/product-recommendations/',
  customerFilterList: 'v1/customers/filters/',
  customerListExport: 'v1/customers/export/',
  customerPortal: 'v1/payment/create-customer-portal-session',
  annualRevenue: 'v1/license/annual_revenue',
  stripeData: 'v1/payment/license/billing-info/',
  products: 'v1/products/',
  productsExport: 'v1/products/export/',
  productFilters: 'v1/products/filters/',
  productSegments: 'v1/products/segments/',
  productCategories: 'v1/products/categories/',
  productCategoriesExport: 'v1/products/categories/export/',
  productCategoryFilters: 'v1/products/categories-filters/',
  home: 'v1/home/',
  homeDateRange: 'v1/home/date-range/',
  homeExport: 'v1/home/export/',
  metrics: 'v1/home/metrics/',
  socialsGoogleLogin: 'v1/socials/google/login/',
  emailVerificationSend: 'v1/user/verification/send/',
};
