import React from 'react';
import Block from 'components/common/Block';
import SourceIconBox from 'components/views/Integrations/IconBox';
import PropTypes from 'prop-types';
import { AVAILABLE_INTEGRATION_STATUSES } from 'components/views/Integrations/constants/Constants';
import { Grid } from '@mui/material';
import { noop } from 'lodash';

const IntegrationsCategoryBlock = ({
  header,
  postHeader,
  list,
  updateIntegration,
  activePrimaryId,
  readonly,
  guide,
}) => (
  <Block
    header={header}
    postHeader={postHeader}
    fillHeight
    guide={guide}
  >
    <div className="integrations__row">
      <Grid container spacing={4}>
        {list.map((item) => (
          <Grid item md={3} xs={6}>
            <SourceIconBox
              key={item.uuid}
              primaryNotAvailable={!!activePrimaryId
                && item.status !== AVAILABLE_INTEGRATION_STATUSES.coming_soon
                && activePrimaryId !== item.uuid}
              onClick={updateIntegration.bind(this, item)}
              disabled={!item.is_available_for_integration || readonly}
              source={item}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  </Block>
);

IntegrationsCategoryBlock.propTypes = {
  header: PropTypes.string,
  postHeader: PropTypes.string,
  readonly: PropTypes.bool,
  list: PropTypes.arrayOf(PropTypes.object),
  updateIntegration: PropTypes.func,
  activePrimaryId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  guide: PropTypes.string,
};

IntegrationsCategoryBlock.defaultProps = {
  header: undefined,
  postHeader: undefined,
  readonly: false,
  list: [],
  updateIntegration: noop,
  activePrimaryId: undefined,
  guide: undefined,
};

export default IntegrationsCategoryBlock;
