import { useState, useCallback } from 'react';
import stringQuery from 'query-string';
import useRouter from 'hooks/useRouter';
import { isNumeric } from 'rxjs/internal-compatibility';
import useDeepCompareEffect from 'use-deep-compare-effect';

const STRINGIFY_KEYS = {
  segment: 'segment',
  ordering: 'ordering',
};

const stringifyFilterQuery = (filterConfig) => stringQuery.stringify(filterConfig, { arrayFormat: 'comma' });
const initConfig = (baseConfig, search) => {
  const query = stringQuery.parse(search);
  const formattedQuery = Object.keys(query).reduce((result, key) => {
    let value = query[key];
    if (key === STRINGIFY_KEYS.segment || key === STRINGIFY_KEYS.ordering) {
      value = value.split(',');
    } else if (isNumeric(value)) {
      value = Number(value);
    }
    result[key] = value;
    return result;
  }, {});

  return {
    ...baseConfig,
    ...formattedQuery,
  };
};

export default (baseConfig, onConfigChange) => {
  const { location, push } = useRouter();
  const [config, setConfig] = useState(() => initConfig(baseConfig, location?.search));
  const baseConfigKeys = Object.keys(baseConfig);

  useDeepCompareEffect(() => {
    location.search = stringifyFilterQuery(config);
    push(location);
    onConfigChange(config)
  }, [config]);

  const changeBaseConfig = useCallback((configChanges) => {
    const newConfig = {...config, ...configChanges};
    setConfig(newConfig);
  }, [config]);

  const changeFiltersConfig = useCallback((filtersConfig) => {
    // filter old config to contain only base properties.
    // filtersConfig will contain ALL actual filters
    const baseOnly = baseConfigKeys.reduce((result, key) => {
      result[key] = config[key];
      return result;
    }, {});
    const newConfig = {...baseOnly, ...filtersConfig};
    if (location?.search.replace('?', '') !== stringifyFilterQuery(newConfig).replace('?', '')) {
      newConfig.page = 1;
    }

    setConfig(newConfig);
  }, [config]);

  return { changeBaseConfig, changeFiltersConfig, config, baseConfigKeys };
};
