import React, { useState, useEffect, useCallback } from 'react';
import propTypes from 'prop-types';
import Popup from 'reactjs-popup';
import 'components/views/Integrations/UpdateIntegrationModal/styles.scss';
import { ReactComponent as LogosDivider } from 'assets/svg/logosDivider.svg';
import { ReactComponent as GoogleLogo } from 'assets/svg/google-logo.svg';
import { ReactComponent as CustimyLogo } from 'assets/svg/custimyLogo.svg';
import SourceIconBox from 'components/views/Integrations/IconBox';
import { updateIntegration } from 'api/integrationsAPI';
import Button from 'components/common/Button';
import useAsync from 'hooks/useAsync';
import useForm from 'hooks/useForm';
import { REQUEST_STATUS, BUTTON_TYPE } from 'constants/common';
import Input from 'components/common/Input';
import NeedHelpLink from 'components/views/Integrations/NeedHelpLink';
import Prompt from 'react-router-dom/Prompt';
import { oauthStateLSManager } from 'helper/localStorage';
import { PUBLIC_KEY } from 'constants/oauth';
import formatCredentials from 'components/views/Integrations/UpdateIntegrationModal/integrationFormatters';
import { v4 as uuidv4 } from 'uuid';
import { toUrlParams } from 'utils/common';
import { MESSAGES } from 'constants/messages';
import { Box, Grid, Stack } from '@mui/material';
import {
  getHelpGuide,
  GOOGLE_INTEGRATIONS,
} from 'constants/helpGuides';
import { LinkButton } from 'components/common/LinkButton';
import TextArea from 'components/common/TextArea';
import FieldLabel from 'components/common/FieldLabel';
import Disclaimer from 'components/common/Disclaimer';
import TooltipCopy from 'components/common/TooltipCopy';

const UpdateIntegrationModal = ({
  open, integration, modalStep, modalError, editMode, onConfirm, onCancel, onDelete, forceBlocking,
}) => {
  const source = integration?.source || integration;

  const collectInitialCreds = () => {
    const creds = {};
    if (!!source.credential_fields_names && !!source.credential_fields_names.length) {
      source.credential_fields_names.filter((field) => field.visible).forEach((field) => {
        creds[field.key] = '';
      });
    }
    return creds;
  };

  const validation = useCallback((values) => {
    const isOptional = source.credential_fields_names
      .filter((field) => field.is_optional)
      .map((field) => field.key);
    const errors = {};
    if (Object.keys(values).length) {
      Object.keys(values).forEach((key) => {
        if (!values[key] && !isOptional.includes(key)) {
          errors[key] = { header: `Please provide ${key}` };
        }
      });
    }
    return errors;
  }, []);

  const [step, setStep] = useState(modalStep);
  const [error, setError] = useState(modalError);
  const [initialCreds] = useState(collectInitialCreds());
  const [isBlocking, setIsBlocking] = useState(false);
  const [savedCreds, setSavedCreds] = useState();

  const replaceTemplateKeys = (str, obj) => Object.keys(obj).reduce((result, key) => result.replaceAll(`{${key}}`, obj[key]), str);

  const grantOAuth2Permissions = (stateId, credentials) => {
    const { auth_url, scope } = source.oauth_urls;
    const sourceKey = source.key;
    const publicKey = PUBLIC_KEY[sourceKey];
    const params = {
      [publicKey.urlName]: publicKey.value,
      redirect_uri: `${window.location.origin}/integrations/${sourceKey}`,
      response_type: 'code',
      access_type: 'offline',
      prompt: 'consent',
      state: stateId,
    };

    if (scope) {
      params.scope = scope;
    }

    const authUrl = replaceTemplateKeys(auth_url, credentials);
    window.location.href = `${authUrl}?${toUrlParams(params)}`;
  };

  const onConfirmConnect = async (credentials) => {
    setError({});

    let success = true;
    credentials = await formatCredentials(source.key, credentials)
      .catch((e) => {
        setError(e);
        success = false;
      });
    if (!success) return;

    if (source.auth_type === 'OAUTH') {
      const stateId = uuidv4();
      oauthStateLSManager.set({ stateId, credentials });
      grantOAuth2Permissions(stateId, credentials);
    } else {
      const data = { credentials };
      try {
        const integrationData = await updateIntegration({
          integrationUUID: source.uuid,
          data,
        });
        if (integrationData) {
          setSavedCreds(integrationData.credentials);
          setIsBlocking(false);
          setStep(2);
        }
      } catch (e) {
        setStep(1);
        setError(e);
      }
    }
  };
  const { execute, status } = useAsync(onConfirmConnect);
  const changeStep = (credentials) => {
    switch (step) {
      case 1:
        execute(credentials);
        break;
      case 2:
        onConfirm();
        break;
      default:
        break;
    }
  };
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    cleanUpForm,
    values,
    errors,
    touched,
  } = useForm(changeStep, validation, initialCreds);

  const isGoogleAnalytics = GOOGLE_INTEGRATIONS.includes(source.key);
  const isDatalakeAPI = source.key === 'datalake_api';

  useEffect(() => {
    if (open) {
      setIsBlocking(forceBlocking);
      setError(modalError);
      cleanUpForm();
      setStep(modalStep);
      setSavedCreds(null);
    }
  }, [open]);

  useEffect(() => {
    setSavedCreds(integration.generated_credentials);
  }, [integration]);

  return (
    <>
      <Prompt
        when={isBlocking}
        message={(location) => `Are you sure you want to go to ${location.pathname}`}
      />
      <Popup
        modal
        open={open}
        position="center center"
        closeOnDocumentClick={false}
        onOpen={() => setIsBlocking(forceBlocking)}
      >
        {() => (
          <div className="confirmation-window update-integration-window">
            <Grid container spacing={4}>
              <Grid item md={2} xs={0} component={Box} display={{ md: 'block', xs: 'none' }} />
              <Grid item md={8} xs={12}>
                <div className="update-integration-window__logos-container">
                  <SourceIconBox source={source} justLogo />
                  {!editMode && <LogosDivider className="logos-divider" />}
                  {!editMode && <CustimyLogo className="company-logo" />}
                </div>
              </Grid>
              <Grid item md={2} xs={12}>
                {step === 1 && (
                  <NeedHelpLink href={getHelpGuide(source.key)} />
                )}
              </Grid>
              <Grid item xs={12}>
                <Stack alignItems="center" justifyContent="center" textAlign="center">
                  {step === 1 && (
                    <>
                      <h3>
                        {editMode ? MESSAGES.update_authorization : MESSAGES.congrats}
                      </h3>
                      {editMode ? (
                        <Stack spacing={2} className="stack-info">
                          <span>{`${MESSAGES.you_are_about_to_update_connection} ${source.name}`}</span>
                          {isDatalakeAPI && (
                            <>
                              <FieldLabel label={`${MESSAGES.your} ${MESSAGES.current.toLowerCase()} ${MESSAGES.refresh_token}`}>
                                <TooltipCopy
                                  value={savedCreds?.jwt_refresh_token}
                                  info={MESSAGES.click_to_copy}
                                >
                                  <TextArea
                                    name="token"
                                    placeholder={MESSAGES.refresh_token}
                                    value={savedCreds?.jwt_refresh_token}
                                    rows={5}
                                    readOnly
                                  />
                                </TooltipCopy>
                              </FieldLabel>
                              <Disclaimer
                                type="warning"
                                text={MESSAGES.refresh_token_update_disclaimer}
                              />
                            </>
                          )}
                        </Stack>
                      ) : (
                        isDatalakeAPI ? (
                          <span>
                            {`${MESSAGES.you_are_about_to_connect} ${MESSAGES.to.toLowerCase()} ${source.name}`}
                          </span>
                        ) : (
                          <span>
                            {`${MESSAGES.you_are_about_to_connect} ${MESSAGES.your.toLowerCase()} ${source.name}`}
                            <br />
                            {(MESSAGES.account_with_custimy).toLowerCase()}
                          </span>
                        )
                      )}
                    </>
                  )}
                  {step === 2 && (
                    <>
                      <h3>
                        {MESSAGES.done}
                      </h3>
                      <br />
                      {isDatalakeAPI ? (
                        <Stack spacing={2} className="stack-info">
                          <div>
                            {MESSAGES.you_are_ready_datalake_api}
                            <LinkButton
                              size="large"
                              type="secondary"
                              href={getHelpGuide(source.key)}
                              hrefNewTab
                              underlined
                            >
                              our help page
                            </LinkButton>
                            {MESSAGES.dot_it_contains_guide}
                          </div>
                          <FieldLabel label={MESSAGES.refresh_token}>
                            <TooltipCopy
                              value={savedCreds.jwt_refresh_token}
                              info={MESSAGES.click_to_copy}
                            >
                              <TextArea
                                name="token"
                                placeholder={MESSAGES.refresh_token}
                                value={savedCreds.jwt_refresh_token}
                                rows={5}
                                readOnly
                              />
                            </TooltipCopy>
                          </FieldLabel>
                          <Disclaimer
                            type="info"
                            text={MESSAGES.refresh_token_disclaimer}
                          />
                        </Stack>
                      ) : (
                        <>
                          {MESSAGES.your}
                          {' '}
                          {source.name}
                          {' '}
                          {(MESSAGES.data_will_be_available).toLowerCase()}
                        </>
                      )}
                    </>
                  )}
                </Stack>
              </Grid>
              {step === 1 && !!source.credential_fields_names
                && !!source.credential_fields_names.filter((field) => field.visible).length
                && (
                  <Grid item xs={12}>
                    <form
                      onSubmit={handleSubmit}
                      noValidate
                    >
                      <Grid item container spacing={4} xs={12}>
                        {source.credential_fields_names.filter((field) => field.visible).map((field, key) => (
                          <>
                            <Grid item md={3} xs={0} component={Box} display={{ md: 'block', xs: 'none' }} />
                            <Grid item md={6} xs={12}>
                              <Input
                                key={key}
                                id={field.key}
                                name={field.key}
                                isDisable={status === REQUEST_STATUS.loading}
                                error={(!!errors[field.key] && { header: `Please provide ${field.label}` }) || undefined}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values[field.key]}
                                label={field.label}
                                touched={touched[field.key]}
                                hasFeedback
                                isRequired={!field.is_optional}
                              />
                            </Grid>
                            <Grid
                              item
                              md={3}
                              xs={0}
                              component={Box}
                              display={{ md: 'block', xs: 'none' }}
                            />
                          </>
                        ))}
                      </Grid>
                    </form>
                  </Grid>
                )}
              {!!error && !!Object.keys(error).length && (
                <Grid item xs={12}>
                  <Box display="flex" textAlign="center" justifyContent="center">
                    <div className="update-integration-window__errors-container">
                      {typeof error === 'string' && (
                        <div>
                          {error}
                        </div>
                      )}
                      {typeof error !== 'string' && Object.keys(error).map((errKey) => (Array.isArray(error[errKey]) ? error[errKey].map((text, key) => (
                        <div key={key}>
                          {text}
                        </div>
                      )) : (
                        <div>
                          {error[errKey]}
                        </div>
                      )))}
                    </div>
                  </Box>
                </Grid>
              )}
              <Grid item xs={12} container spacing={2}>
                {step === 1 && (
                  <Grid item xs={6}>
                    <Box display="flex" justifyContent="flex-end">
                      <Button
                        isPreventSubmit
                        onClick={() => {
                          setIsBlocking(false);
                          onCancel();
                        }}
                        type={BUTTON_TYPE.secondary}
                        isDisabled={status === REQUEST_STATUS.loading}
                      >
                        {MESSAGES.cancel}
                      </Button>
                    </Box>
                  </Grid>
                )}
                {step === 1 && !editMode && isGoogleAnalytics && (
                  <Grid item xs={6}>
                    <Box display="flex" justifyContent="flex-start">
                      <div
                        className="google-button"
                        onClick={handleSubmit}
                        role="button"
                        tabIndex="0"
                      >
                        <span className="logo"><GoogleLogo width="25" height="25" /></span>
                        <span className="text">Sign in with Google</span>
                      </div>
                    </Box>
                  </Grid>
                )}
                {step === 1 && (!isGoogleAnalytics || editMode) && (
                  <Grid item xs={6}>
                    <Box display="flex" justifyContent="flex-start">
                      <Button
                        type={BUTTON_TYPE.primary}
                        onClick={handleSubmit}
                        isLoading={status === REQUEST_STATUS.loading}
                      >
                        {editMode ? MESSAGES.update : MESSAGES.connect}
                      </Button>
                    </Box>
                  </Grid>
                )}
                {step === 2 && (
                  <>
                    <Grid item md={4} xs={2} />
                    <Grid item md={4} xs={8}>
                      <Box display="flex" justifyContent="center">
                        <Button
                          block
                          type={BUTTON_TYPE.primary}
                          onClick={handleSubmit}
                          isLoading={status === REQUEST_STATUS.loading}
                        >
                          {MESSAGES.ok}
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item md={4} xs={2} />
                  </>
                )}
              </Grid>
              {editMode && !source.disableDelete && step === 1 && (
                <div
                  className="update-integration-window__buttons-container update-integration-window__buttons-container--with-top-border"
                >
                  <div>
                    <div
                      className="update-integration-window__button update-integration-window__button--delete"
                    >
                      <LinkButton size="large" type="danger" onClick={onDelete}>
                        {MESSAGES.delete_source}
                      </LinkButton>
                    </div>
                  </div>
                </div>
              )}
            </Grid>
          </div>
        )}
      </Popup>
    </>
  );
};

UpdateIntegrationModal.propTypes = {
  open: propTypes.bool,
  integration: propTypes.object,
  modalStep: propTypes.number,
  editMode: propTypes.bool,
  onConfirm: propTypes.func,
  onCancel: propTypes.func,
  onDelete: propTypes.func,
  forceBlocking: propTypes.bool,
};

UpdateIntegrationModal.defaultProps = {
  open: false,
  integration: {},
  editMode: false,
  modalStep: undefined,
  onConfirm: undefined,
  onCancel: undefined,
  onDelete: undefined,
  forceBlocking: true,
};

export default UpdateIntegrationModal;
