import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import './styles.scss';
import { datepickerDefaultProps, datepickerPropTypes } from 'components/common/Datepicker/datepickerPropTypes';
import Input from '../Input';
import { ReactComponent as ClearIcon } from 'assets/svg/offCircleClose.svg';

const Datepicker = ({
  value,
  onChange,
  includeDates,
  label,
  dateFormat,
  isClearable,
}) => {
  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className="datepicker__input-container">
      <Input
        id="datepicker"
        name="datepicker"
        containerRef={ref}
        type="text"
        label={label}
        hasCalendar
        isReadonly
        value={value}
        onClick={onClick}
      />
      {isClearable && value && (
        <div
          className="datepicker__clear"
          onClick={() => onChange(null)}
        >
          <ClearIcon className="datepicker__clear-icon" />
        </div>
      )}
    </div>
  ));

  return (
    <DatePicker
      selected={value}
      onChange={onChange}
      includeDates={includeDates}
      dateFormat={dateFormat}
      customInput={<CustomInput />}
    />
  );
};

Datepicker.propTypes = datepickerPropTypes;
Datepicker.defaultProps = datepickerDefaultProps;

export default Datepicker;
