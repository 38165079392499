import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './styles.scss';
import { ReactComponent as DeleteChipIcon } from 'assets/svg/offCircleClose.svg';
import { Box } from '@mui/material';
import GuideInfo from 'components/common/GuideInfo';
import { isNil, noop } from 'lodash';

const Chip = ({
  label,
  color,
  size,
  labelNote,
  DeleteIcon,
  clickColor,
  selected,
  info,
  onClick,
  onDelete,
  onHover,
}) => {
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = (e) => {
    setIsHover(true);
    onHover(e, true);
  };

  const handleMouseLeave = (e) => {
    setIsHover(false);
    onHover(e, false);
  };

  return (
    <Box
      className={classNames('chip', color, size, {
        has_click: !!onClick,
        selected,
      })}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={clickColor ? {
        '&:hover': {
          backgroundColor: `${clickColor} !important`,
        },
        '&.selected': {
          backgroundColor: `${clickColor} !important`,
        },
      } : null}
      onClick={(e) => onClick && onClick(e)}
    >
      <div className="chip__content">
        <div className="chip__label">
          {label}
          {!isNil(labelNote) && (
            <span className="chip__label__note">
              ({labelNote})
            </span>
          )}
        </div>

      </div>

      {!!onDelete && (
        <button
          type="button"
          className="chip__action"
          onClick={() => onDelete(label)}
        >
          <DeleteIcon className="chip__icon chip__icon--delete" />
        </button>
      )}
      {!!info && (
        <div className="chip__info">
          <GuideInfo
            info={info}
            type={(isHover || selected) ? 'white' : 'grey'}
          />
        </div>
      )}
    </Box>
  );
};

Chip.propTypes = {
  color: PropTypes.oneOf(['grey', 'blue', 'green', 'yellow']),
  size: PropTypes.oneOf(['medium', 'large']),
  label: PropTypes.string.isRequired,
  labelNote: PropTypes.string,
  DeleteIcon: PropTypes.node,
  clickColor: PropTypes.string,
  selected: PropTypes.bool,
  info: PropTypes.string,
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
  onHover: PropTypes.func,
};

Chip.defaultProps = {
  color: 'grey',
  size: 'medium',
  labelNote: null,
  selected: false,
  DeleteIcon: <DeleteChipIcon />,
  clickColor: undefined,
  info: undefined,
  onDelete: undefined,
  onClick: undefined,
  onHover: noop,
};

export default Chip;
