import React, { useState } from 'react';
import './styles.scss';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const TextArea = ({
  name,
  className,
  placeholder,
  value,
  characterLimit,
  onChange,
  onBlur,
  onFocus,
  allowVerticalResize,
  rows,
  readOnly,
}) => {
  const [intervalValue, setIntervalValue] = useState('');

  const handleChange = (event) => {
    let newValue = event.target.value;
    if (!!characterLimit && newValue.length > characterLimit) {
      newValue = newValue.substring(0, characterLimit);
      event.target.value = newValue;
    }

    setIntervalValue(newValue);
    onChange(event, newValue);
  };

  return (
    <div className={classNames('text-area', className, {
      allow_vertical_resize: allowVerticalResize,
    })}
    >
      <textarea
        id={name}
        name={name}
        value={value || intervalValue}
        rows={rows}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={onBlur}
        onFocus={onFocus}
        readOnly={readOnly}
        className="text-area__edit"
      />
    </div>
  );
};

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  characterLimit: PropTypes.number,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  allowVerticalResize: PropTypes.bool,
  rows: PropTypes.number,
  readOnly: PropTypes.bool,
};

TextArea.defaultProps = {
  className: null,
  placeholder: '',
  value: null,
  characterLimit: null,
  onChange: noop,
  onBlur: noop,
  onFocus: noop,
  allowVerticalResize: false,
  rows: 2,
  readOnly: false,
};

export default TextArea;
