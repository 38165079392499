export const SEGMENT_NAME = {
  most_valuable_customers: 'Most Valuable Customers',
  promising_customers: 'Promising Customers',
  core: 'Core',
  high_spend_customers: 'High Spend Customers',
  loyal_low_spend_customers: 'Loyal Low Spend Customers',
  new_customers: 'New Customers',
  least_valuable_customers: 'Least Valuable Customers',
  win_back_customers: 'Win-back Customers',
  departed_customers: 'Departed Customers',
};
