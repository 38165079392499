import React, {
  useCallback, useEffect, useState,
} from 'react';
import './styles.scss';
import classNames from 'classnames';
import {
  Tab, TabList, TabPanel, Tabs,
} from 'react-tabs';
import Input from 'components/common/Input';
import { debounce, noop } from 'lodash';
import dataFunctions from 'components/common/SearchEntity/dataFunctions';
import ContentLoader from 'components/common/ContentLoader';
import { MESSAGES } from 'constants/messages';
import InfiniteScroll from 'react-infinite-scroll-component';
import PropTypes from 'prop-types';

const SearchEntityPopover = ({
  config,
  onOptionSelected,
  onOptionHover,
  selectedItemsSet,
  position,
  isInContainer,
}) => {
  const [tabIdx, setTabIdx] = useState(0);
  const [page, setPage] = useState(1);
  const [hasMorePages, setHasMorePages] = useState(true);
  const [inputValue, setInputValue] = useState('');
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [disabledTabs, setDisabledTabs] = useState([]);

  const getTabResults = async (searchStr) =>
    Promise.all(config.map(async (tabConfig, idx) => ({
      idx,
      results: await dataFunctions[tabConfig.key](searchStr),
    })));

  const updateDisabledTabs = async (searchStr) => {
    if (!searchStr) {
      setDisabledTabs([]);
      return;
    }

    const tabResults = await getTabResults(searchStr);
    const newDisabledTabs = [];
    tabResults.forEach((tabResult) => {
      if (!tabResult.results.length) {
        newDisabledTabs.push(tabResult.idx);
      }
    });
    setDisabledTabs(newDisabledTabs);
  };

  useEffect(() => {
    if (!disabledTabs.includes(tabIdx)) return;
    config.every((tabConfig, idx) => {
      if (!disabledTabs.includes(idx)) {
        setTabIdx(idx);
        return false;
      }
      return true;
    });
  }, [disabledTabs]);

  const getDataFetchFunc = () => {
    const currentKey = config[tabIdx].key;
    return dataFunctions[currentKey];
  };

  const fetchData = async (searchStr) => {
    updateDisabledTabs(searchStr);
    setIsLoading(true);
    const getData = getDataFetchFunc();
    const result = await getData(searchStr);

    setOptions(result);
    setIsLoading(false);
  };

  const loadNext = async () => {
    const getData = getDataFetchFunc();
    const result = await getData(search, page + 1).catch(() => ([]));

    setHasMorePages(!!result.length);
    setOptions(options.concat(result));
    setPage(page + 1);
  };

  const searchChanged = useCallback(debounce(setSearch, 300), []);

  useEffect(() => {
    fetchData(search);

    setPage(1);
    setHasMorePages(true);
  }, [tabIdx, search]);

  const inputValueChanged = (value) => {
    setInputValue(value);
    searchChanged(value);
  };

  const onTabChange = (idx) => {
    if (idx !== tabIdx) {
      setTabIdx(idx);
    }
  };

  const isEntityUnique = (entity) => entity.unique === undefined || entity.unique;
  const isOptionUsed = (entity, option) => isEntityUnique(entity)
    && selectedItemsSet.has(entity.key + option.uuid);

  return (
    <div
      className={classNames(`search-entity-popover search-entity-popover__${position}`,
        { 'search-entity-popover-incontainer': isInContainer })}
    >
      <div className="search-input">
        <Input
          name="search-entity-input"
          id="search-entity-input"
          type="text"
          label={MESSAGES.search_options}
          hasSearch
          value={inputValue}
          onChange={(e) => inputValueChanged(e.target.value)}
        />
      </div>
      <Tabs onSelect={onTabChange} selectedIndex={tabIdx}>
        <TabList className="search-entity-popover__tab-list">
          {config.map((entity, idx) => (
            <Tab
              key={entity.key}
              className="search-entity-popover__tab-list__tab"
              disabled={disabledTabs.includes(idx)}
            >
              {entity.title}
            </Tab>
          ))}
        </TabList>
        {config.map((entity) => (
          <TabPanel key={entity.key}>
            <div className="search-entity-popover__tap-panel">
              <div className="search-entity-popover__tap-panel__input" />
              <ContentLoader isLoading={isLoading}>
                <div>
                  {options.length ? (
                    <InfiniteScroll
                      dataLength={options.length}
                      next={loadNext}
                      height="200px"
                      hasMore={entity.hasPages ? hasMorePages : false}
                      loader={<></>}
                    >
                      {options.map((option, idx) => (
                        <div
                          key={idx}
                          className={classNames('search-entity-popover__tap-panel__option', {
                            'search-entity-popover__tap-panel__option__used': isOptionUsed(entity, option),
                          })}
                          onClick={() => onOptionSelected(entity, option)}
                          onMouseEnter={(e) => onOptionHover(e, true, entity, option)}
                          onMouseLeave={(e) => onOptionHover(e, false, entity, option)}
                        >
                          {option.image ? (
                            <div className="search-entity-popover__tap-panel__option__image">
                              <img alt="image" src={option.image} />
                            </div>
                          ) : option.color ? (
                            <div
                              className="search-entity-popover__tap-panel__option__placeholder"
                              style={option.color ? { background: option.color } : {}}
                            />
                          ) : <></>}
                          <span className="search-entity-popover__tap-panel__option__name text-ellipsis">
                            {!option.image && !option.color && '- '}
                            {option.name}
                          </span>
                        </div>
                      ))}
                    </InfiniteScroll>
                  ) : (
                    <div className="search-entity-popover__tap-panel__option search-entity-popover__tap-panel__option__disabled">
                      <span className="search-entity-popover__tap-panel__option__name">
                        {MESSAGES.no_results}
                      </span>
                    </div>
                  )}
                </div>
              </ContentLoader>
            </div>
          </TabPanel>
        ))}
      </Tabs>
    </div>
  );
};

SearchEntityPopover.propTypes = {
  config: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    unique: PropTypes.bool,
  })).isRequired,
  onOptionSelected: PropTypes.func.isRequired,
  onOptionHover: PropTypes.func,
  selectedItemsSet: PropTypes.instanceOf(Set).isRequired,
  position: PropTypes.string.isRequired,
  isInContainer: PropTypes.bool,
};

SearchEntityPopover.defaultProps = {
  isInContainer: false,
  onOptionHover: noop,
};

export default SearchEntityPopover;
