import React from 'react';
import classNames from 'classnames';
import propTypes from 'prop-types';

import './styles.scss';

const DropdownItem = ({ option, onClick, className }) => {
  const onClickItemHandler = () => onClick(option);

  return (
    <li
      className={classNames('dropdown__option', {
        [`${className}__option`]: className,
      })}
      onClick={onClickItemHandler}
    >
      {option?.label}
    </li>
  );
};

DropdownItem.propTypes = {
  option: propTypes.string.isRequired,
  onClick: propTypes.func.isRequired,
  className: propTypes.string,
};

DropdownItem.defaultProps = {
  className: '',
};

export default DropdownItem;
