import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import propTypes from 'prop-types';
import Popup from 'reactjs-popup';
import 'components/views/CustomAudience/ShowCustomersTableWindow/styles.scss';
import CustomerList from 'components/common/CustomersListTools/CustomerList';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import { MESSAGES } from 'constants/messages';
import Block from 'components/common/Block';
import IconButton from 'components/common/IconButton';

const ShowCustomersTableWindow = ({
  open,
  onClose,
  forceBlocking,
  config,
  changeBaseConfig,
  customerListTotalCount,
  withExport,
  openOnNewTab,
}) => {
  const [isBlocking, setIsBlocking] = useState(false);

  useEffect(() => {
    const close = (e) => {
      if (open && e.keyCode === 27) {
        onClose(false);
      }

      setIsBlocking(false);
    };

    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, [open]);

  return (
    <>
      <Prompt
        when={isBlocking}
      />
      <Popup
        modal
        open={open}
        position="center center"
        closeOnDocumentClick={false}
        onOpen={() => setIsBlocking(forceBlocking)}
      >
        {() => (
          <div className="show-customers-table-window">
            <div className="show-customers-table-window__actions">
              <Block
                header={MESSAGES.customer_list}
                headerContent={(
                  <IconButton
                    onClick={() => onClose(false)}
                    icon={CloseIcon}
                    size="small"
                    type="secondary"
                  />
                )}
              >
                <CustomerList
                  config={config}
                  changeBaseConfig={changeBaseConfig}
                  customerListTotalCount={customerListTotalCount}
                  withExport={withExport}
                  openOnNewTab={openOnNewTab}
                />
              </Block>
            </div>
          </div>
        )}
      </Popup>
    </>
  );
};

ShowCustomersTableWindow.propTypes = {
  open: propTypes.bool,
  onClose: propTypes.func,
  forceBlocking: propTypes.bool,
  config: propTypes.object,
  changeBaseConfig: propTypes.func,
  customerListTotalCount: propTypes.number,
  withExport: propTypes.bool,
  openOnNewTab: propTypes.bool,
};

ShowCustomersTableWindow.defaultProps = {
  open: false,
  onClose: undefined,
  forceBlocking: true,
  config: undefined,
  changeBaseConfig: null,
  customerListTotalCount: 0,
  withExport: true,
  openOnNewTab: false,
};

export default ShowCustomersTableWindow;
