import React from 'react';
import Input from 'components/common/Input';
import { BUTTON_TYPE, REQUEST_STATUS } from 'constants/common';
import ConfirmationCancelButton from 'components/views/Profile/PersonalBlock/ConfirmationCancelButton';
import Button from 'components/common/Button';
import passwordValidation from 'components/views/Profile/PasswordBlock/PasswordEditMode/PasswordValidation';
import useForm from 'hooks/useForm';
import { http } from 'utils';
import { HTTP_METHODS } from 'constants/http';
import useAsync from 'hooks/useAsync';
import ConfirmationWindow from 'components/common/ConfirmationWindow';
import usePrompt from 'hooks/usePrompt';
import { MESSAGES } from 'constants/messages';
import Grid from '@mui/material/Grid';
import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { useSelector } from 'react-redux';
import { getCurrentUser } from 'store/profile/getters';
import useCurrentUser from 'hooks/useCurrentUser';

const PasswordEditMode = ({ onClick }) => {
  const currentUser = useSelector(getCurrentUser);
  const { setCurrentUserAsync } = useCurrentUser();

  const submitResetPassword = async (values, setError) => {
    await http({
      url: 'v1/auth/password-reset/',
      method: HTTP_METHODS.PATCH,
      data: {
        old_password: values.old_password,
        new_password: values.new_password,
        duplicate_new_password: values.confirm_password,
      },
    }).then((resp) => {
      if (resp.uuid) {
        onClick();
        setCurrentUserAsync();
      }
    }).catch((error) => {
      const errors = {
        old_password: {
          header: error[0],
        },
      };
      setError(errors);
    });
  };

  const { execute, status } = useAsync(submitResetPassword);
  const {
    handleSubmit, handleChange, handleBlur, values, errors, touched,
  } = useForm(
    execute,
    passwordValidation,
    { uses_password: currentUser.uses_password },
  );
  const hasFormChanged = !!Object.keys(values).length;
  const { isBlocking, onCancel, onConfirm } = usePrompt(hasFormChanged);
  return (
    <form className="password-edit-mode" onSubmit={handleSubmit}>
      <ConfirmationWindow
        content={MESSAGES.leave_the_page}
        open={isBlocking}
        onCancel={onCancel}
        onConfirm={onConfirm}
        inWorkArea
        forceBlocking={false}
      />
      <Grid container spacing={4}>
        {currentUser.uses_password && (
          <Grid item xs={12}>
            <Input
              id="old_password"
              name="old_password"
              label={MESSAGES.old_password}
              type="password"
              onChange={handleChange}
              onBlur={handleBlur}
              hasPreview
              hasFeedback
              value={values.old_password}
              error={errors.old_password}
              touched={touched.old_password}
            />
          </Grid>
        )}
        <Grid item container xs={12} columnSpacing={2} rowSpacing={6}>
          <Grid item md={6} xs={12}>
            <Input
              id="new_password"
              name="new_password"
              label={MESSAGES.new_password}
              type="password"
              hasScale
              hasPreview
              hasFeedback
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.new_password}
              error={errors.new_password}
              touched={touched.new_password}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              id="confirm_password"
              name="confirm_password"
              label={MESSAGES.confirm_password}
              type="password"
              onChange={handleChange}
              onBlur={handleBlur}
              hasPreview
              hasFeedback
              value={values.confirm_password}
              error={errors.confirm_password}
              touched={touched.confirm_password}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Stack
            direction="row"
            spacing={1}
            display="flex"
            justifyContent="flex-end"
          >
            { hasFormChanged ? (
              <ConfirmationCancelButton
                onConfirm={onClick}
                status={status}
              />
            ) : (
              <Button
                onClick={onClick}
                type={BUTTON_TYPE.secondary}
                isPreventSubmit
              >
                {MESSAGES.cancel}
              </Button>
            )}
            <Button
              type={BUTTON_TYPE.primary}
              isDisabled={REQUEST_STATUS.loading === status}
            >
              {MESSAGES.save}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
};

PasswordEditMode.propTypes = {
  onClick: PropTypes.func,
};

PasswordEditMode.defaultProps = {
  onClick: noop,
};

export default PasswordEditMode;
