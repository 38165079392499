import { defaultInputProps, inputPropTypes } from 'components/common/Input/inputPropTypes';
import propTypes from 'prop-types';

export const stripeElementPropTypes = {
  ...inputPropTypes,
  children: propTypes.node.isRequired,
  placeholder: propTypes.string.isRequired,
  hasLock: propTypes.bool,
  hasHelp: propTypes.bool,
};

export const defaultStripeElementProps = {
  ...defaultInputProps,
  hasLock: false,
  hasHelp: false,
};
