import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export const ListSelectItem = ({ label, onClick }) => (
  <li
    className="drop_option"
    onClick={onClick}
  >
    {label}
  </li>
);

ListSelectItem.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

ListSelectItem.defaultProps = {
  onClick: undefined,
};
