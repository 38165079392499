import React, { useState } from 'react';

import Input from 'components/common/Input';
import Button from 'components/common/Button';
import { BUTTON_TYPE, REQUEST_STATUS } from 'constants/common';
import useAsync from 'hooks/useAsync';
import useForm from 'hooks/useForm';
import validation from './provideEmailValidation';
import restorePasswordPropTypes from 'containers/Authorization/RestorePassword/types';
import { ROUTES } from 'constants/routes';
import useRouter from 'hooks/useRouter';
import { restorePassword } from 'api/authAPI';
import { MESSAGES } from 'constants/messages';
import ReactTooltip from 'react-tooltip';
import { size } from 'lodash';
import Grid from '@mui/material/Grid';
import Block from 'components/common/Block';

const ProvideEmail = () => {
  document.title = `${MESSAGES.restore_password} - ${MESSAGES.custimy}`;
  const { push } = useRouter();
  const [isResetEmailSent, setIsResetEmailSent] = useState(false);

  const cancelHandler = () => {
    setIsResetEmailSent(false);
    push(ROUTES.portal);
  };

  const submitHandler = async ({ email }, setError) => {
    try {
      await restorePassword({ email });
      setIsResetEmailSent(true);
    } catch (error) {
      // eslint-disable-next-line camelcase
      const { email: responseEmail, non_field_errors } = error;

      setError({
        email: {
          header: (responseEmail && responseEmail[0]) || non_field_errors[0],
        },
      });
    }
  };

  const { execute, status } = useAsync(submitHandler);
  const {
    handleSubmit, handleChange, handleBlur, values, touched, errors,
  } = useForm(execute, validation);

  const calculateToolTipPosition = (pos, e) => ({ left: (e.x - 45), top: e.y + 15 });

  return (
    <Grid item xs={12} sx={{ margin: { lg: 'auto 65px auto 225px', md: 'auto 65px auto 25px', xs: 'auto' } }}>
      <div className="container__main">
        <Block>
          <main className="main">
            <>
              {isResetEmailSent ? (
                <>
                  <h1 className="main__title-other">{MESSAGES.we_sent_you_an_instruction_email}</h1>
                  <p className="main__subtitle-other restore-subtitle">{MESSAGES.email_will_be_in_your_inbox}</p>
                  <Button
                    isPreventSubmit
                    type={BUTTON_TYPE.primary}
                    isDisabled={REQUEST_STATUS.loading === status}
                    onClick={cancelHandler}
                    fillWidth
                  >
                    {MESSAGES.back_to_login}
                  </Button>
                </>
              ) : (
                <>
                  <h1 className="main__title-other">{MESSAGES.forgot_your_password_q}</h1>
                  <p className="main__subtitle-other">{MESSAGES.no_problem_we_send_you_a_link}</p>
                  <form className="main__form form" onSubmit={handleSubmit} noValidate>
                    <div className="form__field form__field--last">
                      <Input
                        id="email"
                        name="email"
                        label={MESSAGES.email}
                        type="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete="email"
                        value={values.email}
                        error={errors.email}
                        touched={touched.email}
                        isRequired
                        hasFeedback={!!values?.email?.length}
                      />
                    </div>
                    <div className="form__actions">
                      <div
                        className="form__actions__submit-sign-in"
                        data-tip
                        data-for="bottonTip"
                      >
                        <Button
                          type={BUTTON_TYPE.primary}
                          isDisabled={REQUEST_STATUS.loading === status}
                          fillWidth
                        >
                          {MESSAGES.restore_password}
                        </Button>
                        {!!size(errors)
                        && (
                          <ReactTooltip className="tooltip-container" id="bottonTip" place="right" overridePosition={calculateToolTipPosition} type="light" effect="solid" globalEventOff="click" arrowColor="transparent">
                            <div className="tooltip-text">{MESSAGES.please_fill_in_your_information}</div>
                          </ReactTooltip>
                        )}
                      </div>
                      <div className="input-button">
                        <input
                          type="submit"
                          value={MESSAGES.back_to_login}
                          onClick={cancelHandler}
                        />
                      </div>
                    </div>
                  </form>
                </>
              )}
            </>
          </main>
        </Block>
      </div>
    </Grid>
  );
};

ProvideEmail.propTypes = restorePasswordPropTypes;

export default ProvideEmail;
