import React from 'react';
import PropTypes from 'prop-types';
import Item from 'components/views/Customer/RecentActivity/item';
import 'components/views/Customer/RecentActivity/style.scss';
import { MESSAGES } from 'constants/messages';
import Block from 'components/common/Block';
import { GUIDES } from 'constants/guides';
import Divider from 'components/common/Divider';

const RecentActivity = ({ data }) => (
  <Block
    postHeader={MESSAGES.recent_activity}
    className="recent-activity"
    guide={GUIDES.CSV10}
  >
    <div className="items">
      {!!data && !!data.length ? data.map((item, k) => {
        if (k !== data.length - 1 && !!item.event_source) {
          return (
            <>
              <Item item={item} />
              <Divider />
            </>
          );
        }
        return <Item item={item} />;
      }) : (
        <div className="empty-state">
          {MESSAGES.no_activity_tracked_yet_for_customer}
        </div>
      )}
    </div>
  </Block>
);

RecentActivity.propTypes = {
  data: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.bool,
  ])).isRequired,
};

export default RecentActivity;
