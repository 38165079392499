import React from 'react';
import classNames from 'classnames';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './styles.scss';
import { VIEWTYPE } from 'constants/common';
import useRouter from 'hooks/useRouter';
import { getRouteKeyFromPath } from 'helper/routerHelper';

const SideBarMenuItem = ({
  item, viewType, toggleMenu, onCloseSlideMenu,
}) => {
  const { title, hrefTo, disabled } = item;
  const { location } = useRouter();

  const handleLinkClick = (e) => {
    if (disabled) {
      e.preventDefault();
    }
    if (viewType === VIEWTYPE.TABLET) toggleMenu();
    if (viewType === VIEWTYPE.LARGE) onCloseSlideMenu();
  };

  const isActive = hrefTo === location.pathname
    || item.id === getRouteKeyFromPath(location.pathname);

  return (
    <li className={classNames('full-menu-item', {
      'full-menu__item--disabled': disabled,
    })}
    >
      <button
        type="button"
        className="full-menu-item__link-wrapper"
        title={title}
      >

        <Link
          onClick={handleLinkClick}
          to={hrefTo}
          className={classNames('full-menu-item__link', {
            'full-menu-item__link--active': isActive,
          })}
          title={title}
        >
          {title}
        </Link>
      </button>
    </li>
  );
};

SideBarMenuItem.propTypes = {
  item: propTypes.objectOf(propTypes.oneOfType([propTypes.any])).isRequired,
  viewType: propTypes.string.isRequired,
  toggleMenu: propTypes.func,
  onCloseSlideMenu: propTypes.func,
};

SideBarMenuItem.defaultProps = {
  toggleMenu: null,
  onCloseSlideMenu: null,
};

export default SideBarMenuItem;
