import React from 'react';
import Status from 'components/views/Integrations/Status';
import ToggleSwitch from 'components/common/ToggleSwitch';
import propTypes from 'prop-types';
import { dateFormatter } from 'helper/moment';
import { ReactComponent as EditIcon } from 'assets/svg/pen.svg';
import { ACTIVE_INTEGRATION_STATUSES } from 'components/views/Integrations/constants/Constants';
import classNames from 'classnames';
import SourceIconBox from 'components/views/Integrations/IconBox';
import { Grid } from '@mui/material';
import IconButton from 'components/common/IconButton';

const ActiveIntegrationItem = ({
  integration, activateIntegration, onEditClick, readonly,
}) => (
  <div
    className={classNames('active-integrations__item', `active-integrations__item--status-${ACTIVE_INTEGRATION_STATUSES[integration.integration_status].toLowerCase()}`)}
  >
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item xs={3}>
        <SourceIconBox source={integration.source} justLogo />
      </Grid>
      <Grid item xs={3}>
        {(!!integration.last_successful_pulling && dateFormatter(integration.last_successful_pulling, undefined, 'DD/MM')) || '--/--'}
      </Grid>
      <Grid item xs={2}>
        <Status status={integration.integration_status} />
      </Grid>
      <Grid item xs={2} container justifyContent="center" alignItems="center">
        <IconButton
          icon={EditIcon}
          onClick={onEditClick}
          type="secondary"
        />
      </Grid>
      <Grid item xs={2}>
        <ToggleSwitch
          name={integration.source.uuid}
          checked={ACTIVE_INTEGRATION_STATUSES[integration.integration_status] !== ACTIVE_INTEGRATION_STATUSES.DISABLED}
          onChange={() => {
            activateIntegration(integration);
          }}
          disabled={readonly}
        />
      </Grid>
    </Grid>
  </div>
);

ActiveIntegrationItem.propTypes = {
  integration: propTypes.object,
  activateIntegration: propTypes.func,
  onEditClick: propTypes.func,
  readonly: propTypes.bool,
};

ActiveIntegrationItem.defaultProps = {
  integration: {},
  activateIntegration: undefined,
  onEditClick: undefined,
  readonly: null,
};

export default ActiveIntegrationItem;
