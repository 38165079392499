import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { ReactComponent as InfoIcon } from 'assets/svg/info.svg';
import classNames from 'classnames';
import InfoTooltip from 'components/common/InfoTooltip';

const GuideInfo = ({ info, type, tooltipPlacement }) => (
  <InfoTooltip
    text={info}
    disablePropagation
    placement={tooltipPlacement}
  >
    <InfoIcon
      className={classNames('info-icon', type)}
    />
  </InfoTooltip>
);

GuideInfo.propTypes = {
  info: PropTypes.string,
  type: PropTypes.oneOf(['white', 'grey', 'light-grey']),
  tooltipPlacement: InfoTooltip.propTypes.placement,
};

GuideInfo.defaultProps = {
  info: undefined,
  type: 'light-grey',
  tooltipPlacement: 'bottom',
};

export default GuideInfo;
