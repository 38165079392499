import React, { Children } from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import SeparatorLine from 'components/common/SeparatorLine';

const MetricList = ({ children }) => {
  const listItems = [];
  const childrenCount = Children.count(children);
  let childrenIndex = 0;
  Children.forEach(children, (child) => {
    childrenIndex += 1;
    listItems.push(child);

    if (childrenCount > childrenIndex) {
      listItems.push(<SeparatorLine />);
    }
  });

  return (
    <div className="metric-list">
      <Stack spacing={1}>
        {listItems}
      </Stack>
    </div>
  );
};

MetricList.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default MetricList;
