import { createSlice } from '@reduxjs/toolkit';

import moduleName from './constants';

const initialState = {
  payment_method_data: null,
};

const paymentMethodSlice = createSlice({
  name: moduleName,
  initialState,
  reducers: {
    setPaymentMethodAction(state, action) {
      const { payment_method_data } = action.payload;
      state.payment_method_data = payment_method_data;
    },
    cleanupPaymentMethodAction: () => initialState,
  },
});

export const {
  setPaymentMethodAction,
  cleanupPaymentMethodAction,
} = paymentMethodSlice.actions;

export default paymentMethodSlice.reducer;
