import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import 'components/views/Settings/LicenseSettings/LicenseMembers/LicenseMembersTable/LicenseMembersTableItem/styles.scss';
import { AVATAR_TYPE } from 'constants/common';
import Avatar from 'components/common/Avatar';
import Dropdown from 'components/common/Dropdown';
import { getCurrentUser, getIsSuperUser } from 'store/profile/getters';
import { getIsLicenseAdmin } from 'store/license/getters';
import ConfirmationRemoveButton
  from 'components/views/Settings/LicenseSettings/LicenseMembers/LicenseMembersTable/LicenseMembersTableItem/ConfirmationRemoveButton';
import ReInviteFeedback
  from 'components/views/Settings/LicenseSettings/LicenseMembers/LicenseMembersTable/LicenseMembersTableItem/ReInviteFeedback';

const LicenseMembersTableItem = ({
  licenseMember,
  licenseStatusOptions,
  confirmInviteUserHandler,
  confirmInviteUserStatus,
  status,
  onSelect,
  onDelete,
}) => {
  const { uuid: currentUserUUID } = useSelector(getCurrentUser);
  const {
    first_name: firstName,
    last_name: lastName,
    email: workEmail,
    uuid: memberUUID,
    avatar,
  } = licenseMember.user;
  const memberStatus = status.length && status[1];

  const isOwner = currentUserUUID === memberUUID;
  const isInvited = memberStatus === 'Invited';
  const isAdministrator = useSelector(getIsLicenseAdmin);
  const isSuperUser = useSelector(getIsSuperUser);
  const defaultStatusValue = licenseStatusOptions
    .find((option) => option.label === memberStatus);

  const deleteMemberHandler = () => onDelete(licenseMember);
  const onLicenseStatusChangeHandler = (selectedStatus) => onSelect(selectedStatus, licenseMember);
  const reInviteClickHandler = () => confirmInviteUserHandler(workEmail);

  return (
    <tr className={classNames('license-members-table-row', {
      'license-members-table-row--invited': isInvited,
    })}
    >
      <td className="license-table__column license-members-table__column--user">
        <div className="license-table-row__logo">
          {avatar ? <img src={avatar} alt="avatar" className="license-members-avatar" /> : <Avatar type={AVATAR_TYPE.small} />}
        </div>
        <div className="license-members-table-row__name">
          <span
            title={`${firstName} ${lastName}`}
            className="license-members-table-row__name-text"
          >
            {firstName}
            {' '}
            {lastName}
          </span>
        </div>
      </td>
      <td className="license-table__column license-members-table__column--email">
        <div className="license-members-table-row__email">
          <span title={workEmail} className="license-members-table-row__email-text">
            {workEmail}
          </span>
        </div>
      </td>
      <td className="license-table__column license-members-table__column--status">
        {
          isInvited ? (
            <div className="license-members-table-row-status license-members-table-row-status--invited">
              {memberStatus}
              <ReInviteFeedback
                reInviteClick={reInviteClickHandler}
                reInviteStatus={confirmInviteUserStatus}
              />
            </div>
          ) : (
            <div className="license-members-table-row__status-wrapper">
              <Dropdown
                onChange={onLicenseStatusChangeHandler}
                value={defaultStatusValue}
                options={licenseStatusOptions}
                className="license-members-table-row-status"
                isDisable={isOwner || !isAdministrator}
              />
            </div>
          )
        }
      </td>
      {
        isAdministrator && (
          <td className="license-table__column license-members-table__column--remove">
            {(isOwner && !isSuperUser)
              || <ConfirmationRemoveButton onConfirm={deleteMemberHandler} />}
          </td>
        )
      }
    </tr>
  );
};

LicenseMembersTableItem.propTypes = {
  licenseMember: propTypes.objectOf(propTypes.string).isRequired,
  licenseStatusOptions: propTypes.arrayOf(propTypes.object).isRequired,
  confirmInviteUserHandler: propTypes.func.isRequired,
  confirmInviteUserStatus: propTypes.string.isRequired,
  status: propTypes.string.isRequired,
  onSelect: propTypes.func.isRequired,
  onDelete: propTypes.func.isRequired,
};

export default LicenseMembersTableItem;
