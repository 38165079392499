import React from 'react';
import propTypes from 'prop-types';

import DeleteLicenseConfirmationWindow from 'components/views/Settings/LicenseSettings/License/DeleteLicense/DeleteLicenseConfirmationWindow';
import DeleteLicenseButton from 'components/views/Settings/LicenseSettings/License/DeleteLicense/DeleteLicenseButton';
import { MESSAGES } from 'constants/messages';

const DeleteLicense = ({ onConfirm, onCancel }) => {
  const onCancelWithClose = (close) => {
    onCancel();
    close();
  };

  return (
    <div className="license-settings__delete-license">
      <DeleteLicenseConfirmationWindow
        content={MESSAGES.changes_will_be_lost_do_you_want_to_cancel}
        preventNavigationBlocking={onConfirm}
        onCancel={onCancelWithClose}
        customOpenButton={DeleteLicenseButton}
      />
    </div>
  );
};

DeleteLicense.propTypes = {
  onConfirm: propTypes.func.isRequired,
  onCancel: propTypes.func.isRequired,
};

export default DeleteLicense;
