export const PUBLIC_KEY = {
  google_analytics: {
    urlName: 'client_id',
    value: process.env.REACT_APP_GA_OAUTH_CLIENT_ID,
  },
  google_ads: {
    urlName: 'client_id',
    value: process.env.REACT_APP_GA_OAUTH_CLIENT_ID,
  },
  shopify: {
    urlName: 'client_id',
    value: process.env.REACT_APP_SHOPIFY_OAUTH_CLIENT_ID,
  },
  facebook_ads: {
    urlName: 'client_id',
    value: process.env.REACT_APP_FACEBOOK_APP_ID,
  },
  snapchat: {
    urlName: 'client_id',
    value: process.env.REACT_APP_SNAPCHAT_APP_ID,
  },
  tiktok: {
    urlName: 'app_id',
    value: process.env.REACT_APP_TIKTOK_APP_ID,
  },
  business_central: {
    urlName: 'client_id',
    value: process.env.REACT_APP_BUSINESS_CENTRAL_APP_ID,
  },
};
