import React, { useLayoutEffect, useState } from 'react';
import useAsync from 'hooks/useAsync';
import useForm from 'hooks/useForm';
import useRouter from 'hooks/useRouter';
import validation from './resetLinkValidation';
import Input from 'components/common/Input';
import Button from 'components/common/Button';
import { BUTTON_TYPE, REQUEST_STATUS } from 'constants/common';
import restorePasswordPropTypes from 'containers/Authorization/RestorePassword/types';
import { ROUTES } from 'constants/routes';
import {
  RESTORE_PASSWORD_LINK_EXPIRED,
  RESTORE_PASSWORD_RESTORED,
} from 'constants/errors';
import useLogout from 'hooks/useLogout';
import { restorePasswordTokenConfirm } from 'api/authAPI';
import { createInvitedUser } from 'api/usersAPI';
import { MESSAGES } from 'constants/messages';
import classNames from 'classnames';
import { size } from 'lodash';
import PasswordChecks from 'containers/Authorization/PasswordChecks';
import Grid from '@mui/material/Grid';
import Block from 'components/common/Block';
import FormModal from 'containers/Authorization/RestorePassword/ResetLink/FormModal';

const ResetLink = () => {
  document.title = `${MESSAGES.restore_password} - ${MESSAGES.custimy}`;
  const { query, push } = useRouter();
  const logoutHandler = useLogout();

  const [updateUUID, setUpdateUUID] = useState(null);
  const [isPasswordRestored, setIsPasswordRestored] = useState(false);
  const [isLinkExpired, setIsLinkExpired] = useState(false);

  const resendLinkHandler = () => {
    setIsPasswordRestored(false);
    push(ROUTES.restorePassword);
  };

  const cancelHandler = () => {
    logoutHandler();
    setIsPasswordRestored(false);
    push(ROUTES.portal);
  };

  const submitHandler = async ({ password, confirmPassword }) => {
    await createInvitedUser({ updateUUID, password, confirm_password: confirmPassword });

    setIsPasswordRestored(true);
  };

  const verifyLinkParams = async () => {
    const { token, uid } = query;

    if (!token || !uid) {
      setIsLinkExpired(true);
      setIsPasswordRestored(true);
      return;
    }

    try {
      const { uuid: responseUUID } = await restorePasswordTokenConfirm({ uid, token });
      setUpdateUUID(responseUUID);
    } catch (e) {
      setIsLinkExpired(true);
      setIsPasswordRestored(true);
    }
  };

  const {
    execute: verifyLinkParamsAsync,
    status: verifyLinkParamsStatus,
  } = useAsync(verifyLinkParams);

  useLayoutEffect(() => {
    verifyLinkParamsAsync();
  }, []);

  const { execute, status } = useAsync(submitHandler);
  const {
    handleSubmit, handleChange, handleBlur, values, touched, errors,
  } = useForm(execute, validation);

  const actionsConfig = isLinkExpired ? [
    {
      label: MESSAGES.resend_link,
      status,
      onClickHandler: resendLinkHandler,
    },
    {
      label: MESSAGES.back_to_login,
      withInput: true,
      onClickHandler: cancelHandler,
    },
  ] : [
    {
      label: MESSAGES.back_to_login,
      status,
      onClickHandler: cancelHandler,
    },
  ];

  return verifyLinkParamsStatus !== REQUEST_STATUS.loading && (
    <>
      <Grid
        item
        xs={12}
        sx={{
          margin: { lg: 'auto 65px auto 225px', md: 'auto 65px auto 25px', xs: 'auto' },
          minWidth: { sm: '400px', xs: '100%' },
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div className="container__main">
          <Block>
            <main className="main">
              <h1 className="main__title-other">
                {isLinkExpired
                  ? MESSAGES.expired_link
                  : isPasswordRestored
                    ? MESSAGES.successful
                    : MESSAGES.create_new_password}
              </h1>
              {isPasswordRestored ? (
                <>
                  <p className="main__subtitle-other restore-subtitle">
                    {isLinkExpired
                      ? RESTORE_PASSWORD_LINK_EXPIRED
                      : isPasswordRestored
                        ? RESTORE_PASSWORD_RESTORED
                        : null }
                  </p>
                  <FormModal actionsConfig={actionsConfig} />
                </>
              ) : (
                <>
                  <form className="main__form form" onSubmit={handleSubmit} noValidate>
                    <div className="form__field field-password password-resetlink">
                      <Input
                        id="password"
                        name="password"
                        label={MESSAGES.new_password}
                        type="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        error={errors.password}
                        touched={touched.password}
                        autoComplete="new-password"
                        isRequired
                        hasPreview
                        hasFeedback={!!values?.password?.length}
                        hasSuccess
                      />
                    </div>
                    <PasswordChecks password={values.password} />
                    <div className="form__field form__field--last form__field--confirm">
                      <Input
                        id="confirmPassword"
                        name="confirmPassword"
                        label={MESSAGES.confirm_new_password}
                        type="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete="new-password"
                        value={values.confirmPassword}
                        error={errors.confirmPassword}
                        touched={touched.confirmPassword}
                        isRequired
                        hasPreview
                        hasFeedback={!!values?.confirmPassword?.length}
                        hasSuccess
                      />
                    </div>
                    <div className="form__actions">
                      <div
                        className={classNames('form__actions__submit-sign-up', {
                          'submit-success': !size(errors) && !!values?.password?.length,
                        })}
                      >
                        <Button
                          block
                          fillWidth
                          type={BUTTON_TYPE.primary}
                          isDisabled={REQUEST_STATUS.loading === status}
                        >
                          {MESSAGES.save_password}
                        </Button>
                      </div>
                    </div>
                  </form>
                </>
              )}
            </main>
          </Block>
        </div>
      </Grid>
    </>
  );
};

ResetLink.propTypes = restorePasswordPropTypes;

export default ResetLink;
