import React from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import classNames from 'classnames';
import { noop } from 'lodash';
import './styles.scss';

const IconSwitch = ({ options, selected, onSelect }) => (
  <Stack direction="row" className="icon-switch">
    {options.map(({ value, icon: Icon }) => (
      <div
        key={value}
        onClick={() => onSelect(value)}
        className={classNames('icon-switch__option', {
          selected: selected === value,
        })}
      >
        <Icon className="icon-switch__option__icon" />
      </div>
    ))}
  </Stack>
);

IconSwitch.propTypes = {
  selected: PropTypes.string,
  onSelect: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.object.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
};

IconSwitch.defaultProps = {
  selected: null,
  onSelect: noop,
};

export default IconSwitch;