import axios from 'axios';
import { HTTP_METHODS, HTTP_STATUS } from 'constants/http';
import { accessTokenLSManager } from 'helper/localStorage';
import { handleError } from 'utils/responseErrorHanlder';
import { ENDPOINTS } from 'constants/routes';

const http = async ({
  url,
  method = HTTP_METHODS.GET,
  data,
  useToken = true,
  throwError = false,
  onlyData = true,
  noContentType,
  cancelToken = undefined,
  ...rest
}) => {
  const headers = {
    'Access-Control-Allow-Origin': '*',
  };
  if (!noContentType) {
    headers['Content-Type'] = 'application/json';
    headers.Accept = 'application/json';
  }
  const accessToken = accessTokenLSManager.get();

  if (accessToken && useToken) {
    headers.Authorization = `Bearer ${accessToken}`;
  }

  try {
    const response = await axios({
      url: `${ENDPOINTS.baseUrl}${url}`,
      method,
      headers,
      data,
      cancelToken,
      ...rest,
    });

    if (response.status >= 200 && response.status < 400) {
      if (response.status === HTTP_STATUS.noContent) {
        return undefined;
      }
    }

    return onlyData ? response.data : response;
  } catch (error) {
    if (throwError) {
      throw error;
    }

    const handleErrorResponse = await handleError(error.response, error);

    if (!handleErrorResponse) {
      await Promise.reject(error.response.data);
    }
    if (handleErrorResponse.error) {
      await Promise.reject(handleErrorResponse.error.data);
    }
    if (handleErrorResponse.cancel) {
      await Promise.reject(handleErrorResponse.cancel);
    }
    if (handleErrorResponse.response) {
      return handleErrorResponse.response;
    }
  }

  return undefined;
};

export default http;
