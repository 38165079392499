import React, { useEffect, useState } from 'react';
import {
  assign, flow, keyBy, omit, partialRight
} from 'lodash';
import useRouter from 'hooks/useRouter';
import { getCustomAudiencesList, updateCustomAudience } from 'api/customAudiencesAPI';
import useListApi from 'hooks/useListApi';
import { getChannels } from 'api/integrationsAPI';
import { NameCell, SegmentsCell } from 'components/common/CommonTableCells';
import {
  SizeCell,
  StatusCell,
  StatusSwitchCell,
  CreatorCell,
  ActivationDateCell,
  ChannelsCell, AudienceTypeCell,
} from 'components/views/CustomAudiences/ActivationCells';
import { ENTITY_CHANNEL, ENTITY_SEGMENT } from 'constants/entities';
import { getSegmentsInfo } from 'api/segmentsAPI';
import { Box, Grid, Stack } from '@mui/material';
import { MESSAGES } from 'constants/messages';
import ContentLoader from 'components/common/ContentLoader';
import Block from 'components/common/Block';
import { ReactComponent as ActivateAudienceIcon } from 'assets/svg/activate_audience.svg';
import Button from 'components/common/Button';
import { AUDIENCES_PER_PAGE, BUTTON_TYPE } from 'constants/common';
import { ROUTES } from 'constants/routes';
import 'components/views/CustomAudiences/styles.scss';
import PaginatedTable from 'components/common/Table/PaginatedTable';
import Search from 'components/common/Search';
import TableSearchEntity from 'components/common/TableSearchEntity';
import FilterTags from 'components/common/FilterTags';
import { LIST_API_REDUX_KEYS } from 'constants/listApi';
import { GUIDES } from 'constants/guides';
import GuideInfo from 'components/common/GuideInfo';
import { ActionsCell } from 'components/common/Table/TableCells';
import DeleteAudiencePreviewModal from 'components/views/CustomAudience/DeleteAudiencePreviewModal';

const getTableConfig = (channelsMap, segmentsMap, updateAudience, actions) => [{
  title: MESSAGES.name,
  key: 'name',
  ordering: ['name'],
  CellComponent: NameCell,
  componentProps: {},
  width: '250px',
  hasOverflowEllipsis: true,
}, {
  title: MESSAGES.type,
  key: 'type',
  ordering: null,
  CellComponent: AudienceTypeCell,
  componentProps: {},
  width: '130px',
  hasOverflowEllipsis: false,
}, {
  title: MESSAGES.channels,
  key: 'channels',
  ordering: null,
  CellComponent: ChannelsCell,
  componentProps: { channelsMap },
  width: '185px',
  hasOverflowEllipsis: false,
}, {
  title: MESSAGES.segments,
  key: 'segment_ids',
  ordering: null,
  CellComponent: SegmentsCell,
  componentProps: { segmentsMap, name_key: 'segment_name' },
  width: '150px',
  hasOverflowEllipsis: false,
}, {
  title: MESSAGES.size,
  key: 'size',
  ordering: null,
  CellComponent: SizeCell,
  componentProps: {},
  width: '120px',
  hasOverflowEllipsis: true,
}, {
  title: MESSAGES.creator,
  key: 'creator',
  ordering: ['creator__first_name', 'creator__last_name'],
  CellComponent: CreatorCell,
  componentProps: {},
  width: '10%',
  hasOverflowEllipsis: true,
}, {
  title: MESSAGES.created,
  key: 'created_at',
  ordering: ['created_at'],
  CellComponent: ActivationDateCell,
  componentProps: {},
  width: '90px',
  hasOverflowEllipsis: false,
}, {
  title: MESSAGES.synchronized,
  key: 'last_synchronized',
  ordering: ['last_synchronized'],
  CellComponent: ActivationDateCell,
  componentProps: {},
  width: '120px',
  hasOverflowEllipsis: true,
}, {
  title: null,
  key: 'is_active_switch',
  ordering: null,
  CellComponent: StatusSwitchCell,
  componentProps: { updateAudience },
  width: '40px',
  hasOverflowEllipsis: false,
}, {
  title: MESSAGES.status,
  key: 'is_active',
  ordering: ['is_active'],
  CellComponent: StatusCell,
  componentProps: {},
  width: '100px',
  hasOverflowEllipsis: false,
}, {
  title: null,
  key: null,
  ordering: null,
  CellComponent: ActionsCell,
  componentProps: { actions },
  width: '60px',
  hasOverflowEllipsis: false,
}];

const searchEntityConfig = [{
  key: ENTITY_CHANNEL,
  title: MESSAGES.channels,
  hasPages: false,
}, {
  key: ENTITY_SEGMENT,
  title: MESSAGES.segments,
  hasPages: false,
}];

const defaultConfig = { ordering: ['-last_synchronized'], page_size: AUDIENCES_PER_PAGE };

const CustomAudiences = () => {
  const [channelsMap, setChannelsMap] = useState(null);
  const [segmentsMap, setSegmentsMap] = useState(null);
  const [deleteAudienceUuid, setDeleteAudienceUuid] = useState();
  const { push } = useRouter();

  const {
    data,
    setData,
    totalCount,
    isDataLoading,
    config,
    changeConfig,
    reloadData,
  } = useListApi(LIST_API_REDUX_KEYS.customAudiences, defaultConfig, getCustomAudiencesList);

  useEffect(() => {
    const populateChannels = async () => {
      const channelsList = await getChannels();
      setChannelsMap(keyBy(channelsList, 'uuid'));
    };

    const populateSegments = async () => {
      const segmentsInfo = await getSegmentsInfo();
      setSegmentsMap(keyBy(segmentsInfo, 'id'));
    };

    populateChannels();
    populateSegments();
  }, []);

  const onCreateEditItem = (uuid = '') => {
    const editRoute = ROUTES.customAudience.replace(':uuid', uuid || 'new');
    push(editRoute);
  };

  const handleTagsChange = (tags) => changeConfig({ tags });
  const handleSortChange = (ordering) => changeConfig({ ordering });
  const handlePageChange = (page) => changeConfig({ page });
  const handleSearchChange = (search) => changeConfig({ search });

  const updateAudience = async (row, isActive) => {
    row.is_active = isActive;
    setData([...data]);

    const audienceData = {
      ...row,
      integrations: row.channels.map((channel) => channel.integration),
    };
    await updateCustomAudience(row.uuid, audienceData);
  };

  const handleEdit = ({ row }) => onCreateEditItem(row.uuid);
  const handleDuplicate = ({ row }) => {
    const createRoute = ROUTES.customAudience.replace(':uuid', 'new');

    const prepare = flow(
      partialRight(omit, ['uuid']),
      partialRight(assign, { name: `${row.name} - Copy` }),
    );

    push(createRoute, prepare(row));
  };
  const handleDelete = ({ row }) => setDeleteAudienceUuid(row.uuid);

  const actions = [{
    name: MESSAGES.edit,
    action: handleEdit,
  }, {
    name: MESSAGES.duplicate,
    action: handleDuplicate,
  }, {
    name: MESSAGES.delete,
    action: handleDelete,
  }];

  const tableConfig = getTableConfig(channelsMap, segmentsMap, updateAudience, actions);

  return (
    <>
      <div className="activation-container">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item lg={3} xs={12}>
                <Block
                  header={MESSAGES.custom_audiences}
                  postHeader={MESSAGES.activate_your_custom_data}
                  guide={GUIDES.A4}
                  type="large-header"
                  fillHeight
                />
              </Grid>
              <Grid item lg={9} xs={12}>
                <Block fillHeight>
                  <Grid container spacing={2}>
                    <Grid item md={4} xs={12}>
                      <Search
                        search={config.search}
                        title={MESSAGES.search_by_name}
                        searchChanged={handleSearchChange}
                      />
                    </Grid>
                    <Grid item md={4} xs={5}>
                      <TableSearchEntity
                        searchEntityConfig={searchEntityConfig}
                        tags={config.tags}
                        tagsChanged={handleTagsChange}
                      />
                    </Grid>
                    <Grid item md={4} xs={7}>
                      <Box display="flex" justifyContent="flex-end">
                        <Stack direction="row" spacing={2}>
                          <Button
                            type={BUTTON_TYPE.primary}
                            onClick={() => onCreateEditItem()}
                          >
                            {MESSAGES.new_audience}
                          </Button>
                          <GuideInfo info={GUIDES.A1} />
                        </Stack>
                      </Box>
                    </Grid>
                    {(!!config.tags.length) && (
                      <Grid item xs={12}>
                        <FilterTags
                          tags={config.tags}
                          tagsChanged={handleTagsChange}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Block>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {data?.length || config.search.length || config.tags.length ? (
              <Block header={MESSAGES.custom_audiences} guide={GUIDES.A3}>
                <PaginatedTable
                  handleRowClick={(row) => onCreateEditItem(row.uuid)}
                  sort={config.ordering}
                  sortChanged={handleSortChange}
                  totalCount={totalCount}
                  tableConfig={tableConfig}
                  data={data}
                  highlightStartIdx={0}
                  highlightEndIdx={10}
                  countPerPage={AUDIENCES_PER_PAGE}
                  page={config.page}
                  pageChanged={handlePageChange}
                  isDataLoading={isDataLoading}
                />
              </Block>
            ) : (
              <ContentLoader isLoading={isDataLoading}>
                <Block fillHeight>
                  <Grid item xs={12} paddingTop="80px" paddingBottom="80px">
                    <Stack spacing={2} alignItems="center" justifyContent="center">
                      <ActivateAudienceIcon className="new-audience-icon" />
                      <div className="empty-list-text">{MESSAGES.create_your_first_audience}</div>
                      <Button
                        type={BUTTON_TYPE.primary}
                        onClick={() => onCreateEditItem()}
                      >
                        {MESSAGES.new_audience}
                      </Button>
                    </Stack>
                  </Grid>
                </Block>
              </ContentLoader>
            )}
          </Grid>
        </Grid>
      </div>
      <DeleteAudiencePreviewModal
        deleteAudienceUuid={deleteAudienceUuid}
        onClose={() => setDeleteAudienceUuid(null)}
        onSubmit={reloadData}
      />
    </>
  );
};

export default CustomAudiences;
