import { useDispatch } from 'react-redux';

import { cleanUpPrivateStorage } from 'utils/common';
import { resetApp } from 'store/common/actions';
import { ROUTES } from 'constants/routes';
import useRouter from 'hooks/useRouter';
import { googleLogout } from '@react-oauth/google';

export default () => {
  const { replace } = useRouter();
  const dispatch = useDispatch();

  return () => {
    cleanUpPrivateStorage();
    googleLogout();
    dispatch(resetApp());
    replace(ROUTES.signIn);
  };
};
