import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import Search from 'components/common/Search';
import { MESSAGES } from 'constants/messages';
import DataExport from 'components/common/DataExport';
import { TableColumnSelector } from 'components/common/Table/TableColumnSelector';
import PaginatedTable from 'components/common/Table/PaginatedTable';
import { PRODUCTS_PER_PAGE } from 'constants/common';
import useListApi from 'hooks/useListApi';
import { LIST_API_REDUX_KEYS } from 'constants/listApi';
import { exportProducts, getProducts, getProductsSegments } from 'api/productsAPI';
import tableColumnHelper from 'components/common/Table/TableColumnSelector/tableColumnHelper';
import { productColumnsLSManager } from 'helper/localStorage';
import { PRODUCT_SEGMENTS_COLORS } from 'constants/colors';
import {
  get, identity, keyBy,
} from 'lodash';
import {
  defaultProductVariantItems,
  getProductVariantListItems,
} from 'components/views/Products/ProductVariantsTable/itemDefinitions';
import PropTypes from 'prop-types';
import { ROUTES } from 'constants/routes';

const ProductVariantsTable = ({ parentId }) => {
  const [currency, setCurrency] = useState();
  const [segmentsMap, setSegmentsMap] = useState({});
  const defaultConfig = { ordering: ['id'], parentIds: [parentId], search: '' };

  const keyFunc = (item) => item.id;

  const {
    config,
    changeConfig,
    totalCount: productListTotalCount,
    data: productsList,
    isDataLoading,
  } = useListApi(LIST_API_REDUX_KEYS.productVariants, defaultConfig, getProducts, true);

  const handleSortChange = (sort) => changeConfig({ ordering: sort });
  const handlePageChange = (page) => changeConfig({ page });
  const handleExport = () => exportProducts(config);
  const tableConfig = getProductVariantListItems(currency, segmentsMap);
  const handleSearchChange = (search) => {
    if (config.search === search) return;
    changeConfig({ search });
  };

  const {
    isChecked,
    setIsChecked,
    filteredTableConfig,
    resetColumns,
  } = tableColumnHelper(
    tableConfig,
    defaultProductVariantItems,
    productColumnsLSManager,
  );

  useEffect(() => {
    const populateSegments = async () => {
      const segments = await getProductsSegments();
      const segmentsWithColor = segments.map((segment) => (
        { ...segment, color_1: PRODUCT_SEGMENTS_COLORS[segment?.id] }));
      setSegmentsMap(keyBy(segmentsWithColor, 'id'));
    };

    populateSegments();
  }, []);

  useEffect(() => {
    setCurrency(get(productsList, [0, 'currency']));
  }, [productsList]);

  const handleRowClick = (item) => {
    const win = window.open(ROUTES.product.replace(':id', item.id), '_blank');
    win.focus();
  };

  const columnNumHighlighted = Object.values(isChecked).filter(identity).length;

  return (
    <Grid container spacing={2}>
      <Grid item lg={4} md={6} xs={12}>
        <Search
          search=""
          title={MESSAGES.search_for_products}
          searchChanged={handleSearchChange}
        />
      </Grid>
      <Grid item container lg={8} md={6} xs={12} justifyContent="flex-end" display="flex" spacing={2}>
        <Grid item>
          <DataExport exportHandler={handleExport} />
        </Grid>
        <Grid item>
          <TableColumnSelector
            tableConfig={tableConfig}
            maxColumnsNum={8}
            onReset={resetColumns}
            isChecked={isChecked}
            onCheckBoxChange={setIsChecked}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <PaginatedTable
          keyFunc={keyFunc}
          sort={config.ordering}
          sortChanged={handleSortChange}
          totalCount={productListTotalCount}
          tableConfig={filteredTableConfig}
          data={productsList}
          highlightStartIdx={0}
          highlightEndIdx={columnNumHighlighted - 1}
          countPerPage={PRODUCTS_PER_PAGE}
          page={config.page}
          pageChanged={handlePageChange}
          isDataLoading={isDataLoading}
          handleRowClick={handleRowClick}
        />
      </Grid>
    </Grid>
  );
};

ProductVariantsTable.propTypes = {
  parentId: PropTypes.string.isRequired,
};

export default ProductVariantsTable;
