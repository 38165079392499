import React from 'react';
import './styles.scss';
import classNames from 'classnames';
import propTypes from 'prop-types';

const ToggleSwitch = ({
  name,
  checked,
  onChange,
  disabled,
}) => (
  <div className={classNames('toggle-switch small-switch', { 'toggle-switch-disabled': disabled })}>
    <input
      type="checkbox"
      className="toggle-switch-checkbox"
      name={name}
      id={name}
      checked={checked}
      onChange={(e) => onChange(e.target.checked)}
      disabled={disabled}
    />
    <label className="toggle-switch-label" htmlFor={name}>
      <span className="toggle-switch-inner" />
      <span className="toggle-switch-switch" />
    </label>
  </div>
);

ToggleSwitch.propTypes = {
  name: propTypes.string,
  disabled: propTypes.bool,
  checked: propTypes.bool,
  onChange: propTypes.func.isRequired,
};

ToggleSwitch.defaultProps = {
  name: undefined,
  disabled: false,
  checked: false,
};
export default ToggleSwitch;
