import { ReactComponent as VisaCardIcon } from 'assets/svg/visaCard.svg';
import { ReactComponent as DinersCardIcon } from 'assets/svg/dinersCard.svg';
import { ReactComponent as AmericanExpressCardIcon } from 'assets/svg/amExCard.svg';
import { ReactComponent as DiscoveryCardIcon } from 'assets/svg/discoverCard.svg';
import { ReactComponent as MasterCardIcon } from 'assets/svg/masterCard.svg';

export const CARD_BRAND = {
  visa: 'visa',
  masterCard: 'mastercard',
  americanExpress: 'amex',
  discover: 'discover',
  dinnersClub: 'diners',
  unknown: 'unknown',
};
export const AVAILABLE_CARDS = {
  [CARD_BRAND.visa]: VisaCardIcon,
  [CARD_BRAND.masterCard]: MasterCardIcon,
  [CARD_BRAND.americanExpress]: AmericanExpressCardIcon,
  [CARD_BRAND.discover]: DiscoveryCardIcon,
  [CARD_BRAND.dinnersClub]: DinersCardIcon,
};
