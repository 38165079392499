import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './styles.scss';
import { ReactComponent as ArrowIcon } from 'assets/svg/arrowUp.svg';
import { ReactComponent as UserIcon } from 'assets/svg/user.svg';
import { ReactComponent as ShieldIcon } from 'assets/svg/shield.svg';
import { ReactComponent as RepeatIcon } from 'assets/svg/repeat.svg';
import { ReactComponent as LogOutIcon } from 'assets/svg/exit.svg';
import Avatar from 'components/common/Avatar';
import { AVATAR_TYPE } from 'constants/common';
import { ROUTES } from 'constants/routes';
import MobileUserPanelItem from 'containers/PrivateApp/Portal/MobileUserPanel/MobileUserPanelItem';
import useRouter from 'hooks/useRouter';
import { useSelector } from 'react-redux';
import useLogout from 'hooks/useLogout';
import { getCurrentUser, getIsSuperUser } from 'store/profile/getters';
import { MESSAGES } from 'constants/messages';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';

const MobileUserPanelheader = () => {
  const {
    first_name: firstName, last_name: lastName, avatar, email,
  } = useSelector(getCurrentUser);
  return (
    <div className="mobile-user-panel-list__header">
      <div className="mobile-user-panel-list__header__avatar">
        {(avatar ? <img src={avatar} alt="avatar" />
          : <Avatar type={AVATAR_TYPE.middle} hasBorder />) }
      </div>
      <div className="mobile-user-panel-list__header__details">
        <span
          title={`${firstName} ${lastName}`}
          className="mobile-user-panel-list__header__details__full-name"
        >
          {firstName}
          {' '}
          {lastName}
        </span>
        <span
          title={`${email}`}
          className="mobile-user-panel-list__header__details__email"
        >
          {email}
        </span>
      </div>
      <ArrowIcon className="mobile-user-panel-list__header__arrow-icon" />
    </div>
  );
};

const MobileUserPanelList = () => {
  const isSuperUser = useSelector(getIsSuperUser);
  const { push } = useRouter();
  const logoutHandler = useLogout();

  const goToProfile = () => push(ROUTES.profile);

  const goToSwitchLicense = () => push(ROUTES.switchLicense);

  const goToAdminPanel = () => push(ROUTES.adminPanelLicenses);

  return (
    <ul className="mobile-user-panel-list__list">
      <ul className="mobile-user-panel-list__list--top">
        <MobileUserPanelItem onClickHandler={goToProfile}>
          <UserIcon className="mobile-user-panel-list__item-icon" />
          {MESSAGES.user_profile}
        </MobileUserPanelItem>
        <MobileUserPanelItem onClickHandler={goToSwitchLicense}>
          <RepeatIcon className="mobile-user-panel-list__item-icon" />
          {MESSAGES.switch_license}
        </MobileUserPanelItem>
        {isSuperUser && (
          <MobileUserPanelItem onClickHandler={goToAdminPanel}>
            <ShieldIcon className="mobile-user-panel-list__item-icon" />
            {MESSAGES.admin_panel}
          </MobileUserPanelItem>
        )}
      </ul>
      <MobileUserPanelItem onClickHandler={logoutHandler}>
        <LogOutIcon className="mobile-user-panel-list__item-icon" />
        {MESSAGES.log_out}
      </MobileUserPanelItem>
    </ul>
  );
};

const MobileUserPanelBlock = ({
  isOpened, onClose,
}) => (
  <div className="mobile-user-panel-list-wrapper">
    <div className="mobile-user-panel-list-container">
      <div
        role="button"
        tabIndex="0"
        onClick={onClose}
        onKeyDown={onClose}
        className={classNames('mobile-user-panel-list', {
          'mobile-user-panel-list--collapsed': !isOpened,
        })}
      >
        <MobileUserPanelheader />
      </div>
      <div
        className="mobile-user-panel-list__items-wrapper"
        style={{
          position: 'absolute', top: 70, right: 0, left: 0, zIndex: 7,
        }}
        role="button"
        tabIndex="0"
        onClick={onClose}
        onKeyDown={onClose}
      >
        <MobileUserPanelList />
      </div>
    </div>
  </div>
);

const MobileUserPanel = ({ toggleMenu }) => {
  const [isOpened, setIsOpened] = useState(false);

  const handleDrawerClick = (val) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsOpened(val);
  };

  const onClose = () => {
    setIsOpened(false);
    toggleMenu();
  };

  return (
    <>
      <div
        className="mobile-user-panel-list-wrapper"
        style={!isOpened ? {
          display: 'block', position: 'fixed', bottom: 0, maxHeight: 70, zIndex: 5,
        } : { display: 'none', position: 'static', bottom: 'none' }}
      >
        <div className="mobile-user-panel-list-container">
          <div
            role="button"
            tabIndex="0"
            onClick={handleDrawerClick(true)}
            onKeyDown={handleDrawerClick(true)}
            className={classNames('mobile-user-panel-list', {
              'mobile-user-panel-list--collapsed': !isOpened,
            })}
          >
            <MobileUserPanelheader />
          </div>
        </div>
      </div>
      <Drawer
        anchor="bottom"
        open={isOpened}
        onClose={handleDrawerClick(false)}
      >
        <Box sx={{ height: 280 }}>
          <MobileUserPanelBlock
            handleDrawerClick={handleDrawerClick}
            onClose={onClose}
            isOpened={isOpened}
          />
        </Box>
      </Drawer>
    </>
  );
};

export default MobileUserPanel;

MobileUserPanel.propTypes = {
  toggleMenu: PropTypes.func.isRequired,
};

MobileUserPanelBlock.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
