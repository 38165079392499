import React from 'react';
import PropTypes from 'prop-types';
import 'components/views/Homepage/KpiHistoryDataBlock/UtilityKpiItem/style.scss';
import { Grid } from '@mui/material';
import Metric from 'components/common/Metric/Metric';

const UtilityKpiItem = ({
  categoryTitle, graphData, options, onClickChartHandler, isMostRecent,
}) => (
  <div className="utility-kpi-container">
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div className="utility-kpi-category">
          {categoryTitle}
        </div>
      </Grid>
      {options.map((option) => (
        <Grid item xl={3} lg={4} sm={6} xs={12} key={option.key}>
          <Metric
            guide={option.guide}
            option={option}
            graphData={graphData}
            key={option.key}
            onClickChartHandler={onClickChartHandler}
            isMostRecent={isMostRecent}
          />
        </Grid>
      ))}
    </Grid>
  </div>
);

UtilityKpiItem.propTypes = {
  graphData: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.any,
    ]),
  ),
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.any,
    ]),
  ),
  categoryTitle: PropTypes.string,
  onClickChartHandler: PropTypes.func,
  isMostRecent: PropTypes.bool,
};

UtilityKpiItem.defaultProps = {
  graphData: undefined,
  options: undefined,
  categoryTitle: undefined,
  onClickChartHandler: undefined,
  isMostRecent: false,
};

export default UtilityKpiItem;
