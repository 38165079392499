import React from 'react';
import propTypes from 'prop-types';
import './styles.scss';
import { MESSAGES } from 'constants/messages';
import Block from 'components/common/Block';
import { GUIDES } from 'constants/guides';
import Table from 'components/common/Table';
import { ImageCell, NameCell, PercentageCell } from 'components/common/CommonTableCells';
import { getProducts } from 'api/productsAPI';
import useRouter from 'hooks/useRouter';
import { ROUTES } from 'constants/routes';
import ProductPopperMetrics, {
  ProductPopperMetricsStateHandler,
} from 'components/common/MetricList/PopperMetrics/ProductPopperMetrics';

const getTableConfig = (hasImages = false) => [
  ...(hasImages ? [{
    title: hasImages ? MESSAGES.product : null,
    key: 'img',
    ordering: null,
    CellComponent: ImageCell,
    componentProps: {},
    width: '60px',
    hasOverflowEllipsis: true,
  }] : []), {
    title: hasImages ? null : MESSAGES.product,
    key: 'name',
    ordering: null,
    CellComponent: NameCell,
    componentProps: {},
    width: '500px',
    hasOverflowEllipsis: true,
  }, {
    title: MESSAGES.sku,
    key: 'sku',
    ordering: null,
    CellComponent: NameCell,
    componentProps: { },
    width: '250px',
    hasOverflowEllipsis: false,
  }, {
    title: MESSAGES.sold_together_rate,
    key: 'ratio_co_ocurrence',
    ordering: null,
    CellComponent: PercentageCell,
    componentProps: { },
    width: '150px',
    hasOverflowEllipsis: false,
  },
];

const SoldTogetherItem = ({ data }) => {
  const hasImages = data?.length && !!data.filter((row) => !!row.img).length;
  const { push } = useRouter();

  const handleRowClick = (row) => {
    const { sku } = row;
    if (!sku) return;
    getProducts({ search: sku }).then((res) => {
      if (!res.count) return;
      const { id } = res.results[0];
      push(ROUTES.product.replace(':id', id));
    });
  };

  const {
    handleOnHover,
    anchorEl,
    item,
    itemData,
    isLoading,
    isOpen,
  } = ProductPopperMetricsStateHandler();
  const formattedItem = item ? { title: item.name } : undefined;

  return (
    <>
      <Block
        postHeader={MESSAGES.most_sold_together}
        guide={GUIDES.SP11}
        className="sold-together-table-wrapper"
      >
        {data && data?.length ? (
          <Table
            data={data}
            sortChanged={() => {}}
            initSort={['ratio_co_ocurrence']}
            tableConfig={getTableConfig(hasImages)}
            highlightStartIdx={hasImages ? 1 : 0}
            highlightEndIdx={hasImages ? 3 : 2}
            handleRowClick={handleRowClick}
            onHover={handleOnHover}
          />
        ) : (
          <div className="empty-state">
            <span className="no-data">
              {MESSAGES.no_data_available}
            </span>
          </div>
        )}
      </Block>
      <ProductPopperMetrics
        isLoading={isLoading}
        isOpen={isOpen}
        item={formattedItem}
        itemData={itemData}
        anchorEl={anchorEl}
      />
    </>
  );
};

SoldTogetherItem.propTypes = {
  data: propTypes.arrayOf(propTypes.oneOfType([propTypes.any])),
};

SoldTogetherItem.defaultProps = {
  data: null,
};

export default SoldTogetherItem;
