import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import { BUTTON_TYPE, REQUEST_STATUS } from 'constants/common';
import { ReactComponent as WarningIcon } from 'assets/svg/warning.svg';
import propTypes from 'prop-types';
import 'components/views/Integrations/DeleteIntegrationModal/styles.scss';
import Button from 'components/common/Button';
import { deleteActiveIntegration } from 'api/integrationsAPI';
import useAsync from 'hooks/useAsync';
import NeedHelpLink from 'components/views/Integrations/NeedHelpLink';
import { MESSAGES } from 'constants/messages';
import { Stack } from '@mui/material';

const DeleteIntegrationModal = ({
  open, integration, onCancel, onConfirm,
}) => {
  const [error, setError] = useState('');
  const deleteIntegration = async () => {
    try {
      await deleteActiveIntegration({
        integrationUUID: integration.source ? integration.source.uuid : integration.uuid,
      });
      onConfirm();
    } catch (e) {
      setError(e.non_field_errors);
    }
  };
  const {
    status: deletionStatus,
    execute: deleteIntegrationAsync,
  } = useAsync(deleteIntegration);
  useEffect(() => {
    setError('');
  }, [open]);
  return (
    <Popup
      modal
      open={open}
      position="center center"
      closeOnDocumentClick={false}
      closeOnEscape
    >
      {() => (
        <div className="confirmation-window delete-integration-window">
          <NeedHelpLink />
          <WarningIcon className="warning-icon" />
          <div className="delete-integration-window__content-container">
            <strong>
              {`Remove ${integration.name} connection?`}
            </strong>
            <div>{MESSAGES.all_data_will_be_lost}</div>
            <ul className="delete-integration-window__warnings-list">
              <li>{MESSAGES.all_related_customer_data}</li>
              <li>{MESSAGES.all_analytics}</li>
              <li>{MESSAGES.all_integration_settings}</li>
              <li>{MESSAGES.all_historical_data}</li>
            </ul>
          </div>
          {!!error && (
            <div className="delete-integration-window__errors-container">
              {Array.isArray(error) ? error.map((err, key) => <div key={key}>{err}</div>)
                : <div>{error}</div>}
            </div>
          )}
          <Stack spacing={1} direction="row" className="confirmation-window-button-group">
            <Button
              block
              isPreventSubmit
              onClick={onCancel}
              isDisabled={deletionStatus === REQUEST_STATUS.loading}
              type={BUTTON_TYPE.secondary}
            >
              {MESSAGES.cancel}
            </Button>
            <Button
              danger
              block
              isPreventSubmit
              type={BUTTON_TYPE.primary}
              isLoading={deletionStatus === REQUEST_STATUS.loading}
              onClick={deleteIntegrationAsync}
            >
              {MESSAGES.remove}
            </Button>
          </Stack>
        </div>
      )}
    </Popup>
  );
};

DeleteIntegrationModal.propTypes = {
  open: propTypes.bool,
  integration: propTypes.object,
  onConfirm: propTypes.func,
  onCancel: propTypes.func,
};

DeleteIntegrationModal.defaultProps = {
  open: false,
  integration: {},
  onConfirm: undefined,
  onCancel: undefined,
};
export default DeleteIntegrationModal;
