import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import 'components/views/Products/SingleCategoryView/SingleCategoryHeader/styles.scss';
import Block from 'components/common/Block';
import { NumberFormatted, NumberFormattedCurrency } from 'helper/NumberFormatted';
import { MESSAGES } from 'constants/messages';
import { GUIDES } from 'constants/guides';
import Divider from 'components/common/Divider';
import { LinkButton } from 'components/common/LinkButton';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import Popup from 'reactjs-popup';
import ProductTable from 'components/views/Products/ProductList/ProductTable';
import useListApi from 'hooks/useListApi';
import { LIST_API_REDUX_KEYS } from 'constants/listApi';
import { getProducts } from 'api/productsAPI';
import { ENTITY_CATEGORY } from 'constants/entities';
import IconButton from 'components/common/IconButton';

const SingleCategoryHeader = ({ data }) => {
  const {
    id,
    name,
    cost_min,
    cost_max,
    price_min,
    price_max,
    profit_margin_min,
    profit_margin_max,
    unique_products,
    currency,
  } = data;

  const [isProductsOpen, setIsProductsOpen] = useState(false);
  const defaultConfig = { ordering: ['name'], search: '' };
  const listApi = useListApi(
    LIST_API_REDUX_KEYS.categoryProducts,
    defaultConfig,
    getProducts,
    true,
    true,
  );
  const { changeConfig } = listApi;

  useEffect(() => {
    if (!id) return;
    const categoryTag = { entityKey: ENTITY_CATEGORY, uuid: id };
    changeConfig({ tags: [categoryTag] });
  }, [data]);

  const emptyState = () => (
    <div className="empty-state">
      <span className="no-data">
        {MESSAGES.not_available}
      </span>
    </div>
  );

  const handleProductsModalClose = () =>
    setIsProductsOpen(false);

  const handleProductsModalOpen = () =>
    setIsProductsOpen(true);

  return (
    <>
      <Stack className="single-category">
        <Block
          postHeader={MESSAGES.category_insights}
          type="large-header"
          className="single-category__header"
        >
          <div className="name">
            {name}
          </div>
        </Block>
        <Block
          postHeader={MESSAGES.details}
          className="single-category__details"
          guide={GUIDES.SC1}
        >
          <div className="details-kpi">
            <div>
              {MESSAGES.cost_price_range}
            </div>
            <div className="details-kpi-value">
              {cost_min && cost_max && currency
                ? `${NumberFormattedCurrency(cost_min)} ${currency} - ${NumberFormattedCurrency(cost_max)} ${currency}`
                : cost_min && cost_max
                  ? `${NumberFormattedCurrency(cost_min)} - ${NumberFormattedCurrency(cost_min)}`
                  : emptyState()}
            </div>
          </div>
          <Divider />
          <div className="details-kpi">
            <div>
              {MESSAGES.price_range}
            </div>
            <div className="details-kpi-value">
              {price_min && price_max && currency
                ? `${NumberFormattedCurrency(price_min)} ${currency} - ${NumberFormattedCurrency(price_max)} ${currency}`
                : price_min && price_max
                  ? `${NumberFormattedCurrency(price_min)} - ${NumberFormattedCurrency(price_max)}`
                  : emptyState()}
            </div>
          </div>
          <Divider />
          <div className="details-kpi">
            <div>
              {MESSAGES.profit_margin_range}
            </div>
            <div className="details-kpi-value">
              {profit_margin_min && profit_margin_max && currency
                ? `${NumberFormattedCurrency(profit_margin_min)} ${currency} - ${NumberFormattedCurrency(profit_margin_max)} ${currency}`
                : profit_margin_min && profit_margin_max
                  ? `${NumberFormattedCurrency(profit_margin_min)} - ${NumberFormattedCurrency(profit_margin_max)}`
                  : emptyState()}
            </div>
          </div>
          <Divider />
          <div className="details-kpi">
            <Stack
              direction="row"
              spacing={1}
              justifyContent="space-between"
            >
              <div>
                {MESSAGES.unique_products}
              </div>
              <LinkButton
                type="secondary"
                size="small"
                onClick={handleProductsModalOpen}
              >
                {MESSAGES.view_all}
              </LinkButton>
            </Stack>
            <div className="details-kpi-value">
              {unique_products ? NumberFormatted(unique_products) : emptyState()}
            </div>
          </div>
        </Block>
      </Stack>
      <Popup
        modal
        open={isProductsOpen}
        position="center center"
        onClose={handleProductsModalClose}
        closeOnDocumentClick={false}
      >
        <div className="category-products-table">
          <Block
            header={MESSAGES.products}
            postHeader={MESSAGES.yearly_performance}
            headerContent={<IconButton icon={CloseIcon} size="small" type="secondary" onClick={handleProductsModalClose} />}
          >
            <ProductTable
              listApi={listApi}
              expandableVariants={false}
            />
          </Block>
        </div>
      </Popup>
    </>
  );
};
SingleCategoryHeader.propTypes = {
  data: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string, PropTypes.number, PropTypes.any])),
};

SingleCategoryHeader.defaultProps = {
  data: undefined,
};

export default SingleCategoryHeader;
