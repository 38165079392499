import { useCallback, useMemo, useState } from 'react';
import { debounce } from 'utils/common';

export default (suggestionsRequest) => {
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const suggestionsRequestAsync = useCallback(async (...args) => {
    const suggestionsResult = await suggestionsRequest(...args);
    if (suggestionsResult) {
      setSuggestions(suggestionsResult);
    }
  }, [suggestionsRequest]);

  const lazySuggestionsRequest = useMemo(
    () => debounce(suggestionsRequestAsync, 500),
    [suggestionsRequestAsync],
  );

  const onClearInputRequested = useCallback(() => setInputValue(''), []);
  const onSuggestionsClearRequested = useCallback(() => setSuggestions([]), []);
  const onSuggestionsFetchRequested = useCallback(({ value }) => {
    lazySuggestionsRequest(value)
  }, []);
  const onChange = (event, { newValue }) => setInputValue(newValue);
  const cleanUpValue = ( ) => setInputValue("");

  return {
    suggestions,
    value: inputValue,
    onChange,
    cleanUpValue,
    onClearInputRequested,
    onSuggestionsFetchRequested,
    onSuggestionsClearRequested,
  };
};
