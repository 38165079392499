import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';

import 'components/common/Avatar/styles.scss';
import { ReactComponent as DefaultAvatarIcon } from 'assets/svg/defaultAvatar.svg';
import { AVATAR_TYPE } from 'constants/common';

const Avatar = ({ type, hasBorder }) => (
  <div className={classNames('avatar', {
    'avatar--small': type === AVATAR_TYPE.small,
    'avatar--middle': type === AVATAR_TYPE.middle,
    'avatar--large': type === AVATAR_TYPE.large,
    'avatar--with-border': hasBorder,
  })}
  >
    <DefaultAvatarIcon className="avatar__default-icon" />
  </div>
);

Avatar.propTypes = {
  type: propTypes.string,
  hasBorder: propTypes.bool,
};

Avatar.defaultProps = {
  type: AVATAR_TYPE.small,
  hasBorder: false,
};

export default Avatar;
