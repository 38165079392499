import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import { NumberFormattedCurrency } from 'helper/NumberFormatted';
import { get } from 'lodash';

export const IdCell = ({ column, row }) => (
  <span className="customer__cell__id">
    {row[column.key]}
  </span>
);

IdCell.propTypes = {
  column: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  row: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

export const NameCell = ({ row }) => (
  <span className="customer__cell__name ph-no-capture">
    {row.first_name}
    {' '}
    {row.last_name}
  </span>
);

NameCell.propTypes = IdCell.propTypes;

export const SegmentCell = ({ column, row, segmentsMap }) => {
  const id = row[column.key];
  const segmentItem = get(segmentsMap, id);

  return segmentItem ? (
    <div className="customer__cell__segment">
      <div
        className="customer__cell__segment__color"
        style={segmentItem.color_1 ? { backgroundColor: segmentItem.color_1 } : {}}
      />
      <span className="text-ellipsis">
        {segmentItem.segment_name}
      </span>
    </div>
  ) : null;
};

SegmentCell.propTypes = IdCell.propTypes;

export const TotalSalesCell = ({ column, row }) => (
  <span>
    {row.currency}
    {NumberFormattedCurrency(row[column.key])}
  </span>
);

TotalSalesCell.propTypes = IdCell.propTypes;
