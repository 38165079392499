import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CustomerProfile from 'components/views/Customer/CustomerProfile';
import 'components/views/Customer/style.scss';
import TopFirstBlock from 'components/views/Customer/TopFirstBlock';
import PersonalInformation from 'components/views/Customer/PersonalInformation';
import RecentActivity from 'components/views/Customer/RecentActivity';
import { http } from 'utils';
import useRouter from 'hooks/useRouter';
import { isEmpty } from 'lodash';
import RecentlyPurchasedProducts from 'components/views/Customer/RecentlyPurchasedProducts';
import { Grid, Stack } from '@mui/material';
import { setCustomerName } from 'store/crumbsIdToName/crumbsIdToNameSlice';

const Customer = () => {
  const [data, setData] = useState({});
  const { location } = useRouter();
  const [activity, setActivity] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    http({
      url: `v1/customers/${location.pathname.split('/').reverse()[0]}/`,
    })
      .then((resp) => {
        setData(resp);
        dispatch(setCustomerName({ [resp.id]: `${resp.first_name} ${resp.last_name}` }));
      });
  }, []);
  useEffect(() => {
    if (!!data.email && !!data.email.length) {
      http({
        url: `v1/customers/activity/?email=${data.email}&page_size=8&page=1`,
      })
        .then((resp) => {
          setActivity(resp.results);
        });
    }
  }, [data]);

  return (
    <Grid container spacing={2}>
      <Grid item lg={4} xs={12}>
        <Stack spacing={2}>
          <CustomerProfile data={data} />
          <PersonalInformation data={data} />
        </Stack>
      </Grid>
      <Grid item lg={8} xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TopFirstBlock data={data} />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xl={6} xs={12}>
                <RecentActivity data={activity} />
              </Grid>
              <Grid item xl={6} xs={12}>
                {!isEmpty(data.products) && (
                  <RecentlyPurchasedProducts products={data.products} />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Customer;
