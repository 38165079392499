import { useEffect, useState } from 'react';
import useAsync from 'hooks/useAsync';
import { REQUEST_STATUS } from 'constants/common';
import { useDispatch, useSelector } from 'react-redux';
import { setParamsAction } from 'store/listApi/listApiSlice';
import { getListApiParams } from 'store/listApi/getters';
import { concat, isEqual } from 'lodash';

const containsSingleKey = (obj, key) => Object.keys(obj).length === 1 && obj[key];

const getInitConfig = (defaultConfig) => ({
  page: 1,
  search: '',
  tags: [],
  ...defaultConfig,
});

export default (reduxKey, defaultConfig, fetchList, overrideStateConfig, awaitFirstChange) => {
  const initConfig = getInitConfig(defaultConfig);

  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isFetchingNext, setIsFetchingNext] = useState(false);
  const {
    value: fetchResult,
    execute: executeFetch,
    status: fetchStatus,
  } = useAsync(fetchList);

  const dispatch = useDispatch();
  const config = useSelector((state) => getListApiParams(state, reduxKey));
  const setConfig = (newConfig) => dispatch(setParamsAction({ key: reduxKey, params: newConfig }));
  const changeConfig = (changes, shouldFetch = true) => {
    const newConfig = {
      ...config,
      ...changes,
    };

    if ((data.length || isDataLoading) && isEqual(newConfig, config)) return;

    if (containsSingleKey(changes, 'search') || containsSingleKey(changes, 'tags')) {
      newConfig.page = 1;
    }

    setConfig(newConfig);
    if (shouldFetch) executeFetch(newConfig);
  };

  useEffect(() => {
    if (overrideStateConfig) {
      changeConfig({
        ...initConfig,
      }, !awaitFirstChange);
      return;
    }

    changeConfig({
      ...initConfig,
      ...config,
    }, !awaitFirstChange);
  }, []);

  useEffect(() => {
    setIsDataLoading(fetchStatus === REQUEST_STATUS.loading);
  }, [fetchStatus]);

  useEffect(() => {
    if (fetchResult) {
      setTotalCount(fetchResult.count);

      if (isFetchingNext) {
        setData(concat(data, fetchResult.results));
        setIsFetchingNext(false);
        return;
      }
      setData(fetchResult.results);
    }
  }, [fetchResult]);

  const fetchNext = () => {
    setIsFetchingNext(true);
    changeConfig({ page: config.page + 1 });
  };

  const reloadData = () =>
    executeFetch(config);

  return {
    data,
    setData,
    totalCount,
    isDataLoading,
    config: config || initConfig,
    changeConfig,
    fetchNext,
    isFetchingNext,
    reloadData,
  };
};
