import PropTypes from 'prop-types';
import { noop } from 'lodash';

export const selectPropTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.string.isRequired,
  }),
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })),
  error: PropTypes.objectOf(PropTypes.string),
  touched: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isClearable: PropTypes.bool,
  hasFeedback: PropTypes.bool,
  defaultValue: PropTypes.objectOf(PropTypes.string),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  isRequired: PropTypes.bool,
  hasFloatLabel: PropTypes.bool,
  isFixedLabel: PropTypes.bool,
  isSearchable: PropTypes.bool,
  openMenuOnClick: PropTypes.bool,
};

export const selectDefaultProps = {
  options: [],
  value: undefined,
  error: undefined,
  touched: false,
  isDisabled: false,
  isLoading: false,
  isClearable: false,
  isRequired: false,
  hasFeedback: false,
  hasFloatLabel: true,
  defaultValue: undefined,
  onChange: noop,
  onBlur: noop,
  isFixedLabel: false,
  isSearchable: true,
  openMenuOnClick: true,
};
