import React, { useEffect, useState } from 'react';
import { BUTTON_TYPE } from 'constants/common';
import 'components/views/CustomAudience/AudienceChannels/styles.scss';
import { MESSAGES } from 'constants/messages';
import PropTypes from 'prop-types';
import Button from 'components/common/Button';
import { ReactComponent as ActivateChannelIcon } from 'assets/svg/activate_audience.svg';
import SearchEntity from 'components/common/SearchEntity';
import { ENTITY_CHANNEL } from 'constants/entities';
import { SEARCH_POPOVER_POSITION } from 'components/common/SearchEntity/constants';
import { getAssetSourceImage } from 'utils/assets';
import EntitySelectItem from 'components/common/EntitySelectItem';
import { Stack } from '@mui/material';
import { noop } from 'lodash';

const searchEntityConfig = [{
  key: ENTITY_CHANNEL,
  title: MESSAGES.channels,
  hasPages: false,
}];

const ToggleSearchEntity = ({ isOpened, onClick }) => (
  <div
    className="audience-channels__add"
    onClick={onClick}
  >
    <Button
      fillWidth
      type={BUTTON_TYPE.secondary}
      isActive={isOpened}
    >
      {`+ ${MESSAGES.add_channels}`}
    </Button>
  </div>
);

ToggleSearchEntity.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
};

ToggleSearchEntity.defaultProps = {
  onClick: noop,
};

const AudienceChannels = ({ channelsMap, integrations, integrationsChanged }) => {
  const [channels, setChannels] = useState([]);
  const [searchTags, setSearchTags] = useState([]);

  useEffect(() => {
    if (channelsMap && integrations) {
      setChannels(integrations.map((uuid) => channelsMap[uuid].source));
      setSearchTags(integrations.map((uuid) => ({
        uuid,
        entityKey: ENTITY_CHANNEL,
        name: channelsMap[uuid].source.name,
      })));
    }
  }, [channelsMap, integrations]);

  const handleRemove = (idx) => {
    const newIntegrations = [...integrations.slice(0, idx), ...integrations.slice(idx + 1)];
    integrationsChanged(newIntegrations);
  };

  const onTagsChange = (tags) => {
    const newIntegrations = tags.map(({ uuid }) => uuid);
    integrationsChanged(newIntegrations);
  };

  return (
    <div className="audience-channels">
      {!!integrations.length && (
        <Stack spacing={2}>
          {channels.map((channel, idx) => (
            <div key={channel.key}>
              <EntitySelectItem
                entityImage={getAssetSourceImage(channel.key, true)}
                onRemove={() => handleRemove(idx)}
              >
                {channel.name}
              </EntitySelectItem>
            </div>
          ))}
        </Stack>
      )}
      {!integrations.length && (
        <div className="audience-channels__empty">
          <ActivateChannelIcon className="audience-channels__empty__icon" />
          <div className="audience-channels__empty__text">{MESSAGES.no_channels_selected_yet}</div>
        </div>
      )}
      <SearchEntity
        selectedItems={searchTags}
        onItemSelect={(item) => onTagsChange([...searchTags, item])}
        config={searchEntityConfig}
        ToggleComponent={ToggleSearchEntity}
        position={SEARCH_POPOVER_POSITION.top}
        isInContainer
      />
    </div>
  );
};
export default AudienceChannels;

AudienceChannels.propTypes = {
  channelsMap: PropTypes.objectOf(PropTypes.oneOfType(
    [PropTypes.object],
  )),
  integrations: PropTypes.arrayOf(PropTypes.string).isRequired,
  integrationsChanged: PropTypes.func.isRequired,
};

AudienceChannels.defaultProps = {
  channelsMap: null,
};
