import React from 'react';
import 'components/views/Customer/CustomerProfile/style.scss';
import Item from 'components/views/Customer/CustomerProfile/item';
import InfoForm from 'components/views/Customer/CustomerProfile/InfoForm';
import propTypes from 'prop-types';
import { MESSAGES } from 'constants/messages';
import Block from 'components/common/Block';
import { GUIDES } from 'constants/guides';

const CustomerProfile = ({ data }) => (
  <div className="customer-profile">
    <Block
      postHeader={MESSAGES.customer_profile}
      className="user-info"
      guide={GUIDES.CSV1}
      type="large-header"
    >
      <div className="user-info__name">
        {`${data.first_name} ${data.last_name}`}
      </div>
      {!!data.age && (
        <div className="user-info__age">
          {MESSAGES.age}
          {' '}
          <span className="age-category">
            {data.age}
          </span>
        </div>
      )}
    </Block>
    <Block className="customer-profile-kpis">
      <div className="user-product-info">
        <div className="user-product-info__wrapper">
          <Item
            title1={MESSAGES.total}
            title2={MESSAGES.orders}
            value={data.total_orders}
          />
          <div className="top-block-separator" />
          <Item
            title1={MESSAGES.total}
            title2={MESSAGES.items}
            value={data.total_products}
          />
          <div className="top-block-separator" />
          <Item
            title1={MESSAGES.last}
            title2={MESSAGES.order}
            value={data.days_since_last_order}
            postfix={MESSAGES.days_ago}
          />
        </div>
      </div>
      <div className="extra-info">
        <InfoForm data={data} />
      </div>
    </Block>
  </div>
);

CustomerProfile.propTypes = {
  data: propTypes.objectOf(propTypes.oneOfType([
    propTypes.string,
    propTypes.number,
    propTypes.object,
    propTypes.bool,
  ])).isRequired,
};

export default CustomerProfile;
