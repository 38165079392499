import React from 'react';
import propTypes from 'prop-types';
import Popup from 'reactjs-popup';

import 'components/views/Admin/Licenses/ReactivateLicenseModal/styles.scss';
import Button from 'components/common/Button';
import { REQUEST_STATUS, BUTTON_TYPE } from 'constants/common';
import useAsync from 'hooks/useAsync';
import { reactivateLicense } from 'api/licenseAPI';
import { MESSAGES } from 'constants/messages';

const ReactivateLicenseModal = ({ license, onClose, onSubmit }) => {
  const reactivateLicenseHandler = async () => {
    await reactivateLicense(license.uuid);
    onSubmit();
  };

  const { execute, status } = useAsync(reactivateLicenseHandler);

  return (
    <Popup
      modal
      open={!!license.uuid}
      position="center center"
      onClose={onClose}
    >
      {() => (
        <div className="confirmation-window reactivate-license">
          <span className="reactivate-license__title">{MESSAGES.reactivate_this_user}</span>
          <div className="reactivate-license__form__actions form__actions">
            <div className="reactivate-license__form__actions__cancel form__cancel">
              <Button
                block
                isPreventSubmit
                onClick={onClose}
                type={BUTTON_TYPE.secondary}
              >
                {MESSAGES.cancel}
              </Button>
            </div>
            <div className="reactivate-license__form__actions__submit form__submit">
              <Button
                type={BUTTON_TYPE.primary}
                isLoading={REQUEST_STATUS.loading === status}
                onClick={execute}
              >
                {MESSAGES.reactivate}
              </Button>
            </div>
          </div>
        </div>
      )}
    </Popup>
  );
};

export default ReactivateLicenseModal;

ReactivateLicenseModal.propTypes = {
  license: propTypes.objectOf(propTypes.oneOfType([
    propTypes.string,
    propTypes.array,
    propTypes.object,
  ])).isRequired,
  onClose: propTypes.func.isRequired,
  onSubmit: propTypes.func.isRequired,
};
