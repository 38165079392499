import React, { useEffect, useState } from 'react';
import Prompt from 'react-router-dom/Prompt';
import propTypes from 'prop-types';
import Popup from 'reactjs-popup';

import Button from 'components/common/Button';
import './styles.scss';
import { BUTTON_TYPE, REQUEST_STATUS } from 'constants/common';
import { MESSAGES } from 'constants/messages';

const defaultActions = (close) => close();

const ConfirmationWindow = ({
  content,
  customOpenButton,
  open,
  onCancel,
  onConfirm,
  confirmText,
  danger,
  warning,
  inWorkArea,
  secondaryGhost,
  requestStatus,
  forceBlocking,
}) => {
  const [isBlocking, setIsBlocking] = useState(false);

  const onCancelHandler = (...args) => {
    setIsBlocking(false);
    onCancel(...args);
  };

  const onConfirmHandler = (...args) => {
    setIsBlocking(false);
    onConfirm(...args);
  };

  useEffect(() => {
    const close = (e) => {
      if (open && e.keyCode === 27) {
        onCancelHandler();
      }

      setIsBlocking(false);
    };

    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, [open]);

  return (
    <>
      <Prompt
        when={isBlocking}
        message={(location) => `Are you sure you want to go to ${location.pathname}`}
      />
      <Popup
        modal
        open={open}
        trigger={customOpenButton}
        position="center center"
        closeOnDocumentClick={false}
        onOpen={() => setIsBlocking(forceBlocking)}
      >
        {(close) => (
          <div className="confirmation-window">
            <div
              className="confirmation-window__content"
              dangerouslySetInnerHTML={{ __html: content }}
            />
            <div className="confirmation-window__actions">
              <div className="confirmation-window__action-button confirmation-window__action-button--cancel">
                <Button
                  onClick={() => onCancelHandler(close)}
                  block
                  isDisabled={requestStatus === REQUEST_STATUS.loading}
                  type={BUTTON_TYPE.secondary}
                >
                  {MESSAGES.cancel}
                </Button>
              </div>
              <div className="confirmation-window__action-button confirmation-window__action-button--accept">
                <Button
                  danger={danger}
                  warning={warning}
                  onClick={() => onConfirmHandler(close)}
                  block
                  isLoading={requestStatus === REQUEST_STATUS.loading}
                  type={BUTTON_TYPE.primary}
                >
                  {confirmText || MESSAGES.ok}
                </Button>
              </div>
            </div>
          </div>
        )}
      </Popup>
    </>
  );
};

ConfirmationWindow.propTypes = {
  content: propTypes.string.isRequired,
  customOpenButton: propTypes.func,
  open: propTypes.bool,
  onCancel: propTypes.func,
  onConfirm: propTypes.func,
  confirmText: propTypes.string,
  danger: propTypes.bool,
  inWorkArea: propTypes.bool,
  warning: propTypes.bool,
  secondaryGhost: propTypes.bool,
  forceBlocking: propTypes.bool,
  requestStatus: propTypes.string,
};

ConfirmationWindow.defaultProps = {
  confirmText: undefined,
  danger: false,
  inWorkArea: true,
  onCancel: defaultActions,
  onConfirm: defaultActions,
  customOpenButton: undefined,
  open: false,
  warning: false,
  secondaryGhost: false,
  requestStatus: undefined,
  forceBlocking: true,
};

export default ConfirmationWindow;
