import React from 'react';
import 'components/views/Homepage/EmptyStates/EmptyTemplate/style.scss';
import useRouter from 'hooks/useRouter';
import { ROUTES } from 'constants/routes';
import { useSelector } from 'react-redux';
import { getIsLicenseAdmin } from 'store/license/getters';
import { MESSAGES } from 'constants/messages';
import Button from 'components/common/Button';

const EmptyTemplate = (props) => {
  const { push } = useRouter();
  const clickHandler = () => {
    push(ROUTES.integrations);
  };
  const isLicenseAdmin = useSelector(getIsLicenseAdmin);
  return (
    <div className="dashboard-empty-state">
      <div className="empty-state-wrapper">
        <div className="description">
          {!!props.title && (
          <div className="title">
            {props.title}
          </div>
          )}
          {!!props.children && (
          <div className="subtitle">
            {props.children}
          </div>
          )}
        </div>
        {isLicenseAdmin && (
          <Button onClick={clickHandler}>
            {MESSAGES.go_to_integrations}
          </Button>
        )}
      </div>
    </div>
  );
};

export default EmptyTemplate;
