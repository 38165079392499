import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom';

import './styles.scss';
import { ROUTES } from 'constants/routes';
import SignUp from './SignUp';
import SignIn from './SignIn';
import Details from './Details';
import RestorePassword from './RestorePassword';
import PrivateRoute from 'components/common/ProtectedRoutes/PrivateRoute';

const Authorization = () => (
  <div className="authorization">
    <Switch>
      <Route path={ROUTES.signIn} component={SignIn} />
      <Route path={ROUTES.signUp} component={SignUp} />
      <Route path={ROUTES.restorePassword} component={RestorePassword} />
      <PrivateRoute path={ROUTES.details} component={Details} exact />
    </Switch>
  </div>
);

export default Authorization;
