import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import Drawer from '@mui/material/Drawer';
import MinSideBarMenuList from 'containers/PrivateApp/Portal/CombinedSideBarMenu/MinSideBarMenuList';
import LogoSmall from 'components/common/LogoSmall';
import { ReactComponent as CustimyLogoSmall } from 'assets/svg/custimyLogo.svg';
import SideBarMenuList from 'containers/PrivateApp/Portal/CombinedSideBarMenu/SideBarMenuList';
import MobileSideBarMenuList from 'containers/PrivateApp/Portal/CombinedSideBarMenu/MobileSideBarMenuList';
import { VIEWTYPE, SIDEBARTYPE } from 'constants/common';

const MobileSideBarElement = ({
  item, viewType, toggleMenu, getCollapseStatus,
}) => (
  <MobileSideBarMenuList
    hrefTo={item.hrefTo}
    config={item.config}
    viewType={viewType}
    toggleMenu={toggleMenu}
    getCollapseStatus={getCollapseStatus}
  >
    <item.IconComponent className="mobile-menu-nav__icon" />
    {item.name}
  </MobileSideBarMenuList>
);

const MinSideBarElement = ({ item }) => (
  <MinSideBarMenuList
    hrefTo={item.hrefTo}
    config={item.config}
    title={item.name}
  >
    <item.IconComponent className="min-menu__nav__icon" />
  </MinSideBarMenuList>
);

const FullSideBarElement = ({
  item, viewType, toggleMenu, onCloseSlideMenu,
}) => (
  <SideBarMenuList
    hrefTo={item.hrefTo}
    config={item.config}
    viewType={viewType}
    toggleMenu={toggleMenu}
    onCloseSlideMenu={onCloseSlideMenu}
  >
    <item.IconComponent className="full-menu-nav__icon" />
    {item.name}
  </SideBarMenuList>
);

const MobileSideBarView = ({
  mainItems, additionalItems, viewType, toggleMenu,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(undefined);

  return (
    <aside className="mobile-menu">
      <ul className="mobile-menu__nav">
        {mainItems.map((item) => (
          <MobileSideBarElement
            key={item.name}
            item={item}
            viewType={viewType}
            toggleMenu={toggleMenu}
            getCollapseStatus={setIsCollapsed}
          />
        ))}
      </ul>
      <ul className="mobile-menu__nav mobile-menu__nav-additional">
        {additionalItems.map((item) => (
          <MobileSideBarElement
            key={item.name}
            item={item}
            viewType={viewType}
            toggleMenu={toggleMenu}
            getCollapseStatus={setIsCollapsed}
          />
        ))}
      </ul>
      <div
        className="mobile-menu__logo"
        style={{ margin: isCollapsed ? 'auto 0 90px' : 'auto 0 50px' }}
      >
        <LogoSmall className="mobile-menu-logo" />
      </div>
    </aside>
  );
};

const MinSideBarView = ({ mainItems, additionalItems }) => (
  <aside className="min-menu">
    <ul className="min-menu__nav">
      {mainItems.map((item) => (
        <MinSideBarElement key={item.name} item={item} />
      ))}
    </ul>
    <ul className="min-menu__nav min-menu__nav-additional">
      {additionalItems.map((item) => (
        <MinSideBarElement key={item.name} item={item} />
      ))}
    </ul>
    <div className="min-menu__logo">
      <CustimyLogoSmall
        className="min-menu-logo"
        width="30"
        height="30"
      />
    </div>
  </aside>
);

const FullSideBarView = ({
  mainItems, additionalItems, viewType, toggleMenu, onCloseSlideMenu,
}) => (
  <aside className="full-menu">
    <ul className="full-menu__nav">
      {mainItems.map((item) => (
        <FullSideBarElement
          key={item.name}
          item={item}
          viewType={viewType}
          toggleMenu={toggleMenu}
          onCloseSlideMenu={onCloseSlideMenu}
        />
      ))}
    </ul>
    <ul className="full-menu__nav-additional">
      {additionalItems.map((item) => (
        <FullSideBarElement
          key={item.name}
          item={item}
          viewType={viewType}
          toggleMenu={toggleMenu}
          onCloseSlideMenu={onCloseSlideMenu}
        />
      ))}
    </ul>
    <div className="full-menu__logo">
      <LogoSmall className="full-menu-logo" />
    </div>
  </aside>
);

const CombinedSideBarMenu = ({
  mainItems,
  additionalItems,
  defaultSideBar,
  viewType,
  toggleDrawer,
  toggleMenu,
  isMenuOpened,
  onCloseSlideMenu,
}) => (
  <>
    { viewType === VIEWTYPE.LARGE ? (
      defaultSideBar === SIDEBARTYPE.MIN ? (
        <MinSideBarView mainItems={mainItems} additionalItems={additionalItems} />
      ) : (
        <FullSideBarView
          mainItems={mainItems}
          additionalItems={additionalItems}
          viewType={viewType}
          toggleMenu={toggleMenu}
          onCloseSlideMenu={onCloseSlideMenu}
        />
      )
    ) : viewType === VIEWTYPE.TABLET
      ? (defaultSideBar === SIDEBARTYPE.MIN
        ? (
          <MinSideBarView mainItems={mainItems} additionalItems={additionalItems} />
        ) : (
          <Drawer
            anchor="left"
            open={defaultSideBar === SIDEBARTYPE.SLIDE && isMenuOpened}
            onClose={toggleDrawer(false)}
          >
            <FullSideBarView
              mainItems={mainItems}
              additionalItems={additionalItems}
              viewType={viewType}
              toggleMenu={toggleMenu}
              onCloseSlideMenu={onCloseSlideMenu}
            />
          </Drawer>
        )
      ) : (
        <MobileSideBarView
          mainItems={mainItems}
          additionalItems={additionalItems}
          viewType={viewType}
          toggleMenu={toggleMenu}
        />
      )}
  </>
);

MinSideBarElement.propTypes = {
  item: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

MobileSideBarElement.propTypes = {
  ...MinSideBarElement.propTypes,
  viewType: PropTypes.string.isRequired,
  toggleMenu: PropTypes.func,
  getCollapseStatus: PropTypes.func,
};

MobileSideBarElement.defaultProps = {
  toggleMenu: null,
  getCollapseStatus: null,
};

FullSideBarElement.propTypes = {
  ...MobileSideBarElement.propTypes,
  onCloseSlideMenu: PropTypes.func,
};

FullSideBarElement.defaultProps = {
  ...MobileSideBarElement.defaultProps,
  onCloseSlideMenu: null,
};

MinSideBarView.propTypes = {
  mainItems: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  additionalItems: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
};

MobileSideBarView.propTypes = {
  ...MinSideBarView.propTypes,
  viewType: PropTypes.string.isRequired,
  toggleMenu: PropTypes.func,
};

MobileSideBarView.defaultProps = {
  toggleMenu: null,
};

FullSideBarView.propTypes = {
  ...MobileSideBarView.propTypes,
  onCloseSlideMenu: PropTypes.func,
};

FullSideBarView.defaultProps = {
  ...MobileSideBarView.defaultProps,
  onCloseSlideMenu: null,
};

CombinedSideBarMenu.propTypes = {
  ...MinSideBarView.propTypes,
  isMenuOpened: PropTypes.bool.isRequired,
  onCloseSlideMenu: PropTypes.func,
  viewType: PropTypes.string.isRequired,
  toggleDrawer: PropTypes.func,
  toggleMenu: PropTypes.func,
  defaultSideBar: PropTypes.string,
};

CombinedSideBarMenu.defaultProps = {
  toggleDrawer: null,
  toggleMenu: null,
  onCloseSlideMenu: null,
  defaultSideBar: undefined,
};

export default CombinedSideBarMenu;
