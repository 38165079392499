import React from 'react';
import './styles.scss';
import { Stack } from '@mui/material';
import { ReactComponent as WarningIcon } from 'assets/svg/warning.svg';
import { ReactComponent as InfoIcon } from 'assets/svg/info-icon.svg';
import { ReactComponent as SuccessIcon } from 'assets/svg/check.svg';
import { ReactComponent as PreciseIcon } from 'assets/svg/bullseye.svg';

import PropTypes from 'prop-types';
import classNames from 'classnames';

const disclaimerIcons = {
  info: <InfoIcon />,
  success: <SuccessIcon />,
  warning: <WarningIcon />,
  danger: <WarningIcon />,
  precise: <PreciseIcon />,
};

const Disclaimer = ({ text, type }) => (
  <div className={classNames('disclaimer', type)}>
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      justifyContent="center"
    >
      <div className="disclaimer-icon">
        {disclaimerIcons[type]}
      </div>
      <div className="disclaimer-text">
        {text}
      </div>
    </Stack>
  </div>
);

Disclaimer.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['info', 'success', 'warning', 'danger', 'precise']),
};

Disclaimer.defaultProps = {
  type: 'info',
};

export default Disclaimer;
