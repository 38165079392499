import React from 'react';
import 'components/views/Settings/LicenseSettings/styles.scss';
import Block from 'components/common/Block';
import LicensePackage from 'components/views/Settings/LicenseSettings/LicensePackage';
import Licence from 'components/views/Settings/LicenseSettings/License';
import LicenseMembers from 'components/views/Settings/LicenseSettings/LicenseMembers';
import { MESSAGES } from 'constants/messages';
import { Grid } from '@mui/material';

const LicenseSettings = () => (
  <div className="license-settings">
    <Grid container spacing={2}>
      <Grid item xs={12} container spacing={1}>
        <Grid item xl={10} lg={9} xs={12}>
          <Block postHeader={MESSAGES.license} fillHeight>
            <Licence />
          </Block>
        </Grid>
        <Grid item xl={2} lg={3} xs={12}>
          <Block postHeader={MESSAGES.subscription} fillHeight>
            <LicensePackage />
          </Block>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Block postHeader={MESSAGES.users}>
          <LicenseMembers />
        </Block>
      </Grid>
    </Grid>
  </div>
);

export default LicenseSettings;
