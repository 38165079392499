import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';

import './styles.scss';
import AutosuggestHighlightMatch from 'autosuggest-highlight/match';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';

const Suggest = ({ suggestionText, query }) => {
  const matches = AutosuggestHighlightMatch(suggestionText.toLowerCase(), query.toLowerCase());
  const parts = AutosuggestHighlightParse(suggestionText, matches);

  return (
    <div className="autosuggest__suggest">
      <span className="autosuggest__suggest-text">
        {parts.map(({
          highlight,
          text,
        }) => <span className={classNames({ highlight })}>{text}</span>)}
      </span>
    </div>
  );
};

Suggest.propTypes = {
  suggestionText: propTypes.string.isRequired,
  query: propTypes.string.isRequired,
};

export default Suggest;
