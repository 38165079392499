import propTypes from 'prop-types';

export const datepickerPropTypes = {
  value: propTypes.instanceOf(Date),
  onChange: propTypes.func,
  includeDates: propTypes.array,
  label: propTypes.string,
  dateFormat: propTypes.string,
  isClearable: propTypes.bool,
};

export const datepickerDefaultProps = {
  value: undefined,
  onChange: undefined,
  includeDates: undefined,
  label: '',
  dateFormat: 'MM/dd/yyyy',
  isClearable: false,
};
