import React from 'react';
import 'components/views/Settings/Settings/BillingDetails/Billing/ProgressBar/styles.scss';
import propTypes from 'prop-types';

const ProgressBar = ({ background_color, progressBarPercentage }) => {
  const fillerStyles = {
    width: `${progressBarPercentage}%`,
    backgroundColor: background_color,
  };

  return (
    <div className="container_styles">
      <div className="filler_styles" style={fillerStyles}>
        <div className="label-styles">{`${progressBarPercentage}%`}</div>
      </div>
    </div>
  );
};

export default ProgressBar;

ProgressBar.propTypes = {
  background_color: propTypes.string.isRequired,
  progressBarPercentage: propTypes.number.isRequired,
};
