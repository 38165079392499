import {
  EXPERIMENT_GROUPS_ERROR_REQUIRED,
  NAME_ERROR_REQUIRED,
} from 'constants/errors';
import Validator from 'utils/validator';

export default ({ name, groups }) => {
  const errors = {};

  const nameValidator = new Validator(name);
  const groupsValidator = new Validator(groups);

  try {
    nameValidator.required(NAME_ERROR_REQUIRED);
  } catch (error) {
    errors.name = error.message;
  }

  try {
    groupsValidator.notEmpty(EXPERIMENT_GROUPS_ERROR_REQUIRED);
  } catch (error) {
    errors.groups = error.message;
  }

  return errors;
};
