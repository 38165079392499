import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ItemTemplate } from 'components/views/Segments/ItemTemplate';

export const ItemBlock = ({
  data, title, className, active, updateState, ...props
}) => (
  <div
    className={classNames('items-block', className, {
      'active-customer': active.activeCustomer,
      'active-order': active.activeOrderSize,
      'active-avg-profit': active.activeAvgProfit,
      'active-avg-order': active.activeAvgOrders,
    })}
    onMouseOver={() => updateState(true)}
    onMouseOut={() => updateState(false)}
  >

    <ItemTemplate percentage={props.percentage} title={title} prefix={props.prefix ? props.prefix : ''} data={data} />

  </div>
);

ItemBlock.propTypes = {
  title: PropTypes.string.isRequired,
  updateState: PropTypes.func.isRequired,
  active: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
    ]),
  ),
  className: PropTypes.string,
  data: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
};

ItemBlock.defaultProps = {
  active: undefined,
  className: undefined,
};
