import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  NameCell, SegmentCell, TotalSalesCell,
} from 'components/common/CustomersListTools/CustomerList/CustomerListCells';
import { useSelector } from 'react-redux';
import useRouter from 'hooks/useRouter';
import { getCustomerList, getCustomerListLoading } from 'store/customerList/getters';
import { DefaultCell, IdCell } from 'components/common/Table/TableCells';
import { CUSTOMER_PER_PAGE } from 'constants/common';
import PaginatedTable from 'components/common/Table/PaginatedTable';
import DataExport from 'components/common/DataExport';
import { ROUTES } from 'constants/routes';
import Grid from '@mui/material/Grid';
import './styles.scss';
import { keyBy } from 'lodash';
import { getSegmentsInfo } from 'api/segmentsAPI';
import { exportCustomers } from 'api/customersAPI';

const getTableConfig = (segmentsMap) => [{
  title: 'ID',
  key: 'id',
  ordering: ['id'],
  CellComponent: IdCell,
  componentProps: {},
  width: '100px',
  hasOverflowEllipsis: true,
}, {
  title: 'Name',
  key: 'name',
  ordering: ['first_name', 'last_name'],
  CellComponent: NameCell,
  componentProps: {},
  width: '200px',
  hasOverflowEllipsis: true,
}, {
  title: 'Segment',
  key: 'segment',
  ordering: ['segment'],
  CellComponent: SegmentCell,
  componentProps: { segmentsMap },
  width: '200px',
  hasOverflowEllipsis: true,
}, {
  title: 'Total Spent',
  key: 'total_sales',
  ordering: ['total_sales'],
  CellComponent: TotalSalesCell,
  componentProps: {},
  width: '150px',
  hasOverflowEllipsis: true,
}, {
  title: 'Last order',
  key: 'date_last_order',
  ordering: ['date_last_order'],
  CellComponent: DefaultCell,
  componentProps: {},
  width: '13%',
  hasOverflowEllipsis: true,
}];

const CustomerList = ({
  config,
  changeBaseConfig,
  customerListTotalCount,
  withExport,
  openOnNewTab,
}) => {
  const [segmentsMap, setSegmentsMap] = useState(null);
  const { push } = useRouter();
  const customerList = useSelector(getCustomerList);
  const customerListLoading = useSelector(getCustomerListLoading);

  useEffect(() => {
    const populateSegments = async () => {
      const segmentsInfo = await getSegmentsInfo();
      setSegmentsMap(keyBy(segmentsInfo, 'id'));
    };

    populateSegments();
  }, []);

  const handleSortChange = (sort) => changeBaseConfig({ ordering: sort });
  const handlePageChange = (page) => changeBaseConfig({ page });
  const handleRowClick = (row) => {
    if (!row?.id) return;

    const customerPath = ROUTES.customer.replace(':id', row.id);
    if (openOnNewTab) {
      window.open(customerPath, '_blank');
      return;
    }

    push(customerPath);
  };

  const handleExport = async () => {
    const {
      page, ordering = [], segment = [], ...filters
    } = config;
    await exportCustomers({ ordering, segment, filters });
  };
  const tableConfig = getTableConfig(segmentsMap);

  return (
    <Grid container spacing={2} justifyContent="flex-end">
      {withExport && (
        <Grid item className="customer-list__export">
          <DataExport exportHandler={handleExport} />
        </Grid>
      )}
      <Grid item xs={12}>
        <PaginatedTable
          sort={config.ordering}
          sortChanged={handleSortChange}
          totalCount={customerListTotalCount}
          tableConfig={tableConfig}
          data={customerList}
          highlightStartIdx={0}
          highlightEndIdx={4}
          countPerPage={CUSTOMER_PER_PAGE}
          page={config.page}
          handleRowClick={handleRowClick}
          pageChanged={handlePageChange}
          isDataLoading={customerListLoading}
        />
      </Grid>
    </Grid>
  );
};

CustomerList.propTypes = {
  config: PropTypes.object.isRequired,
  changeBaseConfig: PropTypes.func.isRequired,
  customerListTotalCount: PropTypes.number.isRequired,
  withExport: PropTypes.bool,
  openOnNewTab: PropTypes.bool,
};

CustomerList.defaultProps = {
  withExport: true,
  openOnNewTab: false,
};

export default CustomerList;
