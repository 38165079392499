export const COHORT_DISPLAY_TYPES = {
  table: 'table',
  graph: 'graph',
};

export const COHORT_AVERAGED_BY_TYPES = {
  none: 'N',
  customers: 'C',
  customers_in_month: 'CIM',
  orders: 'O',
};
