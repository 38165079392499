import React from 'react';
import PropTypes from 'prop-types';
import InfoTooltip from 'components/common/InfoTooltip';
import { noop } from 'lodash';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { maxDecimals } from 'utils/formatters';
import { DefaultCell } from 'components/common/Table/TableCells';
import 'components/common/CommonTableCells/styles.scss';

const cellPropTypes = {
  column: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  row: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

export const NameCell = ({ column, row, color }) => (
  <span className="table__cell__name" style={color && { color }}>
    {row[column.key]}
  </span>
);

NameCell.propTypes = {
  ...cellPropTypes,
  color: PropTypes.string,
};

NameCell.defaultProps = {
  color: undefined,
};

export const NameLinkCell = ({
  column, row, color, routeFunc, openInNewTab,
}) => (
  <Link
    className="table__cell__name-link"
    style={color && { color }}
    target={openInNewTab ? '_blank' : '_self'}
    to={routeFunc(row, column)}
  >
    {row[column.key]}
  </Link>
);

NameLinkCell.propTypes = {
  ...cellPropTypes,
  color: PropTypes.string,
  routeFunc: PropTypes.func,
  openInNewTab: PropTypes.bool,
};
NameLinkCell.defaultProps = {
  color: undefined,
  routeFunc: noop,
  openInNewTab: false,
};

export const ImageCell = ({ column, row }) => (
  <span className="table__cell__image">
    <img src={row[column.key]} />
  </span>
);

ImageCell.propTypes = {
  ...cellPropTypes,
};

export const PercentageCell = ({ column, row }) => {
  const formattedPercentage = maxDecimals(row[column.key] * 100, 2);

  return (
    <span className="table__cell__percentage">
      {`${formattedPercentage}%`}
    </span>
  );
};

PercentageCell.propTypes = {
  ...cellPropTypes,
};

export const OverflowListCell = ({ ItemComponent, data, limit }) => {
  const display = data?.slice(0, limit);
  const hiddenCount = data?.slice(limit).length;

  return (
    <>
      {display && display.map((item) => <ItemComponent key={item} item={item} />)}
      {hiddenCount > 0 && (
      <span className="overflow-list__cell__channels__counter">
        +
        {hiddenCount}
      </span>
      )}
    </>
  );
};

OverflowListCell.propTypes = {
  ItemComponent: PropTypes.elementType.isRequired,
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.any])),
  limit: PropTypes.number.isRequired,
};

OverflowListCell.defaultProps = {
  data: null,
};

const SEGMENTS_DISPLAY_LIMIT = 5;
export const SegmentsCell = ({
  column, row, segmentsMap, name_key,
}) => {
  const data = row[column.key];

  const ItemComponent = ({ item: segment_id }) => {
    if (!segmentsMap?.[segment_id]) return null;

    const name = segmentsMap[segment_id]?.[name_key];
    const { color_1: color } = segmentsMap[segment_id];
    return (
      <InfoTooltip text={name} placement="top">
        <div
          className="table__cell__segments__circle"
          style={{ backgroundColor: color }}
          id={segment_id}
          data-tip
          data-for="tip"
        />
      </InfoTooltip>
    );
  };

  ItemComponent.propTypes = {
    item: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  };

  return (
    <div className="table__cell__segments">
      {segmentsMap && (
        <OverflowListCell
          ItemComponent={ItemComponent}
          data={data}
          limit={SEGMENTS_DISPLAY_LIMIT}
        />
      )}
    </div>
  );
};

SegmentsCell.propTypes = {
  ...cellPropTypes,
  segmentsMap: PropTypes.objectOf(PropTypes.shape({
    color_1: PropTypes.string.isRequired,
  })).isRequired,
  name_key: PropTypes.string.isRequired,
};

export const DateCell = ({
  column, row, fromFormat, toFormat, fallbackTextKey,
}) => (
  <span className="table__cell__date">
    {row[column.key] && (
      moment(row[column.key], fromFormat).format(toFormat)
    )}
    {!row[column.key] && fallbackTextKey && !!row[fallbackTextKey] && (
      row[fallbackTextKey]
    )}
  </span>
);

DateCell.propTypes = {
  ...cellPropTypes,
  fromFormat: PropTypes.string.isRequired,
  toFormat: PropTypes.string.isRequired,
  fallbackTextKey: PropTypes.string,
};

DateCell.defaultProps = {
  fallbackTextKey: null,
};

export const NameDescriptionCell = ({ column, row, descriptionKey }) => (
  <div className="table__cell__name-description">
    <span className="table__cell__name">
      {row[column.key]}
    </span>
    <span className="table__cell__description">
      {row[descriptionKey]}
    </span>
  </div>
);

NameDescriptionCell.propTypes = {
  ...cellPropTypes,
  descriptionKey: PropTypes.string,
};

NameDescriptionCell.defaultProps = {
  descriptionKey: 'description',
};

export const CreatorCell = ({ column, row }) => {
  const firstName = row[column.key] && row[column.key].first_name;
  const lastName = row[column.key] && row[column.key].last_name;
  return (
    <span className="cohort__cell__creator">
      {firstName}
      {' '}
      {lastName}
    </span>
  );
};

CreatorCell.propTypes = cellPropTypes;

export const ContentCell = ({ column, row, content: Content }) => (
  <span className="table__cell__content">
    <Content
      column={column}
      row={row}
    />
  </span>
);

ContentCell.propTypes = {
  ...DefaultCell.propTypes,
  content: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
};
