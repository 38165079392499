import React, { useEffect, useState } from 'react';
import 'components/views/Homepage/style.scss';
import MainWelcomeBanner from 'components/views/Homepage/Banners/MainWelcomeBanner';
import KpiHistoryDataBlock from 'components/views/Homepage/KpiHistoryDataBlock';
import NoIntegrationState from 'components/views/Homepage/EmptyStates/NoIntegrationState';
import PullingDataState from 'components/views/Homepage/EmptyStates/PullingDataState';
import NoDataState from 'components/views/Homepage/EmptyStates/NoDataState';
import WelcomeBanner from 'components/views/Homepage/Banners/WelcomeBanner';
import MainHurrayBanner from 'components/views/Homepage/Banners/MainHurrayBanner';
import {
  ALL_SOURCES_PULLING_ERR_OR_PULLING,
  FIRST_PULLING,
  NO_ACTIVE_INTEGRATION,
  OK_STATUS,
  OK_PARTIAL,
} from 'constants/status';
import MainPullingDataState from 'components/views/Homepage/EmptyStates/MainPullingDataState';
import { Box, Stack } from '@mui/material';
import { getHomeDateRange } from 'api/homeAPI';
import { getUserStatus } from 'api/usersAPI';
import Disclaimer from 'components/common/Disclaimer';
import { MESSAGES } from 'constants/messages';

const HomePage = () => {
  const [status, setStatus] = useState({});
  const [withBanner, setWithBanner] = useState(true);
  const [dateBoundaries, setDateBoundaries] = useState({ date_from: null, date_to: null });
  const [hasLoadedDates, setHasLoadedDate] = useState(false);
  const hasDates = !!dateBoundaries.date_from && !!dateBoundaries.date_to;

  useEffect(() => {
    const populateUserStatus = async () => {
      const response = await getUserStatus();
      setStatus(response.status);
    };

    populateUserStatus();
  }, []);

  useEffect(() => {
    const setDates = async () => {
      getHomeDateRange().then((result) => {
        setDateBoundaries(result);
      }).finally(() => setHasLoadedDate(true));
    };

    setDates();
  }, []);

  useEffect(() => {
    if (localStorage.getItem('visit_status') === 'second') {
      setWithBanner(false);
    } else {
      localStorage.setItem('visit_status', 'second');
      setWithBanner(true);
    }
  }, []);

  return (
    <Stack className="home-container">
      {!!status && (status === OK_STATUS || status === OK_PARTIAL) && hasDates && (
        <>
          <Box display={{ xs: 'none', md: 'block' }}>
            {withBanner && (
              <MainWelcomeBanner />
            )}
          </Box>
          {status === OK_PARTIAL && (
            <Disclaimer
              type="warning"
              text={MESSAGES.data_is_still_pulled}
            />
          )}
          <KpiHistoryDataBlock />
        </>
      )}

      {!!status && status === NO_ACTIVE_INTEGRATION && (
        <>
          {withBanner && (
            <MainWelcomeBanner />
          )}
          {' '}
          <NoIntegrationState />
          {' '}
        </>
      )}

      {!!status && status === FIRST_PULLING && (
        <>
          {withBanner ? (
            <>
              <MainHurrayBanner />
              <PullingDataState />
            </>
          ) : (
            <MainPullingDataState />
          )}
        </>
      )}

      {!!status && (status === ALL_SOURCES_PULLING_ERR_OR_PULLING || (status !== NO_ACTIVE_INTEGRATION && (!hasDates && hasLoadedDates))) && (
        <>
          {' '}
          {withBanner && (
            <WelcomeBanner />
          )}
          {' '}
          <NoDataState />
          {' '}
        </>
      )}
    </Stack>
  );
};

export default HomePage;
