import { http } from 'utils';
import { ENDPOINTS } from 'constants/routes';
import { HTTP_METHODS } from 'constants/http';
import { prepareFilters, prepareSegment, prepareTagsAsFilters } from 'utils/filters';
import { ENTITY_SEGMENT } from 'constants/entities';
import { AUDIENCE_TYPES } from 'constants/customAudience';
import { httpFile } from 'utils/file';

export const prepareCustomerTags = (tags) => {
  const customerSegments = tags
    .filter(({ entityKey }) => entityKey === ENTITY_SEGMENT)
    .map((tag) => `${tag.uuid}__${tag.operator}__${tag.value}`);

  const takenEntities = [ENTITY_SEGMENT];
  const notTakenFilters = tags.filter(({ entityKey }) => !takenEntities.includes(entityKey));
  const filters = prepareTagsAsFilters(notTakenFilters);
  return `&customer_segments=${customerSegments.join(',')}&${filters}`;
};

export const getCustomerFilterList = () =>
  http({
    url: `${ENDPOINTS.customerFilterList}`,
    method: HTTP_METHODS.GET,
  });

export const getCustomersList = ({
  page,
  ordering = [],
  segment = [],
  filters = {},
  tags = [],
  type = AUDIENCE_TYPES.segment,
  cancelToken = undefined,
  withProductRecommendations = false,
}) => http({
  url: `${ENDPOINTS.customerList}?page=${page}&ordering=${ordering}&type=${type}&with_product_recommendations=${withProductRecommendations}&segment=${prepareSegment(segment)}${prepareFilters(filters)}${prepareCustomerTags(tags)}`,
  method: HTTP_METHODS.GET,
  cancelToken,
});

export const getProductRecommendations = (segment = [], tags = []) => http({
  url: `${ENDPOINTS.customersProductRecommendations}?segment=${prepareSegment(segment)}${prepareCustomerTags(tags)}`,
});

export const exportCustomers = ({
  ordering = [],
  segment = [],
  tags = [],
  type = AUDIENCE_TYPES.segment,
  filters = {},
}) => httpFile({
  url: `${ENDPOINTS.customerListExport}?ordering=${ordering}&type=${type}&segment=${prepareSegment(segment)}${prepareFilters(filters)}${prepareCustomerTags(tags)}`,
});
