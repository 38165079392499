import { SOURCE_IMAGE_CUSTOM_MAPPINGS } from 'constants/sources';
import { CUSTOMER_SOURCE_IMAGES } from 'constants/customerSources';

const getAssetSourceImageName = (source_key) =>
  SOURCE_IMAGE_CUSTOM_MAPPINGS[source_key] ?? source_key;

export const getAssetSourceImage = (source_key, mini = false) => {
  const type = mini ? 'mini' : 'default';
  const name = getAssetSourceImageName(source_key);
  return `/static/svg/sources/${type}/${name}.svg`;
};

export const getCustomerSourceImage = (source_key) => {
  const assetName = CUSTOMER_SOURCE_IMAGES[source_key] || 'other';
  return `/static/svg/sources/mini/${assetName}.svg`;
};
