import React from 'react';
import './styles.scss';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';

const RadioSelect = ({
  name,
  options,
  value,
  onChange,
}) => (
  <div className="radio-select">
    <Grid
      container
      columnSpacing={2}
      rowSpacing={1}
    >
      {options.map((option) => (
        <Grid item key={option.value}>
          <div className="radio-select__option">
            <input
              type="radio"
              id={option.value}
              name={name}
              value={option.value}
              checked={option.value === value}
              onChange={onChange}
            />
            <label htmlFor={option.value}>
              {option.label}
            </label>
          </div>
        </Grid>
      ))}
    </Grid>
  </div>
);

RadioSelect.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RadioSelect;
