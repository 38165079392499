import React, { Children, useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

const RadioCollapsibleGroup = ({
  onChange, value, children, allowDeselect,
}) => {
  const [selected, setSelected] = useState(null);

  const handleClick = (newValue) => {
    if (selected === newValue && allowDeselect) newValue = null;
    setSelected(newValue);
    onChange(newValue);
  };

  useEffect(() => {
    setSelected(value);
  }, [value]);

  const collapsibles = Children.map(children, (element) => {
    if (!React.isValidElement(element)) return null;

    const childValue = element.props.value;
    if (!childValue) return element;

    return React.cloneElement(element, {
      onClick: () => handleClick(childValue),
      isExpanded: selected === childValue,
    });
  });

  return (
    <Stack spacing={2}>
      {collapsibles}
    </Stack>
  );
};

RadioCollapsibleGroup.propTypes = {
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
  allowDeselect: PropTypes.bool,
};

RadioCollapsibleGroup.defaultProps = {
  onChange: noop,
  value: null,
  allowDeselect: false,
};

export default RadioCollapsibleGroup;
