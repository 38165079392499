import { http } from 'utils';
import { ENDPOINTS } from 'constants/routes';
import { HTTP_METHODS } from 'constants/http';
import { userUuidLSManager } from 'helper/localStorage';
import { ENTITY_LICENSE } from 'constants/entities';

export const getUserStatus = () => http({
  url: ENDPOINTS.userStatus,
});

export const createUser = ({
  email,
  password,
  confirm_password,
  verification_code,
}) => http({
  url: ENDPOINTS.user,
  method: HTTP_METHODS.POST,
  data: {
    email,
    password,
    confirm_password,
    verification_code,
  },
  useToken: false,
});

const prepareTagsParams = (tags) => {
  const licenseUuids = tags
    .filter(({ entityKey }) => entityKey === ENTITY_LICENSE)
    .map(({ uuid }) => uuid);

  return `&license_uuid__in=${licenseUuids.join(',')}`;
};

export const getUsersList = ({
  page = 1, page_size = '', ordering, search = '', is_active = true, tags = [],
}) => http({
  url: `${ENDPOINTS.user}?page=${page}&page_size=${page_size}&ordering=${ordering}&search=${search}&is_active=${is_active}${prepareTagsParams(tags)}`,
  method: HTTP_METHODS.GET,
});

export const reactivateUser = (uuid) => http({
  url: `${ENDPOINTS.userReactivate}${uuid}/`,
  method: HTTP_METHODS.GET,
});

export const updateUserPassword = ({
  uuid, password, confirm_password,
}) => http({
  url: `${ENDPOINTS.user}${uuid}/`,
  method: HTTP_METHODS.PUT,
  data: { password, confirm_password },
});

export const deleteUser = (uuid) => http({
  url: `${ENDPOINTS.user}${uuid}/`,
  method: HTTP_METHODS.DELETE,
});

export const createInvitedUser = ({ updateUUID, password, confirm_password }) => http({
  url: `${ENDPOINTS.user}${updateUUID}/`,
  method: HTTP_METHODS.PATCH,
  data: { password, confirm_password },
  useToken: false,
});

export const updateInvitedUser = ({ invitedEmail, password, confirm_password }) => http({
  url: `${ENDPOINTS.updateInvitedUser}${invitedEmail}/`,
  method: HTTP_METHODS.PATCH,
  data: {
    password,
    confirm_password,
  },
  useToken: false,
});

export const getCurrentUser = () => http({
  url: ENDPOINTS.getCurrentUser,
  method: HTTP_METHODS.GET,
});

export const updatePersonalDetails = ({
  first_name,
  last_name,
  role, avatar,
}) => {
  const data = new FormData();

  if (!avatar?.length && avatar) {
    data.append('avatar', avatar, 'avatar.png');
  } else {
    data.append('avatar', '');
  }

  data.append('first_name', first_name);
  data.append('last_name', last_name);
  data.append('role', role);

  return http({
    url: `${ENDPOINTS.personalDetails}${userUuidLSManager.get()}/`,
    method: HTTP_METHODS.PUT,
    data,
    noContentType: true,
  });
};

export const inviteUser = ({ email, license }) => http({
  url: ENDPOINTS.inviteUser,
  method: HTTP_METHODS.POST,
  data: {
    email,
    license,
  },
});

export const acceptInvitationLink = ({ invitedLicenseUUID }) => http({
  url: ENDPOINTS.acceptLicenseInvitation,
  method: HTTP_METHODS.POST,
  data: { license_details_uuid: invitedLicenseUUID },
});

export const sendEmailVerification = (email) => http({
  url: ENDPOINTS.emailVerificationSend,
  method: HTTP_METHODS.POST,
  data: { email },
});
