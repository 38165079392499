import React, { useState, useEffect } from 'react';
import { ImageCell, NameCell, PercentageCell } from 'components/common/CommonTableCells';
import { FormattedNumberCell, IdCell } from 'components/common/Table/TableCells';
import { MESSAGES } from 'constants/messages';
import { titleWithCurrency } from 'utils/formatters';
import PropTypes from 'prop-types';
import Table from 'components/common/Table';
import './styles.scss';
import { get } from 'lodash';
import Button from 'components/common/Button';
import { Stack } from '@mui/material';
import { ReactComponent as ArrowIcon } from 'assets/svg/arrowUp.svg';
import classNames from 'classnames';
import ProductPopperMetrics, { ProductPopperMetricsStateHandler } from 'components/common/MetricList/PopperMetrics/ProductPopperMetrics';
import { ROUTES } from 'constants/routes';

const getProductTableConfig = (currency, hasImages) => [
  ...(hasImages ? [{
    title: null,
    key: 'img',
    ordering: null,
    CellComponent: ImageCell,
    componentProps: {},
    width: '50px',
    hasOverflowEllipsis: true,
  }] : []), {
    title: MESSAGES.product,
    key: 'name',
    ordering: null,
    CellComponent: NameCell,
    componentProps: {},
    width: '200px',
    hasOverflowEllipsis: true,
  }, {
    title: MESSAGES.sku,
    key: 'sku',
    ordering: null,
    CellComponent: IdCell,
    componentProps: {},
    width: '100px',
    hasOverflowEllipsis: true,
  }, {
    title: MESSAGES.profit_margin,
    key: 'profit_margin',
    ordering: null,
    CellComponent: PercentageCell,
    componentProps: {},
    width: '100px',
    hasOverflowEllipsis: true,
  }, {
    title: titleWithCurrency(MESSAGES.revenue, currency),
    key: 'revenue',
    ordering: null,
    CellComponent: FormattedNumberCell,
    componentProps: { prefix: true },
    width: '100px',
    hasOverflowEllipsis: false,
  },
];

const RecommendedProducts = ({ products }) => {
  const [currency, setCurrency] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const hasImages = products?.length && !!products.filter((row) => !!row.img).length;
  useEffect(() => {
    setCurrency(get(products, [0, 'currency']));
  }, [products]);

  const {
    handleOnHover,
    anchorEl,
    item,
    itemData,
    isLoading,
    isOpen,
  } = ProductPopperMetricsStateHandler();
  const formattedItem = item ? { title: item.name } : undefined;

  const handleRowClick = (row) => {
    const { id } = row;
    if (!id) return;
    window.open(ROUTES.product.replace(':id', id), '_blank');
  };

  if (!products || !products?.length) return null;

  return (
    <>
      <Stack
        spacing={2}
        className={classNames('recommended-products', { expanded: isExpanded })}
      >
        <div className="table-wrapper">
          <Table
            data={products}
            sortChanged={() => {}}
            initSort={['rating']}
            tableConfig={getProductTableConfig(currency, hasImages)}
            highlightStartIdx={0}
            highlightEndIdx={hasImages ? 4 : 3}
            contentClassName="table-content"
            onHover={handleOnHover}
            handleRowClick={handleRowClick}
          />
        </div>
        <Button
          className="expand-button"
          type="secondary"
          icon={ArrowIcon}
          fillWidth
          onClick={() => setIsExpanded(!isExpanded)}
        />
      </Stack>
      <ProductPopperMetrics
        isLoading={isLoading}
        isOpen={isOpen}
        item={formattedItem}
        itemData={itemData}
        anchorEl={anchorEl}
      />
    </>
  );
};

RecommendedProducts.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    img: PropTypes.string,
    profit_margin: PropTypes.number,
    revenue: PropTypes.number,
    currency: PropTypes.string.isRequired,
  })),
};

RecommendedProducts.defaultProps = {
  products: [],
};

export default RecommendedProducts;
