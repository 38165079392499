import React from 'react';
import { useSelector } from 'react-redux';
import ProtectedRoute from 'components/common/ProtectedRoutes';
import { ROUTES } from 'constants/routes';
import propTypes from 'prop-types';
import { getHasDataAccess } from 'store/license/getters';

const DataAccessRoute = ({ component: Component, ...rest }) => {
  const hasDataAccess = useSelector(getHasDataAccess);

  return (
    <ProtectedRoute
      hasPermission={hasDataAccess}
      component={Component}
      redirectToFunc={() => ({ pathname: ROUTES.home })}
      {...rest}
    />
  );
};

export default DataAccessRoute;

DataAccessRoute.propTypes = {
  component: propTypes.func.isRequired,
};
