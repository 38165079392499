import React from 'react';
import propTypes from 'prop-types';

import validationPropTypes from '../types';
import Input from 'components/common/Input';
import Select from 'components/common/Select';
import { BUTTON_TYPE, REQUEST_STATUS } from 'constants/common';
import { ENDPOINTS, ROUTES } from 'constants/routes';
import Button from 'components/common/Button';
import useOptions from 'hooks/useOptions';
import ReactTooltip from 'react-tooltip';
import { MESSAGES } from 'constants/messages';
import { size } from 'lodash';
import BaseFooter from 'containers/Authorization/BaseFooter';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/material';
import { LinkButton } from 'components/common/LinkButton';
import useRouter from 'hooks/useRouter';
import Block from 'components/common/Block';

const CompanyDetails = ({
  handleSubmit,
  handleChange,
  handleBlur,
  hasBackButton,
  onBackButtonHandler,
  values,
  errors,
  touched,
  status,
}) => {
  document.title = `${MESSAGES.company_info} - ${MESSAGES.custimy}`;
  const { replace } = useRouter();

  const {
    options: businessCategoryOptions,
    status: businessCategoryStatus,
  } = useOptions(ENDPOINTS.businessCategory, 'business_categories', true);
  const {
    options: companySizeOptions,
    status: companySizeStatus,
  } = useOptions(ENDPOINTS.companySize, 'company_sizes', true);

  const calculateToolTipPosition = (pos, e) => ({ left: (e.x - 35), top: e.y + 25 });

  return (
    <div className="container create-license">
      <Grid
        item
        xs={12}
        sx={{
          width: '100%', height: '100%', display: 'flex', justifyContent: 'space-between',
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            margin: {
              xl: 'auto 65px auto 225px', lg: 'auto 25px auto 125px', md: 'auto 0px auto 25px', xs: 'auto',
            },
            width: { sm: '400px', xs: '100%' },
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div className="container__main">
            <Block>
              <main className="main">
                <h1 className="main__title-create-license">{MESSAGES.create_license}</h1>
                <p className="main__subtitle-create-license">{MESSAGES.please_fill_in_your_information_to_create_a_new_license}</p>
                <form className="main__form form" onSubmit={handleSubmit} noValidate>
                  <div className="form__field">
                    <Input
                      id="companyName"
                      name="companyName"
                      label={MESSAGES.company_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="given-name"
                      value={values.companyName}
                      error={errors.companyName}
                      touched={touched.companyName}
                      isRequired
                      hasFeedback={!!values.companyName?.length}
                    />
                  </div>
                  <div className="form__field">
                    <Select
                      name="businessCategory"
                      label={MESSAGES.business_category}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={businessCategoryOptions}
                      defaultValue={values.businessCategory}
                      error={errors.businessCategory}
                      touched={touched.businessCategory}
                      isLoading={businessCategoryStatus === REQUEST_STATUS.loading}
                      isRequired
                    />
                  </div>
                  <div className="form__field">
                    <Input
                      id="companyURL"
                      name="companyURL"
                      label={MESSAGES.company_url}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.companyURL}
                      error={errors.companyURL}
                      touched={touched.companyURL}
                      autoComplete="url"
                      hasFeedback={!!values.companyURL?.length}
                      isRequired
                    />
                  </div>
                  <div className="form__field form__field--last">
                    <Select
                      name="companySize"
                      label={MESSAGES.company_size}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={companySizeOptions}
                      defaultValue={values.companySize}
                      error={errors.companySize}
                      touched={touched.companySize}
                      isLoading={companySizeStatus === REQUEST_STATUS.loading}
                      isRequired
                    />
                  </div>
                  <div className="form__actions">
                    <div className="form__submit continue" data-tip data-for="createlicenseTip">
                      <Button type={BUTTON_TYPE.primary} isDisabled={status === REQUEST_STATUS.loading} fillWidth>
                        {MESSAGES.continue}
                      </Button>
                    </div>
                    {!!size(errors) && (
                    <ReactTooltip className="tooltip-container" id="createlicenseTip" overridePosition={calculateToolTipPosition} type="light" effect="solid" globalEventOff="click" arrowColor="transparent">
                      <div className="tooltip-text">{MESSAGES.please_fill_in_your_company_information}</div>
                    </ReactTooltip>
                    )}
                  </div>
                  {hasBackButton && (
                    <div className="form__actions">
                      <Box display="flex" justifyContent="center">
                        <LinkButton
                          size="medium"
                          onClick={onBackButtonHandler}
                        >
                          {MESSAGES.cancel}
                        </LinkButton>
                      </Box>
                    </div>
                  )}
                  {!hasBackButton && (
                    <div className="form__actions">
                      <Box display="flex" justifyContent="center">
                        <LinkButton
                          size="medium"
                          onClick={() => replace(ROUTES.switchLicense)}
                        >
                          {MESSAGES.switch_license}
                        </LinkButton>
                      </Box>
                    </div>
                  )}
                </form>
              </main>
            </Block>
            <BaseFooter />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

CompanyDetails.propTypes = {
  ...validationPropTypes,
  hasBackButton: propTypes.bool,
  onBackButtonHandler: propTypes.func,
};

CompanyDetails.defaultProps = {
  hasBackButton: false,
  onBackButtonHandler: () => {},
};

export default CompanyDetails;
