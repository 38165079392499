import React from 'react';
import propTypes from 'prop-types';

import { MESSAGES } from 'constants/messages';
import useAsync from 'hooks/useAsync';
import Button from 'components/common/Button';
import { BUTTON_TYPE } from 'constants/common';
import { ReactComponent as ExportIcon } from 'assets/svg/export.svg';

const DataExport = ({ exportHandler }) => {
  const { isLoading, execute } = useAsync(exportHandler);

  return (
    <Button
      type={BUTTON_TYPE.secondary}
      onClick={execute}
      icon={ExportIcon}
      isLoading={isLoading}
    >
      {MESSAGES.export}
    </Button>
  );
};

DataExport.propTypes = {
  exportHandler: propTypes.func.isRequired,
};

export default DataExport;
