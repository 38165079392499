import React from 'react';

import Tooltip from 'components/common/Tooltip';
import { ReactComponent as HelpIcon } from 'assets/svg/help.svg';
import CVCAlert from 'containers/PrivateApp/Payment/PaymentForm/StripeElementInput/CVCAlert';
import { MESSAGES } from 'constants/messages';

const CVCHelpIcon = () => <HelpIcon className="card-number__help-icon" />;

const CVCFeedback = () => (
  <Tooltip
    trigger={CVCHelpIcon}
    arrow={false}
    position="right center"
  >
    <div className="tooltip-alert">
      <CVCAlert>
        {MESSAGES.digits_on_the_back_of_the_card}
      </CVCAlert>
    </div>
  </Tooltip>
);

export default CVCFeedback;
