import React, { useState } from 'react';
import propTypes from 'prop-types';
import 'components/views/Profile/PasswordBlock/styles.scss';
import Button from 'components/common/Button';
import { BUTTON_TYPE } from 'constants/common';
import PasswordEditMode from 'components/views/Profile/PasswordBlock/PasswordEditMode';
import { MESSAGES } from 'constants/messages';
import { Box, Stack } from '@mui/material';

const PasswordBlock = ({ password, editable }) => {
  const passwordForMap = Array(password.length).fill(true);
  const [isEdit, setIsEdit] = useState(false);
  const onEditModeSetHandler = () => setIsEdit(!isEdit);
  const onEditModeCancelHandler = () => {
    setIsEdit(false);
  };

  return (
    <Stack className="password-wrapper">
      {isEdit ? (
        <PasswordEditMode onClick={onEditModeCancelHandler} />
      ) : (
        <div>
          <span className="password-block__field-title">
            {MESSAGES.password}
          </span>
          <div className="password-block__field-value">
            {passwordForMap.map((_, index) => (
              <div className="password-block__round" key={index} />
            ))}
          </div>
        </div>
      )}
      {!isEdit && editable && (
        <Box display="flex" justifyContent="flex-end">
          <Button onClick={onEditModeSetHandler} block type={BUTTON_TYPE.primary}>
            {MESSAGES.edit}
          </Button>
        </Box>
      )}
    </Stack>
  );
};

PasswordBlock.propTypes = {
  password: propTypes.string.isRequired,
  editable: propTypes.bool,
};

PasswordBlock.defaultProps = {
  editable: false,
};

export default PasswordBlock;
