import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getToasts } from 'store/toasts/getters';
import { addToastAction, removeToastAction, updateToastAction } from 'store/toasts/toastsSlice';
import { v1 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import './styles.scss';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import { Grid } from '@mui/material';
import classNames from 'classnames';
import IconButton from 'components/common/IconButton';

const TOAST_MAX = 6;
const TOAST_FADE_TIME = 1000;
const TOAST_SHOW_TIME = 4500;

export const useToast = () => {
  const toasts = useSelector(getToasts);
  const dispatch = useDispatch();

  const removeToast = (toast) => {
    if (toast.closingTimer) clearTimeout(toast.closingTimer);
    dispatch(removeToastAction(toast));
  };

  const updateToast = (toast) => {
    dispatch(updateToastAction(toast));
  };

  const startClosingToast = (toast) => {
    updateToast({ ...toast, isClosing: true });
    setTimeout(() => removeToast(toast), TOAST_FADE_TIME);
  };

  const getToastByUniqueName = (uniqueName) => toasts.find((e) => e.uniqueName === uniqueName);

  const handleUniqueToast = (uniqueName) => {
    const existingToast = getToastByUniqueName(uniqueName);
    if (existingToast) removeToast(existingToast);
  };

  const createToast = (header, text, type = 'success', uniqueName = null) => {
    if (uniqueName) handleUniqueToast(uniqueName);

    if (toasts.length > TOAST_MAX) {
      toasts.splice(0, toasts.length - TOAST_MAX).forEach((toast) => removeToast(toast));
    }

    const showTime = TOAST_SHOW_TIME;
    const toast = {
      id: uuid(),
      uniqueName,
      header,
      text,
      type,
      showTime,
      isClosing: false,
      closingTimer: null,
    };
    toast.closingTimer = setTimeout(() => startClosingToast(toast), showTime);
    dispatch(addToastAction(toast));
  };

  return {
    toasts,
    createToast,
    removeToast,
  };
};

const ToastCard = ({ toast, onClose }) => (
  <div className={classNames('toast', toast.type, { 'is-fading': toast.isClosing })}>
    <Grid container>
      <Grid item xs={11}>
        <div className="toast-header">
          {toast.header}
        </div>
        <div className="toast-text">
          {toast.text}
        </div>
      </Grid>
      <Grid item xs={1}>
        <IconButton
          onClick={() => onClose(toast)}
          icon={CloseIcon}
          size="mediun"
          type="secondary"
        />
      </Grid>
    </Grid>
  </div>
);

ToastCard.propTypes = {
  toast: PropTypes.shape({
    id: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['success', 'info', 'warning', 'danger']).isRequired,
    showTime: PropTypes.number.isRequired,
    isClosing: PropTypes.bool.isRequired,
  }).isRequired,
  onClose: PropTypes.func,
};

ToastCard.defaultProps = {
  onClose: noop,
};

export const Toasts = () => {
  const { toasts, removeToast } = useToast();
  return (
    <div className="toast-container">
      {!!toasts.length && toasts.map((toast) => (
        <ToastCard
          toast={toast}
          onClose={removeToast}
          key={toast.id}
        />
      ))}
    </div>
  );
};
