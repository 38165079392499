import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/common/Button';
import { BUTTON_TYPE, REQUEST_STATUS } from 'constants/common';
import './styles.scss';

const FormModal = ({ actionsConfig }) => (
  <form className="main__form form" noValidate>
    <div className="form__actions">
      {actionsConfig.map((item, index) => (item?.withInput ? (
        <div className="input-button" key={index}>
          <input
            type="submit"
            value={item.label}
            onClick={item.onClickHandler}
          />
        </div>
      )
        : (
          <div className="form__actions__submit-sign-in" key={index}>
            <Button
              isPreventSubmit
              type={BUTTON_TYPE.primary}
              isDisabled={REQUEST_STATUS.loading === item?.status}
              onClick={item.onClickHandler}
              fillWidth
            >
              {item.label}
            </Button>
          </div>
        )))}
    </div>
  </form>
);

export default FormModal;

FormModal.propTypes = {
  actionsConfig: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.object,
    ]),
  ),
};

FormModal.defaultProps = {
  actionsConfig: null,
};
