import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import './styles.scss';
import Percentage from 'components/common/Percentage';
import LastMonthLabel from '../LastMonthLabel';
import { NumberFormatted, NumberFormattedCurrency } from 'helper/NumberFormatted';
import GuideInfo from '../GuideInfo';
import { getActiveIntegrationsList } from 'store/license/getters';
import { checkHasIntegration } from 'utils/activeIntegrations';
import IntegrationRequired from 'components/common/IntegrationRequired/IntegrationRequired';
import { MESSAGES } from 'constants/messages';
import { Box, Stack } from '@mui/material';

const TotalItem = ({
  title,
  percentage,
  children,
  prefix,
  subtitle,
  guide,
  perc,
  monthly,
  coming_soon,
  requiredIntegration,
  negative,
  titleSuffix,
  isPrevious,
  hasPrevValue,
  fillHeight,
}) => {
  const activeIntegrationsList = useSelector(getActiveIntegrationsList);
  const isNotIntegrated = requiredIntegration
    ? !checkHasIntegration(activeIntegrationsList, requiredIntegration) : null;

  return (
    <div className={classNames('total-item', {
      disabled: !percentage && !children && !isNotIntegrated,
      'total-item--fill-height': fillHeight,
    })}
    >
      <Stack direction="row" justifyContent="space-between">
        <div className="total-item-title-wrapper">
          <p className="total-item-title">
            {(!!title && !!title.length) ? title : ''}
          </p>
          <span className="total-item-title-suffix">
            {(!!titleSuffix && !!titleSuffix.length) ? titleSuffix : ''}
          </span>
        </div>
        {!!guide && (
          <Box alignItems="flex-end">
            <GuideInfo info={guide} />
          </Box>
        )}
      </Stack>
      {!!subtitle && <p className="total-item-subtitle">{(!!subtitle && !!subtitle.length) ? subtitle : ''}</p>}

      {(!!percentage || !!children || percentage === 0 || children === 0)
        ? (
          <>
            <div className="total-item-value">
              {(!!children || children === 0) && !!prefix && <span className="prefix">{prefix}</span>}
              {!!children || children === 0 ? prefix ? NumberFormattedCurrency(children) : NumberFormatted(children) : <div className="empty-state">-</div> }
              {perc && <span className="percent-value">%</span>}
              {!!monthly && (
              <span className="month">
                {' '}
                {MESSAGES.per_month}
              </span>
              ) }
            </div>
            {hasPrevValue && (
              <div className="total-item-description">
                {(!!percentage || percentage === 0) ? (
                  <>
                    {' '}
                    <Percentage inverted={negative}>
                      {percentage === 0 ? percentage : (percentage || '')}
                    </Percentage>
                    <LastMonthLabel isPrevious={isPrevious} />
                  </>
                ) : <span className="empty-value">-</span> }
              </div>
            )}
          </>
        )
        : isNotIntegrated
          ? <IntegrationRequired requiredIntegration={requiredIntegration} />
          : <div className="empty-state">{coming_soon ? MESSAGES.coming_soon : MESSAGES.no_data_available}</div> }
    </div>
  );
};

TotalItem.propTypes = {
  title: PropTypes.string.isRequired,
  percentage: PropTypes.number,
  children: PropTypes.number,
  prefix: PropTypes.string,
  subtitle: PropTypes.string,
  guide: PropTypes.string,
  perc: PropTypes.bool,
  monthly: PropTypes.bool,
  coming_soon: PropTypes.bool,
  requiredIntegration: PropTypes.string,
  negative: PropTypes.bool,
  titleSuffix: PropTypes.string,
  isPrevious: PropTypes.bool,
  hasPrevValue: PropTypes.bool,
  fillHeight: PropTypes.bool,
};

TotalItem.defaultProps = {
  percentage: null,
  children: null,
  prefix: undefined,
  subtitle: undefined,
  guide: undefined,
  perc: undefined,
  monthly: undefined,
  coming_soon: null,
  requiredIntegration: undefined,
  negative: false,
  titleSuffix: undefined,
  isPrevious: false,
  hasPrevValue: true,
  fillHeight: true,
};

export default TotalItem;
