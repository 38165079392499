import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import { ReactComponent as RemoveIcon } from 'assets/svg/offCircleClose.svg';
import classNames from 'classnames';

const FilterTag = ({
  isActive, onTagClick, onRemoveClick, children, disabled,
}) => {
  const handleRemoveClick = (e) => {
    e.stopPropagation();
    onRemoveClick();
  };

  const handleClick = (e) => {
    if (disabled) return;
    onTagClick(e);
  };

  return (
    <div
      className={classNames('filter-tags__content__tag', {
        'filter-tags__content__tag__active': isActive,
        disabled,
      })}
      onClick={handleClick}
    >
      <div className="filter-tags__content__tag__text text-ellipsis">
        {children}
      </div>
      {!disabled && (
        <RemoveIcon
          className="filter-tags__content__tag__image"
          onClick={handleRemoveClick}
        />
      )}
    </div>
  );
};

FilterTag.propTypes = {
  isActive: PropTypes.bool,
  onTagClick: PropTypes.func.isRequired,
  onRemoveClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
};

FilterTag.defaultProps = {
  isActive: false,
  disabled: false,
};

export default FilterTag;
