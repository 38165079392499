import { MESSAGES } from 'constants/messages';

export const SEARCH_POPOVER_POSITION = {
  top: 'top',
  bottom: 'bottom',
};

export const DETAILS_FIELDS = [
  { key: 'price', title: MESSAGES.price },
  { key: 'avg_price', title: MESSAGES.avg_price },
  { key: 'cost', title: MESSAGES.cost },
  { key: 'sku', title: MESSAGES.sku },
  { key: 'id', title: MESSAGES.id },
];

export const PERFORMANCE_FIELDS = [
  { key: 'profit', title: MESSAGES.profit },
  { key: 'profit_margin', title: MESSAGES.profit_margin },
  { key: 'revenue', title: MESSAGES.revenue },
  { key: 'items_sold', title: MESSAGES.items_sold },
  { key: 'total_returns', title: MESSAGES.items_returned },
  { key: 'return_rate', title: MESSAGES.return_rate },
  { key: 'discounts', title: MESSAGES.discounts },
  { key: 'repurchase_rate', title: MESSAGES.repurchase_rate },
  { key: 'first_order_rate', title: MESSAGES.first_order_rate },
  { key: 'bought_uniquely_rate', title: MESSAGES.bought_uniquely_rate },
];

export const CATEGORY_PERFORMANCE_FIELDS = [
  { key: 'profit', title: MESSAGES.profit },
  { key: 'profit_margin', title: MESSAGES.profit_margin },
  { key: 'revenue', title: MESSAGES.revenue },
  { key: 'total_returns', title: MESSAGES.items_returned },
  { key: 'items_sold', title: MESSAGES.items_sold },
  { key: 'cost_min', title: MESSAGES.cost_min },
  { key: 'cost_max', title: MESSAGES.cost_max },
  { key: 'price_min', title: MESSAGES.price_min },
  { key: 'price_max', title: MESSAGES.price_max },
  { key: 'price_margin_min', title: MESSAGES.profit_margin_min },
  { key: 'price_margin_max', title: MESSAGES.profit_margin_max },
  { key: 'unique_products', title: MESSAGES.unique_products },
  { key: 'total_discounts', title: MESSAGES.total_discounts },
  { key: 'return_rate', title: MESSAGES.return_rate },
  { key: 'discount_rate', title: MESSAGES.discount_rate },
];

export const CUSTOMER_PERFORMANCE_FIELDS = [
  { key: 'clv', title: MESSAGES.predicted_yearly_value },
  { key: 'churn_prob', title: MESSAGES.probability_of_churn },
  { key: 'propensity_to_buy', title: MESSAGES.propensity_to_buy },
  { key: 'total_sales', title: MESSAGES.total_sales },
  { key: 'total_profit', title: MESSAGES.total_profit },
  { key: 'total_orders', title: MESSAGES.total_orders },
  { key: 'total_refunded_amount', title: MESSAGES.total_refunded },
  { key: 'avg_order_size', title: MESSAGES.order_value_avg },
  { key: 'total_products', title: MESSAGES.total_order_items },
  { key: 'total_refunds', title: MESSAGES.total_returns },
  { key: 'total_discounts', title: MESSAGES.total_discounts },
  { key: 'return_rate', title: MESSAGES.return_rate },
  { key: 'profit_margin', title: MESSAGES.profit_margin },
  { key: 'discount_rate', title: MESSAGES.discount_rate },
  { key: 'refunded_amount_rate', title: MESSAGES.refund_rate },
];

export const CUSTOMER_DETAILS_FIELDS = [
  { key: 'name', title: MESSAGES.name },
  { key: 'email', title: MESSAGES.email },
  { key: 'country', title: MESSAGES.country },
  { key: 'city', title: MESSAGES.city },
  { key: 'address', title: MESSAGES.billing_address },
  { key: 'tags', title: MESSAGES.tags },
  { key: 'accepts_marketing', title: MESSAGES.customer_consent },
];

export const CUSTOMER_EVENT_FIELDS = [
  { key: 'total_sessions', title: MESSAGES.total_sessions },
  { key: 'opened_emails', title: MESSAGES.opened_emails },
  { key: 'received_emails', title: MESSAGES.received_emails },
  { key: 'days_since_last_order', title: MESSAGES.days_since_last_order },
  { key: 'days_since_first_order', title: MESSAGES.days_since_first_order },
  { key: 'days_since_contacted_email', title: MESSAGES.days_since_last_contacted },
  { key: 'avg_days_between_orders', title: MESSAGES.days_between_orders_avg },
];
