import React from 'react';
import propTypes from 'prop-types';

import 'components/views/Profile/PersonalBlock/styles.scss';
import ConfirmationWindow from 'components/common/ConfirmationWindow';
import CustomOpenButton from 'components/common/ConfirmationWindow/CustomOpenButton';
import { BUTTON_TYPE, REQUEST_STATUS } from 'constants/common';
import { MESSAGES } from 'constants/messages';

const ConfirmationCancelButton = ({ onConfirm, status }) => {
  const onConfirmWithClose = (close) => {
    onConfirm();
    close();
  };

  return (
    <ConfirmationWindow
      content={MESSAGES.confirmation_cancel_content}
      onConfirm={onConfirmWithClose}
      forceBlocking={false}
      customOpenButton={() => (
        <CustomOpenButton
          type={BUTTON_TYPE.secondary}
          isPreventSubmit
          isDisabled={REQUEST_STATUS.loading === status}
        >
          Cancel
        </CustomOpenButton>
      )}
    />
  );
};

ConfirmationCancelButton.propTypes = {
  onConfirm: propTypes.func.isRequired,
  status: propTypes.string.isRequired,
};

export default ConfirmationCancelButton;
