import React from 'react';

import PropTypes from 'prop-types';
import { ReactComponent as CheckMark } from 'assets/svg/checkMark.svg';
import { ReactComponent as CrossOut } from 'assets/svg/cross.svg';
import {
  PASSWORD_PATTERN_LETTER_NUMBER, PASSWORD_PATTERN_UPPERCASE_LOWERCASE, PASSWORD_PATTERN_SPECIAL_XTERS,
} from 'constants/validation';
import { MESSAGES } from 'constants/messages';

export const MinLengthCheck = ({ password }) => (
  <li className={password?.length > 7 ? 'password-passed' : password?.length ? 'password-failed' : ''}>
    <span>
      {password?.length > 7 ? <CheckMark width="10" height="8" />
        : password?.length ? <CrossOut width="10" height="8" /> : null}
    </span>
    {MESSAGES.or_more_characters}
  </li>
);

export const NumberLetterMixCheck = ({ password }) => (
  <li className={PASSWORD_PATTERN_LETTER_NUMBER.test(password) ? 'password-passed' : password?.length > 7 ? 'password-failed' : ''}>
    <span>
      {PASSWORD_PATTERN_LETTER_NUMBER.test(password) ? <CheckMark width="10" height="8" />
        : password?.length > 7 ? <CrossOut width="10" height="8" /> : null}
    </span>
    {MESSAGES.mixture_of_letters_and_numbers}
  </li>
);

export const UpperLowerCaseMixCheck = ({ password }) => (
  <li className={PASSWORD_PATTERN_UPPERCASE_LOWERCASE.test(password) ? 'password-passed' : password?.length > 7 ? 'password-failed' : ''}>
    <span>
      {PASSWORD_PATTERN_UPPERCASE_LOWERCASE.test(password) ? <CheckMark width="10" height="8" />
        : password?.length > 7 ? <CrossOut width="10" height="8" /> : null}
    </span>
    {MESSAGES.mixture_of_upper_and_lowercase}
  </li>
);

export const SpecialCharacterMixCheck = ({ password }) => (
  <li className={PASSWORD_PATTERN_SPECIAL_XTERS.test(password)
    ? 'password-passed' : password?.length > 7 ? 'password-failed' : ''}
  >
    <span>
      {PASSWORD_PATTERN_SPECIAL_XTERS.test(password)
        ? <CheckMark width="10" height="8" /> : password?.length > 7 ? <CrossOut width="10" height="8" /> : null}
    </span>
    {MESSAGES.special_characters}
  </li>
);

const PasswordChecks = ({ password }) => (
  <div className="form__field field-list">
    <ul>
      <MinLengthCheck password={password} />
      <NumberLetterMixCheck password={password} />
      <UpperLowerCaseMixCheck password={password} />
      <SpecialCharacterMixCheck password={password} />
    </ul>
  </div>
);

export default PasswordChecks;

MinLengthCheck.propTypes = { password: PropTypes.string };
MinLengthCheck.defaultProps = { password: null };

PasswordChecks.propTypes = { password: PropTypes.string };
PasswordChecks.defaultProps = { password: null };

NumberLetterMixCheck.propTypes = { password: PropTypes.string };
NumberLetterMixCheck.defaultProps = { password: null };

UpperLowerCaseMixCheck.propTypes = { password: PropTypes.string };
UpperLowerCaseMixCheck.defaultProps = { password: null };

SpecialCharacterMixCheck.propTypes = { password: PropTypes.string };
SpecialCharacterMixCheck.defaultProps = { password: null };
