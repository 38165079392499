import React from 'react';

import Tooltip from 'components/common/Tooltip';
import { ReactComponent as InfoIcon } from 'assets/svg/info-icon.svg';
import IconBoxAlert from 'components/views/Integrations/IconBox/IconBoxAlert';
import { MESSAGES } from 'constants/messages';

const InfoIconTrigger = () => <InfoIcon className="iconBox__infoIcon" />;

const IconBoxFeedback = () => (
  <Tooltip
    trigger={InfoIconTrigger}
    arrow={false}
    position="right center"
  >
    <div className="tooltip-alert tooltip-alert__integration--coming-soon">
      <IconBoxAlert>
        {MESSAGES.coming_soon}
      </IconBoxAlert>
    </div>
  </Tooltip>
);

export default IconBoxFeedback;
