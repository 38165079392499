import { useEffect } from 'react';
import chmln from '@chamaeleonidae/chmln';
import { useSelector } from 'react-redux';
import {
  getActiveIntegrationKeys,
  getCompletedIntegrationKeys,
  getCurrentLicense,
  getLicenseStatus,
} from 'store/license/getters';
import { getCurrentUser } from 'store/profile/getters';

export default () => {
  const currentUser = useSelector(getCurrentUser);
  const currentLicense = useSelector(getCurrentLicense);
  const activeIntegrationKeys = useSelector(getActiveIntegrationKeys);
  const completedIntegrationKeys = useSelector(getCompletedIntegrationKeys);
  const licenseStatus = useSelector(getLicenseStatus);

  useEffect(() => {
    if (!currentUser?.uuid) return;

    chmln.identify(currentUser.uuid, {
      first_name: currentUser.first_name,
      last_name: currentUser.last_name,
      email: currentUser.email,
      role: currentUser.role?.length ? currentUser.role[1] : null,
      role_key: currentUser.role?.length ? currentUser.role[0] : null,
      license_status: licenseStatus?.length ? licenseStatus[1] : '',
      license_status_key: licenseStatus?.length ? licenseStatus[0] : '',
      company: {
        uid: currentLicense.uuid,
        created: currentLicense.created_at,
        name: currentLicense.company_name,
        integrated_sources: activeIntegrationKeys.length ? activeIntegrationKeys : null,
        active_sources: completedIntegrationKeys.length ? completedIntegrationKeys : null,
      },
    });
  }, [
    currentUser, currentLicense, activeIntegrationKeys,
    completedIntegrationKeys, licenseStatus,
  ]);
};
