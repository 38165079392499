import { VIEWTYPE, SIDEBARTYPE } from 'constants/common';

export const getTemplateColumn = (viewType, defaultSideBar, isOpenedLarge) => {
  switch (true) {
    case viewType === VIEWTYPE.MOBILE:
      return '1fr';
    case viewType === VIEWTYPE.TABLET:
      return '65px repeat(11, 1fr)';
    case defaultSideBar === SIDEBARTYPE.SLIDE:
      return '65px repeat(11, 1fr)';
    case viewType === VIEWTYPE.LARGE && defaultSideBar === SIDEBARTYPE.MIN && !isOpenedLarge:
      return '220px repeat(11, 1fr)';
    case viewType === VIEWTYPE.LARGE && defaultSideBar === SIDEBARTYPE.MIN && isOpenedLarge:
      return '65px repeat(11, 1fr)';
    case viewType === VIEWTYPE.LARGE && defaultSideBar === SIDEBARTYPE.FULL && !isOpenedLarge:
      return '220px repeat(11, 1fr)';
    case viewType === VIEWTYPE.LARGE && defaultSideBar === SIDEBARTYPE.FULL && isOpenedLarge:
      return '65px repeat(11, 1fr)';
    default:
      return null;
  }
};
