import React, { useEffect, useState } from 'react';

import 'components/views/SingleSegments/styles.scss';
import Block from 'components/common/Block';
import TotalItem from 'components/common/TotalItem';
import useRouter from 'hooks/useRouter';
import { MostPopularCustomerSourceGraphic } from 'components/views/SingleSegments/MostPopularCustomerSourceGraphic';
import { getSegmentById, getSegmentGraphicById } from 'api/segmentsAPI';
import useAsync from 'hooks/useAsync';
import { REQUEST_STATUS } from 'constants/common';
import classNames from 'classnames';
import ContentLoader from 'components/common/ContentLoader';
import { checkHasIntegration } from 'utils/activeIntegrations';
import { useSelector } from 'react-redux';
import IntegrationRequired from 'components/common/IntegrationRequired/IntegrationRequired';
import { AVAILABLE_INTEGRATIONS, INTEGRATION_CATEGORIES } from 'components/views/Integrations/constants/Constants';
import { getActiveIntegrationsList } from 'store/license/getters';
import { MESSAGES } from 'constants/messages';
import { Box, Grid } from '@mui/material';
import { LinkButtonDropdown } from 'components/common/LinkButtonDropdown';
import { ListSelect } from 'components/common/ListSelect';
import { ListSelectItem } from 'components/common/ListSelect/ListSelectItem';
import { GUIDES } from 'constants/guides';
import { exportCustomers } from 'api/customersAPI';

const SingleSegments = () => {
  const { query } = useRouter();
  const [data, setData] = useState([]);
  const [graphicData, setGraphicData] = useState({});
  const activeIntegrationsList = useSelector(getActiveIntegrationsList);
  const [isLoading, setIsLoading] = useState(false);

  const loadSegmentData = async () => {
    setIsLoading(true);

    const segmentData = await getSegmentById({ id: query.id });
    setData(segmentData);

    if (!!segmentData.segment && !!segmentData.segment.id) {
      const segmentGraphic = await getSegmentGraphicById({ id: segmentData.segment.id });
      if (segmentGraphic) setGraphicData(segmentGraphic);
    }

    setIsLoading(false);
  };

  const {
    execute: loadSegmentDataAsync,
    status: loadSegmentDataStatus,
  } = useAsync(loadSegmentData);

  const exportFile = async (id, flag = null) => {
    await exportCustomers({
      segment: [id],
      filters: { accepts_marketing: !flag ? '' : flag.toString() },
    });
  };

  const requiredIntegration = AVAILABLE_INTEGRATIONS.GOOGLE_ANALYTICS;
  const isNotIntegrated = !checkHasIntegration(activeIntegrationsList, requiredIntegration);

  const getMostPopularCustomerSourceGraphic = () => (
    !!graphicData && !!graphicData.length
      ? <MostPopularCustomerSourceGraphic data={graphicData} />
      : (
        isNotIntegrated
          ? <IntegrationRequired requiredIntegration={requiredIntegration} />
          : (
            <div className="most-popular-service__empty-state empty-state">
              <p>{MESSAGES.no_activity_has_been_tracked}</p>
            </div>
          )
      ));

  useEffect(() => {
    if (query.id !== null) {
      loadSegmentDataAsync();
    }
  }, [query?.id]);

  return (
    <ContentLoader isLoading={loadSegmentDataStatus === REQUEST_STATUS.loading && isLoading}>
      {[REQUEST_STATUS.loading, REQUEST_STATUS.success].includes(loadSegmentDataStatus) && !isLoading && (
        <Grid className="single-segment">
          <Grid container spacing={2}>
            <Grid item container xs={15} columns={15} spacing={2}>
              <Grid item xs={15}>
                <Block
                  header={data.segment.segment_name}
                  postHeader={data.segment.description}
                  className="single-segment-header"
                  style={{ background: `linear-gradient(90deg, ${data.segment.color_1 || ''} 0%, ${data.segment.color_2 || ''} 100%)` }}
                  type="large-header"
                >
                  <Box display="flex" height="100%" justifyContent="flex-end" alignItems="flex-end">
                    <LinkButtonDropdown label={MESSAGES.export}>
                      <ListSelect>
                        <ListSelectItem
                          label={MESSAGES.all}
                          onClick={() => exportFile(data.segment.id)}
                        />
                        <ListSelectItem
                          label={MESSAGES.customer_consent}
                          onClick={() => exportFile(data.segment.id, true)}
                        />
                      </ListSelect>
                    </LinkButtonDropdown>
                  </Box>
                </Block>
              </Grid>
              <Grid item md={7.5} sm={15} xs={15}>
                <TotalItem
                  title={MESSAGES.customers}
                  guide={GUIDES.ISP1}
                  percentage={data.cus_num_last_month_percentage}
                  fillHeight={false}
                >
                  {data.cus_num}
                </TotalItem>
              </Grid>
              <Grid item md={7.5} sm={15} xs={15}>
                <TotalItem
                  guide={GUIDES.ISP2}
                  title={MESSAGES.orders}
                  percentage={data.ord_num_last_month_percentage}
                  fillHeight={false}
                >
                  {data.ord_num}
                </TotalItem>
              </Grid>
              <Grid item md={7.5} sm={15} xs={15}>
                <TotalItem
                  title={MESSAGES.total_sales}
                  guide={GUIDES.ISP3}
                  percentage={data.total_sales_last_month_percentage}
                  prefix={data.currency}
                  fillHeight={false}
                >
                  {data.total_sales}
                </TotalItem>
              </Grid>
              <Grid item md={7.5} sm={15} xs={15}>
                <TotalItem
                  title={MESSAGES.total_profit}
                  guide={GUIDES.ISP4}
                  percentage={data.total_profit_last_month_percentage}
                  prefix={data.currency}
                  fillHeight={false}
                >
                  {data.total_profit}
                </TotalItem>
              </Grid>
              <Grid item lg={5} md={7.5} xs={15}>
                <TotalItem
                  title={MESSAGES.repeat_purchasing_rate}
                  guide={GUIDES.ISP14}
                  percentage={data.repeat_purchasing_rate_last_month_percentage}
                  perc
                >
                  {data.repeat_purchasing_rate}
                </TotalItem>
              </Grid>
              <Grid item lg={5} md={7.5} xs={15}>
                <TotalItem
                  title={MESSAGES.organic_traffic}
                  guide={GUIDES.ISP12}
                  percentage={data.organic_percent_last_month_percentage}
                  requiredIntegration={AVAILABLE_INTEGRATIONS.GOOGLE_ANALYTICS}
                  perc
                >
                  {data.organic_percent}
                </TotalItem>
              </Grid>
              <Grid item lg={5} md={7.5} xs={15}>
                <TotalItem
                  title={MESSAGES.paid_traffic}
                  guide={GUIDES.ISP13}
                  percentage={data.paid_percent_last_month_percentage}
                  requiredIntegration={AVAILABLE_INTEGRATIONS.GOOGLE_ANALYTICS}
                  perc
                >
                  {data.paid_percent}
                </TotalItem>
              </Grid>
              <Grid item lg={5} md={7.5} xs={15}>
                <TotalItem
                  title={MESSAGES.order_value_avg}
                  guide={GUIDES.ISP5}
                  percentage={data.avg_order_size_last_month_percentage}
                  prefix={data.currency}
                >
                  {data.avg_order_size}
                </TotalItem>
              </Grid>
              <Grid item lg={5} md={7.5} xs={15}>
                <TotalItem
                  title={MESSAGES.orders_avg}
                  guide={GUIDES.ISP6}
                  percentage={data.avg_orders_last_month_percentage}
                >
                  {data.avg_orders}
                </TotalItem>
              </Grid>
              <Grid item lg={5} md={7.5} xs={15}>
                <TotalItem
                  title={MESSAGES.profit_avg}
                  guide={GUIDES.ISP7}
                  percentage={data.avg_profit_last_month_percentage}
                  prefix={data.currency}
                >
                  {data.avg_profit}
                </TotalItem>
              </Grid>
              <Grid item lg={5} md={7.5} xs={15}>
                <TotalItem
                  title={MESSAGES.sales}
                  guide={GUIDES.ISP8}
                  percentage={data.monthly_sales_last_month_percentage}
                  prefix={data.currency}
                  titleSuffix={MESSAGES.per_month}
                >
                  {data.monthly_sales}
                </TotalItem>
              </Grid>
              <Grid item lg={5} md={7.5} xs={15}>
                <TotalItem
                  title={MESSAGES.return_rate}
                  guide={GUIDES.ISP9}
                  percentage={data.return_rate_last_month_percentage}
                  perc
                  negative
                >
                  {data.return_rate}
                </TotalItem>
              </Grid>
              <Grid item lg={5} md={7.5} xs={15}>
                <TotalItem
                  title={MESSAGES.email_bounce_rate}
                  guide={GUIDES.ISP10}
                  requiredIntegrationCategory={INTEGRATION_CATEGORIES.EMAIL_MARKETING}
                  percentage={data.email_bounce_rate_last_month_percentage}
                  perc
                  coming_soon
                >
                  {data.email_bounce_rate}
                </TotalItem>
              </Grid>
              <Grid item xs={15}>
                <div className="most-popular-service">
                  <Block
                    postHeader={MESSAGES.most_popular_customer_source}
                    guide={GUIDES.ISP11}
                  >
                    <div className={classNames('most-popular-service-wrapper', { empty: !graphicData.length })}>
                      {getMostPopularCustomerSourceGraphic()}
                    </div>
                  </Block>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </ContentLoader>
  );
};

export default SingleSegments;
