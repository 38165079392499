import React from 'react';

import SearchEntity from 'components/common/SearchEntity';
import propTypes from 'prop-types';
import { MESSAGES } from 'constants/messages';
import { noop } from 'lodash';
import Button from 'components/common/Button';
import { BUTTON_TYPE } from 'constants/common';
import { ReactComponent as PlusIcon } from 'assets/svg/plus.svg';

export const ToggleSearchEntity = ({ isOpened, onClick }) => (
  <Button
    type={BUTTON_TYPE.secondary}
    onClick={onClick}
    isActive={isOpened}
    icon={PlusIcon}
  >
    {MESSAGES.add_filter}
  </Button>
);

ToggleSearchEntity.propTypes = {
  isOpened: propTypes.bool.isRequired,
  onClick: propTypes.func,
};

ToggleSearchEntity.defaultProps = {
  onClick: noop,
};

const TableSearchEntity = ({
  searchEntityConfig,
  tags,
  tagsChanged,
}) => (
  <SearchEntity
    config={searchEntityConfig}
    ToggleComponent={ToggleSearchEntity}
    selectedItems={tags}
    onItemSelect={(item) => tagsChanged([...tags, item])}
  />
);

TableSearchEntity.propTypes = {
  searchEntityConfig: propTypes.arrayOf(propTypes.shape({
    key: propTypes.string.isRequired,
    title: propTypes.string.isRequired,
  })),
  tags: propTypes.arrayOf(propTypes.shape({
    uuid: propTypes.string.isRequired,
    name: propTypes.string.isRequired,
    entityKey: propTypes.string.isRequired,
  })),
  tagsChanged: propTypes.func,
};

TableSearchEntity.defaultProps = {
  searchEntityConfig: null,
  tags: null,
  tagsChanged: null,
};

export default TableSearchEntity;
