import { createSlice } from '@reduxjs/toolkit';
import { getSegments as getSegmentsAPI } from 'api/segmentsAPI';

import moduleName from './constants';

const initialState = {
  segmentsData: {
    departed_customers: '',
    departed_customers_last_month_percentage: '',
    licence_segments: [],
    total_customers: 0,
    total_customers_last_month_percentage: 0,
  },
  isLoading: false,
  isLoaded: false,
  error: undefined,
};

const segmentsSlice = createSlice({
  name: moduleName,
  initialState,
  reducers: {
    getSegmentsStart(state) {
      state.isLoading = true;
      state.isLoaded = false;
    },
    getSegmentsFinish(state) {
      state.isLoading = false;
      state.isLoaded = true;
    },
    getSegmentsError(state, action) {
      const { error } = action.payload;
      state.isLoading = false;
      state.isLoaded = false;
      state.error = error;
    },
    getSegmentsAction(state, action) {
      state.segmentsData = action.payload;
    },
  },
});

export const {
  getSegmentsStart,
  getSegmentsFinish,
  getSegmentsError,
  getSegmentsAction,
} = segmentsSlice.actions;

export default segmentsSlice.reducer;

export const fetchSegments = () => async (dispatch) => {
  try {
    dispatch(getSegmentsStart());
    const result = await getSegmentsAPI();
    if (result) {
      dispatch(getSegmentsAction(result));
    }
  } catch (err) {
    dispatch(getSegmentsError({ error: err.message }));
  } finally {
    dispatch(getSegmentsFinish());
  }
};
