import { http } from '../utils';
import { ENDPOINTS } from 'constants/routes';
import { HTTP_METHODS } from 'constants/http';

export const getAvailableIntegrationsSources = (search = '') => http({
  url: `${ENDPOINTS.getAvailableIntegrationsSources}?search=${search}`,
  method: HTTP_METHODS.GET,
});

export const getChannels = (search = '') => http({
  url: `${ENDPOINTS.getChannels}?search=${search}`,
  method: HTTP_METHODS.GET,
});

export const getActiveIntegrationsSources = () => http({
  url: ENDPOINTS.getActiveIntegrationsSources,
  method: HTTP_METHODS.GET,
});

export const updateIntegration = ({ integrationUUID, data }) => http({
  url: `${ENDPOINTS.updateIntegration}${integrationUUID}/`,
  method: HTTP_METHODS.PATCH,
  data,
});

export const updateOAuthIntegration = ({ integrationUUID, data }) => http({
  url: `${ENDPOINTS.updateOAuthIntegration}${integrationUUID}/`,
  method: HTTP_METHODS.PATCH,
  data,
});

export const deleteActiveIntegration = ({ integrationUUID }) => http({
  url: `${ENDPOINTS.deleteActiveRegistration}${integrationUUID}/`,
  method: HTTP_METHODS.DELETE,
});

export const getRedirectUrl = ({ url }) => http({
  url: `${ENDPOINTS.getRedirectUrl}?url=${url}`,
  method: HTTP_METHODS.GET,
});
