import React, { useEffect, useState } from 'react';

import './styles.scss';
import SearchEntityPopover from 'components/common/SearchEntity/SearchEntityPopover';
import { SEARCH_POPOVER_POSITION } from 'components/common/SearchEntity/constants';
import Popover from '@mui/material/Popover';
import { noop } from 'lodash';
import PropTypes from 'prop-types';

const SearchEntity = ({
  config,
  ToggleComponent,
  position,
  selectedItems,
  onItemSelect,
  isInContainer,
  onOptionHover,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpened = !!anchorEl;
  const [selectedItemsSet, setSelectedItemsSet] = useState(new Set());
  const [anchorOrigin, setAnchorOrigin] = useState({});
  const [transformOrigin, setTransformOrigin] = useState({});

  useEffect(() => {
    const itemsKeys = selectedItems.map((item) => item.entityKey + item.uuid);
    setSelectedItemsSet(new Set(itemsKeys));
  }, [selectedItems]);

  useEffect(() => {
    switch (position) {
      case SEARCH_POPOVER_POSITION.bottom:
        setAnchorOrigin({ vertical: 'bottom', horizontal: 'left' });
        setTransformOrigin({ vertical: 'top', horizontal: 'left' });
        break;
      case SEARCH_POPOVER_POSITION.top:
        setAnchorOrigin({ vertical: 'top', horizontal: 'center' });
        setTransformOrigin({ vertical: 'bottom', horizontal: 'center' });
        break;
    }
  }, [position]);

  const handleOptionSelected = (entity, option) => {
    const newItem = {
      uuid: option.uuid,
      name: option.name,
      entityKey: entity.key,
    };
    onItemSelect(newItem);
    setAnchorEl(null);
  };

  return (
    <div
      className={isInContainer ? 'search-entity-incontainer' : 'search-entity'}
    >
      <ToggleComponent
        isOpened={isOpened}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      />
      <Popover
        open={isOpened}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        classes={{ paper: 'search-entity__popover', root: 'search-entity__popover-root' }}
        disableAutoFocus
        disableEnforceFocus
      >
        <SearchEntityPopover
          config={config}
          onOptionSelected={handleOptionSelected}
          selectedItemsSet={selectedItemsSet}
          position={position}
          isInContainer={isInContainer}
          onOptionHover={onOptionHover}
        />
      </Popover>
    </div>
  );
};

SearchEntity.propTypes = {
  config: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  })).isRequired,
  position: PropTypes.string,
  ToggleComponent: PropTypes.elementType.isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.shape({
    uuid: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    name: PropTypes.string.isRequired,
    entityKey: PropTypes.string.isRequired,
  })).isRequired,
  onItemSelect: PropTypes.func.isRequired,
  isInContainer: PropTypes.bool,
  onOptionHover: PropTypes.func,
};

SearchEntity.defaultProps = {
  position: SEARCH_POPOVER_POSITION.bottom,
  isInContainer: false,
  onOptionHover: noop,
};

export default SearchEntity;
