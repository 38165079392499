import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

import TotalItem from 'components/common/TotalItem';

const SingleProductItem = ({ options }) => (
  <div className="single-product-item-container">
    <Grid container spacing={2}>
      {options.map((option) => (
        <Grid item lg={4} sm={6} xs={6} key={option?.title}>
          <TotalItem
            title={option?.title}
            percentage={option?.percentage}
            prefix={option?.prefix}
            perc={option?.perc}
            titleSuffix={option?.titleSuffix}
            isPrevious={option?.isPrevious}
            hasPrevValue={option?.hasPrevValue}
            guide={option?.guide}
          >
            {option?.value}
          </TotalItem>
        </Grid>
      ))}
    </Grid>
  </div>
);

SingleProductItem.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.any,
    ]),
  ),
};

SingleProductItem.defaultProps = {
  options: undefined,
};

export default SingleProductItem;
