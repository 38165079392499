import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import './styles.scss';
import { MESSAGES } from 'constants/messages';
import { ReactComponent as OkIcon } from 'assets/svg/ok.svg';

const TooltipCopy = ({ value, info, children }) => {
  const [showCopied, setShowCopied] = useState(false);

  const copyToClipboard = (event) => {
    navigator.clipboard.writeText(value);
    setShowCopied(true);
    setTimeout(() => setShowCopied(false), 1000);
    event.stopPropagation();
  };

  // the trick with <div key={...}> is used to trigger react to rerender react tooltip layout
  return (
    <div
      key={showCopied ? 'tooltip__wrapper__copied' : 'tooltip__wrapper__regular'}
      tabIndex="-1"
      className="tooltip-copy-container"
    >
      <div
        role="button"
        tabIndex="-1"
        className="tooltip__wrapper"
        data-tip
        data-for={value}
        onClick={copyToClipboard}
      >
        {children}
      </div>
      <ReactTooltip className="tooltip__wrapper__tooltip" id={value} type="light" effect="solid" delayHide={50}>
        {showCopied ? (
          <div className="tooltip__wrapper__tooltip__copied">
            {MESSAGES.copied}
            <OkIcon className="tooltip__wrapper__icon" />
          </div>
        ) : (
          <span>{info}</span>
        )}
      </ReactTooltip>
    </div>
  );
};

export default TooltipCopy;

TooltipCopy.propTypes = {
  value: PropTypes.string,
  info: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.any]),
};

TooltipCopy.defaultProps = {
  value: undefined,
  info: undefined,
  children: null,
};
