import { useCallback, useEffect, useState } from 'react';
import { http } from 'utils';
import { HTTP_METHODS } from 'constants/http';
import useAsync from 'hooks/useAsync';
import { convertedRoleOptions } from 'utils/common';

export default (roleEndpoint, responseField) => {
  const [options, setOptions] = useState([]);

  const loadRoles = useCallback(() => http({
    url: roleEndpoint,
    method: HTTP_METHODS.GET,
    useToken: true,
  }), [roleEndpoint]);

  const {
    execute: loadRolesAsync, status, error, value,
  } = useAsync(loadRoles);

  useEffect(() => {
    loadRolesAsync();
  }, []);

  useEffect(() => {
    if (!value) {
      return;
    }

    setOptions(convertedRoleOptions(value[responseField]));
  }, [value]);

  return { options, status, error };
};
