import React from 'react';
import propTypes from 'prop-types';

import 'containers/PrivateApp/SwitchLicense/LicenseTable/styles.scss';
import LicenseTableItem from './LicenseTableItem';
import { MESSAGES } from 'constants/messages';
import Grid from '@mui/material/Grid';

const LicenseTable = ({ licenses, onSelect }) => {
  const onSelectHandler = (selectedLicense) => {
    onSelect(selectedLicense);
  };

  return (
    <table className="license-table">
      <Grid container>
        <thead className="license-table__header">
          <Grid item xs={12} sx={{ display: 'flex', padding: { sm: '0 20px', xs: '0px' } }}>
            <th className="license-table__header-item license-table__header--license">
              <Grid item xs={12} sx={{ paddingLeft: { sm: '72px', xs: '0px' } }}>
                <span className="license-table__header-text--license">{MESSAGES.license}</span>
              </Grid>
            </th>
            <th className="license-table__header-item license-table__header--status">
              <span className="license-table__header-text--status">{MESSAGES.status}</span>
            </th>
          </Grid>
        </thead>
        <tbody className="license-table__body">
          <Grid item xs={12} sx={{ padding: { sm: '20px', xs: '20px 0px' } }}>
            {

              licenses.map((license) => (
                <LicenseTableItem
                  key={license.uuid}
                  license={license}
                  company={license.license}
                  status={license.license_status[1]}
                  onSelect={onSelectHandler}
                />
              ))
          }
          </Grid>
        </tbody>
      </Grid>
    </table>
  );
};

LicenseTable.propTypes = {
  licenses: propTypes.arrayOf(propTypes.oneOfType([propTypes.object])).isRequired,
  onSelect: propTypes.func.isRequired,
};

export default LicenseTable;
