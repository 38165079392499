import React from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import 'components/common/MonthPicker/styles.scss';
import 'react-datepicker/dist/react-datepicker.css';
import { DefaultDisplay, NavigationDisplay } from 'components/common/MonthPicker/Displays';

const addMonths = (date, months) => {
  const dateCopy = new Date(date);
  dateCopy.setMonth(dateCopy.getMonth() + months);
  return dateCopy;
};

const MonthPicker = ({
  value, type, onChange, minDate, maxDate, withNavigation,
}) => {
  const DisplayComponent = (withNavigation ? NavigationDisplay : DefaultDisplay);

  const prevDisabled = Boolean(minDate && (minDate > addMonths(value, -1)));
  const nextDisabled = Boolean(maxDate && (maxDate < addMonths(value, 1)));

  const onPrevClick = () => onChange(addMonths(value, -1));
  const onNextClick = () => onChange(addMonths(value, 1));

  return (
    <div className="date-selector">
      <DatePicker
        selected={value}
        onChange={onChange}
        dateFormat="MMM yyyy"
        minDate={minDate}
        maxDate={maxDate}
        showPopperArrow={false}
        showMonthYearPicker
        showDisabledMonthNavigation
        customInput={(
          <DisplayComponent
            type={type}
            prevDisabled={prevDisabled}
            onPrevClick={onPrevClick}
            nextDisabled={nextDisabled}
            onNextClick={onNextClick}
          />
        )}
      />
    </div>
  );
};

export default MonthPicker;

MonthPicker.propTypes = {
  value: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['primary', 'secondary']),
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  withNavigation: PropTypes.bool,
};

MonthPicker.defaultProps = {
  value: null,
  type: 'secondary',
  minDate: null,
  maxDate: null,
  withNavigation: false,
};
