import { MESSAGES } from 'constants/messages';
import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const LastMonthLabel = ({ isPrevious }) => (
  <span className="from-last-month">
    {(MESSAGES.from).toLowerCase()}
    {isPrevious
      ? <span>{(MESSAGES.previous_30_days).toLowerCase()}</span>
      : <span>{(MESSAGES.last_month).toLowerCase()}</span>}
  </span>
);

export default LastMonthLabel;

LastMonthLabel.propTypes = {
  isPrevious: PropTypes.bool,
};

LastMonthLabel.defaultProps = {
  isPrevious: false,
};
