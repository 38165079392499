import { MESSAGES } from 'constants/messages';
import React from 'react';
import EmptyTemplate from 'components/views/Homepage/EmptyStates/EmptyTemplate';

const PullingDataState = () => (
  <div className="empty-state-wrapper">
    <EmptyTemplate>
      <span>
        {MESSAGES.process_can_take}
        <strong>{MESSAGES.up_to_24_hours}</strong>
        {' '}
        <br />
        {' '}
        {MESSAGES.return_soon_to_see_dashboard}
      </span>
    </EmptyTemplate>
  </div>
);

export default PullingDataState;
