import React from 'react';

import 'components/views/Profile/styles.scss';
import Block from 'components/common/Block';
import PersonalBlock from 'components/views/Profile/PersonalBlock';
import PasswordBlock from 'components/views/Profile/PasswordBlock';
import { MESSAGES } from 'constants/messages';
import Grid from '@mui/material/Grid';

const Profile = () => (
  <div className="profile">
    <Grid container spacing={2}>
      <Grid item xl={7} xs={12}>
        <Block fillHeight header={MESSAGES.my_profile}>
          <PersonalBlock editable />
        </Block>
      </Grid>
      <Grid item xl={5} xs={12}>
        <Block header={MESSAGES.my_password}>
          <PasswordBlock password={MESSAGES.password_example} editable />
        </Block>
      </Grid>
    </Grid>
  </div>
);

export default Profile;
