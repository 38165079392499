import { useEffect, useState } from 'react';
import { getCohortData } from 'api/cohortsAPI';
import { isNil, values, get } from 'lodash';
import { MESSAGES } from 'constants/messages';
import { useToast } from 'components/common/Toast';

export default (cohortId, excludeFirst, displayMetric) => {
  const { createToast } = useToast();

  const [isLoading, setIsLoading] = useState(false);
  const [cohortData, setCohortData] = useState([]);
  const [cohortAverages, setCohortAverages] = useState([]);
  const [cohortRows, setCohortRows] = useState([]);
  const [maxMonthsSince, setMaxMonthsSince] = useState(0);
  const [minValue, setMinValue] = useState(null);
  const [maxValue, setMaxValue] = useState(null);
  const [currency, setCurrency] = useState();

  const fetchCohortData = async () => {
    setIsLoading(true);
    getCohortData(cohortId).then((data) => {
      setCohortAverages(data.averages);
      setCohortData(data.values);
      setIsLoading(false);
    }).catch(() => {
      setIsLoading(false);
      setCohortAverages([]);
      setCohortData([]);

      createToast(
        MESSAGES.failed,
        MESSAGES.failed_to_load_cohort,
        'danger',
        'cohort-failed-to-load',
      );
    });
  };

  useEffect(() => {
    if (!cohortId) return;
    fetchCohortData();
  }, [cohortId]);

  const getAveragesRow = () => {
    const row = {
      name: MESSAGES.average,
    };
    cohortAverages.forEach((monthAverage) => {
      row[`month_${monthAverage.months_since}`] = monthAverage.value;
    });
    return row;
  };

  const formatCohortRows = () => {
    const rows = {};
    let newMaxMonthsSince = 0;
    let newMinValue = null;
    let newMaxValue = null;
    const rowsInfo = {};

    cohortData.forEach((interval) => {
      const rowInfo = rowsInfo[interval.date] || {
        date: interval.date,
        maxMonthsSince: 0,
        customers_count: 0,
        predictiveFromMonth: null,
      };

      if (interval.months_since > rowInfo.maxMonthsSince) {
        rowInfo.maxMonthsSince = interval.months_since;
      }
      if (interval.months_since === 0) {
        rowInfo.customers_count = interval.customers_count;
      }
      if (interval.is_predictive && (interval.months_since < rowInfo.predictiveFromMonth || isNil(rowInfo.predictiveFromMonth))) {
        rowInfo.predictiveFromMonth = interval.months_since;
      }
      rowsInfo[interval.date] = rowInfo;
    });

    cohortData.forEach((interval) => {
      if (excludeFirst && interval.months_since === 0) return;
      if (interval.months_since > newMaxMonthsSince) newMaxMonthsSince = interval.months_since;
      if (newMaxValue === null || interval.value > newMaxValue) newMaxValue = interval.value;
      if (newMinValue === null || interval.value < newMinValue) newMinValue = interval.value;

      const row = rows[interval.date]
        || {
          date: interval.date,
          customers_count: rowsInfo[interval.date].customers_count,
          predictiveFromMonth: rowsInfo[interval.date].predictiveFromMonth,
          listed: [],
        };

      row.listed.push(interval.value);
      row[`month_${interval.months_since}`] = interval.value;
      rows[interval.date] = row;
    });

    if (cohortAverages.length > 0) {
      rows.averages = getAveragesRow();
    }

    const newCurrency = (get(displayMetric, 'value') !== 'num_orders' ? get(cohortData, [0, 'currency']) : null);

    setMaxMonthsSince(newMaxMonthsSince);
    setMinValue(newMinValue);
    setMaxValue(newMaxValue);
    setCohortRows(values(rows));
    setCurrency(newCurrency);
  };

  useEffect(() => {
    formatCohortRows();
  }, [cohortData, excludeFirst]);

  const reloadCohort = () => {
    if (!cohortId) return;
    fetchCohortData();
  };

  return {
    maxMonthsSince,
    maxValue,
    minValue,
    cohortRows,
    currency,
    reloadCohort,
    isLoading,
  };
};
