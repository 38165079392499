import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ReactComponent as ChannelErrorIcon } from 'assets/svg/channel-error.svg';
import { ReactComponent as ChannelPushingIcon } from 'assets/svg/channel-loading.svg';
import { ReactComponent as ChannelCheckIcon } from 'assets/svg/check.svg';
import { MESSAGES } from 'constants/messages';
import 'components/views/CustomAudiences/ActivationCells/styles.scss';
import StatusSwitch from 'components/common/StatusSwitch';
import { get } from 'lodash';
import classNames from 'classnames';
import { getAssetSourceImage } from 'utils/assets';
import { NameCell, OverflowListCell } from 'components/common/CommonTableCells';
import InfoTooltip from 'components/common/InfoTooltip';
import { AUDIENCE_TYPES } from 'constants/customAudience';
import Chip from 'components/common/Chip';

export const SizeCell = ({ column, row }) => (
  <span className="activation__cell__size">
    {row[column.key] || 0}
  </span>
);

SizeCell.propTypes = {
  column: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  row: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

export const ActivationDateCell = ({ column, row }) => {
  const lastSynchronizedAt = row[column.key] && moment(row[column.key]).format('DD/MM/YY');
  return (
    <span className="activation__cell__last_synchronized">
      {lastSynchronizedAt}
    </span>
  );
};

ActivationDateCell.propTypes = SizeCell.propTypes;

export const StatusCell = ({ column, row }) => (
  <div className={`activation__cell__status__${row[column.key] ? 'active' : 'inactive'}`}>
    {row[column.key] ? MESSAGES.active : MESSAGES.inactive}
  </div>
);

StatusCell.propTypes = SizeCell.propTypes;

export const StatusSwitchCell = ({ row, updateAudience }) => (
  <div
    className="activation__cell__status-switch"
    onClick={(e) => e.stopPropagation()}
  >
    <StatusSwitch
      checked={row.is_active}
      onChange={(value) => updateAudience(row, value)}
    />
  </div>
);

StatusSwitchCell.propTypes = {
  ...SizeCell.propTypes,
  updateAudience: PropTypes.func.isRequired,
};

export const CreatorCell = ({ column, row }) => {
  const firstName = row[column.key] && row[column.key].first_name;
  const lastName = row[column.key] && row[column.key].last_name;
  return (
    <div className="activation__cell__creator">
      {firstName}
      {' '}
      {lastName}
    </div>
  );
};

CreatorCell.propTypes = {
  column: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  row: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

const CHANNELS_DISPLAY_LIMIT = 4;

const STATUS_INFO = {
  PUSHING: {
    info: 'Audience update in progress',
    icon: ChannelPushingIcon,
  },
  ERROR: {
    info: 'Failed to update audience to channel',
    icon: ChannelErrorIcon,
  },
  OK: {
    info: 'Successfully updated',
    icon: ChannelCheckIcon,
  },
};

export const ChannelsCell = ({ column, row, channelsMap }) => {
  const data = row[column.key];

  const ItemComponent = ({ item }) => {
    const { integration, status } = item;
    const source = get(channelsMap, [integration, 'source']);

    const StatusIcon = row.is_active ? STATUS_INFO[status]?.icon : undefined;

    return (
      <div className="table__cell__channels__icon-container">
        <InfoTooltip text={source.name} placement="top" disablePropagation>
          <img
            className={classNames('table__cell__channels__icon-container__icon', {
              'table__cell__channels__icon-container__icon-blurred': status !== 'OK',
            })}
            src={getAssetSourceImage(source.key, true)}
            alt={source.name}
          />
        </InfoTooltip>

        {!!status && StatusIcon && (
          <InfoTooltip
            text={STATUS_INFO[status].info}
            enterTouchDelay={0}
            placement="top"
            disablePropagation
          >
            <StatusIcon className="table__cell__channels__icon-container__status" />
          </InfoTooltip>
        )}
      </div>
    );
  };

  ItemComponent.propTypes = {
    item: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  };

  return (
    <div className="table__cell__channels">
      {channelsMap && (
        <OverflowListCell
          ItemComponent={ItemComponent}
          data={data}
          limit={CHANNELS_DISPLAY_LIMIT}
        />
      )}
    </div>
  );
};

ChannelsCell.propTypes = {
  ...NameCell.propTypes,
  channelsMap: PropTypes.objectOf(PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    source: PropTypes.oneOfType([PropTypes.object]).isRequired,
  })),
  row: PropTypes.shape({
    is_active: PropTypes.bool.isRequired,
  }).isRequired,
};

ChannelsCell.defaultProps = {
  channelsMap: null,
};

const typeChips = {
  [AUDIENCE_TYPES.segment]: {
    title: MESSAGES.segment,
    color: 'green',
  },
  [AUDIENCE_TYPES.product]: {
    title: MESSAGES.product,
    color: 'blue',
  },
  [AUDIENCE_TYPES.category]: {
    title: MESSAGES.category,
    color: 'yellow',
  },
};

export const AudienceTypeCell = ({ column, row }) => {
  const chipConfig = typeChips[row[column.key]];
  return (
    <div className="activation__cell__audience-type">
      <Chip
        label={chipConfig.title}
        color={chipConfig.color}
        size="large"
      />
    </div>
  );
};
AudienceTypeCell.propTypes = {
  column: PropTypes.shape({
    key: PropTypes.string,
  }).isRequired,
  row: PropTypes.object.isRequired,
};
