import { http } from 'utils';
import { ENDPOINTS } from 'constants/routes';
import { HTTP_METHODS } from 'constants/http';
import { ENTITY_CREATOR } from 'constants/entities';
import { httpFile } from 'utils/file';

const prepareTagsParams = (tags) => {
  const userUuids = tags
    .filter(({ entityKey }) => entityKey === ENTITY_CREATOR)
    .map(({ uuid }) => uuid);

  return `&creator_uuid__in=${userUuids.join(',')}`;
};

export const getExperiment = (uuid) => http({
  url: `${ENDPOINTS.experiments}${uuid}/`,
  method: HTTP_METHODS.GET,
});

export const getExperimentsList = ({
  page = 1, page_size = '', ordering, search = '', tags = [],
}) => http({
  url: `${ENDPOINTS.experiments}?page=${page}&page_size=${page_size}&ordering=${ordering}&search=${search}${prepareTagsParams(tags)}`,
  method: HTTP_METHODS.GET,
});

export const createExperiment = (data) => http({
  url: ENDPOINTS.experiments,
  method: HTTP_METHODS.POST,
  data,
});

export const deleteExperiment = (uuid) => http({
  url: `${ENDPOINTS.experiments}${uuid}/`,
  method: HTTP_METHODS.DELETE,
});

export const updateExperiment = (uuid, data) => http({
  url: `${ENDPOINTS.experiments}${uuid}/`,
  method: HTTP_METHODS.PATCH,
  data,
});

export const getExperimentResults = ({
  uuid,
  metric = 'num_orders',
  groupBy = 'day',
  isCumulative = true,
  averagedBy = null,
  fromDate = null,
  toDate = null,
}) => {
  let url = `${ENDPOINTS.experimentData.replace(':uuid', uuid)}/?metric=${metric}&group_by=${groupBy}`;
  if (isCumulative) url += '&is_cumulative=1';
  if (averagedBy) url += `&averaged_by=${averagedBy}`;
  if (fromDate) url += `&from_date=${fromDate}`;
  if (toDate) url += `&to_date=${toDate}`;

  return http({
    url,
    method: HTTP_METHODS.GET,
  });
};

export const exportExperimentGroup = (uuid) => httpFile({
  url: ENDPOINTS.exportExperimentGroup.replace(':uuid', uuid),
  method: HTTP_METHODS.GET,
});

export const getExperimentDataBoundaries = (uuid) => http({
  url: ENDPOINTS.experimentDataBoundaries.replace(':uuid', uuid),
  method: HTTP_METHODS.GET,
});
