export const LIST_API_REDUX_KEYS = {
  adminPanelUsers: 'adminPanelUsers',
  adminPanelSuperUsers: 'adminPanelSuperUsers',
  adminPanelBundles: 'adminPanelBundles',
  adminPanelLicenses: 'adminPanelLicenses',
  cohorts: 'cohorts',
  experiments: 'experiments',
  customAudiences: 'customAudiences',
  products: 'products',
  productVariants: 'productVariants',
  productsRecommendations: 'productsRecommendations',
  productCategories: 'productCategories',
  productsCategoriesRecommendations: 'productsCategoriesRecommendations',
  categoryProducts: 'categoryProducts',
};

export const NON_LICENSE_LIST_API_KEYS = [
  LIST_API_REDUX_KEYS.adminPanelLicenses,
  LIST_API_REDUX_KEYS.adminPanelUsers,
  LIST_API_REDUX_KEYS.adminPanelBundles,
  LIST_API_REDUX_KEYS.adminPanelSuperUsers,
];
