import React from 'react';
import propTypes from 'prop-types';

import 'components/views/Settings/LicenseSettings/LicenseMembers/LicenseMembersTable/LicenseMembersTableItem/styles.scss';
import { ReactComponent as InvitationIcon } from 'assets/svg/invitationMail.svg';
import { ReactComponent as SuccessAlertIcon } from 'assets/svg/successAlert.svg';
import { MESSAGES } from 'constants/messages';

const ReInviteAlert = ({ isSuccess, children }) => (
  <div className="alert alert--invitation">
    <div className="alert__content">
      {
      isSuccess
        ? MESSAGES.invitation_has_been_sent
        : children
}
    </div>
    {
      isSuccess
        ? <SuccessAlertIcon className="alert__help-icon--invitation" />
        : <InvitationIcon className="alert__help-icon--invitation" />
    }
  </div>
);

ReInviteAlert.propTypes = {
  isSuccess: propTypes.bool.isRequired,
  children: propTypes.string.isRequired,
};

export default ReInviteAlert;
