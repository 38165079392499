import { http } from 'utils';
import { ENDPOINTS } from 'constants/routes';
import { HTTP_METHODS } from 'constants/http';

export const getSuperUsersList = ({
  page, ordering, search,
}) => http({
  url: `${ENDPOINTS.superUser}?page=${page}&ordering=${ordering}&search=${search}`,
  method: HTTP_METHODS.GET,
});

export const inviteSuperUser = ({ email }) => http({
  url: `${ENDPOINTS.superUser}`,
  method: HTTP_METHODS.POST,
  data: { email },
});

export const deleteSuperUser = (uuid) => http({
  url: `${ENDPOINTS.superUser}${uuid}/`,
  method: HTTP_METHODS.DELETE,
});

export const changeSuperUserPermission = (uuid, permission) => http({
  url: `${ENDPOINTS.superUser}${uuid}/`,
  method: HTTP_METHODS.PATCH,
  data: { permission },
});

export const getSuperUserLicenseAccessList = (uuid, ordering) => http({
  url: `${ENDPOINTS.superUser}${uuid}/license-access?ordering=${ordering}`,
  method: HTTP_METHODS.GET,
});

export const changeSuperUserLicenseAccess = (uuid, license_uuids) => http({
  url: `${ENDPOINTS.superUser}${uuid}/`,
  method: HTTP_METHODS.PATCH,
  data: { license_uuids },
});
