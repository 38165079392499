import React from 'react';
import 'components/common/AdvancedFilterTag/AdvancedFilterTagField/styles.scss';
import PropTypes from 'prop-types';
import SearchEntity from 'components/common/SearchEntity';
import Select from 'components/common/Select';
import { noop } from 'lodash';
import { MESSAGES } from 'constants/messages';

const AdvancedFilterTagField = ({
  field, fieldChanged, searchEntityConfig,
}) => {
  const ToggleSearchEntity = ({ onClick }) => (
    <div
      className="products-filter-tag-field__toogle"
      onClick={onClick}
    >
      <Select
        name="filter-tag-field"
        label={MESSAGES.variable}
        value={field}
        onChange={() => undefined}
        onBlur={() => undefined}
        options={[field]}
        isSearchable={false}
        openMenuOnClick={false}
        isFixedLabel
      />
    </div>
  );

  return (
    <SearchEntity
      config={searchEntityConfig}
      ToggleComponent={ToggleSearchEntity}
      onItemSelect={(item) => fieldChanged(item)}
      selectedItems={[]}
    />
  );
};

AdvancedFilterTagField.propTypes = {
  field: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.string.isRequired,
  }),
  searchEntityConfig: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  })).isRequired,
  fieldChanged: PropTypes.func,
};

AdvancedFilterTagField.defaultProps = {
  fieldChanged: noop,
  field: null,
};

export default AdvancedFilterTagField;
