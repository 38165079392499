import React from 'react';
import { useSelector } from 'react-redux';
import { ROUTES } from 'constants/routes';
import propTypes from 'prop-types';
import { getIsSuperUser } from 'store/profile/getters';
import PrivateRoute from 'components/common/ProtectedRoutes/PrivateRoute';

const SuperUserRoute = ({ component: Component, ...rest }) => {
  const isSuperUser = useSelector(getIsSuperUser);

  return (
    <PrivateRoute
      hasPermission={isSuperUser}
      component={Component}
      redirectToFunc={() => ({ pathname: ROUTES.home })}
      {...rest}
    />
  );
};

export default SuperUserRoute;

SuperUserRoute.propTypes = {
  component: propTypes.func.isRequired,
};
