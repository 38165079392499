import { http } from 'utils';
import { ENDPOINTS } from 'constants/routes';
import { HTTP_METHODS } from 'constants/http';
import { ENTITY_CHANNEL, ENTITY_SEGMENT } from 'constants/entities';

const prepareTagsParams = (tags) => {
  const channelUuids = tags
    .filter(({ entityKey }) => entityKey === ENTITY_CHANNEL)
    .map(({ uuid }) => uuid);

  const segmentUuids = tags
    .filter(({ entityKey }) => entityKey === ENTITY_SEGMENT)
    .map(({ uuid }) => uuid);

  return `&integration_uuid__in=${channelUuids.join(',')}&segment_ids__in=${segmentUuids.join(',')}`;
};

export const getCustomAudiencesList = ({
  page = 1, page_size = '', ordering, search = '', tags = [],
}) => http({
  url: `${ENDPOINTS.customAudiences}?page=${page}&page_size=${page_size}&ordering=${ordering}&search=${search}${prepareTagsParams(tags)}`,
  method: HTTP_METHODS.GET,
});

export const getCustomAudience = (uuid) => http({
  url: `${ENDPOINTS.customAudiences}${uuid}/`,
  method: HTTP_METHODS.GET,
});

export const createCustomAudience = (data) => http({
  url: ENDPOINTS.customAudiences,
  method: HTTP_METHODS.POST,
  data,
});

export const updateCustomAudience = (uuid, data) => http({
  url: `${ENDPOINTS.customAudiences}${uuid}/`,
  method: HTTP_METHODS.PUT,
  data,
});

export const deleteCustomAudience = (uuid) => http({
  url: `${ENDPOINTS.customAudiences}${uuid}/`,
  method: HTTP_METHODS.DELETE,
});
