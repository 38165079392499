import React, { useState } from 'react';
import CheckBoxBlock from 'components/common/Table/TableColumnSelector/CheckBoxBlock';
import { Popover, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import Button from 'components/common/Button';
import { MESSAGES } from 'constants/messages';
import { BUTTON_TYPE } from 'constants/common';
import { ReactComponent as ColumnSelectionIcon } from 'assets/svg/columnSelection.svg';
import './styles.scss';
import Table from 'components/common/Table/index';
import { noop } from 'lodash';

export const TableColumnSelector = ({
  tableConfig, maxColumnsNum, isChecked, onCheckBoxChange, onReset,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpened = !!anchorEl;

  return (
    <>
      <Button
        type={BUTTON_TYPE.secondary}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        icon={ColumnSelectionIcon}
      >
        {MESSAGES.change_view}
      </Button>
      <Popover
        open={isOpened}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <CheckBoxBlock
          tableConfig={tableConfig}
          maxColumnsNum={maxColumnsNum}
          onReset={onReset}
          isChecked={isChecked}
          onCheckBoxChange={onCheckBoxChange}
        />
      </Popover>
    </>
  );
};

TableColumnSelector.propTypes = {
  tableConfig: Table.propTypes.tableConfig,
  maxColumnsNum: PropTypes.number.isRequired,
  isChecked: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.bool])).isRequired,
  onCheckBoxChange: PropTypes.func,
  onReset: PropTypes.func,
};

TableColumnSelector.defaultProps = {
  onCheckBoxChange: noop,
  onReset: noop,
};
