import React from 'react';
import ReactPaginate from 'react-paginate';
import propTypes from 'prop-types';

import './styles.scss';
import FirstPageButton from './FirstPageButton';
import PrevPageButton from './PrevPageButton';
import NextPageButton from './NextPageButton';
import LastPageButton from './LastPageButton';

const Pagination = ({
  page, maxPage, pageChanged,
}) => {
  const changePageHandler = ({ selected }) => pageChanged(selected + 1);
  const goToPrevPageHandler = () => pageChanged(Math.max(page - 1, 1));
  const goToNextPageHandler = () => pageChanged(Math.min(page + 1, maxPage));
  const firstPageHandler = () => pageChanged(1);
  const lastPageHandler = () => pageChanged(maxPage);

  return (
    <div className="pagination">
      <FirstPageButton onClick={firstPageHandler} disabled={+page === 1} />
      <ReactPaginate
        previousLabel={<PrevPageButton onClick={goToPrevPageHandler} />}
        nextLabel={<NextPageButton onClick={goToNextPageHandler} />}
        onPageChange={changePageHandler}
        breakLabel="..."
        pageCount={maxPage}
        forcePage={page - 1}
        containerClassName="pagination__container"
        activeLinkClassName="pagination__item--active"
        pageClassName="pagination__item"
        pageLinkClassName="pagination__item-link"
        breakClassName="pagination__item"
        breakLinkClassName="pagination__item-link"
        previousClassName="pagination__prev"
        nextClassName="pagination__next"
        disabledClassName="pagination__button--disable"
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
      />
      <LastPageButton onClick={lastPageHandler} disabled={+page === +maxPage} />
    </div>
  );
};

Pagination.propTypes = {
  page: propTypes.number.isRequired,
  maxPage: propTypes.number.isRequired,
  pageChanged: propTypes.func.isRequired,
};

export default Pagination;
