import React from 'react';
import PropTypes from 'prop-types';
import 'components/common/Charts/styles.scss';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { cutThousands } from 'utils/common';
import {
  setAxisMinMaxValues, updateChartAxisPrefix, updateChartToolTipPrefix,
} from 'components/common/Charts/utils/helper';
import { currentMonthChartColor } from 'components/views/Homepage/KpiHistoryDataBlock/const';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

const LineChart = ({
  dataSet, prefixConfig, isMostRecent,
}) => {
  const { min, max } = setAxisMinMaxValues(dataSet.data);
  const options = {
    interaction: {
      intersect: false,
      mode: 'index',
    },
    tension: 0.4,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      filler: {
        propagate: false,
      },
      tooltip: {
        backgroundColor: '#fbfcfd',
        titleColor: '#869b9b',
        titleFont: { weight: 'normal' },
        displayColors: false,
        bodyColor: '#869b9b',
        bodyFont: { weight: 'bold' },
        cornerRadius: 5,
        callbacks: {
          label(context) {
            return updateChartToolTipPrefix(context, prefixConfig);
          },
        },
      },
    },
    scales: {
      x: {
        display: 'auto',
        beginAtZero: true,
        stacked: false,
        grid: {
          display: false,
          drawBorder: true,
        },
        gridLines: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          autoSkip: false,
          maxTicksLimit: 2,
          color: '#9A9A9A',
          font: { weight: 'light' },
        },
      },
      y: {
        type: 'linear',
        display: 'auto',
        beginAtZero: false,
        stacked: false,
        grid: {
          display: false,
          drawBorder: false,
        },
        gridLines: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 2,
          color: '#9A9A9A',
          font: { weight: 'light' },
          callback: (value) => {
            const result = cutThousands(value);
            return updateChartAxisPrefix(result, prefixConfig);
          },
        },
        min,
        max,
      },
    },
  };

  const colors = {
    white: {
      default: 'rgba(255, 255, 255, 1)',
      half: 'rgba(255, 255, 255, 0.6)',
      quarter: 'rgba(255, 255, 255, 0.3)',
      zero: 'rgba(255, 255, 255, 0.1)',
      hidden: 'rgba(0,0,0,0)',
      currentMonth: currentMonthChartColor('rgba(255, 255, 255, 0.6)'),
    },
    green: {
      default: 'rgba(101, 179, 102, 1)',
      half: 'rgba(101, 179, 102, 0.6)',
      quarter: 'rgba(101, 179, 102, 0.3)',
      zero: 'rgba(101, 179, 102, 0.1)',
      hidden: 'rgba(0,0,0,0)',
      currentMonth: currentMonthChartColor('rgba(101, 179, 102, 0.6)'),
    },
    orange: {
      default: 'rgba(206, 98, 48, 1)',
      half: 'rgba(206, 98, 48, 0.4)',
      quarter: 'rgba(206, 98, 48, 0.1)',
      zero: 'rgba(206, 98, 48, 0)',
      hidden: 'rgba(0,0,0,0)',
      currentMonth: currentMonthChartColor('rgba(206, 98, 48, 0.6)'),
    },
  };

  const ctx = document.createElement('canvas').getContext('2d');
  ctx.canvas.height = 100;

  const colorSet = colors.orange;
  const gradient = ctx.createLinearGradient(0, 25, 0, 100);
  gradient.addColorStop(0, colorSet.half);
  gradient.addColorStop(0.5, colorSet.quarter);
  gradient.addColorStop(1, colorSet.zero);

  const lastForMostRecent = (context, dataLength, value) =>
    (isMostRecent && context.p1DataIndex >= dataLength - 1 ? value : undefined);
  const data = {
    labels: dataSet.dateRange,
    datasets: [
      {
        label: dataSet.title,
        data: dataSet.data,
        clip: false,
        fill: 'start',
        backgroundColor: gradient,
        borderColor: colorSet.default,
        pointHoverBackgroundColor: colorSet.default,
        pointHoverBorderColor: colorSet.default,
        pointBackgroundColor: colorSet.hidden,
        pointBorderColor: colorSet.hidden,
        borderWidth: 2,
        aspectRatio: 4,
        segment: {
          backgroundColor: (context) => lastForMostRecent(
            context,
            dataSet.data.length,
            colorSet.currentMonth,
          ),
          borderColor: (context) => lastForMostRecent(
            context,
            dataSet.data.length,
            colorSet.half,
          ),
        },
      }],
  };
  return (
    <div className="line-chart-wrapper">
      <Line
        options={options}
        data={data}
        width={100}
        height={100}
      />
    </div>
  );
};

export default LineChart;

LineChart.propTypes = {
  dataSet: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.any,
    ]),
  ),
  type: PropTypes.string,
  prefixConfig: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool])),
  isMostRecent: PropTypes.bool,
};

LineChart.defaultProps = {
  dataSet: undefined,
  type: undefined,
  prefixConfig: {},
  isMostRecent: false,
};
