import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  keyBy, map, noop, reject,
} from 'lodash';
import { Grid, Stack } from '@mui/material';
import { MESSAGES } from 'constants/messages';
import { LinkButton } from 'components/common/LinkButton';
import { ReactComponent as AddIcon } from 'assets/svg/add.svg';
import { v1 as uuidv1 } from 'uuid';
import FieldLabel from 'components/common/FieldLabel';
import { getChannels } from 'api/integrationsAPI';
import ExperimentGroupRow from 'components/views/Experiments/ExperimentCreateView/ExperimentGroupTable/ExperimentGroupRow';

const ExperimentGroupTable = ({ groups, onGroupsChange }) => {
  const [channelsMap, setChannelsMap] = useState();
  const [availableSplit, setAvailableSplit] = useState(0);

  useEffect(() => {
    let newAvailableSplit = 100;
    groups.forEach((group) => {
      newAvailableSplit -= group.split_size;
    });
    setAvailableSplit(Math.max(newAvailableSplit, 0));
  }, [groups]);

  useEffect(() => {
    const populateChannels = async () => {
      const channelsList = await getChannels();
      setChannelsMap(keyBy(channelsList, 'uuid'));
    };

    populateChannels();
  }, []);

  const handleGroupSplitChange = (newGroup, newGroupIndex) => {
    let diff = newGroup.split_size - groups[newGroupIndex].split_size;
    let movableSplit = 0;
    let movableGroupCount = 0;

    groups.forEach((group, index) => {
      if (index === newGroupIndex) return;
      if (group.locked || !group.split_size) return;
      movableSplit += group.split_size;
      movableGroupCount += 1;
    });
    movableSplit = Math.min(movableSplit, 100);

    const openSplit = movableSplit + availableSplit;
    if (openSplit < diff) diff = openSplit;
    const reduceAmount = Math.max((diff - availableSplit) / movableGroupCount, 0);

    let carryover = 0;
    const newGroups = map(groups, (group, index) => {
      if (index === newGroupIndex) {
        group.split_size += diff;
        return group;
      }
      if (group.locked) return group;
      if (!group.split_size) return group;

      let groupReduceAmount = reduceAmount + (carryover / movableGroupCount);
      const fractionMod = groupReduceAmount % 1;
      groupReduceAmount -= fractionMod;
      carryover += fractionMod;

      if (groupReduceAmount > group.split_size) {
        carryover += groupReduceAmount - group.split_size;
        group.split_size = 0;
      } else {
        group.split_size -= groupReduceAmount;
      }
      movableGroupCount -= 1;
      return group;
    });

    onGroupsChange(newGroups);
  };

  const handleGroupChange = (newGroup) => {
    const groupIndex = groups.findIndex((group) => group.id === newGroup.id);
    if (groupIndex === -1) return;

    if (groups[groupIndex].split_size < newGroup.split_size) {
      handleGroupSplitChange(newGroup, groupIndex);
      return;
    }

    const newGroups = [...groups];
    newGroups[groupIndex] = newGroup;
    onGroupsChange(newGroups);
  };

  const handleGroupDelete = (id) =>
    onGroupsChange(reject(groups, { id }));

  const handleGroupCreate = () => {
    const newGroups = [...groups];
    const nameSuffix = groups.length < 26
      ? (groups.length + 10).toString(36).toUpperCase()
      : groups.length;

    newGroups.push({
      id: uuidv1(),
      name: `${MESSAGES.group} ${nameSuffix}`,
      split_size: availableSplit,
      channels: [],
    });
    onGroupsChange(newGroups);
  };
  return (
    <div className="experiment-group-table">
      <Stack>
        <Grid container columnSpacing={4}>
          <Grid
            item
            lg={3}
            sx={{ display: { lg: 'block', xs: 'none' } }}
          >
            <FieldLabel label={MESSAGES.group_name} />
          </Grid>
          <Grid
            item
            lg={4}
            sx={{ display: { lg: 'block', xs: 'none' } }}
          >
            <FieldLabel label={MESSAGES.split_size} />
          </Grid>
          <Grid
            item
            lg={4}
            sx={{ display: { lg: 'block', xs: 'none' } }}
          >
            <FieldLabel label={MESSAGES.send_to_channels} />
          </Grid>
        </Grid>
        <Stack spacing={2}>
          {groups.map(({ id, name, split_size, channels, locked }) => (
            <div key={id}>
              <ExperimentGroupRow
                id={id}
                name={name}
                splitSize={split_size}
                channels={channels}
                locked={locked}
                channelsMap={channelsMap}
                onChange={handleGroupChange}
                onDelete={() => handleGroupDelete(id)}
              />
            </div>
          ))}
          <LinkButton
            icon={AddIcon}
            onClick={handleGroupCreate}
            size="large"
            type="secondary"
          >
            {MESSAGES.add_group}
          </LinkButton>
          <FieldLabel label={`${MESSAGES.allocated} ${100 - availableSplit}% / 100%`} />
        </Stack>
      </Stack>
    </div>
  );
};

ExperimentGroupTable.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    split_size: PropTypes.number.isRequired,
    channels: PropTypes.arrayOf(PropTypes.shape({
      integration: PropTypes.string.isRequired,
    })).isRequired,
    locked: PropTypes.bool.isRequired,
  })).isRequired,
  onGroupsChange: PropTypes.func,
};

ExperimentGroupTable.defaultProps = {
  onGroupsChange: noop,
};

export default ExperimentGroupTable;
