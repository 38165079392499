import {
  ValidationError,
  USER_NAME_ERROR_REQUIRED, USER_NAME_ERROR_NOT_MATCH,
} from 'constants/errors';
import Validator from 'utils/validator';

export default ({ user, userName }) => {
  const errors = {};

  const userNameValidator = new Validator(userName);

  try {
    userNameValidator
      .required(USER_NAME_ERROR_REQUIRED)
      .additional((validator) => {
        if (validator.field !== `${user.first_name} ${user.last_name}`) {
          throw new ValidationError(USER_NAME_ERROR_NOT_MATCH);
        }

        return validator;
      });
  } catch (error) {
    errors.userName = error.message;
  }

  return errors;
};
