import { MESSAGES } from 'constants/messages';

export const BUTTON_TYPE = {
  primary: 'primary',
  secondary: 'secondary',
  link: 'link',
};

export const INPUT_TYPE = {
  v1: 'v1',
  v2: 'v2',
};

export const LOGO_TYPE = {
  primary: 'primary',
  small: 'small',
};

export const REQUEST_STATUS = {
  idle: 'idle',
  loading: 'loading',
  success: 'success',
  failure: 'failure',
};

export const DETAIL_STEPS = {
  personal: 1,
  company: 2,
};

export const ADD_LICENSE_STEPS = {
  license: 1,
  pay: 2,
};

export const AVATAR_TYPE = {
  small: 'small',
  middle: 'middle',
  large: 'large',
};

export const WEB_STORAGE = {
  accessToken: 'access_token',
  refreshToken: 'refresh_token',
  uuid: 'uuid',
  oauthState: 'oauth_state',
  expiresAt: 'expires_at',
  sidebarExpanded: 'sidebarExpanded',
  productColumns: 'productColumns',
  productCategoryColumns: 'productCategoryColumns',
};

export const COMPANY_NAME_SIZE = {
  small: 'small',
  middle: 'middle',
  large: 'large',
};

export const COMPANY_NAME_HEADER_TYPE = {
  primary: 'primary',
  secondary: 'secondary',
};

export const ALERT_TYPE = {
  info: 'info',
  warning: 'warning',
  error: 'error',
};

export const TOOLTIP_POSITIONS = {
  topLeft: 'top left',
  topCenter: 'top center',
  topRight: 'top right',
  rightTop: 'right top',
  rightCenter: 'right center',
  rightBottom: 'right bottom',
  bottomLeft: 'bottom left',
  bottomCenter: 'bottom center',
  bottomRight: 'bottom right',
  leftTop: 'left top',
  leftCenter: 'left center',
  leftBottom: 'left bottom',
  centerCenter: 'center center',
};

export const ACCEPT_INVITATION_STEP = {
  verifyLink: 1,
  acceptInvitation: 2,
};

export const CUSTOMER_PER_PAGE = 12;
export const USERS_PER_PAGE = 12;
export const LICENSES_PER_PAGE = 12;
export const SUPER_USERS_PER_PAGE = 12;
export const BUNDLES_PER_PAGE = 12;
export const LICENSE_USERS_PER_PAGE = 10;
export const AUDIENCES_PER_PAGE = 10;
export const COHORTS_PER_PAGE = 6;
export const PRODUCTS_PER_PAGE = 16;
export const CATEGORIES_PER_PAGE = 16;
export const EXPERIMENTS_PER_PAGE = 12;

export const SORT_DIRECTIONS = {
  desc: 'desc',
  asc: 'asc',
};

export const VIEWTYPE = {
  MOBILE: 'mobile',
  TABLET: 'tablet',
  LARGE: 'large',
};

export const SIDEBARTYPE = {
  MOBILE: 'mobile',
  MIN: 'min',
  SLIDE: 'slide',
  FULL: 'full',
};

export const INPUT_VALUE_TYPE = {
  number: 'number',
  percent: 'percent',
  text: 'text',
  boolean: 'boolean',
  list: 'list',
};

export const BOOLEAN_OPTIONS = [{
  value: false,
  label: MESSAGES.false,
}, {
  value: true,
  label: MESSAGES.true,
}];
