import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';

import CompanyDetails from 'containers/Authorization/Details/CompanyDetails';
import AddLicenseValidation from 'containers/Authorization/Details/CompanyDetails/companyValidation';
import { submitCompanyDetails } from 'containers/Authorization/Details/utils';
import useAsync from 'hooks/useAsync';
import useForm from 'hooks/useForm';
import { useDispatch } from 'react-redux';

import { setLicenseDetailsAction } from 'store/license/licenseSlice';
import { cleanupPaymentMethodAction } from 'store/paymentMethod/paymentMethodSlice';
import useRouter from 'hooks/useRouter';
import { ROUTES } from 'constants/routes';

const AddLicense = ({ nextStep }) => {
  const { replace, history, query } = useRouter();
  const dispatch = useDispatch();
  const [isFromAdminPanel, setIsFromAdminPanel] = useState(false);

  useEffect(() => {
    if (query['from-admin-panel'] === 'true') {
      setIsFromAdminPanel(true);
    }
  }, []);

  const onBackButtonHandler = () => {
    history.goBack();
  };

  const submitStepHandler = async (values) => {
    try {
      const newLicenseDetails = await submitCompanyDetails(values, !isFromAdminPanel);
      replace({ search: '' });
      dispatch(setLicenseDetailsAction(newLicenseDetails));
      dispatch(cleanupPaymentMethodAction());
      nextStep();
    } catch (error) {
      replace(ROUTES.portal);
    }
  };

  const {
    execute: submitStepHandlerAsync,
    status: submitStepHandlerStatus,
  } = useAsync(submitStepHandler);
  const {
    handleSubmit, handleChange, handleBlur, values, errors, touched,
  } = useForm(
    submitStepHandlerAsync,
    AddLicenseValidation,
  );

  return (
    <CompanyDetails
      handleSubmit={handleSubmit}
      handleChange={handleChange}
      handleBlur={handleBlur}
      values={values}
      errors={errors}
      touched={touched}
      hasBackButton
      onBackButtonHandler={onBackButtonHandler}
      status={submitStepHandlerStatus}
    />
  );
};

AddLicense.propTypes = {
  nextStep: propTypes.func.isRequired,
};

export default AddLicense;
