import React from 'react';
import PropTypes from 'prop-types';
import 'components/common/Charts/styles.scss';
import { Bar } from 'react-chartjs-2';
import { updateChartAxisPrefix, updateChartToolTipPrefix } from 'components/common/Charts/utils/helper';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const BarChart = ({ datasets, barChartLabels, prefixConfig }) => {
  const options = {
    interaction: {
      intersect: false,
      mode: 'index',
    },
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 2,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
      title: {
        display: false,
      },
      tooltip: {
        backgroundColor: '#fbfcfd',
        titleColor: '#869b9b',
        titleFont: { weight: 'normal' },
        displayColors: false,
        bodyColor: '#869b9b',
        bodyFont: { weight: 'bold' },
        cornerRadius: 5,
        callbacks: {
          label(context) {
            return updateChartToolTipPrefix(context, prefixConfig);
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: true,
        },
        display: true,
        beginAtZero: true,
        stacked: false,
        ticks: {
          callback: (value) => updateChartAxisPrefix(value, prefixConfig),
        },
      },
    },
  };

  const data = {
    labels: barChartLabels,
    datasets,
  };

  return (
    <div className="bar-chart-wrapper">
      <Bar
        options={options}
        width={100}
        height={100}
        data={data}
      />
    </div>
  );
};

export default BarChart;

BarChart.propTypes = {
  datasets: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.any,
    ]),
  ),
  barChartLabels: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.any,
    ]),
  ),
  prefixConfig: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool])),
};

BarChart.defaultProps = {
  datasets: [],
  barChartLabels: [],
  prefixConfig: {},
};
