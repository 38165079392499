import { EXTERNAL_LINK } from 'constants/routes';
import { get } from 'lodash';

export const GOOGLE_INTEGRATIONS = ['google_analytics', 'google_ads'];

export const HELP_GUIDES = {
  shopify: EXTERNAL_LINK.helpShopify,
  woocommerce: EXTERNAL_LINK.helpWoocommerce,
  dandomain_classic: EXTERNAL_LINK.helpDandomainClassic,
  dandomain: EXTERNAL_LINK.helpDandomain,
  business_central: EXTERNAL_LINK.helpBusinessCentral,
  magento: EXTERNAL_LINK.helpMagento,
  datalake_api: EXTERNAL_LINK.helpDatalakeAPI,
  mailchimp: EXTERNAL_LINK.helpMailchimp,
  klaviyo: EXTERNAL_LINK.helpKlaviyo,
  activecampaign: EXTERNAL_LINK.helpActiveCampaign,
  google_ads: EXTERNAL_LINK.helpGoogleAds,
  facebook_ads: EXTERNAL_LINK.helpFacebookAds,
  google_analytics: EXTERNAL_LINK.helpGA,
  facebook_capi: EXTERNAL_LINK.helpFacebookCAPI,
  magento_legacy: EXTERNAL_LINK.helpMagentoLegacy,
  snapchat: EXTERNAL_LINK.helpSnapchat,
  tiktok: EXTERNAL_LINK.helpTikTok,
  drip: EXTERNAL_LINK.helpDrip,
};

export const getHelpGuide = (sourceKey) => get(HELP_GUIDES, sourceKey, EXTERNAL_LINK.custimyHelp);
