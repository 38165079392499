import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './styles.scss';
import { ReactComponent as CheckboxIcon } from 'assets/svg/checkbox.svg';
import { noop } from 'lodash';

const Checkbox = ({
  id, label, value, onChange, hasBigLabel, disabled,
}) => (
  <div className={classNames('container-checkbox', {
    'container-checkbox__disabled': disabled,
  })}
  >
    <label htmlFor={id} className="checkbox">
      <input
        id={id}
        className="checkbox__input"
        type="checkbox"
        name="checkbox"
        checked={value}
        onChange={onChange}
        disabled={disabled}
      />
      <div className="checkbox__checkmark">
        <CheckboxIcon className="checkbox__icon" />
      </div>
    </label>
    <label
      htmlFor={id}
      className={classNames('checkbox__label', {
        checkbox__label__big: hasBigLabel,
      })}
    >
      {label}
    </label>
  </div>
);

Checkbox.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  hasBigLabel: PropTypes.bool,
  disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
  id: 'id_select',
  label: '',
  value: undefined,
  hasBigLabel: undefined,
  disabled: false,
  onChange: noop,
};

export default Checkbox;
