import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { getSegmentsInfo } from 'api/segmentsAPI';
import { Grid } from '@mui/material';
import Chip from 'components/common/Chip';
import SegmentPopperMetrics, { SegmentPopperMetricsStateHandler } from 'components/common/MetricList/PopperMetrics/SegmentPopperMetrics';
import { useSelector } from 'react-redux';
import { getSegmentByIdInState, getSegments } from 'store/segments/getters';
import { get } from 'lodash';

const CustomerListSegments = ({
  onSelect, selectedSegments, popperPlacement, segmentCounts,
}) => {
  const [segments, setSegments] = useState([]);
  const segmentStates = useSelector(getSegments);

  useEffect(() => {
    getSegmentsInfo()
      .then((resp) => setSegments(resp));
  }, []);

  useEffect(() => {
    const newSegments = segments.map((segment) => {
      const countItem = segmentCounts.find(({ id }) => segment.id === id);
      const customersCount = get(countItem, 'count', 0);

      return Object.assign(segment, { customersCount });
    });

    setSegments(newSegments);
  }, [segmentCounts]);

  const isSegmentSelected = (segment) => selectedSegments.includes(segment.id.toString());

  const {
    handleOnHover,
    anchorEl,
    item,
    itemData,
    isLoading,
    isOpen,
  } = SegmentPopperMetricsStateHandler();

  const handleChipOnHover = (e, segment, isHovering) => {
    const segmentState = getSegmentByIdInState(segment.id, segmentStates);
    if (!segmentState) return;
    handleOnHover(e, { ...segment, id: segmentState.id }, isHovering);
  };

  return (
    <>
      <Grid container spacing={1}>
        {segments?.map((segment) => (
          <Grid key={segment.id} item>
            <Chip
              label={segment.segment_name}
              labelNote={segment.customersCount}
              selected={isSegmentSelected(segment)}
              clickColor={segment.color_1}
              onClick={() => onSelect(segment.id.toString(), isSegmentSelected(segment))}
              onHover={(e, isHovering) => handleChipOnHover(e, segment, isHovering)}
            />
          </Grid>
        ))}
      </Grid>
      <SegmentPopperMetrics
        isLoading={isLoading}
        isOpen={isOpen}
        anchorEl={anchorEl}
        item={item}
        itemData={itemData}
        placement={popperPlacement}
      />
    </>
  );
};

CustomerListSegments.propTypes = {
  onSelect: propTypes.func,
  selectedSegments: propTypes.array,
  popperPlacement: SegmentPopperMetrics.propTypes.placement,
  segmentCounts: propTypes.array,
};

CustomerListSegments.defaultProps = {
  selectedSegments: [],
  onSelect: () => {},
  popperPlacement: SegmentPopperMetrics.defaultProps.placement,
  segmentCounts: [],
};

export default CustomerListSegments;
