import React from 'react';
import TotalItem from 'components/common/TotalItem';
import 'components/views/Customer/TopFirstBlock/style.scss';
import { MESSAGES } from 'constants/messages';
import { Grid } from '@mui/material';
import { GUIDES } from 'constants/guides';
import PropTypes from 'prop-types';

const TopFirstBlock = ({ data }) => (
  <Grid container spacing={2}>
    <Grid item xl={3} lg={6} xs={6}>
      <TotalItem
        title={MESSAGES.sales}
        percentage={data.total_sales_last_month_percentage}
        prefix={data.currency}
        guide={GUIDES.CSV3}
      >
        {data.total_sales}
      </TotalItem>
    </Grid>
    <Grid item xl={3} lg={6} xs={6}>
      <TotalItem
        title={MESSAGES.subTotal_sales}
        percentage={data.total_revenue_last_month_percentage}
        prefix={data.currency}
        guide={GUIDES.CSV16}
      >
        {data.total_revenue}
      </TotalItem>
    </Grid>
    <Grid item xl={3} lg={4} xs={6}>
      <TotalItem
        title={MESSAGES.profit}
        percentage={data.total_profit_last_month_percentage}
        prefix={data.currency}
        guide={GUIDES.CSV4}
      >
        {data.total_profit}
      </TotalItem>
    </Grid>
    <Grid item xl={3} lg={4} xs={6}>
      <TotalItem
        title={MESSAGES.profit_margin}
        percentage={data.profit_margin_last_month_percentage}
        perc
        guide={GUIDES.CSV5}
      >
        {data.profit_margin}
      </TotalItem>
    </Grid>
    <Grid item xl={3} lg={4} xs={6}>
      <TotalItem
        title={MESSAGES.order_value_avg}
        prefix={data.currency}
        percentage={data.avg_order_size_last_month_percentage}
        guide={GUIDES.CSV8}
      >
        {data.avg_order_size}
      </TotalItem>
    </Grid>
    <Grid item xl={3} lg={4} xs={6}>
      <TotalItem
        title={MESSAGES.applied_discounts}
        prefix={data.currency}
        percentage={data.total_discounts_last_month_percentage}
        guide={GUIDES.CSV7}
      >
        {data.total_discounts}
      </TotalItem>
    </Grid>
    <Grid item xl={3} lg={4} xs={6}>
      <TotalItem
        title={MESSAGES.total_refunded}
        percentage={data.total_refunded_amount_last_month_percentage}
        prefix={data.currency}
        guide={GUIDES.CSV17}
      >
        {data.total_refunded_amount}
      </TotalItem>
    </Grid>
    <Grid item xl={3} lg={4} xs={6}>
      <TotalItem
        title={MESSAGES.return_rate}
        percentage={data.return_rate_last_month_percentage}
        perc
        guide={GUIDES.CSV6}
      >
        {data.return_rate}
      </TotalItem>
    </Grid>
    <Grid item lg={4} md={4} xs={6}>
      <TotalItem
        title={MESSAGES.propensity_to_buy}
        perc
        hasPrevValue={false}
        guide={GUIDES.CSV9}
      >
        {data.propensity_to_buy}
      </TotalItem>
    </Grid>
    <Grid item lg={4} md={4} xs={6}>
      <TotalItem
        title={MESSAGES.probability_of_churn}
        perc
        hasPrevValue={false}
        guide={GUIDES.CSV14}
      >
        {data.churn_prob}
      </TotalItem>
    </Grid>
    <Grid item lg={4} md={4} xs={6}>
      <TotalItem
        title={MESSAGES.predicted_yearly_value}
        prefix={data.currency}
        hasPrevValue={false}
        guide={GUIDES.CSV15}
      >
        {data.clv}
      </TotalItem>
    </Grid>
  </Grid>
);

TopFirstBlock.propTypes = {
  data: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.bool,
  ])).isRequired,
};

export default TopFirstBlock;
