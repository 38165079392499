import React from 'react';
import PropTypes from 'prop-types';
import 'components/views/Customer/PersonalInformation/style.scss';
import { Map } from 'components/views/Customer/Map';
import { MESSAGES } from 'constants/messages';
import Block from 'components/common/Block';
import { Stack } from '@mui/material';
import { GUIDES } from 'constants/guides';

const PersonalInformation = ({ data }) => (
  <Block
    fillHeight
    className="personal-information"
    guide={GUIDES.CSV2}
    postHeader={MESSAGES.personal_information}
  >
    <Stack spacing={2}>
      <div className="address">
        <Stack spacing={0}>
          <span className="title">{MESSAGES.billing_address}</span>
          <span className="value">{`${data.billing_address || ''} ${data.billing_city || ''} ${data.country || ''}` || '-'}</span>
        </Stack>
      </div>
      <div className="address">
        <Stack spacing={0}>
          <span className="title">{MESSAGES.shipping_address}</span>
          <span className="value">{`${data.shipping_address || ''} ${data.shipping_city || ''} ${data.country || ''}` || '-'}</span>
        </Stack>
      </div>
      <div className="map">
        <Map data={data} />
      </div>
    </Stack>
  </Block>
);

PersonalInformation.propTypes = {
  data: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.bool,
  ])).isRequired,
};
export default PersonalInformation;
