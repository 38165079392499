import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import 'containers/PrivateApp/Portal/styles.scss';
import CustomHeader from 'containers/PrivateApp/Portal/CustomHeader';
import AdminPanelMenu from './AdminPanelMenu';
import { ROUTES } from 'constants/routes';
import Users from 'components/views/Admin/Users';
import Licenses from 'components/views/Admin/Licenses';
import SuperUsers from 'components/views/Admin/SuperUsers';
import MaxAccessSuperUserRoute from 'components/common/ProtectedRoutes/MaxAccessSuperUserRoute';
import Bundles from 'components/views/Admin/Bundles';
import usePortal from 'hooks/usePortal';
import { getTemplateColumn } from 'containers/PrivateApp/Portal/utils';
import { VIEWTYPE } from 'constants/common';
import MobileUserPanel from 'containers/PrivateApp/Portal/MobileUserPanel';
import { Grid, Box } from '@mui/material';

const AdminPanel = () => {
  const {
    isMenuOpened,
    isOpenedLarge,
    defaultSideBar,
    viewType,
    toggleDrawer,
    toggleMenu,
    setIsSliderOpened,
    onCloseSlideMenu,
  } = usePortal();

  return (
    <div className="portal">
      <Grid
        component={Box}
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridTemplateRows="65px 1fr"
        sx={{ minHeight: '100%' }}
      >
        <Grid
          component={Box}
          gridColumn="span 12"
          display="grid"
          gridTemplateColumns="1fr"
        >
          <CustomHeader
            isMenuOpened={isMenuOpened}
            viewType={viewType}
            toggleMenu={toggleMenu}
            handleSliderClick={setIsSliderOpened}
          />
        </Grid>
        <Grid
          component={Box}
          gridColumn="span 12"
          display="grid"
          gridTemplateColumns={getTemplateColumn(viewType, defaultSideBar, isOpenedLarge)}
        >
          {(viewType !== VIEWTYPE.MOBILE || (viewType === VIEWTYPE.MOBILE && isMenuOpened))
            && (
              <Grid
                component={Box}
                gridColumn={viewType === VIEWTYPE.MOBILE ? 'span 12' : 'span 1'}
              >
                <AdminPanelMenu
                  isMenuOpened={isMenuOpened}
                  defaultSideBar={defaultSideBar}
                  viewType={viewType}
                  toggleDrawer={toggleDrawer}
                  toggleMenu={toggleMenu}
                  onCloseSlideMenu={onCloseSlideMenu}
                />
              </Grid>
            )}
          {viewType === VIEWTYPE.MOBILE && isMenuOpened
            && (
              <Grid
                item
                xs={12}
                sx={{
                  display: 'block',
                  maxHeight: 'fitContent',
                  backgroundColor: 'white',
                }}
              >
                <MobileUserPanel toggleMenu={toggleMenu} />
              </Grid>
            )}
          {(viewType !== VIEWTYPE.MOBILE || (viewType === VIEWTYPE.MOBILE && !isMenuOpened))
              && (
                <Grid
                  component={Box}
                  gridColumn={viewType === VIEWTYPE.MOBILE ? 'span 12' : 'span 11'}
                >
                  <div className="portal__content-wrapper">
                    <main className="portal__content">
                      <Switch>
                        <Route exact path={ROUTES.adminPanel}>
                          <Redirect to={ROUTES.adminPanelLicenses} />
                        </Route>
                        <Route path={ROUTES.adminPanelLicenses} component={Licenses} />
                        <Route path={ROUTES.adminPanelUsers} component={Users} />
                        <Route path={ROUTES.adminBundles} component={Bundles} />
                        <MaxAccessSuperUserRoute
                          path={ROUTES.adminPanelSuperUsers}
                          component={SuperUsers}
                        />
                      </Switch>
                    </main>
                  </div>
                </Grid>
              )}
        </Grid>
      </Grid>
    </div>
  );
};

export default AdminPanel;
