import { useEffect, useState, useCallback } from 'react';
import { VIEWTYPE, SIDEBARTYPE } from 'constants/common';
import { sidebarExpandedLSManager } from 'helper/localStorage';

export default () => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [isOpenedLarge, setIsOpenedLarge] = useState(sidebarExpandedLSManager.get());
  const [isSliderOpened, setIsSliderOpened] = useState(false);
  const [defaultSideBar, setDefaultSideBar] = useState(undefined);
  const [viewType, setViewType] = useState('');

  const mobileMaxScreen = 600;
  const tabletMaxScreen = 900;

  const handleScreenSize = useCallback(() => {
    if (window.innerWidth < tabletMaxScreen && window.innerWidth >= mobileMaxScreen) {
      setViewType(VIEWTYPE.TABLET);
      setIsMenuOpened(false);
    } else if (window.innerWidth >= tabletMaxScreen) {
      setViewType(VIEWTYPE.LARGE);
    } else {
      setViewType(VIEWTYPE.MOBILE);
      setIsMenuOpened(false);
    }
  });

  useEffect(() => {
    handleScreenSize();
    window.addEventListener('resize', handleScreenSize);

    return () => window.removeEventListener('resize', handleScreenSize);
  }, []);

  useEffect(() => {
    if (viewType === VIEWTYPE.MOBILE) {
      setDefaultSideBar(SIDEBARTYPE.MOBILE);
    } else if (viewType === VIEWTYPE.TABLET && !isMenuOpened) {
      setDefaultSideBar(SIDEBARTYPE.MIN);
    } else if (viewType === VIEWTYPE.TABLET && isMenuOpened) {
      setDefaultSideBar(SIDEBARTYPE.SLIDE);
    } else if (!isOpenedLarge) {
      setDefaultSideBar(SIDEBARTYPE.FULL);
    } else if (isOpenedLarge) {
      setDefaultSideBar(SIDEBARTYPE.MIN);
    }
  }, [isMenuOpened, viewType, isOpenedLarge]);

  const toggleDrawer = (val) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    if (viewType === VIEWTYPE.TABLET) setIsMenuOpened(val);
    if (viewType === VIEWTYPE.LARGE) setIsSliderOpened(val);
  };

  const toggleMenu = () => {
    if (viewType === VIEWTYPE.LARGE) {
      setIsOpenedLarge(!isOpenedLarge);
      sidebarExpandedLSManager.set(!isOpenedLarge);
    } else {
      setIsMenuOpened(!isMenuOpened);
    }
  };

  const toggleSlider = () => setIsSliderOpened(!isSliderOpened);

  const onCloseSlideMenu = () => {
    if (defaultSideBar === SIDEBARTYPE.SLIDE) {
      toggleSlider();
      setDefaultSideBar(SIDEBARTYPE.MIN);
    }
  };

  const handleMouseMove = useCallback(() => {
    const getMouseLocation = (e) => ({ x: e.clientX, y: e.clientY });

    document.onmousemove = (e) => {
      if (viewType === VIEWTYPE.LARGE && isOpenedLarge) {
        const pos = getMouseLocation(e);
        if (pos && pos.x < 10 && defaultSideBar !== SIDEBARTYPE.SLIDE) {
          setIsSliderOpened(true);
          setDefaultSideBar(SIDEBARTYPE.SLIDE);
        } else if (pos && pos.x > 220) {
          setIsSliderOpened(false);
          setDefaultSideBar(SIDEBARTYPE.MIN);
        }
      }
    };
  });

  useEffect(() => {
    handleMouseMove();
    document.addEventListener('mousemove', handleMouseMove);

    return () => document.removeEventListener('mousemove', handleMouseMove);
  });

  return {
    isMenuOpened,
    isOpenedLarge,
    isSliderOpened,
    defaultSideBar,
    viewType,
    setIsMenuOpened,
    setIsSliderOpened,
    toggleDrawer,
    toggleMenu,
    toggleSlider,
    onCloseSlideMenu,
  };
};
