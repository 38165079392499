import React from 'react';
import PropTypes from 'prop-types';
import { getAvailableIntegrationsList } from 'store/license/getters';
import { useSelector } from 'react-redux';
import { INTEGRATION_CATEGORY_NAMES } from 'components/views/Integrations/constants/Constants';
import { MESSAGES } from 'constants/messages';
import { getAssetSourceImage } from 'utils/assets';
import { LinkButton } from 'components/common/LinkButton';
import { ROUTES } from 'constants/routes';
import useRouter from 'hooks/useRouter';
import './styles.scss';
import { Box, Stack } from '@mui/material';

const IntegrationRequired = ({
  requiredIntegration,
  requiredIntegrationCategory,
}) => {
  const integrationsData = useSelector(getAvailableIntegrationsList);
  const { push } = useRouter();
  let name;
  let path;

  if (requiredIntegration) {
    integrationsData.forEach((integration) => {
      if (integration.key === requiredIntegration) {
        name = integration.name;
        path = getAssetSourceImage(integration.key, true);
      }
    });
  }

  if (requiredIntegrationCategory) {
    name = INTEGRATION_CATEGORY_NAMES[requiredIntegrationCategory];
  }

  return (
    <Box
      className="integration-items"
      display="flex"
      justifyContent="center"
    >
      <Stack
        spacing={1}
        justifyContent="center"
      >
        <Stack
          direction="row"
          className="integration-required-state"
          justifyContent="center"
        >
          {path && (
            <span className="integrate-icon">
              <img
                src={path}
                className="title-icon"
                alt=""
              />
            </span>
          )}
          <span className="integrate-text">
            {name}
          </span>
        </Stack>
        <Box display="flex" justifyContent="center">
          <LinkButton
            size="medium"
            underlined
            onClick={() => push(ROUTES.integrations)}
          >
            {MESSAGES.integrate_to_unlock}
          </LinkButton>
        </Box>
      </Stack>
    </Box>
  );
};

IntegrationRequired.propTypes = {
  requiredIntegration: PropTypes.string,
  requiredIntegrationCategory: PropTypes.string,
};

IntegrationRequired.defaultProps = {
  requiredIntegration: undefined,
  requiredIntegrationCategory: undefined,
};

export default IntegrationRequired;
