import { values } from 'lodash';

export const AUDIENCE_TYPES = {
  segment: 'segment',
  product: 'product',
  category: 'category',
};

export const AUDIENCE_STATUSES = {
  INACTIVE: {
    label: 'Inactive',
    value: 'DRAFT',
    type: 'warning',
  },
  ACTIVE: {
    label: 'Active',
    value: 'ACTIVE',
    type: 'success',
  },
};

export const AUDIENCE_STATUSES_VALUES = values(AUDIENCE_STATUSES);