import React from 'react';
import propTypes from 'prop-types';

import CustomOpenButton from 'components/common/ConfirmationWindow/CustomOpenButton';
import { BUTTON_TYPE } from 'constants/common';
import { MESSAGES } from 'constants/messages';

const InviteUserButton = ({ disable, ...props }) => (
  <CustomOpenButton
    block
    type={BUTTON_TYPE.primary}
    isPreventSubmit
    isDisabled={disable}
    {...props}
  >
    {MESSAGES.invite_user}
  </CustomOpenButton>
);

InviteUserButton.propTypes = {
  disable: propTypes.bool,
};

InviteUserButton.defaultProps = {
  disable: false,
};

export default InviteUserButton;
