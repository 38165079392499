import React from 'react';
import propTypes from 'prop-types';
import Popup from 'reactjs-popup';
import 'components/views/CustomAudience/DeleteAudiencePreviewModal/styles.scss';
import Button from 'components/common/Button';
import { REQUEST_STATUS, BUTTON_TYPE } from 'constants/common';
import useAsync from 'hooks/useAsync';
import { deleteCustomAudience } from 'api/customAudiencesAPI';
import { MESSAGES } from 'constants/messages';

const DeleteAudiencePreviewModal = ({
  deleteAudienceUuid, onClose, onSubmit,
}) => {
  const deleteCustomAudienceHandler = async () => {
    await deleteCustomAudience(deleteAudienceUuid);
    onSubmit();
  };

  const { execute, status } = useAsync(deleteCustomAudienceHandler);

  return (
    <Popup
      modal
      open={!!deleteAudienceUuid}
      position="center center"
      onClose={onClose}
    >
      {() => (
        <div className="confirmation-window delete-audience">
          <span className="confirmation-window__content delete-audience__title">
            {MESSAGES.delete_confirmation}
          </span>
          <div className="delete-audience__form__actions form__actions">
            <div className="delete-audience__form__actions__cancel form__cancel">
              <Button
                block
                isPreventSubmit
                onClick={onClose}
                type={BUTTON_TYPE.secondary}
              >
                {MESSAGES.cancel}
              </Button>
            </div>
            <div className="delete-audience__form__actions__submit form__submit">
              <Button
                type={BUTTON_TYPE.primary}
                danger
                isLoading={REQUEST_STATUS.loading === status}
                onClick={execute}
              >
                {MESSAGES.remove}
              </Button>
            </div>
          </div>
        </div>
      )}
    </Popup>
  );
};

export default DeleteAudiencePreviewModal;

DeleteAudiencePreviewModal.propTypes = {
  deleteAudienceUuid: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  onSubmit: propTypes.func.isRequired,
};
