import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import { ReactComponent as AddIcon } from 'assets/svg/add.svg';
import { getCustomerFilterList } from 'api/customersAPI';
import CustomerListFilter from './CustomerListFilter';
import { MESSAGES } from 'constants/messages';
import { formatFilterLists } from 'helper/filterListFormatter';
import { BOOLEAN_OPTIONS, INPUT_VALUE_TYPE } from 'constants/common';
import { LinkButton } from 'components/common/LinkButton';

export const isFilterValid = (filter) => (
  filter.field && filter.operator && (filter.value || filter.value === 0));

const identity = (x) => x;
const toFiltersValueFormatters = {
  [INPUT_VALUE_TYPE.number]: (x) => Number(x),
  [INPUT_VALUE_TYPE.percent]: (x) => Number(x) * 100,
  [INPUT_VALUE_TYPE.boolean]: (x) => BOOLEAN_OPTIONS.find((option) => option.value === (x === 'true')),
};
const configToFilters = (baseConfigKeys, config, fields, operators) => {
  const baseConfigSet = new Set(baseConfigKeys);
  const filtersConfigKeys = Object.keys(config).filter((key) => !baseConfigSet.has(key));
  const filters = [];
  filtersConfigKeys.forEach((key) => {
    let [fieldValue, operatorValue] = key.split('__');
    const field = fields.find((x) => x.value === fieldValue);

    const operatorsList = field ? operators[field.type] : [];
    operatorValue = operatorValue || 'exact';
    const operator = operatorsList.find((x) => x.value === operatorValue);

    const format = toFiltersValueFormatters[field?.type] || identity;
    const value = format(config[key]);

    const filter = { field, operator, value };
    isFilterValid(filter) && filters.push(filter);
  });

  return filters;
};

const toConfigValueFormatters = {
  [INPUT_VALUE_TYPE.percent]: (x) => x / 100,
  [INPUT_VALUE_TYPE.boolean]: (x) => x.value,
};
const filtersToConfig = (filters) => filters.reduce((result, filter) => {
  if (isFilterValid(filter)) {
    let filterKey = filter.field.value;
    if (filter.operator.value !== 'exact') {
      filterKey += `__${filter.operator.value}`;
    }

    const format = toConfigValueFormatters[filter.field.type] || identity;
    result[filterKey] = format(filter.value);
  }

  return result;
}, {});

const CustomerListFilters = ({ baseConfigKeys, config, changeFiltersConfig }) => {
  const [filters, setFilters] = useState([]);
  const [fields, setFields] = useState([]);
  const [operators, setOperators] = useState({});

  useEffect(() => {
    getCustomerFilterList().then((resp) => {
      const { fields, operators } = formatFilterLists(resp);
      setFields(fields);
      setOperators(operators);
      setFilters(configToFilters(baseConfigKeys, config, fields, operators));
    });
  }, []);

  const changeConfig = (filters) => {
    const filterConfig = filtersToConfig(filters);
    changeFiltersConfig(filterConfig);
  };

  const addFilter = () => {
    const newFilters = [
      ...filters,
      { key: +new Date() },
    ];
    setFilters(newFilters);
    changeConfig(newFilters);
  };

  const setFilterValue = (idx, value) => {
    const newFilters = [...filters.slice(0, idx), value, ...filters.slice(idx + 1)];
    setFilters(newFilters);
    changeConfig(newFilters);
  };

  const removeFilter = (idx) => {
    const newFilters = [...filters.slice(0, idx), ...filters.slice(idx + 1)];
    setFilters(newFilters);
    changeConfig(newFilters);
  };

  return (
    <div className="customer-list-filters">
      {!!filters.length
        && (
        <table className="customer-list-filters__table">
          <thead>
            <tr>
              <th>{MESSAGES.variable}</th>
              <th>{MESSAGES.operator}</th>
              <th>{MESSAGES.value}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {filters.map((filter, idx) => (
              <CustomerListFilter
                key={filter.key}
                idx={idx}
                filter={filter}
                setFilter={(value) => setFilterValue(idx, value)}
                fields={fields}
                operators={operators}
                handleRemove={() => removeFilter(idx)}
              />
            ))}
          </tbody>
        </table>
        )}
      <div className="customer-list-filters__footer">
        <LinkButton
          icon={AddIcon}
          onClick={addFilter}
          size="large"
          type="secondary"
        >
          {MESSAGES.add_filter}
        </LinkButton>
      </div>
    </div>
  );
};

CustomerListFilters.propTypes = {
  baseConfigKeys: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
  config: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.number,
  ])).isRequired,
  changeFiltersConfig: PropTypes.func.isRequired,
};

export default CustomerListFilters;
