import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import { ReactComponent as MenuIcon } from 'assets/svg/menu.svg';
import { ReactComponent as CloseMenuIcon } from 'assets/svg/closeMenu.svg';
import CompanyName from 'components/common/CompanyName';
import { useSelector } from 'react-redux';
import { getCurrentLicense } from 'store/license/getters';
import useRouter from 'hooks/useRouter';
import { ROUTES } from 'constants/routes';
import { MESSAGES } from 'constants/messages';
import { VIEWTYPE } from 'constants/common';
import Breadcrumbs from 'containers/PrivateApp/Portal/CustomHeader/Breadcrumbs';

const Header = ({
  isMenuOpened, viewType, toggleMenu, handleSliderClick,
}) => {
  const {
    avatar: avatarImg,
  } = useSelector(getCurrentLicense);
  const { pathname } = useRouter();
  const isInAdminPanel = pathname.startsWith(ROUTES.adminPanel);

  const onClickHandler = () => {
    toggleMenu();
    if (viewType !== VIEWTYPE.MOBILE) handleSliderClick(false);
  };

  return (
    <header className="portal-header-container">
      <button
        type="button"
        className="portal-header-menu-icon"
        onClick={onClickHandler}
      >
        {viewType !== VIEWTYPE.MOBILE
          ? <MenuIcon className="portal-menu-icon" width={30} height={30} />
          : viewType === VIEWTYPE.MOBILE && isMenuOpened
            ? <CloseMenuIcon className="portal-menu-icon" width={30} height={30} />
            : <MenuIcon className="portal-menu-icon" width={30} height={30} />}
      </button>
      {isInAdminPanel ? (
        <span className="portal-header-menu__admin_panel">{MESSAGES.admin_panel}</span>
      ) : (
        <>
          <div className="portal-header-menu__company-name">
            <CompanyName src={avatarImg || null} hasAddButton />
          </div>
        </>
      )}
      {viewType !== VIEWTYPE.MOBILE && <Breadcrumbs />}
    </header>
  );
};

Header.propTypes = {
  handleSliderClick: PropTypes.func.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  viewType: PropTypes.string.isRequired,
  isMenuOpened: PropTypes.bool.isRequired,
};

export default Header;
