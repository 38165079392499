import { useDispatch } from 'react-redux';
import { fetchSegments } from 'store/segments/segmentsSlice';

export default () => {
  const dispatch = useDispatch();

  const initializeState = () => {
    dispatch(fetchSegments());
  };

  return { initializeState };
};
