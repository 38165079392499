import { MESSAGES } from 'constants/messages';
import React from 'react';
import BannerTemplate from 'components/views/Homepage/Banners/BannerTemplate';

const MainHurrayBanner = () => (
  <BannerTemplate
    title={MESSAGES.hurray}
    className="hurray"
    subtitle={MESSAGES.your_data_is_being_pulled}
  />
);

export default MainHurrayBanner;
