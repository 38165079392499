import { ValidationError } from 'constants/errors';

export default class Validator {
  constructor(field) {
    this.field = field;
  }

  required(error) {
    if (!this.field) {
      throw new ValidationError(error);
    }

    return this;
  }

  notEmpty(error) {
    this.required(error);
    if (Array.isArray(this.field) && !this.field.length) {
      throw new ValidationError(error);
    }

    return this;
  }

  match(pattern, error) {
    if (!pattern.test(this.field)) {
      throw new ValidationError(error);
    }

    return this;
  }

  maxLength(maxLength, error) {
    if (this.field?.length > maxLength) {
      throw new ValidationError(error);
    }

    return this;
  }

  minLength(minLength, error) {
    if (this.field?.length < minLength) {
      throw new ValidationError(error);
    }

    return this;
  }

  additional(callback) {
    return callback(this);
  }

  largerThan(min, error) {
    if (!this.field || this.field <= min) {
      throw new ValidationError(error);
    }
    return this;
  }
}
