import {
  ValidationError,
  COMPANY_NAME_ERROR_REQUIRED, COMPANY_NAME_ERROR_NOT_MATCH,
} from 'constants/errors';
import Validator from 'utils/validator';

export default ({ license, companyName }) => {
  const errors = {};

  const companyNameValidator = new Validator(companyName);

  try {
    companyNameValidator
      .required(COMPANY_NAME_ERROR_REQUIRED)
      .additional((validator) => {
        if (validator.field !== license.company_name) {
          throw new ValidationError(COMPANY_NAME_ERROR_NOT_MATCH);
        }

        return validator;
      });
  } catch (error) {
    errors.companyName = error.message;
  }

  return errors;
};
