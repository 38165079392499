import React from 'react';
import PropTypes from 'prop-types';
import TrendIndicator from 'components/common/TrendIndicator';
import './styles.scss';
import { NumberFormatted } from 'helper/NumberFormatted';
import { MESSAGES } from 'constants/messages';

const MetricListItem = ({
  title,
  trendPercentage,
  value,
  valuePrefix,
  valueSuffix,
}) => (
  <div className="metric-list-item">
    <div className="title-value-wrapper">
      <span className="title">
        {title}
      </span>

      <div className="value-wrapper">
        {!!value && !!valuePrefix && (
          <span className="value-prefix">
            {valuePrefix}
          </span>
        )}

        <span className="value">
          {NumberFormatted(value)}
        </span>

        {!!value && !!valueSuffix && (
          <span className="value-suffix">
            {valueSuffix}
          </span>
        )}
      </div>
    </div>

    <TrendIndicator
      percentage={trendPercentage}
      label={MESSAGES.month_on_month}
    />
  </div>
);

MetricListItem.propTypes = {
  title: PropTypes.string.isRequired,
  trendPercentage: PropTypes.number,
  valuePrefix: PropTypes.string,
  valueSuffix: PropTypes.string,
  value: PropTypes.number.isRequired,
};

MetricListItem.defaultProps = {
  trendPercentage: undefined,
  valuePrefix: undefined,
  valueSuffix: undefined,
};

export default MetricListItem;
