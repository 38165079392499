import React, { useState } from 'react';
import { Stack } from '@mui/material';
import './styles.scss';
import { MESSAGES } from 'constants/messages';
import { NumberFormattedCurrency } from 'helper/NumberFormatted';
import Block from 'components/common/Block';
import { GUIDES } from 'constants/guides';
import { LinkButton } from 'components/common/LinkButton';
import CategoryPopperMetrics, { CategoryPopperMetricsStateHandler } from 'components/common/MetricList/PopperMetrics/CategoryPopperMetrics';
import PropTypes from 'prop-types';
import useRouter from 'hooks/useRouter';
import { ROUTES } from 'constants/routes';
import Divider from 'components/common/Divider';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import ProductVariantsTable from 'components/views/Products/ProductVariantsTable';
import Popup from 'reactjs-popup';
import IconButton from 'components/common/IconButton';
import TooltipCopy from 'components/common/TooltipCopy';

const EmptyState = ({ showDash }) => (
  <div className="empty-state">
    <span className="no-data">
      {showDash ? '-' : MESSAGES.not_available}
    </span>
  </div>
);

const KpiWithCurrency = ({ title, value, currency }) => (
  <div className="details-kpi">
    <div>
      {title}
    </div>
    <div className="details-kpi-value">
      {value ? (
        <>
          {NumberFormattedCurrency(value)}
          <span className="details-kpi-value__currency">
            {currency}
          </span>
        </>
      ) : <EmptyState />}
    </div>
  </div>
);

const SingleProductHeader = ({ data, segmentsMap }) => {
  const [isVariantsOpen, setIsVariantsOpen] = useState(false);

  const {
    name,
    price,
    img: image,
    avg_price,
    cost,
    product_segments,
    product_categories,
    sku,
    id,
    currency,
    variations_count,
  } = data;

  const {
    handleOnHover,
    anchorEl,
    item,
    itemData,
    isLoading,
    isOpen,
  } = CategoryPopperMetricsStateHandler();

  const { push } = useRouter();

  const handleCategoryOnHover = (e, isHovering, category) => {
    const categoryItem = { id: category.id, title: category.name };
    handleOnHover(e, categoryItem, isHovering);
  };

  const handleVariantsModalClose = () =>
    setIsVariantsOpen(false);

  const handleVariantsModalOpen = () =>
    setIsVariantsOpen(true);

  return (
    <>
      <Stack className="single-product">
        <Block
          className="single-product__header"
          type="large-header"
          postHeader={MESSAGES.product_insights}
        >
          <div className="name">
            {name}
          </div>
          <div className="price">
            {MESSAGES.price}
            {': '}
            <span className="price-value">
              {price && currency
                ? `${NumberFormattedCurrency(price)} ${currency}`
                : price
                  ? NumberFormattedCurrency(price)
                  : <EmptyState />}
            </span>
          </div>
        </Block>
        <Block
          fillHeight
          postHeader={MESSAGES.details}
          className="single-product__details"
          guide={GUIDES.SP1}
        >
          <div className="details-image">
            {!!image && (
              <img src={image} alt="product-header" />
            )}
          </div>
          <div className="details-kpi clickable">
            <div>
              {MESSAGES.id.toUpperCase()}
            </div>
            {id
              ? (
                <TooltipCopy
                  value={String(id)}
                  info={`${MESSAGES.click_to_copy} ${MESSAGES.id.toUpperCase()}`}
                >
                  {id}
                </TooltipCopy>
              ) : <EmptyState />}
          </div>
          <Divider />
          <div className="details-kpi clickable">
            <div>
              {MESSAGES.sku}
            </div>
            {sku
              ? (
                <TooltipCopy
                  value={String(sku)}
                  info={`${MESSAGES.click_to_copy} ${MESSAGES.sku}`}
                >
                  {sku}
                </TooltipCopy>
              )
              : <EmptyState />}
          </div>
          <Divider />
          <KpiWithCurrency
            title={MESSAGES.avg_price}
            value={avg_price}
            currency={currency}
          />
          <Divider />
          <KpiWithCurrency
            title={MESSAGES.cost}
            value={cost}
            currency={currency}
          />
          <Divider />
          <div className="details-kpi">
            <div>
              {MESSAGES.product_segments}
            </div>
            {product_segments && product_segments.length ? (
              <div className="details-kpi-value">
                {product_segments.map((x) => segmentsMap?.[x]?.product_segment).join(', ')}
              </div>
            ) : <EmptyState showDash />}
          </div>
          <Divider />
          <div className="details-kpi">
            <div>
              {MESSAGES.categories}
            </div>
            {product_categories && Array.isArray(product_categories) && product_categories.length
              ? product_categories.map((category) => (
                <div key={category.id}>
                  <LinkButton
                    size="medium"
                    underlined
                    onHover={(e, isHovering) => handleCategoryOnHover(e, isHovering, category)}
                    onClick={() => push(`${ROUTES.productCategories}/${category.id}`)}
                  >
                    {category.name}
                  </LinkButton>
                </div>
              )) : <EmptyState />}
          </div>
          {!!variations_count && (
            <div className="details-kpi">
              <Divider />
              <Stack
                direction="row"
                spacing={1}
                justifyContent="space-between"
              >
                <div>
                  {MESSAGES.variants}
                </div>
                <LinkButton
                  type="secondary"
                  size="small"
                  onClick={handleVariantsModalOpen}
                >
                  {MESSAGES.view_all}
                </LinkButton>
              </Stack>
              <span className="details-kpi-value">
                {variations_count}
              </span>
            </div>
          )}
        </Block>
      </Stack>
      <CategoryPopperMetrics
        isLoading={isLoading}
        isOpen={isOpen}
        item={item}
        itemData={itemData}
        anchorEl={anchorEl}
        hasClickableLabel
      />
      {!!variations_count && (
        <Popup
          modal
          open={isVariantsOpen}
          position="center center"
          closeOnDocumentClick={false}
          onClose={handleVariantsModalClose}
        >
          {isVariantsOpen && (
            <div className="single-product-variants-table">
              <Block
                header={MESSAGES.variants}
                headerContent={<IconButton icon={CloseIcon} size="small" type="secondary" onClick={handleVariantsModalClose} />}
              >
                <ProductVariantsTable
                  parentId={id}
                />
              </Block>
            </div>
          )}
        </Popup>
      )}
    </>
  );
};

SingleProductHeader.propTypes = {
  data: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string, PropTypes.number, PropTypes.any])),
  segmentsMap: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.any])),
};

SingleProductHeader.defaultProps = {
  data: undefined,
  segmentsMap: undefined,
};

export default SingleProductHeader;
