import React from 'react';
import Table from 'components/common/Table';
import { FormattedNumberCell } from 'components/common/Table/TableCells';
import { DateCell } from 'components/common/CommonTableCells';
import { CohortValueCell } from 'components/views/Cohort/CohortTable/CohortCells';
import PropTypes from 'prop-types';
import { MESSAGES } from 'constants/messages';

const getBaseTableConfig = () => [{
  title: MESSAGES.date,
  key: 'date',
  ordering: null,
  width: '150px',
  CellComponent: DateCell,
  componentProps: {
    fromFormat: 'YYYY-MM-DDTHH:mm:ssZ',
    toFormat: 'MMM YYYY',
    fallbackTextKey: 'name',
  },
  hasOverflowEllipsis: true,
}, {
  title: MESSAGES.customers,
  key: 'customers_count',
  width: '150px',
  ordering: null,
  CellComponent: FormattedNumberCell,
  componentProps: {},
  hasOverflowEllipsis: true,
}];

const getTableConfig = (maxMonthsSince, minValue, maxValue, isCumulative, excludeFirst) => {
  const tableConfig = getBaseTableConfig();
  for (let i = 0 + (excludeFirst ? 1 : 0); i <= maxMonthsSince; i++) {
    tableConfig.push({
      title: `${MESSAGES.month} ${i}`,
      key: `month_${i}`,
      width: '80px',
      ordering: null,
      CellComponent: CohortValueCell,
      componentProps: {
        minValue, maxValue, isCumulative, month: i,
      },
      hasOverflowEllipsis: true,
    });
  }
  return tableConfig;
};

const CohortTable = ({
  cohortRows,
  maxMonthsSince,
  minValue,
  maxValue,
  isCumulative,
  excludeFirst,
}) => (
  <Table
    data={cohortRows}
    tableConfig={getTableConfig(maxMonthsSince, minValue, maxValue, isCumulative, excludeFirst)}
    initSort={['date']}
    sortChanged={() => {}}
    highlightStartIdx={0}
    highlightEndIdx={2 + maxMonthsSince}
  />
);

CohortTable.propTypes = {
  cohortRows: PropTypes.array.isRequired,
  maxMonthsSince: PropTypes.number.isRequired,
  minValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  isCumulative: PropTypes.bool.isRequired,
  excludeFirst: PropTypes.bool,
};

CohortTable.defaultProps = {
  excludeFirst: false,
};

export default CohortTable;
