import React from 'react';

import './styles.scss';
import Logo from 'components/common/Logo';
import { LOGO_TYPE } from 'constants/common';

const LogoSmall = () => (
  <div className="logo">
    <Logo type={LOGO_TYPE.small} />
  </div>
);

export default LogoSmall;
