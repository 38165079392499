import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { MESSAGES } from 'constants/messages';
import { useSelector } from 'react-redux';
import useRouter from 'hooks/useRouter';
import { LinkButton } from 'components/common/LinkButton';
import { getSegmentById } from 'store/segments/getters';
import { ROUTES } from 'constants/routes';
import SegmentPopperMetrics, {
  SegmentPopperMetricsStateHandler,
} from 'components/common/MetricList/PopperMetrics/SegmentPopperMetrics';
import { Box } from '@mui/material';
import Divider from 'components/common/Divider';

const InfoForm = ({ data }) => {
  const { push } = useRouter();
  const segment_details = useSelector(getSegmentById(data?.segment?.id));
  const id = segment_details?.id;
  const {
    handleOnHover,
    anchorEl,
    item,
    itemData,
    isLoading,
    isOpen,
  } = SegmentPopperMetricsStateHandler();

  return (
    <>
      <div className="form-block">
        {!!data.segment && !!data.segment.segment_name ? (
          <div className="segment-title">
            <LinkButton
              type="white"
              onClick={() => push(ROUTES.segments_id.replace(':id', id))}
              size="medium"
              underlined
              onHover={(e, isHovering) => handleOnHover(e, { ...data.segment, id }, isHovering)}
            >
              <Box display="flex" alignItems="center">
                <div className="segment-color" style={{ backgroundColor: `${data.segment.color_1}` }} />
              </Box>
              {data.segment.segment_name}
            </LinkButton>
          </div>
        ) : (
          <div className="segment-title">
            {' '}
            -
          </div>
        )}
        <Divider />
        <div className="item">
          <span className="title">
            {`${MESSAGES.email}:`}
          </span>
          {data.email ? (
            <span className="value">
              {` ${data.email}`}
            </span>
          ) : (
            <span className="title empty">
              -
            </span>
          )}
        </div>
        <Divider />
        <div className="item">
          <span className="title">{MESSAGES.registration_date}</span>
          {!!data.registration_date && !!data.registration_date.length
            ? <span className="value">{data.registration_date}</span> : <span className="title empty">-</span>}
        </div>
        <Divider />
        <div className="item">
          <span className="title">
            {`${MESSAGES.customer_consent}: `}
          </span>
          {data.accepts_marketing == null
            ? <span className="title empty">-</span>
            : (
              <span className={classNames('value', {
                active: data.accepts_marketing,
              })}
              >
                {data.accepts_marketing ? MESSAGES.yes : MESSAGES.no }
              </span>
            )}
        </div>
      </div>
      <SegmentPopperMetrics
        isLoading={isLoading}
        isOpen={isOpen}
        anchorEl={anchorEl}
        item={item}
        itemData={itemData}
        hasClickableLabel
        placement="right"
      />
    </>
  );
};
InfoForm.propTypes = {
  data: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.bool,
  ])).isRequired,
};

export default InfoForm;
