import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import './styles.scss';
import InfoTooltip from 'components/common/InfoTooltip';

const IconButton = ({
  key,
  className,
  icon: Icon,
  onClick,
  size,
  type,
  uncolored,
  tooltip,
  tooltipPlacement,
}) => (
  <div key={key}>
    <InfoTooltip
      text={tooltip}
      placement={tooltipPlacement}
      delay={750}
      disabled={!tooltip}
    >
      <div
        onClick={onClick}
        className={classNames('icon-button', className, size, type, { 'colored-icon': !uncolored })}
      >
        <Icon className="icon-button__icon" />
      </div>
    </InfoTooltip>
  </div>
);

IconButton.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  type: PropTypes.oneOf(['primary', 'secondary', 'danger']),
  uncolored: PropTypes.bool,
  tooltip: PropTypes.string,
  tooltipPlacement: InfoTooltip.propTypes.placement,
  key: PropTypes.string,
};

IconButton.defaultProps = {
  className: null,
  onClick: noop,
  size: 'medium',
  type: 'primary',
  uncolored: false,
  tooltip: null,
  tooltipPlacement: 'top',
  key: null,
};

export default IconButton;
