import React, { useEffect, useState, useCallback } from 'react';
import Block from 'components/common/Block';
import { MESSAGES } from 'constants/messages';
import { Box, Popper } from '@mui/material';
import Loader from 'components/common/Loader';
import MetricList from 'components/common/MetricList/index';
import MetricListItem from 'components/common/MetricList/MetricListItem';
import { getProduct, getProducts } from 'api/productsAPI';
import PropTypes from 'prop-types';
import './styles.scss';
import { debounce } from 'lodash';

export const ProductPopperMetricsStateHandler = (hasDebounce = true) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [item, setItem] = useState(null);
  const [itemData, setItemData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const isOpen = !!anchorEl;

  const openHoverItem = (event, hoverItem, isHovering) => {
    if (!isHovering || !document.body.contains(event.target)) return;
    setAnchorEl(event.target);
    setItem(hoverItem);
    setItemData(null);
  };

  const debounceOpenHoverItem = useCallback(debounce(openHoverItem, 500), []);

  const handleOnHover = (event, hoverItem, isHovering) => {
    if (!isHovering) {
      setItem(null);
      setAnchorEl(null);
      setItemData(null);
    }
    if (hasDebounce) {
      debounceOpenHoverItem(event, hoverItem, isHovering);
      return;
    }
    openHoverItem(event, hoverItem, isHovering);
  };

  useEffect(() => {
    if (!item || (itemData && item.id === itemData.id)) return;

    const { sku } = item;
    if (!sku) return;

    setIsLoading(true);
    getProducts({ search: sku }).then((searchRes) => {
      if (!searchRes.count) {
        setIsLoading(false);
        return;
      }

      const { id } = searchRes.results[0];
      getProduct(id).then((productRes) => {
        setIsLoading(false);
        setItemData(productRes);
      });
    });
  }, [item]);

  return {
    handleOnHover,
    anchorEl,
    item,
    itemData,
    isLoading,
    isOpen,
  };
};

const ProductPopperMetrics = ({
  isOpen,
  anchorEl,
  item,
  isLoading,
  itemData,
}) => (
  <Popper open={isOpen} anchorEl={anchorEl} placement="top">
    <Block
      header={item && item.title}
      postHeader={MESSAGES.yearly_performance}
      className="product-metric-list"
    >
      {isLoading && (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Loader color="white" />
        </Box>
      )}
      {!isLoading && !itemData && (
        <div className="empty-state">
          {MESSAGES.no_data_available}
        </div>
      )}
      {!isLoading && itemData && (
        <>
          <MetricList>
            <MetricListItem
              title={MESSAGES.items_sold}
              value={itemData.items_sold}
              trendPercentage={itemData.items_sold_last_month_percentage}
            />
            <MetricListItem
              title={MESSAGES.revenue}
              value={itemData.revenue}
              trendPercentage={itemData.revenue_last_month_percentage}
              valuePrefix={itemData.currency}
            />
            <MetricListItem
              title={MESSAGES.profit}
              value={itemData.profit}
              trendPercentage={itemData.profit_last_month_percentage}
              valuePrefix={itemData.currency}
            />
            <MetricListItem
              title={MESSAGES.first_order_rate}
              value={itemData.first_order_rate}
              trendPercentage={itemData.first_order_rate_last_month_percentage}
              valueSuffix="%"
            />
            <MetricListItem
              title={MESSAGES.bought_uniquely_rate}
              value={itemData.bought_uniquely_rate}
              trendPercentage={itemData.bought_uniquely_rate_last_month_percentage}
              valueSuffix="%"
            />
          </MetricList>
          <div className="click-label">
            {MESSAGES.click_to_open}
          </div>
        </>
      )}
    </Block>
  </Popper>
);

ProductPopperMetrics.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  anchorEl: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }),
  isLoading: PropTypes.bool.isRequired,
  itemData: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    items_sold: PropTypes.number.isRequired,
    items_sold_last_month_percentage: PropTypes.number.isRequired,
    revenue: PropTypes.number.isRequired,
    revenue_last_month_percentage: PropTypes.number.isRequired,
    profit: PropTypes.number.isRequired,
    profit_last_month_percentage: PropTypes.number.isRequired,
    first_order_rate: PropTypes.number.isRequired,
    first_order_rate_last_month_percentage: PropTypes.number.isRequired,
    bought_uniquely_rate: PropTypes.number.isRequired,
    bought_uniquely_rate_last_month_percentage: PropTypes.number.isRequired,
  }),
};

ProductPopperMetrics.defaultProps = {
  anchorEl: undefined,
  item: undefined,
  itemData: undefined,
};

export default ProductPopperMetrics;
