import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PieChart } from 'react-minimal-pie-chart';
import ReactTooltip from 'react-tooltip';

import './styles.scss';
import { MESSAGES } from 'constants/messages';
import Block from 'components/common/Block';
import { GUIDES } from 'constants/guides';

const ProductSegmentsChart = ({ customer_segments, customerSegmentsMap }) => {
  const segmentData = customer_segments && Object?.keys(customer_segments).map((key) => ({
    key: String(key),
    value: customer_segments[key],
    title: customerSegmentsMap?.[key]?.segment_name,
    color: customerSegmentsMap?.[key]?.color_1,
  })).sort((a, b) => b.value - a.value);

  const [hovered, setHovered] = useState(null);

  return (
    <Block
      postHeader={MESSAGES.customer_segments_order_rate}
      guide={GUIDES.SP12}
      className="product-segment-chart-wrapper"
    >
      {(segmentData?.length) ? (
        <div className="chart-wrapper">
          <div className="pie-chart" data-tip="" data-for="chart">
            <PieChart
              animate
              data={segmentData}
              onMouseOver={(_, index) => {
                setHovered(index);
              }}
              onMouseOut={() => {
                setHovered(null);
              }}
            />
            <ReactTooltip
              id="chart"
              getContent={() => (typeof hovered === 'number' ? `${segmentData[hovered].value}%` : null)}
            />
          </div>
          <div className="legend-wrapper">
            {segmentData?.map((segment) => (
              <div className="legend" key={segment.key}>
                <div style={{
                  backgroundColor: segment?.color,
                }}
                />
                {segment?.title}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="empty-state">
          <span className="no-data">{MESSAGES.no_data_available}</span>
        </div>
      )}
    </Block>
  );
};

ProductSegmentsChart.propTypes = {
  customer_segments: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  customerSegmentsMap: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.any])),
};

ProductSegmentsChart.defaultProps = {
  customer_segments: undefined,
  customerSegmentsMap: undefined,
};

export default ProductSegmentsChart;
