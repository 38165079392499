import React, { useRef } from 'react';
import { Box, Stack } from '@mui/material';
import MultiSelectItem from 'components/common/MultiSelect/MultiSelectItem';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import './styles.scss';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from 'components/common/Loader';

const MultiSelect = ({
  options, selected, onSelect, onDeselect, maxSelection,
  loadNext, hasMore,
}) => {
  const atMaxSelection = maxSelection && selected.length >= maxSelection;

  const wrapperRef = useRef(null);
  const handleItemSelect = (itemKey, e) => {
    e.stopPropagation();
    e.preventDefault();
    if (selected.includes(itemKey)) {
      onDeselect(itemKey, e);
      return;
    }
    if (atMaxSelection) return;
    onSelect(itemKey, e);
  };

  const scrollLoader = (
    <Box
      display="flex"
      justifyContent="center"
    >
      <Loader color="black" />
    </Box>
  );

  return (
    <div className="multi-select" ref={wrapperRef} id="scroll-ref">
      <Stack>
        <InfiniteScroll
          dataLength={options.length}
          next={loadNext}
          hasMore={loadNext ? hasMore : false}
          loader={scrollLoader}
          scrollableTarget="scroll-ref"
        >
          {options.map(({ content, key, leftContent }) => (
            <div key={key}>
              <MultiSelectItem
                itemKey={key}
                selected={selected.includes(key)}
                leftContent={leftContent}
                onSelect={(itemKey, e) => handleItemSelect(itemKey, e)}
                disabled={!selected.includes(key) && atMaxSelection}
              >
                {content}
              </MultiSelectItem>
            </div>
          ))}
        </InfiniteScroll>
      </Stack>
    </div>
  );
};

MultiSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    content: PropTypes.node.isRequired,
    leftContent: PropTypes.node,
  })).isRequired,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelect: PropTypes.func,
  onDeselect: PropTypes.func,
  maxSelection: PropTypes.number,
  loadNext: PropTypes.func,
  hasMore: PropTypes.bool,
};

MultiSelect.defaultProps = {
  onSelect: noop,
  onDeselect: noop,
  maxSelection: null,
  loadNext: noop,
  hasMore: false,
};

export default MultiSelect;
