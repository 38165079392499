import React, { useCallback, useState } from 'react';
import { getDateRange } from 'utils/common';
import { EXPERIMENT_GROUP_BY } from 'constants/experiments';
import { Line } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import moment from 'moment';
import './styles.scss';
import { getColorForGroup } from 'components/views/Experiments/utils';
import { debounce } from 'lodash';

const ExperimentGraph = ({
  startDate,
  endDate,
  groupBy,
  groupResults,
  currency,
}) => {
  const [highlightedGroup, setHighlightedGroup] = useState(null);

  const handleGroupHighlight = (highlightedIndex) => {
    if (highlightedGroup === highlightedIndex) return;
    setHighlightedGroup(highlightedIndex);
  };

  const onGroupHover = useCallback(debounce(handleGroupHighlight, 100), [highlightedGroup]);

  const getLabels = () => {
    const labels = [];
    const dateformat = groupBy.value === EXPERIMENT_GROUP_BY.DAY.value
      ? 'DD-MM-YYYY'
      : 'MMMM, YYYY';

    const dateType = groupBy.value === EXPERIMENT_GROUP_BY.DAY.value
      ? 'day' : 'month';

    getDateRange(startDate, endDate, dateType).forEach((date) => {
      labels.push(`${date.format(dateformat)}`);
    });

    return labels;
  };

  const options = {
    onHover: (e, activeEls) => onGroupHover(activeEls.length ? activeEls[0].datasetIndex : null),
    responsive: true,
    tension: 0.3,
    maintainAspectRatio: false,
    interaction: {
      mode: 'point',
      intersect: false,
    },
    stacked: false,
    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
      },
    },
    plugins: {
      legend: {
        position: 'bottom',
        onHover: (e, item) => onGroupHover(item.datasetIndex),
        onLeave: () => onGroupHover(null),
      },
      tooltip: {
        backgroundColor: '#fbfcfd',
        titleColor: '#869b9b',
        titleFont: { weight: 'normal' },
        displayColors: false,
        bodyColor: '#869b9b',
        bodyFont: { weight: 'bold' },
        cornerRadius: 5,
        callbacks: {
          label(context) {
            return `${context.dataset.label}: ${context.formattedValue} ${currency || ''}`;
          },
        },
      },
    },
  };

  const getGroupOpacity = (group) =>
    (highlightedGroup !== null && group !== highlightedGroup ? 0.1 : 1);

  const getDataSets = () => groupResults.filter((group) => !!group?.metrics?.length)
    .map((group, index) => ({
      label: group.name,
      data: group.metrics,
      borderColor: getColorForGroup(group.order, groupResults.length, getGroupOpacity(index)),
      backgroundColor: getColorForGroup(group.order, groupResults.length, getGroupOpacity(index)),
    }));

  const data = {
    labels: getLabels(),
    datasets: getDataSets(),
  };

  return (
    <div className="experiment-graph-wrapper">
      <Line
        options={options}
        data={data}
      />
    </div>
  );
};

ExperimentGraph.propTypes = {
  startDate: PropTypes.instanceOf(moment).isRequired,
  endDate: PropTypes.instanceOf(moment).isRequired,
  groupBy: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  groupResults: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    order: PropTypes.number.isRequired,
    metrics: PropTypes.arrayOf(PropTypes.number).isRequired,
  })).isRequired,
  currency: PropTypes.string,
};

ExperimentGraph.defaultProps = {
  currency: null,
};

export default ExperimentGraph;
