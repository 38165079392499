import React from 'react';
import 'components/views/Settings/Settings/BillingDetails/Billing/CustomerInfo/styles.scss';
import propTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getCurrentLicense } from 'store/license/getters';
import { MESSAGES } from 'constants/messages';

const CustomerInfo = ({
  cardHolderName, customer,
}) => {
  const currentLicense = useSelector(getCurrentLicense);

  const isInfoEmpty = !cardHolderName && !customer.email && !customer.address?.line1
    && !customer.address?.postal_code && !customer.address?.city;

  return (
    <div className="info">
      <div className="license-img">
        {' '}
        {
          currentLicense.avatar ? (
            <img src={currentLicense.avatar} alt="Company logo" />
          ) : (
            <img src="/static/img/companyDefaultLogo.svg" className="company-name__img" alt="Company logo" />
          )
      }
      </div>
      <div className="customer-text">
        {isInfoEmpty ? (
          <div>
            {MESSAGES.no_info}
          </div>
        ) : (
          <>
            <div className="card-holder-name__value">
              {cardHolderName}
            </div>
            <div className="card-holder-email__value">
              {customer.email}
            </div>
            <div className="card-holder-street-name__value">
              {customer.address?.line1}
            </div>
            <div className="card-holder-postal-code__value">
              {customer.address?.postal_code}
            </div>
            <div className="card-holder-city__value">
              {customer.address?.city}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

CustomerInfo.propTypes = {
  cardHolderName: propTypes.string,
  customer: propTypes.object,
};

CustomerInfo.defaultProps = {
  cardHolderName: undefined,
  customer: undefined,
};

export default CustomerInfo;
