import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PieChart } from 'react-minimal-pie-chart';
import ReactTooltip from 'react-tooltip';

import 'components/views/Products/SingleCategoryView/CategorySegmentsChart/styles.scss';
import { MESSAGES } from 'constants/messages';
import Block from 'components/common/Block';
import { GUIDES } from 'constants/guides';

const CategorySegmentsChart = ({ customer_segments, productsInSegments, segmentsMap }) => {
  const segmentData = customer_segments && Object?.keys(customer_segments)?.map((key) => ({
    key: String(key),
    value: customer_segments[String(key)],
    title: segmentsMap?.[String(key)]?.segment_name,
    color: segmentsMap?.[String(key)]?.color_1,
  })).sort((a, b) => b.value - a.value);

  const [hovered, setHovered] = useState(null);

  const productsNumbersList = productsInSegments && Object.keys(productsInSegments)
    .map((key) => ({
      key: String(key),
      value: productsInSegments?.[key],
    })).sort((a, b) => b.value - a.value);

  return (
    <Block
      className="category-segment-chart-wrapper"
      postHeader={MESSAGES.customer_segments_order_rate}
      guide={GUIDES.SC9}
    >
      {(segmentData?.length) ? (
        <div className="chart-wrapper">
          <div className="pie-chart" data-tip="" data-for="chart">
            <PieChart
              animate
              data={segmentData}
              onMouseOver={(_, index) => {
                setHovered(index);
              }}
              onMouseOut={() => {
                setHovered(null);
              }}
            />
            <ReactTooltip
              id="chart"
              getContent={() => (typeof hovered === 'number' ? `${segmentData[hovered].value}%` : null)}
            />
          </div>
          <div className="legend-wrapper">
            {segmentData?.map((segment) => (
              <div className="legend" key={segment.key}>
                <div style={{
                  backgroundColor: segment?.color,
                }}
                />
                {segment?.title}
              </div>
            ))}
          </div>
          <div className="products-numbers-in-segments">
            {productsNumbersList?.length && productsNumbersList.map((item) => (
              <div className="product-number" key={item.key}>
                {item.value}
                <span>{MESSAGES.products.toLowerCase()}</span>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="empty-state">
          <span className="no-data">{MESSAGES.no_data_available}</span>
        </div>
      )}
    </Block>
  );
};

CategorySegmentsChart.propTypes = {
  customer_segments: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  segmentsMap: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.any])),
  productsInSegments: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.any])),
};

CategorySegmentsChart.defaultProps = {
  customer_segments: undefined,
  segmentsMap: undefined,
  productsInSegments: undefined,
};

export default CategorySegmentsChart;
