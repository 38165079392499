import React, { useCallback, useState } from 'react';
import 'components/common/Search/styles.scss';
import Input from 'components/common/Input';
import PropTypes from 'prop-types';
import { debounce, noop } from 'lodash';

const Search = ({
  title, search, searchChanged, onFocus, onSearchClick, useDebounce,
}) => {
  const [value, setValue] = useState(search);

  const debounceFn = useCallback(debounce(searchChanged, 1000), []);

  const handleValueChange = ({ target }) => {
    setValue(target.value);
    if (useDebounce) debounceFn(target.value);
  };

  const handleSearchClick = (e) => {
    searchChanged(value);
    onSearchClick(value, e);
  };

  const handleBlur = () => searchChanged(value);

  return (
    <Input
      id="table-search"
      name="table-search"
      label={title}
      value={value}
      onChange={handleValueChange}
      onSearchClick={handleSearchClick}
      onBlur={handleBlur}
      hasSearch
      isBlurOnEnter
      onFocus={onFocus}
    />
  );
};

Search.propTypes = {
  title: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
  searchChanged: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onSearchClick: PropTypes.func,
  useDebounce: PropTypes.bool,
};

Search.defaultProps = {
  onFocus: noop,
  onSearchClick: noop,
  useDebounce: false,
};

export default Search;
