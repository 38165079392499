import React from 'react';

import { EXTERNAL_LINK } from 'constants/routes';
import { MESSAGES } from 'constants/messages';

const BaseFooter = () => (
  <footer className="base-footer">
    <a href={EXTERNAL_LINK.custimyTermsOfBusiness} target="_blank" className="footer__link" rel="noreferrer">{MESSAGES.terms_of_business}</a>
    <a href={EXTERNAL_LINK.custimyPrivacyPolicy} target="_blank" className="footer__link" rel="noreferrer">{MESSAGES.privacy_policy}</a>
  </footer>
);

export default BaseFooter;
