import { http } from 'utils';
import { ENDPOINTS } from 'constants/routes';
import { HTTP_METHODS } from 'constants/http';
import { ENTITY_CREATOR } from 'constants/entities';
import { httpFile } from 'utils/file';

const prepareTagsParams = (tags) => {
  const userUuids = tags
    .filter(({ entityKey }) => entityKey === ENTITY_CREATOR)
    .map(({ uuid }) => uuid);

  return `&creator_uuid__in=${userUuids.join(',')}`;
};

export const getCohortsList = ({
  page = 1, page_size = '', ordering, search = '', tags = [],
}) => http({
  url: `${ENDPOINTS.cohorts}?page=${page}&page_size=${page_size}&ordering=${ordering}&search=${search}${prepareTagsParams(tags)}`,
  method: HTTP_METHODS.GET,
});

export const getCohort = (uuid) => http({
  url: `${ENDPOINTS.cohorts}${uuid}/`,
  method: HTTP_METHODS.GET,
});

export const createCohort = (data) => http({
  url: ENDPOINTS.cohorts,
  method: HTTP_METHODS.POST,
  data,
});

export const updateCohort = (uuid, data) => http({
  url: `${ENDPOINTS.cohorts}${uuid}/`,
  method: HTTP_METHODS.PUT,
  data,
});

export const getCohortData = (uuid) => http({
  url: `${ENDPOINTS.cohortsData.replace(':uuid', uuid)}`,
  method: HTTP_METHODS.GET,
});

export const exportCohortData = (uuid) => httpFile({
  url: `${ENDPOINTS.cohortsDataExport.replace(':uuid', uuid)}`,
});

export const deleteCohort = (uuid) => http({
  url: `${ENDPOINTS.cohorts}${uuid}/`,
  method: HTTP_METHODS.DELETE,
});
