import { MESSAGES } from 'constants/messages';
import React from 'react';
import EmptyTemplate from 'components/views/Homepage/EmptyStates/EmptyTemplate';

const NoDataState = () => (
  <div className="empty-state-wrapper">
    <EmptyTemplate title={MESSAGES.no_information_to_display}>
      <span>{MESSAGES.check_your_integrations}</span>
    </EmptyTemplate>
  </div>
);

export default NoDataState;
