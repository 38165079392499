import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { ReactComponent as ArrowIcon } from 'assets/svg/pointerArrow.svg';
import classNames from 'classnames';
import './styles.scss';

const TrendIndicator = ({ percentage, type, label }) => {
  if (!percentage) percentage = 0;
  const isUnmoved = percentage === 0;
  const isPositive = percentage > 0;
  const indicatorSign = isPositive ? '+' : '';
  const indicatorClasses = {
    up: isPositive,
    down: !isPositive && !isUnmoved,
    unmoved: isUnmoved,
  };

  return (
    <Box className={classNames('trend-indicator', indicatorClasses, type)}>
      <div className="trend-inner-wrapper">
        <ArrowIcon className="trend-arrow" />
        <span className="trend-percentage">
          {`${indicatorSign}${percentage}%`}
        </span>
        {!!label && (
          <span className="trend-label">
            {label}
          </span>
        )}
      </div>
    </Box>
  );
};

TrendIndicator.propTypes = {
  percentage: PropTypes.number.isRequired,
  type: PropTypes.oneOf(['default', 'white']),
  label: PropTypes.string,
};

TrendIndicator.defaultProps = {
  type: 'default',
  label: '',
};

export default TrendIndicator;
