import React, {
  useState, useMemo, useEffect,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import './styles.scss';
import { DETAIL_STEPS } from 'constants/common';
import useForm from 'hooks/useForm';
import useRouter from 'hooks/useRouter';
import {
  renderStepComponent,
  renderStepValidation,
  submitHandlerByStep,
  prepareLicenseFromJSON,
} from './utils';
import { ROUTES } from 'constants/routes';
import useAsync from 'hooks/useAsync';
import { updateCurrentUser } from 'store/profile/profileSlice';
import { getCurrentLicense } from 'store/license/getters';
import { getCurrentUser } from 'store/profile/getters';
import { updateLicenseDetailsAction } from 'store/license/licenseSlice';

const Details = () => {
  const currentUser = useSelector(getCurrentUser);
  const currentLicense = useSelector(getCurrentLicense);
  const dispatch = useDispatch();
  const [step, setStep] = useState(DETAIL_STEPS.personal);
  const ComponentByStep = useMemo(() => renderStepComponent(step), [step]);
  const validationByStep = useMemo(() => renderStepValidation(step), [step]);
  const { replace, location } = useRouter();

  const checkUserDetails = () => {
    if (!currentUser?.first_name) {
      setStep(DETAIL_STEPS.personal);
      return;
    }
    if (!currentLicense?.company_name) {
      setStep(DETAIL_STEPS.company);
      return;
    }

    replace(ROUTES.home, location?.state);
  };

  const dispatchDetailsByStep = (details) => {
    switch (step) {
      case DETAIL_STEPS.personal:
        dispatch(updateCurrentUser(details));
        break;
      case DETAIL_STEPS.company:
        dispatch(updateLicenseDetailsAction(details));
        break;
      default:
    }
  };

  const submitStepHandler = async (values, setErrors) => {
    const responseDetailsByStep = await submitHandlerByStep(step)(
      { ...values, isActive: true },
      setErrors, location?.state?.license,
    );

    if (responseDetailsByStep) {
      dispatchDetailsByStep(responseDetailsByStep);
    }
  };

  const { execute, status } = useAsync(submitStepHandler);
  const {
    handleSubmit, handleChange, handleBlur, values, errors, touched,
  } = useForm(
    execute,
    validationByStep,
    prepareLicenseFromJSON(location?.state?.license),
  );

  const stepProps = {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    status,
  };

  useEffect(() => {
    checkUserDetails();
  }, [currentUser, currentLicense]);

  return (
    <main className="details main">
      <div className="details__content">
        <ComponentByStep {...stepProps} />
      </div>
    </main>
  );
};

export default Details;
