import React, { useEffect, useState, useCallback } from 'react';
import Block from 'components/common/Block';
import { MESSAGES } from 'constants/messages';
import { Box, Popper } from '@mui/material';
import Loader from 'components/common/Loader';
import MetricList from 'components/common/MetricList/index';
import MetricListItem from 'components/common/MetricList/MetricListItem';
import PropTypes from 'prop-types';
import './styles.scss';
import { getSegmentById } from 'api/segmentsAPI';
import { debounce } from 'lodash';

export const SegmentPopperMetricsStateHandler = (hasDebounce = true) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [item, setItem] = useState(null);
  const [itemData, setItemData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const isOpen = !!anchorEl;

  const openHoverItem = (event, hoverItem, isHovering) => {
    if (!isHovering || !document.body.contains(event.target)) return;
    setAnchorEl(event.target);
    setItem(hoverItem);
    setItemData(null);
  };

  const debounceOpenHoverItem = useCallback(debounce(openHoverItem, 500), []);

  const handleOnHover = (event, hoverItem, isHovering) => {
    if (!isHovering) {
      setItem(null);
      setAnchorEl(null);
      setItemData(null);
    }
    if (hasDebounce) {
      debounceOpenHoverItem(event, hoverItem, isHovering);
      return;
    }
    openHoverItem(event, hoverItem, isHovering);
  };

  useEffect(() => {
    if (!item || (itemData && item.id === itemData.id)) return;

    const { id } = item;
    setIsLoading(true);
    getSegmentById({ id }).then((res) => {
      if (!res) {
        setIsLoading(false);
        return;
      }

      setIsLoading(false);
      setItemData(res);
    });
  }, [item]);

  return {
    handleOnHover,
    anchorEl,
    item,
    itemData,
    isLoading,
    isOpen,
  };
};

const SegmentPopperMetrics = ({
  isOpen,
  anchorEl,
  item,
  itemData,
  isLoading,
  placement,
  hasClickableLabel,
}) => (
  <Popper
    open={isOpen}
    anchorEl={anchorEl}
    placement={placement}
    className="segment-popper-overlay"
  >
    <Block
      header={item && item.segment_name}
      postHeader={MESSAGES.alltime_performance}
      className="segment-metric-list"
    >
      {isLoading && (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Loader color="white" />
        </Box>
      )}
      {!isLoading && !itemData && (
        <div className="empty-state">
          {MESSAGES.no_data_available}
        </div>
      )}
      {!isLoading && itemData && (
        <>
          <MetricList>
            <MetricListItem
              title={MESSAGES.customers}
              value={itemData.cus_num}
              trendPercentage={itemData.cus_num_last_month_percentage}
            />
            <MetricListItem
              title={MESSAGES.total_sales}
              value={itemData.total_sales}
              trendPercentage={itemData.total_sales_last_month_percentage}
              valuePrefix={itemData.currency}
            />
            <MetricListItem
              title={MESSAGES.total_profit}
              value={itemData.total_profit}
              trendPercentage={itemData.total_profit_last_month_percentage}
              valuePrefix={itemData.currency}
            />
            <MetricListItem
              title={MESSAGES.order_value_avg}
              value={itemData.avg_order_size}
              trendPercentage={itemData.avg_order_size_last_month_percentage}
              valuePrefix={itemData.currency}
            />
            <MetricListItem
              title={MESSAGES.avg_count_orders}
              value={itemData.avg_orders}
              trendPercentage={itemData.avg_orders_last_month_percentage}
            />
          </MetricList>
          {hasClickableLabel && (
            <div className="click-label">
              {MESSAGES.click_to_open}
            </div>
          )}
        </>
      )}
    </Block>
  </Popper>
);

SegmentPopperMetrics.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  anchorEl: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  item: PropTypes.shape({
    segment_name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }),
  isLoading: PropTypes.bool.isRequired,
  placement: Popper.propTypes.placement,
  hasClickableLabel: PropTypes.bool,
  itemData: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    cus_num: PropTypes.number.isRequired,
    cus_num_last_month_percentage: PropTypes.number.isRequired,
    total_sales: PropTypes.number.isRequired,
    total_sales_last_month_percentage: PropTypes.number.isRequired,
    total_profit: PropTypes.number.isRequired,
    total_profit_last_month_percentage: PropTypes.number.isRequired,
    avg_order_size: PropTypes.number.isRequired,
    avg_order_size_last_month_percentage: PropTypes.number.isRequired,
    avg_orders: PropTypes.number.isRequired,
    avg_orders_last_month_percentage: PropTypes.number.isRequired,
  }),
};

SegmentPopperMetrics.defaultProps = {
  anchorEl: undefined,
  item: undefined,
  itemData: undefined,
  placement: 'top',
  hasClickableLabel: false,
};

export default SegmentPopperMetrics;
