import React from 'react';
import './styles.scss';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import classNames from 'classnames';

export const LinkButton = ({
  children,
  onClick,
  icon: Icon,
  type,
  size,
  className,
  onHover,
  underlined,
  href,
  hrefNewTab,
}) => (
  <a
    className={classNames(className, 'link-button', type, size, { underlined })}
    onClick={onClick}
    onMouseEnter={(e) => onHover(e, true)}
    onMouseLeave={(e) => onHover(e, false)}
    href={href}
    target={hrefNewTab ? '_blank' : '_self'}
  >
    <span className="label">
      {children}
    </span>
    {Icon && (
      <div className="icon-wrapper">
        <Icon className="icon" />
      </div>
    )}
  </a>
);

LinkButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['primary', 'secondary', 'white', 'danger', 'success']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  className: PropTypes.string,
  underlined: PropTypes.bool,
  onHover: PropTypes.func,
  href: PropTypes.string,
  hrefNewTab: PropTypes.bool,
};

LinkButton.defaultProps = {
  children: undefined,
  onClick: noop,
  type: 'primary',
  size: 'small',
  className: undefined,
  underlined: false,
  onHover: noop,
  href: null,
  hrefNewTab: false,
};
