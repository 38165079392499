import React, { useState } from 'react';
import useListApi from 'hooks/useListApi';
import {
  BUTTON_TYPE, LICENSES_PER_PAGE,
} from 'constants/common';
import { ActionsCell, DefaultCell, IdCell } from 'components/common/Table/TableCells';
import {
  CreatedAtCell,
  NameCell,
  StatusCell,
} from 'components/views/Admin/Licenses/LicensesCells';
import { getLicensesList } from 'api/licenseAPI';
import { ChoicesCell } from 'components/views/Admin/AdminCells';
import { ROUTES } from 'constants/routes';
import useRouter from 'hooks/useRouter';
import useSwitchLicense from 'hooks/useSwitchLicense';
import DeleteBundleModal from 'components/views/Admin/Licenses/DeleteBundleModal';
import DeleteLicenseModal from 'components/common/DeleteLicenseModal';
import ReactivateLicenseModal from 'components/views/Admin/Licenses/ReactivateLicenseModal';
import { ENTITY_INTEGRATION, ENTITY_USER } from 'constants/entities';
import { MESSAGES } from 'constants/messages';
import Block from 'components/common/Block';
import { Box, Grid, Stack } from '@mui/material';
import Search from 'components/common/Search';
import TableSearchEntity from 'components/common/TableSearchEntity';
import Checkbox from 'components/common/Checkbox';
import FilterTags from 'components/common/FilterTags';
import PaginatedTable from 'components/common/Table/PaginatedTable';
import Button from 'components/common/Button';
import { LIST_API_REDUX_KEYS } from 'constants/listApi';

const getTableConfig = (actions, showStatus) => [{
  title: 'Name',
  key: 'company_name',
  ordering: ['company_name'],
  CellComponent: NameCell,
  componentProps: {},
  width: '180px',
  hasOverflowEllipsis: true,
}, {
  title: 'Type',
  key: 'business_category',
  ordering: ['business_category'],
  CellComponent: ChoicesCell,
  componentProps: {},
  width: '150px',
  hasOverflowEllipsis: true,
}, {
  title: 'Integrations',
  key: 'integrations_count',
  ordering: null,
  CellComponent: DefaultCell,
  componentProps: {},
  width: '90px',
  hasOverflowEllipsis: true,
}, {
  title: 'ID',
  key: 'uuid',
  ordering: ['uuid'],
  CellComponent: IdCell,
  componentProps: {},
  width: '110px',
  hasOverflowEllipsis: false,
}, {
  title: 'Payment Method ID',
  key: 'subscription.payment_method_id',
  ordering: ['subscription__payment_method_id'],
  CellComponent: IdCell,
  componentProps: {},
  width: '150px',
  hasOverflowEllipsis: false,
}, {
  title: 'Bundle code',
  key: 'bundle.title',
  ordering: ['bundle__title'],
  CellComponent: DefaultCell,
  componentProps: {},
  width: '15%',
  hasOverflowEllipsis: true,
}, {
  title: 'Created',
  key: 'created_at',
  ordering: ['created_at'],
  CellComponent: CreatedAtCell,
  componentProps: {},
  width: '70px',
  hasOverflowEllipsis: true,
}, showStatus && {
  title: 'Status',
  key: 'is_delete',
  ordering: ['is_delete'],
  CellComponent: StatusCell,
  componentProps: {},
  width: '70px',
  hasOverflowEllipsis: true,
}, {
  title: null,
  key: null,
  ordering: null,
  CellComponent: ActionsCell,
  componentProps: { actions },
  width: '40px',
  hasOverflowEllipsis: false,
}].filter((item) => !!item);

const searchEntityConfig = [{
  key: ENTITY_USER,
  title: 'Users',
  hasPages: true,
}, {
  key: ENTITY_INTEGRATION,
  title: 'Integrations',
  hasPages: false,
}];

const Licenses = () => {
  const defaultConfig = {
    ordering: ['-created_at'],
    is_delete: false,
  };
  const {
    data,
    totalCount,
    isDataLoading,
    config,
    changeConfig,
  } = useListApi(LIST_API_REDUX_KEYS.adminPanelLicenses, defaultConfig, getLicensesList);

  const [licenseForBundleDelete, setLicenseForBundleDelete] = useState({});
  const [licenseForDeletion, setLicenseForDeletion] = useState({});
  const [licenseForReactivation, setLicenseForReactivation] = useState({});

  const { push } = useRouter();
  const switchLicense = useSwitchLicense();
  const createLicense = () => push(`${ROUTES.newLicense}?from-admin-panel=true`);

  const handleTagsChange = (tags) => changeConfig({ tags });
  const handleSortChange = (ordering) => changeConfig({ ordering });
  const handlePageChange = (page) => changeConfig({ page });
  const handleSearchChange = (search) => changeConfig({ search });
  const handleShowDeletedChange = () => changeConfig({ is_delete: config.is_delete === false ? '' : false });
  const handleDeleteBundleSubmit = () => {
    setLicenseForBundleDelete({});
    changeConfig({ page: config.page });
  };
  const handleLicenseReactivateSubmit = () => {
    setLicenseForReactivation({});
    changeConfig({ page: config.page });
  };

  const handleRowClick = (row) => {
    if (!row.is_delete && row.user_has_access) switchLicense(row);
  };

  const actions = [{
    name: 'Log in',
    action: (x) => switchLicense(x.row),
    shouldShow: (x) => !x.row.is_delete && x.row.user_has_access,
  }, {
    name: 'Remove bundle code',
    action: (x) => setLicenseForBundleDelete(x.row),
    shouldShow: (x) => x.row.bundle && !x.row.is_delete,
  }, {
    name: 'Delete License',
    action: (x) => setLicenseForDeletion(x.row),
    shouldShow: (x) => !x.row.is_delete,
  }, {
    name: 'Reactivate License',
    action: (x) => setLicenseForReactivation(x.row),
    shouldShow: (x) => x.row.is_delete,
  }];
  const tableConfig = getTableConfig(actions, config.is_delete === '');

  return (
    <>
      <Block header={MESSAGES.licenses}>
        <Grid container rowSpacing={2}>
          <Grid item container spacing={2} xs={12}>
            <Grid item md={4} xs={12}>
              <Search
                search={config.search}
                title={MESSAGES.search_by_name_id}
                searchChanged={handleSearchChange}
              />
            </Grid>
            <Grid lg={4} md={5} xs={8} item container spacing={2}>
              <Grid item>
                <TableSearchEntity
                  searchEntityConfig={searchEntityConfig}
                  tags={config.tags}
                  tagsChanged={handleTagsChange}
                />
              </Grid>
              <Grid item component={Stack} justifyContent="center">
                <Checkbox
                  label={MESSAGES.show_deleted}
                  value={config.is_delete === ''}
                  onChange={handleShowDeletedChange}
                />
              </Grid>
            </Grid>
            <Grid item lg={4} md={3} xs={4}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  type={BUTTON_TYPE.primary}
                  onClick={createLicense}
                >
                  {MESSAGES.new_license}
                </Button>
              </Box>
            </Grid>
            {!!config.tags.length && (
              <Grid item xs={12}>
                <FilterTags
                  tags={config.tags}
                  tagsChanged={handleTagsChange}
                />
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <PaginatedTable
              sort={config.ordering}
              sortChanged={handleSortChange}
              totalCount={totalCount}
              tableConfig={tableConfig}
              data={data}
              highlightStartIdx={0}
              highlightEndIdx={7}
              countPerPage={LICENSES_PER_PAGE}
              handleRowClick={handleRowClick}
              page={config.page}
              pageChanged={handlePageChange}
              isDataLoading={isDataLoading}
            />
          </Grid>
        </Grid>
      </Block>
      <DeleteBundleModal
        license={licenseForBundleDelete}
        onClose={() => setLicenseForBundleDelete({})}
        onSubmit={handleDeleteBundleSubmit}
      />
      <DeleteLicenseModal
        license={licenseForDeletion}
        onClose={() => setLicenseForDeletion({})}
        onSubmit={() => changeConfig({ page: 1 })}
      />
      <ReactivateLicenseModal
        license={licenseForReactivation}
        onClose={() => setLicenseForReactivation({})}
        onSubmit={handleLicenseReactivateSubmit}
      />
    </>
  );
};

Licenses.propTypes = {};

export default Licenses;
