import { HTTP_STATUS } from 'constants/http';
import { http } from 'utils';
import { ENDPOINTS, ROUTES } from 'constants/routes';
import {
  refreshTokenLSManager,
  accessTokenLSManager,
} from 'helper/localStorage';
import { cleanUpPrivateStorage } from 'utils/common';
import { getRefreshToken } from 'api/authAPI';
import { INTERNAL_SERVER_ERROR_TEXT } from 'constants/errors';
import axios from 'axios';

export const repeatRequest = ({
  method, url, data, headers,
}) => http({
  method,
  url: url.replace(ENDPOINTS.baseUrl, ''),
  data,
  useToken: !!headers?.Authorization,
});

export const refreshTokenHandler = async ({ config }) => {
  const refreshToken = refreshTokenLSManager.get();

  if (!refreshToken) {
    cleanUpPrivateStorage();
    return undefined;
  }

  try {
    const { access: updatedAccessToken } = await getRefreshToken({ refresh: refreshToken });

    if (!updatedAccessToken) {
      cleanUpPrivateStorage();
      return undefined;
    }

    accessTokenLSManager.set(updatedAccessToken);
    return { response: await repeatRequest(config) };
  } catch (error) {
    cleanUpPrivateStorage();
    window.location.href = ROUTES.portal;
  }

  return undefined;
};

export const handleError = (error, errorBody) => {
  if (axios.isCancel(errorBody)) {
    return { cancel: errorBody };
  }

  switch (error.status) {
    case HTTP_STATUS.unauthorized: {
      return refreshTokenHandler(error);
    }
    case HTTP_STATUS.forbidden: {
      return {
        error: Object.assign(error, { data: errorBody.data }),
      };
    }
    // no need to keep backend stack trace inside FE code
    case HTTP_STATUS.internal: {
      return {
        error: Object.assign(error, { data: INTERNAL_SERVER_ERROR_TEXT }),
      };
    }
    default:
      return { error };
  }
};
