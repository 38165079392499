import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import Popup from 'reactjs-popup';
import moment from 'moment';

import 'components/views/Admin/Bundles/BundleModal/styles.scss';
import Button from 'components/common/Button';
import { REQUEST_STATUS, BUTTON_TYPE } from 'constants/common';
import useAsync from 'hooks/useAsync';
import { ReactComponent as BundleIcon } from 'assets/svg/segments.svg';
import { ReactComponent as OkIcon } from 'assets/svg/ok.svg';
import useForm from 'hooks/useForm';
import validation from 'components/views/Admin/Bundles/BundleModal/bundleValidation';
import Input from 'components/common/Input';
import { createBundle, updateBundle } from 'api/bundleAPI';
import Checkbox from 'components/common/Checkbox';
import Datepicker from 'components/common/Datepicker';
import { MESSAGES } from 'constants/messages';
import { Stack } from '@mui/material';

const BundleModal = ({ bundle, onClose, onSubmit }) => {
  const [step, setStep] = useState(1);
  const isEdit = !!(bundle && bundle.uuid);

  useEffect(() => {
    cleanUpForm();
  }, [bundle]);

  const submitHandler = async (data) => {
    const requestBody = {
      ...data,
      expiration_date: data.expiration_date && moment(data.expiration_date).toISOString(),
    };

    if (isEdit) {
      await updateBundle(requestBody.uuid, requestBody);
    } else {
      await createBundle(requestBody);
    }

    onSubmit();
    setStep(2);
    setTimeout(handleClose, 3000);
  };

  const { execute, status } = useAsync(submitHandler);

  const {
    handleSubmit, handleChange, handleBlur, handleFocus, values, errors, touched, cleanUpForm,
  } = useForm(
    execute,
    validation,
    bundle || {},
  );

  const imitateChange = (name, value) => {
    const change = {
      target: { name, value },
    };
    handleChange(change);
  };

  const handleClose = () => {
    onClose();
    setStep(1);
    cleanUpForm();
  };

  return (
    <Popup
      modal
      open={!!bundle}
      position="center center"
      onClose={handleClose}
      closeOnDocumentClick={step === 2}
    >
      {() => (
        step === 1
          ? (
            <div className="confirmation-window bundle-modal">
              <BundleIcon className="bundle-modal__icon" />
              <span className="bundle-modal__title">
                {isEdit ? MESSAGES.update : MESSAGES.create}
                {' '}
                {MESSAGES.bundle}
              </span>

              <form className="bundle-modal__form main__form form" onSubmit={handleSubmit} noValidate>
                <Stack spacing={3} justifyContent="center">
                  <Input
                    id="title"
                    name="title"
                    label={MESSAGES.title}
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    value={values.title}
                    error={errors.title}
                    touched={touched.title}
                    isRequired
                    hasFeedback
                  />
                  <Input
                    id="code"
                    name="code"
                    label={MESSAGES.code}
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    value={values.code}
                    error={errors.code}
                    touched={touched.code}
                    isRequired
                    hasFeedback
                  />
                  <Input
                    id="max_licenses_num"
                    name="max_licenses_num"
                    label={MESSAGES.max_licenses}
                    type="number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    value={values.max_licenses_num}
                    error={errors.max_licenses_num}
                    touched={touched.max_licenses_num}
                    isRequired
                    hasFeedback
                  />
                  {/* This div around datepicker is so MUI stack adds styling to the div instead og datapicker, to stop jumpy behavior */}
                  <div>
                    <Datepicker
                      label={MESSAGES.expiration_date}
                      dateFormat="dd-MM-yyyy"
                      onChange={(value) => imitateChange('expiration_date', value)}
                      value={values.expiration_date}
                      isClearable
                    />
                  </div>
                  <Checkbox
                    label={MESSAGES.active}
                    onChange={() => imitateChange('is_active', !values.is_active)}
                    value={values.is_active}
                    hasBigLabel
                  />
                </Stack>
                <div className="bundle-modal__form__actions form__actions">
                  <div className="bundle-modal__form__actions__cancel form__cancel">
                    <Button
                      block
                      isPreventSubmit
                      onClick={onClose}
                      type={BUTTON_TYPE.secondary}
                    >
                      {MESSAGES.cancel}
                    </Button>
                  </div>
                  <div className="bundle-modal__form__actions__submit form__submit">
                    <Button
                      type={BUTTON_TYPE.primary}
                      isLoading={REQUEST_STATUS.loading === status}
                    >
                      {isEdit ? MESSAGES.update : MESSAGES.create}
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          )
          : (
            <div className="confirmation-window bundle-modal__success">
              <OkIcon />
              <span className="bundle-modal-success__text">
                {MESSAGES.bundle_has_been}
                {' '}
                {isEdit ? MESSAGES.updated : MESSAGES.created}
              </span>
            </div>
          )
      )}
    </Popup>
  );
};

export default BundleModal;

BundleModal.propTypes = {
  bundle: propTypes.objectOf(propTypes.oneOfType([
    propTypes.string,
    propTypes.number,
  ])).isRequired,
  onClose: propTypes.func.isRequired,
  onSubmit: propTypes.func.isRequired,
};
