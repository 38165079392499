import {
  EMAIL_MIN_LENGTH,
  EMAIL_MAX_LENGTH,
  EMAIL_PATTERN,
} from 'constants/validation';
import {
  EMAIL_ERROR_REQUIRED,
  EMAIL_ERROR_PATTERN,
  EMAIL_ERROR_MIN_LENGTH,
  EMAIL_ERROR_MAX_LENGTH,
} from 'constants/errors';
import Validator from 'utils/validator';

export default ({ email }) => {
  const errors = {};

  const emailValidator = new Validator(email);

  try {
    emailValidator
      .required(EMAIL_ERROR_REQUIRED)
      .minLength(EMAIL_MIN_LENGTH, EMAIL_ERROR_MIN_LENGTH)
      .maxLength(EMAIL_MAX_LENGTH, EMAIL_ERROR_MAX_LENGTH)
      .match(EMAIL_PATTERN, EMAIL_ERROR_PATTERN);
  } catch (error) {
    errors.email = error.message;
  }

  return errors;
};
