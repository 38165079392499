import React from 'react';
import StatusIndicator from 'components/common/StatusIndicator';
import PropTypes from 'prop-types';
import { EXPERIMENT_STATUSES } from 'constants/experiments';
import { get, values } from 'lodash';
import InfoTooltip from 'components/common/InfoTooltip';
import classNames from 'classnames';
import { getAssetSourceImage } from 'utils/assets';
import { OverflowListCell } from 'components/common/CommonTableCells';
import { ReactComponent as ChannelPushingIcon } from 'assets/svg/channel-loading.svg';
import { ReactComponent as ChannelErrorIcon } from 'assets/svg/channel-error.svg';
import { ReactComponent as ChannelCheckIcon } from 'assets/svg/check.svg';
import { DefaultCell, FormattedNumberCell } from 'components/common/Table/TableCells';
import { MESSAGES } from 'constants/messages';
import { Stack } from '@mui/material';
import './styles.scss';
import { getColorForGroup } from 'components/views/Experiments/utils';
import GuideInfo from 'components/common/GuideInfo';
import { GUIDES } from 'constants/guides';

export const ExperimentStatusCell = ({ column, row }) => (
  <StatusIndicator
    statuses={values(EXPERIMENT_STATUSES)}
    value={row[column.key]}
  />
);

ExperimentStatusCell.propTypes = DefaultCell.propTypes;

const STATUS_INFO = {
  PUSHING: {
    info: MESSAGES.upload_in_progress,
    icon: ChannelPushingIcon,
  },
  ERROR: {
    info: MESSAGES.failed_to_upload_experiment_group,
    icon: ChannelErrorIcon,
  },
  OK: {
    info: MESSAGES.successfully_uploaded,
    icon: ChannelCheckIcon,
  },
};

export const ExperimentChannelsCell = ({ column, row, channelsMap }) => {
  const data = row[column.key];

  const ItemComponent = ({ item }) => {
    const { integration, status } = item;
    const source = get(channelsMap, [integration, 'source']);
    const StatusIcon = STATUS_INFO[status]?.icon;

    return (
      <div className="table__cell__channels__icon-container">
        <InfoTooltip text={source.name} placement="top" disablePropagation>
          <img
            className={classNames('table__cell__channels__icon-container__icon', {
              'table__cell__channels__icon-container__icon-blurred': status !== 'OK',
            })}
            src={getAssetSourceImage(source.key, true)}
            alt={source.name}
          />
        </InfoTooltip>

        {!!status && StatusIcon && (
          <InfoTooltip
            text={STATUS_INFO[status].info}
            enterTouchDelay={0}
            placement="top"
            disablePropagation
          >
            <StatusIcon className="table__cell__channels__icon-container__status" />
          </InfoTooltip>
        )}
      </div>
    );
  };

  ItemComponent.propTypes = {
    item: PropTypes.shape({
      integration: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    }).isRequired,
  };

  return (
    <div className="table__cell__channels">
      {channelsMap && (
        <OverflowListCell
          ItemComponent={ItemComponent}
          data={data}
          limit={4}
        />
      )}
    </div>
  );
};

ExperimentChannelsCell.propTypes = {
  ...DefaultCell.propTypes,
  channelsMap: PropTypes.objectOf(PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    source: PropTypes.oneOfType([PropTypes.object]).isRequired,
  })),
};

ExperimentChannelsCell.defaultProps = {
  channelsMap: null,
};

export const ExperimentGroupNameCell = ({ column, row, groupCount }) => {
  const backgroundColor = getColorForGroup(row.order, groupCount);
  return (
    <div className="table__cell__experiment-group-name">
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
      >
        <div
          className="table__cell__experiment-group-name__color"
          style={{ backgroundColor }}
        />
        <span className="table__cell__experiment-group-name__label">
          {row[column.key]}
        </span>
      </Stack>
    </div>
  );
};

ExperimentGroupNameCell.propTypes = {
  row: PropTypes.shape({
    order: PropTypes.number.isRequired,
  }).isRequired,
  groupCount: PropTypes.number.isRequired,
  ...DefaultCell.propTypes,
};

export const ExperimentCustomerSizeCell = ({ column, row }) => {
  if (!row[column.key] && row.split_size) {
    return (
      <span className="table__cell__experiment-size-populating">
        {MESSAGES.in_progress}
        <GuideInfo info={GUIDES.EX1} type="grey" />
      </span>
    );
  }

  return (
    <FormattedNumberCell
      column={column}
      row={row}
    />
  );
};

ExperimentCustomerSizeCell.propTypes = {
  column: PropTypes.shape({
    key: PropTypes.string,
  }).isRequired,
  row: PropTypes.shape({
    split_size: PropTypes.number.isRequired,
  }).isRequired,
};
