import React from 'react';
import PropTypes from 'prop-types';
import 'components/views/Customer/RecentlyPurchasedProducts/style.scss';
import { MESSAGES } from 'constants/messages';
import Block from 'components/common/Block';
import { GUIDES } from 'constants/guides';
import { ImageCell, NameCell } from 'components/common/CommonTableCells';
import Table from 'components/common/Table';
import useRouter from 'hooks/useRouter';
import { ROUTES } from 'constants/routes';
import { getProducts } from 'api/productsAPI';
import ProductPopperMetrics, {
  ProductPopperMetricsStateHandler,
} from 'components/common/MetricList/PopperMetrics/ProductPopperMetrics';
import { IdCell } from 'components/common/Table/TableCells';

const getTableConfig = (hasImages = false) => [
  ...(hasImages ? [{
    title: '',
    key: 'img',
    ordering: null,
    CellComponent: ImageCell,
    componentProps: {},
    width: '50px',
    hasOverflowEllipsis: true,
  }] : []), {
    title: MESSAGES.title,
    key: 'title',
    ordering: null,
    CellComponent: NameCell,
    componentProps: {},
    width: '300px',
    hasOverflowEllipsis: true,
  }, {
    title: MESSAGES.sku,
    key: 'sku',
    ordering: null,
    CellComponent: IdCell,
    componentProps: { },
    width: '100px',
    hasOverflowEllipsis: false,
  },
];

const RecentlyPurchasedProducts = ({ products }) => {
  const hasImages = products?.length && !!products.filter((row) => !!row.img).length;
  const { push } = useRouter();

  const handleRowClick = (row) => {
    const { sku } = row;
    if (!sku) return;
    getProducts({ search: sku }).then((res) => {
      if (!res.count) return;
      const { id } = res.results[0];
      push(ROUTES.product.replace(':id', id));
    });
  };

  const {
    handleOnHover,
    anchorEl,
    item,
    itemData,
    isLoading,
    isOpen,
  } = ProductPopperMetricsStateHandler();

  return (
    <>
      <Block
        postHeader={MESSAGES.recently_purchased_products}
        className="rp-products"
        guide={GUIDES.CSV13}
      >
        <Table
          data={products}
          sortChanged={() => {}}
          initSort={['ratio_co_ocurrence']}
          tableConfig={getTableConfig(hasImages)}
          highlightStartIdx={0}
          highlightEndIdx={hasImages ? 2 : 1}
          handleRowClick={handleRowClick}
          onHover={handleOnHover}
        />
      </Block>
      <ProductPopperMetrics
        isLoading={isLoading}
        isOpen={isOpen}
        anchorEl={anchorEl}
        item={item}
        itemData={itemData}
      />
    </>
  );
};

RecentlyPurchasedProducts.propTypes = {
  products: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.bool,
  ])).isRequired,
};

export default RecentlyPurchasedProducts;
