import React, { useState } from 'react';

import useListApi from 'hooks/useListApi';
import { ENTITY_CREATOR } from 'constants/entities';
import { Box, Grid, Stack } from '@mui/material';
import { MESSAGES } from 'constants/messages';
import Block from 'components/common/Block';
import Button from 'components/common/Button';
import { BUTTON_TYPE, COHORTS_PER_PAGE } from 'constants/common';
import PaginatedTable from 'components/common/Table/PaginatedTable';
import Search from 'components/common/Search';
import TableSearchEntity from 'components/common/TableSearchEntity';
import FilterTags from 'components/common/FilterTags';
import { LIST_API_REDUX_KEYS } from 'constants/listApi';
import { GUIDES } from 'constants/guides';
import GuideInfo from 'components/common/GuideInfo';
import { deleteCohort, exportCohortData, getCohortsList } from 'api/cohortsAPI';
import useRouter from 'hooks/useRouter';
import { ROUTES } from 'constants/routes';
import ConfirmationWindow from 'components/common/ConfirmationWindow';
import { CreatorCell, DateCell, NameDescriptionCell } from 'components/common/CommonTableCells';
import { ActionsCell } from 'components/common/Table/TableCells';
import {
  assign, flow, omit, partialRight,
} from 'lodash';
import './styles.scss';
import ContentLoader from 'components/common/ContentLoader';
import { ReactComponent as ActivateAudienceIcon } from 'assets/svg/activate_audience.svg';

const getTableConfig = (actions) => [{
  title: MESSAGES.name,
  key: 'name',
  ordering: ['name', 'description'],
  CellComponent: NameDescriptionCell,
  componentProps: {},
  width: '300px',
  hasOverflowEllipsis: false,
}, {
  title: MESSAGES.creator,
  key: 'creator',
  ordering: ['creator__first_name', 'creator__last_name'],
  CellComponent: CreatorCell,
  componentProps: {},
  width: '150px',
  hasOverflowEllipsis: true,
}, {
  title: MESSAGES.updated,
  key: 'updated_at',
  ordering: ['updated_at'],
  CellComponent: DateCell,
  componentProps: {
    fromFormat: 'YYYY-MM-DDTHH:mm:ssZ',
    toFormat: 'MM/DD/YY',
  },
  width: '90px',
  hasOverflowEllipsis: false,
}, {
  title: null,
  key: null,
  ordering: null,
  CellComponent: ActionsCell,
  componentProps: { actions },
  width: '40px',
  hasOverflowEllipsis: false,
}];

const searchEntityConfig = [{
  key: ENTITY_CREATOR,
  title: MESSAGES.creators,
  hasPages: true,
}];

const defaultConfig = { ordering: ['-updated_at'], page_size: COHORTS_PER_PAGE };

const Cohorts = () => {
  const { push } = useRouter();
  const [cohortForDeletion, setCohortForDeletion] = useState(null);

  const {
    data,
    totalCount,
    isDataLoading,
    config,
    changeConfig,
    reloadData,
  } = useListApi(LIST_API_REDUX_KEYS.cohorts, defaultConfig, getCohortsList);

  const handleTagsChange = (tags) => changeConfig({ tags });
  const handleSortChange = (ordering) => changeConfig({ ordering });
  const handlePageChange = (page) => changeConfig({ page });
  const handleSearchChange = (search) => changeConfig({ search });

  const handleCohortDelete = () => deleteCohort(cohortForDeletion).then(() => {
    setCohortForDeletion(null);
    reloadData();
  });

  const handleDeleteAction = ({ row }) =>
    setCohortForDeletion(row.uuid);

  const handleExportAction = ({ row }) => exportCohortData(row.uuid);

  const handleDuplicate = ({ row }) => {
    const createRoute = ROUTES.cohort.replace(':uuid', 'new');

    const prepare = flow(
      partialRight(omit, ['uuid']),
      partialRight(assign, { name: `${row.name} - Copy` }),
    );

    push(createRoute, prepare(row));
  };

  const handleDeleteCancel = () =>
    setCohortForDeletion(null);

  const onCreateEditItem = (uuid = '') => {
    const editRoute = ROUTES.cohort.replace(':uuid', uuid || 'new');
    push(editRoute);
  };

  const handleEdit = ({ row }) => onCreateEditItem(row.uuid);

  const actions = [{
    name: MESSAGES.edit,
    action: handleEdit,
  }, {
    name: MESSAGES.export,
    action: handleExportAction,
    hasLoader: true,
  }, {
    name: MESSAGES.duplicate,
    action: handleDuplicate,
  }, {
    name: MESSAGES.delete,
    action: handleDeleteAction,
  }];
  const tableConfig = getTableConfig(actions);

  return (
    <>
      <div className="cohorts-container">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item lg={3} xs={12}>
                <Block
                  header={MESSAGES.cohorts}
                  postHeader={MESSAGES.cohorts_description}
                  guide={GUIDES.CH1}
                  type="large-header"
                  fillHeight
                />
              </Grid>
              <Grid item lg={9} xs={12}>
                <Block fillHeight>
                  <Grid container spacing={2}>
                    <Grid item md={4} xs={12}>
                      <Search
                        search={config.search}
                        title={MESSAGES.search_by_name}
                        searchChanged={handleSearchChange}
                      />
                    </Grid>
                    <Grid item md={4} xs={5}>
                      <TableSearchEntity
                        searchEntityConfig={searchEntityConfig}
                        tags={config.tags}
                        tagsChanged={handleTagsChange}
                      />
                    </Grid>
                    <Grid item md={4} xs={7}>
                      <Box display="flex" justifyContent="flex-end">
                        <Stack direction="row" spacing={2}>
                          <Button
                            type={BUTTON_TYPE.primary}
                            onClick={() => onCreateEditItem()}
                          >
                            {MESSAGES.new_cohort}
                          </Button>
                          <GuideInfo info={GUIDES.CH2} />
                        </Stack>
                      </Box>
                    </Grid>
                    {(!!config.tags.length) && (
                      <Grid item xs={12}>
                        <FilterTags
                          tags={config.tags}
                          tagsChanged={handleTagsChange}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Block>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {data?.length || config.search.length || config.tags.length ? (
              <Block header={MESSAGES.cohorts} guide={GUIDES.CH3}>
                <PaginatedTable
                  handleRowClick={(row) => onCreateEditItem(row.uuid)}
                  sort={config.ordering}
                  sortChanged={handleSortChange}
                  totalCount={totalCount}
                  tableConfig={tableConfig}
                  data={data}
                  highlightStartIdx={0}
                  highlightEndIdx={3}
                  countPerPage={COHORTS_PER_PAGE}
                  page={config.page}
                  pageChanged={handlePageChange}
                  isDataLoading={isDataLoading}
                />
              </Block>
            ) : (
              <ContentLoader isLoading={isDataLoading}>
                <Block fillHeight>
                  <Grid item xs={12} paddingTop="80px" paddingBottom="80px">
                    <Stack spacing={2} alignItems="center" justifyContent="center">
                      <ActivateAudienceIcon className="new-audience-icon" />
                      <div className="empty-list-text">{MESSAGES.create_your_first_cohort}</div>
                      <Button
                        type={BUTTON_TYPE.primary}
                        onClick={() => onCreateEditItem()}
                      >
                        {MESSAGES.new_cohort}
                      </Button>
                    </Stack>
                  </Grid>
                </Block>
              </ContentLoader>
            )}
          </Grid>
        </Grid>
      </div>
      <ConfirmationWindow
        content={MESSAGES.cohort_delete_confirmation}
        open={!!cohortForDeletion}
        onCancel={handleDeleteCancel}
        onConfirm={handleCohortDelete}
        inWorkArea
        forceBlocking={false}
        danger
      />
    </>
  );
};

export default Cohorts;
