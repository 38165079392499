import React from 'react';
import classNames from 'classnames';
import propTypes from 'prop-types';

import './styles.scss';
import { ALERT_TYPE } from 'constants/common';
import { ReactComponent as InfoIcon } from 'assets/svg/attention.svg';

const Alert = ({ type, children }) => (
  <div className={classNames('alert alert--invitation-button', {
    'alert--info': type === ALERT_TYPE.info,
    'alert--warning': type === ALERT_TYPE.warning,
    'alert--error': type === ALERT_TYPE.error,
  })}
  >
    <div className="alert__content">{children}</div>
    <div className="alert__feedback">
      <InfoIcon className="alert__icon" />
    </div>
  </div>
);

Alert.propTypes = {
  type: propTypes.string.isRequired,
  children: propTypes.string.isRequired,
};

export default Alert;
