import React from 'react';

import './styles.scss';
import useRouter from 'hooks/useRouter';
import { EXTERNAL_LINK, ROUTES } from 'constants/routes';
import { MESSAGES } from 'constants/messages';

const Footer = () => {
  const { pathname } = useRouter();

  const footerByLocation = pathname === ROUTES.signUp
    || pathname === ROUTES.details
    ? MESSAGES.sign_up
    : MESSAGES.sign_in;

  return (
    <footer className="footer">
      <div className="footer__policy">
        {MESSAGES.by_clicking}
        {footerByLocation}
        {MESSAGES.you_agree_to_the}
        {' '}
        <a href={EXTERNAL_LINK.custimyTermsOfBusiness} target="_blank" className="footer__link" rel="noreferrer">{MESSAGES.custimy_terms}</a>
        {' '}
        {(MESSAGES.and).toLowerCase()}
        {' '}
        <a href={EXTERNAL_LINK.custimyPrivacyPolicy} target="_blank" className="footer__link" rel="noreferrer">{MESSAGES.privacy_policy}</a>
      </div>
    </footer>
  );
};

export default Footer;
