import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { MESSAGES } from 'constants/messages';
import Search from 'components/common/Search';
import {
  identity, get, keyBy, union, without, cloneDeep, find, noop,
} from 'lodash';
import {
  exportProducts, getProducts, getProductsSegments,
} from 'api/productsAPI';
import { PRODUCT_SEGMENTS_COLORS } from 'constants/colors';
import ContentLoader from 'components/common/ContentLoader';
import { PRODUCTS_PER_PAGE } from 'constants/common';
import useRouter from 'hooks/useRouter';
import { ROUTES } from 'constants/routes';
import { productColumnsLSManager } from 'helper/localStorage';
import PaginatedTable from 'components/common/Table/PaginatedTable';
import { TableColumnSelector } from 'components/common/Table/TableColumnSelector';
import { LinkButton } from 'components/common/LinkButton';
import {
  defaultVisibleProductItems,
  getProductListItems,
} from 'components/views/Products/ProductList/ProductTable/itemDefinitions';
import tableColumnHelper from 'components/common/Table/TableColumnSelector/tableColumnHelper';
import DataExport from 'components/common/DataExport';
import Table from 'components/common/Table';
import classNames from 'classnames';
import './styles.scss';
import PropTypes from 'prop-types';

const productVariantsShowCount = 5;

const ProductTable = ({ listApi, onVariantRowClick, expandableVariants }) => {
  const [currency, setCurrency] = useState();
  const [segmentsMap, setSegmentsMap] = useState({});
  const [expandedRows, setExpandedRows] = useState([]);
  const [productItems, setProductItems] = useState([]);
  const [fetchedProductVariant, setFetchedProductVariant] = useState(null);
  const { push } = useRouter();

  const keyFunc = (item) => item.id;

  const {
    config,
    changeConfig,
    totalCount: productListTotalCount,
    data: productsList,
    isDataLoading,
  } = listApi;

  useEffect(() => {
    setExpandedRows([]);
    setProductItems(productsList);
  }, [productsList]);

  const handleSortChange = (sort) => changeConfig({ ordering: sort });
  const handlePageChange = (page) => changeConfig({ page });
  const handleSearchChange = (search) => changeConfig({ search });
  const handleExport = () => exportProducts(config);

  const tableConfig = getProductListItems(currency, segmentsMap, expandableVariants);

  const {
    isChecked,
    setIsChecked,
    filteredTableConfig,
    resetColumns,
  } = tableColumnHelper(
    tableConfig,
    defaultVisibleProductItems,
    productColumnsLSManager,
  );

  useEffect(() => {
    const populateSegments = async () => {
      const segments = await getProductsSegments();
      const segmentsWithColor = segments.map((segment) => (
        { ...segment, color_1: PRODUCT_SEGMENTS_COLORS[segment?.id] }));
      setSegmentsMap(keyBy(segmentsWithColor, 'id'));
    };

    populateSegments();
  }, []);

  // we assume that currency is same for all products in a license
  useEffect(() => {
    setCurrency(get(productsList, [0, 'currency']));
  }, [productsList]);

  const fetchProductVariants = (item) => {
    getProducts({
      parentIds: [item.id],
      pageSize: productVariantsShowCount,
    }).then((res) => {
      if (!res.count) return;
      setFetchedProductVariant({ item, res });
    });
  };

  useEffect(() => {
    if (!fetchedProductVariant) return;

    const { item, res } = fetchedProductVariant;
    const items = cloneDeep(productItems);
    const updateItem = find(items, { id: item.id });

    if (!updateItem) return;
    updateItem.subItems = res.results;

    setProductItems(items);
    setFetchedProductVariant(null);
  }, [fetchedProductVariant]);

  const columnNumHighlighted = Object.values(isChecked).filter(identity).length;

  const handleRowExpandToggle = (item) => {
    if (expandedRows.includes(item.id)) {
      setExpandedRows(without(expandedRows, item.id));
      return;
    }
    setExpandedRows(union(expandedRows, [item.id]));
    if (!item.subItems) fetchProductVariants(item);
  };

  const handleRowClick = (item) => {
    if (expandableVariants && item.variations_count) {
      handleRowExpandToggle(item);
      return;
    }
    push(ROUTES.product.replace(':id', item.id));
  };

  const renderExpandedContent = (item) => (
    <div className={classNames('sub-items-wrapper', {
      loading: !item.subItems || !item.subItems.length,
    })}
    >
      <ContentLoader isLoading={!item.subItems || !item.subItems.length}>
        <Table
          tableConfig={filteredTableConfig}
          data={item.subItems || []}
          keyFunc={keyFunc}
          initSort={config.ordering}
          sortChanged={handleSortChange}
          highlightStartIdx={0}
          highlightEndIdx={columnNumHighlighted - 1}
          handleRowClick={handleRowClick}
          withoutHeader
        />
        {item.variations_count > productVariantsShowCount && (
          <div className="view-all-wrapper">
            <LinkButton
              type="secondary"
              size="medium"
              onClick={() => onVariantRowClick(item.id)}
            >
              {`${MESSAGES.view_all} (${item.variations_count})`}
            </LinkButton>
          </div>
        )}
      </ContentLoader>
    </div>
  );

  return (
    <Grid container spacing={2}>
      <Grid item lg={4} md={6} xs={12}>
        <Search
          search={config?.search}
          title={MESSAGES.search_for_products}
          searchChanged={handleSearchChange}
        />
      </Grid>
      <Grid item container lg={8} md={6} xs={12} justifyContent="flex-end" display="flex" spacing={2}>
        <Grid item>
          <DataExport exportHandler={handleExport} />
        </Grid>
        <Grid item>
          <TableColumnSelector
            tableConfig={tableConfig}
            maxColumnsNum={8}
            onReset={resetColumns}
            isChecked={isChecked}
            onCheckBoxChange={setIsChecked}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <PaginatedTable
          keyFunc={keyFunc}
          sort={config.ordering}
          sortChanged={handleSortChange}
          totalCount={productListTotalCount}
          tableConfig={filteredTableConfig}
          data={productItems}
          highlightStartIdx={0}
          highlightEndIdx={columnNumHighlighted - 1}
          countPerPage={PRODUCTS_PER_PAGE}
          page={config.page}
          pageChanged={handlePageChange}
          isDataLoading={isDataLoading}
          handleRowClick={handleRowClick}
          expandedRows={expandedRows}
          expandedContent={renderExpandedContent}
        />
      </Grid>
    </Grid>
  );
};

ProductTable.propTypes = {
  listApi: PropTypes.shape({
    config: PropTypes.object.isRequired,
    changeConfig: PropTypes.func.isRequired,
    totalCount: PropTypes.number.isRequired,
    data: PropTypes.array.isRequired,
    isDataLoading: PropTypes.bool.isRequired,
  }).isRequired,
  onVariantRowClick: PropTypes.func,
  expandableVariants: PropTypes.bool,
};

ProductTable.defaultProps = {
  onVariantRowClick: noop,
  expandableVariants: true,
};

export default ProductTable;
