import React, { useEffect, useState, useCallback } from 'react';
import Grid from '@mui/material/Grid';
import Block from 'components/common/Block';
import { MESSAGES } from 'constants/messages';
import FieldLabel from 'components/common/FieldLabel';
import Input from 'components/common/Input';
import useForm from 'hooks/useForm';
import validation from 'components/views/Experiments/ExperimentCreateView/experimentValidation';
import useAsync from 'hooks/useAsync';
import TextArea from 'components/common/TextArea';
import CustomerListSegments from 'components/common/CustomersListTools/CustomerListSegments';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerListLoading, getCustomerListTotalCount, getSegmentsCount } from 'store/customerList/getters';
import SearchEntity from 'components/common/SearchEntity';
import { ToggleSearchEntity } from 'components/common/TableSearchEntity';
import AdvancedFilterTag from 'components/common/AdvancedFilterTag';
import tagHelper from 'components/common/AdvancedFilterTag/tagHelper';
import { getCustomerFilterList } from 'api/customersAPI';
import {
  CUSTOMER_DETAILS,
  CUSTOMER_EVENTS,
  CUSTOMER_PERFORMANCE,
} from 'constants/entities';
import { BUTTON_TYPE } from 'constants/common';
import Divider from 'components/common/Divider';
import Button from 'components/common/Button';
import { Stack } from '@mui/material';
import { ROUTES } from 'constants/routes';
import useRouter from 'hooks/useRouter';
import { useToast } from 'components/common/Toast';
import ExperimentGroupTable from 'components/views/Experiments/ExperimentCreateView/ExperimentGroupTable';
import { v1 as uuidv1 } from 'uuid';
import './styles.scss';
import { fetchCustomerList } from 'store/customerList/customerListSlice';
import { NumberFormatted } from 'helper/NumberFormatted';
import { debounce, map } from 'lodash';
import ContentLoader from 'components/common/ContentLoader';
import { getTagsFromFilters, prepareTagsAsFilterArray } from 'utils/filters';
import { createExperiment } from 'api/experimentsAPI';
import { GUIDES } from 'constants/guides';
import EditableField from 'components/common/EditableField';
import {
  CUSTOMER_DETAILS_FIELDS,
  CUSTOMER_EVENT_FIELDS,
  CUSTOMER_PERFORMANCE_FIELDS,
} from 'components/common/SearchEntity/constants';

const searchEntityConfig = [{
  key: CUSTOMER_PERFORMANCE,
  title: MESSAGES.performance,
  hasPages: false,
  unique: false,
}, {
  key: CUSTOMER_DETAILS,
  title: MESSAGES.details,
  hasPages: false,
  unique: false,
}, {
  key: CUSTOMER_EVENTS,
  title: MESSAGES.events,
  hasPages: false,
  unique: false,
}];

const getEntityKeysToFields = [
  {
    entityKey: CUSTOMER_PERFORMANCE,
    fields: CUSTOMER_PERFORMANCE_FIELDS,
  },
  {
    entityKey: CUSTOMER_DETAILS,
    fields: CUSTOMER_DETAILS_FIELDS,
  },
  {
    entityKey: CUSTOMER_EVENTS,
    fields: CUSTOMER_EVENT_FIELDS,
  },
];

const defaultGroups = [{
  id: uuidv1(),
  name: 'Group A',
  split_size: 50,
  channels: [],
  locked: false,
}, {
  id: uuidv1(),
  name: 'Control Group',
  split_size: 50,
  channels: [],
  locked: false,
}];

const customersConfig = (data) => ({
  page: 1,
  segment: data.segment || [],
  tags: data.tags || [],
});

const ExperimentCreateView = () => {
  const { push, location } = useRouter();
  const { createToast } = useToast();
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    name: '',
    description: '',
    groups: defaultGroups,
    tags: [],
    segment: [],
  });
  const segmentsCount = useSelector(getSegmentsCount);
  const customerListTotalCount = useSelector(getCustomerListTotalCount);
  const customerListIsLoading = useSelector(getCustomerListLoading);

  const updateCustomerList = (newValues) =>
    dispatch(fetchCustomerList(newValues));

  const debounceUpdateCustomerList = useCallback(debounce(updateCustomerList, 1000), []);

  useEffect(() => {
    debounceUpdateCustomerList(customersConfig(values));
  }, [values.tags, values.segment]);

  const submitHandler = async () => {
    const filters = prepareTagsAsFilterArray(values.tags);
    if (values.segment.length) {
      filters.segment__in = values.segment.join(',');
    }

    const body = {
      name: values.name,
      description: values.description,
      filters,
      groups: map(values.groups, (group) => ({ ...group, split_size: group.split_size / 100 })),
    };
    await createExperiment(body).then((experiment) => {
      push(ROUTES.experiment.replace(':uuid', experiment.uuid));
    }).catch((resp) => {
      if (resp.name) {
        createToast(
          MESSAGES.failed,
          MESSAGES.experiment_with_name_exists,
          'danger',
          'experiment-name-unique-error',
        );
        return;
      }

      createToast(
        MESSAGES.failed,
        MESSAGES.something_went_wrong_experiments,
        'danger',
        'experiment-save-error',
      );
    });
  };
  const { execute, isLoading } = useAsync(submitHandler);
  const {
    handleSubmit,
    handleBlur,
    handleFocus,
    setValues: setFormValues,
    errors,
    touched,
  } = useForm(execute, validation, values);

  const handleTagsChange = (tags) => setValues({ ...values, tags });
  const handleInputChange = (e) => {
    setValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    setFormValues(values);
  }, [values]);

  const {
    tags,
    fields,
    operators,
    addTag,
    removeTag,
    updateTag,
    setTags,
  } = tagHelper(null, handleTagsChange, searchEntityConfig, getCustomerFilterList);

  const isGroupAllocationMet = () => {
    if (!values?.groups?.length) return false;
    let allocated = 0;

    values.groups.forEach((group) => {
      allocated += group.split_size;
    });
    return allocated === 100;
  };

  const handleSubmitClick = (e) => {
    if (!isGroupAllocationMet()) {
      createToast(
        MESSAGES.failed,
        MESSAGES.experiment_group_allocation_error,
        'danger',
        'experiment-groups-allocation-error',
      );
      return;
    }

    const toastErrors = [errors.groups];
    toastErrors.filter(Boolean).forEach((error) =>
      createToast(
        MESSAGES.failed,
        error.header,
        'danger',
        'experiment-groups-error',
      ));

    handleSubmit(e);
  };

  const selectSegmentHandler = (id, isSelected) => {
    const newSegments = !isSelected ? [...values.segment, id]
      : values.segment.filter((segmentId) => segmentId !== id);
    setValues({ ...values, segment: newSegments });
  };

  const handleGroupsChange = (groups) =>
    setValues({ ...values, groups });

  useEffect(() => {
    if (!location.state) return;

    let segment = [];
    if (location.state?.filters?.segment__in) {
      segment = location.state.filters.segment__in.split(',');
    }

    const groups = map(location.state.groups, (group) => ({
      ...group,
      id: uuidv1(),
      split_size: group.split_size * 100,
    }));

    const newValues = {
      ...location.state,
      tags: getTagsFromFilters(location.state.filters, getEntityKeysToFields),
      groups,
      segment,
    };

    setValues(newValues);
    setTags(newValues.tags);
  }, [location.state]);

  return (
    <div className="experiment-create-view">
      <Grid container spacing={2}>
        <Grid item lg={12} xs={12}>
          <Block
            header={MESSAGES.new_experiment}
            postHeader={MESSAGES.create_experiment_description}
            type="large-header"
            fillHeight
          />
        </Grid>
        <Grid item lg={5} xs={12}>
          <Block fillHeight>
            <Stack spacing={2}>
              <FieldLabel label={MESSAGES.name}>
                <Input
                  id="name"
                  name="name"
                  label={MESSAGES.experiment_name_placeholder}
                  hasFloatLabel={false}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  value={values.name}
                  error={errors.name}
                  touched={touched.name}
                  hasFeedback
                  isRequired
                />
              </FieldLabel>
              <FieldLabel label={MESSAGES.description}>
                <EditableField
                  value={values.description}
                  placeholder={MESSAGES.experiment_description_placeholder}
                  hasClose
                >
                  <TextArea
                    name="description"
                    placeholder={MESSAGES.experiment_description_placeholder}
                    characterLimit={300}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    value={values.description}
                    error={errors.description}
                    touched={touched.description}
                    allowVerticalResize
                  />
                </EditableField>
              </FieldLabel>
            </Stack>
          </Block>
        </Grid>
        <Grid item lg={7} xs={12} container>
          <Block header={MESSAGES.customers} guide={GUIDES.EX2}>
            <Grid container>
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={12}>
                  <CustomerListSegments
                    onSelect={selectSegmentHandler}
                    selectedSegments={values.segment}
                    popperPlacement="top"
                    segmentCounts={segmentsCount}
                  />
                </Grid>
                <Grid item>
                  <SearchEntity
                    config={searchEntityConfig}
                    ToggleComponent={ToggleSearchEntity}
                    selectedItems={tags}
                    onItemSelect={addTag}
                  />
                </Grid>
                {tags && tags.map((tag) => (
                  <Grid key={tag.key} item>
                    <AdvancedFilterTag
                      tag={tag}
                      fields={fields}
                      operators={operators}
                      searchEntityConfig={searchEntityConfig}
                      onTagChange={(updatedTag) => updateTag(tag.key, updatedTag)}
                      onRemove={() => removeTag(tag.key)}
                    />
                  </Grid>
                ))}
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <ContentLoader justify="left" isLoading={customerListIsLoading}>
                  <div className="show-customers__results">
                    <span className="count">
                      {NumberFormatted(customerListTotalCount)}
                    </span>
                    <span className="results">
                      {(MESSAGES.customers).toLowerCase()}
                    </span>
                  </div>
                </ContentLoader>
              </Grid>
            </Grid>
          </Block>
        </Grid>
        <Grid item xs={12}>
          <Block header={MESSAGES.experiment_groups} guide={GUIDES.EX3}>
            <ExperimentGroupTable
              groups={values.groups}
              onGroupsChange={handleGroupsChange}
            />
            <Grid container>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  spacing={2}
                >
                  <Button
                    type={BUTTON_TYPE.secondary}
                    onClick={() => push(ROUTES.experiments)}
                  >
                    {MESSAGES.cancel}
                  </Button>
                  <Button
                    type={BUTTON_TYPE.primary}
                    onClick={handleSubmitClick}
                    isLoading={isLoading}
                  >
                    {MESSAGES.save_experiment}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Block>
        </Grid>
      </Grid>
    </div>
  );
};

export default ExperimentCreateView;
