import { useCallback, useEffect, useState } from 'react';

import useRouter from 'hooks/useRouter';
import { DOM_EVENTS } from 'constants/domEvents';

let unblockTransitions = null;

export default (when, searchQueryCheck) => {
  const [isPrevented, setIsPrevented] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [targetLocation, setTargetLocation] = useState(null);
  const [isBlocking, setIsBlocking] = useState(false);
  const {
    history, pathname, location, replace, push,
  } = useRouter();

  const onBeforeUnload = useCallback((e) => {
    if (when) {
      e.returnValue = 'false';
    }
  }, [when]);

  const setInitialState = () => {
    setCurrentLocation(null);
    setTargetLocation(null);
    setIsBlocking(false);
  };

  const tryBlockingTransitions = () => {
    unblockTransitions = history.block((targetLocationBlock) => {
      const hasPathnameChanged = pathname !== targetLocationBlock.pathname;
      const hasSearchQueryChanged = location.search !== targetLocationBlock.search;
      const hasUrlChanged = searchQueryCheck
        ? hasPathnameChanged || hasSearchQueryChanged
        : hasPathnameChanged;

      const hasChanged = !isPrevented && when && hasUrlChanged;

      if (hasChanged) {
        setCurrentLocation(location);
        setTargetLocation(targetLocationBlock);
        setIsBlocking(true);
      }

      return !hasChanged;
    });
  };

  useEffect(() => {
    window.addEventListener(DOM_EVENTS.beforeunload, onBeforeUnload);

    return () => {
      if (typeof unblockTransitions === 'function') {
        unblockTransitions();
      }
      window.removeEventListener(DOM_EVENTS.beforeunload, onBeforeUnload);
    };
  }, [when]);

  useEffect(() => {
    if (!isPrevented) {
      tryBlockingTransitions();
    }
  }, [isPrevented, currentLocation, targetLocation, when]);

  const preventBlocking = () => {
    if (typeof unblockTransitions === 'function') {
      unblockTransitions();
    }
    setIsPrevented(true);

    return () => setIsPrevented(false);
  };

  const onConfirm = () => {
    if (typeof unblockTransitions === 'function') {
      unblockTransitions();
    }
    push(targetLocation.pathname);
    setInitialState();
  };

  const onCancel = () => {
    if (typeof unblockTransitions === 'function') {
      unblockTransitions();
    }
    replace(currentLocation.pathname);
    setInitialState();
  };

  return {
    isBlocking, preventBlocking, onCancel, onConfirm,
  };
};
