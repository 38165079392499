import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import './styles.scss';

const InfoTooltip = ({
  text, children, placement, disablePropagation, delay, disabled,
}) => {
  const content = (
    <div className="info-tooltip">
      {text}
    </div>
  );

  const handleOnClick = (e) => {
    if (disablePropagation) {
      e.stopPropagation();
      e.preventDefault();
    }
  };

  return (
    <Tooltip
      open={disabled ? false : undefined}
      title={content}
      arrow
      enterTouchDelay={0}
      enterDelay={delay}
      enterNextDelay={delay}
      onClick={handleOnClick}
      placement={placement}
    >
      {children}
    </Tooltip>
  );
};

InfoTooltip.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  disablePropagation: PropTypes.bool,
  placement: PropTypes.oneOf([
    'bottom-end', 'bottom-start', 'bottom', 'left-end', 'left-start',
    'left', 'right-end', 'right-start', 'right', 'top-end', 'top-start', 'top',
  ]),
  delay: PropTypes.number,
  disabled: PropTypes.bool,
};

InfoTooltip.defaultProps = {
  disablePropagation: false,
  placement: 'bottom',
  delay: 0,
  disabled: false,
};

export default InfoTooltip;
