import React from 'react';
import propTypes from 'prop-types';
import Popup from 'reactjs-popup';

import 'components/views/Admin/Users/ReactivateUserModal/styles.scss';
import Button from 'components/common/Button';
import { REQUEST_STATUS, BUTTON_TYPE } from 'constants/common';
import useAsync from 'hooks/useAsync';
import { reactivateUser } from 'api/usersAPI';
import { MESSAGES } from 'constants/messages';

const ReactivateUserModal = ({ user, onClose, onSubmit }) => {
  const reactivateUserHandler = async () => {
    await reactivateUser(user.uuid);
    onSubmit();
  };

  const { execute, status } = useAsync(reactivateUserHandler);

  return (
    <Popup
      modal
      open={!!user.uuid}
      position="center center"
      onClose={onClose}
    >
      {() => (
        <div className="confirmation-window reactivate-user">
          <span className="reactivate-user__title">{MESSAGES.reactivate_this_user}</span>
          <div className="reactivate-user__form__actions form__actions">
            <div className="reactivate-user__form__actions__cancel form__cancel">
              <Button
                block
                isPreventSubmit
                onClick={onClose}
                type={BUTTON_TYPE.secondary}
              >
                {MESSAGES.cancel}
              </Button>
            </div>
            <div className="reactivate-user__form__actions__submit form__submit">
              <Button
                type={BUTTON_TYPE.primary}
                isLoading={REQUEST_STATUS.loading === status}
                onClick={execute}
              >
                {MESSAGES.reactivate}
              </Button>
            </div>
          </div>
        </div>
      )}
    </Popup>
  );
};

export default ReactivateUserModal;

ReactivateUserModal.propTypes = {
  user: propTypes.objectOf(propTypes.oneOfType([
    propTypes.string,
    propTypes.array,
    propTypes.object,
  ])).isRequired,
  onClose: propTypes.func.isRequired,
  onSubmit: propTypes.func.isRequired,
};
