import React, { useRef, useState } from 'react';
import './styles.scss';
import PropTypes from 'prop-types';
import { LinkButton } from 'components/common/LinkButton';
import { ReactComponent as ArrowIcon } from 'assets/svg/chevron_down.svg';
import { Popover } from '@mui/material';
import classNames from 'classnames';

export const LinkButtonDropdown = ({ label, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef();

  const handleClose = () => {
    setIsOpen(false);
  };

  const togglePopover = () => {
    setIsOpen(true);
  };

  return (
    <div
      ref={buttonRef}
      className={classNames('link-button-dropdown', { 'is-open': isOpen })}
    >
      <LinkButton
        type="white"
        icon={ArrowIcon}
        onClick={togglePopover}
      >
        {label}
      </LinkButton>
      <Popover
        open={isOpen}
        anchorEl={buttonRef.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {children}
      </Popover>
    </div>
  );
};

LinkButtonDropdown.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node,
};

LinkButtonDropdown.defaultProps = {
  children: undefined,
};
