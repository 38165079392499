import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';

import './styles.scss';
import { ReactComponent as DoubleLeftArrowIcon } from 'assets/svg/doubleLeftArrow.svg';
import Button from 'components/common/Button';

const LastPageButton = ({ onClick, disabled }) => (
  <div className={classNames('pagination__button pagination__next-button--double', {
    'pagination__button--disable': disabled,
  })}
  >
    <Button block onClick={onClick} isDisabled={disabled}>
      <DoubleLeftArrowIcon className="pagination__icon pagination__icon--next pagination__icon--double" />
    </Button>
  </div>
);

LastPageButton.propTypes = {
  onClick: propTypes.func.isRequired,
  disabled: propTypes.bool,
};

LastPageButton.defaultProps = {
  disabled: false,
};

export default LastPageButton;
