import React from 'react';
import classNames from 'classnames';
import propTypes from 'prop-types';

import './styles.scss';
import { CARD_BRAND } from 'constants/card';

const PaymentCards = ({ cards, brand }) => (
  <div className={classNames('payment__cards', {
    'payment__cards--unknown': brand === CARD_BRAND.unknown || !brand,
  })}
  >
    {Object.keys(cards).map((key) => {
      const Card = cards[key];
      return (
        <Card className={classNames(`payment__card-icon payment__card--${key}`, {
          'payment__card--active': key === brand,
        })}
        />
      );
    })}
  </div>
);

PaymentCards.propTypes = {
  cards: propTypes.objectOf(propTypes.object).isRequired,
  brand: propTypes.string.isRequired,
};

export default PaymentCards;
