import React from 'react';
import propTypes from 'prop-types';

import './styles.scss';
import SquareSwitch from 'components/common/SquareSwitch';
import { MESSAGES } from 'constants/messages';

const LicensePackageHeader = ({ toggleValue, onToggleChange, disableToggle }) => (
  <div className="license-package-header">
    <div className="license-package-header__plan">
      <span className="license-package-header__plan__text">
        {MESSAGES.monthly}
      </span>
      <div className="license-package-header__plan__switch">
        <SquareSwitch
          checked={toggleValue}
          onChange={onToggleChange}
          disabled={disableToggle}
        />
      </div>
      <span className="license-package-header__plan__text">
        {MESSAGES.yearly}
      </span>
      <span className="license-package-header__plan__text-bold">
        {MESSAGES.discount}
      </span>
    </div>
  </div>
);

LicensePackageHeader.propTypes = {
  toggleValue: propTypes.bool.isRequired,
  onToggleChange: propTypes.func.isRequired,
  disableToggle: propTypes.bool.isRequired,
};

export default LicensePackageHeader;
