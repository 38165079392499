import React, { useMemo } from 'react';

import Table from 'components/common/Table/index';
import Pagination from 'components/common/PaginationTools/Pagination';
import DataRange from 'components/common/PaginationTools/DataRange';
import ContentLoader from 'components/common/ContentLoader';
import { getPaginationDetails } from 'components/common/PaginationTools/getPaginationDetails';
import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';

const PaginatedTable = ({
  keyFunc,
  tableConfig,
  data,
  isDataLoading,
  sort,
  sortChanged,
  highlightStartIdx,
  highlightEndIdx,
  handleRowClick,
  page,
  pageChanged,
  totalCount,
  countPerPage,
  footerActions,
  expandedRows,
  expandedContent,
}) => {
  const { from, to, maxPage } = useMemo(
    () => getPaginationDetails(page, totalCount, countPerPage),
    [page, totalCount, countPerPage],
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ContentLoader isLoading={isDataLoading}>
          <Table
            tableConfig={tableConfig}
            data={data}
            keyFunc={keyFunc}
            initSort={sort}
            sortChanged={sortChanged}
            highlightStartIdx={highlightStartIdx}
            highlightEndIdx={highlightEndIdx}
            handleRowClick={handleRowClick}
            expandedRows={expandedRows}
            expandedContent={expandedContent}
          />
        </ContentLoader>
      </Grid>
      <Grid item xs={12}>
        <div className="managed-table__content__footer">
          <Grid container spacing={2}>
            <Grid
              item
              md={3}
              xs={12}
              component={Box}
              display="flex"
              justifyContent={{ md: 'flex-start', xs: 'center' }}
            >
              <DataRange
                totalCount={totalCount}
                from={from}
                to={to}
              />
            </Grid>
            <Grid item md={7} xs={12} className="pagination-wrapper">
              <Pagination
                page={page}
                maxPage={maxPage}
                pageChanged={pageChanged}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              {footerActions}
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

PaginatedTable.propTypes = {
  footerActions: PropTypes.element,
  ...Table.propTypes,
};
PaginatedTable.defaultProps = {
  footerActions: undefined,
  ...Table.defaultProps,
};

export default PaginatedTable;
