import propTypes from 'prop-types';

export default {
  handleSubmit: propTypes.func.isRequired,
  handleChange: propTypes.func.isRequired,
  handleBlur: propTypes.func.isRequired,
  values: propTypes.oneOfType([
    propTypes.string,
    propTypes.object,
  ]).isRequired,
  errors: propTypes.objectOf(propTypes.object).isRequired,
  touched: propTypes.objectOf(propTypes.bool).isRequired,
  status: propTypes.string.isRequired,
};
