import React from 'react';
import { CardCvcElement } from '@stripe/react-stripe-js';

import {
  defaultStripeElementProps,
  stripeElementPropTypes,
} from 'containers/PrivateApp/Payment/PaymentForm/StripeElementInput/stripeElementPropTypes';
import StripeElementInput from 'containers/PrivateApp/Payment/PaymentForm/StripeElementInput';

const CardCVCNumber = (props) => (
  <StripeElementInput {...props}>
    <CardCvcElement />
  </StripeElementInput>
);

CardCVCNumber.propTypes = stripeElementPropTypes;
CardCVCNumber.defaultProps = defaultStripeElementProps;

export default CardCVCNumber;
