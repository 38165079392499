import { useState, useEffect } from 'react';
import moment from 'moment';
import { last } from 'lodash';
import { dashboardActiveKpisKeys } from 'components/views/Homepage/KpiHistoryDataBlock/const';
import { getHomeData } from 'api/homeAPI';
import useAsync from 'hooks/useAsync';

export default (selectedMonth) => {
  const [dashboardData, setDashboardData] = useState();
  const [dashboardGraphData, setDashboardGraphData] = useState();
  const {
    execute: fetchData,
    isLoading: isDashboardLoading,
    value: fetchResult,
  } = useAsync(getHomeData);

  const getDateRange = () => {
    const portalDateFormat = 'YYYY-MM-DDTHH:mm:ss[Z]';
    const dateTo = moment(selectedMonth).endOf('month').format(portalDateFormat);
    const dateFrom = moment(selectedMonth).add(-6, 'months').format(portalDateFormat);
    return [dateFrom, dateTo];
  };

  const populateGraphData = (data) => {
    const historicalData = data || [];

    const kpis = dashboardActiveKpisKeys.reduce(
      (result, key) => Object.assign(result, { [key]: historicalData.map((item) => item[key]) }),
      {},
    );

    setDashboardGraphData({
      ...kpis,
      dateRange: historicalData.map((item) => moment(item.date).format('YYYY-MM')),
    });
  };

  useEffect(() => {
    const [dateFrom, dateTo] = getDateRange();
    fetchData({ dateFrom, dateTo });
  }, [selectedMonth]);

  useEffect(() => {
    setDashboardData(last(fetchResult));
    populateGraphData(fetchResult);
  }, [fetchResult]);

  return {
    dashboardData,
    dashboardGraphData,
    isDashboardLoading,
  };
};
