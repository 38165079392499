import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'containers/AcceptInvitation/styles.scss';
import useRouter from 'hooks/useRouter';
import { ROUTES } from 'constants/routes';
import { ACCEPT_INVITATION_STEP, BUTTON_TYPE, REQUEST_STATUS } from 'constants/common';
import InviteLicense from 'containers/AcceptInvitation/InviteLicense';
import useAsync from 'hooks/useAsync';
import { confirmToken } from 'api/authAPI';
import { acceptInvitationLink } from 'api/usersAPI';
import { setLicenseDetailsAction, setInvitedLicenseAction } from 'store/license/licenseSlice';
import { setInvitedUser } from 'store/profile/profileSlice';
import { getLicenseDetails } from 'store/license/getters';
import { getCurrentUser } from 'store/profile/getters';
import LinkError from 'containers/AcceptInvitation/LinkError';
import { MESSAGES } from 'constants/messages';
import Grid from '@mui/material/Grid';
import { LinkButton } from 'components/common/LinkButton';
import Button from 'components/common/Button';
import Block from 'components/common/Block';

const AcceptInvitation = () => {
  const { query, replace, location } = useRouter();
  const [linkError, setLinkError] = useState('');
  const [acceptInvitationStep, setAcceptInvitationStep] = useState(ACCEPT_INVITATION_STEP.verifyLink);

  const {
    license: invitedLicense,
    license_status: licenseStatus,
    uuid: invitedLicenseUUID,
  } = useSelector(getLicenseDetails);
  const currentUser = useSelector(getCurrentUser);
  const dispatch = useDispatch();

  const acceptInvitation = async () => {
    const acceptedLicense = await acceptInvitationLink({ invitedLicenseUUID });

    if (acceptedLicense) {
      dispatch(setLicenseDetailsAction(acceptedLicense));
      replace(ROUTES.home);
    }
  };

  const {
    execute: acceptInvitationAsync,
    status: acceptInvitationStatus,
  } = useAsync(acceptInvitation);

  const verifyInviteLink = async () => {
    const {
      uid: licenseUID,
      token: inviteToken,
    } = query;

    if (!inviteToken || !licenseUID) {
      setLinkError('Link is broken. Incorrect format.');
      return;
    }

    const userEmailParam = currentUser?.email
      ? `&user_email=${currentUser?.email}`
      : '';

    const {
      uuid,
      email,
      is_user_full: isUserFull,
      license_name: licenseName,
      license_avatar: licenseAvatar,
      license_status,
    } = await confirmToken({
      licenseUID,
      inviteToken,
      query: location.search,
      userEmailParam,
    });

    if (!isUserFull) {
      dispatch(setInvitedUser({ email }));
    }

    dispatch(setInvitedLicenseAction({
      uuid,
      company_name: licenseName,
      avatar: licenseAvatar,
      license_status,
    }));

    if (currentUser?.uuid
      && license_status?.length
      && license_status[1] === 'Invited'
    ) {
      setAcceptInvitationStep(ACCEPT_INVITATION_STEP.acceptInvitation);
      return;
    }

    replace(isUserFull
      ? ROUTES.signIn
      : ROUTES.signUp);
  };

  const {
    execute: verifyInviteLinkAsync,
    status: verifyInviteLinkStatus,
    error: verifyInviteLinkError,
  } = useAsync(verifyInviteLink);

  useLayoutEffect(() => {
    if (verifyInviteLinkError) {
      setLinkError(verifyInviteLinkError.message || 'Error accepting invited license.');
    }
  }, [verifyInviteLinkError]);

  useEffect(() => {
    if (currentUser?.uuid
      && licenseStatus?.length
      && licenseStatus[1] === 'Invited'
    ) {
      setAcceptInvitationStep(ACCEPT_INVITATION_STEP.acceptInvitation);
      return;
    }

    verifyInviteLinkAsync();
  }, []);

  return verifyInviteLinkStatus === REQUEST_STATUS.loading || (
    <div className="accept-invitation">
      <div className="container">
        <Grid
          item
          xs={12}
          sx={{ margin: { lg: 'auto 65px auto 225px', md: 'auto 65px auto 25px', xs: 'auto' } }}
        >
          <div className="container__main">
            <Block>
              <main className="main">
                {
                  linkError
                    ? <LinkError message={linkError} />
                    : acceptInvitationStep === ACCEPT_INVITATION_STEP.acceptInvitation && (
                      <>
                        <h1 className="main__title">{MESSAGES.you_ve_been_invited_to_join_a_license}</h1>
                        <p className="main__subtitle">{MESSAGES.please_accept_the_invitation_to_access}</p>
                        {
                          invitedLicense && (
                          <>
                            <InviteLicense
                              license={invitedLicense}
                            />
                            <Button
                              isPreventSubmit
                              type={BUTTON_TYPE.primary}
                              isDisabled={acceptInvitationStatus === REQUEST_STATUS.loading}
                              onClick={acceptInvitationAsync}
                              fillWidth
                            >
                              {MESSAGES.accept_invitation}
                            </Button>
                          </>
                          )
                        }
                        <LinkButton
                          size="medium"
                          onClick={() => replace(ROUTES.switchLicense)}
                        >
                          {MESSAGES.switch_license}
                        </LinkButton>
                      </>
                    )
                }
              </main>
            </Block>
          </div>
        </Grid>
      </div>
    </div>
  );
};

export default AcceptInvitation;
