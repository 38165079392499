export const ENTITY_USER = 'user';
export const ENTITY_CREATOR = 'creator';
export const ENTITY_INTEGRATION = 'integration';
export const ENTITY_LICENSE = 'license';
export const ENTITY_CHANNEL = 'channel';
export const ENTITY_SEGMENT = 'segment';
export const ENTITY_PERFORMANCE = 'performance';
export const ENTITY_CATEGORY = 'category';
export const ENTITY_DETAIL = 'detail';
export const ENTITY_PRODUCT_SEGMENT = 'productSegment';
export const ENTITY_CATEGORY_PERFORMANCE = 'categoryPerformance';
export const CUSTOMER_DETAILS = 'customerDetails';
export const CUSTOMER_PERFORMANCE = 'customerPerformance';
export const CUSTOMER_EVENTS = 'customerEvents';
