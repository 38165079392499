import React from 'react';
import { NumberFormatted } from 'helper/NumberFormatted';
import propTypes from 'prop-types';
import { isNil } from 'lodash';

const Item = ({ title1, title2, value, postfix }) => (
  <div className="block-item">
    <div className="block-tem_wrapper">
      <div className="title">
        <span>{title1}</span>
        <span>{title2}</span>
      </div>
      <div className="value">
        {value === 0 ? 0 : (NumberFormatted(value) || <span style={{ color: '#D4D4D4', fontWeight: 400 }}>-</span>)}
        {postfix && !isNil(value) && <span className="postfix">{postfix}</span> }
      </div>
    </div>
  </div>
);

Item.propTypes = {
  title1: propTypes.string.isRequired,
  title2: propTypes.string.isRequired,
  value: propTypes.number.isRequired,
  postfix: propTypes.string,
};

Item.defaultProps = {
  postfix: '',
};

export default Item;
