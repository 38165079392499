import { createSlice } from '@reduxjs/toolkit';
import { set, pick } from 'lodash';

import moduleName from './constants';
import { NON_LICENSE_LIST_API_KEYS } from 'constants/listApi';


const initialState = {};

const listApiSlice = createSlice({
  name: moduleName,
  initialState,
  reducers: {
    setParamsAction(state, action) {
      const { key, params } = action.payload;
      return set(state, key, params);
    },
    cleanupLicenseParamsAction(state, action) {
      return pick(state, NON_LICENSE_LIST_API_KEYS);
    },
  },
});

export const {
  setParamsAction,
  cleanupLicenseParamsAction,
} = listApiSlice.actions;


export default listApiSlice.reducer;
