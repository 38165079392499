import React from 'react';
import { Box, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { ReactComponent as RemoveIcon } from 'assets/svg/remove.svg';
import 'components/common/EntitySelectItem/styles.scss';
import IconButton from 'components/common/IconButton';

const EntitySelectItem = ({
  entityImage, children, onRemove, entityImagePlaceholder, type,
}) => {
  if (type === 'extended') {
    return (
      <div className="entity-select-item">
        <Stack direction="row" justifyContent="space-between">
          <div className="content-wrapper">
            <Stack direction="row" spacing={2}>
              {!!entityImage && (
                <div className="entity-image-wrapper">
                  <img
                    alt={children}
                    src={entityImage}
                    className="entity-image"
                  />
                </div>
              )}
              {!entityImage && !!entityImagePlaceholder && (
                <div className="entity-image-wrapper">
                  {entityImagePlaceholder}
                </div>
              )}
              <Box
                className="content"
                display="flex"
                alignItems="center"
              >
                {children}
              </Box>
            </Stack>
          </div>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            className="entity-button-wrapper"
          >
            <IconButton
              icon={RemoveIcon}
              type="danger"
              onClick={onRemove}
              size="medium"
              uncolored
            />
          </Box>
        </Stack>
      </div>
    );
  }

  if (type === 'mini') {
    return (
      <div className="entity-select-item mini">
        <div className="content-wrapper">
          {!!entityImage && (
            <div className="entity-image-wrapper">
              <img
                alt={children}
                src={entityImage}
                className="entity-image"
              />
            </div>
          )}
          {!entityImage && !!entityImagePlaceholder && (
            <div className="entity-image-wrapper">
              {entityImagePlaceholder}
            </div>
          )}
          <div className="remove-icon-wrapper">
            <IconButton
              icon={RemoveIcon}
              type="danger"
              onClick={onRemove}
              size="small"
            />
          </div>
        </div>
      </div>
    );
  }

  return null;
};

EntitySelectItem.propTypes = {
  children: PropTypes.string.isRequired,
  entityImage: PropTypes.string,
  entityImagePlaceholder: PropTypes.node,
  onRemove: PropTypes.func,
  type: PropTypes.oneOf(['extended', 'mini']),
};

EntitySelectItem.defaultProps = {
  entityImage: null,
  entityImagePlaceholder: null,
  onRemove: noop,
  type: 'extended',
};

export default EntitySelectItem;
