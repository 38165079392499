import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Spinner } from 'assets/svg/loader.svg';
import './styles.scss';


const Loader = ({ color }) => (
  <Spinner
    className={`loader loader--${color}`}
    style={{fill: color}}
  />
);

Loader.propTypes = {
  color: PropTypes.oneOf(['white', 'black', 'green']),
};

Loader.defaultProps = {
  color: 'white',
};

export default Loader;
