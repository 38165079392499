import { http } from 'utils';
import { ENDPOINTS } from 'constants/routes';
import { HTTP_METHODS } from 'constants/http';

export const createPaymentCard = (data) => http({
  url: ENDPOINTS.paymentCreateCard,
  method: HTTP_METHODS.POST,
  data,
});

export const createLicenseBundle = (data) => http({
  url: ENDPOINTS.paymentLicenseBundle,
  method: HTTP_METHODS.POST,
  data,
});

export const deleteLicenseBundle = (licenseUuid) => http({
  url: `${ENDPOINTS.paymentLicenseBundle}${licenseUuid}/`,
  method: HTTP_METHODS.DELETE,
});

export const getPromotionCode = (code) => http({
  url: `${ENDPOINTS.paymentGetPromotionCode}${code}/`,
  method: HTTP_METHODS.GET,
});

export const getPaymentMethod = () => http({
  url: ENDPOINTS.paymentGetPaymentMethod,
  method: HTTP_METHODS.GET,
});

export const createCustomerPortal = () => http({
  url: ENDPOINTS.customerPortal,
  method: HTTP_METHODS.GET,
});

export const getBillingInfo = () => http({
  url: ENDPOINTS.stripeData,
  method: HTTP_METHODS.GET,
});
