import React from 'react';
import CustomerList from 'components/common/CustomersListTools/CustomerList';
import Block from 'components/common/Block';
import CustomerListSegments from 'components/common/CustomersListTools/CustomerListSegments';
import CustomerListFilters from 'components/common/CustomersListTools/CustomerListFilters';
import useUrlParams from 'hooks/useUrlParams';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerListTotalCount } from 'store/customerList/getters';
import { fetchCustomerList } from 'store/customerList/customerListSlice';
import { MESSAGES } from 'constants/messages';
import { Grid } from '@mui/material';
import { GUIDES } from 'constants/guides';
import './styles.scss';

const baseConfig = {
  page: 1,
  segment: [],
  ordering: ['-date_last_order'],
};

const Customers = () => {
  const dispatch = useDispatch();

  const customerListTotalCount = useSelector(getCustomerListTotalCount);
  const onConfigChange = (config) => dispatch(fetchCustomerList(config));
  const {
    baseConfigKeys, config, changeBaseConfig, changeFiltersConfig,
  } = useUrlParams(baseConfig, onConfigChange);

  const segmentSelectHandler = (id, isSelected) => {
    const newSegments = !isSelected ? [...config.segment, id]
      : config.segment.filter((segmentId) => segmentId !== id);

    changeBaseConfig({ segment: newSegments, page: 1 });
  };

  return (
    <div className="customers">
      <Grid container spacing={2}>
        <Grid item lg={5} xs={12}>
          <Block
            header={MESSAGES.segments}
            guide={GUIDES.C1}
            type="large-header"
          >
            <CustomerListSegments
              onSelect={segmentSelectHandler}
              selectedSegments={config.segment}
              popperPlacement="bottom-start"
            />
          </Block>
          <Block header={MESSAGES.filter_customers_by} guide={GUIDES.C2}>
            <CustomerListFilters
              baseConfigKeys={baseConfigKeys}
              config={config}
              changeFiltersConfig={changeFiltersConfig}
            />
          </Block>
        </Grid>
        <Grid item lg={7} xs={12}>
          <Block
            header={MESSAGES.customer_list}
            guide={GUIDES.C3}
          >
            <CustomerList
              config={config}
              changeBaseConfig={changeBaseConfig}
              customerListTotalCount={customerListTotalCount}
            />
          </Block>
        </Grid>
      </Grid>
    </div>
  );
};
export default Customers;
