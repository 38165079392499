import React, { useEffect, useState } from 'react';
import BaseAutosuggest from 'react-autosuggest';
import propTypes from 'prop-types';
import classNames from 'classnames';

import './styles.scss';
import { ReactComponent as SearchIcon } from 'assets/svg/search.svg';
import { ReactComponent as ClearIcon } from 'assets/svg/offOutlineClose.svg';
import Suggest from 'components/common/Autosuggest/Suggest';
import ContentLoader from '../ContentLoader';
import IsolatedScroll from 'components/common/IsolatedScroll';

const renderSuggestion = ({ suggestion, query, getSuggestionValue }) => (
  <Suggest
    suggestionText={getSuggestionValue(suggestion)}
    suggest={suggestion}
    query={query}
  />
);

const Autosuggest = ({
  suggestions,
  onClearInputRequested,
  onSuggestionsFetchRequested,
  onSuggestionsClearRequested,
  onSuggestionSelected,
  getSuggestionValue,
  inputProps,
  focusInputOnSuggestionClick,
}) => {
  const [isActive, setIsActive] = useState(false);
  const {
    id,
    placeholder,
    value,
  } = inputProps || {};
  useEffect(() => {
    if (value) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [value]);

  const onClearInputRequestedHandler = (e) => {
    e.preventDefault();
    onSuggestionsClearRequested();
    onClearInputRequested();
  };

  const shouldRenderSuggestions = () => true;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    !suggestions.length ? setIsLoading(true) : setTimeout(() => setIsLoading(false), 500);
  }, [suggestions]);

  const renderSuggestionsContainer = ({ containerProps, children }) => {
    const { ref, ...restContainerProps } = containerProps;
    const callRef = (isolatedScroll) => {
      if (isolatedScroll !== null) {
        ref(isolatedScroll.component);
      }
    };

    return (
      <IsolatedScroll ref={callRef} {...restContainerProps}>
        <ContentLoader isLoading={isLoading}>
          {children}
        </ContentLoader>
      </IsolatedScroll>
    );
  };

  return (
    <div className="autosuggest">
      <div className={classNames('autosuggest-search autosuggest-search-focus-label', {
        loading: isLoading,
        loaded: !isLoading,
      })}
      >
        <label
          htmlFor={id}
          className={classNames('autosuggest-search__floating-label',
            { 'autosuggest-search__floating-label--activated': isActive })}
        >
          {placeholder}
        </label>
        <BaseAutosuggest
          suggestions={suggestions}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          renderSuggestion={(suggestion, { query, isHighlighted }) => renderSuggestion({
            suggestion, query, isHighlighted, getSuggestionValue,
          })}
          shouldRenderSuggestions={shouldRenderSuggestions}
          onSuggestionSelected={onSuggestionSelected}
          getSuggestionValue={getSuggestionValue}
          inputProps={inputProps}
          renderSuggestionsContainer={renderSuggestionsContainer}
          focusInputOnSuggestionClick={focusInputOnSuggestionClick}
        />
        <button type="button" onMouseDown={onClearInputRequestedHandler} className="autosuggest-clear__button">
          <ClearIcon className="autosuggest-clear__icon" />
        </button>
        <div className="autosuggest-search__button">
          <SearchIcon className="autosuggest-search__icon" />
        </div>
      </div>
    </div>
  );
};

Autosuggest.propTypes = {
  suggestions: propTypes.arrayOf(propTypes.oneOfType([propTypes.any])).isRequired,
  onClearInputRequested: propTypes.func.isRequired,
  onSuggestionsFetchRequested: propTypes.func.isRequired,
  onSuggestionsClearRequested: propTypes.func.isRequired,
  onSuggestionSelected: propTypes.func.isRequired,
  getSuggestionValue: propTypes.func.isRequired,
  inputProps: propTypes.objectOf(propTypes.oneOfType([propTypes.any])).isRequired,
  focusInputOnSuggestionClick: propTypes.bool,
};

Autosuggest.defaultProps = {
  focusInputOnSuggestionClick: false,
};

export default Autosuggest;
