import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import propTypes from 'prop-types';

import './styles.scss';
import { ReactComponent as ArrowIcon } from 'assets/svg/arrowUp.svg';
import useRouter from 'hooks/useRouter';
import SideBarMenuItem from 'containers/PrivateApp/Portal/CombinedSideBarMenu/SideBarMenuItem';
import { VIEWTYPE } from 'constants/common';
import { Link } from 'react-router-dom';

const SideBarMenuList = ({
  config, hrefTo, children, viewType, toggleMenu, onCloseSlideMenu,
}) => {
  const { location } = useRouter();
  const [isCollapsed, setIsCollapsed] = useState(false);

  const hasConfig = !!(config && config.length);

  const openListHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (hasConfig) {
      setIsCollapsed((prevState) => !prevState);
    }
  };

  const headerClickHandler = () => {
    if (viewType === VIEWTYPE.TABLET) toggleMenu();
    if (viewType === VIEWTYPE.LARGE) onCloseSlideMenu();
  };

  useEffect(() => {
    if (location?.pathname.includes(hrefTo)) {
      setIsCollapsed(true);
    } else setIsCollapsed(false);
  }, [location?.pathname]);

  return (
    <li className={classNames('full-menu-list full-menu-list__list--selectable', {
      'full-menu-list__list--collapsed': !isCollapsed,
    })}
    >
      <div className="full-menu-list__header-wrapper">
        <Link to={hrefTo}>
          <button
            type="button"
            className={classNames('full-menu-list__header', {
              'full-menu-list__header--active': location.pathname.includes(hrefTo),
            })}
            onClick={() => headerClickHandler()}
          >
            <div
              type="button"
              className="full-menu-list__title"
            >
              {children}
            </div>
            {hasConfig
            && (
              <span
                role="button"
                tabIndex="0"
                className="full-menu-list__arrow-container"
                onClick={(e) => openListHandler(e)}
              >
                <ArrowIcon className="full-menu-list__arrow full-menu-list__icon" />
              </span>
            )}
          </button>
          </Link>
      </div>
      <ul className="full-menu-list__list">
        {config.map((item) => (
          <SideBarMenuItem
            key={item.title}
            item={item}
            viewType={viewType}
            toggleMenu={toggleMenu}
            onCloseSlideMenu={onCloseSlideMenu}
          />
        ))}
      </ul>
    </li>
  );
};

SideBarMenuList.propTypes = {
  children: propTypes.node.isRequired,
  config: propTypes.arrayOf(propTypes.oneOfType([propTypes.object])),
  hrefTo: propTypes.string,
  viewType: propTypes.string.isRequired,
  toggleMenu: propTypes.func,
  onCloseSlideMenu: propTypes.func,
};

SideBarMenuList.defaultProps = {
  config: [],
  hrefTo: undefined,
  toggleMenu: null,
  onCloseSlideMenu: null,
};

export default SideBarMenuList;
