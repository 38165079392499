import React from 'react';
import PropTypes from 'prop-types';
import { find } from 'lodash';
import classNames from 'classnames';
import './styles.scss';

const StatusIndicator = ({ value, statuses }) => {
  const activeStatus = find(statuses, { value });
  if (!activeStatus) return null;

  return (
    <div className={classNames('status-indicator', activeStatus.type)}>
      <span className="status-indicator__label">
        {activeStatus.label.toUpperCase()}
      </span>
    </div>
  );
};

StatusIndicator.propTypes = {
  value: PropTypes.string,
  statuses: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['success', 'warning', 'danger', 'neutral']).isRequired,
  })).isRequired,
};

StatusIndicator.defaultProps = {
  value: null,
};

export default StatusIndicator;
