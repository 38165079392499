import React from 'react';

import Tooltip from 'components/common/Tooltip';
import Alert from 'components/common/Alert';
import { ALERT_TYPE } from 'constants/common';
import InviteUserButton from 'components/views/Settings/LicenseSettings/LicenseMembers/InviteUser/InviteUserButton';
import { MESSAGES } from 'constants/messages';

const disableInviteButton = (props) => (
  <div className="license-members__add-button">
    <InviteUserButton disable {...props} />
  </div>
);

const DisabledInviteUserButton = () => (
  <Tooltip
    trigger={disableInviteButton}
  >
    <div className="invite-button-alert">
      <Alert type={ALERT_TYPE.info}>
        {MESSAGES.the_limit_of_users_has_been_reached}
      </Alert>
    </div>
  </Tooltip>
);

export default DisabledInviteUserButton;
