import React, { useState, useEffect } from 'react';
import { Box, Grid, Stack } from '@mui/material';
import { ENTITY_CATEGORY_PERFORMANCE, ENTITY_SEGMENT } from 'constants/entities';
import { MESSAGES } from 'constants/messages';
import ContentLoader from 'components/common/ContentLoader';
import {
  CATEGORIES_PER_PAGE, INPUT_VALUE_TYPE, REQUEST_STATUS,
} from 'constants/common';
import {
  ALL_SOURCES_PULLING_ERR_OR_PULLING, FIRST_PULLING, NO_ACTIVE_INTEGRATION, OK_STATUS, OK_PARTIAL,
} from 'constants/status';
import NoIntegrationState from 'components/views/Homepage/EmptyStates/NoIntegrationState';
import MainPullingDataState from 'components/views/Homepage/EmptyStates/MainPullingDataState';
import NoDataState from 'components/views/Homepage/EmptyStates/NoDataState';
import Block from 'components/common/Block';
import SearchEntity from 'components/common/SearchEntity';
import { ToggleSearchEntity } from 'components/common/TableSearchEntity';
import { LinkButton } from 'components/common/LinkButton';
import { ReactComponent as ClearImage } from 'assets/svg/trash.svg';
import AdvancedFilterTag from 'components/common/AdvancedFilterTag';
import Search from 'components/common/Search';
import { TableColumnSelector } from 'components/common/Table/TableColumnSelector';
import PaginatedTable from 'components/common/Table/PaginatedTable';
import tagHelper from 'components/common/AdvancedFilterTag/tagHelper';
import { productCategoryColumnsLSManager } from 'helper/localStorage';
import useRouter from 'hooks/useRouter';
import useListApi from 'hooks/useListApi';
import { LIST_API_REDUX_KEYS } from 'constants/listApi';
import {
  exportProductCategories,
  getProductCategories,
  getProductCategoryFilters,
} from 'api/productsAPI';
import { get, identity } from 'lodash';
import { ROUTES } from 'constants/routes';
import tableColumnHelper from 'components/common/Table/TableColumnSelector/tableColumnHelper';
import {
  defaultVisibleCategoryItems,
  getCategoryListItems,
} from 'components/views/Products/CategoryList/itemDefinitions';
import DataExport from 'components/common/DataExport';
import { GUIDES } from 'constants/guides';
import SegmentPopperMetrics, { SegmentPopperMetricsStateHandler } from 'components/common/MetricList/PopperMetrics/SegmentPopperMetrics';
import { getSegmentByIdInState, getSegments } from 'store/segments/getters';
import { useSelector } from 'react-redux';
import { getUserStatus } from 'api/usersAPI';
import Disclaimer from 'components/common/Disclaimer';

const searchEntityConfig = [{
  key: ENTITY_CATEGORY_PERFORMANCE,
  title: MESSAGES.performance,
  hasPages: false,
  unique: false,
}, {
  key: ENTITY_SEGMENT,
  title: MESSAGES.segments_rate,
  hasPages: false,
  unique: false,
  type: INPUT_VALUE_TYPE.percent,
}];

const defaultConfig = { ordering: ['id'] };

const CategoryList = () => {
  const [status, setStatus] = useState('');
  const [currency, setCurrency] = useState();
  const { push } = useRouter();
  const segmentStates = useSelector(getSegments);

  const {
    config,
    changeConfig,
    totalCount: productsCategoryListTotalCount,
    data: productsCategoryList,
    isDataLoading,
  } = useListApi(LIST_API_REDUX_KEYS.productCategories, defaultConfig, getProductCategories);

  const handleSortChange = (sort) => changeConfig({ ordering: sort });
  const handlePageChange = (page) => changeConfig({ page });
  const handleTagsChange = (tags) => changeConfig({ tags });
  const handleSearchChange = (search) => changeConfig({ search });
  const handleExport = () => exportProductCategories(config);

  const {
    tags,
    fields,
    operators,
    addTag,
    removeTag,
    updateTag,
    clearTags,
  } = tagHelper(config?.tags, handleTagsChange, searchEntityConfig, getProductCategoryFilters);

  const {
    handleOnHover,
    anchorEl: metricsAnchorEl,
    item: metricsItem,
    itemData: metricsItemData,
    isLoading: isMetricsLoading,
    isOpen: isMetricsOpen,
  } = SegmentPopperMetricsStateHandler();

  const handleOptionHover = (e, isHovering, entity, option) => {
    if (entity.key !== ENTITY_SEGMENT) return;
    const { id } = getSegmentByIdInState(option.uuid, segmentStates);
    handleOnHover(e, { id, segment_name: option.name }, isHovering);
  };

  const tableConfig = getCategoryListItems(currency);

  const {
    isChecked,
    setIsChecked,
    filteredTableConfig,
    resetColumns,
  } = tableColumnHelper(
    tableConfig,
    defaultVisibleCategoryItems,
    productCategoryColumnsLSManager,
  );

  useEffect(() => {
    const populateUserStatus = async () => {
      const response = await getUserStatus();
      setStatus(response.status);
    };

    populateUserStatus();
  }, []);

  // we assume that currency is same for all categories in a license
  useEffect(() => {
    setCurrency(get(productsCategoryList, [0, 'currency']));
  }, [productsCategoryList]);

  const columnNumHighlighted = Object.values(isChecked).filter(identity).length;
  const handleRowClick = (item) => push(ROUTES.productCategory.replace(':id', encodeURIComponent(item.id)));

  return (
    <>
      <Box width="100%">
        <ContentLoader isLoading={status === REQUEST_STATUS.loading}>
          {!!status && status === NO_ACTIVE_INTEGRATION && <NoIntegrationState />}
          {!!status && status === FIRST_PULLING && <MainPullingDataState />}
          {!!status && status === ALL_SOURCES_PULLING_ERR_OR_PULLING && <NoDataState />}
          {!!status && (status === OK_STATUS || status === OK_PARTIAL) && (
          <Grid container spacing={2}>
            {status === OK_PARTIAL && (
              <Grid item>
                <Disclaimer
                  type="warning"
                  text={MESSAGES.data_is_still_pulled}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Block
                header={MESSAGES.category_insights}
                postHeader={MESSAGES.category_insights_subtext}
                type="large-header"
              />
            </Grid>
            <Grid item xs={12}>
              <Block header={MESSAGES.filters} guide={GUIDES.CI1}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <SearchEntity
                      config={searchEntityConfig}
                      ToggleComponent={ToggleSearchEntity}
                      selectedItems={tags}
                      onItemSelect={addTag}
                      onOptionHover={handleOptionHover}
                    />
                  </Grid>
                  <Grid item xs={6} component={Stack} justifyContent="flex-end">
                    {!!tags?.length && (
                      <LinkButton
                        type="danger"
                        onClick={clearTags}
                        icon={ClearImage}
                      >
                        {MESSAGES.clear_filter}
                      </LinkButton>
                    )}
                  </Grid>
                  <Grid item container spacing={1} xs={12}>
                    {tags.map((tag) => (
                      <Grid key={tag.key} item>
                        <AdvancedFilterTag
                          tag={tag}
                          fields={fields}
                          operators={operators}
                          searchEntityConfig={searchEntityConfig}
                          onTagChange={(updatedTag) => updateTag(tag.key, updatedTag)}
                          onRemove={() => removeTag(tag.key)}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Block>
            </Grid>
            <Grid item xs={12}>
              <Block
                header={MESSAGES.category_list}
                postHeader={MESSAGES.yearly_performance}
                guide={GUIDES.CI2}
              >
                <Grid container spacing={2}>
                  <Grid item lg={4} md={6} xs={12}>
                    <Search
                      search={config?.search}
                      title={MESSAGES.search_by_name}
                      searchChanged={handleSearchChange}
                    />
                  </Grid>
                  <Grid item container lg={8} md={6} xs={12} justifyContent="flex-end" display="flex" spacing={2}>
                    <Grid item>
                      <DataExport exportHandler={handleExport} />
                    </Grid>
                    <Grid item>
                      <TableColumnSelector
                        tableConfig={tableConfig}
                        maxColumnsNum={8}
                        onReset={resetColumns}
                        isChecked={isChecked}
                        onCheckBoxChange={setIsChecked}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <PaginatedTable
                      keyFunc={(item) => item.id}
                      sort={config.ordering}
                      sortChanged={handleSortChange}
                      totalCount={productsCategoryListTotalCount}
                      tableConfig={filteredTableConfig}
                      data={productsCategoryList}
                      highlightStartIdx={0}
                      highlightEndIdx={columnNumHighlighted - 1}
                      countPerPage={CATEGORIES_PER_PAGE}
                      page={config.page}
                      pageChanged={handlePageChange}
                      isDataLoading={isDataLoading}
                      handleRowClick={handleRowClick}
                    />
                  </Grid>
                </Grid>
              </Block>
            </Grid>
          </Grid>
          )}
        </ContentLoader>
      </Box>
      <SegmentPopperMetrics
        isLoading={isMetricsLoading}
        isOpen={isMetricsOpen}
        anchorEl={metricsAnchorEl}
        item={metricsItem}
        itemData={metricsItemData}
        placement="right-end"
      />
    </>
  );
};

export default CategoryList;
