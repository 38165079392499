import React, { useEffect, useState } from 'react';
import './styles.scss';
import CardInfo from 'components/views/Settings/Settings/BillingDetails/Billing/CardInfo/CardInfo';
import { useSelector } from 'react-redux';
import { getPaymentMethodData } from 'store/paymentMethod/getters';
import Button from 'components/common/Button';
import { BUTTON_TYPE } from 'constants/common';
import { MESSAGES } from 'constants/messages';
import { createCustomerPortal, getBillingInfo } from 'api/paymentAPI';
import CustomerInfo from 'components/views/Settings/Settings/BillingDetails/Billing/CustomerInfo/CustomerInfo';
import ProgressBar from 'components/views/Settings/Settings/BillingDetails/Billing/ProgressBar/ProgressBar';
import { getYearlyRevenue } from 'api/licenseAPI';
import { getCustomer } from 'store/license/getters';
import moment from 'moment';
import { Grid, Stack } from '@mui/material';
import { ReactComponent as IntegrationsIcon } from 'assets/svg/dataBase.svg';
import Box from '@mui/material/Box';
import ContentLoader from 'components/common/ContentLoader';

const annualRevenueConfig = [
  {
    message: MESSAGES.no_usage_text,
    ar: 0,
    nextTier: 0,
  },
  {
    tierMessage: MESSAGES.entry_text,
    currentTier: MESSAGES.entry,
    nextTierText: MESSAGES.mid,
    price: 249,
    nextTier: 0.02,
  },
  {
    tierMessage: MESSAGES.mid_text,
    currentTier: MESSAGES.mid,
    nextTierText: MESSAGES.high,
    price: 479,
    nextTier: 0.07,
  },
  {
    tierMessage: MESSAGES.high_text,
    currentTier: MESSAGES.high,
    price: 749,
    nextTier: 0.15,
  },
  {
    tierMessage: MESSAGES.high_text,
    currentTier: MESSAGES.high,
    price: 749,
    nextTier: 0.15,
  },
];

const Billing = () => {
  const { card, billing_details } = useSelector(getPaymentMethodData);
  const { monthly_billing } = useSelector(getCustomer);
  const getPortalURL = async () => {
    const URL = await createCustomerPortal();
    window.location.href = URL.session;
  };

  const [nextBillingDate, setNextBillingDate] = useState('');
  const [customer, setCustomer] = useState({});
  const [yearlyRevenue, setYearlyRevenue] = useState(0);
  const [isLoadingRevenue, setIsLoadingRevenue] = useState(false);

  const usage = yearlyRevenue ? yearlyRevenue / 1000 : 0;
  const calculateTier = () => {
    let tierIndex = 0;
    if (usage >= 1) tierIndex = 1;
    if (usage >= 2) tierIndex = 2;
    if (usage >= 7) tierIndex = 3;

    return annualRevenueConfig[tierIndex];
  };

  const tier = calculateTier();

  const calculateProgressBarPercentage = () => {
    let calc = 0;
    if (usage < 15) calc = Math.round(yearlyRevenue / 1000 / tier.nextTier);
    // Hack to get the progressbar to say 100% if the revenue is above the 15 mil mark
    if (usage >= 15) calc = Math.round(usage / usage);
    return calc;
  };
  const progressBarPercentage = calculateProgressBarPercentage();
  const fetchYearlyRevenue = async () => {
    setIsLoadingRevenue(true);
    getYearlyRevenue()
      .then((res) => {
        setYearlyRevenue(res.ar);
        setIsLoadingRevenue(false);
      })
      .catch((e) => {
        setIsLoadingRevenue(false);
      });
  };

  const fetchBillingInfo = async () => {
    const res = await getBillingInfo();
    const date = moment.unix(res.next_invoice_time).format('D/MM-YYYY');
    setCustomer(res.customer);
    setNextBillingDate(date);
  };

  useEffect(() => {
    fetchYearlyRevenue();
  }, []);

  useEffect(() => {
    fetchBillingInfo();
  }, []);

  return (
    <ContentLoader isLoading={isLoadingRevenue}>
      <Grid container rowSpacing={2}>
        {tier.ar === 0 ? (
          <Grid
            item
            xl={6}
            xs={12}
            component={Stack}
            justifyContent={{ xl: 'center', md: 'flex-start' }}
            alignItems="center"
          >
            <Stack direction="row">
              <IntegrationsIcon className="no_usage_icon" />
              <div className="no_usage">
                {MESSAGES.no_usage_text}
                <br />
                {MESSAGES.integrate_billing_text}
              </div>
            </Stack>
          </Grid>
        ) : (
          <Grid container item xl={6} xs={12} rowSpacing={2}>
            <Grid item xs={12}>
              <Stack>
                <Stack direction="row">
                  <div className="current_price">
                    {tier.price}
                    <div className="currency_price">€</div>
                  </div>
                  <div className="pay_per_field">
                    <div className="pay_per">
                      {monthly_billing ? MESSAGES.monthly : MESSAGES.yearly}
                    </div>
                  </div>
                </Stack>
                <div className="tier_message">
                  {tier.tierMessage}
                </div>
              </Stack>
            </Grid>
            <Grid item xl={11} md={8} xs={12}>
              <div className="tier-text">
                <Grid container>
                  <Grid item xs={6}>
                    <div className="current-tier">
                      {tier.currentTier}
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container justifyContent="flex-end">
                      <div className="next-tier">
                        {tier.nextTierText}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
              <ProgressBar
                background_color="#A5D2A7"
                progressBarPercentage={progressBarPercentage}
              />
            </Grid>
          </Grid>
        )}
        <Grid item container rowSpacing={2} xl={6} xs={12}>
          <Grid item md={6} xs={12}>
            <Stack spacing={2}>
              <div className="field-header">
                {MESSAGES.billing_address}
              </div>
              <CustomerInfo
                cardHolderName={billing_details.name}
                customer={customer}
              />
            </Stack>
          </Grid>
          <Grid item md={6} xs={12}>
            <Stack spacing={2}>
              {nextBillingDate && (
                <div className="field-header">
                  {MESSAGES.next_invoice}
                  <b> {nextBillingDate}</b>
                </div>
              )}
              <div className="billing__view-container">
                <CardInfo
                  brand={card.brand}
                  cardNumber={card.last4}
                  expirationDate={`0${card.exp_month.toString()}/${card.exp_year.toString()}`}
                />
              </div>
            </Stack>
          </Grid>
          <Grid container item xs={12} component={Box} justifyContent="flex-end">
            <div className="manage-btn">
              <Button onClick={getPortalURL} type={BUTTON_TYPE.primary}>
                {MESSAGES.manage_subscription}
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </ContentLoader>
  );
};

export default Billing;
