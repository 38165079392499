import React from 'react';
import propTypes from 'prop-types';

import 'components/common/FilterTags/styles.scss';
import { capitalize, isEqual } from 'lodash';
import { ReactComponent as ClearImage } from 'assets/svg/trash.svg';
import { MESSAGES } from 'constants/messages';
import FilterTag from 'components/common/FilterTag';
import { LinkButton } from 'components/common/LinkButton';

const FilterTags = ({
  tags, tagsChanged,
}) => {
  const removeTag = (tagToRemove) => {
    const newTags = tags.filter((tag) => !isEqual(tag, tagToRemove));
    tagsChanged(newTags);
  };

  const clearTags = () => tagsChanged([]);

  return (
    <div className="filter-tags">
      <span className="filter-tags__title">
        {MESSAGES.filters}
      </span>
      <div className="filter-tags__container">
        <div className="filter-tags__content">
          {tags.map((tag) => (
            <FilterTag
              key={tag.entityKey + tag.uuid}
              onTagClick={() => removeTag(tag)}
              onRemoveClick={() => removeTag(tag)}
            >
              {`${capitalize(tag.entityKey)}: ${tag.name}`}
            </FilterTag>
          ))}
        </div>
        <div className="filter-tags__controls">
          <LinkButton
            type="danger"
            onClick={clearTags}
            icon={ClearImage}
          >
            {MESSAGES.clear_filter}
          </LinkButton>
        </div>
      </div>

    </div>
  );
};

FilterTags.propTypes = {
  tags: propTypes.arrayOf(propTypes.shape({
    uuid: propTypes.string.isRequired,
    name: propTypes.string.isRequired,
    entityKey: propTypes.string.isRequired,
  })).isRequired,
  tagsChanged: propTypes.func.isRequired,
};

export default FilterTags;
