import { http } from '../utils';
import { ENDPOINTS } from 'constants/routes';
import { HTTP_METHODS } from 'constants/http';
import {
  ENTITY_CATEGORY,
  ENTITY_PRODUCT_SEGMENT,
  ENTITY_SEGMENT,
} from 'constants/entities';
import { httpFile } from 'utils/file';
import { prepareTagsAsFilters } from 'utils/filters';

const prepareTags = (tags) => {
  const productSegments = tags
    .filter(({ entityKey }) => entityKey === ENTITY_PRODUCT_SEGMENT)
    .map(({ uuid }) => uuid);

  const categories = tags
    .filter(({ entityKey }) => entityKey === ENTITY_CATEGORY)
    .map(({ uuid }) => uuid);

  const customerSegments = tags
    .filter(({ entityKey }) => entityKey === ENTITY_SEGMENT)
    .map((tag) => `${tag.uuid}__${tag.operator}__${tag.value}`);

  const takenEntities = [ENTITY_PRODUCT_SEGMENT, ENTITY_CATEGORY, ENTITY_SEGMENT];
  const notTakenFilters = tags.filter(({ entityKey }) => !takenEntities.includes(entityKey));
  const filters = prepareTagsAsFilters(notTakenFilters);

  return `&product_segments__in=${productSegments.join(',')}&categories_ids__in=${categories.join(',')}&customer_segments=${customerSegments.join(',')}&${filters}`;
};

export const getProduct = (id) => http({
  url: `${ENDPOINTS.products}${id}/`,
  method: HTTP_METHODS.GET,
});

export const getProducts = ({
  page = 1,
  ordering = [],
  search = '',
  tags = [],
  audienceSizeEdge = '',
  parentIds = null,
  pageSize = null,
}) => http({
  url: `${ENDPOINTS.products}?page=${page}${pageSize ? `&page_size=${pageSize}` : ''}&search=${search}&ordering=${ordering}&audience_size__gt=${audienceSizeEdge}${prepareTags(tags)}${parentIds ? `&parent_ids__in=${parentIds}` : ''}`,
  method: HTTP_METHODS.GET,
});

export const exportProducts = ({
  ordering = [],
  search = '',
  tags = [],
  parentIds = null,
}) => httpFile({
  url: `${ENDPOINTS.productsExport}?search=${search}&ordering=${ordering}${prepareTags(tags)}${parentIds ? `&parent_ids__in=${parentIds}` : ''}`,
  method: HTTP_METHODS.GET,
});

export const getProductFilters = () => http({
  url: `${ENDPOINTS.productFilters}`,
  method: HTTP_METHODS.GET,
});

export const getProductsSegments = (search = '') => http({
  url: `${ENDPOINTS.productSegments}?search=${search}`,
  method: HTTP_METHODS.GET,
});

export const getProductCategory = (id) => http({
  url: `${ENDPOINTS.productCategories}${id}/`,
  method: HTTP_METHODS.GET,
});

export const getProductCategories = ({
  page = 1, ordering = [], page_size = '', search = '', tags = [], audienceSizeEdge = '',
}) => http({
  url: `${ENDPOINTS.productCategories}?page=${page}&page_size=${page_size}&ordering=${ordering}&search=${search}&audience_size__gt=${audienceSizeEdge}${prepareTags(tags)}`,
  method: HTTP_METHODS.GET,
});

export const exportProductCategories = ({
  ordering = [], search = '', tags = [],
}) => httpFile({
  url: `${ENDPOINTS.productCategoriesExport}?ordering=${ordering}&search=${search}${prepareTags(tags)}`,
  method: HTTP_METHODS.GET,
});

export const getProductCategoryFilters = () => http({
  url: `${ENDPOINTS.productCategoryFilters}`,
  method: HTTP_METHODS.GET,
});
