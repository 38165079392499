import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import propTypes from 'prop-types';

import './styles.scss';
import { ReactComponent as ArrowIcon } from 'assets/svg/arrowUp.svg';
import useRouter from 'hooks/useRouter';
import MobileSideBarMenuItem from 'containers/PrivateApp/Portal/CombinedSideBarMenu/MobileSideBarMenuItem';
import { VIEWTYPE } from 'constants/common';
import { Link } from "react-router-dom";

const MobileSideBarMenuList = ({
  config, hrefTo, children, viewType, toggleMenu, getCollapseStatus,
}) => {
  const { location } = useRouter();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const hasConfig = !!(config && config.length);

  const headerClickHandler = () => {
    if (viewType === VIEWTYPE.MOBILE) toggleMenu();
  };

  const openListHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (hasConfig) {
      setIsCollapsed((prevState) => !prevState);
    }
  };

  useEffect(() => {
    getCollapseStatus(isCollapsed);
  }, [isCollapsed]);

  useEffect(() => {
    if (location?.pathname.includes(hrefTo)) {
      setIsCollapsed(true);
    } else setIsCollapsed(false);
  }, [location?.pathname]);

  return (
    <li className={classNames('mobile-menu-list mobile-menu-list__list--selectable', {
      'mobile-menu-list__list--collapsed': !isCollapsed,
    })}
    >
      <div className="mobile-menu-list__header-wrapper">
        <Link to={hrefTo}>
          <button
            type="button"
            className={classNames('mobile-menu-list__header', {
              'mobile-menu-list__header--active': location.pathname.includes(hrefTo),
            })}
            onClick={headerClickHandler}
          >
            <div className="mobile-menu-list__title">
              {children}
            </div>
            {hasConfig
            && (
            <span
              role="button"
              tabIndex="0"
              className="mobile-menu-list__arrow-container"
              onClick={(e) => openListHandler(e)}
            >
              <ArrowIcon className="mobile-menu-list__arrow mobile-menu-list__icon" />
            </span>
            )}
          </button>
        </Link>
      </div>
      <ul className="mobile-menu-list__list">
        {config.map((item) => (
          <MobileSideBarMenuItem
            key={item.title}
            item={item}
            viewType={viewType}
            toggleMenu={toggleMenu}
          />
        ))}
      </ul>
    </li>
  );
};

MobileSideBarMenuList.propTypes = {
  children: propTypes.node.isRequired,
  config: propTypes.arrayOf(propTypes.oneOfType([propTypes.object])),
  hrefTo: propTypes.string,
  viewType: propTypes.string.isRequired,
  toggleMenu: propTypes.func,
  getCollapseStatus: propTypes.func.isRequired,
};

MobileSideBarMenuList.defaultProps = {
  config: [],
  hrefTo: undefined,
  toggleMenu: null,
};

export default MobileSideBarMenuList;
