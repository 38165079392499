import { accessTokenLSManager, refreshTokenLSManager, userUuidLSManager } from 'helper/localStorage';
import { fetchSocialsGoogleToken } from 'api/socialsAPI';
import { useToast } from 'components/common/Toast';
import { MESSAGES } from 'constants/messages';
import useCurrentUser from 'hooks/useCurrentUser';

export default () => {
  const { createToast } = useToast();
  const { setCurrentUserAsync } = useCurrentUser();

  const runFailureToast = () => {
    createToast(
      MESSAGES.failed,
      MESSAGES.failed_to_login,
      'danger',
      'socials-google-login-failed',
    );
  };

  const handleSuccessfulTokenResponse = async (tokens) => {
    userUuidLSManager.set(tokens.uuid);
    accessTokenLSManager.set(tokens.access);
    refreshTokenLSManager.set(tokens.refresh);

    await setCurrentUserAsync();
  };

  const fetchTokens = async (token) => {
    fetchSocialsGoogleToken({ token })
      .then(handleSuccessfulTokenResponse)
      .catch(runFailureToast);
  };

  const onSuccess = async (credentialResponse) => {
    await fetchTokens(credentialResponse.credential);
  };

  const onError = async () =>
    runFailureToast();

  return {
    onSuccess,
    onError,
  };
};
