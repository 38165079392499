import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';

import './styles.scss';
import { ReactComponent as DoubleLeftArrowIcon } from 'assets/svg/doubleLeftArrow.svg';
import Button from 'components/common/Button';

const FirstPageButton = ({ onClick, disabled }) => {
  const onClickHandler = () => onClick({ selected: 0 });
  return (
    <div className={classNames('pagination__button pagination__prev-button', {
      'pagination__button--disable': disabled,
    })}
    >
      <Button block onClick={onClickHandler} isDisabled={disabled}>
        <DoubleLeftArrowIcon className="pagination__icon pagination__icon--prev pagination__icon--double" />
      </Button>
    </div>
  );
};

FirstPageButton.propTypes = {
  onClick: propTypes.func.isRequired,
  disabled: propTypes.bool,
};

FirstPageButton.defaultProps = {
  disabled: false,
};

export default FirstPageButton;
