import React from 'react';
import propTypes from 'prop-types';

import useLogout from 'hooks/useLogout';
import { MESSAGES } from 'constants/messages';
import Button from 'components/common/Button';
import { BUTTON_TYPE } from 'constants/common';

const LinkError = ({ message }) => {
  const logoutHandler = useLogout();

  return (
    <>
      <h1 className="main__title">{MESSAGES.link_issue}</h1>
      <p className="main__subtitle">{message}</p>
      <Button
        isPreventSubmit
        type={BUTTON_TYPE.primary}
        onClick={logoutHandler}
        fillWidth
      >
        {MESSAGES.back_to_login}
      </Button>
    </>
  );
};

LinkError.propTypes = {
  message: propTypes.string.isRequired,
};

export default LinkError;
