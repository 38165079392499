import React from 'react';
import { Grid } from '@mui/material';
import Chip from 'components/common/Chip';
import { ENTITY_PRODUCT_SEGMENT } from 'constants/entities';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { GUIDES, PRODUCT_SEGMENT_GUIDE_LIST } from 'constants/guides';

export const ProductSegmentsSelector = ({
  segments, tags, onDeselect, onSelect,
}) => {
  const isSegmentSelected = (segment_uuid) => !!tags
    .filter((tag) => tag.entityKey === ENTITY_PRODUCT_SEGMENT && tag.uuid === segment_uuid).length;

  const segmentItemToggle = (segment) => {
    if (isSegmentSelected(segment.id)) {
      const segmentTag = tags
        .find(({ entityKey, uuid }) => entityKey === ENTITY_PRODUCT_SEGMENT && uuid === segment.id);

      if (!segmentTag) return;

      onDeselect(segmentTag.key);
      return;
    }

    onSelect({
      uuid: segment.id,
      name: segment.product_segment,
      entityKey: ENTITY_PRODUCT_SEGMENT,
      finalized: true,
    });
  };

  return (
    <Grid container spacing={1}>
      {Object.values(segments).map((segment) => (
        <Grid item key={segment.id}>
          <Chip
            selected={isSegmentSelected(segment.id)}
            clickColor={segment.color_1}
            onClick={() => segmentItemToggle(segment)}
            label={segment.product_segment}
            info={GUIDES[PRODUCT_SEGMENT_GUIDE_LIST[segment.id]]}
          />
        </Grid>
      ))}
    </Grid>
  );
};

ProductSegmentsSelector.propTypes = {
  segments: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    color_1: PropTypes.string.isRequired,
    product_segment: PropTypes.string.isRequired,
  })),
  tags: PropTypes.array,
  onDeselect: PropTypes.func,
  onSelect: PropTypes.func,
};

ProductSegmentsSelector.defaultProps = {
  segments: [],
  tags: [],
  onDeselect: noop,
  onSelect: noop,
};