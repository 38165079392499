import React from 'react';
import './styles.scss';
import propTypes from 'prop-types';
import Switch from 'react-switch';

const SquareSwitch = ({
  checked, onChange, disabled,
}) => (
  <div className="square-switch-container">
    <Switch
      className="square-switch"
      checked={checked}
      onChange={(x) => onChange(x)}
      disabled={disabled}
      offColor="#232323"
      onColor="#232323"
      borderRadius={5}
      width={46}
      height={23}
      checkedIcon={false}
      uncheckedIcon={false}
    />
  </div>
);

SquareSwitch.propTypes = {
  checked: propTypes.bool.isRequired,
  onChange: propTypes.func.isRequired,
  disabled: propTypes.bool,
};

SquareSwitch.defaultProps = {
  disabled: false,
};

export default SquareSwitch;
