export const CUSTOMER_SOURCE_IMAGES = {
  'Google cpc': 'g_ads',
  Email: 'mail',
  Google: 'google',
  Sms: 'sms',
  Facebook: 'facebook_ads',
  Instagram: 'instagram',
  Organic: 'organic',
  Twitter: 'twitter',
  default: 'other',
};
