import React from 'react';
import propTypes from 'prop-types';

import 'components/views/Settings/LicenseSettings/LicenseMembers/LicenseMembersTable/styles.scss';
import useOptions from 'hooks/useOptions';
import { ENDPOINTS } from 'constants/routes';
import LicenseMembersTableItem
  from 'components/views/Settings/LicenseSettings/LicenseMembers/LicenseMembersTable/LicenseMembersTableItem';
import { MESSAGES } from 'constants/messages';
import ContentLoader from 'components/common/ContentLoader';

const LicenseMembersTable = ({
  isAdministrator,
  licenseMembers,
  onSelect,
  onDelete,
  confirmInviteUserHandler,
  confirmInviteUserStatus,
  isDataLoading,
}) => {
  const onSelectHandler = (selectedStatus, member) => onSelect(selectedStatus, member);
  const deleteMemberHandler = (member) => onDelete(member);

  const {
    options: licenseStatusOptions,
  } = useOptions(ENDPOINTS.licenseStatus, 'license_statuses', true);

  return (
    <table className="license-table license-members-table">
      <ContentLoader isLoading={isDataLoading}>
        <thead className="license-members-table__header">
          <th className="license-table__header-item license-members-table__header-item license-members-table__header--user">
            <span className="license-members-table__header-text--user">
              {MESSAGES.name}
            </span>
          </th>
          <th className="license-table__header-item license-members-table__header-item license-members-table__header--email">
            <span className="license-members-table__header-text--email">
              {MESSAGES.email}
            </span>
          </th>
          <th className="license-table__header-item license-members-table__header-item license-members-table__header--status">
            <span className="license-members-table__header-text--status">
              {MESSAGES.status}
            </span>
          </th>
          {
            isAdministrator && (
            <th className="license-table__header-item license-members-table__header-item license-members-table__header--remove">
              <span className="license-members-table__header-text--remove">
                {MESSAGES.remove}
              </span>
            </th>
            )
          }
        </thead>
        <div className="license-members-table__wrapper">
          <tbody className="license-members-table__body">
            {
              licenseMembers.map((licenseMember) => (
                <LicenseMembersTableItem
                  key={licenseMember.uuid}
                  licenseMember={licenseMember}
                  licenseStatusOptions={licenseStatusOptions}
                  confirmInviteUserHandler={confirmInviteUserHandler}
                  confirmInviteUserStatus={confirmInviteUserStatus}
                  status={licenseMember.license_status}
                  onSelect={onSelectHandler}
                  onDelete={deleteMemberHandler}
                />
              ))
            }
          </tbody>
        </div>
      </ContentLoader>
    </table>
  );
};

LicenseMembersTable.propTypes = {
  isAdministrator: propTypes.string.isRequired,
  licenseMembers: propTypes.arrayOf(propTypes.object).isRequired,
  confirmInviteUserHandler: propTypes.func.isRequired,
  confirmInviteUserStatus: propTypes.string.isRequired,
  onSelect: propTypes.func.isRequired,
  onDelete: propTypes.func.isRequired,
  isDataLoading: propTypes.bool.isRequired,
};

export default LicenseMembersTable;
