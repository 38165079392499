import { combineReducers } from 'redux';
import { RESET_APP } from 'store/common/actionTypes';

import profileReducer from './profile/profileSlice';
import licenseReducer from './license/licenseSlice';
import paymentMethodReducer from './paymentMethod/paymentMethodSlice';
import segmentsReducer from './segments/segmentsSlice';
import customerListReducer from './customerList/customerListSlice';
import productsReducer from './products/productsSlice';
import listApiReducer from './listApi/listApiSlice';
import crumbsIdToNameReducer from './crumbsIdToName/crumbsIdToNameSlice';
import toastsReducer from './toasts/toastsSlice';

import profileModuleName from './profile/constants';
import licenseModuleName from './license/constants';
import paymentMethodModuleName from './paymentMethod/constants';
import segmentsModuleName from './segments/constants';
import customerListModuleName from './customerList/constants';
import productModuleName from './products/constants';
import listApiModuleName from './listApi/constants';
import crumbsIdToNameModuleName from './crumbsIdToName/constants';
import toastsModuleName from './toasts/constants';

const appReducer = combineReducers({
  [profileModuleName]: profileReducer,
  [licenseModuleName]: licenseReducer,
  [paymentMethodModuleName]: paymentMethodReducer,
  [segmentsModuleName]: segmentsReducer,
  [customerListModuleName]: customerListReducer,
  [productModuleName]: productsReducer,
  [listApiModuleName]: listApiReducer,
  [crumbsIdToNameModuleName]: crumbsIdToNameReducer,
  [toastsModuleName]: toastsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === RESET_APP) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
