import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import Loader from 'components/common/Loader';
import classNames from 'classnames';

const ContentLoader = ({ isLoading, children, justify }) => (
  <div className={classNames('content-loader', `justify-${justify}`)}>
    {isLoading && (
      <div className="content-loader__loader">
        <Loader color="white" />
      </div>
    )}
    <div className={classNames('content-loader__content', { 'content-loader__content-hide': isLoading })}>
      {children}
    </div>
  </div>
);

ContentLoader.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.node,
  justify: PropTypes.oneOf(['left', 'center', 'right']),
};

ContentLoader.defaultProps = {
  isLoading: undefined,
  children: null,
  justify: 'center',
};

export default ContentLoader;
