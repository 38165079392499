import React, { useState } from 'react';

import 'components/views/Settings/LicenseSettings/License/styles.scss';
import EditMode from 'components/views/Settings/LicenseSettings/License/EditMode';
import { useSelector } from 'react-redux';
import { getCurrentLicense } from 'store/license/getters';
import ViewMode from 'components/views/Settings/LicenseSettings/License/ViewMode';

const Licence = () => {
  const [isEdit, setIsEdit] = useState(false);

  const {
    company_name: companyName,
    business_category: businessCategory,
    company_url: companyURL,
    company_size: companySize,
    avatar: avatarImg,
  } = useSelector(getCurrentLicense);
  const licenceProps = {
    companyName,
    businessCategory,
    companyURL,
    companySize,
    avatarImg,
  };

  const onEditModeSetHandler = () => setIsEdit(true);
  const onEditModeCancelHandler = () => setIsEdit(false);

  return isEdit
    ? <EditMode cancelEditMode={onEditModeCancelHandler} {...licenceProps} />
    : <ViewMode onEditClick={onEditModeSetHandler} {...licenceProps} />;
};

export default Licence;
