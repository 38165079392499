import React, { useState } from 'react';
import { Box, Grid, Stack } from '@mui/material';
import { MESSAGES } from 'constants/messages';
import Block from 'components/common/Block';
import Search from 'components/common/Search';
import TableSearchEntity from 'components/common/TableSearchEntity';
import Button from 'components/common/Button';
import { BUTTON_TYPE, EXPERIMENTS_PER_PAGE } from 'constants/common';
import FilterTags from 'components/common/FilterTags';
import useListApi from 'hooks/useListApi';
import { LIST_API_REDUX_KEYS } from 'constants/listApi';
import { deleteExperiment, getExperimentsList } from 'api/experimentsAPI';
import useRouter from 'hooks/useRouter';
import { ROUTES } from 'constants/routes';
import { ENTITY_CREATOR } from 'constants/entities';
import PaginatedTable from 'components/common/Table/PaginatedTable';
import { ActionsCell, FormattedNumberCell } from 'components/common/Table/TableCells';
import { CreatorCell, DateCell, NameDescriptionCell } from 'components/common/CommonTableCells';
import { ExperimentStatusCell } from 'components/views/Experiments/ExperimentCells';
import ConfirmationWindow from 'components/common/ConfirmationWindow';
import { ReactComponent as ActivateAudienceIcon } from 'assets/svg/activate_audience.svg';
import ContentLoader from 'components/common/ContentLoader';
import './styles.scss';
import {
  assign,
  flow,
  omit,
  partialRight,
} from 'lodash';

const getTableConfig = (actions) => [{
  title: MESSAGES.name,
  key: 'name',
  ordering: ['name'],
  CellComponent: NameDescriptionCell,
  componentProps: {},
  width: '200px',
  hasOverflowEllipsis: false,
}, {
  title: MESSAGES.groups,
  key: 'group_count',
  ordering: null,
  CellComponent: FormattedNumberCell,
  componentProps: {},
  width: '120px',
  hasOverflowEllipsis: true,
}, {
  title: MESSAGES.size,
  key: 'size',
  ordering: null,
  CellComponent: FormattedNumberCell,
  componentProps: {},
  width: '120px',
  hasOverflowEllipsis: true,
}, {
  title: MESSAGES.creator,
  key: 'creator',
  ordering: ['creator__first_name', 'creator__last_name'],
  CellComponent: CreatorCell,
  componentProps: {},
  width: '150px',
  hasOverflowEllipsis: true,
}, {
  title: MESSAGES.created,
  key: 'created_at',
  ordering: ['created_at'],
  CellComponent: DateCell,
  componentProps: {
    fromFormat: 'YYYY-MM-DDTHH:mm:ssZ',
    toFormat: 'MM/DD/YY',
  },
  width: '90px',
  hasOverflowEllipsis: false,
}, {
  title: MESSAGES.status,
  key: 'status',
  ordering: ['status'],
  CellComponent: ExperimentStatusCell,
  componentProps: {},
  width: '90px',
  hasOverflowEllipsis: false,
}, {
  title: null,
  key: null,
  ordering: null,
  CellComponent: ActionsCell,
  componentProps: { actions },
  width: '40px',
  hasOverflowEllipsis: false,
}];

const defaultConfig = { ordering: ['-created_at'], page_size: EXPERIMENTS_PER_PAGE };

const searchEntityConfig = [{
  key: ENTITY_CREATOR,
  title: MESSAGES.creators,
  hasPages: true,
}];

const ExperimentListView = () => {
  const { push } = useRouter();
  const [experimentForDeletion, setExperimentForDeletion] = useState(null);

  const {
    data,
    totalCount,
    isDataLoading,
    config,
    reloadData,
    changeConfig,
  } = useListApi(LIST_API_REDUX_KEYS.experiments, defaultConfig, getExperimentsList);

  const handleTagsChange = (tags) => changeConfig({ tags });
  const handleSortChange = (ordering) => changeConfig({ ordering });
  const handlePageChange = (page) => changeConfig({ page });
  const handleSearchChange = (search) => changeConfig({ search });
  const handleRowClick = (row) => push(ROUTES.experiment.replace(':uuid', row.uuid));

  const handleExperimentDelete = () => deleteExperiment(experimentForDeletion).then(() => {
    setExperimentForDeletion(null);
    reloadData();
  });

  const handleDeleteAction = ({ row }) =>
    setExperimentForDeletion(row.uuid);

  const handleDeleteCancel = () =>
    setExperimentForDeletion(null);

  const handleDuplicate = ({ row }) => {
    const createRoute = ROUTES.experiment.replace(':uuid', 'new');

    const prepare = flow(
      partialRight(omit, ['uuid']),
      partialRight(assign, { name: `${row.name} - Copy` }),
    );

    push(createRoute, prepare(row));
  };

  const handleEdit = ({ row }) => push(ROUTES.experiment.replace(':uuid', row.uuid));

  const actions = [{
    name: MESSAGES.edit,
    action: handleEdit,
  }, {
    name: MESSAGES.duplicate,
    action: handleDuplicate,
  }, {
    name: MESSAGES.delete,
    action: handleDeleteAction,
  }];

  const tableConfig = getTableConfig(actions);

  return (
    <>
      <div className="experiments-container">
        <Grid container spacing={2}>
          <Grid item xs={12} container spacing={1}>
            <Grid item lg={3} xs={12}>
              <Block
                header={MESSAGES.experiments}
                postHeader={MESSAGES.experiments_description}
                type="large-header"
                fillHeight
              />
            </Grid>
            <Grid item lg={9} xs={12}>
              <Block fillHeight>
                <Grid container spacing={2}>
                  <Grid item md={4} xs={12}>
                    <Search
                      search={config.search}
                      title={MESSAGES.search_by_name}
                      searchChanged={handleSearchChange}
                    />
                  </Grid>
                  <Grid item md={4} xs={5}>
                    <TableSearchEntity
                      searchEntityConfig={searchEntityConfig}
                      tags={config.tags}
                      tagsChanged={handleTagsChange}
                    />
                  </Grid>
                  <Grid item md={4} xs={7}>
                    <Box display="flex" justifyContent="flex-end">
                      <Stack direction="row" spacing={2}>
                        <Button
                          type={BUTTON_TYPE.primary}
                          onClick={() => push(ROUTES.newExperiment)}
                        >
                          {MESSAGES.new_experiment}
                        </Button>
                      </Stack>
                    </Box>
                  </Grid>
                  {(!!config.tags.length) && (
                    <Grid item xs={12}>
                      <FilterTags
                        tags={config.tags}
                        tagsChanged={handleTagsChange}
                      />
                    </Grid>
                  )}
                </Grid>
              </Block>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {data?.length || config.search.length || config.tags.length ? (
              <Block header={MESSAGES.experiments}>
                <PaginatedTable
                  handleRowClick={handleRowClick}
                  sort={config.ordering}
                  sortChanged={handleSortChange}
                  totalCount={totalCount}
                  tableConfig={tableConfig}
                  data={data}
                  highlightStartIdx={0}
                  highlightEndIdx={6}
                  countPerPage={EXPERIMENTS_PER_PAGE}
                  page={config.page}
                  pageChanged={handlePageChange}
                  isDataLoading={isDataLoading}
                />
              </Block>
              ) : (
                <ContentLoader isLoading={isDataLoading}>
                  <Block fillHeight>
                    <Grid item xs={12} paddingTop="80px" paddingBottom="80px">
                      <Stack spacing={2} alignItems="center" justifyContent="center">
                        <ActivateAudienceIcon />
                        <div className="empty-list-text">
                          {MESSAGES.create_your_first_experiment}
                        </div>
                        <Button
                          type={BUTTON_TYPE.primary}
                          onClick={() => push(ROUTES.newExperiment)}
                        >
                          {MESSAGES.new_experiment}
                        </Button>
                      </Stack>
                    </Grid>
                  </Block>
                </ContentLoader>
              )}
          </Grid>
        </Grid>
      </div>
      <ConfirmationWindow
        content={MESSAGES.experiment_delete_confirmation}
        open={!!experimentForDeletion}
        onCancel={handleDeleteCancel}
        onConfirm={handleExperimentDelete}
        inWorkArea
        forceBlocking={false}
        danger
      />
    </>
  );
};

export default ExperimentListView;
