import { switchLicense as switchLicenseAPI } from 'api/licenseAPI';
import { deleteLicenseAction, setLicenseDetailsAction } from 'store/license/licenseSlice';
import { getPaymentMethod } from 'api/paymentAPI';
import { cleanupPaymentMethodAction, setPaymentMethodAction } from 'store/paymentMethod/paymentMethodSlice';
import { ROUTES } from 'constants/routes';
import useRouter from 'hooks/useRouter';
import { useDispatch } from 'react-redux';
import posthog from 'posthog-js';
import { cleanupLicenseParamsAction } from 'store/listApi/listApiSlice';

export default () => {
  const { replace } = useRouter();
  const dispatch = useDispatch();

  const switchLicense = async (license) => {
    let newLicense;

    try {
      const licenseDetails = await switchLicenseAPI({ licenseUuid: license.uuid });
      dispatch(setLicenseDetailsAction(licenseDetails));
      newLicense = licenseDetails;
      posthog.capture('Switched license', {
        license_uuid: licenseDetails.license.uuid,
        license_name: licenseDetails.license.company_name,
      });
      dispatch(cleanupLicenseParamsAction());
    } catch (error) {
      dispatch(deleteLicenseAction());
    }

    if (!newLicense?.license?.bundle) {
      try {
        const currentPaymentMethod = await getPaymentMethod();
        dispatch(setPaymentMethodAction(currentPaymentMethod));
      } catch (error) {
        dispatch(cleanupPaymentMethodAction());
      }
    } else {
      dispatch(cleanupPaymentMethodAction());
    }

    replace(ROUTES.home);
  };

  return switchLicense;
};
