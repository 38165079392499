import React from 'react';
import PropTypes from 'prop-types';
import 'components/views/Admin/AdminCells/styles.scss';
import Avatar from 'components/common/Avatar';
import { AVATAR_TYPE } from 'constants/common';
import { get } from 'lodash';


export const AvatarCell = ({ column, row }) => {
  const value = get(row, column.key);
  return (value ? (
    <img className="admin__cell__avatar" src={value} alt="avatar" />
  ) : (
    <Avatar type={AVATAR_TYPE.small} />
  )
  );
};

AvatarCell.propTypes = {
  row: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

export const ChoicesCell = ({ column, row }) => {
  const value = row[column.key];
  return (
    <span className="admin__cell__choices">
      {!!value && value[1]}
    </span>
  );
};

ChoicesCell.propTypes = AvatarCell.propTypes;
