import { WEB_STORAGE } from 'constants/common';
import { getCurrentUser } from 'api/usersAPI';
import { getLicense } from 'api/licenseAPI';
import { getPaymentMethod } from 'api/paymentAPI';
import { useRef, useEffect } from 'react';
import { maxDecimals } from 'utils/formatters';
import moment from 'moment';

export const cleanUpPrivateStorage = () => {
  Object.values(WEB_STORAGE).forEach((item) => {
    localStorage.removeItem(item);
    sessionStorage.removeItem(item);
  });
};

export const isObjectHasChanged = (oldObject, newObject) => JSON
  .stringify(oldObject) !== JSON.stringify(newObject);

export const convertedRoleOptions = (responseRoles) => responseRoles
  .map(([value, label]) => ({ label, value }));

export const verifyUserAuthorization = async () => {
  let currentUser = null;
  let currentUserLicense = null;
  let currentPaymentMethod = null;

  try {
    currentUser = await getCurrentUser();
    currentUserLicense = await getLicense();
    if (!currentUserLicense?.license?.bundle) {
      currentPaymentMethod = await getPaymentMethod();
    }
  } finally {
    return { currentUser, currentUserLicense, currentPaymentMethod };
  }
};

export const debounce = (func, wait, immediate = false) => {
  let timeout;
  return (...args) => {
    const later = () => {
      timeout = null;
      if (!immediate) func(...args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func(...args);
  };
};

export const toUrlParams = (obj) =>
  Object.keys(obj).filter((key) => obj[key] !== null).map((key) => `${key}=${encodeURIComponent(obj[key])}`).join('&');

export const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
};

const suffixes = ['', 'k', 'm', 'b'];
export const cutThousands = (val) => {
  const realTier = Math.log10(Math.abs(val)) / 3 | 0;
  const existingTier = Math.min(suffixes.length - 1, realTier);

  const scale = Math.pow(10, existingTier * 3);
  const scaled = val / scale;
  const suffix = suffixes[existingTier] || '';
  return `${maxDecimals(scaled, 2)}${suffix}`;
};

export const arrayHasSetValues = (array) => {
  for (let i = 0; i < array.length; i++) {
    if (array[i] !== null) return true;
  }

  return false;
};

export const getSetValuesInArray = (array) => {
  let setValues = 0;
  for (let i = 0; i < array.length; i++) {
    if (array[i] !== null) setValues += 1;
  }
  return setValues;
};

export const isArrayEq = (a1, a2) => JSON.stringify(a1) === JSON.stringify(a2);

export const getIntervals = (min, max, intervalAmount) => {
  const intervals = [];
  const intervalSize = (Math.abs(min) + Math.abs(max)) / (intervalAmount - 1);
  for (let i = 0; i < intervalAmount; i++) {
    intervals.push(Math.floor(min + (intervalSize * i)));
  }
  return intervals;
};

export const getDateRange = (fromDate, toDate, type) => {
  const diff = toDate.diff(fromDate, type);
  const range = [];
  for (let i = 0; i < diff; i++) {
    range.push(moment(fromDate).add(i, type));
  }
  return range;
};
