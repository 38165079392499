import {

} from 'constants/validation';
import {
  CARD_NUMBER_ERROR_REQUIRED,
  CARD_NUMBER_ERROR_UNAVAILABLE,
  CARD_EXPIRY_NUMBER_ERROR_REQUIRED,
  CARD_CVC_NUMBER_ERROR_REQUIRED,
  ValidationError, PROMOTION_CODE_ERROR_REQUIRED,
} from 'constants/errors';
import Validator from 'utils/validator';
import { AVAILABLE_CARDS, CARD_BRAND } from 'constants/card';

export default ({
  cardNumber,
  cardExpiry,
  cardCVC,
  promotionCodeInfo,
  promotionCode,
}) => {
  const errors = {};

  const cardNumberValidator = new Validator(cardNumber);
  const cardExpiryValidator = new Validator(cardExpiry);
  const cardCVCValidator = new Validator(cardCVC);
  const promotionCodeValidator = new Validator(promotionCodeInfo);

  // dont validate card if user set up bundle
  if (!promotionCodeInfo || !promotionCodeInfo.isBundle) {
    try {
      cardNumberValidator.additional((validator) => {
        if (validator.field?.empty || !validator.field) {
          throw new ValidationError(CARD_NUMBER_ERROR_REQUIRED);
        }
        if (validator.field?.brand !== CARD_BRAND.unknown
          && !AVAILABLE_CARDS[validator.field?.brand]) {
          throw new ValidationError(CARD_NUMBER_ERROR_UNAVAILABLE);
        }
        if (validator.field?.brand) {
          if (validator.field?.error?.message) {
            throw new ValidationError(validator.field.error.message);
          }
        }

        return validator;
      });
    } catch (error) {
      errors.cardNumber = error.message;
    }

    try {
      cardExpiryValidator.additional((validator) => {
        if (validator.field?.empty || !validator.field) {
          throw new ValidationError(CARD_EXPIRY_NUMBER_ERROR_REQUIRED);
        }
        if (validator.field?.error?.message) {
          throw new ValidationError(validator.field.error.message);
        }

        return validator;
      });
    } catch (error) {
      errors.cardExpiry = error.message;
    }

    try {
      cardCVCValidator.additional((validator) => {
        if (validator.field?.empty || !validator.field) {
          throw new ValidationError(CARD_CVC_NUMBER_ERROR_REQUIRED);
        }
        if (validator.field?.error?.message) {
          throw new ValidationError(validator.field.error.message);
        }

        return validator;
      });
    } catch (error) {
      errors.cardCVC = error.message;
    }
  }

  try {
    promotionCodeValidator.additional((validator) => {
      if (validator.field?.isOpened && !promotionCode) {
        throw new ValidationError(PROMOTION_CODE_ERROR_REQUIRED);
      }

      if (validator.field?.error) {
        throw new ValidationError(validator.field.error);
      }

      return validator;
    });
  } catch (error) {
    errors.promotionCode = error.message;
  }

  return errors;
};
