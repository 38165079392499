import { MESSAGES } from 'constants/messages';
import { CollapsibleCell, FormattedNumberCell, IdCell } from 'components/common/Table/TableCells';
import { NameLinkCell, SegmentsCell } from 'components/common/CommonTableCells';
import { titleWithCurrency } from 'utils/formatters';
import { ROUTES } from 'constants/routes';

export const defaultProductVariantItems = {
  id: false,
  sku: false,
  name: true,
  profit: false,
  revenue: true,
  items_sold: false,
  profit_margin: true,
  product_segments: true,
  price: false,
  avg_price: true,
  cost: true,
  total_returns: false,
  return_rate: false,
  discounts: false,
  repurchase_rate: false,
  first_order_rate: false,
  bought_uniquely_rate: false,
  variations_count: true,
};

export const getProductVariantListItems = (currency, segmentsMap) => [{
  title: MESSAGES.id,
  key: 'id',
  ordering: ['id'],
  CellComponent: IdCell,
  componentProps: {},
  width: '100px',
  hasOverflowEllipsis: false,
}, {
  title: MESSAGES.sku,
  key: 'sku',
  ordering: ['sku'],
  CellComponent: IdCell,
  componentProps: {},
  width: '150px',
  hasOverflowEllipsis: true,
}, {
  title: MESSAGES.name,
  key: 'name',
  ordering: ['name'],
  CellComponent: NameLinkCell,
  componentProps: {
    color: '#FFFFFF',
    routeFunc: (row) => ROUTES.product.replace(':id', row.id),
    openInNewTab: true,
  },
  width: '250px',
  hasOverflowEllipsis: true,
}, {
  title: titleWithCurrency(MESSAGES.revenue, currency),
  key: 'revenue',
  ordering: ['revenue'],
  CellComponent: FormattedNumberCell,
  componentProps: { prefix: true },
  width: '150px',
  hasOverflowEllipsis: false,
}, {
  title: titleWithCurrency(MESSAGES.profit, currency),
  key: 'profit',
  ordering: ['profit'],
  CellComponent: FormattedNumberCell,
  componentProps: { prefix: true },
  width: '150px',
  hasOverflowEllipsis: false,
}, {
  title: MESSAGES.items_sold,
  key: 'items_sold',
  ordering: ['items_sold'],
  CellComponent: FormattedNumberCell,
  componentProps: {},
  width: '150px',
  hasOverflowEllipsis: false,
}, {
  title: MESSAGES.profit_margin,
  key: 'profit_margin',
  ordering: ['profit_margin'],
  CellComponent: FormattedNumberCell,
  componentProps: { perc: true },
  width: '150px',
  hasOverflowEllipsis: false,
}, {
  title: 'Segment',
  key: 'product_segments',
  ordering: null,
  CellComponent: SegmentsCell,
  componentProps: { segmentsMap, name_key: 'product_segment' },
  width: '180px',
  hasOverflowEllipsis: false,
}, {
  title: titleWithCurrency(MESSAGES.price, currency),
  key: 'price',
  ordering: ['price'],
  CellComponent: FormattedNumberCell,
  componentProps: { prefix: true },
  width: '150px',
  hasOverflowEllipsis: false,
}, {
  title: titleWithCurrency(MESSAGES.avg_price, currency),
  key: 'avg_price',
  ordering: ['avg_price'],
  CellComponent: FormattedNumberCell,
  componentProps: { prefix: true },
  width: '150px',
  hasOverflowEllipsis: false,
}, {
  title: titleWithCurrency(MESSAGES.cost, currency),
  key: 'cost',
  ordering: ['cost'],
  CellComponent: FormattedNumberCell,
  componentProps: { prefix: true },
  width: '150px',
  hasOverflowEllipsis: false,
}, {
  title: MESSAGES.items_returned,
  key: 'total_returns',
  ordering: ['total_returns'],
  CellComponent: FormattedNumberCell,
  componentProps: {},
  width: '150px',
  hasOverflowEllipsis: false,
}, {
  title: MESSAGES.return_rate,
  key: 'return_rate',
  ordering: ['return_rate'],
  CellComponent: FormattedNumberCell,
  componentProps: { perc: true },
  width: '150px',
  hasOverflowEllipsis: false,
}, {
  title: titleWithCurrency(MESSAGES.discounts, currency),
  key: 'discounts',
  ordering: ['discounts'],
  CellComponent: FormattedNumberCell,
  componentProps: { prefix: true },
  width: '150px',
  hasOverflowEllipsis: false,
}, {
  title: MESSAGES.repurchase_rate,
  key: 'repurchase_rate',
  ordering: ['repurchase_rate'],
  CellComponent: FormattedNumberCell,
  componentProps: { perc: true },
  width: '150px',
  hasOverflowEllipsis: false,
}, {
  title: MESSAGES.first_order_rate,
  key: 'first_order_rate',
  ordering: ['first_order_rate'],
  CellComponent: FormattedNumberCell,
  componentProps: { perc: true },
  width: '150px',
  hasOverflowEllipsis: false,
}, {
  title: MESSAGES.bought_uniquely_rate,
  key: 'bought_uniquely_rate',
  ordering: ['bought_uniquely_rate'],
  CellComponent: FormattedNumberCell,
  componentProps: { perc: true },
  width: '150px',
  hasOverflowEllipsis: false,
}, {
  title: MESSAGES.variants,
  key: 'variations_count',
  ordering: ['variations_count'],
  CellComponent: CollapsibleCell,
  componentProps: { },
  width: '75px',
  hasOverflowEllipsis: false,
}];
