import {
  DATE_FROM_ERROR_REQUIRED,
  DATE_TO_ERROR_REQUIRED,
  DISPLAY_METRIC_ERROR_REQUIRED,
  MAX_MONTHS_ERROR_REQUIRED,
  NAME_ERROR_REQUIRED,
} from 'constants/errors';
import Validator from 'utils/validator';

export default ({
  name, start_date, end_date, display_metric, max_months,
}) => {
  const errors = {};

  const nameValidator = new Validator(name);
  const startDateValidator = new Validator(start_date);
  const endDateValidator = new Validator(end_date);
  const displayMetricValidator = new Validator(display_metric);
  const maxMonthsValidator = new Validator(max_months);

  try {
    nameValidator.required(NAME_ERROR_REQUIRED);
  } catch (error) {
    errors.name = error.message;
  }

  try {
    startDateValidator.required(DATE_FROM_ERROR_REQUIRED);
  } catch (error) {
    errors.date_from = error.message;
  }

  try {
    endDateValidator.required(DATE_TO_ERROR_REQUIRED);
  } catch (error) {
    errors.date_to = error.message;
  }

  try {
    displayMetricValidator.required(DISPLAY_METRIC_ERROR_REQUIRED);
  } catch (error) {
    errors.display_metric = error.message;
  }

  try {
    maxMonthsValidator.largerThan(0, MAX_MONTHS_ERROR_REQUIRED);
  } catch (error) {
    errors.max_months = error.message;
  }

  return errors;
};
