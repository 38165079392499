import { MESSAGES } from 'constants/messages';

export const ACTIVE_INTEGRATION_STATUSES = {
  OK: 'OK',
  ERROR: 'ERROR',
  PULLING: 'PULLING',
  PULLING_PARTIAL: 'PULLING_PARTIAL',
  DISABLED: 'DISABLED',
  INVALID_CREDENTIALS: 'INVALID CREDENTIALS',
};

export const AVAILABLE_INTEGRATION_STATUSES = {
  ACTIVE: 'ACTIVE',
  coming_soon: 'COMING_SOON',
  INACTIVE: 'INACTIVE',
};

export const INTEGRATION_CATEGORY_NAMES = {
  E_COMMERCE: MESSAGES.ecommerce_cms,
  EMAIL_MARKETING: MESSAGES.email_marketing,
  CUSTOMER_SERVICE: MESSAGES.customer_service,
  AD_PROVIDERS: MESSAGES.ad_providers,
  SOCIAL_NETWORKS: MESSAGES.social_networks,
  ANALYTICS: MESSAGES.analytics,
};

export const INTEGRATION_CATEGORIES = {
  E_COMMERCE: 'E_COMMERCE',
  EMAIL_MARKETING: 'EMAIL_MARKETING',
  CUSTOMER_SERVICE: 'CUSTOMER_SERVICE',
  AD_PROVIDERS: 'AD_PROVIDERS',
  SOCIAL_NETWORKS: 'SOCIAL_NETWORKS',
  ANALYTICS: 'ANALYTICS',
};

export const AVAILABLE_INTEGRATIONS = {
  GOOGLE_ANALYTICS: 'google_analytics',
};
