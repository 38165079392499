import React, {
  forwardRef,
} from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import 'react-datepicker/dist/react-datepicker.css';
import { ReactComponent as CalendarIcon } from 'assets/svg/calendar.svg';
import NavigationButton from 'components/common/MonthPicker/NavigationButton';
import classNames from 'classnames';
import { noop } from 'lodash';

export const NavigationDisplay = forwardRef(({
  value,
  onClick,
  prevDisabled,
  onPrevClick,
  nextDisabled,
  onNextClick,
  type,
}, ref) => (
  <div className={classNames('navigation-display', type)}>
    <NavigationButton
      type={type}
      navigation="prev"
      disabled={prevDisabled}
      onClick={onPrevClick}
    />
    <div
      className="navigation-display__button"
      ref={ref}
      onClick={onClick}
    >
      <CalendarIcon className="navigation-display__button__icon" />
      <span className="navigation-display__button__text">
        {value}
      </span>
    </div>
    <NavigationButton
      type={type}
      navigation="next"
      disabled={nextDisabled}
      onClick={onNextClick}
    />
  </div>
));

export const DefaultDisplay = forwardRef(({
  value,
  onClick,
  prevDisabled,
  onPrevClick,
  nextDisabled,
  onNextClick,
  type,
}, ref) => (
  <div
    className="default-display"
    ref={ref}
    onClick={onClick}
  >
    <CalendarIcon className="default-display__icon" />
    <span className="default-display__text">
      {value}
    </span>
  </div>
));

const propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
  prevDisabled: PropTypes.bool,
  onPrevClick: PropTypes.func,
  nextDisabled: PropTypes.bool,
  onNextClick: PropTypes.func,
  type: PropTypes.oneOf(['primary', 'secondary']),
};

const defaultProps = {
  value: null,
  onClick: noop,
  prevDisabled: false,
  onPrevClick: noop,
  nextDisabled: false,
  onNextClick: noop,
  type: 'secondary',
};

NavigationDisplay.propTypes = propTypes;
NavigationDisplay.defaultProps = defaultProps;

DefaultDisplay.propTypes = propTypes;
DefaultDisplay.defaultProps = defaultProps;