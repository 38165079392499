import { MESSAGES } from 'constants/messages';
import React from 'react';

import viewPropTypes from 'components/views/Profile/PersonalBlock/types';
import { Stack } from '@mui/material';

const ViewMode = ({
  firstName, lastName, workRole, workEmail,
}) => (
  <Stack spacing={2}>
    <div className="personal-block__header">
      <h1 className="personal-block__full-name">
        {firstName}
        {' '}
        {lastName}
      </h1>
      <span className="personal-block__field-title">
        {MESSAGES.work_role}
      </span>
      <p className="personal-block__role">
        {workRole && workRole[1]}
      </p>
    </div>
    <div className="personal-block__fields">
      <div className="personal-block__field personal-block__email">
        <span className="personal-block__field-title">
          {MESSAGES.work_email}
        </span>
        <div className="personal-block__field-value">
          {workEmail}
        </div>
      </div>
    </div>
  </Stack>
);

ViewMode.propTypes = viewPropTypes;

export default ViewMode;
