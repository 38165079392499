import React, { useState } from 'react';
import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';

import './styles.scss';
import CardForm from 'containers/PrivateApp/Payment/PaymentForm/CardForm';
import Button from 'components/common/Button';
import { BUTTON_TYPE, REQUEST_STATUS } from 'constants/common';
import useAsync from 'hooks/useAsync';
import { createLicenseBundle, createPaymentCard } from 'api/paymentAPI';
import { getCurrentLicense } from 'store/license/getters';
import {
  deleteLicenseAction,
  setCustomerAction,
  setLicenseAction,
} from 'store/license/licenseSlice';
import { setPaymentMethodAction } from 'store/paymentMethod/paymentMethodSlice';
import { ROUTES } from 'constants/routes';
import useRouter from 'hooks/useRouter';
import { MESSAGES } from 'constants/messages';
import { Stack } from '@mui/material';
import LicensePackageHeader from 'components/common/LicensePackage/LicensePackageHeader';
import useCurrentUser from 'hooks/useCurrentUser';
import Block from 'components/common/Block';

const CARD = 'card';

const PaymentForm = () => {
  document.title = `${MESSAGES.payment_plan} - ${MESSAGES.custimy}`;

  const license = useSelector(getCurrentLicense);
  const dispatch = useDispatch();
  const { replace, location } = useRouter();
  const stripe = useStripe();
  const elements = useElements();
  const [isSubmitDisabled, setSubmitDisabled] = useState(false);
  const [monthlyBilling, setMonthlyBilling] = useState(true);
  const { setCurrentUserAsync } = useCurrentUser();

  const goToCompanyDetails = () => {
    const licenseDraft = JSON.stringify(license);
    dispatch(deleteLicenseAction());
    replace(ROUTES.newLicense, { license: licenseDraft });
  };

  const createBundlePayment = async (selectedLicense, bundle, setErrors) => {
    try {
      await createLicenseBundle({
        license_uuid: selectedLicense.uuid,
        bundle_uuid: bundle.uuid,
      });

      await setCurrentUserAsync();
      replace(ROUTES.integrations, location?.state);
    } catch (exception) {
      setErrors({
        detail: exception[0],
      });
    }
  };

  const createStripePayment = async (cardName, promotionCodeInfo, setErrors) => {
    if (!stripe || !elements) {
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: CARD,
      card: elements.getElement(CardNumberElement),
      billing_details: {
        name: cardName,
      },
    });

    if (error) {
      throw error;
    }

    try {
      const { payment_method, customer, license: updatedLicense } = await createPaymentCard({
        payment_method_id: paymentMethod?.id,
        license_uuid: license.uuid,
        promotion_code_id: promotionCodeInfo?.data?.id,
        monthly_billing: monthlyBilling,
      });

      dispatch(setLicenseAction(updatedLicense));
      dispatch(setCustomerAction(customer));
      dispatch(setPaymentMethodAction({ payment_method_data: payment_method }));
      replace(ROUTES.integrations, location?.state);
    } catch (exception) {
      setErrors({
        detail: exception[0],
      });
    }
  };

  const subscribeHandler = async ({ cardName, promotionCodeInfo }, setErrors) => {
    const isBundlePayment = promotionCodeInfo && promotionCodeInfo.isBundle;

    if (isBundlePayment) {
      await createBundlePayment(license, promotionCodeInfo.data, setErrors);
    } else {
      await createStripePayment(cardName, promotionCodeInfo, setErrors);
    }
  };

  const {
    execute: subscribeHandlerAsync,
    status: subscribeHandlerStatus,
  } = useAsync(subscribeHandler);

  return (
    <>
      <div className="payment-form">
        <Block postHeader={license.company_name} header={MESSAGES.billing}>
          <div className="payment-form__billing">
            <div className="payment-form__card-form-wrapper">
              <LicensePackageHeader
                toggleValue={!monthlyBilling}
                onToggleChange={() => setMonthlyBilling(!monthlyBilling)}
              />
              <CardForm
                onSubmit={subscribeHandlerAsync}
                setSubmitDisabled={setSubmitDisabled}
                hasPromotionCode
              >
                <Stack spacing={2}>
                  <Stack
                    direction="row"
                    spacing={1}
                    display="flex"
                    justifyContent="center"
                  >
                    <Button
                      inputType
                      type={BUTTON_TYPE.secondary}
                      isPreventSubmit
                      block
                      onClick={goToCompanyDetails}
                      isDisabled={subscribeHandlerStatus === REQUEST_STATUS.loading}
                    >
                      {MESSAGES.back}
                    </Button>
                    <Button
                      type={BUTTON_TYPE.primary}
                      block
                      isDisabled={!stripe
                        || subscribeHandlerStatus === REQUEST_STATUS.loading
                        || isSubmitDisabled}
                    >
                      {MESSAGES.subscribe}
                    </Button>
                  </Stack>
                </Stack>
              </CardForm>
            </div>
          </div>
        </Block>
      </div>
    </>
  );
};

export default PaymentForm;
