import React from 'react';
import PropTypes from 'prop-types';
import 'components/views/Admin/Bundles/BundleCells/styles.scss';
import moment from 'moment';
import { ReactComponent as EditIcon } from 'assets/svg/pen.svg';
import { MESSAGES } from 'constants/messages';
import IconButton from 'components/common/IconButton';

export const TitleCell = ({ column, row }) => (
  <span className="bundle__cell__title">
    {row[column.key]}
  </span>
);

TitleCell.propTypes = {
  column: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  row: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
};

export const ExpirationDateCell = ({ column, row }) => {
  const value = row[column.key] && moment(row[column.key]).format('YYYY-MM-DD');
  return (
    <span className="bundle__cell__expiration-date">
      {value}
    </span>
  );
};

ExpirationDateCell.propTypes = TitleCell.propTypes;

export const IsActiveCell = ({ column, row }) => (
  <span className="bundle__cell__is-active">
    {row[column.key] ? MESSAGES.yes : MESSAGES.no}
  </span>
);

IsActiveCell.propTypes = TitleCell.propTypes;

export const EditBundleCell = ({ column, row, onClick }) => (
  <div className="bundle__cell__edit">
    <IconButton
      className="icon-button__table"
      icon={EditIcon}
      type="secondary"
      onClick={() => onClick({ column, row })}
    />
  </div>
);

EditBundleCell.propTypes = {
  column: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  row: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  onClick: PropTypes.func.isRequired,
};
