import React, { useState } from 'react';
import Cropper from 'react-cropper';
import Popup from 'reactjs-popup';
import 'components/views/Profile/CropperImage/style.scss';
import 'cropperjs/dist/cropper.css';
import Button from 'components/common/Button';
import { BUTTON_TYPE } from 'constants/common';
import { MESSAGES } from 'constants/messages';

const CropperImage = (props) => {
  const [cropper, setCropper] = useState();
  const uploadAvatar = (event) => {
    cropper.cropper.destroy();
    props.uploadAvatar(event);
  };

  const dataURItoBlob = (dataURI) => {
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
    else byteString = unescape(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  };

  const savePhoto = () => {
    const dataUrl = cropper.cropper.getCroppedCanvas().toDataURL('image/jpeg');
    const file = dataURItoBlob(dataUrl);
    props.cropAvatar(dataUrl, file);
  };

  return (
    <Popup
      open={props.open}
      modal
      contentStyle={{
        width: '800px',
        height: '492px',
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.15)',
        borderRadius: '5px',
        padding: '0',
        margin: '100px 400px',
      }}
      overlayStyle={{
        background: 'rgba(159, 180, 180, 0.5)',
      }}
      lockScroll
      onClose={props.closeAvatarCropper}
      closeOnDocumentClick={false}
    >
      <div className="avatar-cropper">
        <div className="avatar-cropper__header">
          {MESSAGES.add_photo_profile}
        </div>
        <Cropper
          style={{ backgroundColor: '#fff' }}
          aspectRatio={16 / 16}
          viewMode={1}
          ref={(cropper) => { setCropper(cropper); }}
          className="cropper-block"
          src={props.avatar || null}
        />
        <div className="avatar-cropper__footer">
          <div className="avatar-cropper__footer__buttons">
            <div className="upload-photo">
              <input type="file" className="hidden-input" onChange={uploadAvatar} />
              <div className="personal-block__edit-mode-cancel form__cancel">
                <Button
                  onClick={props.closeAvatarCropper}
                  type={BUTTON_TYPE.secondary}
                  isPreventSubmit
                >
                  Cancel
                </Button>
              </div>
            </div>
            <div className="personal-block__edit-mode-save form__submit">
              <Button
                type={BUTTON_TYPE.primary}
                block
                onClick={savePhoto}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default CropperImage;
