import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ReactComponent as DeepDive } from 'assets/svg/deepDive.svg';
import GuideInfo from 'components/common/GuideInfo';
import { NumberFormatted } from 'helper/NumberFormatted';
import { MESSAGES } from 'constants/messages';
import LineChart from 'components/common/Charts/LineChart';
import { getSetValuesInArray } from 'utils/common';
import { Stack } from '@mui/material';
import TrendIndicator from 'components/common/TrendIndicator';
import { isNumber } from 'lodash';
import { getTrendFromValues } from 'utils/formatters';
import './style.scss';
import IconButton from 'components/common/IconButton';
import { useSelector } from 'react-redux';
import { getActiveIntegrationsList } from 'store/license/getters';
import { checkHasIntegration, checkHasIntegrationCategory } from 'utils/activeIntegrations';
import IntegrationRequired from 'components/common/IntegrationRequired/IntegrationRequired';

const Metric = ({
  option, graphData, onClickChartHandler, isMostRecent,
}) => {
  const activeIntegrationsList = useSelector(getActiveIntegrationsList);

  const {
    key,
    title,
    guide,
    prefix,
    value,
    perc,
    category,
    requiredIntegration,
    requiredIntegrationCategory,
  } = option;

  const itemData = graphData?.[key];
  const dataSet = {
    dateRange: graphData?.dateRange.map((item) => moment(item).format('MMM')),
    data: itemData,
    title,
    key,
  };

  const prefixConfig = {
    perc,
    prefix,
  };

  const lastMonthTrend = !!itemData && !isMostRecent && getTrendFromValues(
    value,
    itemData[itemData?.length - 2],
  );

  const openBarChart = () => {
    let composedTitle = dataSet.title;
    if (category) {
      composedTitle = `${category.title} ${dataSet.title}`;
    }
    onClickChartHandler(dataSet.key, composedTitle, prefixConfig, category?.key);
  };

  const isCategoryNotIntegrated = requiredIntegrationCategory
    ? !checkHasIntegrationCategory(activeIntegrationsList, requiredIntegrationCategory) : null;
  const isSourceNotIntegrated = requiredIntegration
    ? !checkHasIntegration(activeIntegrationsList, requiredIntegration) : null;
  const isNotIntegrated = isCategoryNotIntegrated || isSourceNotIntegrated;

  const showEmptyState = () => (
    isNotIntegrated
      ? (
        <div className="required-integration-wrapper">
          <IntegrationRequired
            requiredIntegrationCategory={requiredIntegrationCategory}
            requiredIntegration={requiredIntegration}
          />
        </div>
      )
      : (
        <div className="empty-state">
          <span className="no-data">
            {MESSAGES.no_data_available}
          </span>
        </div>
      )
  );

  return (
    <Stack className={classNames('metric', {
      disabled: !value,
    })}
    >
      <div className="title-container">
        <div className="title">
          {category && (
            <div className="item-source">
              {category.icon}
            </div>
          )}
          <span className="title-value">
            {!!title && option.title}
          </span>
          {isNumber(lastMonthTrend) && (
            <TrendIndicator
              percentage={lastMonthTrend}
              label={MESSAGES.month_on_month}
            />
          )}
        </div>
        <div className="info-buttons">
          <IconButton
            icon={DeepDive}
            className="deep-dive"
            onClick={openBarChart}
            tooltip={MESSAGES.click_to_open_chart_view}
          />
          {!!guide && (
            <GuideInfo info={guide} tooltipPlacement="top" />
          )}
        </div>
      </div>
      <div className="value-container">
        {(!!value || value === 0) ? (
          <button
            type="button"
            className="bar-chart"
            onClick={openBarChart}
          >
            <div className="value-block">
              <div className="item-value">
                {!!option.prefix && (
                  <span className="prefix">
                    {option.prefix}
                  </span>
                )}
                {NumberFormatted(option.value)}
                {option.perc && (
                  <span className="value-percentage">
                    %
                  </span>
                )}
              </div>
            </div>
            {dataSet.data && getSetValuesInArray(dataSet.data) > 1 ? (
              <LineChart
                dataSet={dataSet}
                prefixConfig={prefixConfig}
                isMostRecent={isMostRecent}
              />
            ) : (
              <div className="empty-state">
                <span className="no-data">
                  {MESSAGES.no_earlier_data_available}
                </span>
              </div>
            )}
          </button>
        ) : showEmptyState() }
      </div>
    </Stack>
  );
};

Metric.propTypes = {
  option: PropTypes.shape({
    key: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    value: PropTypes.number,
    prefix: PropTypes.string,
    perc: PropTypes.bool,
    guide: PropTypes.string,
    noDataMessage: PropTypes.string,
    requiredIntegration: PropTypes.string,
    requiredIntegrationCategory: PropTypes.string,
    category: PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      icon: PropTypes.node.isRequired,
    }),
  }),
  graphData: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.any,
    ]),
  ),
  onClickChartHandler: PropTypes.func,
  isMostRecent: PropTypes.bool,
};

Metric.defaultProps = {
  option: undefined,
  graphData: undefined,
  onClickChartHandler: undefined,
  isMostRecent: false,
};

export default Metric;
