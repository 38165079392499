import React, { useEffect } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import 'containers/PrivateApp/Portal/styles.scss';
import Profile from 'components/views/Profile';
import Segments from 'components/views/Segments';
import { ROUTES } from 'constants/routes';
import Settings from 'components/views/Settings';
import Integrations from 'components/views/Integrations';
import SingleSegments from 'components/views/SingleSegments';
import Customers from 'components/views/Customers';
import useState from 'hooks/useState';
import HomePage from 'components/views/Homepage';
import Customer from 'components/views/Customer';
import AdminRoute from 'components/common/ProtectedRoutes/AdminRoute';
import PortalMenu from './PortalMenu';
import { useSelector } from 'react-redux';
import { getHasDataAccess } from 'store/license/getters';
import DataAccessRoute from 'components/common/ProtectedRoutes/DataAccessRoute';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { getTemplateColumn } from 'containers/PrivateApp/Portal/utils';
import { VIEWTYPE } from 'constants/common';
import CustomHeader from 'containers/PrivateApp/Portal/CustomHeader';
import MobileUserPanel from 'containers/PrivateApp/Portal/MobileUserPanel';
import usePortal from 'hooks/usePortal';
import CustomAudiences from 'components/views/CustomAudiences';
import CustomAudience from 'components/views/CustomAudience';
import ProductList from 'components/views/Products/ProductList';
import SingleProductView from 'components/views/Products/SingleProductView';
import CategoryList from 'components/views/Products/CategoryList';
import SingleCategoryView from 'components/views/Products/SingleCategoryView';
import Cohorts from 'components/views/Cohorts';
import Cohort from 'components/views/Cohort';
import ExperimentListView from 'components/views/Experiments/ExperimentListView';
import ExperimentCreateView from 'components/views/Experiments/ExperimentCreateView';
import ExperimentView from 'components/views/Experiments/ExperimentView';

const Portal = () => {
  const { initializeState } = useState();
  const hasDataAccess = useSelector(getHasDataAccess);

  const {
    isMenuOpened,
    isOpenedLarge,
    defaultSideBar,
    viewType,
    toggleDrawer,
    toggleMenu,
    setIsSliderOpened,
    onCloseSlideMenu,
  } = usePortal();

  useEffect(() => {
    if (hasDataAccess) {
      initializeState();
    }
  }, []);

  return (
    <div className="portal">
      <Grid
        component={Box}
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridTemplateRows="65px 1fr"
        sx={{ minHeight: '100%' }}
      >
        <Grid
          component={Box}
          gridColumn="span 12"
          display="grid"
          gridTemplateColumns="1fr"
        >
          <CustomHeader
            isMenuOpened={isMenuOpened}
            viewType={viewType}
            toggleMenu={toggleMenu}
            handleSliderClick={setIsSliderOpened}
          />
        </Grid>
        <Grid
          component={Box}
          gridColumn="span 12"
          display="grid"
          gridTemplateColumns={getTemplateColumn(viewType, defaultSideBar, isOpenedLarge)}
        >
          {(viewType !== VIEWTYPE.MOBILE || (viewType === VIEWTYPE.MOBILE && isMenuOpened))
            && (
              <Grid
                component={Box}
                gridColumn={viewType === VIEWTYPE.MOBILE ? 'span 12' : 'span 1'}
              >
                <PortalMenu
                  isMenuOpened={isMenuOpened}
                  defaultSideBar={defaultSideBar}
                  viewType={viewType}
                  toggleDrawer={toggleDrawer}
                  toggleMenu={toggleMenu}
                  onCloseSlideMenu={onCloseSlideMenu}
                />
              </Grid>
            )}
          {viewType === VIEWTYPE.MOBILE && isMenuOpened
            && (
              <Grid
                item
                xs={12}
                sx={{
                  display: 'block',
                  maxHeight: 'fitContent',
                  backgroundColor: 'white',
                }}
              >
                <MobileUserPanel toggleMenu={toggleMenu} />
              </Grid>
            )}
          {(viewType !== VIEWTYPE.MOBILE || (viewType === VIEWTYPE.MOBILE && !isMenuOpened))
            && (
              <Grid
                component={Box}
                gridColumn={viewType === VIEWTYPE.MOBILE ? 'span 12' : 'span 11'}
              >
                <div className="portal__content-wrapper">
                  <main className="portal__content">
                    <Switch>
                      <Route exact path="/">
                        <Redirect to="/home" />
                      </Route>
                      <Route path={ROUTES.profiles} />
                      <Route path={ROUTES.profile} component={Profile} />
                      <Route path={ROUTES.home} component={HomePage} />
                      <DataAccessRoute path={ROUTES.customer} component={Customer} />
                      <DataAccessRoute path={ROUTES.customers} component={Customers} />
                      <DataAccessRoute path={ROUTES.productCategory} component={SingleCategoryView} />
                      <DataAccessRoute path={ROUTES.productCategories} component={CategoryList} />
                      <DataAccessRoute path={ROUTES.product} component={SingleProductView} />
                      <DataAccessRoute path={ROUTES.products} component={ProductList} />
                      <Route path={ROUTES.licenseSettings} component={Settings} />
                      <AdminRoute path={ROUTES.integrations} component={Integrations} />
                      <DataAccessRoute path={ROUTES.segments} component={Segments} exact />
                      <DataAccessRoute path={ROUTES.segments_id} component={SingleSegments} />
                      <DataAccessRoute path={ROUTES.newCohort} component={Cohort} />
                      <DataAccessRoute path={ROUTES.cohort} component={Cohort} />
                      <DataAccessRoute path={ROUTES.cohorts} component={Cohorts} />
                      <DataAccessRoute path={ROUTES.newExperiment} component={ExperimentCreateView} />
                      <DataAccessRoute path={ROUTES.experiment} component={ExperimentView} />
                      <DataAccessRoute path={ROUTES.experiments} component={ExperimentListView} />
                      <DataAccessRoute path={ROUTES.newCustomAudience} component={CustomAudience} />
                      <DataAccessRoute path={ROUTES.customAudience} component={CustomAudience} />
                      <DataAccessRoute path={ROUTES.customAudiences} component={CustomAudiences} />
                    </Switch>
                  </main>
                </div>
              </Grid>
            )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Portal;
