import React from 'react';
import { MESSAGES } from 'constants/messages';
import { ReactComponent as FacebookAdsIcon } from 'assets/svg/platforms/facebook_ads.svg';
import { ReactComponent as GoogleAdsIcon } from 'assets/svg/platforms/google_ads.svg';
import { ReactComponent as BlendedIcon } from 'assets/svg/platforms/blended.svg';
import { ReactComponent as InstagramIcon } from 'assets/svg/platforms/instagram.svg';

export const PLATFORMS = {
  facebook: {
    title: MESSAGES.facebook,
    icon: <FacebookAdsIcon />,
    integration: 'facebook_ads',
  },
  google_ads: {
    title: MESSAGES.google_ads,
    icon: <GoogleAdsIcon />,
    integration: 'google_ads',
  },
  instagram: {
    title: MESSAGES.instagram,
    icon: <InstagramIcon />,
    integration: 'facebook_ads',
  },
  blended: {
    title: MESSAGES.blended,
    icon: <BlendedIcon />,
  },
};
