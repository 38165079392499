import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import Select from 'components/common/Select';
import { ReactComponent as RemoveIcon } from 'assets/svg/remove.svg';
import { isFilterValid } from '../index';
import TypedInput from 'components/common/TypedInput';
import IconButton from 'components/common/IconButton';

const CustomerListFilter = ({
  filter, setFilter, fields, operators, handleRemove,
}) => {
  const [field, setField] = useState(null);
  const [operatorsList, setOperatorsList] = useState([]);
  const [operator, setOperator] = useState(null);
  const [value, setValue] = useState('');

  useEffect(() => {
    if (isFilterValid(filter)) {
      setField(filter.field);
      setOperator(filter.operator);
      const operatorsList = operators[filter.field.type];
      setOperatorsList(operatorsList);
      setValue(filter.value);
    }
  }, []);

  const filterChanged = (field, operator, value) => {
    setFilter({
      key: filter.key,
      field,
      operator,
      value,
    });
  };

  const handleFieldChange = ({ target }) => {
    const entry = target.value;
    const prev = field;
    setField(entry);
    setOperatorsList(operators[entry.type]);

    if (!prev || prev.type === entry.type) {
      filterChanged(entry, operator, value);
    } else {
      // when the new type is selected clear all
      setOperator(null);
      setValue('');
      filterChanged(entry, null, '');
    }
  };

  const handleOperatorChange = ({ target }) => {
    setOperator(target.value);
    filterChanged(field, target.value, value);
  };

  const handleValueChange = (val) => {
    setValue(val);
    filterChanged(field, operator, val);
  };

  return (
    <tr className="customer-list-filter">
      <td>
        <Select
          label=""
          value={field}
          onChange={handleFieldChange}
          onBlur={() => undefined}
          options={fields}
        />
      </td>
      <td>
        <Select
          label=""
          value={operator}
          onChange={handleOperatorChange}
          onBlur={() => undefined}
          options={operatorsList}
          isDisabled={!field}
        />
      </td>
      <td>
        <TypedInput
          type={field?.type}
          value={value}
          valueChanged={handleValueChange}
        />
      </td>
      <td>
        <IconButton
          icon={RemoveIcon}
          type="danger"
          onClick={handleRemove}
        />
      </td>
    </tr>
  );
};

CustomerListFilter.propTypes = {
  filter: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  setFilter: PropTypes.func.isRequired,
  fields: PropTypes.oneOfType([
    PropTypes.string,
  ]).isRequired,
  operators: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  handleRemove: PropTypes.func.isRequired,
};
export default CustomerListFilter;
