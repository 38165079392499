import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import UserPanel from 'containers/PrivateApp/Portal/CustomHeader/UserPanel';
import Header from 'containers/PrivateApp/Portal/CustomHeader/Header';
import { VIEWTYPE } from 'constants/common';

const CustomHeader = ({
  viewType, toggleMenu, isMenuOpened, handleSliderClick,
}) => (
  <div className="custom-header-portal">
    {viewType === VIEWTYPE.MOBILE
      ? (
        <Header
          isMenuOpened={isMenuOpened}
          viewType={viewType}
          toggleMenu={toggleMenu}
          handleSliderClick={handleSliderClick}
        />
      )
      : (
        <>
          <Header
            isMenuOpened={isMenuOpened}
            viewType={viewType}
            toggleMenu={toggleMenu}
            handleSliderClick={handleSliderClick}
          />
          <UserPanel />
        </>
      )}
  </div>
);

export default CustomHeader;

CustomHeader.propTypes = {
  toggleMenu: PropTypes.func.isRequired,
  handleSliderClick: PropTypes.func.isRequired,
  viewType: PropTypes.string.isRequired,
  isMenuOpened: PropTypes.bool.isRequired,
};
