import React from 'react';
import 'components/views/Integrations/Status/styles.scss';
import propTypes from 'prop-types';
import { ACTIVE_INTEGRATION_STATUSES } from 'components/views/Integrations/constants/Constants';
import classNames from 'classnames';
import { capitalizeFirstLetter } from 'helper/textFormatters';
import { get } from 'lodash';

const getStatus = (status) => {
  const mappedStatus = ACTIVE_INTEGRATION_STATUSES[status];

  const conversions = {
    [ACTIVE_INTEGRATION_STATUSES.OK]: 'OK',
    [ACTIVE_INTEGRATION_STATUSES.PULLING_PARTIAL]: 'Pulling',
  };

  return get(conversions, mappedStatus, capitalizeFirstLetter(mappedStatus.toLowerCase()));
};

const Status = ({ status }) => (
  <div
    className={classNames(
      'status__container',
      `status__container--${status.toLowerCase()}`,
    )}
  >
    <div className="status__circle" />
    <span className="status__text">
      {getStatus(status)}
    </span>
  </div>
);

Status.propTypes = {
  status: propTypes.string,
};

Status.defaultProps = {
  status: undefined,
};
export default Status;
