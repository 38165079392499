import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as EditIcon } from 'assets/svg/pen.svg';
import { ReactComponent as DoneIcon } from 'assets/svg/done.svg';
import { ReactComponent as RemoveIcon } from 'assets/svg/offCircleClose.svg';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import IconButton from 'components/common/IconButton';
import './styles.scss';
import { MESSAGES } from 'constants/messages';
import classNames from 'classnames';
import { noop } from 'lodash';

const EditableField = ({
  children,
  value,
  placeholder,
  isInitialized,
  hasSave,
  hasDiscard,
  hasClose,
  onSave,
  onDiscard,
  onClose,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleClose = () => {
    if (onClose() === false) return;
    setIsEditing(false);
  };

  const handleSave = () => {
    if (onSave() === false) return;
    setIsEditing(false);
  };

  const handleDiscard = () => {
    if (onDiscard() === false) return;
    setIsEditing(false);
  };

  const handleFieldKeyDown = ({ keyCode }) => {
    if (keyCode === 13) handleSave();
    if (keyCode === 27) handleDiscard();
  };

  useEffect(() => {
    if (!isInitialized || value) return;
    setIsEditing(true);
  }, [isInitialized]);

  return (
    <div className="editable-field" onKeyDown={handleFieldKeyDown}>
      {isEditing ? (
        <div className="editable-field__input">
          {children}
          {hasDiscard && (
            <IconButton
              className="editable-field__button"
              size="small"
              type="secondary"
              tooltip={MESSAGES.discard}
              icon={RemoveIcon}
              onClick={handleDiscard}
              key="discard-button"
            />
          )}
          {hasSave && (
            <IconButton
              className="editable-field__button"
              size="small"
              type="primary"
              uncolored
              tooltip={MESSAGES.save}
              icon={DoneIcon}
              onClick={handleSave}
              key="save-button"
            />
          )}
          {hasClose && (
            <IconButton
              className="editable-field__button"
              size="small"
              type="secondary"
              uncolored
              tooltip={MESSAGES.close}
              icon={CloseIcon}
              onClick={handleClose}
              key="close-button"
            />
          )}
        </div>
      ) : (
        <div className={classNames('editable-field__display', {
          placeholder: !value,
        })}
        >
          {value || placeholder}
          <IconButton
            className="editable-field__button"
            size="small"
            type="secondary"
            tooltip={MESSAGES.edit}
            tooltipPlacement="right"
            icon={EditIcon}
            onClick={() => setIsEditing(true)}
            key="edit-button"
          />
        </div>
      )}
    </div>
  );
};

EditableField.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
    PropTypes.number,
  ]),
  placeholder: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
    PropTypes.number,
  ]),
  isInitialized: PropTypes.bool,
  hasSave: PropTypes.bool,
  hasDiscard: PropTypes.bool,
  hasClose: PropTypes.bool,
  onSave: PropTypes.func,
  onDiscard: PropTypes.func,
  onClose: PropTypes.func,
};

EditableField.defaultProps = {
  value: null,
  placeholder: null,
  isInitialized: false,
  hasSave: false,
  hasDiscard: false,
  hasClose: false,
  onSave: noop,
  onDiscard: noop,
  onClose: noop,
};

export default EditableField;
