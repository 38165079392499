import React from 'react';
import classNames from 'classnames';
import propTypes from 'prop-types';
import './styles.scss';
import { VIEWTYPE } from 'constants/common';
import useRouter from 'hooks/useRouter';
import { Link } from 'react-router-dom';
import { getRouteKeyFromPath } from 'helper/routerHelper';

const MobileSideBarMenuItem = ({
  item, viewType, toggleMenu,
}) => {
  const { title, hrefTo, disabled } = item;
  const { location } = useRouter();

  const handleLinkClick = (e) => {
    if (disabled) {
      e.preventDefault();
    }
    if (viewType === VIEWTYPE.MOBILE) toggleMenu();
  };

  const isActive = hrefTo === location.pathname
    || item.id === getRouteKeyFromPath(location.pathname);

  return (
    <li className={classNames('mobile-menu-item', {
      'mobile-menu__item--disabled': disabled,
    })}
    >
      <Link
        onClick={handleLinkClick}
        to={hrefTo}
        className={classNames('mobile-menu-item__link', {
          'mobile-menu-item__link--active': isActive,
        })}
        title={title}
      >
        {title}
      </Link>
    </li>
  );
};

MobileSideBarMenuItem.propTypes = {
  item: propTypes.objectOf(propTypes.oneOfType([propTypes.any])).isRequired,
  viewType: propTypes.string.isRequired,
  toggleMenu: propTypes.func,
};

MobileSideBarMenuItem.defaultProps = {
  toggleMenu: null,
};

export default MobileSideBarMenuItem;
