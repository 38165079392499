import React from 'react';
import PropTypes from 'prop-types';
import Percentage from 'components/common/Percentage';
import LastMonthLabel from 'components/common/LastMonthLabel';
import classNames from 'classnames';
import { NumberFormatted, NumberFormattedCurrency } from 'helper/NumberFormatted';
import { MESSAGES } from 'constants/messages';

export const ItemTemplate = ({
  title, data, className, percentage, prefix,
}) => (
  <div className={classNames('wrapper-block', className)}>
    <div className="item-title">{title}</div>
    {(!!data || !!percentage) && (
    <div className="item">
      <div className="item-value">
        {!!prefix && !!prefix.length && <span className="ddk">{prefix}</span>}
        {prefix ? NumberFormattedCurrency(data) : NumberFormatted(data)}
      </div>
      <div className="item-percent">
        {!!percentage && (
        <>
          <Percentage>{percentage}</Percentage>
          <LastMonthLabel />
        </>
        )}
      </div>
    </div>
    )}
    {!data && !percentage && (
      <div className="item-empty-state">
        {MESSAGES.no_data_available}
      </div>
    )}
  </div>
);

ItemTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  percentage: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  prefix: PropTypes.string,
  className: PropTypes.string,
  data: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
};

ItemTemplate.defaultProps = {
  percentage: undefined,
  prefix: undefined,
  className: undefined,
};
