import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import classNames from 'classnames';
import { cutThousands } from 'utils/common';
import moment from 'moment';
import InfoTooltip from 'components/common/InfoTooltip';
import { isNil } from 'lodash';

export const CohortValueCell = ({
  column, row, maxValue, minValue, isCumulative, month,
}) => {
  const value = row[column.key];
  if (isNil(value)) return null;

  const processVal = (val) => (!isCumulative ? Math.log(val + 1) : Math.max(val, 1));
  const gradient = value
    ? processVal(value - minValue) / processVal(maxValue - minValue)
    : 0;

  const isPredictive = !!row.predictiveFromMonth && row.predictiveFromMonth <= month;

  const cohortFontColor = gradient < 0.4 && !isPredictive ? 'black' : 'white';
  const backgroundColor = `hsla(251, 40%, ${90 - (gradient * 50)}%, 1)`;

  const cellContent = (
    <span
      className={classNames('table__cell__cohort_value', cohortFontColor, {
        predictive: isPredictive,
      })}
      style={{ backgroundColor, borderColor: backgroundColor }}
    >
      {cutThousands(value)}
    </span>
  );

  if (!row?.date) return cellContent;

  const cellMonth = moment(row.date, 'YYYY-MM-DDTHH:mm:ssZ').add(month, 'M');
  return (
    <InfoTooltip text={cellMonth.format('MMM YYYY')} placement="top">
      {cellContent}
    </InfoTooltip>
  );
};

CohortValueCell.propTypes = {
  column: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  row: PropTypes.shape({
    date: PropTypes.string,
    predictiveFromMonth: PropTypes.number,
  }).isRequired,
  maxValue: PropTypes.number.isRequired,
  minValue: PropTypes.number.isRequired,
  isCumulative: PropTypes.bool,
  month: PropTypes.number.isRequired,
};

CohortValueCell.defaultProps = {
  isCumulative: false,
};
