import React, { useEffect, useState } from 'react';
import { Stack, Grid } from '@mui/material';
import SingleCategoryHeader from 'components/views/Products/SingleCategoryView/SingleCategoryHeader';
import CategorySegmentsChart from 'components/views/Products/SingleCategoryView/CategorySegmentsChart';
import SingleCategoryItems from 'components/views/Products/SingleCategoryView/SingleCategoryItem';
import { MESSAGES } from 'constants/messages';
import { getProductCategory } from 'api/productsAPI';
import { useParams } from 'react-router-dom';
import { keyBy } from 'lodash';
import { getSegmentsInfo } from 'api/segmentsAPI';
import useRouter from 'hooks/useRouter';
import { ROUTES } from 'constants/routes';
import { GUIDES } from 'constants/guides';

const getOptions = (data) => [
  {
    title: MESSAGES.revenue,
    value: data?.revenue,
    percentage: data?.revenue_last_month_percentage,
    prefix: data.currency,
    monthly: true,
    titleSuffix: MESSAGES.per_year,
    isPrevious: true,
    hasPrevValue: true,
    guide: GUIDES.SC2,
  },
  {
    title: MESSAGES.profit,
    value: data?.profit,
    percentage: data?.profit_last_month_percentage,
    prefix: data.currency,
    monthly: true,
    titleSuffix: MESSAGES.per_year,
    isPrevious: true,
    hasPrevValue: true,
    guide: GUIDES.SC3,
  }, {
    title: MESSAGES.profit_margin,
    value: data?.profit_margin,
    percentage: data?.profit_margin_last_month_percentage,
    perc: true,
    monthly: true,
    titleSuffix: MESSAGES.per_year,
    isPrevious: true,
    hasPrevValue: true,
    guide: GUIDES.SC10,
  },
  {
    title: MESSAGES.items_sold,
    value: data?.items_sold,
    percentage: data?.items_sold_last_month_percentage,
    monthly: true,
    prefix: null,
    titleSuffix: MESSAGES.per_year,
    isPrevious: true,
    hasPrevValue: true,
    guide: GUIDES.SC4,
  },
  {
    title: MESSAGES.items_returned,
    value: data?.total_returns,
    percentage: data?.total_returns_last_month_percentage,
    prefix: null,
    monthly: true,
    titleSuffix: MESSAGES.per_year,
    isPrevious: true,
    hasPrevValue: true,
    guide: GUIDES.SC5,
  },
  {
    title: MESSAGES.return_rate,
    value: data?.return_rate,
    percentage: data?.return_rate_last_month_percentage,
    perc: true,
    monthly: true,
    titleSuffix: MESSAGES.per_year,
    isPrevious: true,
    hasPrevValue: true,
    guide: GUIDES.SC6,
  },
  {
    title: MESSAGES.discounts,
    value: data?.total_discounts,
    percentage: data?.total_discounts_last_month_percentage,
    prefix: data.currency,
    monthly: true,
    titleSuffix: MESSAGES.per_year,
    isPrevious: true,
    hasPrevValue: true,
    guide: GUIDES.SC7,
  },
  {
    title: MESSAGES.discount_rate,
    value: data?.discount_rate,
    percentage: data?.discount_rate_last_month_percentage,
    perc: true,
    monthly: true,
    titleSuffix: MESSAGES.per_year,
    isPrevious: true,
    hasPrevValue: true,
    guide: GUIDES.SC8,
  },
];

const SingleCategoryView = () => {
  const { id } = useParams();
  const [customerSegmentsMap, setCustomerSegmentsMap] = useState({});
  const [data, setData] = useState({});
  const { push } = useRouter();

  const { customer_segments, products_in_customer_segments: productsInSegments } = data;

  useEffect(() => {
    const populateCustomerSegments = async () => {
      const response = await getSegmentsInfo();
      setCustomerSegmentsMap(keyBy(response, 'id'));
    };

    populateCustomerSegments();
  }, []);

  const fetchCategory = async () => {
    getProductCategory(id).then((resp) => {
      setData(resp);
    }).catch(() => {
      push(ROUTES.productCategories);
    });
  };

  useEffect(() => {
    id && fetchCategory();
  }, [id]);

  return (
    <div className="single-category-view">
      <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>
        <Grid container spacing={2}>
          <Grid item lg={4} md={5} xs={12}>
            <Stack spacing={2}>
              <SingleCategoryHeader
                data={data}
              />
              <CategorySegmentsChart
                customer_segments={customer_segments}
                segmentsMap={customerSegmentsMap}
                productsInSegments={productsInSegments}
              />
            </Stack>
          </Grid>
          <Grid item lg={8} md={7} xs={12}>
            <SingleCategoryItems
              options={getOptions(data)}
            />
          </Grid>
        </Grid>
      </Stack>
    </div>
  );
};

export default SingleCategoryView;
