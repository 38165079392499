export const MESSAGES = {
  custimy: 'Custimy',
  ok: 'OK',
  name: 'Name',
  cancel: 'Cancel',
  all: 'All',
  yes: 'Yes',
  no: 'No',
  to: 'To',
  or: 'Or',
  and: 'And',
  logs: 'Logs',
  from: 'From',
  weak: 'Weak',
  good: 'Good',
  your: 'Your',
  done: 'Done!',
  cvc: 'CVC',
  sku: 'SKU',
  tip: 'Tip: ',
  age: 'Age:',
  export: 'Export',
  duplicate: 'Duplicate',
  close: 'Close',
  send: 'Send',
  back: 'Back',
  email: 'Email',
  bundle: 'Bundle',
  filters: 'Filters',
  licenses: 'Licenses',
  billing: 'Billing',
  license: 'License',
  package: 'Package',
  submit: 'Submit',
  welcome: 'Welcome',
  subscribe: 'Subscribe',
  loyalty: 'Loyalty',
  title: 'Title',
  sales: 'Sales',
  total_clicks: 'Total Clicks',
  total_impressions: 'Total Impressions',
  organic_traffic: 'Organic traffic',
  paid_traffic: 'Paid traffic',
  code: 'Code',
  total: 'Total',
  last: 'Last',
  orders: 'Orders',
  items: 'Items',
  order: 'Order',
  dismiss: 'Dismiss',
  heads_up: 'Heads up!',
  strong: 'Strong',
  save: 'Save',
  discard: 'Discard',
  delete: 'Delete',
  text: 'Text',
  status: 'Status',
  results: 'Results',
  oops: 'Oops!',
  congrats: 'Congrats!',
  hurray: 'Hurray!',
  update: 'Update',
  connect: 'Connect',
  current: 'Current',
  pending: 'Pending...',
  active: 'Active',
  inactive: 'Inactive',
  deleted: 'Deleted',
  create: 'Create',
  updated: 'Updated',
  created: 'Created',
  edit: 'Edit',
  remove: 'Remove',
  mid: 'Mid',
  high: 'High',
  monthly: 'Monthly',
  yearly: 'Yearly',
  discount: '10% off',
  entry: 'Entry',
  email_example: 'email@example.com',
  password_example: '1234123',
  euro_symbol: '€',
  percent_symbol: '%',
  zero_two_million_euros: '0-2€ Million',
  two_to_seven_million_euros: '2-7€ Million',
  seven_to_fourteen_million_euros: '7-14€ Million',
  month: 'Month',
  per_month: '/ Month',
  per_year: '/ Year',
  day: 'Day',
  per_30_days: '/ 30 days',
  thirty_days_performance: '30 days performance',
  alltime_performance: 'All-time performance',
  previous_30_days: 'Previous 30 Days',
  per_all_time: '/ All-time',
  from_previous_30_days: 'from previous 30 days',
  continue: 'Continue',
  activate: 'Activate',
  reactivate: 'Reactivate',
  password: 'Password',
  history: 'History',
  home: 'Home',
  customers: 'Customers',
  customer: 'Customer',
  funnel: 'Funnel',
  products: 'Products',
  product: 'Product',
  segments: 'Segments',
  cohorts: 'Cohorts',
  experiment: 'Experiment',
  experiments: 'Experiments',
  settings: 'Settings',
  activation: 'Activation',
  integrations: 'Integrations',
  primary: 'Primary',
  user: 'User',
  users: 'Users',
  creators: 'Creators',
  bundles: 'Bundles',
  variable: 'Variable',
  operator: 'Operator',
  value: 'Value',
  download: 'Download',
  preferences: 'Preferences',
  sessions: 'Sessions',
  total_sessions: 'Total sessions',
  successful: 'Successful',
  cost: 'Cost',
  total_cost: 'Total Cost',
  roas: 'ROAS',
  aov: 'AOV',
  cpa: 'CPA',
  cpc: 'CPC',
  cpm: 'CPM',
  ctr: 'CTR',
  discounts: 'Discounts',
  id: 'ID',
  items_sold: 'Items Sold',
  price: 'Price',
  product_name: 'Product Name',
  profit: 'Profit',
  total_refunded: 'Total Refunded',
  refund_rate: 'Refund Rate',
  return_rate: 'Return Rate',
  revenue: 'Revenue',
  segment: 'Segment',
  copied: 'Copied',
  policies: 'Policies',
  days_ago: 'Days ago',
  reset: 'Reset',
  delete_source: 'Delete source',
  company_size: 'Company size',
  e_commerce_cms_platform: 'eCommerce/CMS platform.',
  by_clicking: 'By clicking ‘',
  you_agree_to_the: '’ you agree to the',
  custimy_terms: 'Custimy Terms',
  privacy_policy: 'Privacy Policy.',
  link_issue: 'Link issue',
  billing_details: 'Billing details',
  invite_user: 'Invite user',
  subTotal_sales: 'Subtotal sales',
  product_insights: 'Product insights',
  product_details: 'Product Details',
  avg_price: 'Avg. sales price',
  product_categories: 'Product Categories',
  categories: 'Categories',
  category: 'Category',
  customer_segments: 'Customer segments',
  segments_rate: 'Segments Rate',
  customer_segments_order_rate: 'Customer Segments Order Rate',
  cost_price_range: 'Cost price range',
  price_range: 'Price range',
  profit_margin_range: 'Profit margin range',
  sign_up: 'Sign up',
  sign_in: 'Sign in',
  send_invite: 'Send invite',
  contact_us: 'Contact us',
  user_name: 'User name',
  super_users: 'Super users',
  first_name: 'First name',
  last_name: 'Last name',
  work_role: 'Work role',
  company_name: 'Company name',
  business_category: 'Business category',
  company_url: 'Company URL',
  work_email: 'Work email',
  delete_user: 'Delete user',
  old_password: 'Old password',
  new_password: 'New password',
  confirm_password: 'Confirm password',
  confirm_new_password: 'Confirm new password',
  update_password: 'Update password',
  reset_password: 'Reset password',
  max_licenses: 'Max licenses',
  delete_license: 'Delete license',
  license_name: 'License name',
  create_license: 'Create license',
  enter_your_details: 'Enter your details',
  order_value_avg: 'Order Value (Avg.)',
  order_items_avg: 'Order Items (Avg.)',
  orders_avg: 'Orders (Avg.)',
  profit_avg: 'Profit (Avg.)',
  total_orders: 'Total Orders',
  total_order_items: 'Total Order Items',
  total_sales: 'Total Sales',
  total_profit: 'Total Profit',
  total_discounts: 'Total Discounts',
  repurchase_rate: 'Repurchase Rate',
  first_order_rate: 'First-Order Rate',
  bought_uniquely_rate: 'Bought Uniquely Rate',
  variants: 'Variants',
  total_spent: 'Total Spent',
  propensity_to_buy: 'Propensity to Buy',
  profit_margin: 'Profit Margin',
  conversion_rate: 'Conversion Rate',
  order_return_rate: 'Order Return Rate',
  cart_abandonment_rate: 'Cart Abandonment Rate',
  applied_discounts: 'Applied Discounts',
  applied_discount_rate: 'Applied Discount Rate',
  returning_customers: 'Returning Customers',
  returning_customer: 'Returning Customer',
  churned_customers: 'Churned Customers',
  advertising_costs: 'Advertising Costs',
  return_on_ad_spend: 'Return on Ad Spend',
  churn_rate: 'Churn Rate',
  probability_of_churn: 'Probability of Churn',
  sales_per_month: 'Sales / Month',
  email_bounce_rate: 'Email Bounce Rate',
  avg_total_sales: 'Customer Revenue (Avg.)',
  clv: 'Customer Lifetime Value (Avg.)',
  avg_count_orders: 'Customer Orders (Avg.)',
  returned_customers: 'Returning Customers',
  churn_customers: 'Churned Customers',
  unique_visitors: 'Unique Sessions',
  abandonment_rate: 'Cart Abandonment Rate',
  package_anytime: 'package anytime',
  most_popular_customer_source: 'Most Popular Customer Source',
  customer_averages: 'Customer Averages',
  unique_sessions: 'Unique Sessions',
  new_customers: 'New Customers',
  customer_list: 'Customer list',
  category_list: 'Category list',
  category_details: 'Category details',
  customer_profile: 'Customer profile',
  customer_revenue_avg: 'Customer Revenue (Avg.)',
  customer_lifetime_value_avg: 'Customer LV (Avg.)',
  predicted_yearly_value: 'Predicted Yearly Value',
  customer_orders_avg: 'Customer Orders (Avg.)',
  traffic_conversions: 'Traffic Conversions',
  recommended_product_type: 'Recommended Product Type',
  well_done: 'Well done!',
  expiration_date: 'Expiration date',
  not_available: 'Not available',
  add_filter: 'Add filter',
  coming_soon: 'Coming soon',
  customer_consent: 'Customer consent',
  resend_invitation: 'Resend invitation',
  update_authorization: 'Update authorization',
  resend_link: 'Resend link',
  expired_link: 'Expired link',
  card_number: 'Card number',
  coupon_code: 'Coupon code',
  total_customers: 'Total customers',
  total_departed: 'Total departed',
  user_profile: 'User profile',
  remember_me: 'Remember me',
  switch_license: 'Switch license',
  admin_panel: 'Admin Panel',
  recent_activity: 'Recent activity',
  search_options: 'Search options',
  no_results: 'No results',
  clear_filter: 'Clear filter',
  last_month: 'Last month',
  log_out: 'Log out',
  help_guide: 'Need help',
  my_profile: 'My profile',
  my_password: 'My password',
  personal_information: 'Personal information',
  billing_address: 'Billing address',
  shipping_address: 'Shipping address',
  registration_date: 'Registration date:',
  preferred_communications: 'Preferred communications:',
  report_date: 'Report date',
  to_proceed: ' to proceed',
  your_data: 'Your data',
  add_license: 'Add license',
  the_license: 'The license',
  all_analytics: 'All analytics',
  category_name: 'Category name',
  product_segments: 'Product Segments',
  product_list: 'Product list',
  accept_invitation: 'Accept invitation',
  the_user: 'The user',
  new_audience: 'New audience',
  new_cohort: 'New cohort',
  new_experiment: 'New experiment',
  search_by_name: 'Search by name',
  search_by_name_description: 'Search by name, description',
  the_password_for: 'The password for',
  remove_this_user: 'Remove this user?',
  table_view: 'Table view',
  view_all: 'View all',
  forgot_your: 'Forgot your',
  password_q: 'password?',
  good_choice: 'A good choice',
  click_to_copy: 'Click to copy',
  bundle_has_been: 'Bundle has been',
  back_to_login: 'Back to login',
  redeem_coupon_code: 'Redeem coupon code',
  go_to_integrations: 'Go to Integrations',
  john_nicholas_doe: 'John Nicholas Doe',
  card_holders_name: "Card holder's name",
  add_photo_profile: 'Add photo profile',
  terms_of_business: 'Terms of Business',
  it_will_be_soon: "It's will be soon",
  image_formats: 'PNG, JPEG, JPG',
  image_max_size: '5MB Max.',
  remove_photo: 'Remove photo',
  restore_password: 'Restore password',
  segment_customers: 'Segment customers',
  repeat_purchasing_rate: 'Repeat purchasing rate',
  forgot_your_password_q: 'Forgot your password?',
  click_to_open_chart_view: 'Open chart view',
  search_for_products: 'Search by name, SKU or ID',
  most_sold_together: 'Most sold together',
  sold_together_rate: 'Sold together rate',
  no_problem_we_send_you_a_link: "No problem, we'll send you a link to reset your password",
  we_sent_you_an_instruction_email: "We've sent you an instruction email",
  email_will_be_in_your_inbox: 'The email will be in your inbox within a few minutes.',
  account_with_custimy: 'Account with Custimy',
  set_payment_plan_to: 'Set payment plan to',
  payment_plan: 'Payment plan',
  all_historical_data: 'All historical data',
  all_integration_settings: 'All integration settings',
  no_customers_in_segment: 'No customers in segment',
  days_since_last_session: 'Days since last session',
  days_since_last_email_opened: 'Days since last email opened',
  days_since_last_contacted: 'Days since last contacted',
  days_since_last_order: 'Days since last order',
  days_since_first_order: 'Days since first order',
  days_between_orders_avg: 'Days between orders (avg.)',
  recently_purchased_products: 'Recently purchased products',
  reactivate_this_user: 'Reactivate this User?',
  filter_customers_by: 'Filter customers by',
  process_can_take: 'This process can take',
  up_to_24_hours: 'up to 24 hours.',
  user_removal_warning: 'User removal warning',
  click_to_open_guide: 'Click to open info',
  click_to_open: 'Click to open',
  has_been_deleted: 'has been deleted',
  has_been_updated: 'has been updated',
  welcome_to_custimy: 'Welcome to Custimy!',
  integrate_to_unlock: 'Integrate to unlock',
  restore_your_password: 'Restore your password',
  create_new_password: 'Create new password',
  save_password: 'Save password',
  custom_audiences: 'Custom audiences',
  show_customers: 'Show customers',
  total_returns: 'Total Returns',
  items_returned: 'Items Returned',
  add_channels: 'Add channels',
  apply: 'Apply',
  channels: 'Channels',
  active_integrations: 'Active integrations',
  create_custom_audience: 'Create custom audience',
  edit_custom_audience: 'Edit custom audience',
  create_cohort: 'Create cohort',
  edit_cohort: 'Edit cohort',
  save_experiment: 'Save experiment',
  experiment_groups: 'Experiment groups',
  add_group: 'Add group',
  filter_customers: 'Filter customers',
  recommended_audience: 'Recommended audience',
  recommended_audience_description: `A custom audience is created with the customers who are <b>most likely to buy</b> the selected products.
    The smaller an audience size is, the <b>higher accuracy</b> becomes.`,
  recommended_products: 'Recommended products',
  rating: 'Rating',
  learn_more: 'Learn more',
  lower_accuracy: 'Reduced accuracy',
  good_accuracy: 'Good accuracy',
  high_accuracy: 'High accuracy',
  audience_size: 'Audience size',
  size: 'Size',
  group: 'Group',
  groups: 'Groups',
  or_more_characters: '8 or more characters',
  price_min: 'Min. sales price',
  price_max: 'Max. sales price',
  discount_rate: 'Discount Rate',
  cost_min: 'Min. cost price',
  cost_max: 'Max. cost price',
  profit_margin_min: 'Min. profit margin',
  profit_margin_max: 'Max. profit margin',
  unique_products: 'Products in category',
  mixture_of_letters_and_numbers: 'Mixture of letters and numbers',
  mixture_of_upper_and_lowercase: 'Mixture of upper and lowercase',
  special_characters: 'Special characters',
  category_insights: 'Category insights',
  integrate_with_channels: 'Integrate with channels',
  no_channels_selected_yet: 'No channels selected yet',
  create_your_first_audience: 'Create your first audience',
  create_your_first_cohort: 'Create your first cohort',
  create_your_first_experiment: 'Create your first experiment',
  all_rights_reserved: '© 2020 Custimy · All rights reserved',
  data_is_still_pulled: 'Historical data is still being pulled. However you can already see some recent analytics.',
  give_activation_descriptive_name: 'Give the activation a descriptive name',
  please_accept_the_invitation_to_access: 'Please accept the invitation to access',
  please_fill_in_your_information: 'Please fill in your information',
  please_fill_in_your_company_information: 'Please fill in your company information',
  create_activation_placeholder: 'Custom audience ...',
  choose_a_name: 'Choose a name',
  description: 'Description',
  cohort_description_placeholder: 'Add a description to this cohort',
  group_customers_by: 'Group customers by',
  metric: 'Metric',
  cumulative: 'Cumulative',
  include_predictions: 'Include predictions',
  average: 'Average',
  averaged: 'Averaged',
  averaged_by: 'Averaged by',
  for_each: 'For each',
  exclude_first: 'Exclude first',
  delete_confirmation: 'Are you sure you want to delete this audience?',
  select_maximum_columns: 'Select a maximum of 8 columns',
  category_insights_subtext: 'Identify segments or individual product categories with significant performances using Custimy powered metrics.',
  product_insights_subtext: 'Identify segments or individual products with significant performances using Custimy powered metrics.',
  please_fill_in_your_information_to_create_a_new_license: 'Please fill in your information to create a new license',
  only_one_primary_source_can_be_connected: 'Only 1 primary source can be connected',
  please_provide_the_email_of_the_new_user: 'Please provide the email of the new user:',
  the_limit_of_users_has_been_reached: 'The limit of 10 users has been reached',
  you_ve_been_invited_to_join_a_license: "You've been invited to join a license",
  please_create_a_password_to_accept_the_invitation: 'Please create a password to accept the invitation',
  cancel_confirmation: 'Are you sure you want to discard the changes made to this custom audience?',
  your_invitation_has_been_successfully_sent: 'Your invitation has been successfully sent.',
  activate_your_custom_data: 'Activate your customer data by creating custom audiences that automatically integrates with your channels.',
  no_data_available: 'No data available',
  no_data_available_for_month: 'No data available for month',
  no_earlier_data_available: 'No earlier data available',
  no_cost_data_available: 'No cost data available',
  total_profit_margin: 'Total profit margin',
  no_products_in_segment: 'No products in segment',
  search_by_name_email: 'Search by name, e-mail',
  search_by_name_id: 'Search by name, id',
  search_by_title_code: 'Search by title, code',
  do_you_need_help: 'Do you need help?',
  sign_up_with_google: 'Sign up with Google',
  sign_in_with_google: 'Sign in with Google',
  sign_up_with_outlook: 'Sign up with Outlook',
  sign_in_with_outlook: 'Sign in with Outlook',
  dont_have_an_account: "Don't have an account?",
  already_have_an_account: 'Already have an account?',
  digits_on_the_back_of_the_card: 'The last 3 digits on the back of the card',
  action_can_not_be_undone: 'This action can not be undone',
  enter_full_name_of_user: "Please enter the full name of the user '",
  your_data_is_being_pulled: 'Your data is currently being pulled',
  all_related_customer_data: 'All related customer data',
  you_will_be_redirected: 'You will be redirected to',
  data_will_be_available: 'Data will be available soon.',
  license_removal_warning: 'License removal warning',
  you_dont_belong_to_any_licenses: "You don't belong to any licenses.",
  lets_start_rocking: "Let's start rocking",
  start_integration_header: "Let's start rocking your data",
  start_integration_subtext: `To start using Custimy you must first connect your primary eCommerce/CMS platform. Your data will then be pulled into the Custimy Database and transformed into valuable insights.

Keep integrating more sources to enrich your data and gain more precise results and start unlocking features.`,
  no_information_to_display: 'No information to display',
  your_data_is_currently_being_pulled: 'Your data is currently being pulled',
  invitation_has_been_sent: 'The invitation has been sent',
  sign_up_to_accept_invitation: 'Sign up to accept the invitation from',
  return_soon_to_see_dashboard: 'Return soon to see your dashboards',
  provide_email_of_new_super_user: 'Please provide the email of the new super user',
  leave_the_page: 'Are you sure you want to leave this page?',
  remove_license_bundle_code: 'Remove the bundle code of this license?',
  shows_distribution_of_customers: 'Shows the distribution of customers across segments.',
  attachment_has_wrong_format: 'Your attachment has wrong format.',
  please_check_your_inbox: 'Please check your inbox',
  no_activity_has_been_tracked: 'No activity has been tracked yet.',
  please_enter_the_full_name_of_your_license: 'Please enter the full name of your license',
  changes_will_be_lost_do_you_want_to_cancel: 'The changes will be lost.Do you really want to cancel?',
  license_deletion_is_permanent_you_will_remove: 'License deletion is permanent and cannot be undone, you will remove:',
  enter_name_of_license_company: "Please enter the name of the license company '",
  about_to_change_password_of_user: 'You are about to change the password of the user:',
  enter_your_email_to_restore_password: 'Enter your email to restore your password',
  your_request_has_been_successfully_processed: 'Your request has been successfully processed',
  contact_administrator_or_create_new_license: 'Contact your license administrator or create a new license',
  plan_will_automatically_be_determined: "OBS: Your plan will automatically be determined by your business' yearly revenue",
  attachment_is_too_big: 'Your attachment is too big to send. Try sending a file smaller than 5MB.',
  confirmation_cancel_content: 'The changes will be lost. Do you really want to cancel?',
  welcome_to_custimy_subtitle: 'Every day, all your business and customer data is compiled and analyzed to deliver new and more precise insights.',
  product_segments_subtext: 'Identify products with significant performances, and filter across product categories using the search field',
  connect_your_primary_CMS_pull_data: 'To start using Custimy you must first connect your primary eCommerce/CMS platform. Your data will then be pulled into the Custimy Database and transformed into valuable insights.',
  keep_integrating_more_sources: 'Keep integrating more sources to enrich your data and gain more precise results and start unlocking features.',
  all_data_will_be_lost: 'All data will be lost and this action cannot be undone',
  add_some_secondary_sources: 'Now, add some secondary sources to start working with them',
  source_will_be_deactivated: 'This source will be deactivated and new data will not be pulled anymore.',
  source_will_be_reactivated: 'This source will be reactivated and new data will be pulled.',
  to_start_using_custimy: 'To start using Custimy you must first connect your primary',
  check_your_integrations: 'Check your integrations or wait for new data to come in.',
  no_activity_tracked_yet_for_customer: 'No activity has been tracked yet for this Customer.',
  license_deletion_is_permanent: 'License deletion is permanent and can only be undone by a technical administrator',
  user_deletion_is_permanent: 'User deletion is permanent and can only be undone by a technical administrator',
  acquisition: 'Acquisition',
  you_are_about_to_connect: 'You are about to connect',
  you_are_about_to_update_connection: 'You are about to update the connection to',
  manage_license: 'Manage license access',
  manage_license_sub_header: 'Control the license permission access on a user level.',
  search_by_license_name: 'Search by license name',
  date: 'Date',
  date_granted: 'Date granted',
  action: 'Action',
  manage_subscription: 'Manage subscription',
  entry_text: 'Total revenue 300k-2€ Million p.a',
  mid_text: 'Total revenue 2-7€ Million p.a',
  high_text: 'Total revenue 7-15€ Million p.a',
  subscription: 'Subscription',
  update_your_billing_company_info: 'Update your billing and company information',
  company_info: 'Company information',
  no_usage_text: 'No usage recorded.',
  integrate_billing_text: 'Integrate to calculate pricing tier',
  update_billing_info: 'Update the company billing information',
  next_invoice: 'Next invoice:',
  no_info: 'No info',
  show_deleted: 'Show deleted',
  new_license: 'New License',
  create_bundle: 'Create Bundle',
  invite_super_user: 'Invite Super User',
  change_view: 'Change view',
  performance: 'Performance',
  details: 'Details',
  ecommerce_cms: 'eCommerce/CMS',
  email_marketing: 'Email Marketing',
  customer_service: 'Customer Service',
  ad_providers: 'Ad providers',
  social_networks: 'Social Networks',
  analytics: 'Analytics',
  invalid_credentials_help_text: 'The credentials you have provided are not valid and need to be updated. Click here for a quick how-to guide.',
  facebook: 'Facebook',
  google_ads: 'Google Ads',
  instagram: 'Instagram',
  blended: 'Blended',
  activation_type: 'Activation type',
  type: 'Type',
  opened_emails: 'Opened emails',
  received_emails: 'Received emails',
  country: 'Country',
  city: 'City',
  tags: 'Tags',
  events: 'Events',
  failed: 'Failed',
  synchronized: 'Synchronized',
  creator: 'Creator',
  true: 'True',
  false: 'False',
  yearly_performance: 'Yearly performance',
  month_on_month: 'MoM',
  date_first_order: 'First Order Date',
  date_second_order: 'Second Order Date',
  table: 'Table',
  graph: 'Graph',
  apply_cohort_to_see_results: 'Apply cohort to view graphs',
  cohort_delete_confirmation: 'Are you sure you want to delete this cohort?',
  cohorts_description: 'Explore the performance of your customers and identify trends in the customer lifetime journey.',
  none: 'None',
  send_to_channels: 'Send to channels',
  group_name: 'Group name',
  split_size: 'Split size',
  allocated: 'Allocated',
  something_went_wrong_experiments: 'Something went wrong. Make sure your experiment is created properly.',
  experiment_with_name_exists: 'Experiment with name already exist. Choose a unique name.',
  failed_to_updated: 'Failed to update. Please try again',
  failed_to_start_or_stop_experiment: 'Something went wrong trying to start or stop the experiment. Please try again.',
  failed_to_fetch_experiment_results: 'Something went wrong trying to get experiment results. Please try again.',
  experiment_delete_confirmation: 'Are you sure you want to delete this experiment?',
  experiment_complete_confirmation: 'Are you sure you want to complete this experiment?',
  start_experiment: 'Start experiment',
  complete_experiment: 'Complete experiment',
  started: 'Started',
  completed: 'Completed',
  upload_in_progress: 'Upload in progress',
  failed_to_upload_experiment_group: 'Failed to upload experiment group to channel',
  successfully_uploaded: 'Successfully uploaded',
  experiment_name_placeholder: 'Choose a name for your experiment',
  experiment_description_placeholder: 'Add a description to this experiment',
  experiment_group_name_placeholder: 'Experiment group name',
  experiments_description: 'Put customer strategies to the test and explore performance on a day-to-day basis.',
  experiment_description_draft: `Set up strategies in your external systems by using the synchronized experiment groups or by exporting them.
Start the experiment when you are ready to go live.`,
  experiment_description_active: `The experiment is now live and will measure customers in experiment groups daily. Use the graph to observe the performance of each group as the experiment progresses.
Complete the experiment when a winning strategy has been identified.`,
  experiment_description_complete: 'The experiment is now concluded and will no longer measure performance. Explore the results by using the graph to identify winners, losers and other insights.',
  create_experiment_description: 'Create a new experiment by choosing a selection of customers to set up experiment groups on.',
  show: 'Show',
  by: 'By',
  experiment_no_data: 'No performance data has been tracked yet. Wait at least a day to see results.',
  in_progress: 'In progress',
  failed_to_export_group: 'Experiment group export failed. Please try again.',
  failed_to_load_cohort: 'Something went wrong loading cohort data. Please try again.',
  experiment_group_allocation_error: 'Make sure to allocate all 100% of customers to groups.',
  months: 'Months',
  months_ahead: 'Months ahead',
  max: 'Max',
  not_started: 'Not started',
  not_completed: 'Not completed',
  lock: 'Lock',
  unlock: 'Unlock',
  prepare_experiment: 'Prepare experiment',
  observe_experiment: 'Observe experiment',
  evaluate_experiment: 'Evaluate experiment',
  failed_to_login: 'Something went wrong trying to login. Please try again.',
  verification_code: 'Verification code',
  email_verification: 'Email verification',
  failed_email_verification: 'Failed email verification',
  email_resend: 'Email resend',
  we_have_sent_an_email_to: 'We have sent an email to ',
  email_verification_sent_info: 'The email contains a 6 digit code. Enter the code below to complete your sign-up.',
  email_verification_new_sent: 'An email with a new verification code has been sent.',
  resend_verification_email: 'Resend verification email',
  refresh_token: 'Refresh Token',
  refresh_token_disclaimer: 'Copy and save this Refresh Token. It will be used for Custimy API authorization',
  refresh_token_update_disclaimer: 'Warning! Updating Refresh Token will make the old one invalid. Be cautious when doing that.',
  you_are_ready_datalake_api: 'You are ready to connect to Custimy API. Be sure to read',
  dot_it_contains_guide: '. It contains a detailed guide on how to use API',
};
