import React from 'react';
import propTypes from 'prop-types';
import { MESSAGES } from 'constants/messages';
import { LinkButton } from 'components/common/LinkButton';

const DeleteLicenseButton = ({ disable, ...props }) => (
  <LinkButton
    type="danger"
    onClick={props}
  >
    {MESSAGES.delete_license}
  </LinkButton>
);

DeleteLicenseButton.propTypes = {
  disable: propTypes.bool,
};

DeleteLicenseButton.defaultProps = {
  disable: false,
};

export default DeleteLicenseButton;
