import React, { useState } from 'react';
import useListApi from 'hooks/useListApi';
import {
  BUNDLES_PER_PAGE,
  BUTTON_TYPE,
} from 'constants/common';
import { DefaultCell, IdCell } from 'components/common/Table/TableCells';
import { getBundlesList } from 'api/bundleAPI';
import {
  EditBundleCell,
  ExpirationDateCell,
  IsActiveCell,
} from 'components/views/Admin/Bundles/BundleCells';
import BundleModal from 'components/views/Admin/Bundles/BundleModal';
import { MESSAGES } from 'constants/messages';
import { Box, Grid } from '@mui/material';
import Search from 'components/common/Search';
import Button from 'components/common/Button';
import PaginatedTable from 'components/common/Table/PaginatedTable';
import Block from 'components/common/Block';
import { LIST_API_REDUX_KEYS } from 'constants/listApi';

const getTableConfig = (onEditClick) => [{
  title: 'Title',
  key: 'title',
  ordering: ['title'],
  CellComponent: DefaultCell,
  componentProps: {},
  width: '200px',
  hasOverflowEllipsis: true,
}, {
  title: 'Code',
  key: 'code',
  ordering: ['code'],
  CellComponent: IdCell,
  componentProps: {},
  width: '150px',
  hasOverflowEllipsis: true,
}, {
  title: 'Expiration Date',
  key: 'expiration_date',
  ordering: ['expiration_date'],
  CellComponent: ExpirationDateCell,
  componentProps: {},
  width: '15%',
  hasOverflowEllipsis: false,
}, {
  title: 'Active',
  key: 'is_active',
  ordering: ['is_active'],
  CellComponent: IsActiveCell,
  componentProps: {},
  width: '80px',
  hasOverflowEllipsis: false,
}, {
  title: 'Max Licenses',
  key: 'max_licenses_num',
  ordering: ['max_licenses_num'],
  CellComponent: DefaultCell,
  componentProps: {},
  width: '15%',
  hasOverflowEllipsis: true,
}, {
  title: 'Licenses Count',
  key: 'licenses_count',
  ordering: null,
  CellComponent: DefaultCell,
  componentProps: {},
  width: '100px',
  hasOverflowEllipsis: true,
}, {
  title: null,
  key: null,
  ordering: null,
  CellComponent: EditBundleCell,
  componentProps: { onClick: onEditClick },
  width: '60px',
  hasOverflowEllipsis: false,
}];

const Bundles = () => {
  const [bundleForEdit, setBundleForEdit] = useState(null);
  const {
    data,
    totalCount,
    isDataLoading,
    config,
    changeConfig,
  } = useListApi(LIST_API_REDUX_KEYS.adminPanelBundles, { ordering: ['title'] }, getBundlesList);

  const handleSortChange = (ordering) => changeConfig({ ordering });
  const handlePageChange = (page) => changeConfig({ page });
  const handleSearchChange = (search) => changeConfig({ search });

  const onCreateClick = () => setBundleForEdit({ is_active: true });

  const onEditClick = (x) => {
    const newBundleForEdit = { ...x.row };
    if (newBundleForEdit.expiration_date) {
      newBundleForEdit.expiration_date = new Date(newBundleForEdit.expiration_date);
    }
    setBundleForEdit(newBundleForEdit);
  };

  const tableConfig = getTableConfig(onEditClick);

  return (
    <>
      <Block header={MESSAGES.bundles}>
        <Grid container rowSpacing={2}>
          <Grid item container spacing={2} xs={12}>
            <Grid item lg={4} md={6} xs={12}>
              <Search
                search={config.search}
                title={MESSAGES.search_by_title_code}
                searchChanged={handleSearchChange}
              />
            </Grid>
            <Grid item lg={8} md={6} xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  type={BUTTON_TYPE.primary}
                  onClick={onCreateClick}
                >
                  {MESSAGES.create_bundle}
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <PaginatedTable
              sort={config.ordering}
              sortChanged={handleSortChange}
              totalCount={totalCount}
              tableConfig={tableConfig}
              data={data}
              highlightStartIdx={0}
              highlightEndIdx={6}
              countPerPage={BUNDLES_PER_PAGE}
              page={config.page}
              pageChanged={handlePageChange}
              isDataLoading={isDataLoading}
            />
          </Grid>
        </Grid>
      </Block>
      <BundleModal
        bundle={bundleForEdit}
        onSubmit={() => changeConfig({ page: 1 })}
        onClose={() => setBundleForEdit(null)}
      />
    </>
  );
};

Bundles.propTypes = {};

export default Bundles;
