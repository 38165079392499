import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import LicenseSettings from 'components/views/Settings/LicenseSettings';
import BillingDetails from 'components/views/Settings/BillingDetails';
import { useSelector } from 'react-redux';
import { getIsLicenseAdmin } from 'store/license/getters';

const Settings = () => {
  const isAdministrator = useSelector(getIsLicenseAdmin);

  return (
    <Switch>
      <Route path={ROUTES.licenseSettings} component={LicenseSettings} exact />
      {isAdministrator && (
        <Route path={ROUTES.billingDetails} component={BillingDetails} exact />
      )}
    </Switch>
  );
};

export default Settings;
