import React from 'react';
import classNames from 'classnames';
import propTypes from 'prop-types';

import './styles.scss';
import Input from 'components/common/Input';
import { ReactComponent as HelpIcon } from 'assets/svg/help.svg';
import { ReactComponent as LockIcon } from 'assets/svg/lock.svg';
import { defaultInputProps, inputPropTypes } from 'components/common/Input/inputPropTypes';

const PaymentFormInput = ({
  id,
  label,
  placeholder,
  maxLength,
  hasHelp,
  hasLock,
  isRequired,
  ...rest
}) => (
  <div className={classNames('payment-form__field', {
    'payment-form__field--required': isRequired,
  })}
  >
    <label className="payment-form__label" htmlFor={id}>
      {label}
    </label>
    <div className="payment-form__input">
      <Input
        id={id}
        label={placeholder}
        maxLength={maxLength}
        hasFloatLabel={false}
        {...rest}
      />
      {hasHelp && <HelpIcon className="payment-form__help" />}
      {hasLock && <LockIcon className="payment-form__lock" />}
    </div>
  </div>
);

PaymentFormInput.propTypes = {
  ...inputPropTypes,
  placeholder: propTypes.string.isRequired,
  hasHelp: propTypes.bool,
  hasLock: propTypes.bool,
};

PaymentFormInput.defaultProps = {
  ...defaultInputProps,
  hasHelp: false,
  hasLock: false,
  hasFloatLabel: false,
};

export default PaymentFormInput;
