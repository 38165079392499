import {
  AUDIENCE_CATEGORY_ERROR_REQUIRED,
  AUDIENCE_INTEGRATION_CHANNELS_ERROR_REQUIRED,
  AUDIENCE_NAME_ERROR_REQUIRED,
  AUDIENCE_PRODUCTS_ERROR_REQUIRED
} from 'constants/errors';
import Validator from 'utils/validator';
import { AUDIENCE_TYPES } from 'constants/customAudience';

export default ({ name, integrations, type, recommendations_source_ids }) => {
  const errors = {};

  const nameValidator = new Validator(name);
  const integrationsValidator = new Validator(integrations);
  const sourceIdsValidator = new Validator(recommendations_source_ids);

  try {
    nameValidator.required(AUDIENCE_NAME_ERROR_REQUIRED);
  } catch (error) {
    errors.name = error.message;
  }

  try {
    integrationsValidator.notEmpty(AUDIENCE_INTEGRATION_CHANNELS_ERROR_REQUIRED);
  } catch (error) {
    errors.integrations = error.message;
  }

  if (type === AUDIENCE_TYPES.product) {
    try {
      sourceIdsValidator.notEmpty(AUDIENCE_PRODUCTS_ERROR_REQUIRED);
    } catch (error) {
      errors.recommendations_source_ids = error.message;
    }
  }

  if (type === AUDIENCE_TYPES.category) {
    try {
      sourceIdsValidator.notEmpty(AUDIENCE_CATEGORY_ERROR_REQUIRED);
    } catch (error) {
      errors.recommendations_source_ids = error.message;
    }
  }

  return errors;
};
