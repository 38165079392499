import React, { useState } from 'react';
import classNames from 'classnames';
import propTypes from 'prop-types';
import Prompt from 'react-router-dom/Prompt';

import 'components/views/Settings/LicenseSettings/LicenseMembers/InviteUser/InviteUserConfirmationWindow/styles.scss';
import Popup from 'reactjs-popup';
import Button from 'components/common/Button';
import { BUTTON_TYPE, REQUEST_STATUS } from 'constants/common';
import Input from 'components/common/Input';
import useAsync from 'hooks/useAsync';
import useForm from 'hooks/useForm';
import validation from 'containers/Authorization/RestorePassword/ProvideEmail/provideEmailValidation';
import { ValidationError } from 'constants/errors';
import { MESSAGES } from 'constants/messages';

const InviteUserConfirmationWindow = ({
  customOpenButton,
  open,
  onCancel,
  onConfirm,
  forceBlocking,
}) => {
  const [isUserInvited, setIsUserInvited] = useState(false);
  const [isBlocking, setIsBlocking] = useState(false);

  const inviteUser = async ({ email }, setError) => {
    try {
      await onConfirm(email);
      setIsUserInvited(true);
    } catch ({ message }) {
      setError({
        email: new ValidationError(message).message,
      });
    }
  };

  const {
    execute: inviteUserAsync,
    status: inviteUserStatus,
  } = useAsync(inviteUser);
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    cleanUpForm,
    values,
    touched,
    errors,
  } = useForm(inviteUserAsync, validation);

  const cancelInviteWindow = (close) => {
    setIsBlocking(false);
    setIsUserInvited(false);
    cleanUpForm();
    onCancel(close);
  };

  return (
    <>
      <Prompt
        when={isBlocking}
        message={(location) => `Are you sure you want to go to ${location.pathname}`}
      />
      <Popup
        modal
        open={open}
        trigger={customOpenButton}
        position="center center"
        closeOnDocumentClick={false}
        onOpen={() => setIsBlocking(forceBlocking)}
      >
        {(close) => (
          <div className={classNames('confirmation-window invite-user-confirmation-window', {
            'invite-user-confirmation-window--invited': isUserInvited,
          })}
          >
            <div className="confirmation-window__content">
              {isUserInvited
                ? <span>{MESSAGES.your_invitation_has_been_successfully_sent}</span>
                : (
                  <>
                    {MESSAGES.please_provide_the_email_of_the_new_user}
                    <div className="invite-user-confirmation-window__email">
                      <Input
                        id="invited-user-email"
                        name="email"
                        label={MESSAGES.email_example}
                        type="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete="email"
                        value={values.email}
                        error={errors.email}
                        touched={touched.email}
                        isRequired
                        hasFeedback
                      />
                    </div>
                  </>
                )}
            </div>
            <div className="confirmation-window__actions invite-user-confirmation-window__actions">
              {
                isUserInvited ? (
                  <div className="confirmation-window__action-button confirmation-window__action-button--ok">
                    <Button
                      onClick={() => cancelInviteWindow(close)}
                      block
                      type={BUTTON_TYPE.primary}
                    >
                      {MESSAGES.ok}
                    </Button>
                  </div>
                ) : (
                  <>
                    <div className="confirmation-window__action-button confirmation-window__action-button--cancel">
                      <Button
                        onClick={() => cancelInviteWindow(close)}
                        block
                        type={BUTTON_TYPE.secondary}
                        isDisabled={inviteUserStatus === REQUEST_STATUS.loading}
                      >
                        {MESSAGES.cancel}
                      </Button>
                    </div>
                    <div className="
                    confirmation-window__action-button
                    confirmation-window__action-button--accept
                    invite-user-confirmation-window__confirm-button"
                    >
                      <Button
                        onClick={handleSubmit}
                        block
                        type={BUTTON_TYPE.primary}
                        isDisabled={inviteUserStatus === REQUEST_STATUS.loading}
                      >
                        {MESSAGES.send_invite}
                      </Button>
                    </div>
                  </>
                )
              }
            </div>
          </div>
        )}
      </Popup>
    </>
  );
};

InviteUserConfirmationWindow.propTypes = {
  customOpenButton: propTypes.func.isRequired,
  onCancel: propTypes.func.isRequired,
  onConfirm: propTypes.func.isRequired,
  forceBlocking: propTypes.bool,
  open: propTypes.bool,
};

InviteUserConfirmationWindow.defaultProps = {
  open: false,
  forceBlocking: true,
};

export default InviteUserConfirmationWindow;
