import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import 'components/views/Homepage/KpiHistoryDataBlock/SalesKpiItem/style.scss';
import { MESSAGES } from 'constants/messages';
import { GUIDES } from 'constants/guides';
import Metric from 'components/common/Metric/Metric';

const SALES_KPIS = [
  {
    key: 'total_orders',
    title: MESSAGES.total_orders,
    value: 'total_orders',
    currency: false,
    percentage: false,
    guide: GUIDES.H1,
  },
  {
    key: 'total_sales',
    title: MESSAGES.total_sales,
    value: 'total_sales',
    currency: true,
    percentage: false,
    guide: GUIDES.H2,
  },
  {
    key: 'total_profit',
    title: MESSAGES.total_profit,
    value: 'total_profit',
    currency: true,
    percentage: false,
    guide: GUIDES.H3,
    no_data_message: MESSAGES.no_cost_data_available,
  },
  {
    key: 'profit_margin',
    title: MESSAGES.profit_margin,
    value: 'profit_margin',
    currency: false,
    percentage: true,
    guide: GUIDES.H4,
    no_data_message: MESSAGES.no_cost_data_available,
  },
];

const SalesKpiItem = ({
  data, graphData, onClickChartHandler, isMostRecent,
}) => (
  <Grid container spacing={2} className="sales-kpi-container">
    <Grid item xs={12}>
      <div className="sales-kpi-header">
        <div className="sales-kpi-category">
          {MESSAGES.sales}
        </div>
      </div>
    </Grid>
    {SALES_KPIS.map((item) => (
      <Grid item xl={3} lg={4} sm={6} xs={12} key={item.key}>
        <Metric
          option={{
            key: item.key,
            title: item.title,
            value: data?.[item.value],
            prefix: item.currency ? data?.currency : null,
            perc: item.percentage,
            guide: item.guide,
            noDataMessage: item.no_data_message,
          }}
          graphData={graphData}
          onClickChartHandler={onClickChartHandler}
          isMostRecent={isMostRecent}
        />
      </Grid>
    ))}
  </Grid>
);

SalesKpiItem.propTypes = {
  data: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.any,
    ]),
  ),
  graphData: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.any,
    ]),
  ),
  onClickChartHandler: PropTypes.func,
  isMostRecent: PropTypes.bool,
};

SalesKpiItem.defaultProps = {
  data: undefined,
  graphData: undefined,
  onClickChartHandler: undefined,
  isMostRecent: false,
};

export default SalesKpiItem;
