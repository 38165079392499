import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import 'components/views/Customer/Map/style.scss';
import { ReactComponent as Marker } from 'assets/svg/marker.svg';
import GoogleMapReact from 'google-map-react';
import cn from 'classnames';
import { styles } from 'components/views/Customer/Map/stylesMap';
import Geocode from 'react-geocode';

export const Map = ({ data, className }) => {
  const [center, setCenter] = useState({});

  useEffect(() => {
    if (!!data.shipping_latitude && !!data.shipping_longitude
        && !!data.shipping_latitude.length && !!data.shipping_longitude.length) {
      setCenter({
        lat: Number(data.shipping_latitude),
        lng: Number(data.shipping_longitude),
      });
    } else if (data.shipping_address) {
      const address = `${data.shipping_address} ${data.shipping_city}${data.country || ''}`;
      Geocode.setApiKey('AIzaSyDLp_TZYDIxnKwOftVhPUORY_JDYZMwEAo');
      Geocode.fromAddress(address).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          setCenter({
            lat: Number(lat),
            lng: Number(lng),
          });
        },
      );
    }
  }, [data]);
  const zoom = 6;

  return (
    <div className={cn('container-map', className)}>
      <div className="container-map_address-container">
        <div className="container-map_address-container_text" />
      </div>
      {!!center.lat && !!center.lng && (
        <div
          className="container-map_map"
          style={{ height: '121px', width: '100%' }}
        >
          <GoogleMapReact
            bootstrapURLKeys={{
              key: 'AIzaSyDLp_TZYDIxnKwOftVhPUORY_JDYZMwEAo',
            }}
            yesIWantToUseGoogleMapApiInternals
            center={center}
            defaultCenter={center}
            zoom={zoom}
            options={{
              disableDefaultUI: false,
              draggable: false,
              keyboardShortcuts: true,
              scaleControl: false,
              scrollwheel: false,
              styles,
            }}
          >
            <Marker
              lat={center.lat}
              lng={center.lng}
            />
          </GoogleMapReact>
        </div>
      )}
    </div>
  );
};

Map.propTypes = {
  data: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.bool,
  ])).isRequired,
  className: PropTypes.string,
};

Map.defaultProps = {
  className: undefined,
};
