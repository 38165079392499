import {
  EMAIL_MIN_LENGTH,
  EMAIL_MAX_LENGTH,
  EMAIL_PATTERN,
  PASSWORD_PATTERN,
} from 'constants/validation';
import {
  ValidationError,
  EMAIL_ERROR_REQUIRED,
  EMAIL_ERROR_PATTERN,
  PASSWORD_ERROR_REQUIRED,
  PASSWORD_ERROR_PATTERN,
  CONFIRM_PASSWORD_ERROR_REQUIRED,
  CONFIRM_PASSWORD_ERROR_NOT_MATCH,
  EMAIL_ERROR_MIN_LENGTH,
  EMAIL_ERROR_MAX_LENGTH,
} from 'constants/errors';
import Validator from 'utils/validator';

export default ({
  email,
  password,
  confirmPassword,
}) => {
  const errors = {};

  const emailValidator = new Validator(email);
  const passwordValidator = new Validator(password);
  const confirmPasswordValidator = new Validator(confirmPassword);

  try {
    emailValidator
      .required(EMAIL_ERROR_REQUIRED)
      .minLength(EMAIL_MIN_LENGTH, EMAIL_ERROR_MIN_LENGTH)
      .maxLength(EMAIL_MAX_LENGTH, EMAIL_ERROR_MAX_LENGTH)
      .match(EMAIL_PATTERN, EMAIL_ERROR_PATTERN);
  } catch (error) {
    errors.email = error.message;
  }

  try {
    passwordValidator
      .required(PASSWORD_ERROR_REQUIRED)
      .match(PASSWORD_PATTERN, PASSWORD_ERROR_PATTERN);
  } catch (error) {
    errors.password = error.message;
  }

  try {
    confirmPasswordValidator
      .required(CONFIRM_PASSWORD_ERROR_REQUIRED)
      .additional((validator) => {
        if (validator.field !== password) {
          throw new ValidationError(CONFIRM_PASSWORD_ERROR_NOT_MATCH);
        }

        return validator;
      });
  } catch (error) {
    errors.confirmPassword = error.message;
  }

  return errors;
};
