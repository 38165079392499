import React from 'react';
import { useSelector } from 'react-redux';
import ProtectedRoute from 'components/common/ProtectedRoutes';
import { ROUTES } from 'constants/routes';
import propTypes from 'prop-types';
import { getCurrentLicense, getIsLicenseAdmin } from 'store/license/getters';

const AdminRoute = ({ component: Component, ...rest }) => {
  const { is_demo } = useSelector(getCurrentLicense);
  const isAdmin = useSelector(getIsLicenseAdmin);

  return (
    <ProtectedRoute
      hasPermission={isAdmin || is_demo}
      component={Component}
      redirectToFunc={() => ({ pathname: ROUTES.home })}
      {...rest}
    />
  );
};

export default AdminRoute;

AdminRoute.propTypes = {
  component: propTypes.func.isRequired,
};
