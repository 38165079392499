import React, { useEffect, useState, useCallback } from 'react';
import Block from 'components/common/Block';
import { MESSAGES } from 'constants/messages';
import { Box, Popper } from '@mui/material';
import Loader from 'components/common/Loader';
import MetricList from 'components/common/MetricList/index';
import MetricListItem from 'components/common/MetricList/MetricListItem';
import { getProductCategory } from 'api/productsAPI';
import PropTypes from 'prop-types';
import './styles.scss';
import { debounce } from 'lodash';

export const CategoryPopperMetricsStateHandler = (hasDebounce = true) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [item, setItem] = useState(null);
  const [itemData, setItemData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const isOpen = !!anchorEl;

  const openHoverItem = (event, hoverItem, isHovering) => {
    if (!isHovering || !document.body.contains(event.target)) return;
    setItemData(null);
    setAnchorEl(event.target);
    setItem(hoverItem);
  };

  const debounceOpenHoverItem = useCallback(debounce(openHoverItem, 500), []);

  const handleOnHover = (event, hoverItem, isHovering) => {
    if (!isHovering) {
      setItem(null);
      setAnchorEl(null);
      setItemData(null);
    }
    if (hasDebounce) {
      debounceOpenHoverItem(event, hoverItem, isHovering);
      return;
    }
    openHoverItem(event, hoverItem, isHovering);
  };

  useEffect(() => {
    if (!item || (itemData && item.id === itemData.id)) return;

    const { id } = item;
    if (!id) return;

    setIsLoading(true);
    getProductCategory(id).then((categoryData) => {
      if (!categoryData) {
        setIsLoading(false);
        return;
      }
      setItemData(categoryData);
      setIsLoading(false);
    }).catch(() => {
      setIsLoading(false);
      setItemData(null);
    });
  }, [item]);

  return {
    handleOnHover,
    anchorEl,
    item,
    itemData,
    isLoading,
    isOpen,
  };
};

const CategoryPopperMetrics = ({
  isOpen,
  anchorEl,
  item,
  isLoading,
  itemData,
  hasClickableLabel,
}) => (
  <Popper
    open={isOpen}
    anchorEl={anchorEl}
    placement="right"
    className="category-popper-overlay"
  >
    <Block
      header={item && item.title}
      postHeader={MESSAGES.yearly_performance}
      className="category-metric-list"
    >
      {isLoading && (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Loader color="white" />
        </Box>
      )}
      {!isLoading && !itemData && (
        <div className="empty-state">
          {MESSAGES.no_data_available}
        </div>
      )}
      {!isLoading && itemData && (
        <>
          <MetricList>
            <MetricListItem
              title={MESSAGES.items_sold}
              value={itemData.items_sold}
              trendPercentage={itemData.items_sold_last_month_percentage}
            />
            <MetricListItem
              title={MESSAGES.revenue}
              value={itemData.revenue}
              trendPercentage={itemData.revenue_last_month_percentage}
              valuePrefix={itemData.currency}
            />
            <MetricListItem
              title={MESSAGES.profit}
              value={itemData.profit}
              trendPercentage={itemData.profit_last_month_percentage}
              valuePrefix={itemData.currency}
            />
            <MetricListItem
              title={MESSAGES.profit_margin}
              value={itemData.profit_margin}
              trendPercentage={itemData.profit_margin_last_month_percentage}
              valueSuffix="%"
            />
            <MetricListItem
              title={MESSAGES.return_rate}
              value={itemData.return_rate}
              trendPercentage={itemData.return_rate_last_month_percentage}
              valueSuffix="%"
            />
          </MetricList>
          {hasClickableLabel && (
            <div className="click-label">
              {MESSAGES.click_to_open}
            </div>
          )}
        </>
      )}
    </Block>
  </Popper>
);

CategoryPopperMetrics.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  anchorEl: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }),
  isLoading: PropTypes.bool.isRequired,
  hasClickableLabel: PropTypes.bool,
  itemData: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    items_sold: PropTypes.number,
    items_sold_last_month_percentage: PropTypes.number,
    revenue: PropTypes.number,
    revenue_last_month_percentage: PropTypes.number,
    profit: PropTypes.number,
    profit_last_month_percentage: PropTypes.number,
    return_rate: PropTypes.number,
    return_rate_last_month_percentage: PropTypes.number,
    profit_margin: PropTypes.number,
    profit_margin_last_month_percentage: PropTypes.number,
  }),
};

CategoryPopperMetrics.defaultProps = {
  anchorEl: undefined,
  item: undefined,
  itemData: undefined,
  hasClickableLabel: false,
};

export default CategoryPopperMetrics;
