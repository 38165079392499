import React from 'react';
import propTypes from 'prop-types';
import Popup from 'reactjs-popup';

import 'components/views/Admin/SuperUsers/DeleteSuperUserModal/styles.scss';
import Button from 'components/common/Button';
import { REQUEST_STATUS, BUTTON_TYPE } from 'constants/common';
import useAsync from 'hooks/useAsync';
import { deleteSuperUser } from 'api/superUserAPI';
import { MESSAGES } from 'constants/messages';

const DeleteSuperUserModal = ({ superUser, onClose, onSubmit }) => {
  const deleteSuperUserHandler = async () => {
    await deleteSuperUser(superUser.uuid);
    onSubmit();
  };

  const { execute, status } = useAsync(deleteSuperUserHandler);

  return (
    <Popup
      modal
      open={!!superUser.uuid}
      position="center center"
      onClose={onClose}
    >
      {() => (
        <div className="confirmation-window delete-super-user">
          <span className="confirmation-window__content delete-super-user__title">
            {MESSAGES.remove_this_user}
            <br />
            {MESSAGES.action_can_not_be_undone}
          </span>
          <div className="delete-super-user__form__actions form__actions">
            <div className="delete-super-user__form__actions__cancel form__cancel">
              <Button
                block
                isPreventSubmit
                onClick={onClose}
                type={BUTTON_TYPE.secondary}
              >
                {MESSAGES.cancel}
              </Button>
            </div>
            <div className="delete-super-user__form__actions__submit form__submit">
              <Button
                type={BUTTON_TYPE.primary}
                danger
                isLoading={REQUEST_STATUS.loading === status}
                onClick={execute}
              >
                {MESSAGES.remove}
              </Button>
            </div>
          </div>
        </div>
      )}
    </Popup>
  );
};

export default DeleteSuperUserModal;

DeleteSuperUserModal.propTypes = {
  superUser: propTypes.objectOf(propTypes.oneOfType([
    propTypes.string,
    propTypes.object,
  ])).isRequired,
  onClose: propTypes.func.isRequired,
  onSubmit: propTypes.func.isRequired,
};
