import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { Box, Grid, Stack } from '@mui/material';
import {
  Tab, TabList, TabPanel, Tabs,
} from 'react-tabs';
import { MESSAGES } from 'constants/messages';
import { GUIDES } from 'constants/guides';
import GuideInfo from 'components/common/GuideInfo';
import Metric from 'components/common/Metric/Metric';
import { PLATFORMS } from 'constants/platforms';

const ITEMS = [{
  key: 'orders',
  title: MESSAGES.total_orders,
  currency: false,
  perc: false,
  guide: GUIDES.M1,
}, {
  key: 'sales',
  title: MESSAGES.total_sales,
  currency: true,
  perc: false,
  guide: GUIDES.M2,
}, {
  key: 'clicks',
  title: MESSAGES.total_clicks,
  currency: false,
  perc: false,
  guide: GUIDES.M3,
}, {
  key: 'impressions',
  title: MESSAGES.total_impressions,
  currency: false,
  perc: false,
  guide: GUIDES.M4,
}, {
  key: 'cost',
  title: MESSAGES.total_cost,
  currency: true,
  perc: false,
  guide: GUIDES.M5,
}, {
  key: 'roas',
  title: MESSAGES.roas,
  currency: false,
  perc: false,
  guide: GUIDES.M6,
}, {
  key: 'aov',
  title: MESSAGES.aov,
  currency: true,
  perc: false,
  guide: GUIDES.M7,
}, {
  key: 'cpa',
  title: MESSAGES.cpa,
  currency: true,
  perc: false,
  guide: GUIDES.M8,
}, {
  key: 'cpc',
  title: MESSAGES.cpc,
  currency: true,
  perc: false,
  guide: GUIDES.M9,
}, {
  key: 'cpm',
  title: MESSAGES.cpm,
  currency: true,
  perc: false,
  guide: GUIDES.M10,
}, {
  key: 'ctr',
  title: MESSAGES.ctr,
  currency: false,
  perc: true,
  guide: GUIDES.M11,
}];

const MetricsKpiItem = ({
  data, graphData, onClickChartHandler, isMostRecent,
}) => (
  <div className="metrics-kpi-container">
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Stack direction="row" spacing={1}>
          <div className="metrics-kpi-category">
            {MESSAGES.channels}
          </div>
          <Box display="flex" alignItems="center">
            <GuideInfo info={GUIDES.H20} type="grey" />
          </Box>
        </Stack>
      </Grid>

      <Grid item xs={12}>
        <Tabs>
          <TabList className="metrics-kpi-tablist">
            {ITEMS.map((item) => (
              <Tab key={item.key} className="metrics-kpi-tab">
                <div className="metrics-kpi-tab-content">
                  {item.title}
                </div>
              </Tab>
            ))}
          </TabList>
          {ITEMS.map((item) => (
            <TabPanel key={item.key}>
              <Grid container spacing={2}>
                {['google_ads',
                // 'facebook', 'instagram', 'blended'
              ].map((platform) => (
                  <Grid item xl={3} lg={4} sm={6} xs={12} key={platform}>
                    <Metric
                      option={{
                        category: {
                          key: platform,
                          title: PLATFORMS[platform].title,
                          icon: PLATFORMS[platform].icon,
                        },
                        key: item.key,
                        title: item.title,
                        value: data?.[platform]?.[item.key],
                        percentage: data?.[platform]?.[`${item.key}_last_month_percentage`],
                        prefix: item.currency ? data?.[platform]?.currency : null,
                        perc: item.perc,
                        guide: item.guide,
                      }}
                      requiredIntegration={PLATFORMS[platform].integration}
                      graphData={graphData?.[platform]}
                      onClickChartHandler={onClickChartHandler}
                      isMostRecent={isMostRecent}
                    />
                  </Grid>
                ))}
              </Grid>
            </TabPanel>
          ))}
        </Tabs>
      </Grid>
    </Grid>
  </div>
);

MetricsKpiItem.propTypes = {
  data: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.any,
    ]),
  ),
  graphData: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.any,
    ]),
  ),
  onClickChartHandler: PropTypes.func.isRequired,
  isMostRecent: PropTypes.bool,
};

MetricsKpiItem.defaultProps = {
  data: null,
  graphData: null,
  isMostRecent: false,
};

export default MetricsKpiItem;
