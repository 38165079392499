import React from 'react';
import propTypes from 'prop-types';

import 'containers/AcceptInvitation/InviteLicense/styles.scss';
import CompanyName from 'components/common/CompanyName';

const InviteLicense = ({ content, license }) => (
  <div className="invite-license">
    {content && (
      <span className="invite-license__content">
        {content}
      </span>
    )}
    <div className="invite-license__license">
      <CompanyName src={license?.avatar} />
    </div>
  </div>
);

InviteLicense.propTypes = {
  content: propTypes.string,
  license: propTypes.objectOf(propTypes.string),
};

InviteLicense.defaultProps = {
  content: null,
  license: null,
};

export default InviteLicense;
