import React from 'react';
import classNames from 'classnames';
import propTypes from 'prop-types';

import 'components/common/Table/styles.scss';
import { ReactComponent as SortArrowDownIcon } from 'assets/svg/sortArowDown.svg';
import { SORT_DIRECTIONS } from 'constants/common';

const SortableColumn = ({
  children, sort, setSort, column,
}) => {
  const sortHandler = () => {
    const changedDirection = sort.direction === SORT_DIRECTIONS.asc ? SORT_DIRECTIONS.desc : SORT_DIRECTIONS.asc;
    const newDirection = sort.key === column.key ? changedDirection : sort.direction;

    setSort({
      key: column.key,
      direction: newDirection,
      ordering: column.ordering,
    });
  };

  return (
    <div
      className={classNames('table__header__cell__sortable', {
        'table__header__cell__sortable__sorted': sort.key === column.key,
      })}
      onClick={sortHandler}
    >
      {children}
      <SortArrowDownIcon className={classNames('table__header__cell__sortable__icon', {
        'table__header__cell__sortable__icon__desc': sort.key === column.key && sort.direction === SORT_DIRECTIONS.desc,
        'table__header__cell__sortable__icon__asc': sort.key === column.key && sort.direction === SORT_DIRECTIONS.asc,
      })}
      />
    </div>
  );
};

SortableColumn.propTypes = {
  children: propTypes.node.isRequired,
  sort: propTypes.objectOf(propTypes.oneOfType([
    propTypes.array,
    propTypes.string,
  ])).isRequired,
  setSort: propTypes.func.isRequired,
  column: propTypes.oneOfType([
    propTypes.object,
  ]).isRequired,
};

export default SortableColumn;
