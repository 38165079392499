import moduleName from 'store/license/constants';
import { LICENSE_STATUS_NAMES_FULL } from 'constants/license';
import { getIsDataAccessSuperUser, getIsSuperUser } from 'store/profile/getters';
import {
  ACTIVE_INTEGRATION_STATUSES,
} from 'components/views/Integrations/constants/Constants';

const getLicenseState = (state) => state[moduleName];

export const getLicenseDetails = (state) => getLicenseState(state);

export const getCurrentLicense = (state) => {
  const { license } = getLicenseState(state);
  return license;
};

export const getLicenseStatus = (state) => {
  const { license_status: licenseStatus } = getLicenseState(state);
  return licenseStatus || [];
};

export const getIsLicenseAdmin = (state) => {
  const { license_status: licenseStatus } = getLicenseState(state);
  const isAdminInLicense = (licenseStatus || [])[1] === LICENSE_STATUS_NAMES_FULL.ADMINISTRATOR;

  const isSuperUser = getIsSuperUser(state);

  return isAdminInLicense || isSuperUser;
};

export const getHasDataAccess = (state) => {
  const { license_status: licenseStatus } = getLicenseState(state);
  const isDataAccessSuperUser = getIsDataAccessSuperUser(state);

  return !!licenseStatus || isDataAccessSuperUser;
};

export const getCustomer = (state) => {
  const { license } = getLicenseState(state);
  return license.customer;
};

const getIntegrationsState = (state) => state[moduleName].integrations;

export const getActiveIntegrationsList = (state) => {
  const { activeIntegrationsList } = getIntegrationsState(state);
  return activeIntegrationsList;
};

export const getActiveIntegrationKeys = (state) => {
  const { activeIntegrationsList } = getIntegrationsState(state);
  return activeIntegrationsList.map((integration) => integration.key);
};

export const getCompletedIntegrationKeys = (state) => {
  const { activeIntegrationsList } = getIntegrationsState(state);
  return activeIntegrationsList
    .filter((integration) => integration.integration_status === ACTIVE_INTEGRATION_STATUSES.OK)
    .map((integration) => integration.key);
};

export const getAvailableIntegrationsList = (state) => {
  const { availableIntegrationsList } = getIntegrationsState(state);
  return availableIntegrationsList;
};
