import React from 'react';
import { Stack } from '@mui/material';
import Checkbox from 'components/common/Checkbox';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import './styles.scss';

const MultiSelectItem = ({
  children, selected, onSelect, leftContent, itemKey, disabled,
}) => (
  <div
    className="multi-select-item"
    onClick={(e) => onSelect(itemKey, e)}
  >
    <Stack
      direction="row"
      spacing={1}
      justifyContent="space-between"
    >
      <Stack direction="row" spacing={1}>
        {!!leftContent && (
          <div className="left-content">
            {leftContent}
          </div>
        )}
        <div className="content">
          {children}
        </div>
      </Stack>
      <Checkbox
        disabled={disabled}
        id={itemKey}
        value={selected}
      />
    </Stack>
  </div>
);

MultiSelectItem.propTypes = {
  children: PropTypes.node.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func,
  leftContent: PropTypes.node,
  itemKey: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

MultiSelectItem.defaultProps = {
  onSelect: noop,
  leftContent: null,
  disabled: false,
};

export default MultiSelectItem;