import { useState, useEffect } from 'react';
import moment from 'moment';
import {
  groupBy, mapValues, get,
} from 'lodash';
import {
  metricsActiveKpisKeys,
} from 'components/views/Homepage/KpiHistoryDataBlock/const';
import { getMetrics } from 'api/homeAPI';
import useAsync from 'hooks/useAsync';
import { getTrendFromValues } from 'utils/formatters';

export default (selectedMonth) => {
  const [metricsData, setMetricsData] = useState();
  const [metricsGraphData, setMetricsGraphData] = useState();
  const {
    execute: fetchData,
    isLoading: isMetricsLoading,
    value: fetchResult,
  } = useAsync(getMetrics);

  const getDateRange = () => {
    const portalDateFormat = 'YYYY-MM-DDTHH:mm:ss[Z]';
    const dateTo = moment(selectedMonth).endOf('month').format(portalDateFormat);
    const dateFrom = moment(selectedMonth).add(-6, 'months').format(portalDateFormat);
    return [dateFrom, dateTo];
  };

  const calculateLastMonthPercentages = (current, previous) => {
    if (!current) return null;

    metricsActiveKpisKeys.forEach((key) => {
      const previousValue = get(previous, key);
      const percentageKeyName = `${key}_last_month_percentage`;
      current[percentageKeyName] = getTrendFromValues(current[key], previousValue);
    });

    return current;
  };

  const populateMetricsData = (groupedByPlatform) => {
    const newMetricsData = mapValues(groupedByPlatform, (data) => {
      const currentItem = data[data?.length - 1];
      const prevItem = data[data?.length - 2];
      return calculateLastMonthPercentages(currentItem, prevItem);
    });

    setMetricsData(newMetricsData);
  };

  const populateGraphData = (groupedByPlatform) => {
    const graphData = mapValues(groupedByPlatform, (data) => {
      const historicalData = data || [];

      const kpis = metricsActiveKpisKeys.reduce(
        (result, key) => Object.assign(result, { [key]: historicalData.map((item) => item[key]) }),
        {},
      );

      return {
        ...kpis,
        dateRange: historicalData.map((item) => moment(item.date).format('YYYY-MM')),
      };
    });

    setMetricsGraphData(graphData);
  };

  useEffect(() => {
    const [dateFrom, dateTo] = getDateRange();
    fetchData({ dateFrom, dateTo });
  }, [selectedMonth]);

  useEffect(() => {
    const groupedByPlatform = groupBy(fetchResult, 'platform');

    populateMetricsData(groupedByPlatform);
    populateGraphData(groupedByPlatform);
  }, [fetchResult]);

  return {
    metricsData,
    metricsGraphData,
    isMetricsLoading,
  };
};
