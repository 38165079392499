import React from 'react';
import propTypes from 'prop-types';

import Button from 'components/common/Button';

const CustomOpenButton = ({ children, ...props }, ref) => (
  <Button
    ref={ref}
    {...props}
  >
    {children}
  </Button>
);

CustomOpenButton.propTypes = {
  children: propTypes.node.isRequired,
};

export default React.forwardRef(CustomOpenButton);
