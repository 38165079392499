import React, { useEffect, useState } from 'react';
import { Stack, Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import SingleProductHeader from 'components/views/Products/SingleProductView/SingleProductHeader';
import ProductSegmentsChart from 'components/views/Products/SingleProductView/ProductSegmentsChart';
import SingleProductItem from 'components/views/Products/SingleProductView/SingleProductItem';
import SoldTogetherItem from 'components/views/Products/SingleProductView/SoldTogetherItem';
import { MESSAGES } from 'constants/messages';
import { getProduct, getProductsSegments } from 'api/productsAPI';
import { PRODUCT_SEGMENTS_COLORS } from 'constants/colors';
import { keyBy } from 'lodash';
import { getSegmentsInfo } from 'api/segmentsAPI';
import { setProductName } from 'store/crumbsIdToName/crumbsIdToNameSlice';
import { GUIDES } from 'constants/guides';

const getOptions = (data) => [
  {
    title: MESSAGES.revenue,
    value: data?.revenue,
    percentage: data?.revenue_last_month_percentage,
    prefix: data.currency,
    monthly: true,
    titleSuffix: MESSAGES.per_year,
    isPrevious: true,
    hasPrevValue: true,
    guide: GUIDES.SP2,
  },
  {
    title: MESSAGES.profit,
    value: data?.profit,
    percentage: data?.profit_last_month_percentage,
    prefix: data.currency,
    monthly: true,
    titleSuffix: MESSAGES.per_year,
    isPrevious: true,
    hasPrevValue: true,
    guide: GUIDES.SP3,
  }, {
    title: MESSAGES.profit_margin,
    value: data?.profit_margin,
    percentage: data?.profit_margin_last_month_percentage,
    perc: true,
    monthly: true,
    titleSuffix: MESSAGES.per_year,
    isPrevious: true,
    hasPrevValue: true,
    guide: GUIDES.SP13,
  }, {
    title: MESSAGES.items_sold,
    value: data?.items_sold,
    percentage: data?.items_sold_last_month_percentage,
    prefix: null,
    monthly: true,
    titleSuffix: MESSAGES.per_year,
    isPrevious: true,
    hasPrevValue: true,
    guide: GUIDES.SP4,
  },
  {
    title: MESSAGES.items_returned,
    value: data?.total_returns,
    percentage: data?.total_returns_last_month_percentage,
    prefix: null,
    monthly: true,
    titleSuffix: MESSAGES.per_year,
    isPrevious: true,
    hasPrevValue: true,
    guide: GUIDES.SP5,
  },
  {
    title: MESSAGES.return_rate,
    value: data?.return_rate,
    percentage: data?.return_rate_last_month_percentage,
    perc: true,
    monthly: true,
    titleSuffix: MESSAGES.per_year,
    isPrevious: true,
    hasPrevValue: true,
    guide: GUIDES.SP6,
  },
  {
    title: MESSAGES.discounts,
    value: data?.discounts,
    percentage: data?.discounts_last_month_percentage,
    prefix: data.currency,
    monthly: true,
    titleSuffix: MESSAGES.per_year,
    isPrevious: true,
    hasPrevValue: true,
    guide: GUIDES.SP7,
  },
  {
    title: MESSAGES.repurchase_rate,
    value: data?.repurchase_rate,
    percentage: data?.repurchase_rate_last_month_percentage,
    perc: true,
    monthly: true,
    titleSuffix: MESSAGES.per_all_time,
    isPrevious: false,
    hasPrevValue: false,
    guide: GUIDES.SP8,
  },
  {
    title: MESSAGES.first_order_rate,
    value: data?.first_order_rate,
    percentage: data?.first_order_rate_last_month_percentage,
    perc: true,
    monthly: true,
    titleSuffix: MESSAGES.per_year,
    isPrevious: true,
    hasPrevValue: true,
    guide: GUIDES.SP9,
  },
  {
    title: MESSAGES.bought_uniquely_rate,
    value: data?.bought_uniquely_rate,
    percentage: data?.bought_uniquely_rate_last_month_percentage,
    perc: true,
    monthly: true,
    titleSuffix: MESSAGES.per_year,
    isPrevious: true,
    hasPrevValue: true,
    guide: GUIDES.SP10,
  },
];

const SingleProductView = () => {
  const { id } = useParams();
  const [segmentsMap, setSegmentsMap] = useState({});
  const [customerSegmentsMap, setCustomerSegmentsMap] = useState({});
  const [data, setData] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    const populateSegments = async () => {
      const segments = await getProductsSegments();
      const segmentsWithColor = segments.map((segment) => (
        { ...segment, color_1: PRODUCT_SEGMENTS_COLORS[segment?.id] }));
      setSegmentsMap(keyBy(segmentsWithColor, 'id'));
    };

    const populateCustomerSegments = async () => {
      const response = await getSegmentsInfo();
      setCustomerSegmentsMap(keyBy(response, 'id'));
    };

    populateSegments();
    populateCustomerSegments();
  }, []);

  useEffect(() => {
    const fetchProduct = async () => {
      const product = await getProduct(id);
      setData(product);
      dispatch(setProductName({ [product.id]: product.name }));
    };

    id && fetchProduct();
  }, [id]);

  return (
    <div className="single-product-view">
      <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>
        <Grid container spacing={2}>
          <Grid item lg={4} md={5} xs={12}>
            <Stack spacing={2}>
              <SingleProductHeader
                data={data}
                segmentsMap={segmentsMap}
              />
              <ProductSegmentsChart
                customer_segments={data.customer_segments}
                customerSegmentsMap={customerSegmentsMap}
              />
            </Stack>
          </Grid>
          <Grid item lg={8} md={7} xs={12}>
            <Stack spacing={2}>
              <SingleProductItem
                options={getOptions(data)}
              />
              {!!data?.most_sold_together && (
                <SoldTogetherItem
                  data={data?.most_sold_together}
                />
              )}
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </div>
  );
};

export default SingleProductView;
