import React from 'react';
import propTypes from 'prop-types';

import 'containers/PrivateApp/SwitchLicense/LicenseTable/styles.scss';
import ConfirmationWindow from 'components/common/ConfirmationWindow';
import { ReactComponent as RemoveIcon } from 'assets/svg/trash.svg';
import IconButton from 'components/common/IconButton';

const CONFIRMATION_REMOVE_CONTENT = 'Remove this user?</br>This action can not be undone';

const CustomRemoveButton = React.forwardRef((props, ref) => (
  <button
    type="button"
    ref={ref}
    className="license-members-table-row__remove"
    {...props}
  >
    <IconButton icon={RemoveIcon} type="danger" />
  </button>
));

const ConfirmationRemoveButton = ({ onConfirm }) => {
  const onConfirmWithClose = (close) => {
    onConfirm();
    close();
  };

  return (
    <ConfirmationWindow
      danger
      confirmText="Remove"
      content={CONFIRMATION_REMOVE_CONTENT}
      onConfirm={onConfirmWithClose}
      customOpenButton={() => <CustomRemoveButton />}
      inWorkArea
    />
  );
};

ConfirmationRemoveButton.propTypes = {
  onConfirm: propTypes.func.isRequired,
};

export default ConfirmationRemoveButton;
