import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import BaseSelect from 'react-select';

import './styles.scss';
import Feedback from 'components/common/Input/Feedback';
import { ReactComponent as AttentionIcon } from 'assets/svg/attention.svg';
import { selectDefaultProps, selectPropTypes } from 'components/common/Select/selectPropTypes';

const Select = ({
  name,
  options,
  label,
  value,
  error,
  touched,
  isRequired,
  isDisabled,
  isLoading,
  isClearable,
  hasFeedback,
  defaultValue,
  onBlur,
  onChange,
  hasFloatLabel,
  isFixedLabel,
  isSearchable,
  openMenuOnClick,
}) => {
  const [isActive, setIsActive] = useState(false);

  const onChangeWrapper = ((selectedItem) => {
    const target = {
      name,
      value: selectedItem,
    };

    setIsActive(true);
    onChange({ target });
  });

  const onBlurWrapper = (() => {
    const target = { name, value };

    onBlur({ target });
  });

  useEffect(() => {
    if (value || defaultValue) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [value, defaultValue]);

  return (
    <div className={classNames(
      'select-wrapper', 'float-label', {
        'field--error': touched && error,
        'field-disabled': isDisabled,
        'disable-select-arrow': !openMenuOnClick,
      },
    )}
    >
      {hasFloatLabel && (
        <label htmlFor={name} className={(isActive || isFixedLabel) ? 'activated' : ''}>
          {`${label} ${isRequired ? '*' : ''}`}
        </label>
      )}
      <BaseSelect
        id={name}
        name={name}
        value={value}
        className={classNames('select')}
        classNamePrefix="select"
        defaultValue={defaultValue}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable={isClearable}
        placeholder={hasFloatLabel ? '' : `${label} ${isRequired ? '*' : ''}`}
        onChange={onChangeWrapper}
        onBlur={onBlurWrapper}
        options={options}
        isSearchable={isSearchable}
        openMenuOnClick={openMenuOnClick}
      />
      {hasFeedback && touched && <AttentionIcon className="select__feedback field__feedback" />}
      {hasFeedback && touched && error && (
        <div className="field__hint">
          <Feedback hint={error} />
        </div>
      )}
    </div>
  );
};

Select.propTypes = selectPropTypes;

Select.defaultProps = selectDefaultProps;

export default Select;
