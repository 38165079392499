import { isNumber } from 'lodash';

export const titleWithCurrency = (title, currency) => {
  const currencyStr = currency ? ` (${currency})` : '';
  return title + currencyStr;
};

export const getTrendFromValues = (currentValue, previousValue) => {
  if (!isNumber(currentValue) || !isNumber(previousValue)) return null;
  if (currentValue === 0 && previousValue === 0) return 0;
  if (currentValue === 0 || previousValue === 0) return null;
  return Math.round(((currentValue / previousValue) * 100) - 100);
};

export const maxDecimals = (value, decimals) =>
  parseFloat(value.toFixed(decimals));
