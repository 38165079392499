import { INPUT_VALUE_TYPE } from 'constants/common';
import { find, forEach } from 'lodash';
import { v1 as uuidv1 } from 'uuid';
import { toUrlParams } from 'utils/common';

export const isFilterValid = (tag) => {
  if (tag.type === INPUT_VALUE_TYPE.list && !!tag.uuid) return true;
  return !!(!!tag.uuid && !!tag.operator && (!!tag.value || tag.value === 0));
};

export const prepareFilters = (filters) => {
  const params = toUrlParams(filters);
  return params && `&${params}`;
};

export const prepareSegment = (iterableData) => iterableData?.join(',');

export const prepareTagsAsFilterArray = (tags) => {
  const filters = {};
  tags.filter(isFilterValid).forEach((tag) => {
    let { value, operator, uuid } = tag;
    let key = uuid;

    if (tag.type === INPUT_VALUE_TYPE.percent) value = tag.value / 100;
    if (tag.type === INPUT_VALUE_TYPE.boolean) value = tag.value.value;
    if (tag.type === INPUT_VALUE_TYPE.list) {
      key = tag.entityKey;
      operator = 'in';

      const existingVal = filters[`${key}__${operator}`];
      value = existingVal
        ? `${existingVal},${uuid}`
        : uuid;
    }

    if (operator !== 'exact') {
      key += `__${operator}`;
    }
    filters[key] = value;
  });
  return filters;
};

export const prepareTagsAsFilters = (tags) =>
  tags.filter(isFilterValid).map((tag) => {
    let key = tag.uuid;
    if (tag.operator !== 'exact') {
      key += `__${tag.operator}`;
    }
    const value = (
      tag.type === INPUT_VALUE_TYPE.percent ? tag.value / 100
        : tag.type === INPUT_VALUE_TYPE.boolean ? tag.value.value
          : tag.value
    );
    return `${key}=${value}`;
  }).join('&');

const getBaseTag = (uuid, operator, value) => ({
  key: uuidv1(),
  entityKey: null,
  uuid,
  name: null,
  finalized: true,
  operator: operator || 'exact',
  value,
  fromFilter: true,
});

export const getTagsFromFilters = (filters, lookupEntities) => {
  const tags = [];

  forEach(filters, (value, fieldName) => {
    const split = fieldName.split('__');
    const tag = getBaseTag(split[0], split[1], value);

    const uuidEntity = find(lookupEntities, { entityKey: tag.uuid });
    if (uuidEntity && uuidEntity?.hasDynamicFields) {
      String(tag.value).split(',').forEach((subValue) => {
        subValue = Number(subValue);

        const field = find(uuidEntity.fields, { key: subValue });
        if (!field) return;
        const valueTag = getBaseTag(subValue, null, null);

        tags.push({
          ...valueTag,
          entityKey: uuidEntity.entityKey,
          name: field.title,
          operator: null,
        });
      });
      return;
    }

    lookupEntities.forEach((lookupEntity) => {
      const { entityKey: lookupEntityKey, fields: lookupFields } = lookupEntity;
      const lookupResult = find(lookupFields, { key: tag.uuid });
      if (lookupResult) {
        tag.name = lookupResult.title;
        tag.entityKey = lookupEntityKey;
        return false;
      }
      return true;
    });

    if (!tag.entityKey || !tag.name) return;
    tags.push(tag);
  });

  return tags;
};
