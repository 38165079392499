import propTypes from 'prop-types';
import { noop } from 'lodash';

export const inputPropTypes = {
  id: propTypes.string.isRequired,
  containerRef: propTypes.func,
  name: propTypes.string.isRequired,
  label: propTypes.string.isRequired,
  type: propTypes.string,
  error: propTypes.objectOf(propTypes.string),
  value: propTypes.oneOfType([propTypes.string, propTypes.number]),
  autoComplete: propTypes.string,
  maxLength: propTypes.string,
  inputType: propTypes.string,
  touched: propTypes.bool,
  isRequired: propTypes.bool,
  isDisable: propTypes.bool,
  isReadonly: propTypes.bool,
  isBlurOnEnter: propTypes.bool,
  hasScale: propTypes.bool,
  hasPreview: propTypes.bool,
  hasFeedback: propTypes.bool,
  hasSuccess: propTypes.bool,
  hasFloatLabel: propTypes.bool,
  hasPercent: propTypes.bool,
  hasCalendar: propTypes.bool,
  hasSearch: propTypes.bool,
  onChange: propTypes.func,
  onBlur: propTypes.func,
  onFocus: propTypes.func,
  onSearchClick: propTypes.func,
  onClick: propTypes.func,
  isFixedLabel: propTypes.bool,
  onKeyPress: propTypes.func,
};

export const defaultInputProps = {
  value: '',
  type: 'text',
  error: undefined,
  autoComplete: 'off',
  maxLength: undefined,
  touched: false,
  isRequired: false,
  isDisable: false,
  isReadonly: false,
  isBlurOnEnter: false,
  hasScale: false,
  hasPreview: false,
  hasFeedback: false,
  hasSuccess: false,
  hasFloatLabel: true,
  hasPercent: false,
  hasCalendar: false,
  hasSearch: false,
  onChange: undefined,
  onBlur: undefined,
  onFocus: undefined,
  inputType: undefined,
  onSearchClick: undefined,
  onClick: undefined,
  isFixedLabel: false,
  onKeyPress: noop,
};
