import moduleName from './constants';

const getSegmentsState = (state) => state[moduleName];

export const getSegmentsData = (state) => {
  const { segmentsData } = getSegmentsState(state);
  return segmentsData;
};

export const getSegments = (state) => {
  const { segmentsData } = getSegmentsState(state);
  return segmentsData?.licence_segments;
};

export const getSegmentById = (segment_id) => (state) =>
  getSegments(state).find((segment) => segment.segment.id === segment_id);

export const getSegmentByName = (segment_name) => (state) =>
  getSegments(state).find((segment) => segment.segment.segment_name === segment_name)

export const getSegmentByIdInState = (id, segmentStates) =>
  segmentStates.find((segment) => segment.segment.id === id);
