import {
  COMPANY_NAME_ERROR_REQUIRED,
  COMPANY_NAME_ERROR_MIN_LENGTH,
  COMPANY_NAME_ERROR_MAX_LENGTH,
  BUSINESS_CATEGORY_ERROR_REQUIRED,
  COMPANY_URL_ERROR_MIN_LENGTH,
  COMPANY_URL_ERROR_MAX_LENGTH,
  COMPANY_URL_ERROR_PATTERN,
  COMPANY_URL_ERROR_REQUIRED, COMPANY_SIZE_ERROR_REQUIRED,
} from 'constants/errors';
import {
  COMPANY_NAME_MIN_LENGTH,
  COMPANY_NAME_MAX_LENGTH,
  COMPANY_URL_MIN_LENGTH,
  COMPANY_URL_MAX_LENGTH,
  COMPANY_URL_PATTERN,
} from 'constants/validation';

import { Validator } from 'utils';

export default ({
  companyName, businessCategory, companyURL, companySize,
}) => {
  const errors = {};

  const companyNameValidator = new Validator(companyName);
  const businessCategoryValidator = new Validator(businessCategory);
  const companyURLValidator = new Validator(companyURL);
  const companySizeValidator = new Validator(companySize);

  try {
    companyNameValidator
      .required(COMPANY_NAME_ERROR_REQUIRED)
      .minLength(COMPANY_NAME_MIN_LENGTH, COMPANY_NAME_ERROR_MIN_LENGTH)
      .maxLength(COMPANY_NAME_MAX_LENGTH, COMPANY_NAME_ERROR_MAX_LENGTH);
  } catch (error) {
    errors.companyName = error.message;
  }

  try {
    businessCategoryValidator
      .required(BUSINESS_CATEGORY_ERROR_REQUIRED);
  } catch (error) {
    errors.businessCategory = error.message;
  }

  try {
    companyURLValidator
      .required(COMPANY_URL_ERROR_REQUIRED)
      .minLength(COMPANY_URL_MIN_LENGTH, COMPANY_URL_ERROR_MIN_LENGTH)
      .maxLength(COMPANY_URL_MAX_LENGTH, COMPANY_URL_ERROR_MAX_LENGTH)
      .match(COMPANY_URL_PATTERN, COMPANY_URL_ERROR_PATTERN);
  } catch (error) {
    errors.companyURL = error.message;
  }

  try {
    companySizeValidator
      .required(COMPANY_SIZE_ERROR_REQUIRED);
  } catch (error) {
    errors.companySize = error.message;
  }

  return errors;
};
