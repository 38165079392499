import React, { useState, useEffect, useCallback } from 'react';
import { Box, Stack } from '@mui/material';
import Select from 'components/common/Select';
import FieldLabel from 'components/common/FieldLabel';
import { MESSAGES } from 'constants/messages';
import Grid from '@mui/material/Grid';
import './styles.scss';
import { debounce, isNil, values } from 'lodash';
import ContentLoader from 'components/common/ContentLoader';
import { useToast } from 'components/common/Toast';
import { getExperimentDataBoundaries, getExperimentResults } from 'api/experimentsAPI';
import {
  EXPERIMENT_AVERAGED_BY,
  EXPERIMENT_GROUP_BY,
  EXPERIMENT_METRICS,
} from 'constants/experiments';
import PropTypes from 'prop-types';
import ExperimentGraph from 'components/views/Experiments/ExperimentView/ExperimentResults/ExperimentGraph';
import moment from 'moment';
import Checkbox from 'components/common/Checkbox';
import MonthPicker from 'components/common/MonthPicker';

const ExperimentResults = ({ experiment }) => {
  const { createToast } = useToast();

  const [isInitialized, setIsInitialized] = useState(false);
  const [isLoadingInitial, setIsLoadingInitial] = useState(false);
  const [isLoadingBoundaries, setIsLoadingBoundaries] = useState(false);
  const [selectedMetric, setSelectedMetric] = useState(EXPERIMENT_METRICS.SALES);
  const [selectedGroupBy, setSelectedGroupBy] = useState(EXPERIMENT_GROUP_BY.DAY);
  const [selectedAveragedBy, setSelectedAveragedBy] = useState(EXPERIMENT_AVERAGED_BY.GROUP_SIZE);
  const [isCumulative, setIsCumulative] = useState(false);
  const [groupResults, setGroupResults] = useState([]);
  const [currency, setCurrency] = useState(null);

  const [resultBoundaries, setResultBoundaries] = useState({
    fromDate: null,
    toDate: null,
  });
  const [experimentBoundaries, setExperimentBoundaries] = useState({
    fromDate: null,
    toDate: null,
  });
  const [showResultBoundaries, setShowResultBoundaries] = useState({
    fromDate: null,
    toDate: null,
  });
  const [experimentData, setExperimentData] = useState([]);

  const metric = selectedMetric.value;
  const groupBy = selectedGroupBy.value;
  const averagedBy = selectedAveragedBy.value;

  const updateResults = (queryBody) => {
    if (isNil(queryBody?.metric) || isNil(queryBody?.groupBy)) {
      setExperimentData([]);
      return;
    }

    if (!isInitialized) setIsLoadingInitial(true);
    getExperimentResults(queryBody).then(({
      metrics, start_date, end_date, currency: new_currency,
    }) => {
      setExperimentData(metrics);
      setCurrency(new_currency);

      if (start_date && end_date) {
        setResultBoundaries({
          fromDate: moment(start_date, 'YYYY-MM-DDTHH:mm:ssZ'),
          toDate: moment(end_date, 'YYYY-MM-DDTHH:mm:ssZ'),
        });
      }

      if (!isInitialized) setIsLoadingInitial(false);
      setIsInitialized(true);
    }).catch(() => {
      setIsLoadingInitial(false);
      setIsInitialized(true);
      createToast(
        MESSAGES.failed,
        MESSAGES.failed_to_fetch_experiment_results,
        'danger',
        'experiment-fetch-results-error',
      );
    });
  };

  const debouncedUpdate = useCallback(debounce(updateResults, 600), [isInitialized]);

  useEffect(() => {
    if (!experimentBoundaries.toDate || !experimentBoundaries.fromDate) return;

    const queryBody = {
      uuid: experiment.uuid,
      metric,
      groupBy,
      isCumulative,
      averagedBy,
      fromDate: showResultBoundaries.fromDate
        ? moment(showResultBoundaries.fromDate).format('YYYY-MM') : null,
      toDate: showResultBoundaries.toDate
        ? moment(showResultBoundaries.toDate).format('YYYY-MM') : null,
    };

    debouncedUpdate(queryBody);
  }, [
    experiment.uuid, metric, groupBy, averagedBy,
    isCumulative, showResultBoundaries, experimentBoundaries,
  ]);

  useEffect(() => {
    if (!experiment?.uuid) return;

    setIsLoadingBoundaries(true);
    getExperimentDataBoundaries(experiment.uuid).then(({ start_date, end_date }) => {
      if (!start_date || !end_date) {
        setIsInitialized(true);
        return;
      }

      const fromDate = moment(start_date, 'YYYY-MM-DDTHH:mm:ssZ');
      const toDate = moment(end_date, 'YYYY-MM-DDTHH:mm:ssZ');

      setShowResultBoundaries({
        fromDate: fromDate.toDate(),
        toDate: toDate.toDate(),
      });
      setExperimentBoundaries({ fromDate, toDate });
      setIsLoadingBoundaries(false);
    }).catch(() => {
      setIsLoadingBoundaries(false);
    });
  }, [experiment.uuid]);

  useEffect(() => {
    if (!experimentData || !experimentData?.length) {
      setGroupResults([]);
      return;
    }
    const newGroupResults = {};

    const groupsById = {};
    experiment.groups.forEach((group) => {
      groupsById[group.uuid] = group;
    });

    experimentData.forEach(({ group_id, value }) => {
      const group = groupsById[group_id];
      if (!group) return;

      const groupResult = newGroupResults[group_id] || {
        name: group.name,
        order: group.order,
        metrics: [],
      };

      groupResult.metrics.push(value);
      newGroupResults[group_id] = groupResult;
    });

    setGroupResults(values(newGroupResults));
  }, [experimentData]);

  const handleMetricChange = ({ target }) =>
    setSelectedMetric(target.value);

  const handleGroupByChange = ({ target }) =>
    setSelectedGroupBy(target.value);

  const handleAveragedByChange = ({ target }) =>
    setSelectedAveragedBy(target.value);

  const handleToggleCumulative = () =>
    setIsCumulative(!isCumulative);

  const handleFromDateChange = (value) => setShowResultBoundaries({
    ...showResultBoundaries,
    fromDate: value,
  });

  const handleToDateChange = (value) => setShowResultBoundaries({
    ...showResultBoundaries,
    toDate: value,
  });

  if (isInitialized && !experimentData.length) {
    return (
      <span className="experiment-results__empty-state">
        {MESSAGES.experiment_no_data}
      </span>
    );
  }

  return (
    <div className="experiment-results">
      <ContentLoader isLoading={isLoadingInitial || isLoadingBoundaries}>
        <Grid container spacing={2}>
          <Grid container spacing={2} item xs={12}>
            <Grid item lg={2} sm={3} xs={6}>
              <FieldLabel
                label={MESSAGES.show}
                className="experiment-results__field"
              >
                <Select
                  label=""
                  name="experiment_metric"
                  options={values(EXPERIMENT_METRICS)}
                  value={selectedMetric}
                  onChange={handleMetricChange}
                />
              </FieldLabel>
            </Grid>
            <Grid item lg={2} sm={3} xs={6}>
              <FieldLabel
                label={MESSAGES.for_each}
                className="experiment-results__field"
              >
                <Select
                  label=""
                  name="experiment_group_by"
                  options={values(EXPERIMENT_AVERAGED_BY)}
                  value={selectedAveragedBy}
                  onChange={handleAveragedByChange}
                />
              </FieldLabel>
            </Grid>
            <Grid item lg={2} sm={3} xs={6}>
              <FieldLabel
                label={MESSAGES.by}
                className="experiment-results__field"
              >
                <Select
                  label=""
                  name="experiment_group_by"
                  options={values(EXPERIMENT_GROUP_BY)}
                  value={selectedGroupBy}
                  onChange={handleGroupByChange}
                />
              </FieldLabel>
            </Grid>
            <Grid item lg={2} sm={3} xs={6} display="flex" alignItems="flex-end">
              <div className="experiment-results__cumulative">
                <Checkbox
                  id="cumulative"
                  label={MESSAGES.cumulative}
                  value={values.is_cumulative}
                  onChange={() => handleToggleCumulative('is_cumulative')}
                />
              </div>
            </Grid>
            <Grid item lg={4} xs={12}>
              {experimentBoundaries.fromDate && experimentBoundaries.toDate && (
                <Stack
                  direction="row"
                  justifyContent={{ lg: 'flex-end', xs: 'flex-start' }}
                >
                  <Box>
                  <FieldLabel label={MESSAGES.from}>
                    <MonthPicker
                      withNavigation
                      value={showResultBoundaries.fromDate}
                      onChange={handleFromDateChange}
                      minDate={experimentBoundaries.fromDate.toDate()}
                      maxDate={moment(experimentBoundaries.toDate).add(-1, 'month').toDate()}
                    />
                  </FieldLabel>
                  </Box>
                  <div className="experiment-results__date-seperator-wrapper">
                    <div className="experiment-results__date-seperator" />
                  </div>
                  <Box>
                  <FieldLabel label={MESSAGES.to}>
                    <MonthPicker
                      withNavigation
                      value={showResultBoundaries.toDate}
                      onChange={handleToDateChange}
                      minDate={moment(showResultBoundaries.fromDate).add(1, 'month').toDate()}
                      maxDate={experimentBoundaries.toDate.toDate()}
                    />
                  </FieldLabel>
                  </Box>
                </Stack>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {!!selectedGroupBy
              && !!resultBoundaries.fromDate
              && !!resultBoundaries.toDate
              && !!groupResults.length
              && (
                <ExperimentGraph
                  groupBy={selectedGroupBy}
                  startDate={resultBoundaries.fromDate}
                  endDate={resultBoundaries.toDate}
                  groupResults={groupResults}
                  currency={selectedMetric.hasCurrency ? currency : null}
                />
              )}
          </Grid>
        </Grid>
      </ContentLoader>
    </div>
  );
};

ExperimentResults.propTypes = {
  experiment: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    started_date: PropTypes.string.isRequired,
    groups: PropTypes.arrayOf(PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
};

export default ExperimentResults;
