import { IdCell } from 'components/common/CustomersListTools/CustomerList/CustomerListCells';
import { NameLinkCell } from 'components/common/CommonTableCells';
import { DefaultCell, FormattedNumberCell } from 'components/common/Table/TableCells';
import { MESSAGES } from 'constants/messages';
import { titleWithCurrency } from 'utils/formatters';
import { ROUTES } from 'constants/routes';

export const defaultVisibleCategoryItems = {
  price_max: true,
  name: true,
  revenue: true,
  items_sold: true,
  profit: true,
  profit_margin: true,
  profit_margin_min: false,
  profit_margin_max: false,
  cost_min: false,
  cost_max: false,
  total_discounts: false,
  total_returns: false,
  price_min: true,
  discount_rate: false,
  return_rate: false,
  unique_products: true,
};

export const getCategoryListItems = (currency) => [{
  title: MESSAGES.id,
  key: 'id',
  ordering: ['id'],
  CellComponent: IdCell,
  componentProps: {},
  width: '150px',
  hasOverflowEllipsis: true,
}, {
  title: MESSAGES.name,
  key: 'name',
  ordering: ['name'],
  CellComponent: NameLinkCell,
  componentProps: {
    color: '#FFFFFF',
    routeFunc: (row) => ROUTES.productCategory.replace(':id', row.id),
  },
  width: '200px',
  hasOverflowEllipsis: true,
}, {
  title: titleWithCurrency(MESSAGES.revenue, currency),
  key: 'revenue',
  ordering: ['revenue'],
  CellComponent: FormattedNumberCell,
  componentProps: { prefix: true },
  width: '150px',
  hasOverflowEllipsis: false,
}, {
  title: titleWithCurrency(MESSAGES.profit, currency),
  key: 'profit',
  ordering: ['profit'],
  CellComponent: FormattedNumberCell,
  componentProps: { prefix: true },
  width: '150px',
  hasOverflowEllipsis: false,
}, {
  title: MESSAGES.items_sold,
  key: 'items_sold',
  ordering: ['items_sold'],
  CellComponent: DefaultCell,
  componentProps: {},
  width: '100px',
  hasOverflowEllipsis: false,
}, {
  title: MESSAGES.profit_margin,
  key: 'profit_margin',
  ordering: ['profit_margin'],
  CellComponent: FormattedNumberCell,
  componentProps: { perc: true },
  width: '150px',
  hasOverflowEllipsis: false,
}, {
  title: titleWithCurrency(MESSAGES.profit_margin_min, currency),
  key: 'profit_margin_min',
  ordering: ['profit_margin_min'],
  CellComponent: FormattedNumberCell,
  componentProps: { prefix: true },
  width: '150px',
  hasOverflowEllipsis: false,
}, {
  title: titleWithCurrency(MESSAGES.profit_margin_max, currency),
  key: 'profit_margin_max',
  ordering: ['profit_margin_max'],
  CellComponent: FormattedNumberCell,
  componentProps: { prefix: true },
  width: '150px',
  hasOverflowEllipsis: false,
}, {
  title: MESSAGES.unique_products,
  key: 'unique_products',
  ordering: ['unique_products'],
  CellComponent: DefaultCell,
  componentProps: {},
  width: '150px',
  hasOverflowEllipsis: false,
}, {
  title: MESSAGES.return_rate,
  key: 'return_rate',
  ordering: ['return_rate'],
  CellComponent: FormattedNumberCell,
  componentProps: { perc: true },
  width: '150px',
  hasOverflowEllipsis: false,
}, {
  title: MESSAGES.discount_rate,
  key: 'discount_rate',
  ordering: ['discount_rate'],
  CellComponent: FormattedNumberCell,
  componentProps: { perc: true },
  width: '150px',
  hasOverflowEllipsis: false,
}, {
  title: titleWithCurrency(MESSAGES.discounts, currency),
  key: 'total_discounts',
  ordering: ['total_discounts'],
  CellComponent: FormattedNumberCell,
  componentProps: { prefix: true },
  width: '150px',
  hasOverflowEllipsis: false,
}, {
  title: MESSAGES.items_returned,
  key: 'total_returns',
  ordering: ['total_returns'],
  CellComponent: FormattedNumberCell,
  componentProps: { prefix: true },
  width: '150px',
  hasOverflowEllipsis: false,
}, {
  title: titleWithCurrency(MESSAGES.cost_min, currency),
  key: 'cost_min',
  ordering: ['cost_min'],
  CellComponent: FormattedNumberCell,
  componentProps: { prefix: true },
  width: '150px',
  hasOverflowEllipsis: false,
}, {
  title: titleWithCurrency(MESSAGES.cost_max, currency),
  key: 'cost_max',
  ordering: ['cost_max'],
  CellComponent: FormattedNumberCell,
  componentProps: { prefix: true },
  width: '150px',
  hasOverflowEllipsis: false,
}, {
  title: titleWithCurrency(MESSAGES.price_min, currency),
  key: 'price_min',
  ordering: ['price_min'],
  CellComponent: FormattedNumberCell,
  componentProps: { prefix: true },
  width: '150px',
  hasOverflowEllipsis: false,
}, {
  title: titleWithCurrency(MESSAGES.price_max, currency),
  key: 'price_max',
  ordering: ['price_max'],
  CellComponent: FormattedNumberCell,
  componentProps: { prefix: true },
  width: '150px',
  hasOverflowEllipsis: false,
}];
