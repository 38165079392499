import { useState, useEffect } from 'react';
import moment from 'moment';
import { groupBy, keys } from 'lodash';
import {
  barChartColorSet,
  currentMonthChartColor, barChartFallbackColor,
  getMonthChartBackground,
} from 'components/views/Homepage/KpiHistoryDataBlock/const';
import { getHomeData, getMetrics } from 'api/homeAPI';

export default (selectedKpi) => {
  const [barChartData, setBarChartData] = useState();

  const getBarChartBackgroundColors = (year, color) => {
    const isCurrentYear = moment().format('YYYY') === year;
    const backgroundColors = Array(12).fill(getMonthChartBackground(color));

    if (isCurrentYear) {
      const curMonth = moment().month();
      backgroundColors[curMonth] = currentMonthChartColor(color);
    }

    return backgroundColors;
  };

  const getDatasetData = (yearlyData) => {
    const result = Array(12).fill(0);

    yearlyData.forEach((item) => {
      const month = moment(item.date).month();
      result[month] = item[selectedKpi.key];
    });

    return result;
  };

  const calculateDatasets = (data) => {
    const groupedByYear = groupBy(data, (item) => moment(item.date).format('YYYY'));
    const years = keys(groupedByYear);

    return years.map((year, idx) => {
      const color = barChartColorSet[years.length - 1 - idx] || barChartFallbackColor;

      return {
        data: getDatasetData(groupedByYear[year]),
        label: year,
        fill: false,
        backgroundColor: getBarChartBackgroundColors(year, color),
        borderColor: color,
        pointHoverBackgroundColor: color,
        borderWidth: 1,
      };
    });
  };

  useEffect(() => {
    const populateData = async () => {
      let result = null;
      if (!selectedKpi.platform) {
        result = await getHomeData({ fields: [selectedKpi.key, 'date'] });
      } else {
        result = await getMetrics({ platform: selectedKpi.platform, fields: [selectedKpi.key, 'date'] });
      }

      const datasets = calculateDatasets(result);

      setBarChartData({
        ...selectedKpi,
        datasets,
      });
    };

    selectedKpi ? populateData() : setBarChartData(null);
  }, [selectedKpi]);

  return { barChartData };
};
