import { DETAIL_STEPS } from 'constants/common';
import PersonalDetails from './PersonalDetails';
import CompanyDetails from './CompanyDetails';
import personalValidation from './PersonalDetails/personalValidation';
import companyValidation from './CompanyDetails/companyValidation';
import { updatePersonalDetails } from 'api/usersAPI';
import {
  createLicense, switchLicense, updateLicense,
} from 'api/licenseAPI';
import { convertedRoleOptions } from 'utils/common';

export const prepareLicenseFromJSON = (licenseJSON) => {
  try {
    const license = JSON.parse(licenseJSON);
    const defaultBusinessCategory = license?.business_category
      && convertedRoleOptions([license?.business_category])[0];
    const defaultCompanySize = license?.company_size
      && convertedRoleOptions([license?.company_size])[0];

    return {
      uuid: license?.uuid,
      package: license?.package,
      customer: license?.customer,
      companyName: license?.company_name,
      businessCategory: defaultBusinessCategory,
      companyURL: license?.company_url,
      companySize: defaultCompanySize,
    };
  } catch (error) {
    return {};
  }
};

export const submitPersonalDetails = async ({
  firstName,
  avatar,
  lastName,
  workRole,
}, setErrors) => {
  const requestBody = {
    first_name: firstName,
    last_name: lastName,
    role: workRole.value,
    avatar,
  };
  try {
    return await updatePersonalDetails(requestBody);
  } catch (e) {
    const errorsResponse = {
      firstName: {
        header: e.first_name[0],
      },
      lastName: {
        header: e.last_name[0],
      },
      workRole: {
        header: e.role[0],
      },
    };

    setErrors(errorsResponse);
    return null;
  }
};

export const submitCompanyDetails = async ({
  companyName, businessCategory, companyURL, companySize, uuid,
}, setAdmin = true) => {
  const body = {
    company_name: companyName,
    business_category: businessCategory.value,
    company_url: companyURL,
    company_size: companySize.value,
  };

  try {
    if (uuid) {
      const updatedLicense = await updateLicense({ ...body, uuid });
      return { license: updatedLicense };
    } else {
      const license = await createLicense({ ...body, set_empty: !setAdmin });
      return await switchLicense({ licenseUuid: license.uuid });
    }
  } catch (e) {
    return null;
  }
};

export const renderStepComponent = (step) => {
  switch (step) {
    case DETAIL_STEPS.personal:
      return PersonalDetails;
    case DETAIL_STEPS.company:
      return CompanyDetails;
    default:
      return null;
  }
};

export const renderStepValidation = (step) => {
  switch (step) {
    case DETAIL_STEPS.personal:
      return personalValidation;
    case DETAIL_STEPS.company:
      return companyValidation;
    default:
      return null;
  }
};

export const submitHandlerByStep = (step) => {
  switch (step) {
    case DETAIL_STEPS.personal:
      return submitPersonalDetails;
    case DETAIL_STEPS.company:
      return submitCompanyDetails;
    default:
      return null;
  }
};
