import React from 'react';
import propTypes from 'prop-types';

import './styles.scss';

const DataRange = ({ totalCount, from, to }) => (
  <span className="date-range">
    {totalCount > 0 ? `Showing ${from}-${to} of ` : `Showing 0-${to} of `}
    <span className="date-range__total">{totalCount}</span>
  </span>
);

DataRange.propTypes = {
  totalCount: propTypes.number.isRequired,
  from: propTypes.number.isRequired,
  to: propTypes.number.isRequired,
};

export default DataRange;
