import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import 'components/views/Admin/SuperUsers/ManageLicensesModal/styles.scss';
import Button from 'components/common/Button';
import { AVATAR_TYPE, BUTTON_TYPE } from 'constants/common';
import { MESSAGES } from 'constants/messages';
import propTypes from 'prop-types';
import { changeSuperUserLicenseAccess, getSuperUserLicenseAccessList } from 'api/superUserAPI';
import { getLicensesList } from 'api/licenseAPI';
import {
  CreatedAtCell, NameCell,
  RemoveUserCell,
} from 'components/views/Admin/SuperUsers/ManageLicensesModal/SuperUserLicenseAccessCells/tableCellComponent';
import useAutosuggest from 'hooks/useAutosuggest';
import Autosuggest from 'components/common/Autosuggest';
import Table from 'components/common/Table';
import Avatar from 'components/common/Avatar';

const getTableConfig = (onRemoveClick) => [{
  title: 'License',
  key: 'license_name',
  ordering: ['license__company_name'],
  CellComponent: NameCell,
  componentProps: {},
  width: '',
  hasOverflowEllipsis: true,
}, {
  title: 'Date granted',
  key: 'created_at',
  ordering: ['created_at'],
  CellComponent: CreatedAtCell,
  componentProps: {},
  width: '180px',
  hasOverflowEllipsis: true,
}, {
  title: 'Action',
  key: null,
  ordering: null,
  CellComponent: RemoveUserCell,
  componentProps: { onClick: onRemoveClick },
  width: '60px',
  hasOverflowEllipsis: false,
}];

const ManageLicensesModal = ({
  // eslint-disable-next-line react/prop-types
  onClose, superUser,
}) => {
  const [data, setData] = useState([]);
  const [ordering, setOrdering] = useState([]);

  const changeAccess = async (license_uuids) => {
    await changeSuperUserLicenseAccess(superUser.uuid, license_uuids);
    setOrdering([...ordering]);
  };

  const onRemoveClick = async (x) => {
    const selectedForRevoking = x.row.license_uuid;
    const license_uuids = data.map((item) => item.license_uuid).filter((uuid) => uuid !== selectedForRevoking);
    await changeAccess(license_uuids);
  };
  const tableConfig = getTableConfig(onRemoveClick);
  const getSuggestionValue = (suggestion) => suggestion.company_name;
  const handleSortChange = (changed_ordering) => {
    setOrdering(changed_ordering);
  };
  const suggestionsRequest = async (inputValue) => {
    const response = await getLicensesList({ search: inputValue });
    return response.results;
  };

  const {
    suggestions,
    onClearInputRequested,
    onSuggestionsFetchRequested,
    onSuggestionsClearRequested,
    value,
    onChange,
    cleanUpValue,
  } = useAutosuggest(suggestionsRequest);

  const addLicenseToSuperUser = async (selected_license) => {
    const license_uuids = [...data.map((item) => item.license_uuid), selected_license.uuid];
    await changeAccess(license_uuids);
  };

  const newSelectedValues = (value) => {
    addLicenseToSuperUser(value);
    cleanUpValue();
  };

  const selected = (event, { suggestion }) => newSelectedValues(suggestion);

  useEffect(() => {
    const updateData = async () => {
      const response = await getSuperUserLicenseAccessList(superUser.uuid, ordering);
      setData(response);
    };
    if (superUser.uuid) {
      updateData();
    }
  }, [superUser, ordering]);

  const autosuggestInputProps = {
    placeholder: MESSAGES.search_by_license_name,
    value,
    onChange,
  };

  return (
    <Popup
      modal
      open={!!superUser.uuid}
      position="center center"
      onClose={onClose}
    >

      {() => (
        <div className="confirmation-window__popup">
          <div className="confirmation-window">
            <div className="confirmation-window__container">
              <div className="manage-license--title">{MESSAGES.manage_license}</div>
              <div className="manage-license--sub-heading">{MESSAGES.manage_license_sub_header}</div>

              <div className="user-header">{MESSAGES.user}</div>
              {superUser.uuid && (
                <div className="user-info">
                  {superUser.user.avatar ? (
                    <img className="new-avatar" src={superUser.user.avatar} alt="avatar" />
                  ) : (
                    <Avatar type={AVATAR_TYPE.small} />
                  )}
                  <div className="user-name">
                    {`${superUser.user.first_name} ${superUser.user.last_name}`}
                  </div>
                </div>
              )}
              {/* Used to capture default autofocus */}
              <input type="hidden" />
              <Autosuggest
                suggestions={suggestions}
                onClearInputRequested={onClearInputRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                getSuggestionValue={getSuggestionValue}
                onSuggestionSelected={selected}
                inputProps={autosuggestInputProps}
              />
              <div className="license__table">
                <Table
                  data={data}
                  tableConfig={tableConfig}
                  initSort={['created_at']}
                  sortChanged={handleSortChange}
                  highlightStartIdx={0}
                  highlightEndIdx={2}
                />
              </div>
            </div>
            <div className="Line-30" />
            <form className="main__form form">
              <div className="form__actions">
                <div className="form__cancel">
                  <Button
                    block
                    isPreventSubmit
                    onClick={onClose}
                    type={BUTTON_TYPE.secondary}
                  >
                    {MESSAGES.close}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </Popup>
  );
};

export default ManageLicensesModal;

ManageLicensesModal.propTypes = {
  superUser: propTypes.objectOf(propTypes.any).isRequired,
  onClose: propTypes.func.isRequired,
};
