import React, { useState } from 'react';
import useListApi from 'hooks/useListApi';
import { BUTTON_TYPE, SUPER_USERS_PER_PAGE } from 'constants/common';
import { ActionsCell, DefaultCell, IdCell } from 'components/common/Table/TableCells';
import { AvatarCell } from 'components/views/Admin/AdminCells';
import { getSuperUsersList } from 'api/superUserAPI';
import { NameCell, PermissionCell } from 'components/views/Admin/SuperUsers/SuperUserCells';
import InviteSuperUserModal from 'components/views/Admin/SuperUsers/InviteSuperUserModal';
import DeleteSuperUserModal from 'components/views/Admin/SuperUsers/DeleteSuperUserModal';
import ManageLicensesModal from 'components/views/Admin/SuperUsers/ManageLicensesModal';
import { MESSAGES } from 'constants/messages';
import { useSelector } from 'react-redux';
import { getCurrentUser } from 'store/profile/getters';
import { Grid, Stack } from '@mui/material';
import Search from 'components/common/Search';
import Button from 'components/common/Button';
import PaginatedTable from 'components/common/Table/PaginatedTable';
import Block from 'components/common/Block';
import { LIST_API_REDUX_KEYS } from 'constants/listApi';

const getTableConfig = (actions) => [{
  title: null,
  key: 'user.avatar',
  ordering: null,
  CellComponent: AvatarCell,
  componentProps: {},
  width: '40px',
  hasOverflowEllipsis: false,
}, {
  title: 'User',
  key: 'user.name',
  ordering: ['user__first_name', 'user__last_name'],
  CellComponent: NameCell,
  componentProps: {},
  width: '200px',
  hasOverflowEllipsis: true,
}, {
  title: 'ID',
  key: 'uuid',
  ordering: ['uuid'],
  CellComponent: IdCell,
  componentProps: {},
  width: '25%',
  hasOverflowEllipsis: true,
}, {
  title: 'Email',
  key: 'user.email',
  ordering: ['user__email'],
  CellComponent: DefaultCell,
  componentProps: {},
  width: '200px',
  hasOverflowEllipsis: true,
}, {
  title: 'Permission level',
  key: 'permission',
  ordering: ['permission'],
  CellComponent: PermissionCell,
  componentProps: {},
  width: '110px',
  hasOverflowEllipsis: false,
}, {
  title: null,
  key: null,
  ordering: null,
  CellComponent: ActionsCell,
  componentProps: { actions },
  width: '40px',
  hasOverflowEllipsis: false,
}];

const SuperUsers = () => {
  const {
    data,
    totalCount,
    isDataLoading,
    config,
    changeConfig,
  } = useListApi(
    LIST_API_REDUX_KEYS.adminPanelSuperUsers,
    { ordering: ['user__first_name', 'user__last_name'] },
    getSuperUsersList,
  );

  const [isInviteModalOpened, setIsInviteModalOpened] = useState(false);
  const [superUserForDeletion, setSuperUserForDeletion] = useState({});
  const [manageLicenseAccessUser, setSuperUserForManaging] = useState({});

  const handleSortChange = (ordering) => changeConfig({ ordering });
  const handlePageChange = (page) => changeConfig({ page });
  const handleSearchChange = (search) => changeConfig({ search });

  const inviteSuperUser = () => setIsInviteModalOpened(true);
  const handleInviteSubmit = () => {
    setIsInviteModalOpened(false);
    changeConfig({ page: 1 });
  };

  const handleDeleteSuperUserSubmit = () => {
    setSuperUserForDeletion({});
    changeConfig({ page: 1 });
  };

  const manageLicense = (item) => {
    setSuperUserForManaging(item);
  };

  const currentUser = useSelector(getCurrentUser);

  const actions = [{
    name: 'Edit license access',
    action: (x) => manageLicense(x.row),
  }, {
    name: 'Delete User',
    action: (x) => setSuperUserForDeletion(x.row),
    shouldShow: (x) => x.row.user.uuid !== currentUser.uuid,
  }];

  const tableConfig = getTableConfig(actions);

  return (
    <>
      <Block header={MESSAGES.super_users}>
        <Grid container rowSpacing={2}>
          <Grid item container spacing={2} xs={12}>
            <Grid item lg={4} md={6} xs={12}>
              <Search
                search={config.search}
                title={MESSAGES.search_by_name_email}
                searchChanged={handleSearchChange}
              />
            </Grid>
            <Grid item lg={8} md={6} xs={12}>
              <Stack alignItems="flex-end">
                <Button
                  type={BUTTON_TYPE.primary}
                  onClick={inviteSuperUser}
                >
                  {MESSAGES.invite_super_user}
                </Button>
              </Stack>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <PaginatedTable
              sort={config.ordering}
              sortChanged={handleSortChange}
              totalCount={totalCount}
              tableConfig={tableConfig}
              data={data}
              highlightStartIdx={0}
              highlightEndIdx={5}
              countPerPage={SUPER_USERS_PER_PAGE}
              page={config.page}
              pageChanged={handlePageChange}
              isDataLoading={isDataLoading}
            />
          </Grid>
        </Grid>
      </Block>
      <InviteSuperUserModal
        isOpened={isInviteModalOpened}
        onClose={() => setIsInviteModalOpened(false)}
        onSubmit={handleInviteSubmit}
      />
      <DeleteSuperUserModal
        superUser={superUserForDeletion}
        onClose={() => setSuperUserForDeletion({})}
        onSubmit={handleDeleteSuperUserSubmit}
      />
      <ManageLicensesModal
        onClose={() => setSuperUserForManaging({})}
        superUser={manageLicenseAccessUser}
      />
    </>
  );
};

SuperUsers.propTypes = {};

export default SuperUsers;
