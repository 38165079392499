import { getUsersList } from 'api/usersAPI';
import { getAvailableIntegrationsSources, getChannels } from 'api/integrationsAPI';
import {
  ENTITY_INTEGRATION,
  ENTITY_LICENSE,
  ENTITY_USER,
  ENTITY_CHANNEL,
  ENTITY_SEGMENT,
  ENTITY_PERFORMANCE,
  ENTITY_CATEGORY,
  ENTITY_DETAIL,
  ENTITY_PRODUCT_SEGMENT,
  ENTITY_CATEGORY_PERFORMANCE,
  CUSTOMER_PERFORMANCE,
  CUSTOMER_DETAILS,
  CUSTOMER_EVENTS,
  ENTITY_CREATOR,
} from 'constants/entities';
import { getLicensesList } from 'api/licenseAPI';
import { getSegmentsInfo } from 'api/segmentsAPI';
import { getAssetSourceImage } from 'utils/assets';
import { getProductCategories, getProductsSegments } from 'api/productsAPI';
import {
  PERFORMANCE_FIELDS,
  DETAILS_FIELDS,
  CATEGORY_PERFORMANCE_FIELDS,
  CUSTOMER_PERFORMANCE_FIELDS,
  CUSTOMER_DETAILS_FIELDS,
  CUSTOMER_EVENT_FIELDS,
} from 'components/common/SearchEntity/constants';
import { PRODUCT_SEGMENTS_COLORS } from 'constants/colors';

const RESULTS_LIMIT = 10;

const usersDataFunction = async (search, page = 1) => {
  const response = await getUsersList({
    page_size: RESULTS_LIMIT,
    ordering: 'first_name,last_name',
    search,
    page,
  });
  return response.results.map((item) => ({
    uuid: item.uuid,
    image: item.avatar || '/static/img/defaultAvatar.svg',
    name: `${item.first_name} ${item.last_name}`.trim() || item.email,
  }));
};

/**
 * Dictionary of functions to get the data for SearchEntity component.
 * Each function will be automatically memoized.
 *
 * Function must follow a list of requirements:
 * 1) Should be async function
 * 2) First param is a required search string.
 *    Second param is an optional page int for infinite scroll.
 * 3) it should return list of {uuid, image, color, name} dicts.
 *    If image is null then color is taken. If color is also null then nothing is shown before option.
 * 4) results number should be limited to RESULTS_LIMIT
 */
const dataFunctionsConfig = {
  [ENTITY_USER]: usersDataFunction,
  [ENTITY_CREATOR]: usersDataFunction,
  [ENTITY_INTEGRATION]: async (search) => {
    const response = await getAvailableIntegrationsSources(search);
    return response.map((item) => ({
      uuid: item.uuid,
      image: getAssetSourceImage(item.key, true),
      name: item.name,
    }));
  },
  [ENTITY_LICENSE]: async (search, page = 1) => {
    const response = await getLicensesList({
      page_size: RESULTS_LIMIT,
      ordering: '-created_at',
      search,
      page,
    });
    return response.results.map((item) => ({
      uuid: item.uuid,
      image: item.avatar || '/static/img/companyDefaultLogo.svg',
      name: item.company_name,
    }));
  },
  [ENTITY_CHANNEL]: async (search) => {
    const response = await getChannels(search);
    return response.map((item) => ({
      uuid: item.uuid,
      image: getAssetSourceImage(item.source.key, true),
      name: item.source.name,
    }));
  },
  [ENTITY_SEGMENT]: async (search) => {
    const response = await getSegmentsInfo(search);
    return response.map((item) => ({
      uuid: item.id,
      color: item.color_1,
      name: item.segment_name,
    }));
  },
  [ENTITY_CATEGORY]: async (search, page = 1) => {
    const response = await getProductCategories({ search, page, page_size: RESULTS_LIMIT, ordering: 'name' });
    return response.results?.map((item) => ({
      uuid: item.id,
      name: item.name,
    }));
  },
  [ENTITY_PERFORMANCE]: async (search) => {
    const values = PERFORMANCE_FIELDS.filter((item) => (
      item.title.toLowerCase().includes(search.trim().toLowerCase())
    ));
    return values.map((item) => ({
      uuid: item.key,
      name: item.title,
    }));
  },
  [ENTITY_DETAIL]: async (search) => {
    const values = DETAILS_FIELDS.filter((item) => (
      item.title.toLowerCase().includes(search.trim().toLowerCase())
    ));
    return values.map((item) => ({
      uuid: item.key,
      name: item.title,
    }));
  },
  [ENTITY_PRODUCT_SEGMENT]: async (search) => {
    const response = await getProductsSegments(search);
    return response.map((item) => ({
      uuid: item.id,
      color: PRODUCT_SEGMENTS_COLORS[item.id],
      name: item.product_segment,
    }));
  },
  [ENTITY_CATEGORY_PERFORMANCE]: async (search) => {
    const values = CATEGORY_PERFORMANCE_FIELDS.filter((item) => (
      item.title.toLowerCase().includes(search.trim().toLowerCase())
    ));
    return values.map((item) => ({
      uuid: item.key,
      name: item.title,
    }));
  },
  [CUSTOMER_PERFORMANCE]: async (search) => {
    const values = CUSTOMER_PERFORMANCE_FIELDS.filter((item) => (
      item.title.toLowerCase().includes(search.trim().toLowerCase())
    ));
    return values.map((item) => ({
      uuid: item.key,
      name: item.title,
    }));
  },
  [CUSTOMER_DETAILS]: async (search) => {
    const values = CUSTOMER_DETAILS_FIELDS.filter((item) => (
      item.title.toLowerCase().includes(search.trim().toLowerCase())
    ));
    return values.map((item) => ({
      uuid: item.key,
      name: item.title,
    }));
  },
  [CUSTOMER_EVENTS]: async (search) => {
    const values = CUSTOMER_EVENT_FIELDS.filter((item) => (
      item.title.toLowerCase().includes(search.trim().toLowerCase())
    ));
    return values.map((item) => ({
      uuid: item.key,
      name: item.title,
    }));
  },
};

export default dataFunctionsConfig;
