import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import propTypes from 'prop-types';

import 'components/views/Settings/LicenseSettings/License/styles.scss';
import licencePropTypes from 'components/views/Settings/LicenseSettings/License/types';
import companyValidation from 'containers/Authorization/Details/CompanyDetails/companyValidation';
import useAsync from 'hooks/useAsync';
import useForm from 'hooks/useForm';
import Input from 'components/common/Input';
import Select from 'components/common/Select';
import Button from 'components/common/Button';
import { BUTTON_TYPE, COMPANY_NAME_SIZE, REQUEST_STATUS } from 'constants/common';
import { ENDPOINTS } from 'constants/routes';
import CompanyName from 'components/common/CompanyName';
import usePrompt from 'hooks/usePrompt';
import ConfirmationWindow from 'components/common/ConfirmationWindow';
import ConfirmationCancelButton from 'components/views/Profile/PersonalBlock/ConfirmationCancelButton';
import { isObjectHasChanged, convertedRoleOptions } from 'utils/common';
import useOptions from 'hooks/useOptions';
import DeleteLicense from 'components/views/Settings/LicenseSettings/License/DeleteLicense';
import { updateLicense } from 'api/licenseAPI';
import { getIsLicenseAdmin, getLicenseDetails } from 'store/license/getters';
import { updateLicenseAction } from 'store/license/licenseSlice';
import classNames from 'classnames';
import { ReactComponent as DownloadIcon } from 'assets/svg/dowmnoad.svg';
import { IMAGE_PATTERN } from 'constants/validation';
import CropperImage from 'components/views/Profile/CropperImage';
import { MESSAGES } from 'constants/messages';
import { Box, Grid, Stack } from '@mui/material';

const EditMode = ({
  companyName: companyNameProps,
  businessCategory: businessCategoryProps,
  companyURL: companyURLProps,
  companySize: companySizeProps,
  cancelEditMode,
  avatarImg,
}) => {
  const dispatch = useDispatch();
  const currentLicense = useSelector(getLicenseDetails);
  const isAdministrator = useSelector(getIsLicenseAdmin);
  const [avatar, setAvatar] = useState({});
  const [cropAvatar, setCropAvatar] = useState(false); const [img, setImg] = useState('');
  const [errorUpload, setErrorUpload] = useState('');
  const [isHint, setIsHint] = useState(false);

  const {
    options: businessCategoryOptions,
    status: businessCategoryStatus,
  } = useOptions(ENDPOINTS.businessCategory, 'business_categories', true);

  const {
    options: companySizeOptions,
    status: companySizeStatus,
  } = useOptions(ENDPOINTS.companySize, 'company_sizes', true);

  const submitLicenseHandler = async ({
    companyName, businessCategory, companyURL, companySize,
  }) => {
    const requestBody = {
      company_name: companyName,
      business_category: businessCategory.value,
      company_url: companyURL,
      company_size: companySize.value,
      avatar: avatar?.avatar?.file || avatar?.avatar?.show,
    };

    try {
      const license = await updateLicense({
        uuid: currentLicense.license.uuid,
        ...requestBody,
      });

      if (license) {
        dispatch(updateLicenseAction(license));
        cancelEditMode();
      }

      return license;
    } catch (e) {
      return null;
    }
  };

  useEffect(() => {
    setAvatar({
      avatar: {
        show: avatarImg,
      },
    });
  }, [avatarImg]);

  const defaultBusinessCategory = businessCategoryProps
        && convertedRoleOptions([businessCategoryProps])[0];
  const defaultCompanySize = companySizeProps
        && convertedRoleOptions([companySizeProps])[0];

  const initFormValues = {
    companyName: companyNameProps,
    companyURL: companyURLProps,
    businessCategory: defaultBusinessCategory,
    companySize: defaultCompanySize,
    avatar,
  };

  const { execute, status } = useAsync(submitLicenseHandler);

  const {
    handleSubmit, handleChange, handleBlur, values, errors, touched,
  } = useForm(
    execute,
    companyValidation,
    initFormValues,
  );

  const hasFormChanged = isObjectHasChanged(initFormValues, values);

  const {
    isBlocking, onCancel, onConfirm, preventBlocking,
  } = usePrompt(hasFormChanged);

  const updateLicenseDetails = (e) => {
    if (!hasFormChanged) {
      cancelEditMode();
      return;
    }

    handleSubmit(e);
  };

  const uploadAvatar = (event) => {
    if (event.target.files && event.target.files.length > 0 && event.target.files[0].size < 5242880) {
      if (IMAGE_PATTERN.test(event.target.value)) {
        setErrorUpload('');
        const reader = new FileReader();
        reader.onload = (e) => {
          setAvatar({
            avatar: {
              // ...this.props.data.avatar,
              avatarToCrop: e.target.result,
            },
            changed: true,
          });

          setImg(e.target.value);
          setCropAvatar(true);
        };
        reader.readAsDataURL(event.target.files[0]);
      } else {
        setErrorUpload('Your attachment has wrong format.');
      }
    } else {
      setErrorUpload('Your attachment is too big to send. Try sending a file smaller than 5MB.');
    }
  };

  const deleteAvatar = () => {
    setAvatar({
      avatar: null,
    });
    setImg('');
  };

  const closeAvatarCropper = () => {
    setCropAvatar(false);
    setImg('');
  };

  const croppedAvatar = (show, file) => {
    setCropAvatar(false);
    setAvatar({
      avatar: {
        show,
        file,
      },
    });
  };

  return (
    <>
      <ConfirmationWindow
        content={MESSAGES.leave_the_page}
        open={isBlocking}
        onCancel={onCancel}
        onConfirm={onConfirm}
        forceBlocking={false}
        inWorkArea
      />
      <div className="license-settings__license-edit">
        <Grid container spacing={2}>
          <Grid item md={2} xs={12}>
            <div className="license-settings__license-edit__avatar">
              <div className="upload-avatar__block">
                <div className="upload-avatar-wrapper">
                  <CompanyName
                    size={COMPANY_NAME_SIZE.large}
                    src={(!!avatar && !!avatar.avatar && !!avatar.avatar.show) ? avatar.avatar.show : null}
                    onlyLogo
                  />
                  <div className={classNames('upload-block', { error: !!errorUpload })}>
                    {' '}
                    <DownloadIcon />
                    {!!errorUpload && (
                      <div className={classNames('hint', { errorHint: isHint })}>
                        <span>
                          {errorUpload}
                        </span>
                      </div>
                    )}
                    <input
                      type="file"
                      title=""
                      className="file-input"
                      value={img}
                      accept="image/png, image/jpeg, image/jpg,"
                      onChange={uploadAvatar}
                      onMouseOver={() => setIsHint(true)}
                      onMouseOut={() => setIsHint(false)}
                    />
                  </div>
                  <p className="profile__avatar-formats">
                    {MESSAGES.image_formats}
                  </p>
                  <p className="profile__avatar-size">
                    {MESSAGES.image_max_size}
                  </p>
                  {!!avatar && !!avatar.avatar && !!avatar.avatar.show && (
                    <div className="remove-avatar" onClick={deleteAvatar}>
                      {MESSAGES.remove_photo}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Grid>
          <Grid item container md={10} xs={12}>
            <form
              className="license-settings__form main__form form"
              onSubmit={updateLicenseDetails}
              noValidate
            >
              <Grid container spacing={2}>
                <Grid item xl={9} xs={12} container spacing={4}>
                  <Grid item md={6} xs={12}>
                    <Input
                      id="companyName"
                      name="companyName"
                      label={MESSAGES.company_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="given-name"
                      value={values.companyName}
                      error={errors.companyName}
                      touched={touched.companyName}
                      isRequired
                      hasFeedback
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Input
                      id="companyURL"
                      name="companyURL"
                      label={MESSAGES.company_url}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.companyURL}
                      error={errors.companyURL}
                      touched={touched.companyURL}
                      autoComplete="url"
                      isRequired
                      hasFeedback
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Select
                      name="businessCategory"
                      label={MESSAGES.business_category}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={businessCategoryOptions}
                      defaultValue={defaultBusinessCategory}
                      error={errors.businessCategory}
                      touched={touched.businessCategory}
                      isLoading={businessCategoryStatus === REQUEST_STATUS.loading}
                      isRequired
                      hasFeedback
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Select
                      name="companySize"
                      label={MESSAGES.company_size}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={companySizeOptions}
                      defaultValue={defaultCompanySize}
                      error={errors.companySize}
                      touched={touched.companySize}
                      isLoading={companySizeStatus === REQUEST_STATUS.loading}
                      isRequired
                      hasFeedback
                    />
                  </Grid>
                </Grid>
                <Grid item xl={3} xs={12} container spacing={4}>
                  <Grid item lg={12} sm={6} xs={12}>
                    <Box
                      justifyContent={{ lg: 'flex-end', xs: 'flex-start' }}
                      flexDirection="row"
                      display="flex"
                    >
                      {isAdministrator && (
                        <DeleteLicense
                          onCancel={() => {}}
                          onConfirm={preventBlocking}
                        />
                      )}
                    </Box>
                  </Grid>
                  <Grid item lg={12} sm={6} xs={12}>
                    <Box
                      justifyContent={{ sm: 'flex-end', xs: 'flex-start' }}
                      flexDirection="row"
                      display="flex"
                      height="100%"
                    >
                      <Stack spacing={1} direction="row">
                        {hasFormChanged ? (
                          <ConfirmationCancelButton
                            onConfirm={cancelEditMode}
                            status={status}
                          />
                        ) : (
                          <Button
                            type={BUTTON_TYPE.secondary}
                            onClick={cancelEditMode}
                            isPreventSubmit
                            isDisabled={REQUEST_STATUS.loading === status}
                          >
                            {MESSAGES.cancel}
                          </Button>
                        )}
                        <Button
                          type={BUTTON_TYPE.primary}
                          isDisabled={REQUEST_STATUS.loading === status}
                          block
                        >
                          {MESSAGES.save}
                        </Button>
                      </Stack>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </div>
      <CropperImage
        open={cropAvatar}
        avatar={(!!avatar && !!avatar.avatar) ? avatar.avatar.avatarToCrop : null}
        cropAvatar={croppedAvatar}
        closeAvatarCropper={closeAvatarCropper}
        uploadAvatar={uploadAvatar}
      />
    </>
  );
};

EditMode.propTypes = {
  ...licencePropTypes,
  cancelEditMode: propTypes.func.isRequired,
};

export default EditMode;
