import React from 'react';

import './styles.scss';
import Button from 'components/common/Button';
import { BUTTON_TYPE } from 'constants/common';
import useRouter from 'hooks/useRouter';
import { ROUTES } from 'constants/routes';
import { MESSAGES } from 'constants/messages';

const NoLicensesModal = () => {
  const { replace } = useRouter();
  const addLicenseHandler = () => replace(ROUTES.details);

  return (
    <div className="switch-license-modal switch-license__modal--no-licenses">
      <strong className="switch-license-modal__title">{MESSAGES.oops}</strong>
      <p className="switch-license-modal__content">
        {MESSAGES.you_dont_belong_to_any_licenses}
        <br />
        {MESSAGES.contact_administrator_or_create_new_license}
      </p>
      <div className="switch-license-modal__actions">
        <div className="switch-license-modal__add-license-button">
          <Button
            type={BUTTON_TYPE.primary}
            block
            onClick={addLicenseHandler}
          >
            {MESSAGES.add_license}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NoLicensesModal;
