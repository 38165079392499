import React from 'react';
import propTypes from 'prop-types';
import 'components/views/Settings/Settings/BillingDetails/Billing/CardInfo/styles.scss';
import { AVAILABLE_CARDS } from 'constants/card';
import Grid from '@mui/material/Grid';
import { Stack } from '@mui/material';

const CardInfo = ({
  brand, cardNumber, expirationDate,
}) => {
  const CardIconByBrand = AVAILABLE_CARDS[brand];

  return (
    <Grid container>
      <Stack direction="row" spacing={2}>
        <CardIconByBrand />
        <Grid container item>
          <Grid item md={12} xs={12}>
            <div className="card_text">
              {' '}
              {brand}
              {' '}
              ending in
              {' '}
              {cardNumber}
            </div>
          </Grid>
          <Grid item md={12} xs={12}>
            <div className="expDate">
              Expiry
              {' '}
              {expirationDate}
            </div>
          </Grid>
        </Grid>
      </Stack>
    </Grid>
  );
};

CardInfo.propTypes = {
  brand: propTypes.string.isRequired,
  cardNumber: propTypes.string.isRequired,
  expirationDate: propTypes.string.isRequired,
};

export default CardInfo;
