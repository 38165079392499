import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  createCohort,
  deleteCohort,
  exportCohortData,
  getCohort,
  updateCohort,
} from 'api/cohortsAPI';
import './styles.scss';
import { setCohortName } from 'store/crumbsIdToName/crumbsIdToNameSlice';
import { useDispatch, useSelector } from 'react-redux';
import Block from 'components/common/Block';
import { MESSAGES } from 'constants/messages';
import {
  Box, Grid, Stack,
} from '@mui/material';
import { getTagsFromFilters, prepareTagsAsFilterArray } from 'utils/filters';
import validation from 'components/views/Cohort/cohortValidation';
import useAsync from 'hooks/useAsync';
import useForm from 'hooks/useForm';
import Input from 'components/common/Input';
import TextArea from 'components/common/TextArea';
import RadioSelect from 'components/common/RadioSelect';
import {
  CUSTOMER_DETAILS,
  CUSTOMER_EVENTS,
  CUSTOMER_PERFORMANCE, ENTITY_SEGMENT,
} from 'constants/entities';
import {
  CUSTOMER_DETAILS_FIELDS,
  CUSTOMER_EVENT_FIELDS,
  CUSTOMER_PERFORMANCE_FIELDS,
} from 'components/common/SearchEntity/constants';
import tagHelper from 'components/common/AdvancedFilterTag/tagHelper';
import { getCustomerFilterList } from 'api/customersAPI';
import SearchEntity from 'components/common/SearchEntity';
import { ToggleSearchEntity } from 'components/common/TableSearchEntity';
import AdvancedFilterTag from 'components/common/AdvancedFilterTag';
import MonthPicker from 'components/common/MonthPicker';
import Select from 'components/common/Select';
import { BUTTON_TYPE, INPUT_VALUE_TYPE } from 'constants/common';
import Button from 'components/common/Button';
import { ROUTES } from 'constants/routes';
import useRouter from 'hooks/useRouter';
import moment from 'moment';
import { isEmpty, toNumber } from 'lodash';
import CohortTable from 'components/views/Cohort/CohortTable';
import Checkbox from 'components/common/Checkbox';
import useCohorts from 'hooks/useCohorts';
import ContentLoader from 'components/common/ContentLoader';
import Divider from 'components/common/Divider';
import { ReactComponent as CohortIcon } from 'assets/svg/cohort.svg';
import { ReactComponent as GraphIcon } from 'assets/svg/graph.svg';
import IconSwitch from 'components/common/IconSwitch';
import CohortGraph from 'components/views/Cohort/CohortGraph';
import { COHORT_AVERAGED_BY_TYPES, COHORT_DISPLAY_TYPES } from 'constants/cohorts';
import ConfirmationWindow from 'components/common/ConfirmationWindow';
import { ReactComponent as DeleteIcon } from 'assets/svg/trash.svg';
import { getSegments } from 'store/segments/getters';
import DataExport from 'components/common/DataExport';
import IconButton from 'components/common/IconButton';
import EditableField from 'components/common/EditableField';
import FieldLabel from 'components/common/FieldLabel';
import { GUIDES } from 'constants/guides';

export const GROUP_BY_METRICS = [{
  value: 'date_first_order',
  label: MESSAGES.date_first_order,
}, {
  value: 'date_second_order',
  label: MESSAGES.date_second_order,
}];

export const AVERAGED_BY_OPTIONS = [{
  value: COHORT_AVERAGED_BY_TYPES.none,
  label: MESSAGES.none,
}, {
  value: COHORT_AVERAGED_BY_TYPES.customers,
  label: MESSAGES.customer,
}, {
  value: COHORT_AVERAGED_BY_TYPES.customers_in_month,
  label: MESSAGES.returning_customer,
}, {
  value: COHORT_AVERAGED_BY_TYPES.orders,
  label: MESSAGES.order,
}];

export const DISPLAY_METRICS = [{
  value: 'num_orders',
  label: MESSAGES.orders,
}, {
  value: 'sales',
  label: MESSAGES.sales,
}, {
  value: 'profit',
  label: MESSAGES.profit,
}];

const searchEntityConfig = [{
  key: CUSTOMER_PERFORMANCE,
  title: MESSAGES.performance,
  hasPages: false,
  unique: false,
}, {
  key: CUSTOMER_DETAILS,
  title: MESSAGES.details,
  hasPages: false,
  unique: false,
}, {
  key: CUSTOMER_EVENTS,
  title: MESSAGES.events,
  hasPages: false,
  unique: false,
}, {
  key: ENTITY_SEGMENT,
  title: MESSAGES.segments,
  hasPages: false,
  unique: true,
  finalized: true,
  type: INPUT_VALUE_TYPE.list,
}];

const getEntityKeysToFields = (segments) => {
  const segmentFields = segments.map(({ segment }) =>
    ({ key: segment.id, title: segment.segment_name }));
  return [
    {
      entityKey: CUSTOMER_PERFORMANCE,
      fields: CUSTOMER_PERFORMANCE_FIELDS,
    },
    {
      entityKey: CUSTOMER_DETAILS,
      fields: CUSTOMER_DETAILS_FIELDS,
    },
    {
      entityKey: CUSTOMER_EVENTS,
      fields: CUSTOMER_EVENT_FIELDS,
    },
    {
      entityKey: ENTITY_SEGMENT,
      fields: segmentFields,
      hasDynamicFields: true,
    },
  ];
};

const getOptionItem = (value, options) =>
  options.find((item) => item.value === value);

const getPreviousYear = () => {
  const date = new Date();
  date.setDate(1);
  date.setFullYear(date.getFullYear() - 1);
  return date;
};

const toInternalValues = (data, segments = []) => ({
  uuid: data.uuid || null,
  name: data.name || null,
  description: data.description || null,
  group_by_metric: data.group_by_metric || 'date_first_order',
  tags: getTagsFromFilters(data.filters, getEntityKeysToFields(segments)),
  start_date: data.start_date ? new Date(data.start_date) : getPreviousYear(),
  end_date: data.end_date ? new Date(data.end_date) : new Date(),
  display_metric: data.display_metric
    ? getOptionItem(data.display_metric, DISPLAY_METRICS)
    : getOptionItem('num_orders', DISPLAY_METRICS),
  is_cumulative: data.is_cumulative || false,
  include_predictive: data.include_predictive === undefined ? true : data.include_predictive,
  averaged_by: data.averaged_by
    ? getOptionItem(data.averaged_by, AVERAGED_BY_OPTIONS)
    : getOptionItem(COHORT_AVERAGED_BY_TYPES.none, AVERAGED_BY_OPTIONS),
  exclude_first: data.exclude_first || false,
  max_months: data.max_months || 12,
});

const Cohort = () => {
  const { uuid } = useParams();
  const [values, setValues] = useState(toInternalValues({}));
  const dispatch = useDispatch();
  const { push, replace, location } = useRouter();
  const [savedCohort, setSavedCohort] = useState(null);
  const [graphDisplay, setGraphDisplay] = useState(COHORT_DISPLAY_TYPES.table);
  const [isValuesInitialized, setIsValuesInitialized] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const segmentStates = useSelector(getSegments);
  const [dataHeader, setDataHeader] = useState(MESSAGES.performance);

  const goToList = () => push(ROUTES.cohorts);

  const {
    cohortRows,
    maxMonthsSince,
    minValue,
    maxValue,
    currency,
    reloadCohort,
    isLoading: isCohortsLoading,
  } = useCohorts(values.uuid, values.exclude_first, values.display_metric);

  const submitHandler = async () => {
    const body = {
      name: values.name,
      description: values.description,
      group_by_metric: values.group_by_metric,
      filters: prepareTagsAsFilterArray(values.tags),
      start_date: moment(values.start_date).toISOString(),
      end_date: moment(values.end_date).toISOString(),
      display_metric: values.display_metric.value,
      is_cumulative: values.is_cumulative,
      include_predictive: values.include_predictive,
      averaged_by: values.averaged_by.value,
      exclude_first: values.exclude_first,
      max_months: values.max_months,
    };

    if (values.uuid) {
      const updatedCohort = await updateCohort(values.uuid, body);
      setSavedCohort(updatedCohort);
    } else {
      const newCohort = await createCohort(body);
      setSavedCohort(newCohort);
    }
    reloadCohort();
  };

  const { execute, isLoading } = useAsync(submitHandler);
  const {
    handleSubmit,
    handleBlur,
    handleFocus,
    handleChange: handleFormChange,
    setValues: setFormValues,
    errors,
    touched,
    touchField,
  } = useForm(execute, validation, values);

  const fetchCohort = async () => {
    await getCohort(uuid).then((cohortResults) => {
      setSavedCohort(cohortResults);
      setIsValuesInitialized(true);
    }).catch(() => {
      goToList();
    });
  };

  const { execute: executeInitialFetch, isLoading: isLoadingInitially } = useAsync(fetchCohort);

  const handleTagsChange = (tags) => setValues({ ...values, tags });

  const {
    tags,
    fields,
    operators,
    addTag,
    removeTag,
    setTags,
    updateTag,
  } = tagHelper(null, handleTagsChange, searchEntityConfig, getCustomerFilterList);

  useEffect(() => {
    if (uuid) { // editing usecase
      executeInitialFetch();
    } else if (location.state) { // duplication usecase
      const newValues = toInternalValues(location.state, segmentStates);
      setValues(newValues);
      setFormValues(newValues);
      setTags(newValues.tags);
      setIsValuesInitialized(true);
    } else {
      setIsValuesInitialized(true);
    }
  }, [uuid]);

  useEffect(() => {
    if (!savedCohort) return;

    const newValues = toInternalValues(savedCohort, segmentStates);
    setValues(newValues);
    setFormValues(newValues);
    setTags(newValues.tags);

    dispatch(setCohortName({ [uuid]: newValues.name }));
    replace(ROUTES.cohort.replace(':uuid', newValues.uuid));
  }, [savedCohort, segmentStates]);

  useEffect(() => {
    if (!savedCohort?.display_metric) return;
    const savedDisplayMetric = getOptionItem(savedCohort.display_metric, DISPLAY_METRICS);
    if (!savedDisplayMetric?.label) return;
    const suffix = (currency ? ` (${currency})` : '');
    setDataHeader(savedDisplayMetric.label + suffix);
  }, [savedCohort, currency]);

  const handleInputChange = (e) => {
    setValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    handleFormChange(e);
  };
  const handleDateChange = (name, value) => {
    const nameValue = { [name]: value };

    setValues((prevState) => ({
      ...prevState,
      ...nameValue,
    }));
    handleFormChange({ target: nameValue });
  };

  const handleToggleCheckbox = (key) =>
    setValues({ ...values, [key]: !values[key] });

  const handleCohortDelete = () => deleteCohort(values.uuid).then(() => {
    setIsDeleting(false);
    goToList();
  });

  const handleMaxMonthsChange = (e) => {
    const { target } = e;
    handleFormChange(e);

    let val = toNumber(target.value);
    if (val === 0) {
      setValues({ ...values, max_months: null });
      return;
    }

    val = Math.max(Math.min(val, 36), 1);
    setValues({ ...values, max_months: val });
  };

  const handleExport = () => exportCohortData(values.uuid);

  const graphDisplayOptions = [
    {
      icon: CohortIcon,
      value: COHORT_DISPLAY_TYPES.table,
    },
    {
      icon: GraphIcon,
      value: COHORT_DISPLAY_TYPES.graph,
    },
  ];

  const handleEditableFieldClose = (field) => {
    if (errors[field]) {
      touchField(field);
      return false;
    }
    return true;
  };

  return (
    <>
      <Stack spacing={2} width="100%">
        <Block header={uuid ? MESSAGES.edit_cohort : MESSAGES.create_cohort}>
          <ContentLoader isLoading={isLoadingInitially}>
            <Grid container columnSpacing={4} rowSpacing={4}>
              <Grid item xl={4} lg={3} xs={12}>
                <Stack
                  direction="column"
                  spacing={2}
                >
                  <FieldLabel label={MESSAGES.name}>
                    <EditableField
                      value={values.name}
                      placeholder={MESSAGES.choose_a_name}
                      isInitialized={isValuesInitialized}
                      onClose={() => handleEditableFieldClose('name')}
                      hasClose
                    >
                      <Input
                        id="name"
                        name="name"
                        label={MESSAGES.choose_a_name}
                        hasFloatLabel={false}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        value={values.name}
                        error={errors.name}
                        touched={touched.name}
                        hasFeedback
                        isRequired
                      />
                    </EditableField>
                  </FieldLabel>
                  <FieldLabel label={MESSAGES.description}>
                    <EditableField
                      value={values.description}
                      placeholder={MESSAGES.cohort_description_placeholder}
                      hasClose
                    >
                      <TextArea
                        name="description"
                        placeholder={MESSAGES.cohort_description_placeholder}
                        characterLimit={300}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        value={values.description}
                        error={errors.description}
                        touched={touched.description}
                        allowVerticalResize
                      />
                    </EditableField>
                  </FieldLabel>
                </Stack>
              </Grid>
              <Grid item xl={4} lg={5} xs={12}>
                <Stack spacing={2}>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <FieldLabel label={MESSAGES.show} guide={GUIDES.CH7}>
                      <Select
                        label=""
                        name="display_metric"
                        options={DISPLAY_METRICS}
                        value={values.display_metric}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        hasFeedback
                        error={errors.display_metric}
                        touched={touched.display_metric}
                      />
                    </FieldLabel>
                    <div className="cohort-date-separator" />
                    <FieldLabel label={MESSAGES.for_each} guide={GUIDES.CH8}>
                      <Select
                        label=""
                        name="averaged_by"
                        options={AVERAGED_BY_OPTIONS}
                        value={values.averaged_by}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        hasFeedback
                      />
                    </FieldLabel>
                  </Stack>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <FieldLabel label={MESSAGES.from} guide={GUIDES.CH5}>
                      <MonthPicker
                        value={values.start_date}
                        onChange={(value) => handleDateChange('start_date', value)}
                      />
                    </FieldLabel>
                    <div className="cohort-date-separator" />
                    <FieldLabel label={MESSAGES.to} guide={GUIDES.CH6}>
                      <MonthPicker
                        value={values.end_date}
                        onChange={(value) => handleDateChange('end_date', value)}
                      />
                    </FieldLabel>
                    <FieldLabel label={MESSAGES.months_ahead} guide={GUIDES.CH4}>
                      <Input
                        id="max_months"
                        name="max_months"
                        type="number"
                        label={12}
                        hasFloatLabel={false}
                        onChange={handleMaxMonthsChange}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        value={values.max_months}
                        error={errors.max_months}
                        touched={touched.max_months}
                        hasFeedback
                        isRequired
                      />
                    </FieldLabel>
                  </Stack>
                  <Stack spacing={2} direction="row">
                    <Checkbox
                      id="cumulative"
                      label={MESSAGES.cumulative}
                      value={values.is_cumulative}
                      onChange={() => handleToggleCheckbox('is_cumulative')}
                    />
                    <Checkbox
                      id="include_predictive"
                      label={MESSAGES.include_predictions}
                      value={values.include_predictive}
                      onChange={() => handleToggleCheckbox('include_predictive')}
                    />
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xl={4} lg={4} xs={12}>
                <Stack
                  direction="column"
                  spacing={2}
                >
                  <FieldLabel label={MESSAGES.group_customers_by} guide={GUIDES.CH9}>
                    <RadioSelect
                      name="group_by_metric"
                      value={values.group_by_metric}
                      options={GROUP_BY_METRICS}
                      onChange={handleInputChange}
                    />
                  </FieldLabel>
                  <Divider />
                  <SearchEntity
                    config={searchEntityConfig}
                    ToggleComponent={ToggleSearchEntity}
                    selectedItems={tags}
                    onItemSelect={addTag}
                  />
                  <Grid container spacing={1}>
                    {tags && tags.map((tag) => (
                      <Grid key={tag.key} item>
                        <AdvancedFilterTag
                          tag={tag}
                          fields={fields}
                          operators={operators}
                          searchEntityConfig={searchEntityConfig}
                          onTagChange={(updatedTag) => updateTag(tag.key, updatedTag)}
                          onRemove={() => removeTag(tag.key)}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Stack>
              </Grid>
            </Grid>
          </ContentLoader>
          <Grid container>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Stack
                direction="row"
                justifyContent="flex-end"
                spacing={2}
              >
                {values?.uuid && (
                  <IconButton
                    icon={DeleteIcon}
                    type="danger"
                    size="medium"
                    tooltip={MESSAGES.delete}
                    tooltipPlacement="left"
                    onClick={() => setIsDeleting(true)}
                    uncolored
                  />
                )}
                <Button
                  type={BUTTON_TYPE.secondary}
                  onClick={goToList}
                >
                  {MESSAGES.cancel}
                </Button>
                <Button
                  type={BUTTON_TYPE.primary}
                  onClick={handleSubmit}
                  isDisabled={!isEmpty(errors)}
                  isLoading={isLoading}
                >
                  {MESSAGES.apply}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Block>
        <Block
          header={dataHeader}
          className="cohort-graph"
          headerContent={(
            <Box display="flex" justifyContent="end">
              <Stack direction="row" spacing={2}>
                <Checkbox
                  id="exclude_first"
                  label={MESSAGES.exclude_first}
                  value={values.exclude_first}
                  onChange={() => handleToggleCheckbox('exclude_first')}
                />
                <IconSwitch
                  options={graphDisplayOptions}
                  selected={graphDisplay}
                  onSelect={setGraphDisplay}
                />
                <DataExport exportHandler={handleExport} />
              </Stack>
            </Box>
          )}
        >
          <ContentLoader isLoading={isLoadingInitially || isCohortsLoading || isLoading}>
            {!values?.uuid ? (
              <span className="cohort-graph__placeholder">
                {MESSAGES.apply_cohort_to_see_results}
              </span>
            ) : cohortRows.length === 0 ? (
              <span className="cohort-graph__placeholder">
                {MESSAGES.no_data_available}
              </span>
            ) : (
              <>
                {graphDisplay === COHORT_DISPLAY_TYPES.table && (
                  <CohortTable
                    cohortRows={cohortRows}
                    maxMonthsSince={maxMonthsSince}
                    minValue={minValue}
                    maxValue={maxValue}
                    currency={currency}
                    isCumulative={savedCohort.is_cumulative}
                    excludeFirst={values.exclude_first}
                  />
                )}
                {graphDisplay === COHORT_DISPLAY_TYPES.graph && (
                  <CohortGraph
                    cohortRows={cohortRows}
                    currency={currency}
                    maxMonthsSince={maxMonthsSince}
                    excludeFirst={values.exclude_first}
                  />
                )}
              </>
            )}
          </ContentLoader>
        </Block>
      </Stack>
      <ConfirmationWindow
        content={MESSAGES.cohort_delete_confirmation}
        open={!!isDeleting}
        onCancel={() => setIsDeleting(false)}
        onConfirm={handleCohortDelete}
        inWorkArea
        forceBlocking={false}
        danger
      />
    </>
  );
};

export default Cohort;
