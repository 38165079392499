import React from 'react';
import PropTypes from 'prop-types';

import 'components/views/SingleSegments/DataWithPercentage/styles.scss';
import Percentage from 'components/common/Percentage';
import LastMonthLabel from 'components/common/LastMonthLabel';
import { NumberFormatted } from 'helper/NumberFormatted';
import { checkHasIntegration } from 'utils/activeIntegrations';
import { getActiveIntegrationsList } from 'store/license/getters';
import { useSelector } from 'react-redux';
import IntegrationRequired from 'components/common/IntegrationRequired/IntegrationRequired';
import { MESSAGES } from 'constants/messages';

const DataWithPercentage = ({
  children,
  percentage,
  requiredIntegration,
}) => {
  const activeIntegrationsList = useSelector(getActiveIntegrationsList);
  const isNotIntegrated = requiredIntegration
    ? !checkHasIntegration(activeIntegrationsList, requiredIntegration) : null;

  return (
    <>
      <div>
        {!!children && (
          <span className="percentage-data">
            {NumberFormatted(children)}
            <span>%</span>
          </span>
        )}
        {(!!percentage || percentage === 0) && <Percentage>{percentage === 0 ? percentage : (percentage || '')}</Percentage> }
      </div>
      {isNotIntegrated
        ? null : (!!percentage || percentage === 0) ? <LastMonthLabel /> : <span className="empty-percentage">-</span>}
      {!children && !percentage
        && (
          isNotIntegrated
            ? <IntegrationRequired requiredIntegration={requiredIntegration} />
            : <div className="item-empty-state">{MESSAGES.no_data_available}</div>)}
    </>
  );
};

DataWithPercentage.propTypes = {
  children: PropTypes.node,
  requiredIntegration: PropTypes.string,
  percentage: PropTypes.number,
};

DataWithPercentage.defaultProps = {
  children: null,
  requiredIntegration: undefined,
  percentage: undefined,
};

export default DataWithPercentage;
