import React from 'react';
import propTypes from 'prop-types';

import './styles.scss';
import { ReactComponent as PrevArrowIcon } from 'assets/svg/leftArrow.svg';
import Button from 'components/common/Button';

const NextPageButton = ({ onClick }) => (
  <div className="pagination__button pagination__next-button">
    <Button block onClick={onClick}>
      <PrevArrowIcon className="pagination__icon pagination__icon--next" />
    </Button>
  </div>
);

NextPageButton.propTypes = {
  onClick: propTypes.func.isRequired,
};

export default NextPageButton;
