import React, { useState } from 'react';

import './styles.scss';
import Header from 'containers/Authorization/Header';
import PaymentForm from 'containers/PrivateApp/Payment/PaymentForm';
import { ROUTES } from 'constants/routes';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentLicense } from 'store/license/getters';
import DeleteLicenseModal from 'components/common/DeleteLicenseModal';
import useRouter from 'hooks/useRouter';
import { deleteLicenseAction } from 'store/license/licenseSlice';
import { MESSAGES } from 'constants/messages';
import { Stack } from '@mui/material';
import { LinkButton } from 'components/common/LinkButton';

const Payment = () => {
  const { replace } = useRouter();
  const currentLicense = useSelector(getCurrentLicense);
  const dispatch = useDispatch();
  const [licenseForDeletion, setLicenseForDeletion] = useState({});

  const onDeleteLicense = () => {
    dispatch(deleteLicenseAction());
    replace(ROUTES.switchLicense);
  };

  return (
    <Stack spacing={2} className="payment">
      <Header />
      <div className="license-payment-wrapper">
        <PaymentForm />
      </div>
      <div className="payment__buttons-container">
        <Stack direction="row" spacing={2}>
          {currentLicense.integrations_count === 0 && (
            <LinkButton
              size="large"
              type="danger"
              onClick={() => setLicenseForDeletion(currentLicense)}
            >
              {MESSAGES.delete_license}
            </LinkButton>
          )}
          <LinkButton
            size="large"
            onClick={() => replace(ROUTES.switchLicense)}
          >
            {MESSAGES.switch_license}
          </LinkButton>
        </Stack>
      </div>
      <DeleteLicenseModal
        license={licenseForDeletion}
        onClose={() => setLicenseForDeletion({})}
        onSubmit={onDeleteLicense}
      />
    </Stack>
  );
};

export default Payment;
